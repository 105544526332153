import React from 'react';
import FDSReleaseService from '../../services/FDSReleaseService';
import FDSReleaseDialog from './FDSReleaseDialog';
import ActionsMenu from '../Menu/ActionsMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Link from '@mui/material/Link';
import GetAppIcon from '@mui/icons-material/GetApp';
import IconButton from '@mui/material/IconButton';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import { useTitan } from '../Titan/Titan';
import ReactMarkdown from 'react-markdown';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import { useParams } from 'react-router-dom';

export default function FDSReleasesManageList({ setNewPageForTab }) {
  const [openReleaseDialog, setOpenReleaseDialog] = React.useState(false);
  const [dialogMode, setDialogMode] = React.useState('create');
  const [editableRelease, setEditableRelease] = React.useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const { pushSnackbar } = useTitan();

  const { page: pageParam = 0 } = useParams();

  const loadData = React.useCallback(async (query, config) => {
    const { data, pagination } = await FDSReleaseService.getReleases(
      query,
      config
    );

    return {
      data,
      page: pagination.page - 1,
      totalCount: pagination.totalCount
    };
  }, []);

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Version',
        field: 'version',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: 'State',
        field: 'state',
        minWidth: 150,
        sortable: false
      },
      {
        headerName: 'Description',
        field: 'description',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <ReactMarkdown source={row.description} />
      },
      {
        headerName: 'Created',
        field: 'created',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />
      },
      {
        headerName: '',
        field: 'download',
        width: 60,
        sortable: false,
        renderCell: ({ row }) => (
          <IconButton
            aria-label="download"
            component={Link}
            href={row.downloadUrl}
            target="_blank"
          >
            <GetAppIcon />
          </IconButton>
        )
      },
      {
        headerName: '',
        field: 'actions',
        width: 60,
        sortable: false,
        renderCell: ({ row }) => (
          <ActionsMenu
            items={[
              {
                title: 'Edit',
                icon: EditIcon,
                onClick: () => {
                  setEditableRelease(row);
                  setDialogMode('edit');
                  setOpenReleaseDialog(true);
                }
              },
              {
                title: 'Delete',
                icon: DeleteIcon,
                onClick: () => {
                  setEditableRelease(row);
                  setOpenDeleteDialog(true);
                }
              }
            ]}
          />
        )
      }
    ],
    []
  );

  const gridOptions = {
    columns,
    pinnedColumns: {
      left: ['version'],
      right: ['download', 'actions']
    },
    onChangePage: page => {
      setNewPageForTab('fds-releases', 'fDS Releases', page);
    }
  };

  if (pageParam && Number(pageParam) > 0) {
    gridOptions.page = Number(pageParam);
  }

  const titanDataGridProps = useTitanDataGrid(loadData, gridOptions);

  const { rows, setRows } = titanDataGridProps;

  const onSaveFDSRelease = React.useCallback(
    fdsRelease => {
      if (dialogMode === 'create') {
        setRows(prev => [fdsRelease, ...prev]);
      } else {
        setRows(prev =>
          prev.map(r => (r.id === fdsRelease.id ? { ...r, ...fdsRelease } : r))
        );
      }
    },
    [rows, editableRelease, dialogMode]
  );

  const onCloseReleaseDialog = () => {
    setOpenReleaseDialog(false);
  };

  const onDeleteFDSRelease = React.useCallback(async () => {
    await FDSReleaseService.deleteRelease(editableRelease.id);

    pushSnackbar('Release successfully deleted', { variant: 'success' });
    setOpenDeleteDialog(false);
    setRows(prev => prev.filter(r => r.id !== editableRelease.id));
  }, [rows, editableRelease]);

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        title="fDS Releases"
        columns={columns}
        onCreateClick={() => {
          setEditableRelease({});
          setDialogMode('create');
          setOpenReleaseDialog(true);
        }}
        createButtonLabel="fDS Release"
      />

      {openReleaseDialog && (
        <FDSReleaseDialog
          release={editableRelease}
          mode={dialogMode}
          onClose={() => onCloseReleaseDialog()}
          onSave={onSaveFDSRelease}
        />
      )}

      {openDeleteDialog && (
        <TitanConfirmationDialog
          title="Delete release?"
          message={`Are you sure you want to delete Fibrify DS release ${
            editableRelease.version
          }?`}
          onClose={() => setOpenDeleteDialog(false)}
          onConfirm={() => onDeleteFDSRelease()}
        />
      )}
    </>
  );
}
