import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../constants';
import AssemblyIcon from '../../assets/icons/assembly-icon.svg';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@mui/styles';
import OverflowTip from '../ExpandRenderCell/OverflowTip';
import { useTitan } from '../Titan/Titan';

const useStyles = makeStyles(theme => ({
  hiddenOverflowBlock: {
    overflow: 'hidden'
  }
}));

export default function FinalPartLink({
  finalPart,
  children,
  useName = true,
  onClick = e => e.stopPropagation(),
  width
}) {
  const classes = useStyles();
  const location = useLocation();

  const content = useName
    ? finalPart.finalPartKey
      ? finalPart.finalPartKey
      : finalPart.id
    : children;

  const imageWithSpacingWidth = 52;

  const { addPageToPageHistory, preparePageObjectForLink } = useTitan();

  const handleClick = e => {
    addPageToPageHistory(
      preparePageObjectForLink(
        'FINAL_PART',
        finalPart.id,
        finalPart.finalPartKey || `Final Part: ${finalPart.id}`,
        ROUTES.FINAL_PART_TAB(finalPart.id, 'summary'),
        'Summary'
      )
    );

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Link
      className={classes.hiddenOverflowBlock}
      onClick={handleClick}
      to={{
        pathname: ROUTES.FINAL_PART(finalPart.id),
        state: { from: location.pathname }
      }}
      component={RouterLink}
    >
      <Stack
        className={classes.hiddenOverflowBlock}
        direction="row"
        spacing={1}
      >
        <img src={AssemblyIcon} alt="" />
        {width ? (
          <OverflowTip width={width - imageWithSpacingWidth}>
            {content}
          </OverflowTip>
        ) : (
          <span>{content}</span>
        )}
      </Stack>
    </Link>
  );
}
