import React from 'react';
import { ROUTES } from '../../constants';
import TitanPage from '../Titan/TitanPage';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';

export default function SupportPage() {
  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Open Source License',
        path: ROUTES.LICENSES_OPEN_SOURCE,
        disabled: true
      }
    ],
    []
  );

  return (
    <TitanPage breadcrumbs={breadcrumbs} title="Open Source licenses">
      <Paper sx={{ minHeight: 'calc(100vh - 175px)', p: 2 }}>
        <Typography>Page is under construction</Typography>
      </Paper>
    </TitanPage>
  );
}
