import React from 'react';
import Grid from '@mui/material/Grid';

import { useFuseDialog } from './FuseDialog';

import FusionModuleCard from '../FusionModule/FusionModuleCard';
import FusionModuleLink from '../FusionModule/FusionModuleLink';
import FusionModuleStatus from '../FusionModule/FusionModuleStatus';
import FusionJobLink from '../FusionJob/FusionJobLink';
import ComponentStatus from '../Component/ComponentStatus';
import FusorService from '../../services/FusorService';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import TitanDataGrid, { VIEW_MODES } from '../TitanDataGrid/TitanDataGrid';
import useFusionModules from '../FusionModule/use-fusion-modules';
import Box from '@mui/material/Box';
import { colors } from '../Theme/vars';
import Chip from '@mui/material/Chip';

export default function FuseDialogFusionModulesStep() {
  const { selectedFusionModule, setSelectedFusionModule } = useFuseDialog();

  const onSelectFusionModule = React.useCallback(
    (e, fusionModule) => {
      setSelectedFusionModule(fusionModule);
    },
    [setSelectedFusionModule],
  );

  const selectedFusionModules = React.useMemo(
    () => (selectedFusionModule ? [selectedFusionModule] : []),
    [selectedFusionModule],
  );

  const fusionModuleColumns = React.useMemo(
    () => [
      {
        headerName: 'Fusion Module',
        field: 'name',
        visibilityBreakpoint: 'sm',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <FusionModuleLink fusionModule={row} />,
      },
      {
        headerName: 'State',
        field: 'state',
        visibilityBreakpoint: 'sm',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => (
          <FusionModuleStatus fusionModuleId={row.id} fusionModule={row} />
        ),
      },
      {
        headerName: 'Version',
        field: 'version',
        visibilityBreakpoint: 'md',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => row.state?.fusionModuleVersion || '',
      },
      {
        headerName: 'Fusion Job',
        field: 'fusionJob',
        visibilityBreakpoint: 'sm',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => {
          const activeJob = row.activeJobs[0];
          const prevJob = row.prevFusionJob;

          return activeJob || prevJob ? (
            <>
              <FusionJobLink fusionJob={activeJob ? activeJob : prevJob} />
              <Chip
                size="small"
                label={activeJob ? 'Active' : 'Previous'}
                sx={{ ml: 1, color: colors.STONE }}
              />
            </>
          ) : (
            ''
          );
        },
      },
      {
        headerName: 'Job Status',
        field: 'fusionJobStatus',
        visibilityBreakpoint: 'sm',
        minWidth: 170,
        sortable: false,
        renderCell: ({ row }) => {
          const activeJob = row.activeJobs[0];
          const prevJob = row.prevFusionJob;

          return activeJob || prevJob ? (
            <ComponentStatus component={activeJob ? activeJob : prevJob} />
          ) : (
            ''
          );
        },
      },
    ],
    [],
  );

  const loadFusionModules = React.useCallback(async (params, config) => {
    const response = await FusorService.getFusors(
      {
        withRelated: [
          'activeJobs',
          'activeJobs.fusionPlan',
          'activeJobs.assemblies',
          'prevFusionJob',
          'prevFusionJob.fusionPlan',
          'prevFusionJob.assemblies',
        ],
        ...params,
      },
      config,
    );

    return response;
  }, []);

  const fusionModulesDataGridProps = useTitanDataGrid(loadFusionModules, {
    columns: fusionModuleColumns,
    defaultViewMode: VIEW_MODES.GRID,
  });

  const { rows } = fusionModulesDataGridProps;

  const returnFusionModules = React.useCallback(() => {
    return rows;
  }, [rows]);

  const { fusionModules } = useFusionModules(returnFusionModules);

  return (
    <Box sx={{ p: 2 }}>
      <TitanDataGrid
        {...fusionModulesDataGridProps}
        allowViewModeChange={true}
        rows={fusionModules}
        renderGridCard={(item) => (
          <FusionModuleCard
            fusionModule={item}
            onClick={onSelectFusionModule}
            selected={
              selectedFusionModule ? item.id === selectedFusionModule.id : false
            }
          />
        )}
        searchPlaceholder="Search by Fusion Module name..."
        checkboxSelection
        selectedRows={selectedFusionModules}
        onSelectionModelChange={(selectedFusionModules) => {
          setSelectedFusionModule(
            selectedFusionModules.length === 1
              ? selectedFusionModules[0]
              : null,
          );
        }}
      />
    </Box>
  );
}
