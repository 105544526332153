import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PrinterService from '../../services/PrinterService';
import useBuildModule from './use-build-module';
import { useTitan } from '../Titan/Titan';
import TitanPage from '../Titan/TitanPage';
import TitanTabPanel from '../Titan/TitanTabPanel';
import BuildModuleTemperatures from './BuildModuleTemperatures';
import BuildJobsTable from '../BuildJob/BuildJobsTable';
import BuildModuleNotesTable from './BuildModuleNotesTable';
import BuildModuleAnalytics from './BuildModuleAnalytics/BuildModuleAnalytics';
import BuildModuleState from './BuildModuleState';
import BuildModuleStates from './BuildModuleStates';
import BuildModulePreHeatingTemperaturesTab from './BuildModulePreHeatingTemperaturesTable';
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg';
import { colors } from '../Theme/vars';
import { ROUTES } from '../../constants';
import { Button } from '@mui/material';
import PinCodeDialog from './PinCodeDialog';
import { useAuth0 } from '../Authentication/Auth0';
import BuildModuleSpoolTable from './BuildModuleSpoolsTable';
import MetabaseComponent from '../Metabase/MetabaseComponent';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  backButton: {
    marginRight: theme.spacing(2),
    width: 44,
  },
  status: {
    marginLeft: 18,
    flexGrow: 1,

    '& p': {
      marginLeft: 8,
    },
  },
  tabsRoot: {
    borderBottom: `2px solid ${colors.GREY2}`,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  tabsIndicator: {
    backgroundColor: theme.palette.primary.main,
  },
  tab: {
    '&:focus': {
      color: theme.palette.primary.main,
    },
  },
  tabSelected: {
    color: theme.palette.primary.main,
  },
  progressContainer: {
    marginLeft: 20,
    position: 'relative',
    top: 5,
  },
  progressBar: {
    width: 200,
    backgroundColor: colors.GREY2,
  },
}));

export default function BuildModulePage() {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const { buildModuleId, tab = 'temperature' } = useParams();

  const [error, setError] = React.useState(null);

  const { addPageToPageHistory, backToPreviousPage } = useTitan();
  const { isOrganizationAdmin } = useAuth0();

  const [breadcrumbs, setBreadcrumbs] = React.useState('');
  const [pinCodeDialog, setPinCodeDialog] = React.useState('');

  const pushHistory = React.useCallback(
    (tabName, settedPage) => {
      history.push(
        ROUTES.BUILD_MODULE_TAB_PAGE(buildModuleId, tabName, settedPage),
      );
    },
    [buildModuleId],
  );

  const loadBuildModule = React.useCallback(() => {
    return PrinterService.getPrinter(buildModuleId).catch((error) => {
      setError(error.response.data.message);
      return error;
    });
  }, [buildModuleId]);

  const { buildModule, setBuildModule, loading } =
    useBuildModule(loadBuildModule);

  const preparePageObject = (tab, tabForURL) => {
    return {
      id: `BUILD_MODULE:${buildModuleId}`,
      url:
        tab === 'Temperature'
          ? ROUTES.BUILD_MODULE_TAB(buildModuleId, tabForURL)
          : ROUTES.BUILD_MODULE_TAB_PAGE(buildModuleId, tabForURL, 0),
      label: `${buildModule.name || 'Build Module'} | ${tab}`,
    };
  };

  const setNewPageForTab = React.useCallback(
    (tabName, labelName, settedPage) => {
      if (buildModuleId) {
        const newRoute = ROUTES.BUILD_MODULE_TAB_PAGE(
          buildModuleId,
          tabName,
          settedPage,
        );
        addPageToPageHistory({
          id: `BUILD_MODULE:${buildModuleId}`,
          url: newRoute,
          label:
            settedPage === 0
              ? `${
                  buildModule.name ? buildModule.name : 'Build Module'
                } | ${labelName}`
              : `${
                  buildModule.name ? buildModule.name : 'Build Module'
                } | ${labelName} | Page : ${settedPage + 1}`,
        });
      }
    },
    [buildModule],
  );

  React.useEffect(() => {
    if (!buildModule) {
      return;
    }

    setBreadcrumbs([
      {
        path: ROUTES.BUILD_MODULES,
        name: 'Build Modules',
        onClick: () => {
          addPageToPageHistory({
            id: 'BUILD_MODULES',
            url: ROUTES.BUILD_MODULES,
            label: 'Build Modules',
          });
        },
      },
      {
        path: ROUTES.BUILD_MODULE(buildModuleId),
        name: buildModule.name,
        disabled: true,
      },
    ]);
  }, [buildModule]);

  return (
    <TitanPage breadcrumbs={breadcrumbs} loading={loading} error={error}>
      <div className={classes.header}>
        <IconButton
          className={classes.backButton}
          onClick={() => {
            backToPreviousPage(location);
          }}
        >
          <img src={ArrowLeftIcon} alt="Back button" />
        </IconButton>
        <Typography variant="h1">
          {buildModule ? buildModule.name : ''}
        </Typography>
        {buildModule && (
          <div className={classes.status}>
            <BuildModuleState buildModule={buildModule} />
          </div>
        )}
        {isOrganizationAdmin && (
          <Button
            variant="contained"
            onClick={() => {
              setPinCodeDialog(true);
            }}
          >
            {buildModule?.pinCodeHash ? 'Change PIN code' : 'Set PIN code'}
          </Button>
        )}
      </div>

      <Tabs
        classes={{
          root: classes.tabsRoot,
          indicator: classes.tabsIndicator,
        }}
        value={tab}
        onChange={(e, value) => {
          const activeLabel = value.charAt(0).toUpperCase() + value.slice(1);
          addPageToPageHistory(preparePageObject(activeLabel, value));
          value === 'temperature'
            ? history.push(ROUTES.BUILD_MODULE_TAB(buildModuleId, value))
            : history.push(
                ROUTES.BUILD_MODULE_TAB_PAGE(buildModuleId, value, 0),
              );
        }}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable tabs"
      >
        <Tab
          classes={{ root: classes.tab, selected: classes.tabSelected }}
          label="Temperature"
          value="temperature"
        />
        <Tab
          classes={{ root: classes.tab, selected: classes.tabSelected }}
          label="Build Jobs"
          value="buildJobs"
        />
        <Tab
          classes={{ root: classes.tab, selected: classes.tabSelected }}
          label="Notes"
          value="notes"
        />
        <Tab
          classes={{ root: classes.tab, selected: classes.tabSelected }}
          label="Analytics"
          value="analytics"
        />
        <Tab
          classes={{ root: classes.tab, selected: classes.tabSelected }}
          label="States History"
          value="states"
        />
        <Tab
          classes={{ root: classes.tab, selected: classes.tabSelected }}
          label="Pre-Heating Temperatures"
          value="pre-heating-temperatures"
        />
        <Tab
          classes={{ root: classes.tab, selected: classes.tabSelected }}
          label="Spools"
          value="spools"
        />
        {buildModule && buildModule.metabaseUrl ? (
          <Tab
            classes={{ root: classes.tab, selected: classes.tabSelected }}
            label="Statistics"
            value="statistics"
          />
        ) : (
          ''
        )}
      </Tabs>
      <TitanTabPanel index={tab} value={'temperature'}>
        <BuildModuleTemperatures buildModuleId={buildModuleId} />
      </TitanTabPanel>
      <TitanTabPanel index={tab} value={'buildJobs'}>
        {buildModule && (
          <BuildJobsTable
            buildModuleId={buildModule.id}
            buildModuleName={buildModule.name}
            hiddenColumns={['buildModule']}
            hideBuildModulesFilter={true}
          />
        )}
      </TitanTabPanel>
      <TitanTabPanel index={tab} value={'notes'}>
        <BuildModuleNotesTable
          buildModule={buildModule}
          setNewPageForTab={setNewPageForTab}
          pushHistory={pushHistory}
        />
      </TitanTabPanel>
      <TitanTabPanel index={tab} value={'analytics'}>
        <BuildModuleAnalytics buildModule={buildModule} />
      </TitanTabPanel>
      <TitanTabPanel index={tab} value={'states'}>
        <BuildModuleStates buildModule={buildModule} />
      </TitanTabPanel>
      <TitanTabPanel index={tab} value={'pre-heating-temperatures'}>
        <BuildModulePreHeatingTemperaturesTab
          buildModuleId={buildModuleId}
          setNewPageForTab={setNewPageForTab}
        />
      </TitanTabPanel>
      <TitanTabPanel index={tab} value={'spools'}>
        <BuildModuleSpoolTable buildModuleId={buildModuleId} />
      </TitanTabPanel>
      {buildModule && buildModule.metabaseUrl ? (
        <TitanTabPanel index={tab} value={'statistics'}>
          <MetabaseComponent metabaseUrl={buildModule.metabaseUrl} />
        </TitanTabPanel>
      ) : (
        ''
      )}
      {pinCodeDialog && (
        <PinCodeDialog
          buildModule={buildModule}
          onSave={(pinCodeHash) => {
            setBuildModule({ ...buildModule, pinCodeHash });
          }}
          onClose={() => {
            setPinCodeDialog(false);
          }}
        />
      )}
    </TitanPage>
  );
}
