import ActionsMenu from '../Menu/ActionsMenu';
import DeleteIcon from '@mui/icons-material/Delete';
import { ROUTES } from '../../constants';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import React from 'react';
import TermsAndConditionsService from '../../services/TermsAndConditionsService';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import { useTitan } from '../Titan/Titan';
import cx from 'classnames';
import { makeStyles } from '@mui/styles';
import { colors } from '../Theme/vars';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  status: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1.25rem',
  },
  icon: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  active: {
    background: colors.GREEN,
  },
  inactive: {
    background: colors.RED,
  },
}));

export default function TermsTable() {
  const history = useHistory();
  const location = useLocation();

  const { page: pageParam = 0 } = useParams();

  const { pushSnackbar } = useTitan();
  const classes = useStyles();

  const [editableTerms, setEditableTerms] = React.useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [activeTermsVersion, setActiveTermsVersion] = React.useState('');

  const loadData = React.useCallback(async (query, config) => {
    return TermsAndConditionsService.loadAll(query, config);
  }, []);

  const gridOptions = {};

  if (pageParam && Number(pageParam) > 0) {
    gridOptions.page = Number(pageParam);
  }

  const titanDataGridProps = useTitanDataGrid(loadData, gridOptions);

  const { setRows } = titanDataGridProps;

  React.useEffect(() => {
    TermsAndConditionsService.loadLatest().then((terms) =>
      setActiveTermsVersion(terms ? terms.version : ''),
    );
  }, []);

  const onDelete = async () => {
    await TermsAndConditionsService.delete(editableTerms.id);

    pushSnackbar('Terms And Conditions successfully deleted', {
      variant: 'success',
    });
    setOpenDeleteDialog(false);
    setRows((prev) => prev.filter((t) => t.id !== editableTerms.id));
  };

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Version',
        field: 'version',
        flex: 1,
        sortable: false,
      },
      {
        headerName: 'Updated',
        field: 'updated',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => <TitanTimeAgo time={row.updatedAt} />,
      },
      {
        headerName: 'Published',
        field: 'published',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => (
          <div className={classes.status}>
            <div
              className={cx([
                classes.icon,
                row.isPublished ? classes.active : classes.inactive,
              ])}
            />
          </div>
        ),
      },
      {
        headerName: '',
        field: 'actions',
        width: 60,
        sortable: false,
        renderCell: ({ row }) => (
          <ActionsMenu
            items={[
              {
                title: 'Delete',
                icon: DeleteIcon,
                onClick: () => {
                  setEditableTerms(row);
                  setOpenDeleteDialog(true);
                },
              },
            ]}
          />
        ),
      },
    ],
    [],
  );

  return (
    <Box
      sx={{
        '& .fibrify-active-terms-and-conditions': {
          boxShadow: 'inset 0px 0px 0px 5px #97110C',
        },
      }}
    >
      <TitanDataGrid
        {...titanDataGridProps}
        title="Terms And Conditions"
        createButtonLabel="Terms"
        onCreateClick={() =>
          history.push({
            pathname: ROUTES.NEW_TERMS_AND_CONDITIONS,
            state: { from: location.pathname },
          })
        }
        columns={columns}
        onRowClick={(e, rowData) => {
          history.push({
            pathname: ROUTES.TERMS_AND_CONDITIONS_SINGLE(rowData.id),
            state: { from: location.pathname },
          });
        }}
        getRowClassName={(params) =>
          params.row.version === activeTermsVersion
            ? 'fibrify-active-terms-and-conditions'
            : null
        }
      />

      {openDeleteDialog && (
        <TitanConfirmationDialog
          title="Delete Terms And Conditions?"
          message={`Are you sure you want to delete Terms And Conditions Version ${editableTerms.version}?`}
          onClose={() => setOpenDeleteDialog(false)}
          onConfirm={() => onDelete()}
        />
      )}
    </Box>
  );
}
