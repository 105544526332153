import React from 'react';
import { BooleanCell } from './BooleanCell';
import { MultiSelectCell } from './MultiSelectCell';
import { SingleSelectCell } from './SingleSelectCell';
import { StringCell } from './StringCell';

export const RenderFinalPartsCell = React.memo(
  ({
    row,
    customField,
    fusionPlan,
    isEditable,
    prepareFinalPartToUpdate,
    updateAssembly,
    updateFinalParts,
    updateCustomField,
    prepareCustomFieldsObject,
  }) => {
    let finalPartTypeCustomField;

    if (fusionPlan.finalPartTypes) {
      fusionPlan.finalPartTypes.forEach((finalPartType) => {
        if (finalPartType.customFields) {
          finalPartType.customFields.forEach((cf) => {
            if (cf.name === customField.name) {
              finalPartTypeCustomField = cf;
            }
          });
        }
      });
    }

    if (row.customFields && finalPartTypeCustomField) {
      const customFieldData = row.customFields.find(
        (cf) => cf?.fusionPlanCustomField?.name === customField.name,
      );

      // if (!customFieldData && isEditable(row)) {
      //   const finalPartToUpdate = prepareFinalPartToUpdate(
      //     'INITIAL',
      //     row,
      //     customField,
      //     '',
      //   );
      //
      //   updateAssembly(
      //     row,
      //     prepareCustomFieldsObject(row, customField.id, ''),
      //     customField,
      //     finalPartToUpdate,
      //   );
      // }

      if (customField.type === 'BOOLEAN') {
        return (
          <BooleanCell
            row={row}
            customField={customField}
            customFieldData={customFieldData}
            isEditable={isEditable}
            updateCustomField={updateCustomField}
            prepareComponentToUpdate={prepareFinalPartToUpdate}
            updateComponents={updateFinalParts}
          />
        );
      } else if (customField.type === 'MULTI_SELECT') {
        return (
          <MultiSelectCell
            row={row}
            customField={customField}
            customFieldData={customFieldData}
            componentTypes={fusionPlan}
            isEditable={isEditable}
            updateCustomField={updateCustomField}
            prepareComponentToUpdate={prepareFinalPartToUpdate}
            updateComponents={updateFinalParts}
          />
        );
      } else if (customField.type === 'SINGLE_SELECT') {
        return (
          <SingleSelectCell
            row={row}
            customField={customField}
            customFieldData={customFieldData}
            componentTypes={fusionPlan}
            isEditable={isEditable}
            updateCustomField={updateCustomField}
            prepareComponentToUpdate={prepareFinalPartToUpdate}
            updateComponents={updateFinalParts}
          />
        );
      } else {
        return (
          <StringCell
            row={row}
            customField={customField}
            componentTypes={customField}
            customFieldData={customFieldData}
            isEditable={isEditable}
            updateCustomField={updateCustomField}
            prepareComponentToUpdate={prepareFinalPartToUpdate}
            updateComponents={updateFinalParts}
          />
        );
      }
    } else {
      return '';
    }
  },
);
