import React from 'react';
import TitanPage from '../Titan/TitanPage';
import TitanTabs from '../Titan/TitanTabs';
import DashboardBuildPlans from '../BuildPlans/DashboardBuildPlans';
import DashboardBuildModules from '../BuildModule/DashboardBuildModules';
import DashboardFusionModules from '../FusionModule/DashboardFusionModules';
import DashboardFusionPlans from '../FusionPlans/DashboardFusionPlans';
import Box from '@mui/material/Box';
import BuildJobsTable from '../BuildJob/BuildJobsTable';
import { useAuth0 } from '../Authentication/Auth0';
import { useTitan } from '../Titan/Titan';
import { ROUTES } from '../../constants';

export default function DashboardPage() {
  const { currentMemberId } = useAuth0();

  const { addPageToPageHistory } = useTitan();

  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Dashboard',
        disabled: true
      }
    ],
    []
  );

  React.useEffect(() => {
    addPageToPageHistory({
      id: `DASHBOARD`,
      url: ROUTES.DASHBOARD,
      label: 'Dashboard'
    });
  }, []);

  return (
    <TitanPage breadcrumbs={breadcrumbs} title="Dashboard">
      <TitanTabs
        tabs={[
          {
            label: 'My Recently used Build Modules',
            value: 'recentBuildPlans',
            content: (
              <DashboardBuildModules params={{ order: ['last_used:desc'] }} />
            )
          },
          {
            label: 'My Recently used Fusion Modules',
            value: 'recentFusionPlans',
            content: (
              <DashboardFusionModules params={{ order: ['last_used:desc'] }} />
            )
          }
        ]}
      />

      <Box sx={{ mt: 2 }}>
        <TitanTabs
          tabs={[
            {
              label: 'My Recent Build Plans',
              value: 'recentBuildPlans',
              content: (
                <DashboardBuildPlans params={{ order: ['last_used:desc'] }} />
              )
            },
            {
              label: 'Favorite Build Plans',
              value: 'favoriteBuildPlans',
              content: (
                <DashboardBuildPlans
                  params={{ order: ['last_used:desc'], isFavorite: true }}
                />
              )
            },
            {
              label: 'Assigned to me',
              value: 'assignedToMeBuildPlans',
              content: (
                <DashboardBuildPlans params={{ assigneeIds: [currentMemberId] }} />
              )
            },
            {
              label: 'My Recent Build Jobs',
              value: 'recentBuildJobs',
              content: (
                <BuildJobsTable
                  creatorId={currentMemberId}
                  pageSize={3}
                  hideToolbar
                  hideFooter
                  pagination={false}
                  showFilter={false}
                />
              )
            }
          ]}
        />
      </Box>

      <Box sx={{ mt: 2 }}>
        <TitanTabs
          tabs={[
            {
              label: 'My Recent Fusion Plans',
              value: 'recentFusionPlans',
              content: (
                <DashboardFusionPlans params={{ order: ['last_used:desc'] }} />
              )
            },
            {
              label: 'Favorite Fusion Plans',
              value: 'favoriteFusionPlans',
              content: (
                <DashboardFusionPlans
                  params={{ order: ['last_used:desc'], isFavorite: true }}
                />
              )
            }
          ]}
        />
      </Box>
    </TitanPage>
  );
}
