import React, { useState, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stack, Tooltip, Typography } from '@mui/material';
import WebappMenu from '../WebappMenu';
import Breadcrumbs from '../Breadcrumbs';
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg';
import IconButton from '@mui/material/IconButton';
import { useHistory, useLocation } from 'react-router-dom';
import TitanCircularProgress from './TitanCircularProgress';
import Box from '@mui/material/Box';

export default function TitanPage(props) {
  const history = useHistory();
  const location = useLocation();

  const {
    showMenu = true,
    title,
    titleContent,
    headerContent,
    breadcrumbs,
    filtersContent,
    onBackButtonClick,
    loading = false,
    error
  } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const [isOverflowed, setIsOverflow] = useState(false);

  const measuredRef = useCallback(
    node => {
      if (node !== null) {
        setIsOverflow(node.clientWidth < node.scrollWidth);
      }
    },
    [matches]
  );

  if (error) {
    return (
      <>
        {showMenu && <WebappMenu />}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 4 }}
        >
          <Typography variant="h3">{error}</Typography>
        </Stack>
      </>
    );
  }

  return (
    <>
      {showMenu && <WebappMenu />}
      {loading ? (
        <TitanCircularProgress />
      ) : (
        <Box sx={{ py: 1, px: 4 }}>
          {breadcrumbs && <Breadcrumbs crumbs={breadcrumbs} />}
          {(headerContent || title || onBackButtonClick) && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              {onBackButtonClick && (
                <IconButton
                  onClick={() => {
                    if (onBackButtonClick) {
                      onBackButtonClick();
                    } else if (location.state && location.state.from) {
                      history.push({
                        pathname: location.state.from,
                        state: { from: location.pathname }
                      });
                    }
                  }}
                  sx={{
                    mr: 2,
                    mb: 2,
                    width: 36
                  }}
                >
                  <img src={ArrowLeftIcon} alt="Back Button" title="Back" />
                </IconButton>
              )}

              {title && (
                <Box
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    mb: 2
                  }}
                >
                  <Tooltip
                    title={title}
                    placement="bottom-start"
                    disableHoverListener={!isOverflowed}
                  >
                    <Typography
                      variant="h1"
                      sx={{ mr: 2 }}
                      noWrap
                      ref={measuredRef}
                      data-pw="fp-page-title"
                    >
                      {title}
                    </Typography>
                  </Tooltip>
                  {titleContent && <div>{titleContent}</div>}
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexGrow: 1,
                  mb: 2
                }}
              >
                {headerContent}
              </Box>
            </Box>
          )}
          {filtersContent && <Box sx={{ mb: 4 }}>{filtersContent}</Box>}
          <div>{props.children}</div>
        </Box>
      )}
    </>
  );
}
