import React from 'react';
import { useAuth0 } from '../Authentication/Auth0';
import FibrifyItemsSelectionDialog from './FibrifyItemsSelectionDialog';
import ManufacturingOrderService from '../../services/ManufacturingOrderService';
import ManufacturingOrderLink from '../ManufacturingOrders/ManufacturingOrderLink';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import ManufacturingOrderState from '../ManufacturingOrders/ManufacturingOrderState';
import ProjectLink from '../Projects/ProjectLink';

export default function FibrifyManufacturingOrderSelectionDialog({
  title,
  multipleSelection = false,
  currentSelection = [],
  filters = {},
  onSave,
  saveButtonLabel = 'Confirm',
  onClose,
}) {
  const { profile } = useAuth0();

  const loadData = React.useCallback(
    async ({ page, pageSize, search }, config) => {
      const params = {
        page: page,
        pageSize: pageSize,
        order: ['created_at:desc'],
        ...filters,
      };

      if (search) {
        params.search = search;
      }

      const { data, pagination } =
        await ManufacturingOrderService.getManufacturingOrders(params, config);

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount,
      };
    },
    [profile],
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        hideable: false,
        flex: 1,
        minWidth: 200,
        sortable: false,
        visibilityBreakpoint: 'sm',
        renderCell: ({ row, colDef }) => (
          <ManufacturingOrderLink
            manufacturingOrder={row}
            width={colDef.computedWidth}
          />
        ),
      },
      {
        headerName: 'State',
        field: 'state',
        minWidth: 140,
        sortable: false,
        visibilityBreakpoint: 'xs',
        renderCell: ({ row }) => (
          <ManufacturingOrderState manufacturingOrder={row} />
        ),
      },
      {
        headerName: 'Project',
        field: 'project',
        minWidth: 240,
        sortable: false,
        visibilityBreakpoint: 'xs',
        renderCell: ({ row }) =>
          row.project ? <ProjectLink project={row.project} /> : '',
      },
      {
        headerName: 'Uploaded',
        field: 'uploaded',
        minWidth: 150,
        sortable: false,
        visibilityBreakpoint: 'sm',
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />,
      },
    ],
    [],
  );

  return (
    <FibrifyItemsSelectionDialog
      onClose={onClose}
      selectedItems={currentSelection}
      loadData={loadData}
      columns={columns}
      title={title}
      multipleSelection={multipleSelection}
      onSave={onSave}
      saveButtonLabel={saveButtonLabel}
      searchPlaceholder="Search by Manufacturing Order name..."
    />
  );
}
