import React from 'react';
import { Button } from '@mui/material';
import TitanDialog from '../Titan/TitanDialog';
import Alert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';
const FileDownload = require('js-file-download');

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  config: {
    margin: theme.spacing(2, 0),

    '& > p': {
      margin: 0,
    },
  },
  certificates: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0),
  },
}));

export default function MeasuringDeviceConfigDialog({
  onClose,
  measuringDeviceConfig,
}) {
  const classes = useStyles();

  const downloadCertificate = () => {
    FileDownload(
      measuringDeviceConfig.certificate.certificateData,
      measuringDeviceConfig.certificate.certificateFileName,
    );
  };

  const downloadPrivateKey = () => {
    FileDownload(
      measuringDeviceConfig.certificate.privateKeyData,
      measuringDeviceConfig.certificate.privateFileName,
    );
  };

  const downloadPublicKey = () => {
    FileDownload(
      measuringDeviceConfig.certificate.publicKeyData,
      measuringDeviceConfig.certificate.publicFileName,
    );
  };

  return (
    <TitanDialog
      onClose={onClose}
      maxWidth="md"
      title="Measuring device config file"
      cancelButtonLabel="Close"
    >
      <div className={classes.container}>
        <Alert severity="info">
          Copy next config to .env file of measuring device
        </Alert>

        <div className={classes.config}>
          <p>
            <b>DEVICE_ID</b>={measuringDeviceConfig.measuringDeviceId}
          </p>
          <p>
            <b>AWS_MQTT_CLIENT_ID</b>={measuringDeviceConfig.thingName}
          </p>
          <p>
            <b>AWS_MQTT_HOST</b>={measuringDeviceConfig.awsMqttHost}
          </p>
          <p>
            <b>AWS_REGION</b>={measuringDeviceConfig.awsRegion}
          </p>
        </div>

        <Alert severity="info">
          Download certificate and key files. You will not able to download
          after close this dialog
        </Alert>

        <div className={classes.certificates}>
          <Button
            variant="outlined"
            color="primary"
            onClick={downloadCertificate}
          >
            certificate.pem.crt
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={downloadPrivateKey}
          >
            private.pem.key
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={downloadPublicKey}
          >
            public.pem.key
          </Button>
        </div>
      </div>
    </TitanDialog>
  );
}
