import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES, SORT_ORDERS } from '../../constants';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import FusionPlanService from '../../services/FusionPlanService';
import FusionPlanFavoriteButton from '../FusionPlan/FusionPlanFavoriteButton';
import FusionPlanLink from '../FusionPlan/FusionPlanLink';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';

export default function DashboardFusionPlans({ params }) {
  const history = useHistory();
  const location = useLocation();

  const loadData = React.useCallback(async (query, config) => {
    const queryParams = {
      page: 1,
      pageSize: 3,
      ...params
    };

    const { data, pagination } = await FusionPlanService.getFusionPlansV2(
      queryParams,
      config
    );

    return {
      data,
      page: pagination.page - 1,
      totalCount: pagination.totalCount
    };
  }, []);

  const titanDataGridProps = useTitanDataGrid(loadData, {
    orders: {
      created_at: SORT_ORDERS.DESC
    }
  });

  const { setRows } = titanDataGridProps;

  const updateFusionPlan = React.useCallback(
    fusionPlan => {
      setRows(prev =>
        prev.map(fp => {
          if (fp.id === fusionPlan.id) {
            return {
              ...fp,
              ...fusionPlan
            };
          }

          return fp;
        })
      );
    },
    [setRows]
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        sortable: false,
        flex: 1,
        renderCell: ({ row, colDef }) => (
          <FusionPlanLink fusionPlan={row} width={colDef.computedWidth} />
        ),
        onCellClick: ({ row }) => {
          if (row) {
            history.push({
              pathname: ROUTES.FUSION_PLAN_V2(row.id),
              state: { from: location.pathname }
            });
          }
        }
      },
      {
        headerName: 'Uploaded',
        field: 'uploaded',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />
      },
      {
        headerName: '',
        field: 'favorite',
        width: 60,
        sortable: false,
        renderCell: ({ row }) => (
          <FusionPlanFavoriteButton
            fusionPlan={row}
            onChange={fusionPlan => updateFusionPlan(fusionPlan)}
          />
        )
      }
    ],
    []
  );

  return (
    <TitanDataGrid
      {...titanDataGridProps}
      showSearch={false}
      pagination={false}
      hideFooter
      columns={columns}
    />
  );
}
