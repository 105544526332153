import React from 'react';
import PrinterSeriesService from '../../services/PrinterSeriesService';
import PrinterSeriesDialog from './PrinterSeriesDialog';
import ActionsMenu from '../Menu/ActionsMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import { useTitan } from '../Titan/Titan';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import { useParams } from 'react-router-dom';

export default function PrinterSeriesManageList() {
  const [openPrinterSeriesDialog, setOpenPrinterSeriesDialog] = React.useState(
    false
  );
  const [dialogMode, setDialogMode] = React.useState('create');
  const [editablePrinterSeries, setEditablePrinterSeries] = React.useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const { pushSnackbar } = useTitan();

  const { page: pageParam = 0 } = useParams();

  const loadData = React.useCallback(async (query, config) => {
    return PrinterSeriesService.getPrinterSeries(query, config);
  }, []);

  const gridOption = {};

  if (pageParam && Number(pageParam) > 0) {
    gridOption.page = Number(pageParam);
  }

  const titanDataGridProps = useTitanDataGrid(loadData, gridOption);

  const { page, rows, setRows } = titanDataGridProps;

  const onSavePrinterSeries = React.useCallback(
    printerSeries => {
      if (dialogMode === 'create') {
        setRows(prev => [printerSeries, ...prev]);
      } else {
        setRows(prev =>
          prev.map(ps =>
            ps.id === printerSeries.id ? { ...ps, ...printerSeries } : ps
          )
        );
      }
    },
    [rows, editablePrinterSeries, dialogMode]
  );

  const onCloseDialog = () => {
    setOpenPrinterSeriesDialog(false);
  };

  const onDelete = async () => {
    await PrinterSeriesService.deletePrinterSeries(editablePrinterSeries.id);

    pushSnackbar('Printer series successfully deleted', { variant: 'success' });
    setOpenDeleteDialog(false);
    setRows(prev => prev.filter(ps => ps.id !== editablePrinterSeries.id));
  };

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        flex: 1,
        sortable: false
      },
      {
        headerName: 'Created',
        field: 'created',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />
      },
      {
        headerName: '',
        field: 'actions',
        width: 60,
        sortable: false,
        renderCell: ({ row }) => (
          <ActionsMenu
            items={[
              {
                title: 'Edit',
                icon: EditIcon,
                onClick: () => {
                  setEditablePrinterSeries(row);
                  setDialogMode('edit');
                  setOpenPrinterSeriesDialog(true);
                }
              },
              {
                title: 'Delete',
                icon: DeleteIcon,
                onClick: () => {
                  setEditablePrinterSeries(row);
                  setOpenDeleteDialog(true);
                }
              }
            ]}
          />
        )
      }
    ],
    []
  );

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        title="Printer series"
        columns={columns}
        onCreateClick={() => {
          setEditablePrinterSeries({});
          setDialogMode('create');
          setOpenPrinterSeriesDialog(true);
        }}
        createButtonLabel="Printer Series"
      />

      {openPrinterSeriesDialog && (
        <PrinterSeriesDialog
          printerSeries={editablePrinterSeries}
          mode={dialogMode}
          onClose={() => onCloseDialog()}
          onSave={onSavePrinterSeries}
        />
      )}

      {openDeleteDialog && (
        <TitanConfirmationDialog
          title="Delete printer series?"
          message={`Are you sure you want to delete printer series ${
            editablePrinterSeries.name
          }?`}
          onClose={() => setOpenDeleteDialog(false)}
          onConfirm={() => onDelete()}
        />
      )}
    </>
  );
}
