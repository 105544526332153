import React from 'react';
import OdooService from '../../services/OdooService';
import TitanDialog from '../Titan/TitanDialog';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import { Box } from '@mui/material';
import useAsyncEffect from 'use-async-effect';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TitanCircularProgress from '../Titan/TitanCircularProgress';

export default function OdooSyncManufacturingOrderDialog({
  manufacturingOrderId,
  onClose
}) {
  const [loading, setLoading] = React.useState(true);
  const [syncData, setSyncData] = React.useState();

  const onSync = React.useCallback(async () => {
    return OdooService.syncManufacturingOrderToOdoo(manufacturingOrderId);
  }, []);

  const loadData = React.useCallback(
    async (params, config) => {
      const data = await OdooService.getExportManufacturingOrderToOdooData(
        manufacturingOrderId,
        {},
        config
      );

      return {
        data: syncData ? data.manufacturingOrderSpools : [],
        page: 0,
        totalCount: syncData ? data.manufacturingOrderSpools.length : 0
      };
    },
    [syncData]
  );

  const showSyncSpools = false;

  const titanDataGridProps = useTitanDataGrid(loadData);

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'spoolIdentifier',
        sortable: false,
        flex: 1
      },
      {
        headerName: 'Material type',
        field: 'materialType',
        sortable: false,
        minWidth: 200
      },
      {
        headerName: 'Actual fP usage',
        field: 'actualMaterialUsage',
        sortable: false,
        minWidth: 200,
        cellClassName: params => {
          return params.row.odooActualMaterialUsage !==
            params.row.actualMaterialUsage
            ? 'cell-diff'
            : '';
        }
      },
      {
        headerName: 'Actual Odoo usage',
        field: 'odooActualMaterialUsage',
        sortable: false,
        minWidth: 200,
        cellClassName: params => {
          return params.row.odooActualMaterialUsage !==
            params.row.actualMaterialUsage
            ? 'cell-diff'
            : '';
        }
      }
    ],
    []
  );

  useAsyncEffect(async () => {
    setLoading(true);
    const syncData = await OdooService.getExportManufacturingOrderToOdooData(
      manufacturingOrderId,
      {}
    );

    setSyncData(syncData);
    setLoading(false);
  }, []);

  return (
    <TitanDialog
      title="Sync Manufacturing Order with Odoo"
      maxWidth="xs"
      onClose={onClose}
      onSave={onSync}
      cancelButtonLabel="Close"
      saveButtonLabel="Sync to Odoo"
    >
      <Box
        sx={{
          '& .cell-diff': {
            color: '#97110C',
            fontWeight: 'bold'
          }
        }}
      >
        {loading ? (
          <TitanCircularProgress />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle2">fP produced</Typography>
            </Grid>
            <Grid item xs={8}>
              {syncData && syncData.manufacturingOrderBuiltPreforms
                ? syncData.manufacturingOrderBuiltPreforms.length
                : '-'}
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">Odoo produced</Typography>
            </Grid>
            <Grid item xs={8}>
              {syncData && syncData.odooManufacturingOrder
                ? syncData.odooManufacturingOrder.qty_producing
                : '-'}
            </Grid>
          </Grid>
        )}

        {showSyncSpools ? (
          <TitanDataGrid
            showSearch={false}
            {...titanDataGridProps}
            columns={columns}
            hideFooter
          />
        ) : (
          ''
        )}
      </Box>
    </TitanDialog>
  );
}
