import React from 'react';
import TitanPage from '../Titan/TitanPage';
import Typography from '@mui/material/Typography';

function GuestHomePage() {
  return (
    <TitanPage title={'Welcome to Fibrify Production'}>
      <Typography>
        Please contact your Administrator to request an Application Engineer or
        Operator role
      </Typography>
    </TitanPage>
  );
}

export default GuestHomePage;
