import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useTitan } from '../Titan/Titan';
import { Box } from '@mui/material';
import TitanDialog from '../Titan/TitanDialog';

export default function PrinterAccessKeyDialog(props) {
  const { onClose, printerAccessKey, printer } = props;
  const { copyValueToClipboard } = useTitan();

  const config = `PRINTER_SERIAL_NUMBER=${printer.name}
    PRINTER_ID = ${printer.id}
    PRINTER_AWS_ACCESS_KEY_ID=${printerAccessKey.accessKeyId}
    PRINTER_AWS_SECRET_ACCESS_KEY=${printerAccessKey.secretAccessKey}
    PRINTER_MQTT_IOT_ENDPOINT=${printerAccessKey.mqttIoTEndpoint}
    OCTOPRINT_ADMIN_USERNAME=admin 
    OCTOPRINT_ADMIN_PASSWORD=admin
    OCTOPRINT_PORT=5000
    PRINTER_SERIAL_PORT=/tmp/printer`;

  const configMarkdown = `**PRINTER_SERIAL_NUMBER**=${printer.name}
    \n**PRINTER_ID**=${printer.id}
    \n**PRINTER_AWS_ACCESS_KEY_ID**=${printerAccessKey.accessKeyId}
    \n**PRINTER_AWS_SECRET_ACCESS_KEY**=${printerAccessKey.secretAccessKey}
    \n**PRINTER_MQTT_IOT_ENDPOINT**=${printerAccessKey.mqttIoTEndpoint}
    \n**OCTOPRINT_ADMIN_USERNAME**=admin
    \n**OCTOPRINT_ADMIN_PASSWORD**=admin
    \n**OCTOPRINT_PORT**=5000
    \n**PRINTER_SERIAL_PORT**=/tmp/printer`;

  return (
    <TitanDialog
      onClose={onClose}
      maxWidth="md"
      title="Octoprint config file"
      cancelButtonLabel="Close"
      onAdditionalButton={() => {
        const clipboardConfig = config.replace(/ /g, '');
        copyValueToClipboard(clipboardConfig);
      }}
      additionalButtonLabel="Copy Config"
    >
      <Box p={2}>
        <ReactMarkdown children={configMarkdown} />
      </Box>
    </TitanDialog>
  );
}
