import React from 'react';
import useWebSocket from 'react-use-websocket';
import { WEB_SOCKET_ACTIONS } from '../../constants';
import useAsyncEffect from 'use-async-effect';
import { useTitan } from '../Titan/Titan';
import BuildJobService from '../../services/BuildJobService';

export const updateBuildJobData = (buildJob, newBuildJobData) => {
  const updatedBuildJob = { ...buildJob };

  if (newBuildJobData.statusUpdatedAt) {
    updatedBuildJob.statusUpdatedAt = newBuildJobData.statusUpdatedAt;
  }

  if (newBuildJobData.state) {
    updatedBuildJob.status = newBuildJobData.state;
  } else if (newBuildJobData.status) {
    updatedBuildJob.status = newBuildJobData.status;
  }

  if (newBuildJobData.progress) {
    updatedBuildJob.progress = newBuildJobData.progress;
  }

  if (newBuildJobData.startBuildTime) {
    updatedBuildJob.startBuildTime = newBuildJobData.startBuildTime;
  }

  if (newBuildJobData.endBuildTime) {
    updatedBuildJob.endBuildTime = newBuildJobData.endBuildTime;
  }

  if (newBuildJobData.buildTime) {
    updatedBuildJob.buildTime = newBuildJobData.buildTime;
  }

  if (newBuildJobData.deviceVersion) {
    updatedBuildJob.deviceVersion = newBuildJobData.deviceVersion;
  }

  if (newBuildJobData.manufacturingOrderId) {
    updatedBuildJob.manufacturingOrderId = newBuildJobData.manufacturingOrderId;
  }

  if (newBuildJobData.manufacturingOrder) {
    updatedBuildJob.manufacturingOrder = newBuildJobData.manufacturingOrder;

    if (
      !newBuildJobData.manufacturingOrderId &&
      newBuildJobData.manufacturingOrder.id
    ) {
      updatedBuildJob.manufacturingOrderId =
        newBuildJobData.manufacturingOrder.id;
    }
  }

  return updatedBuildJob;
};

export default function useBuildJobs(
  loadBuildJobs,
  filterBuildJobs,
  allowNewJobs = true
) {
  const { getWebSocketUrl, getWebSocketOptions } = useTitan();

  const [loading, setLoading] = React.useState(true);
  const [buildJobs, setBuildJobs] = React.useState([]);

  useAsyncEffect(async () => {
    setLoading(true);

    const buildJobs = await loadBuildJobs();

    setBuildJobs(buildJobs);
    setLoading(false);
  }, [loadBuildJobs]);

  const onChangeBuildJob = React.useCallback(
    newBuildJobData => {
      const newBuildJobId = newBuildJobData.buildJobId || newBuildJobData.id;

      setBuildJobs(prev => {
        let updatedBuildJobs = prev.map(buildJob => {
          if (buildJob.id === newBuildJobId) {
            return updateBuildJobData(buildJob, {
              id: newBuildJobId,
              ...newBuildJobData
            });
          }

          return buildJob;
        });

        const buildJobToUpdate = prev.find(bj => bj.id === newBuildJobId);

        if (!buildJobToUpdate && allowNewJobs) {
          updatedBuildJobs = [
            { id: newBuildJobId, ...newBuildJobData, isNewBuildJob: true },
            ...updatedBuildJobs
          ];
        }

        if (filterBuildJobs) {
          updatedBuildJobs = filterBuildJobs(updatedBuildJobs);
        }

        return updatedBuildJobs;
      });
    },
    [filterBuildJobs]
  );

  const updateBuildJob = React.useCallback(
    buildJobToUpdate => {
      setBuildJobs(prev => {
        let updatedBuildJobs = prev.map(buildJob => {
          if (buildJob.id === buildJobToUpdate.id) {
            return updateBuildJobData(buildJob, buildJobToUpdate);
          }

          return buildJob;
        });

        if (filterBuildJobs) {
          updatedBuildJobs = filterBuildJobs(updatedBuildJobs);
        }

        return updatedBuildJobs;
      });
    },
    [filterBuildJobs]
  );

  const { lastJsonMessage } = useWebSocket(
    getWebSocketUrl,
    getWebSocketOptions()
  );

  useAsyncEffect(async () => {
    if (
      lastJsonMessage !== null &&
      [
        WEB_SOCKET_ACTIONS.BUILD_JOB,
        WEB_SOCKET_ACTIONS.BUILD_JOB_STATE
      ].includes(lastJsonMessage.action) &&
      lastJsonMessage.data
    ) {
      onChangeBuildJob(lastJsonMessage.data);
    }
  }, [lastJsonMessage, onChangeBuildJob]);

  useAsyncEffect(async () => {
    const newBuildJobs = buildJobs.filter(bj => bj.isNewBuildJob);

    if (newBuildJobs.length !== 0) {
      const newBuildJobsData = await Promise.all(
        newBuildJobs.map(async buildJob =>
          BuildJobService.getBuildJob(
            buildJob.id,
            {
              withRelated: [
                'preforms',
                'preforms.preformType',
                'buildModule',
                'buildPlan',
                'creator'
              ]
            },
            'v2'
          )
        )
      );

      setBuildJobs(prev => {
        return prev.map(bj => {
          const newBuildJob = newBuildJobsData.find(
            newBuildJobData => newBuildJobData.id === bj.id
          );

          if (newBuildJob) {
            return {
              ...bj,
              ...newBuildJob,
              isNewBuildJob: false
            };
          }

          return bj;
        });
      });
    }
  }, [buildJobs]);

  return {
    loading,
    buildJobs,
    setBuildJobs,
    updateBuildJob
  };
}
