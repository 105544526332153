import React from 'react';
import { useTitan } from '../Titan/Titan';
// import NotificationService from '../../services/NotificationService';

export default function NotificationSocketHandlers() {
  const { pushSnackbar } = useTitan();

  // TODO refactor notifications
  // React.useEffect(() => {
  //   const listener = notification => {
  //     pushSnackbar(NotificationService.getNotificationText(notification), {
  //       variant: NotificationService.getNotificationVariant(notification)
  //     });
  //   };
  //
  //   WebSocketService.registerListener(`notification`, listener);
  //
  //   return () => {
  //     WebSocketService.removeListener(`notification`, listener);
  //   };
  // }, []);

  return <></>;
}
