import React from 'react';
import { useAuth0 } from '../../Authentication/Auth0';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { format } from 'date-fns';
import { ROLES } from '../../../constants';
import { ITEMS_TYPES } from './OdooImportManufacturingOrderDialog';
import useFibrifyForm from '../../Fibrify/hooks/useFibrifyForm';

export default function ReviewManufacturingOrderDialog({
  name,
  setName,
  preformsCount,
  setPreformsCount,
  finalPartsCount,
  setFinalPartsCount,
  dates,
  setDates,
  countItemsType,
  setCountItemsType,
  setFormValid,
}) {
  const { onInputWheel } = useFibrifyForm();
  const { profile } = useAuth0();

  React.useEffect(() => {
    const organizationAdmin = profile.roles.find((r) => r.id === ROLES.ADMIN);

    const numberOfItems =
      countItemsType === ITEMS_TYPES.PREFORMS
        ? preformsCount !== ''
        : finalPartsCount !== '';

    if (name && numberOfItems && organizationAdmin) {
      setFormValid(true);
      return;
    }

    setFormValid(false);
  }, [
    profile,
    name,
    preformsCount,
    finalPartsCount,
    countItemsType,
    setFormValid,
  ]);

  return (
    <Box>
      <TextField
        label={`Name of Manufacturing Order ${
          countItemsType === ITEMS_TYPES.PREFORMS
            ? 'for Preforms'
            : 'for Final Parts'
        }`}
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <RadioGroup
        row
        value={countItemsType}
        onChange={(e) => setCountItemsType(e.target.value)}
      >
        <FormControlLabel
          value={ITEMS_TYPES.PREFORMS}
          control={<Radio />}
          label="Preforms"
        />
        <FormControlLabel
          value={ITEMS_TYPES.FINAL_PARTS}
          control={<Radio />}
          label="Final Parts"
        />
      </RadioGroup>
      {countItemsType === ITEMS_TYPES.PREFORMS ? (
        <TextField
          label="Number of Preforms"
          type="number"
          value={preformsCount}
          onChange={(e) =>
            setPreformsCount(
              Math.abs(e.target.value) > 0 ? Math.abs(e.target.value) : '',
            )
          }
          required
          fullWidth
          margin="normal"
          variant="outlined"
          onWheel={onInputWheel}
        />
      ) : (
        <TextField
          label="Number of Final Parts"
          type="number"
          value={finalPartsCount}
          onChange={(e) =>
            setFinalPartsCount(
              Math.abs(e.target.value) > 0 ? Math.abs(e.target.value) : '',
            )
          }
          required
          fullWidth
          margin="normal"
          variant="outlined"
          onWheel={onInputWheel}
        />
      )}
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={{ start: 'Start', end: 'Finish' }}
      >
        <Box sx={{ mt: 2 }}>
          <DateRangePicker
            value={dates}
            inputFormat="yyyy-MM-dd"
            onChange={(newValue) => {
              const formattedDates = newValue.map((date) =>
                date ? format(date, 'yyyy-MM-dd') : null,
              );
              setDates(formattedDates);
            }}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </React.Fragment>
            )}
          />
        </Box>
      </LocalizationProvider>
    </Box>
  );
}
