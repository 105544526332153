import axios from 'axios';

export default class ProjectService {
  static getProjects(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v2/projects`, {
        ...config,
        params
      })
      .then(res => res.data);
  }

  static getProjectById(projectId, params = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v2/projects/${projectId}`, {
        params
      })
      .then(res => res.data);
  }

  static createProject(data) {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/v2/projects`, data)
      .then(res => res.data.data);
  }

  static updateProject(id, data) {
    return axios
      .patch(`${process.env.REACT_APP_API_URL}/api/v2/projects/${id}`, data)
      .then(res => res.data.data);
  }

  static deleteProject(id) {
    return axios
      .delete(`${process.env.REACT_APP_API_URL}/api/v2/projects/${id}`)
      .then(res => res.data);
  }
}
