import React from 'react';

import TitanDialog from '../Titan/TitanDialog';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import MouldService from '../../services/MouldService';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';

export default function SelectMouldsDialog({
  onSelectMould,
  onClose,
  multiSelect = false,
  title = 'Select Mould',
  moulds
}) {
  const [selectedMoulds, setSelectedMoulds] = React.useState(
    moulds ? moulds : []
  );

  const loadMoulds = React.useCallback(async (params, config) => {
    const { data, pagination } = await MouldService.getMoulds(params, config);

    return {
      data,
      page: pagination.page - 1,
      totalCount: pagination.totalCount
    };
  }, []);

  const mouldsDataGridProps = useTitanDataGrid(loadMoulds);

  const selectMouldHandler = React.useCallback(
    newSelectedMoulds => {
      setSelectedMoulds(newSelectedMoulds);
    },
    [setSelectedMoulds]
  );

  const handleSave = React.useCallback(
    () =>
      onSelectMould(
        multiSelect ? selectedMoulds.map(m => m.id) : selectedMoulds[0].id
      ),
    [selectedMoulds, multiSelect]
  );

  const mouldColumns = React.useMemo(
    () => [
      {
        headerName: 'ID',
        field: 'mouldIdentifier',
        minWidth: 200,
        sortable: false
      },
      {
        headerName: 'Comment',
        field: 'comment',
        flex: 1,
        sortable: false
      }
    ],
    []
  );

  return (
    <TitanDialog
      open={true}
      maxWidth="lg"
      title={title}
      onClose={onClose}
      onSave={handleSave}
      saveButtonLabel="Save"
      isValid={selectedMoulds.length !== 0}
    >
      <TitanDataGrid
        {...mouldsDataGridProps}
        searchPlaceholder={'Search by mould identifier or comment'}
        columns={mouldColumns}
        onSelectionModelChange={selectMouldHandler}
        selectedRows={selectedMoulds}
        checkboxSelection={!multiSelect}
        checkboxMultiSelection={multiSelect}
        selectionChipsNameKey="mouldIdentifier"
      />
    </TitanDialog>
  );
}
