import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Chip } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../constants';
import PlanService from '../../services/PlanService';
import { makeStyles } from '@mui/styles';
import OverflowTip from '../ExpandRenderCell/OverflowTip';
import { useTitan } from '../Titan/Titan';

const useStyles = makeStyles(theme => ({
  linkBox: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center'
  },
  hiddenOverflowBlock: {
    overflow: 'hidden'
  },
  chip: {
    fontSize: '0.7rem',
    marginLeft: '8px'
  }
}));

export default function BuildPlanLink(props) {
  const {
    buildPlan,
    children,
    useName = true,
    width,
    onClick = e => e.stopPropagation(),
    buildPlanId,
    sx = {}
  } = props;
  const location = useLocation();
  const classes = useStyles();

  const { addPageToPageHistory, preparePageObjectForLink } = useTitan();

  const handleClick = e => {
    addPageToPageHistory(
      preparePageObjectForLink(
        'BUILD_PLAN',
        buildPlan.id,
        buildPlan.name,
        buildPlan.id === buildPlanId
          ? ROUTES.BUILD_PLAN_V2_TAB(buildPlan.id, 'configuration')
          : ROUTES.BUILD_PLAN_V2(buildPlan.id),
        'Configuration'
      )
    );

    if (onClick) {
      onClick(e);
    }
  };

  let content;

  if (useName) {
    content = [buildPlan.name];

    content.push(
      PlanService.formatPlanKey(buildPlan.planKey, buildPlan.version)
    );

    content = content.join(' ');
  } else {
    content = children;
  }

  if (buildPlan.hasReadAccess === false) {
    return <span>{content}</span>;
  }

  return (
    <Box className={classes.linkBox}>
      <Link
        className={classes.hiddenOverflowBlock}
        onClick={handleClick}
        to={{
          pathname:
            buildPlanId === buildPlan.id
              ? ROUTES.BUILD_PLAN_V2_TAB(buildPlan.id, 'configuration')
              : ROUTES.BUILD_PLAN_V2(buildPlan.id),
          state: { from: location.pathname }
        }}
        component={RouterLink}
        sx={sx}
        data-pw={`build-plan-link-${buildPlan.id}`}
      >
        {width ? (
          <OverflowTip width={width}>{content}</OverflowTip>
        ) : (
          <span data-pw="build-plan-link-text">{content}</span>
        )}
      </Link>
      {buildPlan?.deletedAt && (
        <Chip
          label="DELETED"
          color="primary"
          variant="outlined"
          className={classes.chip}
        />
      )}
    </Box>
  );
}
