import React from 'react';
import useAsyncEffect from 'use-async-effect';
import { useHistory, useParams } from 'react-router-dom';
import { useTitan } from '../Titan/Titan';
import TitanPage from '../Titan/TitanPage';
import TitanTabs from '../Titan/TitanTabs';
import { IconButton, Button } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import SpoolService from '../../services/SpoolService';
import SpoolDialog from './SpoolDialog';
import SpoolSummary from './SpoolSummary';
import { ROUTES } from '../../constants';
import BuildJobsTable from '../BuildJob/BuildJobsTable';
import SpoolBuildModulesHistory from './SpoolBuildModulesHistory';
import FibrifyBuildModuleSelectionDialog from '../Fibrify/FibrifyBuildModuleSelectionDialog';
import BuildModuleService from '../../services/BuildModuleService';
import BuildIcon from '@mui/icons-material/Build';

export default function SpoolPage() {
  const { spoolId, tab = 'summary' } = useParams();
  const history = useHistory();

  const { addPageToPageHistory, pushSnackbar } = useTitan();

  const [spool, setSpool] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const [openEditDialog, setOpenEditDialog] = React.useState(false);

  const [
    openSelectBuildModuleDialog,
    setOpenSelectBuildModuleDialog
  ] = React.useState(false);

  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Spools',
        path: ROUTES.GEAR_TAB('spools')
      },
      {
        name: spool?.spoolIdentifier ? spool.spoolIdentifier : 'Spool',
        disabled: true
      }
    ],
    [spool]
  );

  useAsyncEffect(async () => {
    setLoading(true);
    const { data: spool } = await SpoolService.getSpool(spoolId, {
      withRelated: ['materialType', 'tags']
    });

    setSpool(spool);
    setLoading(false);
  }, [spoolId, setSpool, setLoading]);

  const onUpdateSpool = React.useCallback(
    async data => {
      const {
        spoolIdentifierExists
      } = await SpoolService.spoolIdentifierExists({
        spoolIdentifier: data.spoolIdentifier,
        id: spool.id
      });

      if (spoolIdentifierExists) {
        throw new Error(
          'Spool with the same identifier already exists within the organization'
        );
      }

      const updatedSpool = await SpoolService.updateSpool(spool.id, data);

      setSpool(updatedSpool);
    },
    [spool]
  );

  const preparePageObject = React.useCallback(
    (tab, tabForURL) => {
      return {
        id: `SPOOL:${spoolId}`,
        url: ROUTES.SPOOL(spoolId, tabForURL),
        label: `${spool.name} | ${tab}`
      };
    },
    [spool]
  );

  const tabs = React.useMemo(
    () => [
      {
        label: 'Summary',
        value: 'summary',
        content: <SpoolSummary spool={spool} />
      },
      {
        label: 'Build Modules',
        value: 'build-modules',
        content: <SpoolBuildModulesHistory spool={spool} />
      },
      {
        label: 'Build Jobs',
        value: 'build-jobs',
        content: <BuildJobsTable spoolId={spool.id} />
      }
    ],
    [spool]
  );

  const onChangeTab = React.useCallback(
    value => {
      const activeLabel = value.charAt(0).toUpperCase() + value.slice(1);
      addPageToPageHistory(preparePageObject(activeLabel, value));
      history.push(ROUTES.SPOOL_TAB(spoolId, value));
    },
    [history, addPageToPageHistory, preparePageObject, spoolId]
  );

  const loadSpoolToBuildModule = React.useCallback(
    async buildModule => {
      await BuildModuleService.loadSpoolToBuildModule(buildModule.id, spoolId);

      if (tab === 'build-modules') {
        setSpool(prev => ({ ...prev }));
      }

      pushSnackbar(
        `Spool ${spool.spoolIdentifier} loaded to ${buildModule.name}`,
        {
          variant: 'success'
        }
      );
    },
    [spool, spoolId, pushSnackbar]
  );

  return (
    <TitanPage
      loading={loading}
      breadcrumbs={breadcrumbs}
      title={spool?.spoolIdentifier || 'Spool'}
      titleContent={
        !loading &&
        !spool?.deletedAt && (
          <IconButton size="large" onClick={() => setOpenEditDialog(true)}>
            <CreateIcon />
          </IconButton>
        )
      }
      headerContent={
        <Button
          variant="contained"
          onClick={() => setOpenSelectBuildModuleDialog(true)}
          startIcon={<BuildIcon />}
        >
          Load to BM
        </Button>
      }
    >
      <TitanTabs tabs={tabs} activeTab={tab} onChangeTab={onChangeTab} />

      {openEditDialog && (
        <SpoolDialog
          onClose={() => setOpenEditDialog(false)}
          editableSpool={spool}
          onSave={onUpdateSpool}
        />
      )}

      {openSelectBuildModuleDialog && (
        <FibrifyBuildModuleSelectionDialog
          title="Select build module to load spool"
          onClose={() => setOpenSelectBuildModuleDialog(false)}
          onSave={loadSpoolToBuildModule}
          saveButtonLabel="Load"
        />
      )}
    </TitanPage>
  );
}
