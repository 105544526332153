import React from 'react';
import { makeStyles } from '@mui/styles';
import { colors } from '../Theme/vars';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TitanInfoItem from '../Titan/TitanInfoItem';

const heaters = {
  tool0: { label: 'Fiber Guide', maxTemperature: 450 },
  tool1: { label: 'Polymer Nozzle', maxTemperature: 450 },
  bed: { label: 'Bed', maxTemperature: 200 },
  chamber: { label: 'Build Chamber', maxTemperature: 85 },
  materialChamber: { label: 'Material Chamber', maxTemperature: 85 },
  rollforming: { label: 'Rollforming', maxTemperature: 450 }
};

const useStyles = makeStyles(theme => ({
  container: {
    padding: [0, theme.spacing(2)]
  },
  temperature: {
    width: '100%',
    borderBottom: `1px solid ${colors.GREY2}`,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      '&:last-child': {
        borderBottom: 'none'
      },

      '&:first-child': {
        paddingTop: 0
      }
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      '&:nth-child(even)': {
        borderLeft: `1px solid ${colors.GREY2}`
      }
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(0),
      '&:nth-child(even)': {
        borderLeft: `none`
      },
      '&:last-child': {
        borderBottom: 'none'
      },
      '&:first-child': {
        paddingTop: 0
      }
    }
  }
}));

export default function BuildModuleTemperatureControls({ temperature = {} }) {
  const classes = useStyles();

  return (
    <Grid sx={{ pl: 2, pr: 2, pt: 1 }} container>
      {Object.keys(heaters).map(heater => (
        <Grid
          className={classes.temperature}
          item
          xs={12}
          sm={6}
          md={12}
          lg={12}
          key={heater}
        >
          <Typography variant="subtitle1">{heaters[heater].label}</Typography>

          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between'
            }}
          >
            <TitanInfoItem label="Actual" styleOfItem="vertical">
              {temperature[heater] && temperature[heater].actual
                ? `${temperature[heater].actual}°C`
                : '-'}
            </TitanInfoItem>

            <TitanInfoItem label="Target" styleOfItem="vertical">
              {temperature[heater] && temperature[heater].target
                ? `${temperature[heater].target}°C`
                : '-'}
            </TitanInfoItem>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
