import React from 'react';
import _ from 'lodash';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import renderCellExpand from '../ExpandRenderCell/ExpandRenderCell';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import MaterialTypeService from '../../services/MaterialTypeService';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { CustomGridColumnsPanel } from '../TitanDataGrid/TitanDataGridColumnsPanel';
import { useTitan } from '../Titan/Titan';
import { useAuth0 } from '../Authentication/Auth0';
import { PERMISSIONS } from '../../constants/auth0';
import { ROLES } from '../../constants';
import MaterialDialog from './MaterialDialog';

export default function MaterialsTable() {
  const { pushSnackbar } = useTitan();

  const { permissions, checkPermissions, roles } = useAuth0();

  const canManageMaterials = checkPermissions(permissions, [
    PERMISSIONS.MANAGE_MATERIAL_TYPES,
  ]);

  const [openMaterialTypeDialog, setOpenMaterialTypeDialog] =
    React.useState(false);
  const [editableMaterialType, setEditableMaterialType] = React.useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const loadData = React.useCallback(async (query, config) => {
    const { data } = await MaterialTypeService.getMaterialTypes(
      {
        page: 0,
        pageSize: 1000,
        order: ['created_at:desc'],
      },
      config,
    );

    return {
      data,
    };
  }, []);

  const columns = React.useMemo(() => {
    const columns = [
      {
        headerName: 'Name',
        field: 'name',
        pinnable: true,
        hideable: false,
        visibilityBreakpoint: 'sm',
        flex: 1,
        minWidth: 180,
        sortable: false,
        renderCell: ({ row, colDef }) =>
          renderCellExpand(row.name, colDef.computedWidth),
      },
      {
        headerName: 'Type',
        field: 'type',
        visibilityBreakpoint: 'sm',
        minWidth: 200,
        sortable: false,
        valueGetter: ({ row }) =>
          _.upperFirst(row.type.replace('PLASTIC', 'POLYMER').toLowerCase()),
      },
      {
        headerName: 'Min temperature °C',
        field: 'minTemperature',
        visibilityBreakpoint: 'lg',
        minWidth: 200,
        sortable: false,
      },
      {
        headerName: 'Max temperature °C',
        field: 'maxTemperature',
        visibilityBreakpoint: 'lg',
        minWidth: 200,
        sortable: false,
      },
      {
        headerName: 'Load temperature °C',
        field: 'loadTemperature',
        visibilityBreakpoint: 'lg',
        minWidth: 200,
        sortable: false,
      },
      {
        headerName: 'Avg linear density g/m',
        field: 'averageLinearDensity',
        visibilityBreakpoint: 'lg',
        minWidth: 200,
        sortable: false,
      },
    ];

    if (
      roles.map((r) => r.id).includes(ROLES.ADMIN) ||
      roles.map((r) => r.id).includes(ROLES.APPLICATION_ENGINEER)
    ) {
      columns.push({
        headerName: '',
        width: 60,
        field: 'actions',
        type: 'actions',
        getActions: (params) =>
          [
            {
              label: 'Edit',
              icon: <EditIcon />,
              disabled: !canManageMaterials,
              onClick: () => {
                setEditableMaterialType(params.row);
                setOpenMaterialTypeDialog(true);
              },
            },
            {
              label: 'Delete',
              icon: <DeleteIcon />,
              disabled: !canManageMaterials,
              onClick: () => {
                setEditableMaterialType(params.row);
                setOpenDeleteDialog(true);
              },
            },
          ].map((action) => (
            <GridActionsCellItem
              icon={action.icon}
              label={action.label}
              onClick={action.onClick}
              disabled={action.disabled}
              showInMenu
            />
          )),
      });
    }

    return columns;
  }, [canManageMaterials]);

  const titanDataGridProps = useTitanDataGrid(loadData, {
    columns,
    pinnedColumns: {
      left: ['name'],
      right: ['actions'],
    },
  });

  const { setRows } = titanDataGridProps;

  const onCloseDialog = async () => {
    setOpenMaterialTypeDialog(false);
    setEditableMaterialType(null);
  };

  const onSave = async (data) => {
    if (editableMaterialType) {
      const updatedMaterialType = await MaterialTypeService.updateMaterialType(
        editableMaterialType.id,
        data,
      );

      setRows((prev) =>
        prev.map((mt) =>
          mt.id === editableMaterialType.id ? updatedMaterialType : mt,
        ),
      );
    } else {
      const newMaterialType = await MaterialTypeService.createMaterialType(
        data,
      );

      setRows((prev) => [newMaterialType, ...prev]);
    }

    pushSnackbar(
      `Material successfully ${editableMaterialType ? 'updated' : 'created'}`,
      { variant: 'success' },
    );
    setOpenDeleteDialog(false);
    setEditableMaterialType(null);
  };

  const onDelete = async () => {
    await MaterialTypeService.deleteMaterialType(editableMaterialType.id);

    pushSnackbar('Material successfully deleted', { variant: 'success' });
    setOpenDeleteDialog(false);
    setRows((prev) => prev.filter((mt) => mt.id !== editableMaterialType.id));
  };

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        components={{
          Toolbar: TitanDataGridToolbar,
          ColumnsPanel: CustomGridColumnsPanel,
        }}
        searchPlaceholder="Search by material name"
        showSearch={false}
        pagination={false}
        onCreateClick={() => setOpenMaterialTypeDialog(true)}
        createButtonLabel="Material"
        onCreateClickDisabled={!canManageMaterials}
        title="Materials"
      />
      {openMaterialTypeDialog && (
        <MaterialDialog
          onClose={onCloseDialog}
          materialType={editableMaterialType}
          onSave={onSave}
        />
      )}

      {openDeleteDialog && (
        <TitanConfirmationDialog
          title="Delete material?"
          message={`Are you sure you want to delete material ${editableMaterialType.name}?`}
          onClose={() => {
            setOpenDeleteDialog(false);
            setEditableMaterialType(null);
          }}
          onConfirm={onDelete}
        />
      )}
    </>
  );
}
