// colors
export const colors = {
  RED: '#97110C',
  DARK_RED: '#680B08',
  LIGHT_RED: '#E11710',
  RED_TRANSPARENT: 'rgba(151, 17, 12, 0.3)',
  RED2: '#FF0000',
  GREY: '#888888',
  GREY2: '#DFDFDF',
  GREY3: '#121212',
  GREY4: '#9E9E9E',
  WHITE_GREY1: '#F2F2F2',
  WHITE_GREY2: '#EEEEEE',
  WHITE: '#FFFFFF',
  DARK_GREY: '#232323',
  YELLOW: '#F3C13A',
  VIOLET: '#770088',
  STONE: '#393939',
  BLUE: '#0073cf',
  DARK_BLUE: '#221199',
  ORANGE: '#982A17',
  ORANGE2: '#CC8400',
  PINK: '#DFBEBC',
  GREEN: '#0F970C',
  LIME_GREEN: '#32CD32',
  LIGHT_GREEN: '#DAEBD4',
  PEACH: '#FCE5CE',
  PINK2: '#F4CDCD',
  BLACK: '#1A171B',
  BLACK2: '#151316',
  LIGHT_GREY: '#C4C4C4',
  GREY5: '#F5F5F5',
  LIGHT_PINK: '#EACFCE',
  TEAL: '#1DE9B6',
  AMBER: '#FFC400',
  LIGHT_PURPLE: '#DD33FA',
  DEEP_PURPLE: '#651FFF',
  DARK_CYAN: '#00A0B2',
  LIGHT_CYAN: '#33EAFF'
};
