import axios from 'axios';

export default class PreBuildingChecklistService {
  static getChecklists(params = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v2/pre-building-checklists`, {
        params
      })
      .then(res => res.data);
  }

  static getChecklistById(checklistId, params = {}) {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/pre-building-checklists/${checklistId}`,
        {
          params
        }
      )
      .then(res => res.data);
  }

  static createChecklist(data) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v2/pre-building-checklists`,
        data
      )
      .then(res => res.data.data);
  }

  static updateChecklist(id, data) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v2/pre-building-checklists/${id}`,
        data
      )
      .then(res => res.data.data);
  }

  static deleteChecklist(id) {
    return axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/v2/pre-building-checklists//${id}`
      )
      .then(res => res.data);
  }

  static getChecklistSections(params = {}) {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/pre-building-checklists/sections`,
        { params }
      )
      .then(res => res.data);
  }

  static createChecklistSection(data, params = {}) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/pre-building-checklists/sections`,
        data,
        { params }
      )
      .then(res => res.data.data);
  }

  static updateChecklistSection(id, data) {
    return axios
      .patch(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/pre-building-checklists/sections/${id}`,
        data
      )
      .then(res => res.data.data);
  }

  static deleteChecklistSection(id) {
    return axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/pre-building-checklists/sections/${id}`
      )
      .then(res => res.data);
  }

  static getChecklistSectionSteps(params = {}) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v2/pre-building-checklists/steps`,
        { params }
      )
      .then(res => res.data);
  }

  static createChecklistSectionStep(data, params = {}) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v2/pre-building-checklists/steps`,
        data,
        { params }
      )
      .then(res => res.data.data);
  }

  static updateChecklistSectionStep(id, data) {
    return axios
      .patch(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/pre-building-checklists/steps/${id}`,
        data
      )
      .then(res => res.data.data);
  }

  static deleteChecklistSectionStep(id) {
    return axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/pre-building-checklists/steps/${id}`
      )
      .then(res => res.data);
  }
}
