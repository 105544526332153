import axios from 'axios';

export default class SpoolService {
  static async getSpools(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/spools`, {
        ...config,
        params
      })
      .then(res => res.data);
  }

  static async getSpool(spoolId, params = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/spools/${spoolId}`, {
        params
      })
      .then(res => res.data);
  }

  static async getSpoolBuildModuleHistory(spoolId, params = {}) {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/spools/${spoolId}/build-modules`,
        {
          params
        }
      )
      .then(res => res.data);
  }

  static async spoolIdentifierExists(params) {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/spools/spool-identifier-exists`,
        {
          params
        }
      )
      .then(res => res.data);
  }

  static async createSpool(data) {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/spools`, data)
      .then(res => res.data.data);
  }

  static async updateSpool(spoolId, data) {
    return axios
      .patch(`${process.env.REACT_APP_API_URL}/api/v1/spools/${spoolId}`, data)
      .then(res => res.data.data);
  }

  static async deleteSpool(spoolId) {
    return axios
      .delete(`${process.env.REACT_APP_API_URL}/api/v1/spools/${spoolId}`)
      .then(res => res.data);
  }
}
