import COMPONENT_STATUSES from './componentStatuses';

export default [
  COMPONENT_STATUSES.LOAD_TO_BM,
  COMPONENT_STATUSES.LOADED_TO_BM,
  COMPONENT_STATUSES.LOAD_TO_BM_FAILED,
  COMPONENT_STATUSES.UNLOAD_FROM_BM,
  COMPONENT_STATUSES.LOAD_TO_FM,
  COMPONENT_STATUSES.LOADED_TO_FM,
  COMPONENT_STATUSES.LOAD_TO_FM_FAILED,
  COMPONENT_STATUSES.UNLOAD_FROM_FM,
  COMPONENT_STATUSES.PRE_BUILDING,
  COMPONENT_STATUSES.BUILDING,
  COMPONENT_STATUSES.POST_BUILDING,
  COMPONENT_STATUSES.BUILD_PAUSED,
  COMPONENT_STATUSES.BUILD_CANCELING,
  COMPONENT_STATUSES.POST_CANCELED,
  COMPONENT_STATUSES.PRE_FUSING,
  COMPONENT_STATUSES.FUSING,
  COMPONENT_STATUSES.FUSE_PAUSED,
  COMPONENT_STATUSES.FUSE_CANCELING,
  COMPONENT_STATUSES.POST_FUSING,
  COMPONENT_STATUSES.POST_FAILED,
];
