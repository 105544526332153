import React from 'react';
import { Typography } from '@mui/material';

export default function FibrifyMeasurement({
  label = '',
  value = '',
  units = ''
}) {
  return (
    <Typography variant="caption">
      {label}
      <Typography
        variant="body1"
        component="span"
        sx={{ mr: 1, ml: label ? 1 : 0 }}
      >
        {value}
      </Typography>
      {units}
    </Typography>
  );
}
