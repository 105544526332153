import { useAuth0 } from './Auth0';

const Can = props => {
  const { permissions, checkPermissions } = useAuth0();

  return !permissions ||
    permissions.length === 0 ||
    checkPermissions(permissions, props.permissions, props.matchAll)
    ? props.yes()
    : props.no();
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
  matchAll: false
};

export default Can;
