import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import BuildPlanService from '../../services/BuildPlanService';
import { Backdrop, ListItemButton } from '@mui/material';
import BuildPlanLink from '../BuildPlan/BuildPlanLink';
import FusionPlanService from '../../services/FusionPlanService';
import FusionPlanLink from '../FusionPlan/FusionPlanLink';
import PreformService from '../../services/PreformService';
import SpoolService from '../../services/SpoolService';
import FinalPartService from '../../services/FinalPartService';
import PreformLink from '../Component/PreformLink';
import FinalPartLink from '../FinalPart/FinalPartLink';
import SpoolLink from '../Spools/SpoolLink';
import TitanCircularProgress from '../Titan/TitanCircularProgress';
import debounce from 'lodash.debounce';
import { ROUTES } from '../../constants';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ManufacturingOrderService from '../../services/ManufacturingOrderService';
import PrinterService from '../../services/PrinterService';
import ProjectService from '../../services/ProjectService';
import FusorService from '../../services/FusorService';
import FusionJobService from '../../services/FusionJobService';
import BuildJobService from '../../services/BuildJobService';
import FibrifySearchBarSection from './FibrifySearchBarSection';
import Divider from '@mui/material/Divider';
import FusionJobLink from '../FusionJob/FusionJobLink';
import BuildJobLink from '../BuildJob/BuildJobLink';
import BuildModuleLink from '../BuildModule/BuildModuleLink';
import FusionModuleLink from '../FusionModule/FusionModuleLink';
import ProjectLink from '../Projects/ProjectLink';
import ManufacturingOrderLink from '../ManufacturingOrders/ManufacturingOrderLink';

export default function FibrifySearchBar({ setOpenQRCodeScannerDialog }) {
  const [loading, setLoading] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const [buildPlans, setBuildPlans] = React.useState([]);
  const [fusionPlans, setFusionPlans] = React.useState([]);
  const [preforms, setPreforms] = React.useState([]);
  const [finalParts, setFinalParts] = React.useState([]);
  const [spools, setSpools] = React.useState([]);
  const [buildModules, setBuildModules] = React.useState([]);
  const [fusionModules, setFusionModules] = React.useState([]);
  const [buildJobs, setBuildJobs] = React.useState([]);
  const [fusionJobs, setFusionJobs] = React.useState([]);
  const [manufacturingOrders, setManufacturingOrders] = React.useState([]);
  const [projects, setProjects] = React.useState([]);

  const axiosBuildPlansAbortControllerRef = React.useRef();
  const axiosFusionPlansAbortControllerRef = React.useRef();
  const axiosPreformsAbortControllerRef = React.useRef();
  const axiosFinalPartsAbortControllerRef = React.useRef();
  const axiosSpoolsAbortControllerRef = React.useRef();
  const axiosBuildJobAbortControllerRef = React.useRef();
  const axiosFusionJobsAbortControllerRef = React.useRef();
  const axiosBuildModulesAbortControllerRef = React.useRef();
  const axiosFusionModulesAbortControllerRef = React.useRef();
  const axiosProjectsAbortControllerRef = React.useRef();
  const axiosManufacturingOrdersAbortControllerRef = React.useRef();
  const loadDataPromiseRef = React.useRef(false);

  const loadData = React.useCallback(async (search) => {
    setLoading(true);

    const params = {
      search,
      pageSize: 3,
      page: 1,
      order: ['created_at:desc'],
    };

    if (loadDataPromiseRef.current) {
      axiosBuildPlansAbortControllerRef.current.abort();
      axiosFusionPlansAbortControllerRef.current.abort();
      axiosPreformsAbortControllerRef.current.abort();
      axiosFinalPartsAbortControllerRef.current.abort();
      axiosSpoolsAbortControllerRef.current.abort();
      axiosBuildJobAbortControllerRef.current.abort();
      axiosFusionJobsAbortControllerRef.current.abort();
      axiosBuildModulesAbortControllerRef.current.abort();
      axiosFusionModulesAbortControllerRef.current.abort();
      axiosProjectsAbortControllerRef.current.abort();
      axiosManufacturingOrdersAbortControllerRef.current.abort();
    }

    const config = {};

    axiosBuildPlansAbortControllerRef.current = new AbortController();
    axiosFusionPlansAbortControllerRef.current = new AbortController();
    axiosPreformsAbortControllerRef.current = new AbortController();
    axiosFinalPartsAbortControllerRef.current = new AbortController();
    axiosSpoolsAbortControllerRef.current = new AbortController();
    axiosBuildJobAbortControllerRef.current = new AbortController();
    axiosFusionJobsAbortControllerRef.current = new AbortController();
    axiosBuildModulesAbortControllerRef.current = new AbortController();
    axiosFusionModulesAbortControllerRef.current = new AbortController();
    axiosProjectsAbortControllerRef.current = new AbortController();
    axiosManufacturingOrdersAbortControllerRef.current = new AbortController();

    try {
      loadDataPromiseRef.current = true;

      const [
        { data: buildPlans },
        { data: fusionPlans },
        { data: preforms },
        { data: finalParts },
        { data: spools },
        { data: buildJobs },
        { data: fusionJobs },
        { data: buildModules },
        { data: fusionModules },
        { data: projects },
        { data: manufacturingOrders },
      ] = await Promise.all([
        BuildPlanService.getBuildPlans(params, 'v2', {
          ...config,
          signal: axiosBuildPlansAbortControllerRef.current.signal,
        }),
        FusionPlanService.getFusionPlansV2(params, {
          ...config,
          signal: axiosFusionPlansAbortControllerRef.current.signal,
        }),
        PreformService.getPreforms(params, {
          ...config,
          signal: axiosPreformsAbortControllerRef.current.signal,
        }),
        FinalPartService.getFinalParts(params, {
          ...config,
          signal: axiosFinalPartsAbortControllerRef.current.signal,
        }),
        SpoolService.getSpools(params, {
          ...config,
          signal: axiosSpoolsAbortControllerRef.current.signal,
        }),
        BuildJobService.getBuildJobs(
          { ...params, withRelated: ['buildPlan'] },
          {
            ...config,
            signal: axiosBuildJobAbortControllerRef.current.signal,
          },
        ),
        FusionJobService.getFusionJobs(params, {
          ...config,
          signal: axiosFusionJobsAbortControllerRef.current.signal,
        }),
        PrinterService.getPrinters(params, {
          ...config,
          signal: axiosBuildModulesAbortControllerRef.current.signal,
        }),
        FusorService.getFusors(params, {
          ...config,
          signal: axiosFusionModulesAbortControllerRef.current.signal,
        }),
        ProjectService.getProjects(params, {
          ...config,
          signal: axiosProjectsAbortControllerRef.current.signal,
        }),
        ManufacturingOrderService.getManufacturingOrders(params, {
          ...config,
          signal: axiosManufacturingOrdersAbortControllerRef.current.signal,
        }),
      ]).then((data) => {
        loadDataPromiseRef.current = false;

        return data;
      });

      setBuildPlans(buildPlans);
      setFusionPlans(fusionPlans);
      setPreforms(preforms);
      setFinalParts(finalParts);
      setSpools(spools);
      setBuildJobs(buildJobs);
      setFusionJobs(fusionJobs);
      setBuildModules(buildModules);
      setFusionModules(fusionModules);
      setProjects(projects);
      setManufacturingOrders(manufacturingOrders);
    } catch (e) {}

    setLoading(false);
  }, []);

  const debouncedSearchFunction = React.useCallback(debounce(loadData, 500), [
    loadData,
  ]);

  const handleChange = async (e) => {
    const search = e.target.value;
    setSearch(search);
    setAnchorEl(e.currentTarget);
  };

  React.useEffect(() => {
    if (search.length > 2) {
      debouncedSearchFunction(search);
    }
  }, [search, debouncedSearchFunction]);

  const open = search.length > 2 && Boolean(anchorEl);

  const clearSearch = () => {
    setAnchorEl(null);
    setSearch('');
  };

  const onMoreClick = React.useCallback(() => {
    clearSearch();
  }, [clearSearch]);

  useEffect(() => {
    const onEscapeKeyDown = (event) => {
      if (event.key === 'Escape') {
        clearSearch();
      }
    };

    document.addEventListener('keydown', onEscapeKeyDown);

    return () => {
      document.removeEventListener('keydown', onEscapeKeyDown);
    };
  }, []);

  return (
    <Box sx={{ width: '300px', mr: 2 }}>
      <Backdrop open={open} onClick={clearSearch} invisible />
      <Paper
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconButton type="button" sx={{ p: '10px' }}>
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          onChange={handleChange}
          value={search}
          placeholder="Find anything..."
        />
        {search && (
          <IconButton type="button" sx={{ p: '10px' }} onClick={clearSearch}>
            <CloseIcon />
          </IconButton>
        )}
        <IconButton
          type="button"
          sx={{ p: '10px' }}
          onClick={() => setOpenQRCodeScannerDialog(true)}
        >
          <QrCodeScannerIcon />
        </IconButton>
      </Paper>
      <Popper
        open={open}
        anchorEl={anchorEl}
        onClose={clearSearch}
        placement="bottom-end"
        sx={{ zIndex: 10000 }}
      >
        <Paper
          sx={{
            mt: '10px',
            mr: '-47px',
            width: '500px',
            p: 2,
            bgcolor: 'background.paper',
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 60px)',
          }}
        >
          {loading ? (
            <TitanCircularProgress />
          ) : (
            <>
              <FibrifySearchBarSection
                title="Build Plans"
                totalItems={buildPlans.length}
                onMoreClick={onMoreClick}
                search={search}
                route={ROUTES.BUILD_PLANS('page', 0)}
              >
                {buildPlans.map((buildPlan) => (
                  <ListItemButton key={buildPlan.id}>
                    <BuildPlanLink buildPlan={buildPlan} />
                  </ListItemButton>
                ))}
              </FibrifySearchBarSection>

              <Divider />

              <FibrifySearchBarSection
                title="Fusion Plans"
                totalItems={fusionPlans.length}
                onMoreClick={onMoreClick}
                search={search}
                route={ROUTES.FUSION_PLANS('page', 0)}
              >
                {fusionPlans.map((fusionPlan) => (
                  <ListItemButton key={fusionPlan.id}>
                    <FusionPlanLink fusionPlan={fusionPlan} />
                  </ListItemButton>
                ))}
              </FibrifySearchBarSection>

              <Divider />

              <FibrifySearchBarSection
                title="Preforms"
                totalItems={preforms.length}
                onMoreClick={onMoreClick}
                search={search}
                route={ROUTES.HISTORY_ITEMS('preforms', 'page', 0)}
              >
                {preforms.map((preform) => (
                  <ListItemButton key={preform.id}>
                    <PreformLink preform={preform} />
                  </ListItemButton>
                ))}
              </FibrifySearchBarSection>

              <Divider />

              <FibrifySearchBarSection
                title="Final Parts"
                totalItems={finalParts.length}
                onMoreClick={onMoreClick}
                search={search}
                route={ROUTES.HISTORY_ITEMS('final-parts', 'page', 0)}
              >
                {finalParts.map((finalPart) => (
                  <ListItemButton key={finalPart.id}>
                    <FinalPartLink finalPart={finalPart} />
                  </ListItemButton>
                ))}
              </FibrifySearchBarSection>

              <Divider />

              <FibrifySearchBarSection
                title="Build Jobs"
                totalItems={buildJobs.length}
                onMoreClick={onMoreClick}
                search={search}
                route={ROUTES.HISTORY_ITEMS('preforms', 'page', 0)}
              >
                {buildJobs.map((buildJob) => (
                  <ListItemButton key={buildJob.id}>
                    <BuildJobLink buildJob={buildJob} />
                  </ListItemButton>
                ))}
              </FibrifySearchBarSection>

              <Divider />

              <FibrifySearchBarSection
                title="Fusion Jobs"
                totalItems={fusionJobs.length}
                onMoreClick={onMoreClick}
                search={search}
                route={ROUTES.HISTORY_ITEMS('final-parts', 'page', 0)}
              >
                {fusionJobs.map((fusionJob) => (
                  <ListItemButton key={fusionJob.id}>
                    <FusionJobLink fusionJob={fusionJob} />
                  </ListItemButton>
                ))}
              </FibrifySearchBarSection>

              <Divider />

              <FibrifySearchBarSection
                title="Build Modules"
                totalItems={buildModules.length}
                onMoreClick={onMoreClick}
                search={search}
                route={ROUTES.BUILD_MODULES}
              >
                {buildModules.map((buildModule) => (
                  <ListItemButton key={buildModule.id}>
                    <BuildModuleLink buildModule={buildModule} />
                  </ListItemButton>
                ))}
              </FibrifySearchBarSection>

              <Divider />

              <FibrifySearchBarSection
                title="Fusion Modules"
                totalItems={fusionModules.length}
                onMoreClick={onMoreClick}
                search={search}
                route={ROUTES.FUSION_MODULES}
              >
                {fusionModules.map((fusionModule) => (
                  <ListItemButton key={fusionModule.id}>
                    <FusionModuleLink fusionModule={fusionModule} />
                  </ListItemButton>
                ))}
              </FibrifySearchBarSection>

              <Divider />

              <FibrifySearchBarSection
                title="Spools"
                totalItems={spools.length}
                onMoreClick={onMoreClick}
                search={search}
                route={ROUTES.GEAR_TAB_PAGE('spools', 'page', 0)}
              >
                {spools.map((spool) => (
                  <ListItemButton key={spool.id}>
                    <SpoolLink spool={spool} />
                  </ListItemButton>
                ))}
              </FibrifySearchBarSection>

              <Divider />

              <FibrifySearchBarSection
                title="Projects"
                totalItems={projects.length}
                onMoreClick={onMoreClick}
                search={search}
                route={ROUTES.PROJECTS_PAGE(0)}
              >
                {projects.map((project) => (
                  <ListItemButton key={project.id}>
                    <ProjectLink project={project} />
                  </ListItemButton>
                ))}
              </FibrifySearchBarSection>

              <Divider />

              <FibrifySearchBarSection
                title="Manufacturing Orders"
                totalItems={manufacturingOrders.length}
                onMoreClick={onMoreClick}
                search={search}
                route={ROUTES.MANUFACTURING_ORDERS_PAGE(0)}
              >
                {manufacturingOrders.map((manufacturingOrder) => (
                  <ListItemButton key={manufacturingOrder.id}>
                    <ManufacturingOrderLink
                      manufacturingOrder={manufacturingOrder}
                    />
                  </ListItemButton>
                ))}
              </FibrifySearchBarSection>
            </>
          )}
        </Paper>
      </Popper>
    </Box>
  );
}
