import React from 'react';
import { Grid, Paper, Rating } from '@mui/material';
import TitanInfoItem from '../Titan/TitanInfoItem';
import MaterialLink from '../Materials/MaterialLink';
import { MATERIAL_TYPES, SPOOL_TYPES } from '../../constants';

function SpoolSummary({ spool }) {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TitanInfoItem label="Material">
                <MaterialLink materialType={spool.materialType} />
              </TitanInfoItem>
            </Grid>
            <Grid item xs={4}>
              <TitanInfoItem label="Type">{spool.type}</TitanInfoItem>
            </Grid>
            <Grid item xs={4}>
              <TitanInfoItem label="Rating">
                <Rating value={spool.quality} readOnly />
              </TitanInfoItem>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TitanInfoItem label="Tags">
                {spool.tags && spool.tags.length !== 0
                  ? spool.tags.map(tag => tag.name).join(', ')
                  : '-'}
              </TitanInfoItem>
            </Grid>

            {spool.materialType.type === MATERIAL_TYPES.PLASTIC &&
              spool.type === SPOOL_TYPES.BATCH && (
                <Grid item xs={4}>
                  <TitanInfoItem label="Batch Size">
                    {spool.batchSize}
                  </TitanInfoItem>
                </Grid>
              )}
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TitanInfoItem label="Original Weight">
                {spool.originalWeight ? `${spool.originalWeight}g` : '-'}
              </TitanInfoItem>
            </Grid>
            <Grid item xs={4}>
              <TitanInfoItem label="Current Weight">
                {spool.currentWeight ? `${spool.currentWeight}g` : '-'}
              </TitanInfoItem>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TitanInfoItem label="Original Length">
                {spool.originalLength ? `${spool.originalLength}m` : '-'}
              </TitanInfoItem>
            </Grid>
            <Grid item xs={4}>
              <TitanInfoItem label="Current Length">
                {spool.currentLength ? `${spool.currentLength}m` : '-'}
              </TitanInfoItem>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TitanInfoItem label="Density">
                {spool.density ? `${spool.density}g/m` : '-'}
              </TitanInfoItem>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TitanInfoItem label="Comment">{spool.comment}</TitanInfoItem>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default SpoolSummary;
