import React from 'react';
import FilterChips from '../FilterChips/FilterChips';

export const PRESET_COMPLEXITIES = {
  BASIC: 0,
  ADVANCED: 1,
  EXPERT: 2,
  EXPERIMENTAL: 3
};

export default function PresetChips({ complexityFilter, setComplexityFilter }) {
  const items = React.useMemo(() => {
    const items = [];

    items.push({
      label: 'Basic',
      onClick: () => setComplexityFilter(PRESET_COMPLEXITIES.BASIC),
      isHighlighted: complexityFilter === PRESET_COMPLEXITIES.BASIC
    });

    items.push({
      label: 'Advanced',
      onClick: () => setComplexityFilter(PRESET_COMPLEXITIES.ADVANCED),
      isHighlighted: complexityFilter === PRESET_COMPLEXITIES.ADVANCED
    });

    items.push({
      label: 'Expert',
      onClick: () => setComplexityFilter(PRESET_COMPLEXITIES.EXPERT),
      isHighlighted: complexityFilter === PRESET_COMPLEXITIES.EXPERT
    });

    items.push({
      label: 'Experimental',
      onClick: () => setComplexityFilter(PRESET_COMPLEXITIES.EXPERIMENTAL),
      isHighlighted: complexityFilter === PRESET_COMPLEXITIES.EXPERIMENTAL
    });

    return items;
  }, [complexityFilter]);

  return <FilterChips items={items} />;
}
