import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ChangeCircle from '@mui/icons-material/ChangeCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

export default class PreformService {
  static getPreforms(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v2/preforms`, {
        ...config,
        params,
      })
      .then((res) => res.data);
  }

  static getPreformStates(preformId, params) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v2/preforms/${preformId}/states`,
        {
          params,
        },
      )
      .then((res) => res.data);
  }

  static updatePreformState(preformId, timestamp, data) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v2/preforms/${preformId}/states/${timestamp}`,
        data,
      )
      .then((res) => res.data);
  }

  static preparePreformCustomFields(preform) {
    return preform.customFields
      .concat(
        preform.preformType.customFields
          .sort((a, b) => (a.order && b.order ? a.order - b.order : 0))
          .filter(
            (preformTypeCustomField) =>
              !preform.customFields.find(
                (preformCustomField) =>
                  preformTypeCustomField.id ===
                  preformCustomField.preformTypeCustomFieldId,
              ),
          )
          .map((preformTypeCustomField) => ({
            preformTypeCustomFieldId: preformTypeCustomField.id,
            preformId: preform.id,
            value: '',
          })),
      )
      .reduce((res, customField) => {
        res[customField.preformTypeCustomFieldId] = customField;

        return res;
      }, {});
  }

  static getPreformTypesActions(onEdit, onDelete, hasPreforms, isOwner) {
    const actions = [
      {
        label: 'Edit',
        tooltipText: 'Only the owner of the Build Plan can edit',
        icon: <EditIcon />,
        onClick: onEdit,
        disabled: !isOwner,
      },
      {
        label: 'Delete',
        tooltipText: !isOwner
          ? 'Only the owner of the Build Plan can delete'
          : '',
        icon: <DeleteIcon />,
        onClick: onDelete,
        disabled: !isOwner,
      },
    ];

    return actions;
  }

  static getPreformActions({
    preform,
    onAttachToManufacturingOrder,
    onDetachFromManufacturingOrder,
  }) {
    const actions = [];

    actions.push({
      variant: 'outlined',
      color: 'secondary',
      label: !preform.manufacturingOrderId
        ? 'to Manufacturing Order'
        : 'Change Manufacturing Order',
      icon: !preform.manufacturingOrderId ? (
        <AddCircleIcon />
      ) : (
        <ChangeCircle />
      ),
      onClick: onAttachToManufacturingOrder,
    });

    if (preform.manufacturingOrderId) {
      actions.push({
        variant: 'outlined',
        color: 'secondary',
        label: 'from Manufacturing Order',
        icon: <RemoveCircleIcon />,
        onClick: onDetachFromManufacturingOrder,
      });
    }

    return actions;
  }

  static printLabel(preformId, params = {}) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v2/preforms/${preformId}/print-label`,
        {
          params,
        },
      )
      .then((res) => res.data);
  }
}
