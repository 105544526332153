import React from 'react';
import TimezoneSelect from 'react-timezone-select';
import TitanPage from '../Titan/TitanPage';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Paper,
  TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAuth0 } from '../Authentication/Auth0';
import { colors } from '../Theme/vars';
import regexps from '../../constants/regexps';
import TitanButton from '../Titan/TitanButton';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import ProfileService from '../../services/ProfileService';
import { useTitan } from '../Titan/Titan';

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(2)
  },
  input: {
    maxWidth: 600
  },
  timezoneInput: {
    maxWidth: 600,
    marginTop: '8px',
    borderWidth: '4px'
  }
}));

export default function ProfilePage() {
  const classes = useStyles();
  const { user, setUser, profile, setProfile } = useAuth0();
  const { pushSnackbar } = useTitan();

  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState(user.name);
  const [timezone, setTimezone] = React.useState({ value: profile.region });
  const [analytics, setAnalytics] = React.useState(profile.analytics);

  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Profile',
        disabled: true
      }
    ],
    []
  );

  const onSaveProfile = async e => {
    e.preventDefault();

    setLoading(true);

    const updatedProfile = await ProfileService.updateProfile({
      name,
      analytics,
      timezone: timezone.label.match(regexps.TIMEZONE)[0],
      region: timezone.value
    });

    setUser({
      ...user,
      name: updatedProfile.name,
      analytics: updatedProfile.analytics
    });

    setProfile({
      ...profile,
      timezone: updatedProfile.timezone,
      region: updatedProfile.region
    });

    setLoading(false);
    pushSnackbar('User profile updated successfully', { variant: 'success' });
  };

  return (
    <TitanPage breadcrumbs={breadcrumbs} title="Profile">
      <Paper className={classes.content}>
        <form onSubmit={onSaveProfile}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Name"
              value={name}
              onChange={e => setName(e.target.value)}
              variant="outlined"
              className={classes.input}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Email"
              value={user.email}
              disabled
              variant="outlined"
              className={classes.input}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormLabel>Roles</FormLabel>

            <InputBase
              defaultValue={profile.roles.map(role => role.name).join(', ')}
            />
          </FormControl>
          {profile.organization ? (
            <FormControl fullWidth margin="normal">
              <FormLabel>Organization</FormLabel>

              <InputBase defaultValue={profile.organization.name} />
            </FormControl>
          ) : (
            ''
          )}
          <FormControl fullWidth margin="normal">
            <FormLabel>Timezone</FormLabel>

            <TimezoneSelect
              className={classes.timezoneInput}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: colors.RED,
                  primary25: colors.RED_TRANSPARENT,
                  primary50: colors.LIGHT_RED
                }
              })}
              value={timezone}
              onChange={setTimezone}
              placeholder="Select your timezone..."
            />
          </FormControl>
          <FormControlLabel
            className={classes.checkbox}
            label="I agree to anonymously share usage data to improve the stability, performance and the general user experience of fibrify."
            control={
              <Checkbox
                checked={analytics}
                onChange={e => setAnalytics(e.target.checked)}
                name="analytics"
                color="primary"
              />
            }
          />
          <br />
          <FormControl margin="normal">
            <TitanButton
              variant="contained"
              color="primary"
              type="submit"
              isLoading={loading}
            >
              Save
            </TitanButton>
          </FormControl>
        </form>
      </Paper>
    </TitanPage>
  );
}
