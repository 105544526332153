import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import TitanDialog from './TitanDialog';
import React from 'react';
import 'react-html5-camera-photo/build/css/index.css';
import FileService from '../../services/FileService';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TitanTabs from './TitanTabs';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';

export default function TitanTakePhotosDialog({
  photos,
  onClose,
  onSave,
  onDelete,
  activeTab
}) {
  const [newPhotos, setNewPhotos] = React.useState([]);

  const removePhoto = photoUrl => {
    if (newPhotos.includes(photoUrl)) {
      setNewPhotos(prevState =>
        prevState.filter(currentPhoto => currentPhoto !== photoUrl)
      );
    } else {
      const photoId = photos.filter(
        currentPhoto => currentPhoto.url === photoUrl
      )[0]?.id;

      photoId && onDelete(photoId);
    }
  };

  return (
    <TitanDialog
      onClose={onClose}
      maxWidth="lg"
      fullScreen
      showTitle={false}
      onSave={() => onSave(newPhotos)}
    >
      <TitanTabs
        activeTab={activeTab}
        centered
        tabs={[
          {
            label: 'Take Photo',
            value: 'takePhoto',
            content: (
              <Box
                sx={{
                  '& video, & img': {
                    maxHeight: {
                      xs: 'calc(100vh - 200px)',
                      sm: 'calc(100vh - 200px)',
                      md: 'calc(100vh - 250px)',
                      lg: 'calc(100vh - 250px)',
                      xl: 'calc(100vh - 250px)'
                    },
                    width: 'auto',
                    maxWidth: '100%'
                  },
                  position: 'relative'
                }}
              >
                <Camera
                  onTakePhoto={async dataUri => {
                    setNewPhotos(prevState => [...prevState, dataUri]);
                  }}
                  idealFacingMode={FACING_MODES.ENVIRONMENT}
                  isImageMirror={false}
                />
              </Box>
            )
          },
          {
            label: 'Upload Photo',
            value: 'uploadPhoto',
            content: (
              <Box
                sx={{
                  width: '768px',
                  maxWidth: '100%',
                  display: 'flex',
                  height: 'calc(100vh - 250px)',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '0 auto',
                  flexDirection: 'column'
                }}
              >
                <Button variant="contained" component="label">
                  <AddPhotoAlternateOutlinedIcon sx={{ mr: 1 }} />
                  Upload File
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    multiple
                    onChange={e => {
                      Array.from(e.target.files).forEach(async file => {
                        const base64File = await FileService.fileToBase64(file);
                        setNewPhotos(prevState => [...prevState, base64File]);
                      });
                    }}
                  />
                </Button>
              </Box>
            )
          }
        ]}
      />

      <Stack justifyContent="center" direction="row" spacing={1}>
        {[...photos.map(photo => photo.url), ...newPhotos].map(
          (photo, index) => (
            <Box
              key={index}
              sx={{
                position: 'relative',
                '& img': {
                  height: {
                    xs: 50,
                    sm: 50,
                    md: 100,
                    lg: 100,
                    xl: 100
                  }
                }
              }}
            >
              <Box
                onClick={() => removePhoto(photo)}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  cursor: 'pointer',
                  backgroundColor: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <CloseIcon fontSize="small" />
              </Box>
              <img src={photo} alt={''} />
            </Box>
          )
        )}
      </Stack>
    </TitanDialog>
  );
}
