import axios from 'axios';

export default class FusorService {
  static getFusionModuleLogs(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/fusion-module-logs`, {
        ...config,
        params
      })
      .then(res => res.data);
  }
}
