import React from 'react';
import BuildPlanService from '../../services/BuildPlanService';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import IconButton from '@mui/material/IconButton';

export default function BuildPlanFavoriteButton({ buildPlan, onChange }) {
  return (
    <IconButton
      onClick={async e => {
        e.preventDefault();
        e.stopPropagation();

        const updatedBuildPlan = await (buildPlan.isFavorite
          ? BuildPlanService.removeFromFavorite(buildPlan.id)
          : BuildPlanService.addToFavorite(buildPlan.id));

        onChange({
          ...buildPlan,
          isFavorite: updatedBuildPlan.isFavorite
        });
      }}
      title={buildPlan.isFavorite ? 'Remove from favorite' : 'Add to Favorite'}
    >
      {buildPlan.isFavorite ? (
        <FavoriteIcon color="primary" />
      ) : (
        <FavoriteBorderIcon color="primary" />
      )}
    </IconButton>
  );
}
