import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AuthPage from './AuthPage';
import { useAuth0 } from '../Authentication/Auth0';
import { WELCOME_PAGE_STATES } from './constants';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  image: {
    height: 'calc((100vh - 390px) / 3)',
    minHeight: 100,
    maxHeight: 200
  }
}));

export default function Welcome() {
  const classes = useStyles();
  const auth0 = useAuth0();

  const [pageState, setPageState] = React.useState(WELCOME_PAGE_STATES.LOG_IN);

  const handleAuth = async data => {
    if (pageState === WELCOME_PAGE_STATES.LOG_IN) {
      await auth0.login(data);
    } else if (pageState === WELCOME_PAGE_STATES.FORGOT_PASSWORD) {
      await auth0.changePassword(data);

      setPageState(WELCOME_PAGE_STATES.FORGOT_PASSWORD_SUCCESS);
    }
  };

  const handleConnectionClick = async connection => {
    await auth0.socialAuthorize(connection);
  };

  return (
    <>
      <Grid container style={{ height: '100vh' }}>
        <Grid
          item
          display={{ xs: 'none', sm: 'none', md: 'block' }}
          md={4}
          lg={5}
          style={{ backgroundColor: 'black' }}
        >
          <Box pb={3} style={{ backgroundColor: 'black' }}>
            <Box m={5}>
              <img
                src={process.env.PUBLIC_URL + '/red-series-logo-white.png'}
                alt="red series logo"
              />
            </Box>
            <Box m={5}>
              <Typography
                variant="h6"
                style={{ textTransform: 'none', color: 'white' }}
              >
                Your all-in-one solution for digital composite production
              </Typography>
            </Box>
            <Box>
              <Box my={2} style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src={process.env.PUBLIC_URL + '/ic_laptop.png'}
                  alt="laptop"
                  className={classes.image}
                />
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src={process.env.PUBLIC_URL + '/Arrow 1.png'}
                  alt="arrow"
                />
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <Box
                  my={2}
                  style={{
                    border: '1px solid #585858',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center'
                  }}
                >
                  <Box mx={4} my={2} display="inline">
                    <img
                      src={process.env.PUBLIC_URL + '/Group 153.png'}
                      alt="build"
                      className={classes.image}
                    />
                  </Box>
                  <Box mx={4} my={2} display="inline">
                    <img
                      src={process.env.PUBLIC_URL + '/ic_fusion_module.png'}
                      alt="fuser"
                      className={classes.image}
                    />
                  </Box>
                </Box>
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src={process.env.PUBLIC_URL + '/Arrow 1.png'}
                  alt="arrow"
                />
              </Box>
              <Box mt={2} style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src={process.env.PUBLIC_URL + '/image7 1.png'}
                  alt="part"
                  className={classes.image}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          lg={7}
          display={{ xs: 'flex', md: 'block' }}
          flexDirection={{ xs: 'column' }}
          alignItems={{ xs: 'center' }}
        >
          <Box m={5}>
            <img src={process.env.PUBLIC_URL + '/9TLabs_logo.png'} alt="Logo" />
          </Box>

          <Box m={5}>
            <AuthPage
              pageState={pageState}
              setPageState={setPageState}
              onAuth={handleAuth}
              onConnectionClick={handleConnectionClick}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
