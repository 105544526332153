import React from 'react';
import useWebSocket from 'react-use-websocket';
import { WEB_SOCKET_ACTIONS } from '../../constants';
import useAsyncEffect from 'use-async-effect';
import { useTitan } from '../Titan/Titan';
import FusionJobService from '../../services/FusionJobService';

export const updateFusionJobData = (fusionJob, newFusionJobData) => {
  const updatedFusionJob = { ...fusionJob };

  if (newFusionJobData.timestamp) {
    updatedFusionJob.statusUpdatedAt = newFusionJobData.timestamp;
  }

  if (newFusionJobData.state) {
    updatedFusionJob.status = newFusionJobData.state;
  } else if (newFusionJobData.status) {
    updatedFusionJob.status = newFusionJobData.status;
  }

  if (newFusionJobData.progress) {
    updatedFusionJob.progress = newFusionJobData.progress;
  }

  if (newFusionJobData.startFuseTime) {
    updatedFusionJob.startFuseTime = newFusionJobData.startFuseTime;
  }

  if (newFusionJobData.endFuseTime) {
    updatedFusionJob.endFuseTime = newFusionJobData.endFuseTime;
  }

  if (newFusionJobData.fuseTime) {
    updatedFusionJob.fuseTime = newFusionJobData.fuseTime;
  }

  if (newFusionJobData.deviceVersion) {
    updatedFusionJob.deviceVersion = newFusionJobData.deviceVersion;
  }

  if (newFusionJobData.manufacturingOrderId) {
    updatedFusionJob.manufacturingOrderId =
      newFusionJobData.manufacturingOrderId;
  }

  if (newFusionJobData.manufacturingOrder) {
    updatedFusionJob.manufacturingOrder = newFusionJobData.manufacturingOrder;

    if (
      !newFusionJobData.manufacturingOrderId &&
      newFusionJobData.manufacturingOrder.id
    ) {
      updatedFusionJob.manufacturingOrderId =
        newFusionJobData.manufacturingOrder.id;
    }
  }

  return updatedFusionJob;
};

export default function useFusionJobs(loadFusionJobs, filterFusionJobs) {
  const { getWebSocketUrl, getWebSocketOptions } = useTitan();

  const [loading, setLoading] = React.useState(true);
  const [fusionJobs, setFusionJobs] = React.useState([]);

  useAsyncEffect(async () => {
    setLoading(true);

    const fusionJobs = await loadFusionJobs();

    setFusionJobs(fusionJobs);
    setLoading(false);
  }, [loadFusionJobs]);

  const onChangeFusionJob = React.useCallback(
    async newFusionJobData => {
      const newFusionJobId =
        newFusionJobData.fusionJobId || newFusionJobData.id;

      const fusionJobToUpdate = fusionJobs.find(fj => fj.id === newFusionJobId);

      if (!fusionJobToUpdate) {
        if (filterFusionJobs) {
          const filteredFusionJobs = filterFusionJobs([newFusionJobData]);

          if (filteredFusionJobs.length !== 0) {
            const newFusionJob = await FusionJobService.getFusionJob(
              newFusionJobId,
              {
                withRelated: [
                  'preforms',
                  'preforms.preformType',
                  'fusionModule',
                  'fusionPlan',
                  'creator'
                ]
              },
              'v2'
            );

            setFusionJobs(prev => [
              newFusionJob
                ? { ...newFusionJob, ...newFusionJobData }
                : newFusionJobData,
              ...prev
            ]);
          }
        }
      } else {
        setFusionJobs(prev => {
          let updatedFusionJobs = prev.map(fusionJob => {
            if (
              fusionJob.id === newFusionJobData.fusionJobId ||
              fusionJob.id === newFusionJobData.id
            ) {
              return updateFusionJobData(fusionJob, newFusionJobData);
            }

            return fusionJob;
          });

          if (filterFusionJobs) {
            updatedFusionJobs = filterFusionJobs(updatedFusionJobs);
          }

          return updatedFusionJobs;
        });
      }
    },
    [fusionJobs, setFusionJobs, filterFusionJobs]
  );

  const updateFusionJob = React.useCallback(
    fusionJobToUpdate => {
      setFusionJobs(prev => {
        let updatedFusionJobs = prev.map(fusionJob => {
          if (fusionJob.id === fusionJobToUpdate.id) {
            return updateFusionJobData(fusionJob, fusionJobToUpdate);
          }

          return fusionJob;
        });

        if (filterFusionJobs) {
          updatedFusionJobs = filterFusionJobs(updatedFusionJobs);
        }

        return updatedFusionJobs;
      });
    },
    [setFusionJobs, filterFusionJobs]
  );

  const { lastJsonMessage } = useWebSocket(
    getWebSocketUrl,
    getWebSocketOptions()
  );

  React.useEffect(() => {
    if (
      lastJsonMessage !== null &&
      [WEB_SOCKET_ACTIONS.FUSION_JOB].includes(lastJsonMessage.action) &&
      lastJsonMessage.data
    ) {
      onChangeFusionJob(lastJsonMessage.data);
    }
  }, [lastJsonMessage, onChangeFusionJob]);

  return {
    loading,
    fusionJobs,
    setFusionJobs,
    updateFusionJob
  };
}
