import React from 'react';

import TextField from '@mui/material/TextField';
import TitanDialog from '../Titan/TitanDialog';

import PreBuildingChecklistService from '../../services/PreBuildingChecklistService';

export default function PreBuildingChecklistSectionDialog(props) {
  const { mode, checklistId, section, order, onClose, onSave } = props;

  const [name, setName] = React.useState(section.name || '');

  const onSubmitForm = () => {
    if (mode === 'create') {
      return PreBuildingChecklistService.createChecklistSection(
        { name, order },
        { checklistId }
      ).then(data => {
        onSave(data);
      });
    } else {
      return PreBuildingChecklistService.updateChecklistSection(section.id, {
        name
      }).then(data => {
        onSave(data);
      });
    }
  };

  const isValid = name.length !== 0;

  return (
    <TitanDialog
      title={`${mode === 'create' ? 'Create' : 'Edit'} checklist's section`}
      onClose={onClose}
      onSave={onSubmitForm}
      isValid={isValid}
      successSaveMessage={`Checklist's section successfully ${
        mode === 'create' ? 'created' : 'updated'
      }`}
    >
      <TextField
        autoFocus
        label="Name"
        value={name}
        fullWidth
        margin="normal"
        onChange={e => setName(e.target.value)}
      />
    </TitanDialog>
  );
}
