import React from 'react';
import TextField from '@mui/material/TextField';
import TitanDialog from '../Titan/TitanDialog';

export default function BuildPlanDialog(props) {
  const { buildPlan, onClose, onCreate, onUpdate, currentMemberId } = props;

  const [buildPlanName, setBuildPlanName] = React.useState(buildPlan.name);

  const isOwner = buildPlan?.memberId === currentMemberId;

  const onSubmitForm = () => {
    if (!buildPlan.id) {
      return onCreate(buildPlanName);
    } else {
      return onUpdate(buildPlanName);
    }
  };

  return (
    <TitanDialog
      title={`${!buildPlan.id ? 'Create' : 'Rename'} Build Plan`}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth
      onSave={onSubmitForm}
      isValid={!!buildPlanName}
    >
      <TextField
        autoFocus
        id="name"
        label="name"
        type="text"
        value={buildPlanName}
        fullWidth
        disabled={!isOwner}
        margin="normal"
        onChange={e => setBuildPlanName(e.target.value)}
        variant="outlined"
      />
    </TitanDialog>
  );
}
