import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import React from 'react';
import PrinterService from '../../services/PrinterService';
import ActionsMenu from '../Menu/ActionsMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import BuildModuleNoteDialog from './BuildModuleNoteDialog';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import { useParams } from 'react-router-dom';

export default function BuildModuleNotesTable({
  buildModule,
  setNewPageForTab,
  pushHistory
}) {
  const formatNote = ({ label, note }) => ({ label, note });
  const prepareNotes = notes => notes.map((n, i) => ({ ...n, id: i }));

  const isFristTime = React.useRef(false);

  const [notes, setNotes] = React.useState(
    buildModule && buildModule.notes ? prepareNotes(buildModule.notes) : []
  );
  const [editableNote, setEditableNote] = React.useState();

  const [openNoteDialog, setOpenNoteDialog] = React.useState(false);
  const [noteToDelete, setNoteToDelete] = React.useState();

  const { page: pageParam = 0 } = useParams();

  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(
    pageParam && Number(pageParam) > 0 ? Number(pageParam) : 0
  );

  const onUpdateNote = async note => {
    let updatedNotes;

    if (!editableNote) {
      updatedNotes = [...notes, note];
    } else {
      updatedNotes = notes.map(n => {
        if (n.id === editableNote.id) {
          return formatNote(note);
        }

        return formatNote(n);
      });
    }

    await PrinterService.updatePrinterNotes(buildModule.id, {
      notes: updatedNotes
    });

    setNotes(prepareNotes(updatedNotes));
  };

  const onDeleteNote = async () => {
    const updatedNotes = notes.filter(n => n.id !== noteToDelete.id);

    await PrinterService.updatePrinter(buildModule.id, {
      notes: updatedNotes
    });

    setNotes(prepareNotes(updatedNotes));
  };

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Label',
        field: 'label',
        width: 220,
        sortable: false
      },
      {
        headerName: 'Note',
        field: 'note',
        flex: 1,
        sortable: false
      },
      {
        headerName: '',
        width: 60,
        field: 'actions',
        sortable: false,
        renderCell: ({ row }) => (
          <ActionsMenu
            items={[
              {
                title: 'Edit',
                icon: EditIcon,
                onClick: () => {
                  setEditableNote(row);
                  setOpenNoteDialog(true);
                }
              },
              {
                title: 'Delete',
                icon: DeleteIcon,
                onClick: () => {
                  setNoteToDelete(row);
                }
              }
            ]}
          />
        )
      }
    ],
    []
  );

  React.useEffect(() => {
    if (isFristTime.current) {
      setNewPageForTab('notes', 'Notes', page);
      pushHistory('notes', page);
    }
    isFristTime.current = true;
  }, [page]);

  return (
    <div>
      <TitanDataGrid
        title=" "
        showSearch={false}
        rows={notes.slice(page * pageSize, (page + 1) * pageSize)}
        page={page}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setPage={setPage}
        onCreateClick={() => {
          setEditableNote(null);
          setOpenNoteDialog(true);
        }}
        createButtonLabel="Note"
        columns={columns}
        rowCount={notes.length}
      />

      {openNoteDialog && (
        <BuildModuleNoteDialog
          note={editableNote}
          onClose={() => {
            setEditableNote(null);
            setOpenNoteDialog(false);
          }}
          onSave={onUpdateNote}
        />
      )}

      {noteToDelete && (
        <TitanConfirmationDialog
          title="Delete Preform Type?"
          message={`Are you sure you want to delete this note?`}
          onClose={() => setNoteToDelete(null)}
          onConfirm={onDeleteNote}
        />
      )}
    </div>
  );
}
