import React from 'react';
import useBuildModules from './use-build-modules';

export default function useBuildModule(loadBuildModule) {
  const loadBuildModules = React.useCallback(async () => {
    const buildModule = await loadBuildModule();

    return [buildModule];
  }, [loadBuildModule]);

  const { loading, buildModules, setBuildModules } = useBuildModules(
    loadBuildModules
  );

  return {
    loading,
    buildModule: buildModules.length !== 0 ? buildModules[0] : null,
    setBuildModule: buildModule => setBuildModules([buildModule])
  };
}
