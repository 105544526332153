import axios from 'axios';

export default class ProfileService {
  static async loadProfileData() {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/profile`)
      .then(res => res.data.data);
  }

  static async updateProfile(data) {
    return axios
      .patch(`${process.env.REACT_APP_API_URL}/api/v1/profile`, data)
      .then(res => res.data.data);
  }

  static async resendVerificationEmail() {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/profile/verification-email`
      )
      .then(res => res.data.data);
  }
}
