import React from 'react';
import { Stack, Box, Chip } from '@mui/material';

export default function FilterChips({ items }) {
  return (
    <Stack direction="row" spacing={1}>
      {items.map(item =>
        item.separatorLabel ? (
          <Box key={item.separatorLabel} sx={{ alignSelf: 'center' }}>
            {item.separatorLabel}
          </Box>
        ) : (
          <Chip
            label={item.label}
            icon={item.icon}
            onDelete={item.onDelete}
            key={item.key || item.label}
            onClick={item.onClick}
            color={item.isHighlighted ? 'primary' : undefined}
          />
        )
      )}
    </Stack>
  );
}
