import React from 'react';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import FusionModuleLogsService from '../../services/FusionModuleLogsService';
import FusionModuleLink from './FusionModuleLink';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/GetApp';
import FinalPartLink from '../FinalPart/FinalPartLink';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import renderCellExpand from '../ExpandRenderCell/ExpandRenderCell';
import { CustomGridColumnsPanel } from '../TitanDataGrid/TitanDataGridColumnsPanel';
import { useParams } from 'react-router-dom';
const FileDownload = require('js-file-download');

export default function FusionModuleLogsTable({
  fusionModuleId,
  fusionPlanId,
  fusionJobId,
  finalPartId,
  setNewPageForTab
}) {
  const [downloadingFile, setDownloadingFile] = React.useState();

  const { page: pageParam = 0 } = useParams();

  const loadData = React.useCallback(
    async (query, config) => {
      const params = {
        ...query,
        withRelated: ['fusionModule', 'fusionJob', 'fusionJob.assemblies']
      };

      if (fusionModuleId) {
        params.fusionModuleId = fusionModuleId;
      }

      if (fusionPlanId) {
        params.fusionPlanId = fusionPlanId;
      }

      if (fusionJobId) {
        params.fusionJobId = fusionJobId;
      }

      if (finalPartId) {
        params.finalPartId = finalPartId;
      }

      const {
        data,
        pagination
      } = await FusionModuleLogsService.getFusionModuleLogs(params, config);

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount
      };
    },
    [fusionModuleId, finalPartId, fusionJobId, fusionPlanId]
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: 'File name',
        field: 'fileName',
        visibilityBreakpoint: 'sm',
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: ({ row, colDef }) =>
          renderCellExpand(row.location.split('/')[1], colDef.computedWidth)
      },
      {
        headerName: 'Fusion Module',
        field: 'fusionModule',
        visibilityBreakpoint: 'lg',
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: ({ row, colDef }) => (
          <FusionModuleLink
            fusionModule={row.fusionModule}
            width={colDef.computedWidth}
          />
        )
      },
      {
        headerName: 'Final Parts',
        field: 'finalParts',
        hideable: false,
        visibilityBreakpoint: 'sm',
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: ({ row, colDef }) =>
          row.fusionJob
            ? row.fusionJob.assemblies.map(finalPart => (
                <FinalPartLink
                  finalPart={finalPart}
                  key={finalPart.id}
                  width={colDef.computedWidth}
                />
              ))
            : ''
      },
      {
        headerName: 'Created',
        field: 'created',
        visibilityBreakpoint: 'sm',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />
      },
      {
        headerName: '',
        field: 'actions',
        visibilityBreakpoint: 'sm',
        hideInMenu: true,
        hideable: false,
        width: 60,
        sortable: false,
        renderCell: ({ row }) =>
          row.url ? (
            <IconButton onClick={e => onDownloadClick(e, row)}>
              {downloadingFile && row.id === downloadingFile.id ? (
                <CircularProgress size={20} />
              ) : (
                <DownloadIcon />
              )}
            </IconButton>
          ) : null
      }
    ],
    []
  );

  const gridOptions = {
    columns,
    onChangePage: page => {
      setNewPageForTab('logs', 'Logs', page);
    }
  };

  if (pageParam && Number(pageParam) > 0) {
    gridOptions.page = Number(pageParam);
  }

  const titanDataGridProps = useTitanDataGrid(loadData, gridOptions);

  const onDownloadClick = (e, logFile) => {
    setDownloadingFile(logFile);
    e.preventDefault();

    const fileName = logFile.location.split('/')[1];

    axios
      .get(logFile.url, {
        sendAuth0Headers: false,
        responseType: 'blob'
      })
      .then(res => {
        setDownloadingFile(null);
        FileDownload(res.data, fileName);
      })
      .catch(() => setDownloadingFile(null));
  };

  return (
    <>
      <TitanDataGrid
        components={{
          Toolbar: TitanDataGridToolbar,
          ColumnsPanel: CustomGridColumnsPanel
        }}
        {...titanDataGridProps}
        showSearch={false}
      />
    </>
  );
}
