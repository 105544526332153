import React from 'react';
import { useAuth0 } from '../Authentication/Auth0';
import FibrifyItemsSelectionDialog from './FibrifyItemsSelectionDialog';
import MaterialTypeService from '../../services/MaterialTypeService';

export default function FibrifyMaterialTypeSelectionDialog({
  title,
  multipleSelection = false,
  filters = {},
  currentMaterialType = [],
  onSave,
  saveButtonLabel = 'Confirm',
  onClose,
}) {
  const { profile } = useAuth0();

  const loadData = React.useCallback(
    async ({ page, pageSize, search }, config) => {
      const params = {
        page: page,
        pageSize: pageSize,
        order: ['created_at:desc'],
        ...filters,
      };

      if (search) {
        params.search = search;
      }

      const { data, pagination } = await MaterialTypeService.getMaterialTypes(
        params,
        config,
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount,
      };
    },
    [profile],
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        hideable: false,
        flex: 1,
        minWidth: 200,
        sortable: false,
        visibilityBreakpoint: 'sm',
      },
      {
        headerName: 'Load Temperature °C',
        field: 'loadTemperature',
        minWidth: 200,
        sortable: false,
        visibilityBreakpoint: 'sm',
      },
      {
        headerName: 'Max Temperature °C',
        field: 'maxTemperature',
        minWidth: 200,
        sortable: false,
        visibilityBreakpoint: 'sm',
      },
      {
        headerName: 'Min Temperature °C',
        field: 'minTemperature',
        minWidth: 200,
        sortable: false,
        visibilityBreakpoint: 'sm',
      },
      {
        headerName: 'Avg linear density g/m',
        field: 'averageLinearDensity',
        visibilityBreakpoint: 'lg',
        minWidth: 200,
        sortable: false,
      },
    ],
    [],
  );

  return (
    <FibrifyItemsSelectionDialog
      loadData={loadData}
      columns={columns}
      title={title}
      onSave={onSave}
      onClose={onClose}
      selectedItems={currentMaterialType}
      multipleSelection={multipleSelection}
      saveButtonLabel={saveButtonLabel}
      searchPlaceholder="Search by material type name"
    />
  );
}
