import TitanDialog from '../Titan/TitanDialog';
import TextField from '@mui/material/TextField';
import React from 'react';

export default function PhotosCommentDialog({ onChangeValue, onClose, photo }) {
  const [comment, setComment] = React.useState(photo.comment || '');

  return (
    <TitanDialog
      title={`Add Comment`}
      onClose={() => onClose()}
      maxWidth="md"
      fullWidth
      onSave={() => onChangeValue(comment)}
    >
      <TextField
        autoFocus
        label="Comment"
        type="text"
        value={comment}
        fullWidth
        margin="normal"
        onChange={e => setComment(e.target.value)}
        variant="outlined"
      />
    </TitanDialog>
  );
}
