import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function TitanCircularProgress({ fullHeight, height = '100%' }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: 2,
        height: fullHeight ? '100vh' : height
      }}
    >
      <CircularProgress />
    </Box>
  );
}
