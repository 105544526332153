import React from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import Paper from '@mui/material/Paper';
import TitanTakePhotosDialog from '../Titan/TitanTakePhotosDialog';
import EmptyGallerySelectionMenu from './EmptyGallerySelectionMenu';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CommentIcon from '@mui/icons-material/Comment';
import AddCommentIcon from '@mui/icons-material/AddComment';
import PhotosCommentDialog from './PhotosCommentDialog';
import { Stack } from '@mui/material';

export default function FibrifyPhotosGallery({
  photos,
  hideUploadButtons,
  onAddNewPhotos,
  onDeletePhoto,
  onUpdatePhoto,
  photoGaleryFiltersContent
}) {
  const [openTakePhotoDialog, setOpenTakePhotoDialog] = React.useState();
  const [photoCommentDialog, setPhotoCommentDialog] = React.useState({
    isOpen: false
  });
  const [activeTab, setActiveTab] = React.useState();

  const renderGalleryThumbInner = item => {
    return (
      <>
        <span className="image-gallery-thumbnail-inner">
          <Box
            onClick={event => {
              event.stopPropagation();
              return onDeletePhoto(item.id);
            }}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              cursor: 'pointer',
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CloseIcon fontSize="small" />
          </Box>
          <img
            className="image-gallery-thumbnail-image"
            src={item.thumbnail}
            alt=""
          />
        </span>
        <Button
          size="small"
          onClick={event => {
            event.stopPropagation();
            setPhotoCommentDialog({ isOpen: true, photo: item });
          }}
          component="span"
          sx={{ width: '100%' }}
        >
          {item.comment ? (
            <CommentIcon fontSize="small" />
          ) : (
            <AddCommentIcon fontSize="small" />
          )}
        </Button>
      </>
    );
  };

  return (
    <Paper sx={{ p: 2 }}>
      {photos.length !== 0 ? (
        <>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            mt={2}
            mb={2}
          >
            {photoGaleryFiltersContent ? (
              <Stack
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-around"
              >
                {photoGaleryFiltersContent}
              </Stack>
            ) : null}
            <Button
              variant={'contained'}
              onClick={() => setOpenTakePhotoDialog(true)}
            >
              Upload new photos
            </Button>
          </Stack>

          <ImageGallery
            items={photos.map(photo => ({
              original: photo.url,
              thumbnail: photo.url,
              comment: photo.comment,
              id: photo.id
            }))}
            renderThumbInner={renderGalleryThumbInner.bind(this)}
          />
        </>
      ) : (
        <EmptyGallerySelectionMenu
          hideUploadButtons={hideUploadButtons}
          onUploadPhotoSelection={() => {
            setActiveTab('uploadPhoto');
            setOpenTakePhotoDialog(true);
          }}
          onTakePhotoSelection={() => {
            setActiveTab('takePhoto');
            setOpenTakePhotoDialog(true);
          }}
        />
      )}

      {openTakePhotoDialog && (
        <TitanTakePhotosDialog
          activeTab={activeTab}
          photos={photos}
          onClose={() => setOpenTakePhotoDialog(false)}
          onSave={onAddNewPhotos}
          onDelete={onDeletePhoto}
        />
      )}

      {photoCommentDialog.isOpen && (
        <PhotosCommentDialog
          onClose={() => setPhotoCommentDialog({ isOpen: false, photo: null })}
          onChangeValue={async comment => {
            await onUpdatePhoto(photoCommentDialog.photo.id, { comment });
          }}
          photo={photoCommentDialog.photo}
        />
      )}
    </Paper>
  );
}
