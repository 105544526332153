import React from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';

export default function ActionsMenu(props) {
  const {
    items = [],
    icon: Icon = MoreVertIcon,
    iconSize = 'small',
    type = 'icon',
    text,
    variant = 'outlined',
    color = 'default',
    size = 'small',
    data
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = e => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      {type === 'button' && (
        <Button
          variant={variant}
          color={color}
          size={size}
          startIcon={<Icon />}
          onClick={handleClick}
        >
          {text}
        </Button>
      )}
      {type === 'icon' && (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Icon fontSize={iconSize} />
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items &&
          items.map(item => {
            const ItemIcon = item.icon;

            return (
              <MenuItem
                key={item.title}
                disabled={item.disabled}
                onClick={e => {
                  e.stopPropagation();
                  handleClose(e);
                  item.onClick(e, data);
                }}
              >
                {item.icon && (
                  <ListItemIcon>
                    <ItemIcon fontSize="small" />
                  </ListItemIcon>
                )}
                {item.image && (
                  <ListItemIcon>
                    <img src={item.image} alt="" />
                  </ListItemIcon>
                )}
                <Typography variant="inherit">{item.title}</Typography>
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
}
