import React from 'react';

import { useTitan } from '../Titan/Titan';
import { useAuth0 } from './../Authentication/Auth0';

import ActionsMenu from '../Menu/ActionsMenu';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import PreBuildChecklistLink from '../PreBuildingChecklist/PreBuildChecklistLink';
import PreBuildingChecklistDialog from '../PreBuildingChecklist/PreBuildingChecklistDialog';

import PreBuildingChecklistService from '../../services/PreBuildingChecklistService';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ROLES } from '../../constants';

export default function PreBuildingChecklistsManageList() {
  const { pushSnackbar } = useTitan();

  const [openChecklistDialog, setOpenChecklistDialog] = React.useState(false);
  const [dialogMode, setDialogMode] = React.useState('create');
  const [editableChecklist, setEditableChecklist] = React.useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const { profile, roles } = useAuth0();

  const isOrganizationAdmin = React.useMemo(
    () => roles && roles.find(r => r.id === ROLES.ADMIN) !== undefined,
    [roles]
  );

  const [loading, setLoading] = React.useState(true);
  const [checklists, setChecklists] = React.useState([]);

  const loadChecklists = React.useCallback(async () => {
    setLoading(true);
    const isSuperAdmin =
      roles && roles.find(r => r.id === ROLES.SUPER_ADMIN) !== undefined;

    const params = {
      organizationId: isSuperAdmin ? null : profile.organization.id
    };

    const { data } = await PreBuildingChecklistService.getChecklists(params);

    setChecklists(data);
    setLoading(false);
  }, [setLoading, profile, setChecklists]);

  const onSaveDialog = checklist => {
    if (dialogMode === 'create') {
      setChecklists(prev => [checklist, ...prev]);
    } else {
      setChecklists(prev =>
        prev.map(o => (o.id === checklist.id ? checklist : o))
      );
    }
  };

  const onCloseDialog = () => {
    setOpenChecklistDialog(false);
  };

  const onDelete = async () => {
    await PreBuildingChecklistService.deleteChecklist(editableChecklist.id);

    pushSnackbar('Checklist successfully deleted', { variant: 'success' });
    setOpenDeleteDialog(false);
    setChecklists(prev => prev.filter(o => o.id !== editableChecklist.id));
  };

  React.useEffect(() => {
    loadChecklists();
  }, [loadChecklists]);

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <PreBuildChecklistLink preBuildingChecklist={row} />
        )
      },
      {
        headerName: '',
        field: 'actions',
        width: 60,
        sortable: false,
        renderCell: ({ row }) => (
          <ActionsMenu
            items={[
              {
                title: 'Edit',
                icon: EditIcon,
                onClick: () => {
                  setEditableChecklist(row);
                  setDialogMode('edit');
                  setOpenChecklistDialog(true);
                }
              },
              {
                title: 'Delete',
                icon: DeleteIcon,
                onClick: () => {
                  setEditableChecklist(row);
                  setOpenDeleteDialog(true);
                }
              }
            ]}
          />
        )
      }
    ],
    []
  );

  return (
    <>
      <TitanDataGrid
        title="Pre Building Checklists"
        loading={loading}
        showSearch={false}
        pagination={false}
        columns={columns}
        rows={checklists ? checklists : []}
        rowCount={checklists ? checklists.length : 0}
        onCreateClick={() => {
          setEditableChecklist({});
          setDialogMode('create');
          setOpenChecklistDialog(true);
        }}
        onCreateClickDisabled={isOrganizationAdmin && checklists.length > 0}
        onCreateClickDisabledTooltip="An organization can have only one checklist"
        createButtonLabel="Pre Building Checklist"
      />

      {openChecklistDialog && (
        <PreBuildingChecklistDialog
          preBuildingChecklist={editableChecklist}
          mode={dialogMode}
          onClose={() => onCloseDialog()}
          onSave={onSaveDialog}
        />
      )}

      {openDeleteDialog && (
        <TitanConfirmationDialog
          title="Delete checklist?"
          message={`Are you sure you want to delete checklist ${
            editableChecklist.name
          }?`}
          onClose={() => setOpenDeleteDialog(false)}
          onConfirm={() => onDelete()}
        />
      )}
    </>
  );
}
