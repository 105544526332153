import React, { useRef } from 'react';
import useWebSocket from 'react-use-websocket';
import { WEB_SOCKET_ACTIONS } from '../../constants';
import moment from 'moment';
import PrinterService from '../../services/PrinterService';
import { useTitan } from '../Titan/Titan';

export default function useBuildModuleTemperature(
  buildModuleId,
  buildJobId,
  from,
  to,
) {
  const [loading, setLoading] = React.useState(true);
  const { getWebSocketUrl, getWebSocketOptions } = useTitan();

  const series = React.useMemo(
    () => [
      {
        key: 'tool0',
        label: 'FG',
        csvLabel: 'FG',
      },
      {
        key: 'tool1',
        label: 'PN',
        csvLabel: 'PN',
      },
      {
        key: 'bed',
        label: 'Bed',
        csvLabel: 'Bed',
      },
      {
        key: 'chamber',
        label: 'Chamber',
        csvLabel: 'Build Chamber',
      },
      {
        key: 'materialChamber',
        label: 'Material Chamber',
        csvLabel: 'Material Chamber',
      },
      {
        key: 'rollforming',
        label: 'Rollforming',
        csvLabel: 'Rollforming',
      },
      {
        key: 'printHead',
        label: 'Print Head',
        csvLabel: 'Print Head',
      },
      {
        key: 'water',
        label: 'Water',
        csvLabel: 'Water',
      },
    ],
    [],
  );

  const [actualSeries, setActualSeries] = React.useState(() => {
    return series.map((x) => ({
      key: x.key,
      label: `${x.label} (A)`,
      data: [],
    }));
  });

  const [targetSeries, setTargetSeries] = React.useState(() => {
    return series.map((x) => ({
      key: x.key,
      label: `${x.label} (T)`,
      data: [],
    }));
  });

  const [currentTemperature, setCurrentTemperature] = React.useState(() => {
    const currentTemperaturesObject = {};

    series.forEach((x) => {
      currentTemperaturesObject[x.key] = { actual: 0, target: 0 };
    });

    return currentTemperaturesObject;
  });

  const [csvData, setCSVData] = React.useState([]);

  const csvHeaders = React.useMemo(() => {
    return [
      { label: 'Time', key: 'timestamp' },
      ...series.map((x) => ({
        key: x.key,
        label: `${x.csvLabel}`,
      })),
    ];
  }, [series]);

  const { lastJsonMessage } = useWebSocket(
    getWebSocketUrl,
    getWebSocketOptions(),
  );

  let minTimestamp = useRef(new Date().getTime());

  React.useEffect(() => {
    if (
      lastJsonMessage !== null &&
      lastJsonMessage.action === WEB_SOCKET_ACTIONS.BUILD_MODULE_TELEMETRY &&
      lastJsonMessage.data.buildModuleId === buildModuleId &&
      (!buildJobId || lastJsonMessage.data.buildJobId === buildJobId)
    ) {
      const temperature = lastJsonMessage.data.data;
      const timestamp = lastJsonMessage.data.timestamp;

      setActualSeries((actualSeries) =>
        actualSeries.map((series) => {
          if (temperature[series.key] && temperature[series.key].actual) {
            return {
              key: series.key,
              label: series.label,
              data: series.data.concat([
                {
                  x: timestamp,
                  y: temperature[series.key].actual,
                },
              ]),
            };
          }

          return series;
        }),
      );

      setTargetSeries((targetSeries) =>
        targetSeries.map((series) => {
          if (temperature[series.key] && temperature[series.key].target) {
            return {
              key: series.key,
              label: series.label,
              data: series.data.concat([
                {
                  x: timestamp,
                  y: temperature[series.key].target,
                },
              ]),
            };
          }

          return series;
        }),
      );

      if (!buildJobId) {
        setCurrentTemperature((currentTemperature) => {
          Object.keys(currentTemperature).forEach((key) => {
            if (temperature[key] && temperature[key].actual) {
              currentTemperature[key].actual = parseInt(
                temperature[key].actual,
                10,
              );
            }

            if (temperature[key] && temperature[key].target) {
              currentTemperature[key].target = parseInt(
                temperature[key].target,
                10,
              );
            }
          });

          return currentTemperature;
        });
      }

      let cvsTimeStamp = moment(timestamp).format('YYYY-MM-DD HH:mm:ss');

      if (!!buildJobId) {
        minTimestamp.current = Math.min(timestamp, minTimestamp.current);
        cvsTimeStamp = Math.trunc((timestamp - minTimestamp.current) / 1000);
      }

      setCSVData((csvData) => {
        const csvDataRow = {
          timestamp: cvsTimeStamp,
        };

        Object.keys(temperature).forEach((key) => {
          csvDataRow[key] =
            temperature[key] && temperature[key].actual
              ? temperature[key].actual
              : '';
        });

        return [...csvData, csvDataRow];
      });
    }
  }, [lastJsonMessage]);

  const loadBuildModuleTemperature = async () => {
    let params = {};

    if (buildJobId) {
      params.buildJobId = buildJobId;

      if (from) {
        params.from = moment(from).utc().toISOString();
      }

      if (to) {
        params.to = moment(to).utc().toISOString();
      }
    } else {
      params.from = moment().add(-30, 'minutes').utc().toISOString();
    }

    const data = await PrinterService.getTelemetry(buildModuleId, params);

    if (!data.length) {
      setLoading(false);

      return;
    }

    const actual = {};
    const target = {};

    const partNames = csvHeaders.map((header) => header.key);

    partNames.forEach((key) => {
      actual[key] = {
        key,
        data: [],
      };
      target[key] = {
        key,
        data: [],
      };
    });

    data.forEach((item) => {
      const timestamp = new Date(item.timestamp).getTime();

      let cvsTimeStamp = moment(item.timestamp).format('YYYY-MM-DD HH:mm:ss');

      if (!!buildJobId) {
        minTimestamp.current = Math.min(timestamp, minTimestamp.current);
        cvsTimeStamp = Math.trunc(
          (item.timestamp - minTimestamp.current) / 1000,
        );
      }

      const csvDataRow = {
        timestamp: cvsTimeStamp,
      };

      if (!item.data) {
        return;
      }

      const temperature = item.data;

      partNames.forEach((key) => {
        if (temperature[key] && temperature[key].actual) {
          actual[key].data.push({
            x: timestamp,
            y: temperature[key].actual,
          });

          csvDataRow[key] = temperature[key].actual;
        }

        if (temperature[key] && temperature[key].target) {
          if (
            target[key].data.length !== 0 &&
            target[key].data[target[key].data.length - 1].y ===
              temperature[key].target
          ) {
            target[key].data[target[key].data.length - 1].x = timestamp;
          } else {
            target[key].data.push({
              x: timestamp,
              y: temperature[key].target,
            });
          }
        }
      });

      setCSVData((csvData) => {
        return [...csvData, csvDataRow];
      });
    });

    setActualSeries((actualSeries) =>
      actualSeries.map((series) => {
        return {
          key: series.key,
          label: series.label,
          data: actual[series.key] ? actual[series.key].data : [],
        };
      }),
    );

    setTargetSeries((targetSeries) =>
      targetSeries.map((series) => {
        return {
          key: series.key,
          label: series.label,
          data: target[series.key] ? target[series.key].data : [],
        };
      }),
    );

    if (!buildJobId) {
      setCurrentTemperature((currentTemperature) => {
        Object.keys(currentTemperature).forEach((key) => {
          currentTemperature[key] = {
            actual: actual[key].data[0]
              ? parseInt(actual[key].data[0].y, 10)
              : 0,
            target: target[key].data[0]
              ? parseInt(target[key].data[0].y, 10)
              : 0,
          };
        });

        return currentTemperature;
      });
    }

    setLoading(false);
  };

  React.useEffect(() => {
    setLoading(true);

    loadBuildModuleTemperature();
  }, [buildModuleId]);

  return {
    loading,
    actualSeries,
    targetSeries,
    currentTemperature,
    csvHeaders,
    csvData,
  };
}
