import Logo from '../../assets/logo.png';
import React from 'react';
import { colors } from '../Theme/vars';

export default function TitanLogo() {
  return (
    <div
      style={{
        backgroundColor: colors.BLACK,
        width: 290,
        height: 293,
        borderRadius: 20,
        display: 'inline-block'
      }}
    >
      <img src={Logo} alt="Titan" />
    </div>
  );
}
