import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { makeStyles } from '@mui/styles';
import { SnackbarContent } from 'notistack';

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#43A047',
    color: '#fff',
    padding: '20px 10px'
  }
}));

const SnackbarWithLink = React.forwardRef((props, ref) => {
  const { message } = props;
  const classes = useStyles();
  return (
    <SnackbarContent ref={ref}>
      <Card className={classes.card}>
        <CheckCircleIcon />
        <Typography
          sx={{
            margin: '0 5px'
          }}
        >
          {message}
        </Typography>
      </Card>
    </SnackbarContent>
  );
});

export default SnackbarWithLink;
