import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Link } from '@mui/material';
import ROUTES from '../../constants/routes';

export default function PreBuildChecklistLink({
  preBuildingChecklist,
  children,
  useName = true,
  onClick = e => e.stopPropagation()
}) {
  const location = useLocation();

  return (
    <Link
      onClick={onClick}
      to={{
        pathname: ROUTES.PRE_BUILDING_CHECKLIST(preBuildingChecklist.id),
        state: { from: location.pathname }
      }}
      component={RouterLink}
    >
      {useName ? preBuildingChecklist.name : children}
    </Link>
  );
}
