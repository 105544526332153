import React from 'react';
import TextField from '@mui/material/TextField';
import TitanDialog from '../Titan/TitanDialog';

export default function CommentDialog({ comment, onClose, onSave }) {
  const [updatedComment, setUpdatedComment] = React.useState(comment);

  return (
    <TitanDialog
      title={'Update comment'}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      onSave={() => onSave(updatedComment)}
    >
      <TextField
        autoFocus
        id="comment"
        label="Comment"
        type="text"
        value={updatedComment}
        fullWidth
        margin="normal"
        onChange={e => setUpdatedComment(e.target.value)}
        variant="outlined"
      />
    </TitanDialog>
  );
}
