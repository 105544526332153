import React from 'react';
import { makeStyles } from '@mui/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import TitanPopover from '../Titan/TitanPopover';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 450,
    padding: theme.spacing(2)
  },
  activeJobs: {
    width: {
      xs: '50px',
      md: 'auto'
    }
  },
  fusionModulesFilter: {
    marginTop: theme.spacing(1)
  }
}));

export default function FusionJobsFilter({
  showAll,
  setShowAll,
  hideFusionModulesFilter = false,
  fusionModules,
  chosenFusionModules,
  onFusionModulesChange
}) {
  const classes = useStyles();

  return (
    <TitanPopover icon={FilterListIcon}>
      <div className={classes.root}>
        <FormControl>
          <FormLabel>Jobs Type</FormLabel>
          <Stack direction="row" spacing={1} alignItems="center">
            <RadioGroup
              value={showAll ? 'all' : 'current'}
              onChange={e =>
                setShowAll(e.target.value === 'all' ? true : false)
              }
            >
              <FormControlLabel
                value="current"
                control={<Radio />}
                label="Current version"
              />
              <FormControlLabel
                value="all"
                control={<Radio />}
                label="All versions"
              />
            </RadioGroup>
          </Stack>
        </FormControl>

        {!hideFusionModulesFilter && (
          <FormControl margin="normal">
            <FormLabel>Fusion Modules</FormLabel>
            <Autocomplete
              multiple
              id="fusion-modules-filter"
              value={chosenFusionModules}
              onChange={onFusionModulesChange}
              options={fusionModules}
              getOptionLabel={option => option.name}
              filterSelectedOptions
              className={classes.fusionModulesFilter}
              renderInput={params => (
                <TextField {...params} placeholder="Select Fusion Modules..." />
              )}
            />
          </FormControl>
        )}
      </div>
    </TitanPopover>
  );
}
