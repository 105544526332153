import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Link } from '@mui/material';
import ROUTES from '../../constants/routes';
import { useTitan } from '../Titan/Titan';

export default function OrganizationLink({
  organization,
  children,
  useName = true,
  onClick = e => e.stopPropagation()
}) {
  const location = useLocation();

  const { addPageToPageHistory, preparePageObjectForLink } = useTitan();

  const handleClick = e => {
    addPageToPageHistory(
      preparePageObjectForLink(
        'ORGANIZATION_DASHBOARD',
        organization.id,
        organization.name || `Organization Dashboard`,
        ROUTES.ORGANIZATION_TAB(organization.id, 'users'),
        'Users'
      )
    );

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Link
      onClick={handleClick}
      to={{
        pathname: ROUTES.ORGANIZATION(organization.id),
        state: { from: location.pathname }
      }}
      component={RouterLink}
    >
      {useName ? organization.name : children}
    </Link>
  );
}
