import React from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import FormControlLabel from '@mui/material/FormControlLabel';
import TitanPopover from '../Titan/TitanPopover';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

export default function PreformsTableFilter({
  buildPlanVersionType,
  setBuildPlanVersionType,
  fusionPlanVersionType,
  setFusionPlanVersionType
}) {
  return (
    <TitanPopover icon={FilterListIcon}>
      <Box p={2} width={260}>
        <Typography variant="subtitle1">Build Plan version:</Typography>
        <FormControl component="fieldset">
          {/* Page: Build Plans > Preforms  */}
          {buildPlanVersionType !== undefined && (
            <RadioGroup
              value={buildPlanVersionType}
              onChange={e => setBuildPlanVersionType(e.target.value)}
            >
              <FormControlLabel
                value="current"
                control={<Radio />}
                label="Current version"
              />
              <FormControlLabel
                value="all"
                control={<Radio />}
                label="All versions"
              />
            </RadioGroup>
          )}
          {/* Page: Fusion Plans > Final Parts  */}
          {fusionPlanVersionType !== undefined && (
            <RadioGroup
              value={fusionPlanVersionType}
              onChange={e => setFusionPlanVersionType(e.target.value)}
            >
              <FormControlLabel
                value="current"
                control={<Radio />}
                label="Current version"
              />
              <FormControlLabel
                value="all"
                control={<Radio />}
                label="All versions"
              />
            </RadioGroup>
          )}
        </FormControl>
      </Box>
    </TitanPopover>
  );
}
