import React from 'react';
import ComponentStatus from '../Component/ComponentStatus';
import useBuildModuleState from './use-build-module-state';

export default function BuildModuleState({
  buildModule,
  showVersion = true,
  layout = 'horizontal'
}) {
  const { buildModuleState } = useBuildModuleState(buildModule);

  return (
    <ComponentStatus
      component={{
        status: buildModuleState,
        progress: buildModule.progress,
        deviceVersion: showVersion ? buildModule.deviceVersion : null
      }}
      layout={layout}
    />
  );
}
