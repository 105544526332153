import TitanPopover from '../Titan/TitanPopover';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Checkbox } from '@mui/material';
import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

const availableStates = ['Release', 'PreRelease', 'Alpha'];

export default function FDSReleasesFilter({ states, onChangeStates }) {
  const handleStateChange = e => {
    let updatedStates;

    if (e.target.checked) {
      updatedStates = [...states, e.target.value];
    } else {
      updatedStates = states.filter(ps => ps !== e.target.value);
    }

    onChangeStates(updatedStates);
  };

  return (
    <TitanPopover icon={FilterListIcon}>
      <FormGroup>
        {availableStates.map(state => (
          <FormControlLabel
            key={state}
            control={
              <Checkbox
                checked={states.includes(state)}
                onChange={handleStateChange}
                value={state}
              />
            }
            label={state}
          />
        ))}
      </FormGroup>
    </TitanPopover>
  );
}
