import { MANUFACTURING_ORDER_STATES } from '../../constants';

export const ManufacturingOrderStateLabels = {
  [MANUFACTURING_ORDER_STATES.OPEN]: 'Open',
  [MANUFACTURING_ORDER_STATES.IN_PROGRESS]: 'In progress',
  [MANUFACTURING_ORDER_STATES.DONE]: 'Done',
  [MANUFACTURING_ORDER_STATES.PAUSED]: 'Paused',
  [MANUFACTURING_ORDER_STATES.ABORTED]: 'Aborted',
};

const ManufacturingOrderState = ({ manufacturingOrder }) => {
  return manufacturingOrder.state
    ? ManufacturingOrderStateLabels[manufacturingOrder.state]
    : '';
};

export default ManufacturingOrderState;
