import React from 'react';
import TitanPage from '../Titan/TitanPage';
import { ROUTES } from '../../constants';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import TermsForm from './TermsForm';

export default function NewTermsPage() {
  const history = useHistory();
  const location = useLocation();

  const breadcrumbs = React.useMemo(() => [
    {
      path: ROUTES.ADMIN_DASHBOARD,
      name: 'Admin Dashboard'
    },
    {
      name: 'Terms And Conditions',
      disabled: true
    },
    {
      path: ROUTES.NEW_TERMS_AND_CONDITIONS,
      name: 'New',
      disabled: true
    }
  ]);

  return (
    <TitanPage
      title="New Terms And Conditions"
      breadcrumbs={breadcrumbs}
      onBackButtonClick={() => {
        history.push({
          pathname: ROUTES.ADMIN_DASHBOARD_TAB('terms-and-conditions'),
          state: { from: location.pathname }
        });
      }}
      headerContent={
        <Button
          variant={'outlined'}
          onClick={() => {
            history.push({
              pathname: ROUTES.ADMIN_DASHBOARD_TAB('terms-and-conditions'),
              state: { from: location.pathname }
            });
          }}
        >
          Cancel
        </Button>
      }
    >
      <TermsForm mode="Create" />
    </TitanPage>
  );
}
