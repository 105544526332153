import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Link, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import OverflowTip from '../ExpandRenderCell/OverflowTip';
import { useTitan } from '../Titan/Titan';
import { ROUTES } from '../../constants';

const useStyles = makeStyles(theme => ({
  linkBox: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center'
  },
  hiddenOverflowBlock: {
    overflow: 'hidden'
  },
  chip: {
    fontSize: '0.7rem',
    marginLeft: '8px'
  }
}));

export default function ManufacturingOrderLink(props) {
  const {
    manufacturingOrder,
    width,
    disabled = false,
    onClick = e => e.stopPropagation()
  } = props;
  const location = useLocation();
  const classes = useStyles();

  const { addPageToPageHistory, preparePageObjectForLink } = useTitan();

  const handleClick = e => {
    addPageToPageHistory(
      preparePageObjectForLink(
        'MANUFACTURING_ORDER',
        manufacturingOrder.id,
        manufacturingOrder.name,
        ROUTES.MANUFACTURING_ORDER(manufacturingOrder.id, 'summary'),
        'Summary'
      )
    );

    if (onClick) {
      onClick(e);
    }
  };

  if (disabled) {
    return <span>{manufacturingOrder.name}</span>;
  }

  return (
    <Box className={classes.linkBox}>
      <Link
        className={classes.hiddenOverflowBlock}
        onClick={handleClick}
        to={{
          pathname: ROUTES.MANUFACTURING_ORDER(manufacturingOrder.id),
          state: { from: location.pathname }
        }}
        component={RouterLink}
      >
        {width ? (
          <OverflowTip width={width}>{manufacturingOrder.name}</OverflowTip>
        ) : (
          <span>{manufacturingOrder.name}</span>
        )}
      </Link>
      {manufacturingOrder?.deletedAt && (
        <Chip
          label="DELETED"
          color="primary"
          variant="outlined"
          className={classes.chip}
        />
      )}
    </Box>
  );
}
