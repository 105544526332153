import React from 'react';

import { useBuildDialog } from './BuildDialog';

import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import BuildModuleLink from '../BuildModule/BuildModuleLink';
import BuildModuleState from '../BuildModule/BuildModuleState';

export default function BuildDialogReviewStep() {
  const { selectedBuildModules, selectedManufacturingOrder } = useBuildDialog();

  const loadBuildModules = React.useCallback(async () => {
    return {
      data: selectedBuildModules,
      page: 1,
      totalCount: selectedBuildModules.length,
    };
  }, [selectedBuildModules]);

  const buildModuleColumns = React.useMemo(
    () => [
      {
        headerName: 'Build Module',
        field: 'name',
        visibilityBreakpoint: 'sm',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <BuildModuleLink buildModule={row} />,
      },
      {
        headerName: 'Status',
        field: 'status',
        visibilityBreakpoint: 'sm',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => <BuildModuleState buildModule={row} />,
      },
      {
        headerName: 'Target',
        field: 'target',
        visibilityBreakpoint: 'sm',
        minWidth: 250,
        sortable: false,
        renderCell: ({ row }) =>
          row.isAvailableToBuild ? 'Build' : 'Add to queue',
      },
    ],
    [],
  );

  const buildModulesDataGridProps = useTitanDataGrid(loadBuildModules, {
    columns: buildModuleColumns,
  });

  return (
    <>
      <TitanDataGrid
        {...buildModulesDataGridProps}
        hideFooter
        showSearch={false}
      />

      <Stack direction="row" sx={{ mt: 2 }} spacing={2}>
        <Typography variant="subtitle2">
          Selected manufacturing order:
        </Typography>
        <Typography>
          {selectedManufacturingOrder
            ? selectedManufacturingOrder.name
            : 'Not selected'}
        </Typography>
      </Stack>
    </>
  );
}
