import React from 'react';
import { format } from 'date-fns';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import BuildModuleLink from '../BuildModule/BuildModuleLink';
import SpoolService from '../../services/SpoolService';

function SpoolBuildModulesHistory({ spool }) {
  const buildModuleHistoryColumns = React.useMemo(
    () => [
      {
        headerName: 'Build Module',
        field: 'name',
        visibilityBreakpoint: 'sm',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <BuildModuleLink buildModule={row.buildModule} />
        )
      },
      {
        headerName: 'Load Time',
        field: 'loadTime',
        visibilityBreakpoint: 'sm',
        minWidth: 300,
        sortable: false,
        renderCell: ({ row }) =>
          row.loadTime
            ? format(new Date(row.loadTime), 'yyyy-MM-dd HH:mm:ss')
            : null
      },
      {
        headerName: 'Unload Time',
        field: 'unloadTime',
        visibilityBreakpoint: 'sm',
        minWidth: 300,
        sortable: false,
        renderCell: ({ row }) =>
          row.unloadTime
            ? format(new Date(row.unloadTime), 'yyyy-MM-dd HH:mm:ss')
            : null
      }
    ],
    []
  );

  const loadBuildModulesHistory = React.useCallback(
    async params => {
      const {
        data,
        pagination
      } = await SpoolService.getSpoolBuildModuleHistory(spool.id, {
        ...params
      });

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount
      };
    },
    [spool]
  );

  const buildModulesHistoryGridProps = useTitanDataGrid(
    loadBuildModulesHistory,
    {
      columns: buildModuleHistoryColumns
    }
  );

  return <TitanDataGrid {...buildModulesHistoryGridProps} showSearch={false} />;
}

export default SpoolBuildModulesHistory;
