import axios from 'axios';

export default class MaterialTypeService {
  static async getMaterialTypes(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/material-types`, {
        ...config,
        params
      })
      .then(res => res.data);
  }

  static async getMaterialType(materialTypeId, params = {}) {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/material-types/${materialTypeId}`,
        {
          params
        }
      )
      .then(res => res.data.data);
  }

  static async checkNameExists(name) {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/material-types/check-name-exists`,
        {
          params: {
            name
          }
        }
      )
      .then(res => res.data.exists);
  }

  static async createMaterialType(data) {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/material-types`, data)
      .then(res => res.data.data);
  }

  static async updateMaterialType(materialTypeId, data) {
    return axios
      .patch(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/material-types/${materialTypeId}`,
        data
      )
      .then(res => res.data.data);
  }

  static async deleteMaterialType(materialTypeId) {
    return axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/material-types/${materialTypeId}`
      )
      .then(res => res.data);
  }
}
