import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TitanPage from '../Titan/TitanPage';

export default function NotFound() {
  return (
    <TitanPage>
      <Box
        style={{
          height: '70vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography variant="h4">Sorry, this page does not exist.</Typography>
      </Box>
    </TitanPage>
  );
}
