import React from 'react';
import FilterChips from '../FilterChips/FilterChips';

export default function BuildPlansChips({
  filterOwnedBuildPlans,
  onChangeFilterOwnedBuildPlans,
  filterAssignedBuildPlans,
  onChangeFilterAssignedBuildPlans,
  filterApprovedBuildPlans,
  onChangeFilterApprovedBuildPlans,
  filterPrototypes,
  onChangeFilterPrototypes,
  chosenProjects,
  onChosenProjectsChange
}) {
  const items = React.useMemo(() => {
    const items = [
      {
        label: 'All Plans',
        onClick: () => {
          onChangeFilterOwnedBuildPlans(false);
          onChangeFilterAssignedBuildPlans(false);
          onChangeFilterApprovedBuildPlans(false);
        },
        isHighlighted:
          !filterOwnedBuildPlans &&
          !filterAssignedBuildPlans &&
          !filterApprovedBuildPlans
      },
      {
        label: 'Owned by Me',
        onClick: () => onChangeFilterOwnedBuildPlans(!filterOwnedBuildPlans),
        isHighlighted: filterOwnedBuildPlans
      },
      {
        label: 'Assigned to Me',
        onClick: () =>
          onChangeFilterAssignedBuildPlans(!filterAssignedBuildPlans),
        isHighlighted: filterAssignedBuildPlans
      },
      {
        label: 'Only Approved',
        onClick: () =>
          onChangeFilterApprovedBuildPlans(!filterApprovedBuildPlans),
        isHighlighted: filterApprovedBuildPlans
      },
      {
        label: 'Only Prototypes',
        onClick: () => onChangeFilterPrototypes(!filterPrototypes),
        isHighlighted: filterPrototypes
      }
    ];

    chosenProjects.forEach(project => {
      items.push({
        label: `Project: ${project.name}`,
        onDelete: () =>
          onChosenProjectsChange(
            null,
            chosenProjects.filter(p => p.id !== project.id)
          ),
        isHighlighted: true
      });
    });

    return items;
  }, [
    filterOwnedBuildPlans,
    filterAssignedBuildPlans,
    filterApprovedBuildPlans,
    filterPrototypes,
    chosenProjects
  ]);

  return <FilterChips items={items} />;
}
