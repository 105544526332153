import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from './constants';

export default function Logout() {
  const history = useHistory();

  React.useEffect(() => {
    history.push(ROUTES.WELCOME);
  }, []);

  return '';
}
