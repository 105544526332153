import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colors } from './vars';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1200,
      xl: 1536
    }
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '10px 20px',
          borderRadius: 2
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: `20px!important`
        }
      }
    },
    MuiTabScrollButton: {
      styleOverrides: {
        root: {
          '& svg': {
            fontSize: `1.8rem`
          }
        }
      }
    },
    MuiDataGridPinnedColumns: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff !important'
        }
      }
    }
  },

  typography: {
    fontFamily: 'Roboto',
    h1: {
      fontFamily: 'Roboto Condensed',
      fontWeight: 'bold',
      fontSize: 36,
      letterSpacing: 2,
      lineHeight: '42px'
    },
    h2: {
      fontFamily: 'Roboto Condensed',
      fontWeight: 'bold',
      fontSize: 24,
      letterSpacing: 2,
      lineHeight: '28px'
    },
    h3: {
      fontFamily: 'Roboto Condensed',
      fontWeight: 'bold',
      fontSize: 20,
      letterSpacing: '0.02em',
      lineHeight: '28px'
    },
    h4: {
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: '0.02em',
      lineHeight: '24px'
    },
    h5: {
      fontFamily: 'Roboto Condensed',
      fontSize: 24,
      fontWeight: 'bold',
      letterSpacing: 0.2
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 0.2
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0.02
    },
    body1: {
      fontSize: 16,
      letterSpacing: '0.02em',
      lineHeight: '20px'
    },
    body2: {
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '0.02em'
    },
    caption: {
      fontSize: 14,
      fontWeight: 300,
      letterSpacing: 0.2
    },
    button: {
      fontSize: 14,
      fontWeight: 'bold',
      letterSpacing: 1.25,
      textTransform: 'uppercase',
      lineHeight: '20px'
    },
    overline: {
      fontSize: 12,
      lineHeight: '18px',
      letterSpacing: '0.02em',
      textTransform: 'uppercase'
    }
  },
  palette: {
    primary: {
      main: colors.RED,
      dark: colors.DARK_RED,
      light: colors.RED_TRANSPARENT
    },
    secondary: {
      main: colors.GREY,
      light: colors.LIGHT_GREY
    },
    background: {
      default: colors.WHITE_GREY1
    }
  }
});

export default function TitanThemeProvider({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
