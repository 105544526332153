import React from 'react';
import TextField from '@mui/material/TextField';
import TitanDialog from '../Titan/TitanDialog';

export default function FibrifyStateCommentDialog({ state, onSave, onClose }) {
  const [comment, setComment] = React.useState(
    state.comment ? state.comment : ''
  );

  return (
    <TitanDialog
      title={`State comment`}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      cancelButtonLabel="Skip"
      onSave={async () => {
        await onSave({ comment });
        onClose();
      }}
    >
      <TextField
        autoFocus
        id="name"
        label="Comment"
        type="text"
        value={comment}
        fullWidth
        multiline
        rows={5}
        margin="normal"
        onChange={e => setComment(e.target.value)}
        variant="outlined"
      />
    </TitanDialog>
  );
}
