import React from 'react';
import { ROUTES } from '../../constants';
import TitanPage from '../Titan/TitanPage';
import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  content: {
    height: 'calc(100vh - 75px)',
    padding: theme.spacing(2),
    margin: '0 auto'
  }
}));

export default function SupportPage() {
  const classes = useStyles();

  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Support Request',
        path: ROUTES.SUPPORT,
        disabled: true
      }
    ],
    []
  );

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '5119752',
          formId: '3f66e09b-b656-4502-8bc2-df7a4c76faeb',
          target: '#hubspotForm'
        });
      }
    });
  }, []);

  return (
    <TitanPage breadcrumbs={breadcrumbs}>
      <Paper className={classes.content}>
        <div id="hubspotForm" />
      </Paper>
    </TitanPage>
  );
}
