import axios from 'axios';
import { COMPONENT_STATUSES } from '../constants';

export default class AssemblyService {
  static fuseAssembly(assemblyId, data) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/assemblies/${assemblyId}/fuse`,
        data,
      )
      .then((res) => res.data.data);
  }

  static isReadyToFuse(assembly, buildPlans, components) {
    return buildPlans.every((buildPlan) => {
      const buildPlanComponent = components
        .filter((c) => c.assemblyId === assembly.id)
        .find((component) => component.buildPlanId === buildPlan.id);

      if (!buildPlanComponent) {
        return false;
      }

      if (buildPlanComponent.status !== COMPONENT_STATUSES.BUILT) {
        return false;
      }

      return true;
    });
  }

  static createAssembly(data) {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/assemblies`, data)
      .then((res) => res.data.data);
  }

  static getAssembly(assemblyId, params) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/assemblies/${assemblyId}`, {
        params,
      })
      .then((res) => res.data.data);
  }

  static async updateAssembly(assemblyId, data) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v1/assemblies/${assemblyId}`,
        data,
      )
      .then((res) => res.data.data);
  }

  static getAssemblyPreforms(assemblyId, params) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/assemblies/${assemblyId}/preforms`,
        { params },
      )
      .then((res) => res.data);
  }

  static getTelemetry(assemblyId, params) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/assemblies/${assemblyId}/telemetry`,
        { params },
      )
      .then((res) => res.data.data);
  }

  static setStatus(assembly, status) {
    const data = {
      status,
    };

    if (assembly.status) {
      data.prevStatus = assembly.status;
    }

    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v1/assemblies/${assembly.id}`,
        data,
      )
      .then((res) => res.data.data);
  }

  static markFused(finalPartId) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v2/final-parts/${finalPartId}/mark-fused`,
      )
      .then((res) => res.data.data);
  }

  static markFusionScrap(finalPartId) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v2/final-parts/${finalPartId}/mark-fusion-scrap`,
      )
      .then((res) => res.data.data);
  }

  static prepareFinalPartCustomFields(finalPart) {
    return finalPart.customFields
      .concat(
        finalPart.finalPartType
          ? finalPart.finalPartType.customFields
              .sort((a, b) => (a.order && b.order ? a.order - b.order : 0))
              .filter(
                (preformTypeCustomField) =>
                  !finalPart.customFields.find(
                    (preformCustomField) =>
                      preformTypeCustomField.id ===
                      preformCustomField.finalPartTypeCustomFieldId,
                  ),
              )
              .map((finalPartTypeCustomField) => ({
                finalPartTypeCustomFieldId: finalPartTypeCustomField.id,
                preformId: finalPart.id,
                value: '',
              }))
          : [],
      )
      .reduce((res, customField) => {
        res[customField.finalPartTypeCustomFieldId] = customField;

        return res;
      }, {});
  }
}
