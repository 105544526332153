import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TitanInfoItem from '../Titan/TitanInfoItem';
import TitanDateTime from '../Titan/TitanDateTime';
import FusionModuleSimpleCard from '../FusionModules/FusionModuleCard';
import FinalPartsTable from '../FinalPart/FinalPartsTable';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Switch,
  Typography
} from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  scanButton: {
    float: 'right',
    margin: theme.spacing(1)
  },
  selectedMould: {
    marginTop: theme.spacing(2),

    display: 'flex'
  },
  selectedMouldLabel: {
    margin: '0 8px'
  },
  additionalFlags: {
    marginTop: theme.spacing(2),

    display: 'flex'
  },
  fuseMode: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  },
  checkboxTable: {
    '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
      display: 'none'
    }
  },
  divider: {
    marginRight: theme.spacing(2)
  }
}));

export default function ResendFusionJobDialog({ jobToResend }) {
  const classes = useStyles();

  const {
    id,
    fusor,
    fusionPlan,
    createdAt,
    isAutomatic,
    isExternalHeating,
    isMaterialSetup,
    isMouldSetup,
    mould
  } = jobToResend;

  return (
    <Grid
      sx={{
        marginTop: 4
      }}
    >
      <Grid container display="flex" wrap="wrap">
        <Grid
          item
          xs={6}
          lg={12}
          sx={{
            marginLeft: {
              xs: 2,
              lg: 0
            },
            marginBottom: {
              xs: 0,
              lg: 3
            },
            order: {
              xs: 2,
              lg: 1
            }
          }}
        >
          <Paper
            sx={{
              display: 'flex',
              padding: '20px',
              flexDirection: {
                xs: 'column',
                lg: 'row'
              },
              justifyContent: {
                xs: 'flex-start',
                lg: 'space-between'
              }
            }}
          >
            {fusionPlan ? (
              <>
                <Grid>
                  <TitanInfoItem label="Fusion Plan">
                    {fusionPlan.name}
                  </TitanInfoItem>
                </Grid>
                <Grid>
                  <TitanInfoItem label="Creator">
                    {fusionPlan.member && fusionPlan.member.name
                      ? fusionPlan.member.name
                      : '-'}
                  </TitanInfoItem>
                </Grid>
              </>
            ) : (
              ''
            )}

            <Grid>
              <TitanInfoItem label="Created">
                <TitanDateTime time={createdAt} />
              </TitanInfoItem>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          item
          xs={5}
          lg={3}
          sx={{
            paddingRight: {
              lg: 2.5
            },
            order: {
              xs: 1,
              lg: 2
            }
          }}
        >
          <FusionModuleSimpleCard device={fusor} />
        </Grid>
        <Grid
          item
          xs={12}
          lg={9}
          sx={{
            marginTop: {
              xs: 5,
              lg: 0
            },
            order: {
              xs: 3
            }
          }}
        >
          <FinalPartsTable
            fusionJobId={id}
            isHeaderShown={false}
            columnsToHide={['device']}
          />

          <div className={classes.selectedMould}>
            <Typography variant="subtitle2">Selected mould:</Typography>
            <Typography className={classes.selectedMouldLabel}>
              {mould ? mould.mouldIdentifier : 'Not selected'}
            </Typography>
          </div>

          <div className={classes.additionalFlags}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isExternalHeating}
                  disabled
                  color="primary"
                />
              }
              label="External Heating"
            />
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              className={classes.divider}
            />
            <FormControlLabel
              control={
                <Checkbox checked={isMouldSetup} disabled color="primary" />
              }
              label="Mould Setup"
            />
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              className={classes.divider}
            />
            <FormControlLabel
              control={
                <Checkbox checked={isMaterialSetup} disabled color="primary" />
              }
              label="Material Setup"
            />
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              className={classes.divider}
            />
            <div className={classes.fuseMode}>
              <Typography>Semi-Automatic</Typography>
              <Switch disabled checked={isAutomatic} color="primary" />
              <Typography>Automatic</Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
