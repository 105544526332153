import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { COMPONENT_STATUSES, ROUTES } from '../../constants';
import { colors } from '../Theme/vars';
import FusionModuleIcon from '../../assets/icons/fusion-module.svg';
import ComponentStatus from '../Component/ComponentStatus';
import Paper from '@mui/material/Paper';
import { Grid, Button, List } from '@mui/material';
import TitanInfoItem from '../Titan/TitanInfoItem';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import FusionJobLink from '../FusionJob/FusionJobLink';
import FinalPartLink from '../FinalPart/FinalPartLink';
import Box from '@mui/material/Box';
import useFusionModuleState from './use-fusion-module-state';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { useTitan } from '../Titan/Titan';

const useStyles = makeStyles({
  container: {
    border: `2.75px solid ${colors.WHITE}`,
  },
  selected: {
    border: `2.75px solid ${colors.RED}`,
  },
  content: {
    width: '100%',
  },
  statusBar: {
    width: '100%',
    backgroundColor: colors.GREY2,
  },
});

export default function FusionModuleCard({ fusionModule, onClick, selected }) {
  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();

  const [cardJob, setCardJob] = React.useState();
  const [showAllFinalParts, setShowAllFinalParts] = React.useState(false);

  const { addPageToPageHistory } = useTitan();

  const { state, progress, fusionModuleVersion } = useFusionModuleState(
    fusionModule.id,
  );

  React.useEffect(() => {
    if (fusionModule.prevFusionJob) {
      setCardJob(fusionModule.prevFusionJob);
    } else {
      setCardJob(null);
    }
  }, [fusionModule]);

  return (
    <Paper
      sx={{
        width: {
          xs: '100%',
        },
        minHeight: {
          xs: 280,
          md: 283,
          lg: 300,
        },
        p: 2,
        display: 'flex',
        cursor: 'pointer',
      }}
      className={classNames({
        [classes.container]: true,
        [classes.selected]: selected,
      })}
      onClick={(e) => {
        if (onClick) {
          onClick(e, fusionModule);
        } else {
          addPageToPageHistory({
            id: `FUSION_MODULE:${fusionModule.id}`,
            url: ROUTES.FUSION_MODULE_TAB(fusionModule.id, 'temperature'),
            label: `${fusionModule.name || 'Fusion Module'} | Temperature`,
          });
          history.push({
            pathname: ROUTES.FUSION_MODULE(fusionModule.id),
            state: { from: location.pathname },
          });
        }
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Box maxWidth={130}>
            <img src={FusionModuleIcon} alt="" width="100%" />
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h2" sx={{ mb: 1 }}>
                {fusionModule.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ComponentStatus
                component={{
                  status: state,
                  progress,
                  deviceVersion: fusionModuleVersion,
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {cardJob && (
          <>
            <Grid item xs={6} key={'fusion-job'}>
              <TitanInfoItem label={'Fusion Job'}>
                <FusionJobLink fusionJob={cardJob} />

                <List
                  sx={{
                    maxHeight: showAllFinalParts
                      ? `${cardJob.assemblies.length * 25}px`
                      : '50px',
                    overflow: 'hidden',
                    transition: '0.5s ease-out',
                  }}
                >
                  {cardJob &&
                    cardJob.assemblies.map((finalPart) => (
                      <FinalPartLink finalPart={finalPart} key={finalPart.id} />
                    ))}
                </List>
                {cardJob?.assemblies?.length > 2 && (
                  <Button
                    sx={{ width: '100%', padding: '0 20px', marginTop: '5px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowAllFinalParts((prev) => !prev);
                    }}
                  >
                    {' '}
                    {showAllFinalParts ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </Button>
                )}
              </TitanInfoItem>
            </Grid>
            <Grid
              item
              xs={6}
              key={'fusion-job-status'}
              sx={{ minWidth: '50%' }}
            >
              {cardJob && (
                <TitanInfoItem label={'Job Status'}>
                  <ComponentStatus component={cardJob} />
                </TitanInfoItem>
              )}

              {cardJob && (
                <TitanInfoItem
                  label={
                    [COMPONENT_STATUSES.SCHEDULED].includes(cardJob.status)
                      ? 'Created'
                      : [
                          COMPONENT_STATUSES.LOAD_TO_FM,
                          COMPONENT_STATUSES.LOADED_TO_FM,
                          COMPONENT_STATUSES.PRE_FUSING,
                        ].includes(cardJob.status)
                      ? 'Updated'
                      : cardJob.endFuseTime
                      ? 'Finished'
                      : 'Started'
                  }
                >
                  <TitanTimeAgo
                    time={
                      cardJob.status === COMPONENT_STATUSES.SCHEDULED
                        ? cardJob.createdAt
                        : [
                            COMPONENT_STATUSES.LOAD_TO_FM,
                            COMPONENT_STATUSES.LOADED_TO_FM,
                            COMPONENT_STATUSES.PRE_FUSING,
                          ].includes(cardJob.status)
                        ? cardJob.statusUpdatedAt || cardJob.createdAt
                        : null
                    }
                    start={cardJob.startFuseTime}
                    end={cardJob.endFuseTime}
                    status={cardJob.status}
                    emptyValue="-"
                  />
                </TitanInfoItem>
              )}
            </Grid>
          </>
        )}

        {state &&
          [COMPONENT_STATUSES.FUSING, COMPONENT_STATUSES.FUSE_PAUSED].includes(
            state,
          ) && (
            <Grid item xs={12}>
              <LinearProgress
                sx={{ width: '100%', backgroundColor: colors.GREY2 }}
                variant="determinate"
                value={progress}
              />
            </Grid>
          )}
      </Grid>
    </Paper>
  );
}
