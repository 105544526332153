import React from 'react';
import { useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Can from '../Authentication/Can';
import FusorDialog from './FusorDialog';
import ActionsMenu from '../Menu/ActionsMenu';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import { useTitan } from '../Titan/Titan';
import FusorService from '../../services/FusorService';
import { PERMISSIONS } from '../../constants/auth0';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import FusionModuleConfigDialog from './FusionModuleConfigDialog';
import ComponentStatus from '../Component/ComponentStatus';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeviceInfoPopUp from './DeviceInfoPopUp';
import { COMPONENT_STATUSES } from '../../constants';

export default function FusionModulesTable({
  title = 'Fusion Modules',
  setNewPageForTab,
  apiRef,
  showManageActions = true,
  extraActions,
  manufacturingOrderId,
}) {
  const { organizationId = '', page: pageParam = 0 } = useParams();
  const { pushSnackbar } = useTitan();

  const [openFusorDialog, setOpenFusorDialog] = React.useState(false);
  const [editableDevice, setEditableDevice] = React.useState();
  const [openDeleteDeviceDialog, setOpenDeleteDeviceDialog] = React.useState();
  const [openEditFusorDialog, setOpenEditFusorDialog] = React.useState();
  const [fusionModuleConfig, setFusionModuleConfig] = React.useState();
  const [
    openNewCertificateConfirmationDialog,
    setOpenNewCertificateConfirmationDialog,
  ] = React.useState();
  const [infoDevice, setInfoDevice] = React.useState(null);
  const [openInfoPopUp, setOpenInfoPopUp] = React.useState(false);

  const columns = React.useMemo(() => {
    const columns = [
      {
        headerName: 'Name',
        field: 'name',
        visibilityBreakpoint: 'sm',
        minWidth: 180,
        flex: 1,
        sortable: false,
      },
      {
        headerName: 'Status',
        field: 'status',
        visibilityBreakpoint: 'sm',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => (
          <ComponentStatus
            component={{
              status: row.state ? row.state.state : COMPONENT_STATUSES.OFFLINE,
            }}
          />
        ),
      },
      {
        headerName: 'Created',
        field: 'created',
        visibilityBreakpoint: 'sm',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />,
      },
      {
        headerName: 'Last used',
        field: 'lastUsed',
        visibilityBreakpoint: 'sm',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => <TitanTimeAgo time={row.lastUsed} />,
      },
      {
        headerName: 'Last connected',
        field: 'lastConnected',
        visibilityBreakpoint: 'sm',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => (
          <TitanTimeAgo time={row.lastConnected || ''} />
        ),
      },
      {
        headerName: 'FMA Version',
        field: 'state',
        visibilityBreakpoint: 'sm',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) =>
          row.state ? row.state.fusionModuleVersion : '',
      },
    ];

    if (!organizationId) {
      columns.push({
        headerName: 'Organization',
        field: 'organization',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) =>
          row.organization ? row.organization.name : '',
      });
    }

    columns.push({
      headerName: '',
      field: 'actions',
      width: 60,
      renderCell: ({ row }) => (
        <Can
          permissions={[
            PERMISSIONS.DELETE_FUSION_MODULE,
            PERMISSIONS.UPDATE_FUSION_MODULE,
          ]}
          yes={() => (
            <ActionsMenu
              items={[
                ...(showManageActions
                  ? [
                      {
                        title: 'Edit',
                        icon: EditIcon,
                        onClick: () => {
                          setEditableDevice(row);
                          setOpenEditFusorDialog(true);
                        },
                      },
                      {
                        title: 'Create new access key',
                        icon: VpnKeyIcon,
                        onClick: () => {
                          setEditableDevice(row);
                          setOpenNewCertificateConfirmationDialog(true);
                        },
                      },
                      {
                        title: 'Delete',
                        icon: DeleteIcon,
                        onClick: () => {
                          setEditableDevice(row);
                          setOpenDeleteDeviceDialog(true);
                        },
                      },
                      {
                        title: 'Get info',
                        icon: InfoOutlinedIcon,
                        onClick: () => {
                          setOpenInfoPopUp(true);
                          setInfoDevice({
                            id: row.id,
                            onClose: () => {
                              setOpenInfoPopUp(false);
                              setInfoDevice(null);
                            },
                          });
                        },
                      },
                    ]
                  : []),
                ...(extraActions
                  ? extraActions.map((action) => ({
                      ...action,
                      onClick: () => action.onClick(row),
                    }))
                  : []),
              ]}
            />
          )}
        />
      ),
    });

    return columns;
  }, [showManageActions, organizationId, extraActions]);

  const loadData = React.useCallback(
    async (query, config) => {
      const params = {
        ...query,
        withRelated: ['organization'],
      };

      if (organizationId) {
        params.organizationId = organizationId;
      }

      if (manufacturingOrderId) {
        params.manufacturingOrderId = manufacturingOrderId;
      }

      return FusorService.getFusors(params, config);
    },
    [manufacturingOrderId, organizationId],
  );

  const gridOptions = React.useMemo(() => {
    const options = {
      columns: columns,
      pinnedColumns: {
        left: ['name'],
        right: ['actions'],
      },
      onChangePage: (page) => {
        if (setNewPageForTab) {
          setNewPageForTab('fusion-modules', 'Fusion Modules', page);
        }
      },
    };

    if (pageParam && Number(pageParam) > 0) {
      gridOptions.page = Number(pageParam);
    }

    return options;
  }, [setNewPageForTab, pageParam, columns]);

  const titanDataGridProps = useTitanDataGrid(loadData, gridOptions);

  const { rows, setRows } = titanDataGridProps;

  const onSaveFusionModule = React.useCallback(
    (fusionModule) => {
      if (openFusorDialog) {
        setRows((prev) => [fusionModule, ...prev]);
      } else {
        setRows((prev) =>
          prev.map((ps) =>
            ps.id === fusionModule.id ? { ...ps, ...fusionModule } : ps,
          ),
        );
      }
    },
    [rows, openFusorDialog, openEditFusorDialog],
  );

  const handleDeleteDevice = React.useCallback(
    async ({ id }) => {
      await FusorService.deleteFusor(id);
      pushSnackbar('Fusion Module deleted', { variant: 'success' });

      setOpenDeleteDeviceDialog(false);
      setEditableDevice(false);

      setRows((prev) => prev.filter((ps) => ps.id !== id));
    },
    [setRows, pushSnackbar],
  );

  const handleCreateNewCertificate = React.useCallback(async () => {
    const fusionModule = await FusorService.createNewCertificate(
      editableDevice.id,
    );
    pushSnackbar('Created new certificated', { variant: 'success' });

    setFusionModuleConfig(fusionModule.fusionModuleConfig);
  }, [editableDevice, pushSnackbar]);

  React.useEffect(() => {
    if (apiRef) {
      apiRef.current = titanDataGridProps.apiRef.current;
      apiRef.current.reloadData = titanDataGridProps.reloadData;
    }
  }, [apiRef, titanDataGridProps]);

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        title={title}
        onCreateClick={
          showManageActions ? () => setOpenFusorDialog(true) : null
        }
        createButtonLabel="Fusion Module"
        searchPlaceholder="Search by Fusion Module name..."
      />

      {openFusorDialog && (
        <FusorDialog
          organizationId={organizationId}
          onClose={() => setOpenFusorDialog(false)}
          onSave={(fusionModule) => {
            setFusionModuleConfig(fusionModule.fusionModuleConfig);
            onSaveFusionModule(fusionModule);
          }}
        />
      )}

      {openEditFusorDialog && (
        <FusorDialog
          organizationId={organizationId}
          onClose={() => setOpenEditFusorDialog(false)}
          fusor={editableDevice}
          onSave={onSaveFusionModule}
        />
      )}

      {openDeleteDeviceDialog && (
        <TitanConfirmationDialog
          title={`Delete Fusion Module?`}
          message={`Are you sure you want to delete Fusion Module ${editableDevice.name}?`}
          onClose={() => {
            setEditableDevice(null);
            setOpenDeleteDeviceDialog(false);
          }}
          onConfirm={() => handleDeleteDevice(editableDevice)}
        />
      )}

      {fusionModuleConfig && (
        <FusionModuleConfigDialog
          fusionModuleConfig={fusionModuleConfig}
          onClose={() => setFusionModuleConfig(null)}
        />
      )}

      {openNewCertificateConfirmationDialog && (
        <TitanConfirmationDialog
          title={`Generate new certificate?`}
          message={`Are you sure you want to create new certificate for Fusion Module ${editableDevice.name}? Previous certificate will be removed and disconnected from IoT server`}
          onClose={() => {
            setOpenNewCertificateConfirmationDialog(false);
          }}
          onConfirm={() => handleCreateNewCertificate()}
        />
      )}
      {openInfoPopUp && <DeviceInfoPopUp {...infoDevice} />}
    </>
  );
}
