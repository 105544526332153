import React from 'react';
import useAsyncEffect from 'use-async-effect';
import { format, sub } from 'date-fns';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Paper,
  Stack,
  Chip,
  CircularProgress,
  Typography
} from '@mui/material';
import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  VerticalBarSeries,
  HorizontalGridLines,
  VerticalGridLines
} from 'react-vis';
import PrinterService from '../../../services/PrinterService';
import { colors } from '../../Theme/vars';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(4),
    height: 700
  },
  chartWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(3),
    height: 600
  }
}));

const DATE_RANGES = {
  LAST_HOUR: 'Last Hour',
  LAST_DAY: 'Last Day',
  LAST_WEEK: 'Last Week',
  LAST_MONTH: 'Last Month'
};

function BuildModuleStatesChart({ buildModule }) {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(true);

  const [statesSeries, setStatesSeries] = React.useState([]);

  const [selectedDateRange, setSelectedDateRange] = React.useState(
    DATE_RANGES.LAST_MONTH
  );

  const loadStatesData = React.useCallback(async () => {
    let fromDate;

    switch (selectedDateRange) {
      case DATE_RANGES.LAST_HOUR:
        fromDate = format(sub(Date.now(), { hours: 1 }), 'T');
        break;
      case DATE_RANGES.LAST_DAY:
        fromDate = format(sub(Date.now(), { days: 1 }), 'T');
        break;
      case DATE_RANGES.LAST_WEEK:
        fromDate = format(sub(Date.now(), { weeks: 1 }), 'T');
        break;
      case DATE_RANGES.LAST_MONTH:
        fromDate = format(sub(Date.now(), { months: 1 }), 'T');
        break;
      default:
        fromDate = format(sub(Date.now(), { months: 1 }), 'T');
        break;
    }

    const { analytics } = await PrinterService.getPrinterStatesAnalytics(
      buildModule.id,
      {
        fromDate
      }
    );

    return analytics;
  }, [buildModule, selectedDateRange]);

  useAsyncEffect(async () => {
    setLoading(true);

    const analytics = await loadStatesData();
    setStatesSeries(
      analytics.map(entry => ({
        x: entry.stateType,
        y:
          selectedDateRange === DATE_RANGES.LAST_HOUR
            ? entry.minutesSpent
            : entry.minutesSpent / 60
      }))
    );

    setLoading(false);
  }, [selectedDateRange]);

  return (
    <Paper className={classes.container}>
      <Box display="flex" flexDirection="column">
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h1">States Statistic</Typography>
          <Stack direction="row" spacing={2}>
            {Object.values(DATE_RANGES).map(dateRange => (
              <Chip
                key={dateRange}
                label={dateRange}
                onClick={() => setSelectedDateRange(dateRange)}
                color={selectedDateRange === dateRange ? 'primary' : undefined}
              />
            ))}
          </Stack>
        </Stack>
        <Box className={classes.chartWrapper}>
          {loading ? (
            <Box sx={{ margin: 'auto' }}>
              <CircularProgress />
            </Box>
          ) : (
            <FlexibleXYPlot xType="ordinal">
              <XAxis title="States" tickFormat={t => t.replace(/_/g, ' ')} />
              <YAxis
                title={
                  selectedDateRange === DATE_RANGES.LAST_HOUR
                    ? 'Minutes'
                    : 'Hours'
                }
              />
              <HorizontalGridLines />
              <VerticalGridLines />
              <VerticalBarSeries
                data={statesSeries}
                color={colors.RED}
                barWidth={0.5}
              />
            </FlexibleXYPlot>
          )}
        </Box>
      </Box>
    </Paper>
  );
}

export default BuildModuleStatesChart;
