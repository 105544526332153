import React, { useContext } from 'react';
import { GCODE_SHOW_LAYERS, VIEW_PLAN_MODE } from '../../constants';

export const BuildPlanPreviewContext = React.createContext();
export const useBuildPlanPreview = () => useContext(BuildPlanPreviewContext);

export default function BuildPlanPreview({ children }) {
  const [viewPlanMode, setViewPlanMode] = React.useState(VIEW_PLAN_MODE.STL);
  const [minGCodeLayer, setMinGCodeLayer] = React.useState(1);
  const [maxGCodeLayer, setMaxGCodeLayer] = React.useState(1);
  const [minGCodeLayerSelected, setMinGCodeLayerSelected] = React.useState(1);
  const [maxGCodeLayerSelected, setMaxGCodeLayerSelected] = React.useState(1);
  const [gcodeTravelShown, setGCodeTravelShown] = React.useState(false);
  const [gcodeShowLayers, setGCodeShowLayers] = React.useState(
    GCODE_SHOW_LAYERS.RANGE
  );
  const [openGCodeControls, setOpenGCodeControls] = React.useState(false);

  return (
    <BuildPlanPreviewContext.Provider
      value={{
        viewPlanMode,
        setViewPlanMode,
        minGCodeLayer,
        setMinGCodeLayer,
        maxGCodeLayer,
        setMaxGCodeLayer,
        minGCodeLayerSelected,
        setMinGCodeLayerSelected,
        maxGCodeLayerSelected,
        setMaxGCodeLayerSelected,
        gcodeTravelShown,
        setGCodeTravelShown,
        gcodeShowLayers,
        setGCodeShowLayers,
        openGCodeControls,
        setOpenGCodeControls
      }}
    >
      {children}
    </BuildPlanPreviewContext.Provider>
  );
}
