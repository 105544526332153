import React from 'react';
import { useHistory } from 'react-router-dom';
import { QrReader } from 'react-qr-reader';
import TitanDialog from '../Titan/TitanDialog';
import Box from '@mui/material/Box';

export default function QRCodeScannerDialog({ onClose }) {
  const history = useHistory();
  const scannedTextRef = React.useRef('');

  const isValidHttpUrl = (string) => {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  };

  const handleScan = React.useCallback(async (result) => {
    if (!result?.text || scannedTextRef.current === result.text) {
      return;
    }

    scannedTextRef.current = result.text;

    const targetUrl = result.text;

    if (targetUrl && isValidHttpUrl(targetUrl)) {
      if (targetUrl.startsWith(window.location.origin)) {
        console.log(`Navigate to ${targetUrl}`);
        history.push(targetUrl.replace(window.location.origin, ''));
        onClose();
      }
    }
  }, []);

  return (
    <TitanDialog
      open={true}
      title={`Scan QR code`}
      onClose={onClose}
      fullWidth={false}
    >
      <Box sx={{ width: 300, height: 300, align: 'center' }}>
        <QrReader
          constraints={{
            facingMode: 'environment',
          }}
          scanDelay={100}
          onResult={handleScan}
        />
      </Box>
    </TitanDialog>
  );
}
