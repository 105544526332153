import React from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import FormControlLabel from '@mui/material/FormControlLabel';
import TitanPopover from '../Titan/TitanPopover';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function SpoolFilter({
  selectedMaterialType,
  onChangeMaterialType,
  selectedSpoolType,
  onChangeSpoolType,
}) {
  return (
    <TitanPopover icon={FilterListIcon}>
      <Box sx={{ width: 450, p: 2 }}>
        <FormControl>
          <FormLabel>Material Type</FormLabel>
          <RadioGroup
            row
            value={selectedMaterialType}
            onChange={(e) => onChangeMaterialType(e.target.value)}
          >
            <FormControlLabel value="all" control={<Radio />} label="All" />
            <FormControlLabel value="FIBER" control={<Radio />} label="Tape" />
            <FormControlLabel
              value="PLASTIC"
              control={<Radio />}
              label="Polymer"
            />
          </RadioGroup>
          <FormLabel>Spool Type</FormLabel>
          <RadioGroup
            row
            value={selectedSpoolType}
            onChange={(e) => onChangeSpoolType(e.target.value)}
          >
            <FormControlLabel value="all" control={<Radio />} label="All" />
            <FormControlLabel value="SPOOL" control={<Radio />} label="Spool" />
            <FormControlLabel value="BATCH" control={<Radio />} label="Batch" />
          </RadioGroup>
        </FormControl>
      </Box>
    </TitanPopover>
  );
}
