import React from 'react';
import { useAuth0 } from '../Authentication/Auth0';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import LogoutIcon from '../../assets/icons/logout.svg';
import MemberService from '../../services/MemberService';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { useTitan } from '../Titan/Titan';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

export default function LoggedAsMember() {
  const { profile, setProfile } = useAuth0();
  const history = useHistory();
  const location = useLocation();
  const { pushSnackbar } = useTitan();

  if (!profile || profile.loggedAsMember === null) {
    return '';
  }

  const logoutFromMember = () => {
    MemberService.logoutFromMember().then(updatedProfile => {
      setProfile({
        ...updatedProfile,
        loggedAsMember: null
      });

      history.push({
        pathname: ROUTES.ADMIN_DASHBOARD_TAB('organizations'),
        state: { from: location.pathname }
      }); // TODO: change to DASHBOARD
      pushSnackbar('Logged out from member account to original', {
        variant: 'success'
      });
    });
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography>Logged as:</Typography>
      <IconButton color="inherit">
        <Avatar
          title={profile?.loggedAsMember?.name}
          src={profile?.loggedAsMember?.picture}
        />
      </IconButton>
      <IconButton
        color="inherit"
        onClick={logoutFromMember}
        title="Logout from member"
      >
        <img src={LogoutIcon} alt="Logout Icon" />
      </IconButton>
    </Box>
  );
}
