import axios from 'axios';

export default class PresetService {
  static async getPreset(presetId) {
    try {
      const response = await await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v2/presets/${presetId}`
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      return undefined;
    }
  }
  static async getPresets(params = {}, config = {}) {
    const response = await (await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v2/presets`,
      { ...config, params }
    )).data;
    return response;
  }
  static async addPreset(payload) {
    const response = await await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v2/presets`,
      payload
    );
    if (response.status === 200) {
      return response;
    }

    return false;
  }
  static async updatePreset(preset) {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/api/v2/presets/${preset.id}`,
      preset
    );

    if (response.status === 200) {
      return response.data;
    }

    return false;
  }

  static async deletePreset(presetId) {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v2/presets/${presetId}`
      );
      return true;
    } catch (error) {
      return false;
    }
  }
}
