import React, { Component } from 'react';
import { Paper } from '@mui/material';

export default function MetabaseComponent({
  metabaseUrl,
  width = '100%',
  height = '800px',
}) {
  return (
    <Paper sx={{ p: 2 }}>
      <iframe src={metabaseUrl} style={{ width, height, border: 'none' }} />
    </Paper>
  );
}
