import React from 'react';
import TextField from '@mui/material/TextField';
import TitanDialog from '../Titan/TitanDialog';

export default function BuildModuleNoteDialog({ note, onClose, onSave }) {
  const [label, setLabel] = React.useState(note ? note.label : '');
  const [content, setContent] = React.useState(note ? note.note : '');

  const onSubmitForm = () => {
    return onSave({
      label,
      note: content
    });
  };

  return (
    <TitanDialog
      title={`${note ? 'Edit' : 'Create'} Note`}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      onSave={onSubmitForm}
      isValid={label && content.length}
    >
      <TextField
        autoFocus
        id="name"
        label="Label"
        type="text"
        value={label}
        fullWidth
        margin="normal"
        variant="outlined"
        onChange={e => setLabel(e.target.value)}
      />
      <TextField
        autoFocus
        id="name"
        label="Note"
        type="text"
        value={content}
        fullWidth
        margin="normal"
        variant="outlined"
        onChange={e => setContent(e.target.value)}
      />
    </TitanDialog>
  );
}
