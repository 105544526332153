import React from 'react';
import useWebSocket from 'react-use-websocket';
import { COMPONENT_STATUSES, WEB_SOCKET_ACTIONS } from '../../constants';
import useAsyncEffect from 'use-async-effect';

import { updateBuildJobData } from '../BuildJob/use-build-jobs';
import { useTitan } from '../Titan/Titan';

export default function useBuildModules(loadBuildModules) {
  const { getWebSocketUrl, getWebSocketOptions } = useTitan();

  const [loading, setLoading] = React.useState(true);
  const [buildModules, setBuildModules] = React.useState([]);

  useAsyncEffect(async () => {
    setLoading(true);

    const buildModules = await loadBuildModules();

    setBuildModules(buildModules);
    setLoading(false);
  }, [loadBuildModules]);

  const onChangeBuildModule = React.useCallback(
    (newBuildModuleData) => {
      setBuildModules((prev) => {
        const buildModule = prev.find(
          (bm) => bm.id === newBuildModuleData.buildModuleId,
        );

        if (!buildModule) {
          return prev;
        }

        return prev.map((bm) => {
          if (bm.id === newBuildModuleData.buildModuleId) {
            const updatedBuildModule = { ...bm };

            if (newBuildModuleData.state) {
              updatedBuildModule.state = newBuildModuleData.state;

              updatedBuildModule.lastState = newBuildModuleData.state;
            }

            if (newBuildModuleData.status) {
              updatedBuildModule.currentStatus = newBuildModuleData.status;
            }

            if (newBuildModuleData.progress) {
              updatedBuildModule.progress = newBuildModuleData.progress;
            }

            if (newBuildModuleData.deviceVersion) {
              updatedBuildModule.deviceVersion =
                newBuildModuleData.deviceVersion;
            }

            if (newBuildModuleData.isAvailableToBuild !== undefined) {
              updatedBuildModule.isAvailableToBuild =
                newBuildModuleData.isAvailableToBuild;
            }

            if (newBuildModuleData.activeJobs !== undefined) {
              updatedBuildModule.activeJobs = newBuildModuleData.activeJobs;
            }

            if (newBuildModuleData.prevBuildJob !== undefined) {
              updatedBuildModule.prevBuildJob = newBuildModuleData.prevBuildJob;
            }

            return updatedBuildModule;
          }

          return bm;
        });
      });
    },
    [setBuildModules],
  );

  const onChangeBuildJob = React.useCallback(
    (newBuildJobData) => {
      setBuildModules((prev) => {
        return prev.map((buildModule) => {
          if (buildModule.activeJobs) {
            return {
              ...buildModule,
              activeJobs: buildModule.activeJobs.map((buildJob) => {
                if (
                  buildJob.id === newBuildJobData.buildJobId ||
                  buildJob.id === newBuildJobData.id
                ) {
                  return updateBuildJobData(buildJob, newBuildJobData);
                }

                if (
                  (newBuildJobData.printerId === buildModule.id ||
                    newBuildJobData.buildModuleId === buildModule.id) &&
                  (newBuildJobData.state === COMPONENT_STATUSES.LOAD_TO_BM ||
                    newBuildJobData.status === COMPONENT_STATUSES.LOAD_TO_BM)
                ) {
                  return newBuildJobData;
                }

                return buildJob;
              }),
            };
          }

          return buildModule;
        });
      });
    },
    [setBuildModules],
  );

  const { lastJsonMessage } = useWebSocket(
    getWebSocketUrl,
    getWebSocketOptions(),
  );

  React.useEffect(() => {
    if (
      lastJsonMessage !== null &&
      [
        WEB_SOCKET_ACTIONS.BUILD_MODULE_STATUS,
        WEB_SOCKET_ACTIONS.BUILD_MODULE_STATE,
      ].includes(lastJsonMessage.action) &&
      lastJsonMessage.data
    ) {
      onChangeBuildModule(lastJsonMessage.data);
    }

    if (
      lastJsonMessage !== null &&
      [
        WEB_SOCKET_ACTIONS.BUILD_JOB,
        WEB_SOCKET_ACTIONS.BUILD_JOB_STATE,
      ].includes(lastJsonMessage.action) &&
      lastJsonMessage.data
    ) {
      onChangeBuildJob(lastJsonMessage.data);
    }
  }, [lastJsonMessage, onChangeBuildModule]);

  return {
    loading,
    buildModules,
    setBuildModules,
  };
}
