import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import { COMPONENT_STATUSES, ROUTES } from '../../constants';
import { colors } from '../Theme/vars';
import FusionModuleIcon from '../../assets/icons/fusion-module.svg';
import ComponentStatus from '../Component/ComponentStatus';
import classNames from 'classnames';
import useFusionModuleState from '../FusionModule/use-fusion-module-state';
import { useTitan } from '../Titan/Titan';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: 330,
    padding: '40px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    border: `2.75px solid ${colors.WHITE}`,
  },
  selected: {
    border: `2.75px solid ${colors.RED}`,
  },
  content: {
    width: '100%',
  },
  statusBar: {
    width: '100%',
    backgroundColor: colors.GREY2,
  },
});

export default function FusionModuleSimpleCard({ device, onClick, selected }) {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();

  const { state, progress } = useFusionModuleState(device.id, device);

  const { addPageToPageHistory } = useTitan();

  return (
    <Card
      className={classNames({
        [classes.container]: true,
        [classes.selected]: selected,
      })}
      onClick={(e) => {
        if (onClick) {
          onClick(e, device);
        } else {
          addPageToPageHistory({
            id: `FUSION_MODULE:${device.id}`,
            url: ROUTES.FUSION_MODULE_TAB(device.id, 'temperature'),
            label: `${device.name || 'Fusion Module'} | Temperature`,
          });
          history.push({
            pathname: ROUTES.FUSION_MODULE(device.id),
            state: { from: location.pathname },
          });
        }
      }}
    >
      <CardMedia>
        <img src={FusionModuleIcon} alt="Device icon" />
      </CardMedia>
      <CardContent className={classes.content}>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="h2">{device.name}</Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <ComponentStatus
                component={{
                  status: state,
                  progress,
                }}
              />
            </Grid>
          </Grid>
          {[COMPONENT_STATUSES.BUILDING, COMPONENT_STATUSES.FUSING].includes(
            state,
          ) && (
            <Grid item style={{ width: '100%' }}>
              <LinearProgress
                className={classes.statusBar}
                variant="determinate"
                value={progress}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
