import axios from 'axios';
import jsMD5 from 'js-md5';

export default class FileService {
  static getSignedUrlsToPut(files) {
    return axios
      .put(`${process.env.REACT_APP_API_URL}/api/v1/files/signed-urls`, {
        files
      })
      .then(res => res.data.data);
  }

  static getS3File(url) {
    return fetch(url).then(res => res.blob());
  }

  static async uploadBase64File(name, base64File) {
    const file = await fetch(base64File).then(res => res.blob());

    const signedUrlToPut = await axios
      .put(`${process.env.REACT_APP_API_URL}/api/v1/files/upload-urls`, {
        files: [{ name }]
      })
      .then(res => res.data.data[0]);

    await axios.put(signedUrlToPut.url, file, {
      sendAuth0Headers: false
    });

    return signedUrlToPut;
  }

  static async uploadFile(name, file) {
    const dataToPutFile = await axios
      .put(`${process.env.REACT_APP_API_URL}/api/v1/files/upload-urls`, {
        files: [{ name }]
      })
      .then(res => res.data.data[0]);

    await axios.put(dataToPutFile.url, file, {
      sendAuth0Headers: false
    });

    return dataToPutFile;
  }

  static async uploadFiles(files) {
    const urlsToPut = await this.getSignedUrlsToPut(
      files.map(file => ({ name: file.name, contentType: file.type }))
    );

    await Promise.all(
      urlsToPut.map(({ url, contentType }, index) => {
        // return axios.put(url, files[index], {
        //   headers: { 'Content-Type': contentType }
        // });
        return fetch(url, { method: 'PUT', body: files[index] });
      })
    );

    const arrayBuffers = await Promise.all(
      files.map(file => file.arrayBuffer())
    );

    return Promise.all(
      urlsToPut.map(({ name, location }, index) =>
        this.createFile({
          name,
          location,
          md5: jsMD5(arrayBuffers[index])
        })
      )
    );
  }

  static fileToBase64(file) {
    return new Promise(resolve => {
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };
    });
  }
}
