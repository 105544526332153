import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import { useTitan } from '../Titan/Titan';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import TitanPage from '../Titan/TitanPage';
import TitanTabs from '../Titan/TitanTabs';
import TitanInfoItem from '../Titan/TitanInfoItem';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import BuildPlansTable from '../BuildPlans/BuildPlansTable';
import FusionPlansTable from '../FusionPlans/FusionPlansTable';
import ManufacturingOrdersTable from '../ManufacturingOrders/ManufacturingOrdersTable';
import ProjectService from '../../services/ProjectService';
import { ROUTES, ROLES } from '../../constants';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CreateIcon from '@mui/icons-material/Create';
import ProjectDialog from './ProjectDialog';
import SelectOdooManufacturingOrdersDialog from '../ManufacturingOrders/SelectOdooManufacturingOrdersDialog';
import { useAuth0 } from '../Authentication/Auth0';
import OdooService from '../../services/OdooService';
import ManufacturingOrderService from '../../services/ManufacturingOrderService';
import OdooImportManufacturingOrderDialog from '../ManufacturingOrders/OdooImportManufacturingOrderDialog/OdooImportManufacturingOrderDialog';
import { Link } from '@mui/material';
import MetabaseComponent from '../Metabase/MetabaseComponent';

export default function ProjectPage() {
  const { projectId, tab = 'summary' } = useParams();
  const history = useHistory();

  const { addPageToPageHistory, odooIntegrationEnabled } = useTitan();
  const { roles } = useAuth0();

  const [project, setProject] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const [openEditDialog, setOpenEditDialog] = React.useState(false);

  const [
    manufacturingOrderToLinkWithOdoo,
    setManufacturingOrderToLinkWithOdoo,
  ] = React.useState(null);

  const [
    openOdooImportManufacturingOrdersDialog,
    setOpenOdooImportManufacturingOrdersDialog,
  ] = React.useState(false);

  const manufacturingOrdersTableApiRef = React.useRef();

  const isAbleToEdit = React.useMemo(
    () =>
      roles.find(
        (r) => r.id === ROLES.ADMIN || r.id === ROLES.APPLICATION_ENGINEER,
      ),
    [roles],
  );
  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Projects',
        path: ROUTES.PROJECTS(),
      },
      {
        name: project && project.name ? project.name : 'Project',
        disabled: true,
      },
    ],
    [project],
  );

  useAsyncEffect(async () => {
    setLoading(true);
    const { data: project } = await ProjectService.getProjectById(projectId);
    setProject(project);

    setLoading(false);
  }, []);

  const onUpdateProject = React.useCallback(
    async (data) => {
      const updatedProject = await ProjectService.updateProject(
        project.id,
        data,
      );

      setProject(updatedProject);
    },
    [project],
  );

  const tabs = React.useMemo(
    () => [
      {
        label: 'Summary',
        value: 'summary',
        content: (
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TitanInfoItem label="Project Key">
                  {project.projectKey ? project.projectKey : '-'}
                </TitanInfoItem>
              </Grid>
              <Grid item xs={4}>
                <TitanInfoItem label="Sales Order Number">
                  {project.salesOrderNumber ? project.salesOrderNumber : '-'}
                </TitanInfoItem>
              </Grid>
              <Grid item xs={4}>
                <TitanInfoItem label="Project Number">
                  {project.projectNumber ? project.projectNumber : '-'}
                </TitanInfoItem>
              </Grid>
              <Grid item xs={4}>
                <TitanInfoItem label="Customer's Abbreviation">
                  {project.customerAbbreviation
                    ? project.customerAbbreviation
                    : '-'}
                </TitanInfoItem>
              </Grid>
              <Grid item xs={4}>
                <TitanInfoItem label="Part Name">
                  {project.partName ? project.partName : '-'}
                </TitanInfoItem>
              </Grid>
              {odooIntegrationEnabled && (
                <Grid item xs={4}>
                  <TitanInfoItem label="Odoo Link">
                    {project.odooProjectPageUrl ? (
                      <Link href={project.odooProjectPageUrl} target="_blank">
                        {project.name}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </TitanInfoItem>
                </Grid>
              )}
            </Grid>
          </Paper>
        ),
      },
      {
        label: 'Manufacturing Orders',
        value: 'manufacturing-orders',
        content: (
          <ManufacturingOrdersTable
            apiRef={manufacturingOrdersTableApiRef}
            projectIds={[project.id]}
            createManufacturingOrderButton={true}
            setManufacturingOrderToLinkWithOdoo={
              setManufacturingOrderToLinkWithOdoo
            }
            hiddenColumns={['project']}
          />
        ),
      },
      {
        label: 'Build Plans',
        value: 'build-plans',
        content: (
          <BuildPlansTable
            projectId={project.id}
            addBuildPlanToProjectButton={isAbleToEdit}
          />
        ),
      },
      {
        label: 'Fusion Plans',
        value: 'fusion-plans',
        content: (
          <FusionPlansTable
            projectId={project.id}
            addFusionPlanToProjectButton={isAbleToEdit}
          />
        ),
      },
      ...(project.metabaseUrl
        ? [
            {
              label: 'Statistics',
              value: 'statistics',
              content: <MetabaseComponent metabaseUrl={project.metabaseUrl} />,
            },
          ]
        : []),
    ],
    [project],
  );

  const preparePageObject = React.useCallback(
    (tab, tabForURL) => {
      return {
        id: `PROJECT:${projectId}`,
        url: ROUTES.PROJECT_TAB(projectId, tabForURL),
        label: `${project.name} | ${tab}`,
      };
    },
    [project, projectId],
  );

  const onChangeTab = React.useCallback(
    (value) => {
      const activeLabel = value.charAt(0).toUpperCase() + value.slice(1);
      addPageToPageHistory(preparePageObject(activeLabel, value));
      history.push(ROUTES.PROJECT_TAB(projectId, value));
    },
    [history, addPageToPageHistory, preparePageObject, projectId],
  );

  const handleSelectedOdooManufacturingOrder = React.useCallback(
    async (selectedManufacturingOrder) => {
      if (manufacturingOrderToLinkWithOdoo) {
        await ManufacturingOrderService.updateManufacturingOrder(
          manufacturingOrderToLinkWithOdoo.id,
          {
            odooManufacturingOrderId: selectedManufacturingOrder.id,
            odooManufacturingOrderName: selectedManufacturingOrder.name,
          },
        );
      } else {
        await OdooService.importManufacturingOrderFromOdoo(
          selectedManufacturingOrder.id,
          {
            projectId,
            ...selectedManufacturingOrder,
          },
        );
      }
      await manufacturingOrdersTableApiRef.current.reloadData();
    },
    [manufacturingOrderToLinkWithOdoo],
  );

  return (
    <TitanPage
      loading={loading}
      title={
        <Stack direction="row" alignItems="center">
          <PreviewImage src={project?.imageUrl} alt="" />
          <Typography variant="h1">{project?.name || 'Project'}</Typography>
        </Stack>
      }
      breadcrumbs={breadcrumbs}
      titleContent={
        !loading &&
        !project?.deletedAt &&
        isAbleToEdit && (
          <IconButton size="large" onClick={() => setOpenEditDialog(true)}>
            <CreateIcon />
          </IconButton>
        )
      }
      headerContent={
        <Stack spacing={2}>
          {tab === 'manufacturing-orders' && odooIntegrationEnabled ? (
            <Tooltip
              title={
                !project.odooProjectId ? 'Sync project with Odoo first' : ''
              }
              disableHoverListener={false}
              disableTouchListener={false}
            >
              <div>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<DownloadIcon />}
                  onClick={async () =>
                    setOpenOdooImportManufacturingOrdersDialog(true)
                  }
                  disabled={!project.odooProjectId}
                >
                  Odoo import
                </Button>
              </div>
            </Tooltip>
          ) : (
            ''
          )}
        </Stack>
      }
    >
      <TitanTabs tabs={tabs} activeTab={tab} onChangeTab={onChangeTab} />

      {openOdooImportManufacturingOrdersDialog && (
        <OdooImportManufacturingOrderDialog
          onSave={handleSelectedOdooManufacturingOrder}
          onClose={async () => {
            setOpenOdooImportManufacturingOrdersDialog(false);
            setManufacturingOrderToLinkWithOdoo(null);
            await manufacturingOrdersTableApiRef.current.reloadData();
          }}
        />
      )}

      {manufacturingOrderToLinkWithOdoo && (
        <SelectOdooManufacturingOrdersDialog
          title="Link Manufacturing Order with Odoo"
          onSelect={handleSelectedOdooManufacturingOrder}
          onClose={async () => {
            setOpenOdooImportManufacturingOrdersDialog(false);
            setManufacturingOrderToLinkWithOdoo(null);
            await manufacturingOrdersTableApiRef.current.reloadData();
          }}
          multiSelect={!manufacturingOrderToLinkWithOdoo}
          saveButtonLabel="Link"
        />
      )}

      {openEditDialog && (
        <ProjectDialog
          project={project}
          onSave={onUpdateProject}
          onClose={() => setOpenEditDialog(false)}
        />
      )}
    </TitanPage>
  );
}

const PreviewImage = styled('img')({
  display: 'block',
  height: '40px',
  marginRight: '10px',
});

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'block',
    paddingRight: '20px',
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:visited': {
      color: theme.palette.primary.main,
    },
  },
}));
