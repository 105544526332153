import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import OrganizationService from '../../services/OrganizationService';

export default function ChangeOrganizationDialog({
  currentOrganization,
  onClose,
  onSave
}) {
  const [organization, setOrganization] = React.useState('');
  const [organizations, setOrganizations] = React.useState([]);

  React.useEffect(() => {
    OrganizationService.getOrganizations().then(response => {
      setOrganizations(
        response.data.filter(({ id }) => id !== currentOrganization)
      );
    });
  }, []);

  return (
    <Dialog fullWidth maxWidth="xs" open={true} onClose={onClose}>
      <DialogTitle>Move to organization</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          select
          label="Organization"
          value={organization}
          onChange={e => setOrganization(e.target.value)}
        >
          {organizations.map(organization => (
            <MenuItem key={organization.id} value={organization.id}>
              {organization.name}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button disabled={!organization} onClick={() => onSave(organization)}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
