import React from 'react';

import { useFuseDialog } from './FuseDialog';

import { COMPONENT_STATUSES, SORT_ORDERS } from '../../constants';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import PreformLink from '../Component/PreformLink';
import BuildPlanLink from '../BuildPlan/BuildPlanLink';
import ComponentStatus from '../Component/ComponentStatus';
import Rating from '@mui/material/Rating';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import FusionModuleCardWithoutInfo from '../FusionModules/FusionModuleCard';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import Alert from '@mui/material/Alert';
import useAsyncEffect from 'use-async-effect';
import FusorService from '../../services/FusorService';

export default function FuseDialogReviewStep() {
  const {
    selectedFusionPlan,

    selectedFusionModule,
    selectedMould,

    setSelectedMould,

    isExternalHeating,
    setIsExternalHeating,

    isPreformConfig,
    setIsPreformConfig,

    isMouldSetup,
    setIsMouldSetup,

    isMaterialSetup,
    setIsMaterialSetup,

    isAutomatic,
    setIsAutomatic,

    finalParts,

    selectedManufacturingOrder,

    hasActiveJob,
    hasActiveFusingJob,
  } = useFuseDialog();

  useAsyncEffect(async () => {
    const loadedMoulds = await FusorService.getFusionModuleMoulds(
      selectedFusionModule.id,
      { order: [`load_time:${SORT_ORDERS.DESC}`], page: 0, pageSize: 1 },
    );

    if (loadedMoulds.data.length !== 0 && !loadedMoulds.data[0].unloadTime) {
      setSelectedMould(loadedMoulds.data[0].mould);
    }
  }, [selectedFusionModule]);

  const loadPreforms = React.useCallback(async () => {
    let selectedPreforms = [];

    finalParts.forEach((finalPart) => {
      selectedPreforms = selectedPreforms.concat(finalPart.selectedPreforms);
    });

    return {
      data: selectedPreforms,
      page: 1,
      totalCount: selectedPreforms.length,
    };
  }, [finalParts]);

  const preformColumns = React.useMemo(
    () => [
      {
        headerName: 'ID',
        field: 'id',
        visibilityBreakpoint: 'sm',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) => <PreformLink preform={row} />,
      },
      {
        headerName: 'Build Plan',
        field: 'buildPlan',
        visibilityBreakpoint: 'sm',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row, colDef }) =>
          row.buildPlan ? (
            <BuildPlanLink
              buildPlan={row.buildPlan}
              width={colDef.computedWidth}
            />
          ) : (
            ''
          ),
      },
      {
        headerName: 'Preform Type',
        field: 'preformType',
        visibilityBreakpoint: 'lg',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) =>
          row.preformType
            ? `${row.preformType.name} (${row.preformType.labels})`
            : '',
      },
      {
        headerName: 'Status',
        field: 'status',
        visibilityBreakpoint: 'sm',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => <ComponentStatus component={row} />,
      },
      {
        headerName: 'Quality',
        field: 'quality',
        visibilityBreakpoint: 'lg',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) =>
          [COMPONENT_STATUSES.BUILT, COMPONENT_STATUSES.POST_BUILDING].includes(
            row.status,
          ) && row.quality !== null ? (
            <Rating name="read-only" value={row.quality} readOnly />
          ) : (
            ''
          ),
      },
    ],
    [],
  );

  const reviewPreformsDataGridProps = useTitanDataGrid(loadPreforms, {
    columns: preformColumns,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} lg={3}>
        <FusionModuleCardWithoutInfo device={selectedFusionModule} />
        {hasActiveJob ? (
          <Alert severity="warning" sx={{ mt: 2 }}>
            Fusion Module has active {hasActiveFusingJob ? 'fusing' : ''} job
          </Alert>
        ) : (
          ''
        )}
      </Grid>
      <Grid item xs={12} lg={9}>
        <TitanDataGrid
          {...reviewPreformsDataGridProps}
          components={{
            Toolbar: TitanDataGridToolbar,
          }}
          hideFooter
          showSearch={false}
        />

        <Stack direction="row" sx={{ mt: 2 }} spacing={2} alignItems="center">
          <Typography variant="subtitle2">Loaded mould:</Typography>
          <Typography>
            {selectedMould ? selectedMould.mouldIdentifier : 'Not loaded'}
          </Typography>
          {selectedMould &&
          selectedFusionPlan.mould &&
          selectedMould.id !== selectedFusionPlan.mould.id ? (
            <Alert severity="warning" sx={{ pt: 0, pb: 0 }}>
              Fusion plan requires {selectedFusionPlan.mould.mouldIdentifier}
            </Alert>
          ) : (
            ''
          )}
        </Stack>

        <Stack direction="row" sx={{ mt: 2 }} spacing={2}>
          <Typography variant="subtitle2">
            Selected manufacturing order:
          </Typography>
          <Typography>
            {selectedManufacturingOrder
              ? selectedManufacturingOrder.name
              : 'Not selected'}
          </Typography>
        </Stack>

        <Stack direction="row" sx={{ mt: 2 }} spacing={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isPreformConfig}
                onChange={(e) => {
                  setIsPreformConfig(e.target.checked);
                  setIsMouldSetup(e.target.checked);
                  setIsMaterialSetup(e.target.checked);
                }}
                color="primary"
              />
            }
            label="Preform Config"
          />
          <Divider orientation="vertical" variant="middle" flexItem />
          <FormControlLabel
            control={
              <Checkbox
                checked={isExternalHeating}
                onChange={(e) => setIsExternalHeating(e.target.checked)}
                color="primary"
              />
            }
            label="External Heating"
          />
          <Divider orientation="vertical" variant="middle" flexItem />
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Semi-Automatic</Typography>
            <Switch
              onChange={() => setIsAutomatic(!isAutomatic)}
              checked={isAutomatic}
              color="primary"
            />
            <Typography>Automatic</Typography>
          </Stack>
        </Stack>

        {!isPreformConfig ? (
          <Stack direction="row" sx={{ mt: 2 }} spacing={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isMouldSetup}
                  onChange={(e) => setIsMouldSetup(e.target.checked)}
                  color="primary"
                />
              }
              label="Mould Preset"
            />
            <Divider orientation="vertical" variant="middle" flexItem />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isMaterialSetup}
                  onChange={(e) => setIsMaterialSetup(e.target.checked)}
                  color="primary"
                />
              }
              label="Material Preset"
            />
          </Stack>
        ) : (
          ''
        )}
      </Grid>
    </Grid>
  );
}
