import React from 'react';
import TitanPopover from '../Titan/TitanPopover';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Checkbox } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

export default function BuildPlansTableFilter({
  filterOwnedBuildPlans,
  onChangeFilterOwnedBuildPlans,
  filterAssignedBuildPlans,
  onChangeFilterAssignedBuildPlans,
  filterApprovedBuildPlans,
  onChangeFilterApprovedBuildPlans,
  filterPrototypes,
  onChangeFilterPrototypes,
  projects,
  chosenProjects,
  onChosenProjectsChange
}) {
  const classes = useStyles();

  return (
    <>
      <TitanPopover icon={FilterListIcon}>
        <Container className={classes.root}>
          <FormControl>
            <FormLabel>Filter Plans</FormLabel>
            <FormControlLabel
              control={<Checkbox checked={filterOwnedBuildPlans} />}
              label="Owned by Me"
              onChange={(_, checked) => onChangeFilterOwnedBuildPlans(checked)}
            />
            <FormControlLabel
              control={<Checkbox checked={filterAssignedBuildPlans} />}
              label="Assigned to Me"
              onChange={(_, checked) =>
                onChangeFilterAssignedBuildPlans(checked)
              }
            />
            <FormControlLabel
              control={<Checkbox checked={filterApprovedBuildPlans} />}
              label="Only Approved"
              onChange={(_, checked) =>
                onChangeFilterApprovedBuildPlans(checked)
              }
            />
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <FormLabel>Prototypes Visibility</FormLabel>
            <FormControlLabel
              control={<Checkbox checked={filterPrototypes} />}
              label="Only Prototypes"
              onChange={(_, checked) => onChangeFilterPrototypes(checked)}
            />
          </FormControl>

          <FormControl margin="normal">
            <FormLabel>By Projects</FormLabel>
            <Autocomplete
              multiple
              id="projects-filter"
              value={chosenProjects}
              onChange={onChosenProjectsChange}
              options={projects}
              getOptionLabel={option => option.name}
              filterSelectedOptions
              className={classes.projectsFilter}
              renderInput={params => (
                <TextField {...params} placeholder="Select projects..." />
              )}
            />
          </FormControl>
        </Container>
      </TitanPopover>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 450,
    padding: theme.spacing(2)
  },
  projectsFilter: {
    marginTop: theme.spacing(1)
  }
}));
