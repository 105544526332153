import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Link } from '@mui/material';
import { ROUTES } from '../../constants';
import { makeStyles } from '@mui/styles';
import OverflowTip from '../ExpandRenderCell/OverflowTip';
import { useTitan } from '../Titan/Titan';

const useStyles = makeStyles(theme => ({
  hiddenOverflowBlock: {
    overflow: 'hidden'
  }
}));

export default function FusionJobLink(props) {
  const {
    fusionJob,
    children,
    useName = true,
    width,
    onClick = e => e.stopPropagation()
  } = props;
  const location = useLocation();
  const classes = useStyles();

  const { addPageToPageHistory, preparePageObjectForLink } = useTitan();

  const handleClick = e => {
    addPageToPageHistory(
      preparePageObjectForLink(
        'FUSION_JOB',
        fusionJob.id,
        fusionJob.jobKey || `Fusion Job`,
        ROUTES.FUSION_JOB_TAB(fusionJob.id, 'summary'),
        'Summary',
        { fusionPlan: fusionJob?.fusionPlan }
      )
    );

    if (onClick) {
      onClick(e);
    }
  };

  const content = useName
    ? fusionJob.jobKey
      ? fusionJob.jobKey
      : `Fusion Job ${fusionJob.id.split('-')[0]}`
    : children;

  return (
    <Link
      className={classes.hiddenOverflowBlock}
      onClick={handleClick}
      to={{
        pathname: ROUTES.FUSION_JOB(fusionJob.id),
        state: { from: location.pathname }
      }}
      component={RouterLink}
    >
      {width ? (
        <OverflowTip width={width}>{content}</OverflowTip>
      ) : (
        <span>{content}</span>
      )}
    </Link>
  );
}
