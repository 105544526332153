import React, { useMemo } from 'react';

import nanoid from 'nanoid';
import FileService from '../../services/FileService';
import FibrifyPhotosGallery from '../FibrifyPhotosGallery/FibrifyPhotosGallery';
import FinalPartService from '../../services/FinalPartService';
import { useTitan } from '../Titan/Titan';

export default function FinalPartPhotos({ finalPart, setFinalPart }) {
  const finalPartPhotos = useMemo(() => {
    return [...finalPart.photos].sort((a, b) => a.order - b.order);
  }, [finalPart]);

  const { pushSnackbar } = useTitan();

  return (
    <FibrifyPhotosGallery
      photos={finalPartPhotos}
      onAddNewPhotos={async photos => {
        const newFinalPartPhotos = await Promise.all(
          photos.map(async (photo, order) => {
            const name = `${nanoid()}.png`;

            const initialOrder =
              finalPartPhotos[finalPartPhotos.length - 1]?.order + 1 || 0;

            const { location } = await FileService.uploadBase64File(
              name,
              photo
            );

            return FinalPartService.addPhoto(finalPart.id, {
              name,
              order: initialOrder + order,
              location
            });
          })
        );

        setFinalPart(prevState => ({
          ...prevState,
          photos: [...prevState.photos, ...newFinalPartPhotos]
        }));
      }}
      onDeletePhoto={async photoId => {
        await FinalPartService.deletePhoto(photoId);

        setFinalPart(prevState => ({
          ...prevState,
          photos: finalPartPhotos.filter(
            currentPhoto => currentPhoto.id !== photoId
          )
        }));
      }}
      onUpdatePhoto={async (photoId, updatedPhoto) => {
        await FinalPartService.updatePhoto(photoId, updatedPhoto);

        setFinalPart(prevState => ({
          ...prevState,
          photos: finalPartPhotos.map(photo => {
            return photo.id === photoId ? { ...photo, ...updatedPhoto } : photo;
          })
        }));

        pushSnackbar(`Comment to photo has been successfully updated`, {
          variant: 'success'
        });
      }}
    />
  );
}
