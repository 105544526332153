import React from 'react';

export default function useBuildModuleState(buildModule) {
  const buildModuleState = React.useMemo(() => {
    if (buildModule.lastState) {
      return buildModule.lastState;
    }

    return buildModule.currentStatus;
  }, [buildModule]);

  return {
    buildModuleState
  };
}
