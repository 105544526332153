import React from 'react';
import MouldService from '../../services/MouldService';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import MouldDialog from './MouldDialog';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { SORT_ORDERS } from '../../constants';
import { useTitan } from '../Titan/Titan';
import { useParams } from 'react-router-dom';

export default function MouldsTable({ setNewPageForTab }) {
  const { pushSnackbar } = useTitan();

  const { page: pageParam = 0 } = useParams();

  const [openMouldDialog, setOpenMouldDialog] = React.useState(false);
  const [editableMould, setEditableMould] = React.useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const loadData = React.useCallback(async (params, config) => {
    const { data, pagination } = await MouldService.getMoulds(params, config);

    return {
      data,
      page: pagination.page - 1,
      totalCount: pagination.totalCount
    };
  }, []);

  const gridOption = {
    orders: {
      created_at: SORT_ORDERS.DESC
    },
    onChangePage: page => {
      setNewPageForTab('moulds', 'Moulds', page);
    }
  };

  if (pageParam && Number(pageParam) > 0) {
    gridOption.page = Number(pageParam);
  }

  const titanDataGridProps = useTitanDataGrid(loadData, gridOption);
  const { rows, setRows } = titanDataGridProps;

  const onCloseDialog = async () => {
    setOpenMouldDialog(false);
  };

  const onSave = React.useCallback(
    async data => {
      const {
        mouldIdentifierExists
      } = await MouldService.mouldIdentifierExists({
        mouldIdentifier: data.mouldIdentifier,
        ...(editableMould && { id: editableMould.id })
      });

      if (mouldIdentifierExists) {
        throw new Error(
          'Mould with the same identifier already exists within the organization'
        );
      }

      if (editableMould.id) {
        const updatedMould = await MouldService.updateMould(
          editableMould.id,
          data
        );
        setRows(prev =>
          prev.map(m => (m.id === updatedMould.id ? updatedMould : m))
        );
      } else {
        const newMould = await MouldService.createMould(data);
        setRows(prev => [newMould, ...prev]);
      }
    },
    [rows, editableMould]
  );

  const onDelete = React.useCallback(async () => {
    await MouldService.deleteMould(editableMould.id);

    pushSnackbar('Mould successfully deleted', { variant: 'success' });
    setOpenDeleteDialog(false);
    setRows(prev => prev.filter(m => m.id !== editableMould.id));
  }, [rows, editableMould]);

  const columns = React.useMemo(
    () => [
      {
        headerName: 'ID',
        field: 'mouldIdentifier',
        minWidth: 300,
        sortable: false
      },
      {
        headerName: 'Comment',
        field: 'comment',
        flex: 1,
        sortable: false
      },
      {
        headerName: '',
        field: 'actions',
        type: 'actions',
        width: 60,
        sortable: false,
        getActions: params =>
          [
            {
              label: 'Edit',
              icon: <EditIcon />,
              onClick: () => {
                setEditableMould(params.row);
                setOpenMouldDialog(true);
              }
            },
            {
              label: 'Delete',
              icon: <DeleteIcon />,
              onClick: () => {
                setEditableMould(params.row);
                setOpenDeleteDialog(true);
              }
            }
          ].map(action => (
            <GridActionsCellItem
              icon={action.icon}
              label={action.label}
              onClick={action.onClick}
              disabled={action.disabled}
              showInMenu
            />
          ))
      }
    ],
    []
  );

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        searchPlaceholder={'Search by mould identifier or comment'}
        columns={columns}
        title="Moulds"
        onCreateClick={() => {
          setOpenMouldDialog(true);
        }}
        createButtonLabel="Mould"
      />
      {openMouldDialog && (
        <MouldDialog
          onClose={onCloseDialog}
          editableMould={editableMould}
          onSave={onSave}
        />
      )}
      {openDeleteDialog && editableMould ? (
        <TitanConfirmationDialog
          title="Delete mould?"
          message={`Are you sure you want to delete mould ${
            editableMould.mouldIdentifier
          }?`}
          onClose={() => setOpenDeleteDialog(false)}
          onConfirm={onDelete}
        />
      ) : (
        ''
      )}
    </>
  );
}
