import React from 'react';
import { useTitan } from '../Titan/Titan';
import { useAuth0 } from '../Authentication/Auth0';
import { styled } from '@mui/material/styles';
import TitanPage from '../Titan/TitanPage';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import { CustomGridColumnsPanel } from '../TitanDataGrid/TitanDataGridColumnsPanel';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import ProjectDialog from './ProjectDialog';
import ProjectLink from './ProjectLink';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import DownloadIcon from '@mui/icons-material/Download';
import ProjectService from '../../services/ProjectService';
import AddIcon from '@mui/icons-material/Add';
import AddLinkIcon from '@mui/icons-material/AddLink';
import Stack from '@mui/material/Stack';
import SelectOdooProjectDialog from './SelectOdooProjectDialog';
import { ROUTES, SORT_ORDERS } from '../../constants';
import OdooService from '../../services/OdooService';
import ImportOdooProjectDialog from './ImportOdooProjectDialog';
import { useHistory } from 'react-router-dom';

export default function ProjectsManageList() {
  const history = useHistory();
  const { pushSnackbar, addPageToPageHistory, odooIntegrationEnabled } =
    useTitan();
  const { isOrganizationAdmin, isApplicationEngineer } = useAuth0();

  const isAbleToEdit = isOrganizationAdmin || isApplicationEngineer;

  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Projects',
        disabled: true,
      },
    ],
    [],
  );

  const [openProjectDialog, setOpenProjectDialog] = React.useState(false);
  const [editableProject, setEditableProject] = React.useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openOdooProjectsDialog, setOpenOdooProjectsDialog] =
    React.useState(false);

  const loadData = React.useCallback(async (params, config) => {
    const { data, pagination } = await ProjectService.getProjects(
      params,
      config,
    );

    return {
      data,
      page: pagination.page - 1,
      totalCount: pagination.totalCount,
    };
  }, []);

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Preview',
        field: 'previewImage',
        visibilityBreakpoint: 'sm',
        minWidth: 100,
        sortable: false,
        renderCell: ({ row }) => <Icon src={row.imageUrl} alt="" />,
      },
      {
        headerName: 'Name',
        field: 'name',
        hideable: false,
        visibilityBreakpoint: 'xs',
        minWidth: 180,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <ProjectLink project={row} />,
      },
      {
        headerName: 'Sales Order Number',
        field: 'salesOrderNumber',
        visibilityBreakpoint: 'xs',
        minWidth: 150,
        sortable: false,
      },
      {
        headerName: 'Project Key',
        field: 'projectKey',
        visibilityBreakpoint: 'xs',
        minWidth: 200,
        sortable: false,
      },
      {
        headerName: 'Project Number',
        field: 'projectNumber',
        visibilityBreakpoint: 'md',
        minWidth: 150,
        sortable: false,
      },
      {
        headerName: "Customer's Abbreviation",
        field: 'customerAbbreviation',
        visibilityBreakpoint: 'md',
        minWidth: 150,
        sortable: false,
      },
      {
        headerName: 'Part Name',
        field: 'partName',
        visibilityBreakpoint: 'md',
        minWidth: 150,
        sortable: false,
      },
      ...(odooIntegrationEnabled
        ? [
            {
              headerName: 'Odoo sync',
              field: 'odooProjectId',
              type: 'boolean',
              visibilityBreakpoint: 'md',
              minWidth: 120,
              sortable: false,
            },
          ]
        : []),
      {
        headerName: '',
        field: 'actions',
        type: 'actions',
        visibilityBreakpoint: 'sm',
        hideable: false,
        sortable: false,
        width: 120,
        getActions: (params) =>
          [
            ...(odooIntegrationEnabled
              ? params.row.odooProjectPageUrl
                ? [
                    {
                      showInMenu: false,
                      label: 'Open in Odoo',
                      icon: <LinkIcon />,
                      onClick: () => {
                        window.open(params.row.odooProjectPageUrl, '_blank');
                      },
                    },
                  ]
                : [
                    {
                      showInMenu: false,
                      label: 'Link with Odoo project',
                      icon: <AddLinkIcon />,
                      onClick: () => {
                        setEditableProject(params.row);
                        setOpenOdooProjectsDialog(true);
                      },
                    },
                  ]
              : []),
            {
              label: 'Edit',
              icon: <EditIcon />,
              onClick: () => {
                setEditableProject(params.row);
                setOpenProjectDialog(true);
              },
              disabled: !isAbleToEdit,
            },
            {
              label: 'Delete',
              icon: <DeleteIcon />,
              onClick: () => {
                setEditableProject(params.row);
                setOpenDeleteDialog(true);
              },
              disabled: !isAbleToEdit,
            },
          ].map((action) => (
            <GridActionsCellItem
              icon={action.icon}
              label={action.label}
              onClick={action.onClick}
              disabled={action.disabled}
              showInMenu={
                action.showInMenu !== undefined ? action.showInMenu : true
              }
            />
          )),
      },
    ],
    [],
  );

  const titanDataGridProps = useTitanDataGrid(loadData, {
    columns,
    orders: {
      created_at: SORT_ORDERS.DESC,
    },
  });

  const { setRows, reloadData, page } = titanDataGridProps;

  const onCloseDialog = async () => {
    setOpenProjectDialog(false);
    setEditableProject(null);
  };

  const onSave = async (data) => {
    if (editableProject) {
      const updatedProject = await ProjectService.updateProject(
        editableProject.id,
        data,
      );

      setRows((prev) =>
        prev.map((p) => (p.id === editableProject.id ? updatedProject : p)),
      );
    } else {
      const newProject = await ProjectService.createProject(data);

      setRows((prev) => [newProject, ...prev]);
    }

    pushSnackbar(
      `Project successfully ${editableProject ? 'updated' : 'created'}`,
      { variant: 'success' },
    );
    setOpenDeleteDialog(false);
    setEditableProject(null);
  };

  const onDelete = async () => {
    await ProjectService.deleteProject(editableProject.id);

    pushSnackbar('Project successfully deleted', { variant: 'success' });
    setOpenDeleteDialog(false);
    setRows((prev) => prev.filter((p) => p.id !== editableProject.id));
  };

  const handleSelectedOdooProjects = React.useCallback(
    async (selectedProjects) => {
      if (editableProject && selectedProjects.length === 1) {
        await ProjectService.updateProject(editableProject.id, {
          odooProjectId: selectedProjects[0].id,
        });
      } else {
        await Promise.all(
          selectedProjects.map((odooProject) =>
            OdooService.importProjectFromOdoo(odooProject.id),
          ),
        );
      }

      await reloadData();
    },
    [editableProject],
  );

  React.useEffect(() => {
    addPageToPageHistory({
      id: `PROJECTS`,
      url: ROUTES.PROJECTS(page),
      label: page === 0 ? `Projects` : `Projects | Page : ${page + 1}`,
    });
    history.push(ROUTES.PROJECTS_PAGE(page));
  }, [page]);

  return (
    <TitanPage
      title="Projects"
      breadcrumbs={breadcrumbs}
      headerContent={
        <Stack spacing={2} direction="row">
          {odooIntegrationEnabled && (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<DownloadIcon />}
              onClick={() => {
                setOpenOdooProjectsDialog(true);
                setEditableProject(null);
              }}
            >
              Odoo import
            </Button>
          )}
          <Tooltip
            title={
              isOrganizationAdmin
                ? ''
                : "Login as organization's admin to create project"
            }
          >
            <span>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => setOpenProjectDialog(true)}
                disabled={!isOrganizationAdmin}
              >
                Project
              </Button>
            </span>
          </Tooltip>
        </Stack>
      }
    >
      <TitanDataGrid
        {...titanDataGridProps}
        components={{
          Toolbar: TitanDataGridToolbar,
          ColumnsPanel: CustomGridColumnsPanel,
        }}
        searchPlaceholder="Search by Project name..."
      />
      {openProjectDialog && (
        <ProjectDialog
          onClose={onCloseDialog}
          project={editableProject}
          onSave={onSave}
        />
      )}
      {openDeleteDialog && (
        <TitanConfirmationDialog
          title="Delete project?"
          message={`Are you sure you want to delete this project ${editableProject.name}?`}
          onClose={() => {
            setOpenDeleteDialog(false);
            setEditableProject(null);
          }}
          onConfirm={onDelete}
        />
      )}

      {openOdooProjectsDialog && !editableProject ? (
        <ImportOdooProjectDialog
          onClose={async () => setOpenOdooProjectsDialog(false)}
          onSave={onSave}
        />
      ) : (
        ''
      )}

      {openOdooProjectsDialog && editableProject ? (
        <SelectOdooProjectDialog
          title={
            editableProject
              ? 'Link fP project with odoo project'
              : 'Import Odoo project to fP'
          }
          onSelect={handleSelectedOdooProjects}
          onClose={async () => {
            setOpenOdooProjectsDialog(false);
            setEditableProject(null);
          }}
          saveButtonLabel={editableProject ? 'Link' : 'Import'}
        />
      ) : (
        ''
      )}
    </TitanPage>
  );
}

const Icon = styled('img')({
  display: 'block',
  maxWidth: '60px',
  maxHeight: '50px',
});
