import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../constants';
import ComponentIcon from '../../assets/icons/component-icon.svg';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import OverflowTip from '../ExpandRenderCell/OverflowTip';
import { useTitan } from '../Titan/Titan';

const useStyles = makeStyles(theme => ({
  hiddenOverflowBlock: {
    overflow: 'hidden'
  },
  link: {
    lineHeight: 'inherit',
    fontWeight: 'inherit'
  }
}));

export default function PreformLink({
  preform,
  children,
  useName = true,
  variant = 'subtitle1',
  width,
  onClick = e => e.stopPropagation()
}) {
  const classes = useStyles();
  const location = useLocation();
  const imageWithSpacingWidth = 52;

  const { addPageToPageHistory, preparePageObjectForLink } = useTitan();

  const handleClick = e => {
    addPageToPageHistory(
      preparePageObjectForLink(
        'PREFORM',
        preform.id,
        preform.preformKey || `Preform:${preform.id}`,
        ROUTES.PREFORM_TAB(preform.id, 'summary'),
        'Summary'
      )
    );

    if (onClick) {
      onClick(e);
    }
  };

  const content = (
    <Stack className={classes.hiddenOverflowBlock} direction="row" spacing={1}>
      <img src={ComponentIcon} alt="" />
      {width ? (
        <OverflowTip width={width - imageWithSpacingWidth}>
          {useName
            ? preform.preformKey
              ? preform.preformKey
              : preform.id
            : children}
        </OverflowTip>
      ) : useName ? (
        <Typography className={classes.link} variant={variant}>
          {preform.preformKey ? preform.preformKey : preform.id}
        </Typography>
      ) : (
        children
      )}
    </Stack>
  );

  if (preform.hasReadAccess === false) {
    return <div>{content}</div>;
  }

  return (
    <Link
      className={classes.hiddenOverflowBlock}
      onClick={handleClick}
      to={{
        pathname: ROUTES.PREFORM(preform.id),
        state: { from: location.pathname }
      }}
      component={RouterLink}
    >
      {content}
    </Link>
  );
}
