import React from 'react';
import TitanDialog from '../Titan/TitanDialog';
import ProjectForm from './ProjectForm';

export default function ProjectDialog({ onSave, onClose, project }) {
  const [name, setName] = React.useState(project?.name || '');
  const [salesOrderNumber, setSalesOrderNumber] = React.useState(
    project?.salesOrderNumber || ''
  );
  const [projectNumber, setProjectNumber] = React.useState(
    project?.projectNumber || ''
  );
  const [customerAbbreviation, setCustomerAbbreviation] = React.useState(
    project?.customerAbbreviation || ''
  );
  const [partName, setPartName] = React.useState(project?.partName || '');
  const [projectKey, setProjectKey] = React.useState(project?.projectKey || '');
  const [previewImage, setPreviewImage] = React.useState();
  const [displayImage, setDisplayImage] = React.useState(
    project?.imageUrl || ''
  );
  const [autoGenerateProjectKey, setAutoGenerateProjectKey] = React.useState(
    !project
  );

  const onSubmitForm = React.useCallback(() => {
    return onSave({
      name,
      salesOrderNumber: salesOrderNumber || null,
      projectNumber,
      customerAbbreviation,
      partName: partName || null,
      projectKey: projectKey || null,
      previewImage
    });
  }, [
    name,
    salesOrderNumber,
    projectNumber,
    customerAbbreviation,
    partName,
    projectKey,
    previewImage
  ]);

  return (
    <TitanDialog
      title={`${project ? 'Edit' : 'Create'} Project`}
      maxWidth="xs"
      onClose={onClose}
      onSave={onSubmitForm}
      saveButtonLabel={project ? 'Save' : 'Create'}
      isValid={
        name.length !== 0 &&
        projectNumber.length !== 0 &&
        customerAbbreviation.length !== 0 &&
        customerAbbreviation.length <= 5
      }
    >
      <ProjectForm
        name={name}
        setName={setName}
        projectNumber={projectNumber}
        setProjectNumber={setProjectNumber}
        salesOrderNumber={salesOrderNumber}
        setSalesOrderNumber={setSalesOrderNumber}
        customerAbbreviation={customerAbbreviation}
        setCustomerAbbreviation={setCustomerAbbreviation}
        partName={partName}
        setPartName={setPartName}
        projectKey={projectKey}
        setProjectKey={setProjectKey}
        previewImage={previewImage}
        setPreviewImage={setPreviewImage}
        autoGenerateProjectKey={autoGenerateProjectKey}
        setAutoGenerateProjectKey={setAutoGenerateProjectKey}
        displayImage={displayImage}
        setDisplayImage={setDisplayImage}
      />
    </TitanDialog>
  );
}
