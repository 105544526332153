import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Link } from '@mui/material';
import { ROUTES } from '../../constants';
import { makeStyles } from '@mui/styles';
import OverflowTip from '../ExpandRenderCell/OverflowTip';
import { useTitan } from '../Titan/Titan';

const useStyles = makeStyles(theme => ({
  hiddenOverflowBlock: {
    overflow: 'hidden'
  }
}));

export default function PresetLink(props) {
  const {
    preset,
    children,
    useName = true,
    width,
    onClick = e => e.stopPropagation()
  } = props;
  const location = useLocation();
  const classes = useStyles();

  const { addPageToPageHistory } = useTitan();

  const handleClick = e => {
    addPageToPageHistory({
      id: 'PRESET' + preset.id,
      url: ROUTES.PRESET(preset.id),
      label: `${preset.name}`
    });

    if (onClick) {
      onClick(e);
    }
  };

  let content;

  if (useName) {
    content = [preset.name];

    if (preset.deletedAt) {
      content.push('(deleted)');
    }

    content = content.join(' ');
  } else {
    content = children;
  }

  return (
    <>
      <Link
        className={classes.hiddenOverflowBlock}
        onClick={handleClick}
        to={{
          pathname: ROUTES.PRESET(preset.id),
          state: { from: location.pathname }
        }}
        component={RouterLink}
      >
        {width ? (
          <OverflowTip width={width}>{content}</OverflowTip>
        ) : (
          <span>{content}</span>
        )}
      </Link>
    </>
  );
}
