import React from 'react';
import TitanPage from '../Titan/TitanPage';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { COMPONENT_STATUSES, ROUTES } from '../../constants';
import BuildJobService from '../../services/BuildJobService';
import Grid from '@mui/material/Grid';
import TitanInfoItem from '../Titan/TitanInfoItem';
import BuildModuleLink from '../BuildModule/BuildModuleLink';
import TitanDateTime from '../Titan/TitanDateTime';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import BuildPlanLink from '../BuildPlan/BuildPlanLink';
import BuildJobPageActionButtons from './BuildJobPageActionButtons';
import PreformsTable from '../Preforms/PreformsTable';
import Paper from '@mui/material/Paper';
import BuildJobPostBuildingDialog from './BuildJobPostBuildingDialog';
import TitanEditableText, {
  TITAN_INPUT_TYPES,
} from '../Titan/TitanEditableText';
import TitanTabs from '../Titan/TitanTabs';
import BuildJobSpoolsTable from './BuildJobSpoolsTable';
import BuildJobPhotos from './BuildJobPhotos';
import BuildJobTemperatures from './BuildJobTemperatures';
import BuildJobStates from './BuildJobStates';
import ComponentStatus from '../Component/ComponentStatus';
import { useTitan } from '../Titan/Titan';
import useBuildJob from './use-build-job';
import ManufacturingOrderLink from '../ManufacturingOrders/ManufacturingOrderLink';
import { useAuth0 } from '../Authentication/Auth0';
import ProjectLink from '../Projects/ProjectLink';
import { Stack } from '@mui/material';
import ManufacturingOrderActionsButton from '../ManufacturingOrders/ManufacturingOrderActionsButtons';
import TitanDuration from '../Titan/TitanDuration';
import CopyToClipboard from '../Titan/CopyToClipboard';

export default function BuildJobPage() {
  const { buildJobId, action = '', tab = 'summary' } = useParams();
  const { isOrganizationAdmin } = useAuth0();
  const history = useHistory();
  const location = useLocation();

  const { addPageToPageHistory, backToPreviousPage } = useTitan();

  const [error, setError] = React.useState(null);

  const [openPostBuildingDialog, setOpenPostBuildingDialog] =
    React.useState(false);

  const loadBuildJob = React.useCallback(
    (openPostBuilding = true) =>
      BuildJobService.getBuildJob(buildJobId, {
        withRelated: [
          'buildPlan',
          'buildPlan.files',
          'buildPlan.project',
          'printer',
          'spools',
          'photos',
          'creator',
          'spools.materialType',
          'components',
          'components.preformType',
          'components.preformType.customFields',
          'components.preformType.customFields.image',
          'components.photos',
          'components.customFields',
          'components.customFields.preformTypeCustomField',
          'components.customFields.preformTypeCustomField',
          'printHead',
          'manufacturingOrder',
        ],
      })
        .then((res) => {
          const buildJob = res.data;

          if (
            openPostBuilding &&
            action === 'post-building' &&
            [
              COMPONENT_STATUSES.POST_BUILDING,
              COMPONENT_STATUSES.POST_CANCELED,
              COMPONENT_STATUSES.POST_FAILED,
            ].includes(buildJob.status)
          ) {
            setOpenPostBuildingDialog(true);
          }

          return buildJob;
        })
        .catch((error) => {
          setError(error.response.data.message);
        }),
    [action, buildJobId],
  );

  const { loading, buildJob, setBuildJob } = useBuildJob(loadBuildJob);

  const breadcrumbs = React.useMemo(() => {
    if (!buildJob) {
      return [];
    }

    return [
      {
        name: 'Build Plans',
        path: ROUTES.BUILD_PLANS('page', 0),
      },
      {
        name: buildJob.buildPlan ? buildJob.buildPlan.name : 'Build Plan',
        path: ROUTES.BUILD_PLAN_V2(buildJob.buildPlanId),
      },
      {
        name: buildJob.jobKey ? buildJob.jobKey : 'Build Job',
        disabled: true,
      },
    ];
  }, [buildJob]);

  const preparePageObject = React.useCallback(
    (tab, tabForURL) => {
      return {
        id: `BUILD_JOB:${buildJobId}`,
        url:
          tabForURL === 'preforms'
            ? ROUTES.BUILD_JOB_TAB_PAGE(buildJobId, tabForURL, 0, action)
            : ROUTES.BUILD_JOB_TAB(buildJobId, tabForURL, action),
        label: `${buildJob.jobKey || `Build Job:${buildJob.jobKey}`} | ${tab}`,
        addInfo: {
          buildPlan: buildJob.buildPlan,
        },
      };
    },
    [buildJob],
  );

  const buildJobStatus = React.useMemo(() => {
    if (!buildJob) {
      return null;
    }

    return {
      status: buildJob.status,
      progress: buildJob.progress,
    };
  }, [buildJob]);

  const updateBuildJob = React.useCallback(
    async (fieldName, value, extraDataToUpdate = {}) => {
      await BuildJobService.updateBuildJob(buildJobId, {
        [fieldName]: value,
      });

      setBuildJob({ ...buildJob, ...extraDataToUpdate, [fieldName]: value });
    },
    [buildJob],
  );

  const tabs = React.useMemo(() => {
    if (!buildJob) {
      return [];
    }

    return [
      {
        label: 'Summary',
        value: 'summary',
        content: (
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TitanInfoItem label="Build Module">
                  {buildJob.printer ? (
                    <BuildModuleLink buildModule={buildJob.printer} />
                  ) : (
                    ''
                  )}
                </TitanInfoItem>
              </Grid>
              <Grid item xs={4}>
                <TitanInfoItem label="Build Plan">
                  {buildJob.buildPlan ? (
                    <BuildPlanLink buildPlan={buildJob.buildPlan} width={230} />
                  ) : (
                    ''
                  )}
                </TitanInfoItem>
              </Grid>
              <Grid item xs={4}>
                <TitanInfoItem label="Status">
                  {buildJobStatus && (
                    <ComponentStatus component={buildJobStatus} />
                  )}
                </TitanInfoItem>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TitanInfoItem label="Build Start Time">
                  <TitanDateTime
                    time={buildJob.startBuildTime}
                    emptyValue="-"
                  />
                </TitanInfoItem>
              </Grid>
              <Grid item xs={4}>
                <TitanInfoItem label="Build End Time">
                  <TitanDateTime time={buildJob.endBuildTime} emptyValue="-" />
                </TitanInfoItem>
              </Grid>
              <Grid item xs={3}>
                <TitanInfoItem label="Build Time">
                  <TitanDuration duration={buildJob.buildTime} />
                </TitanInfoItem>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TitanInfoItem label="BMA version">
                  {buildJob.buildModuleVersion || '-'}
                </TitanInfoItem>
              </Grid>
              {buildJob.status === COMPONENT_STATUSES.SCHEDULED && (
                <Grid item xs={4}>
                  <TitanInfoItem label="Scheduled">
                    <TitanTimeAgo time={buildJob.createdAt} />
                  </TitanInfoItem>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TitanInfoItem label="Project">
                  {buildJob?.buildPlan?.project ? (
                    <ProjectLink project={buildJob.buildPlan.project} />
                  ) : (
                    '-'
                  )}
                </TitanInfoItem>
              </Grid>
              <Grid item xs={4}>
                <TitanInfoItem label="Manufacturing Order">
                  <Stack direction="row" spacing={1} alignItems="center">
                    {buildJob.manufacturingOrderId ? (
                      <ManufacturingOrderLink
                        manufacturingOrder={
                          buildJob.manufacturingOrder
                            ? buildJob.manufacturingOrder
                            : { id: buildJob.manufacturingOrderId }
                        }
                      />
                    ) : (
                      <span>-</span>
                    )}

                    {buildJob?.buildPlan?.projectId ? (
                      <ManufacturingOrderActionsButton
                        manufacturingOrder={buildJob.manufacturingOrder}
                        filters={{
                          projectId: buildJob.buildPlan.projectId,
                          buildPlanIds: [buildJob.buildPlanId],
                        }}
                        onChange={(manufacturingOrder) =>
                          updateBuildJob(
                            'manufacturingOrderId',
                            manufacturingOrder ? manufacturingOrder.id : null,
                            {
                              manufacturingOrder,
                            },
                          )
                        }
                      />
                    ) : (
                      ''
                    )}
                  </Stack>
                </TitanInfoItem>
              </Grid>

              <Grid item xs={4}>
                <TitanInfoItem label="Operator">
                  {buildJob.creator &&
                  buildJob.creator.name &&
                  buildJob.creator.email ? (
                    <CopyToClipboard
                      text={buildJob.creator.name}
                      copyValue={buildJob.creator.email}
                      label="operators's email"
                    />
                  ) : (
                    '-'
                  )}
                </TitanInfoItem>
              </Grid>
            </Grid>

            {isOrganizationAdmin && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TitanInfoItem label="Tape Estimated Amount">
                      <TitanEditableText
                        inputType={TITAN_INPUT_TYPES.NUMBER}
                        text={buildJob.estimatedFiber}
                        label="Tape Estimated Amount"
                        validator={(value) => value.length > 0}
                        onChangeValue={(value) =>
                          updateBuildJob('estimatedFiber', value)
                        }
                        suffix="g"
                      />
                    </TitanInfoItem>
                  </Grid>

                  <Grid item xs={4}>
                    <TitanInfoItem label="Polymer Estimated Amount">
                      <TitanEditableText
                        inputType={TITAN_INPUT_TYPES.NUMBER}
                        text={buildJob.estimatedPolymer}
                        label="Polymer Estimated Amount"
                        validator={(value) => value.length > 0}
                        onChangeValue={(value) =>
                          updateBuildJob('estimatedPolymer', value)
                        }
                        suffix="g"
                      />
                    </TitanInfoItem>
                  </Grid>
                </Grid>
                {/*<Grid container spacing={2}>*/}
                {/*  <Grid item xs={4}>*/}
                {/*    <TitanInfoItem label="Polymer Actual Amount">*/}
                {/*      <TitanEditableText*/}
                {/*        inputType={TITAN_INPUT_TYPES.NUMBER}*/}
                {/*        text={buildJob.actualPolymer}*/}
                {/*        label="Polymer Estimated Amount"*/}
                {/*        validator={(value) => value.length > 0}*/}
                {/*        onChangeValue={(value) =>*/}
                {/*          updateBuildJob('actualPolymer', value)*/}
                {/*        }*/}
                {/*        enableEditing={[*/}
                {/*          COMPONENT_STATUSES.POST_BUILDING,*/}
                {/*          COMPONENT_STATUSES.BUILT,*/}
                {/*        ].includes(buildJob.status)}*/}
                {/*        disableReason="This Job hasn't been built yet"*/}
                {/*        suffix="g"*/}
                {/*      />*/}
                {/*    </TitanInfoItem>*/}
                {/*  </Grid>*/}

                {/*  <Grid item xs={4}>*/}
                {/*    <TitanInfoItem label="Fiber Actual Amount">*/}
                {/*      <TitanEditableText*/}
                {/*        inputType={TITAN_INPUT_TYPES.NUMBER}*/}
                {/*        text={buildJob.actualFiber}*/}
                {/*        label="Fiber Actual Amount"*/}
                {/*        validator={(value) => value.length > 0}*/}
                {/*        onChangeValue={(value) =>*/}
                {/*          updateBuildJob('actualFiber', value)*/}
                {/*        }*/}
                {/*        enableEditing={[*/}
                {/*          COMPONENT_STATUSES.POST_BUILDING,*/}
                {/*          COMPONENT_STATUSES.BUILT,*/}
                {/*        ].includes(buildJob.status)}*/}
                {/*        disableReason="This Job hasn't been built yet"*/}
                {/*        suffix="g"*/}
                {/*      />*/}
                {/*    </TitanInfoItem>*/}
                {/*  </Grid>*/}
                {/*</Grid>*/}
              </>
            )}

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TitanInfoItem label="Print Head">
                  {buildJob.printHead?.printHeadId
                    ? buildJob.printHead?.printHeadId
                    : '-'}
                </TitanInfoItem>
              </Grid>

              <Grid item xs={4}>
                <TitanInfoItem label="Estimated Build Time">
                  {buildJob.estimatedBuildTime ? (
                    <TitanDuration duration={buildJob.estimatedBuildTime} />
                  ) : (
                    '-'
                  )}
                </TitanInfoItem>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TitanInfoItem label="Comment">
                  <TitanEditableText
                    label="Build Job comment"
                    text={buildJob.comment}
                    onChangeValue={(comment) =>
                      updateBuildJob('comment', comment)
                    }
                  />
                </TitanInfoItem>
              </Grid>
            </Grid>
          </Paper>
        ),
      },
      {
        label: 'Temperature',
        value: 'temperature',
        content: (
          <BuildJobTemperatures
            buildModuleId={buildJob.printerId}
            buildJobId={buildJob.id}
            startBuildTime={buildJob.startBuildTime}
            endBuildTime={buildJob.endBuildTime}
          />
        ),
      },
      {
        label: 'Preforms',
        value: 'preforms',
        content: <PreformsTable buildJobId={buildJob.id} action={action} />,
      },
      {
        label: 'Spools',
        value: 'spools',
        content: <BuildJobSpoolsTable buildJob={buildJob} />,
      },
      {
        label: 'Photos',
        value: 'photos',
        content: buildJob ? (
          <BuildJobPhotos buildJob={buildJob} setBuildJob={setBuildJob} />
        ) : (
          ''
        ),
      },
      {
        label: 'States History',
        value: 'states',
        content: buildJob ? <BuildJobStates buildJob={buildJob} /> : '',
      },
    ];
  }, [buildJob]);

  return (
    <TitanPage
      loading={loading}
      error={error}
      title={
        buildJob?.jobKey ||
        `Build Job ${buildJob ? buildJob.id.split('-')[0] : ''}`
      }
      breadcrumbs={breadcrumbs}
      onBackButtonClick={() => {
        backToPreviousPage(location);
      }}
      headerContent={
        <BuildJobPageActionButtons
          buildJob={buildJob}
          setBuildJob={(updatedBuildJob) =>
            setBuildJob({ ...buildJob, ...updatedBuildJob })
          }
        />
      }
    >
      <TitanTabs
        tabs={tabs}
        activeTab={tab}
        onChangeTab={(value) => {
          const activeLabel = value.charAt(0).toUpperCase() + value.slice(1);
          addPageToPageHistory(preparePageObject(activeLabel, value));
          history.push(
            value === 'preforms'
              ? ROUTES.BUILD_JOB_TAB_PAGE(buildJobId, value, 0, action)
              : ROUTES.BUILD_JOB_TAB(buildJobId, value, action),
          );
        }}
      />

      {openPostBuildingDialog && (
        <BuildJobPostBuildingDialog
          buildJob={buildJob}
          onClose={() => {
            if (action === 'post-building') {
              history.push({
                pathname: ROUTES.BUILD_JOB(buildJob.id),
                state: { from: location.pathname },
              });
            }

            loadBuildJob(false);
            setOpenPostBuildingDialog(false);
          }}
        />
      )}
    </TitanPage>
  );
}
