import React from 'react';
import { Stack } from '@mui/material';
import BuildModuleStatesChart from './BuildModuleStatesChart';
import BuildModulePreformsChart from './BuildModulePreformsChart';

function BuildModuleAnalytics({ buildModule }) {
  return (
    <Stack spacing={2}>
      <BuildModuleStatesChart buildModule={buildModule} />
      <BuildModulePreformsChart buildModule={buildModule} />
    </Stack>
  );
}

export default BuildModuleAnalytics;
