import TitanDialog from '../Titan/TitanDialog';
import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import TitanCircularProgress from '../Titan/TitanCircularProgress';
import useAsyncEffect from 'use-async-effect';
import MaterialTypeService from '../../services/MaterialTypeService';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { useAuth0 } from '../Authentication/Auth0';
import { PERMISSIONS } from '../../constants/auth0';
import MaterialDialog from '../Materials/MaterialDialog';

export default function PresetImportDialog({ onSave, onClose, preset }) {
  const { permissions, checkPermissions } = useAuth0();

  const canManageMaterials = checkPermissions(permissions, [
    PERMISSIONS.MANAGE_MATERIAL_TYPES,
  ]);

  const onSubmitForm = () => {
    return onSave({
      ...preset,
      fiberMaterialTypeId,
      polymerMaterialTypeId,
    });
  };

  const [fiberMaterialTypeId, setFiberMaterialTypeId] = React.useState(
    preset.fiberMaterialTypeId,
  );
  const [polymerMaterialTypeId, setPolymerMaterialTypeId] = React.useState(
    preset.polymerMaterialTypeId,
  );

  const [fiberMaterials, setFiberMaterials] = React.useState([]);
  const [polymerMaterials, setPolymerMaterials] = React.useState([]);

  const [openMaterialTypeDialog, setOpenMaterialTypeDialog] =
    React.useState(null);
  const [newMaterialType, setNewMaterialType] = React.useState();

  const [loading, setLoading] = React.useState(true);

  const loadMaterialTypes = React.useCallback(async () => {
    setLoading(true);

    const { data } = await MaterialTypeService.getMaterialTypes({
      page: 0,
      pageSize: 1000,
    });

    setFiberMaterials(data.filter((material) => material.type === 'FIBER'));
    setPolymerMaterials(data.filter((material) => material.type === 'PLASTIC'));

    setLoading(false);
  }, []);

  useAsyncEffect(async () => {
    await loadMaterialTypes();
  }, []);

  return (
    <TitanDialog
      title={'Import preset configuration'}
      maxWidth="xs"
      onClose={onClose}
      onSave={onSubmitForm}
      saveButtonLabel="Save"
      isValid={fiberMaterialTypeId && polymerMaterialTypeId}
    >
      {loading ? (
        <TitanCircularProgress />
      ) : (
        <>
          <Stack direction="row" spacing={2} sx={{ mb: 2 }} alignItems="center">
            <FormControl fullWidth>
              <InputLabel>Tape material</InputLabel>
              <Select
                error={!fiberMaterialTypeId}
                value={fiberMaterialTypeId}
                onChange={(e) => setFiberMaterialTypeId(e.target.value)}
                label="Tape material"
              >
                {fiberMaterials.map((material) => (
                  <MenuItem key={material.id} value={material.id}>
                    {material.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box>
              <IconButton
                disabled={!canManageMaterials}
                onClick={() => {
                  setNewMaterialType({
                    type: 'FIBER',
                  });
                  setOpenMaterialTypeDialog(true);
                }}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2} sx={{ mb: 2 }} alignItems="center">
            <FormControl fullWidth>
              <InputLabel>Polymer material</InputLabel>
              <Select
                error={!polymerMaterialTypeId}
                value={polymerMaterialTypeId}
                onChange={(e) => setPolymerMaterialTypeId(e.target.value)}
                label="Polymer material"
              >
                {polymerMaterials.map((material) => (
                  <MenuItem key={material.id} value={material.id}>
                    {material.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box>
              <IconButton
                disabled={!canManageMaterials}
                onClick={() => {
                  setNewMaterialType({
                    type: 'PLASTIC',
                  });
                  setOpenMaterialTypeDialog(true);
                }}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Stack>
        </>
      )}
      {openMaterialTypeDialog && (
        <MaterialDialog
          materialType={newMaterialType}
          onSave={async (data) => {
            const materialType = await MaterialTypeService.createMaterialType(
              data,
            );

            await loadMaterialTypes();

            materialType.type === 'FIBER'
              ? setFiberMaterialTypeId(materialType.id)
              : setPolymerMaterialTypeId(materialType.id);
          }}
          onClose={() => {
            setOpenMaterialTypeDialog(false);
          }}
        />
      )}
    </TitanDialog>
  );
}
