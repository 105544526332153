import React from 'react';
import { useAuth0 } from '../Authentication/Auth0';
import FibrifyItemsSelectionDialog from './FibrifyItemsSelectionDialog';

import SpoolService from '../../services/SpoolService';
import renderCellExpand from '../ExpandRenderCell/ExpandRenderCell';
import MaterialLink from '../Materials/MaterialLink';
import Rating from '@mui/material/Rating';

export default function FibrifySpoolsSelectionDialog({
  title,
  multipleSelection = false,
  filters = {},
  filtersContent,
  currentSpools = [],
  onSave,
  saveButtonLabel = 'Confirm',
  onClose
}) {
  const loadData = React.useCallback(
    async ({ page, pageSize, search }, config) => {
      const params = {
        ...filters,
        page: page,
        pageSize: pageSize,
        withRelated: ['materialType', 'tags'],
        order: ['created_at:desc']
      };

      if (search) {
        params.search = search;
      }

      const { data, pagination } = await SpoolService.getSpools(params, config);

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount
      };
    },
    [filters]
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: 'ID',
        field: 'spoolIdentifier',
        pinnable: true,
        hideable: false,
        visibilityBreakpoint: 'sm',
        flex: 1,
        minWidth: 100,
        sortable: false,
        renderCell: ({ row, colDef }) =>
          renderCellExpand(row.spoolIdentifier, colDef.computedWidth)
      },
      {
        headerName: 'Material',
        field: 'materialType',
        visibilityBreakpoint: 'sm',
        minWidth: 120,
        flex: 1,
        sortable: false,
        renderCell: ({ row, colDef }) => (
          <MaterialLink
            materialType={row.materialType}
            width={colDef.computedWidth}
          />
        )
      },
      {
        headerName: 'Type',
        field: 'type',
        visibilityBreakpoint: 'lg',
        width: 80,
        sortable: false
      },
      {
        headerName: 'Tags',
        field: 'tags',
        visibilityBreakpoint: 'sm',
        width: 80,
        sortable: false,
        renderCell: ({ row }) =>
          row.tags && row.tags !== 0
            ? row.tags.map(tag => tag.name).join(', ')
            : ''
      },
      {
        headerName: 'Original Weight (g)',
        field: 'originalWeight',
        visibilityBreakpoint: 'lg',
        minWidth: 160,
        sortable: false
      },
      {
        headerName: 'Actual Weight (g)',
        field: 'currentWeight',
        visibilityBreakpoint: 'lg',
        minWidth: 160,
        sortable: false
      },
      {
        headerName: 'Quality',
        field: 'quality',
        visibilityBreakpoint: 'lg',
        minWidth: 140,
        sortable: false,
        renderCell: ({ row }) => <Rating value={row.quality} readOnly />
      },
      {
        headerName: 'Comment',
        field: 'comment',
        visibilityBreakpoint: 'lg',
        width: 150,
        sortable: false
      }
    ],
    []
  );

  return (
    <FibrifyItemsSelectionDialog
      onClose={onClose}
      selectedItems={currentSpools}
      loadData={loadData}
      columns={columns}
      title={title}
      multipleSelection={multipleSelection}
      onSave={onSave}
      saveButtonLabel={saveButtonLabel}
      searchPlaceholder="Search by spool identifier"
      chipsNameKey="spoolIdentifier"
      filtersContent={filtersContent}
    />
  );
}
