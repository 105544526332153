import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material';

export default function TitanButton({
  isLoading,
  children,
  progressSize = 20,
  ...props
}) {
  const theme = useTheme();

  const loaderColor =
    props.color && props.color === 'primary' ? 'secondary' : 'primary';

  return (
    <Button {...props}>
      {children}{' '}
      {isLoading && (
        <CircularProgress
          color={loaderColor}
          size={progressSize}
          style={{ marginLeft: theme.spacing(2) }}
        />
      )}
    </Button>
  );
}
