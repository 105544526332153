import React from 'react';
import Paper from '@mui/material/Paper';
import { Grid, Tab, Tabs } from '@mui/material';

import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { useHistory, useLocation } from 'react-router-dom';
import TitanPage from '../Titan/TitanPage';
import { FUSION_PLAN_CATEGORIES, ROUTES } from '../../constants';
import FusionPlanService from '../../services/FusionPlanService';
import FusionComparePlansTable from '../FusionPlan/FusionComparePlansTable';

export default function PlansComparsionPage() {
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = React.useState(true);
  const [fusionPlansIDs, setFusionPlansIDs] = React.useState(() => {
    const savedItems = localStorage.getItem('comparableFusionPlans');
    const parsedData = JSON.parse(savedItems);
    return parsedData ? parsedData : [];
  });

  const [hideSame, setHideSame] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState(
    FUSION_PLAN_CATEGORIES[0],
  );
  const [fusionPlans, setFusionPlans] = React.useState([]);

  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Compare Plans',
        disabled: true,
      },
    ],
    [],
  );

  const loadFusionPlans = async () => {
    setLoading(true);
    const responsePlans = await Promise.all(
      fusionPlansIDs.map((planId) =>
        FusionPlanService.getFusionPlan(
          planId,
          { withRelated: ['member'] },
          'v2',
        ),
      ),
    );
    setFusionPlans(responsePlans);
    setLoading(false);
  };

  const deleteFusionPlanFromComparsion = React.useCallback(
    (planId) => {
      setFusionPlansIDs((prev) => [...prev].filter((plan) => plan !== planId));
    },
    [fusionPlansIDs],
  );

  React.useEffect(() => {
    localStorage.setItem(
      'comparableFusionPlans',
      JSON.stringify(fusionPlansIDs),
    );
  }, [fusionPlansIDs]);

  React.useEffect(() => {
    loadFusionPlans();
  }, [fusionPlansIDs]);

  return (
    <>
      <TitanPage
        loading={loading}
        breadcrumbs={breadcrumbs}
        title="Compare plans"
        onBackButtonClick={() =>
          history.push({
            pathname: ROUTES.FUSION_PLANS('page', 0),
            state: { from: location.pathname },
          })
        }
        headerContent={
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              sx={{
                width: {
                  xs: '50px',
                  md: 'auto',
                },
              }}
            >
              Show all rows
            </Typography>
            <Switch
              checked={hideSame}
              onChange={() => setHideSame((prev) => !prev)}
              color="primary"
            />
            <Typography>Show different rows</Typography>
          </Stack>
        }
      >
        <Paper sx={{ p: 2, mb: 2 }}>
          <Stack>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Tabs
                  orientation="vertical"
                  value={selectedCategory}
                  onChange={(event, category) => {
                    setSelectedCategory(category);
                  }}
                  sx={{ '& .MuiTabs-indicator': { left: 0, right: 'auto' } }}
                >
                  {FUSION_PLAN_CATEGORIES.map((category) => {
                    return (
                      <Tab
                        sx={{ alignItems: 'start' }}
                        key={category}
                        label={category}
                        value={category}
                      />
                    );
                  })}
                </Tabs>
              </Grid>
              <Grid item xs={9}>
                <FusionComparePlansTable
                  setHideSame={setHideSame}
                  hideSame={hideSame}
                  category={selectedCategory}
                  fusionPlans={fusionPlans}
                  deleteFusionPlanFromComparsion={
                    deleteFusionPlanFromComparsion
                  }
                />
              </Grid>
            </Grid>
          </Stack>
        </Paper>
      </TitanPage>
    </>
  );
}
