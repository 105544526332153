import React from 'react';
import Skeleton from '@mui/material/Skeleton';

export default function BuildPlanThumbnail({ buildPlan }) {
  let file;
  if (buildPlan.files) {
    file = buildPlan.files.find(file =>
      file.location.endsWith('build-plate-thumbnail.png')
    );

    if (!file) {
      file = buildPlan.files.find(file =>
        file.location.endsWith('build-plate.png')
      );
    }
  }
  return (
    <>
      {file ? (
        <img
          style={{
            width: 120,
            height: 'auto',
            contain: 'content'
          }}
          alt=""
          src={file.url}
        />
      ) : (
        <Skeleton variant="rectangular" width={120} height={100} />
      )}
    </>
  );
}
