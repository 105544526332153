import React, { useContext } from 'react';
import Link from '@mui/material/Link';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import TitanDialog from '../../Titan/TitanDialog';
import TitanDataGrid from '../../TitanDataGrid/TitanDataGrid';
import useTitanDataGrid from '../../TitanDataGrid/useTitanDataGrid';
import OdooService from '../../../services/OdooService';
import ReviewManufacturingOrderDialog from './ReviewManufacturingOrderStep';

export const OdooImportMOContext = React.createContext();
export const useBuildDialog = () => useContext(OdooImportMOContext);

const STEP_KEYS = {
  SELECT_MANUFACTURING_ORDER: 'SELECT_MANUFACTURING_ORDER',
  REVIEW_MANUFACTURING_ORDER: 'REVIEW_MANUFACTURING_ORDER'
};

export const ITEMS_TYPES = {
  PREFORMS: 'PREFORMS',
  FINAL_PARTS: 'FINAL_PARTS'
};

export default function OdooImportManufacturingOrderDialog({
  odooProductId,
  onSave,
  onClose
}) {
  const [steps, setSteps] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);

  const [
    selectedManufacturingOrders,
    setSelectedManufacturingOrders
  ] = React.useState([]);

  const [name, setName] = React.useState('');
  const [preformsCount, setPreformsCount] = React.useState('');
  const [finalPartsCount, setFinalPartsCount] = React.useState('');
  const [dates, setDates] = React.useState([null, null]);
  const [countItemsType, setCountItemsType] = React.useState(
    ITEMS_TYPES.PREFORMS
  );
  const [formValid, setFormValid] = React.useState(false);

  React.useEffect(() => {
    setSteps([
      {
        key: STEP_KEYS.SELECT_MANUFACTURING_ORDER,
        label: 'Select Manufacturing Order from Odoo'
      },
      {
        key: STEP_KEYS.REVIEW_MANUFACTURING_ORDER,
        label: 'Manufacturing Order Data'
      }
    ]);
  }, []);

  const loadData = React.useCallback(
    async ({ page, pageSize, search }, config) => {
      const params = {
        page: page,
        pageSize: pageSize
      };

      if (search) {
        params.search = search;
      }

      if (odooProductId) {
        params.odooProductId = odooProductId;
      }

      const { data, pagination } = await OdooService.getOdooManufacturingOrders(
        params,
        config
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount
      };
    },
    []
  );

  const titanDataGridProps = useTitanDataGrid(loadData);

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Odoo manufacturing order',
        field: 'name',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <Link href={row.odooManufacturingOrderPageUrl} target="_blank">
            {row.name}
          </Link>
        )
      }
    ],
    []
  );

  React.useEffect(() => {
    if (selectedManufacturingOrders[0]) {
      const { name, productQuantity } = selectedManufacturingOrders[0];

      setName(name);
      setPreformsCount(productQuantity);
      setFinalPartsCount(productQuantity);
    }
  }, [selectedManufacturingOrders]);

  const onSaveHandler = React.useCallback(async () => {
    const {
      id,
      productId,
      odooManufacturingOrderPageUrl
    } = selectedManufacturingOrders[0];

    return onSave({
      name,
      ...(countItemsType === ITEMS_TYPES.PREFORMS && { preformsCount }),
      ...(countItemsType === ITEMS_TYPES.FINAL_PARTS && { finalPartsCount }),
      ...(dates[0] && { startDate: dates[0] }),
      ...(dates[1] && { finishDate: dates[1] }),
      id,
      productId,
      odooManufacturingOrderPageUrl,
      fpManufacturingOrderId: null
    });
  }, [
    name,
    dates,
    preformsCount,
    finalPartsCount,
    countItemsType,
    selectedManufacturingOrders,
    onSave
  ]);

  return (
    <TitanDialog
      maxWidth="lg"
      title="Import Manufacturing Order from Odoo"
      onClose={onClose}
      onSave={
        activeStep === steps.length - 1 && formValid ? onSaveHandler : null
      }
      saveButtonLabel="Import"
      isValid={selectedManufacturingOrders.length > 0}
      isNextValid={selectedManufacturingOrders.length > 0}
      onPrev={activeStep > 0 ? () => setActiveStep(activeStep - 1) : null}
      onNext={
        activeStep < steps.length - 1
          ? () => setActiveStep(activeStep + 1)
          : null
      }
    >
      <OdooImportMOContext.Provider
        value={{
          selectedManufacturingOrder: selectedManufacturingOrders,
          setSelectedManufacturingOrder: setSelectedManufacturingOrders
        }}
      >
        <>
          <Stepper sx={{ mb: 2 }} activeStep={activeStep} alternativeLabel>
            {steps.map(step => (
              <Step key={step.key} completed={step.completed}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === 0 && (
            <TitanDataGrid
              searchPlaceholder="Search by Odoo Manufacturing Order name..."
              {...titanDataGridProps}
              columns={columns}
              onClose={onClose}
              onSelectionModelChange={selectedManufacturingOrders =>
                setSelectedManufacturingOrders(selectedManufacturingOrders)
              }
              selectedRows={selectedManufacturingOrders}
              checkboxSelection
              checkboxMultiSelection={false}
              isRowSelectable={({ row }) => !row.fpManufacturingOrderId}
            />
          )}

          {activeStep === 1 && (
            <ReviewManufacturingOrderDialog
              name={name}
              setName={setName}
              preformsCount={preformsCount}
              setPreformsCount={setPreformsCount}
              finalPartsCount={finalPartsCount}
              setFinalPartsCount={setFinalPartsCount}
              dates={dates}
              setDates={setDates}
              countItemsType={countItemsType}
              setCountItemsType={setCountItemsType}
              setFormValid={setFormValid}
            />
          )}
        </>
      </OdooImportMOContext.Provider>
    </TitanDialog>
  );
}
