import React from 'react';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';

export default function TitanPopover({
  children,
  icon = MoreVertIcon,
  fillIcon = '',
  isImageIcon = false,
  className
}) {
  const IconElement = !isImageIcon ? icon : MoreVertIcon;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = e => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = e => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton component="span" onClick={handleClick} className={className}>
        {!isImageIcon ? (
          <IconElement style={{ fill: fillIcon }} color="primary" />
        ) : (
          <img src={icon} alt="icon" />
        )}
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box p={1}>{children}</Box>
      </Popover>
    </>
  );
}
