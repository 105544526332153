import React from 'react';

import ManufacturingOrderService from '../../services/ManufacturingOrderService';
import { format } from 'date-fns';
import { SORT_ORDERS } from '../../constants';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';

export default function DialogManufacturingOrdersStep({
  buildPlanId,
  fusionPlanId,
  selectedManufacturingOrder,
  setSelectedManufacturingOrder
}) {
  const loadManufacturingOrders = React.useCallback(async (params, config) => {
    const {
      data,
      pagination
    } = await ManufacturingOrderService.getManufacturingOrders(
      {
        ...params,
        ...(buildPlanId ? { buildPlanIds: [buildPlanId] } : {}),
        ...(fusionPlanId ? { fusionPlanIds: [fusionPlanId] } : {})
      },
      config
    );

    if (data.length !== 0) {
      const firstActiveMO = data.find(
        mo =>
          new Date(mo.startDate).getTime() <= Date.now() &&
          new Date(mo.finishDate).getTime() >= Date.now()
      );

      if (firstActiveMO) {
        setSelectedManufacturingOrder(prev =>
          !prev && params.page === 1 ? firstActiveMO : prev
        );
      }
    }

    return {
      data,
      page: pagination.page - 1,
      totalCount: pagination.totalCount
    };
  }, []);

  const manufacturingOrderColumns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        flex: 1,
        sortable: false
      },
      {
        headerName: 'Order Key',
        field: 'orderKey',
        minWidth: 100,
        sortable: false,
        visibilityBreakpoint: 'sm'
      },
      {
        headerName: 'Customer Name',
        field: 'customerName',
        minWidth: 200,
        visibilityBreakpoint: 'lg',
        sortable: false
      },
      {
        headerName: 'Final Parts',
        field: 'finalPartsCount',
        minWidth: 120,
        visibilityBreakpoint: 'lg',
        sortable: false
      },
      {
        headerName: 'Start Date',
        field: 'startDate',
        minWidth: 150,
        sortable: false,
        visibilityBreakpoint: 'md',
        renderCell: ({ row }) =>
          row.startDate ? format(new Date(row.startDate), 'yyyy-MM-dd') : null
      },
      {
        headerName: 'Finish Date',
        field: 'finishDate',
        minWidth: 150,
        sortable: false,
        visibilityBreakpoint: 'md',
        renderCell: ({ row }) =>
          row.finishDate ? format(new Date(row.finishDate), 'yyyy-MM-dd') : null
      }
    ],
    []
  );

  const gridOptionsObject = {
    orders: {
      updated_at: SORT_ORDERS.DESC
    },
    columns: manufacturingOrderColumns,
    pinnedColumns: {
      left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'name'],
      right: ['actions']
    }
  };

  const manufacturingOrdersDataGridProps = useTitanDataGrid(
    loadManufacturingOrders,
    gridOptionsObject
  );

  const onSelectManufacturingOrder = React.useCallback(
    selectedManufactureOrders => {
      setSelectedManufacturingOrder(
        selectedManufactureOrders.length !== 0
          ? selectedManufactureOrders[0]
          : null
      );
    },
    [setSelectedManufacturingOrder]
  );

  const selectedManufacturingOrders = React.useMemo(
    () => (selectedManufacturingOrder ? [selectedManufacturingOrder] : []),
    [selectedManufacturingOrder]
  );

  return (
    <TitanDataGrid
      {...manufacturingOrdersDataGridProps}
      searchPlaceholder={'Search by Manufacturing Order name...'}
      checkboxSelection
      selectedRows={selectedManufacturingOrders}
      onSelectionModelChange={onSelectManufacturingOrder}
    />
  );
}
