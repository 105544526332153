import React from 'react';
import FilterChips from '../FilterChips/FilterChips';
import { ManufacturingOrderStateLabels } from './ManufacturingOrderState';

export default function ManufacturingOrdersTableChips({
  selectedProjects,
  setSelectedProjects,
  selectedStates,
  setSelectedStates,
  manufacturingOrderType,
  setManufacturingOrderType,
  manufacturingOrderProgress,
  setManufacturingOrderProgress,
  filterAssignedMOs,
  setFilterAssignedMOs,
}) {
  const items = React.useMemo(() => {
    const items = [
      { separatorLabel: 'Type' },
      {
        label: 'All',
        onClick: () => setManufacturingOrderType('all'),
        isHighlighted: manufacturingOrderType === 'all',
      },
      {
        label: 'Preform',
        onClick: () => setManufacturingOrderType('preform'),
        isHighlighted: manufacturingOrderType === 'preform',
      },
      {
        label: 'Final part',
        onClick: () => setManufacturingOrderType('finalPart'),
        isHighlighted: manufacturingOrderType === 'finalPart',
      },
      { separatorLabel: 'States' },
    ];

    Object.entries(ManufacturingOrderStateLabels).forEach(([key, label]) => {
      items.push({
        label,
        onClick: () =>
          setSelectedStates((prev) => ({
            ...prev,
            [key]: !prev[key],
          })),
        isHighlighted: selectedStates[key],
      });
    });

    items.push({ separatorLabel: 'Progress' });
    items.push({
      label: 'All',
      onClick: () => setManufacturingOrderProgress('all'),
      isHighlighted: manufacturingOrderProgress === 'all',
    });
    items.push({
      label: 'Progress < 100%',
      onClick: () => setManufacturingOrderProgress('inProgress'),
      isHighlighted: manufacturingOrderProgress === 'inProgress',
    });
    items.push({
      label: 'Progress >= 100%',
      onClick: () => setManufacturingOrderProgress('completed'),
      isHighlighted: manufacturingOrderProgress === 'completed',
    });

    items.push({ separatorLabel: 'Filter' });
    items.push({
      label: 'Assigned to me',
      onClick: () => setFilterAssignedMOs((prev) => !prev),
      isHighlighted: filterAssignedMOs,
    });

    if (selectedProjects.length !== 0) {
      items.push({ separatorLabel: 'Projects' });

      selectedProjects.forEach((project) => {
        items.push({
          label: `${project.name}`,
          onDelete: () =>
            setSelectedProjects((prev) =>
              prev.filter((p) => p.id !== project.id),
            ),
          isHighlighted: true,
        });
      });
    }

    return items;
  }, [
    selectedProjects,
    selectedStates,
    manufacturingOrderType,
    manufacturingOrderProgress,
    filterAssignedMOs,
  ]);

  return <FilterChips items={items} />;
}
