import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select
} from '@mui/material';
import React from 'react';

export const SingleSelectCell = ({
  row,
  customFieldData,
  customField,
  componentTypes,
  isEditable,
  prepareComponentToUpdate,
  updateComponents,
  updateCustomField
}) => {
  let variant = customFieldData?.value ? customFieldData?.value : '';

  const options =
    customFieldData?.fusionPlanCustomField?.options ||
    customFieldData?.preformTypeCustomField?.options ||
    componentTypes.customFields.find(cf => cf.name === customField.name)
      ?.options;

  return (
    <FormControl sx={{ m: 0.5, width: 350 }} size="small">
      <InputLabel id="single-checkbox-label">Variants</InputLabel>
      <Select
        labelId="single-checkbox-label"
        id="demo-single-checkbox"
        disabled={!isEditable(row)}
        value={variant}
        onChange={e => {
          let newValue = e.target.value;

          const componentToUpdate = prepareComponentToUpdate(
            'SINGLE_SELECT',
            row,
            customField.name,
            newValue
          );

          if (customFieldData) {
            updateCustomField(row, {
              ...customFieldData,
              value: newValue
            });
            updateComponents([componentToUpdate]);
          }
        }}
        input={<OutlinedInput label="Variants" />}
      >
        {options &&
          options.map(option => (
            <MenuItem key={option} value={option}>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
