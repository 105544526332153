import axios from 'axios';

export default class MouldService {
  static async getMoulds(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/moulds`, {
        ...config,
        params
      })
      .then(res => res.data);
  }

  static async mouldIdentifierExists(params) {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/moulds/mould-identifier-exists`,
        {
          params
        }
      )
      .then(res => res.data);
  }

  static async createMould(data) {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/moulds`, data)
      .then(res => res.data.data);
  }

  static async updateMould(mouldId, data) {
    return axios
      .patch(`${process.env.REACT_APP_API_URL}/api/v1/moulds/${mouldId}`, data)
      .then(res => res.data.data);
  }

  static async deleteMould(mouldId) {
    return axios
      .delete(`${process.env.REACT_APP_API_URL}/api/v1/moulds/${mouldId}`)
      .then(res => res.data);
  }
}
