import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { useTitan } from './Titan';
import CloseIcon from '../../assets/icons/cross-dark.svg';
import { makeStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  form: {
    height: '100%',
  },
  wrapperMargin: {
    marginLeft: '8px',
  },
}));

export default function TitanDialog(props) {
  const {
    title,
    onSave,
    onPrev,
    onNext,
    onClose,
    onAdditionalButton,
    children,
    isValid = true,
    isPrevValid = true,
    isNextValid = true,
    nextButtonTooltip = '',
    closeOnSuccessSave = true,
    maxWidth,
    fullWidth = true,
    successSaveMessage,
    cancelButtonLabel = 'Cancel',
    saveButtonLabel = 'Save',
    prevButtonLabel = 'Previous',
    nextButtonLabel = 'Next',
    additionalButtonLabel = '',
    fullScreen = false,
    showTitle = true,
    showActions = true,
    resetZIndex = false,
  } = props;
  const { pushSnackbar, pushCustomSnackbar } = useTitan();
  const classes = useStyles();

  const [saveLoading, setSaveLoading] = React.useState(false);

  const handleSaveClick = (e) => {
    e.preventDefault();
    setSaveLoading(true);

    onSave()
      .then(() => {
        setSaveLoading(false);

        if (successSaveMessage) {
          pushCustomSnackbar(successSaveMessage);
        }

        if (closeOnSuccessSave) {
          onClose();
        }
      })
      .catch((error) => {
        const findString = (message) => {
          if (error.message.includes(message)) {
            return error.message;
          }
        };

        if (error && error.message) {
          switch (error.message) {
            case findString(`.units" is not allowed to be empty`):
              pushSnackbar(`Options field shouldn’t be empty`, {
                variant: 'error',
              });
              break;
            default:
              pushSnackbar(error.message, { variant: 'error' });
              break;
          }
        }

        setSaveLoading(false);
      });
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      scroll="paper"
      slotProps={
        resetZIndex
          ? {
              root: {
                sx: { zIndex: 'auto' },
              },
            }
          : {}
      }
    >
      {showTitle && (
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>{title}</Grid>
            <Grid item>
              <IconButton onClick={onClose}>
                <img src={CloseIcon} alt="Close icon" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
      )}
      <DialogContent dividers>
        <form className={classes.form} onSubmit={handleSaveClick}>
          {children}
        </form>
      </DialogContent>
      {showActions && (
        <DialogActions sx={{ pl: 3, pr: 3 }}>
          {onAdditionalButton && (
            <Button
              variant="outlined"
              color="primary"
              onClick={onAdditionalButton}
            >
              {additionalButtonLabel}
            </Button>
          )}
          {onClose && (
            <Button variant="outlined" color="secondary" onClick={onClose}>
              {cancelButtonLabel}
            </Button>
          )}
          {onPrev && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={onPrev}
              disabled={!isPrevValid}
            >
              {prevButtonLabel}
            </Button>
          )}
          {onNext && (
            <Tooltip
              title={nextButtonTooltip}
              placement="top-start"
              disableHoverListener={!nextButtonTooltip}
              className={classes.wrapperMargin}
            >
              <span>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={onNext}
                  disabled={!isNextValid}
                >
                  {nextButtonLabel}
                </Button>
              </span>
            </Tooltip>
          )}
          {onSave && (
            <Button
              variant="outlined"
              type="submit"
              color="primary"
              disabled={saveLoading || !isValid}
              onClick={handleSaveClick}
            >
              {saveButtonLabel}
              {saveLoading && <CircularProgress sx={{ ml: 1 }} size={24} />}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}
