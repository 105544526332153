import React from 'react';
import FilterChips from '../FilterChips/FilterChips';

export default function PreformsChips({
  buildPlanVersionType,
  setBuildPlanVersionType,
  fusionPlanVersionType,
  setFusionPlanVersionType
}) {
  const items = React.useMemo(() => {
    const items = [];

    if (buildPlanVersionType) {
      items.push({
        label: 'Current Version',
        onClick: () => setBuildPlanVersionType('current'),
        isHighlighted: buildPlanVersionType === 'current'
      });

      items.push({
        label: 'All Versions',
        onClick: () => setBuildPlanVersionType('all'),
        isHighlighted: buildPlanVersionType === 'all'
      });
    }

    if (fusionPlanVersionType) {
      items.push({
        label: 'Current Version',
        onClick: () => setFusionPlanVersionType('current'),
        isHighlighted: fusionPlanVersionType === 'current'
      });

      items.push({
        label: 'All Versions',
        onClick: () => setFusionPlanVersionType('all'),
        isHighlighted: fusionPlanVersionType === 'all'
      });
    }

    return items;
  }, [buildPlanVersionType, fusionPlanVersionType]);

  return <FilterChips items={items} />;
}
