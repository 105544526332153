import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import { useResponsiveQueries } from '../../hooks/useResponsiveQueries';
import { setColumnsVisibilityModel } from '../../utils/setColumnsVisibilityModel';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { CustomGridColumnsPanel } from '../TitanDataGrid/TitanDataGridColumnsPanel';
import CustomFieldsDialog from './CustomFieldsDialog';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import Typography from '@mui/material/Typography';

const Image = styled('img')({
  width: '100%',
});

export default function CustomFields({
  customFields,
  onAdd,
  onChange,
  onDelete,
  disableEdit,
  disableEditTooltip,
  disableDelete,
  disableDeleteTooltip,
  title = 'Custom fields',
  openDialog,
  setOpenDialog,
  customTypes,
  rowReordering,
  onRowOrderChange,
}) {
  const updateCustomField = (id, data, save = true) => {
    const customField = customFields.find(
      (customField) => customField.id === id,
    );

    if (!customField) {
      return;
    }

    onChange({ ...customField, ...data }, save);
  };
  const [editingField, setEditingField] = React.useState(null);

  const { matchesSM, matchesMD, matchesLG } = useResponsiveQueries();

  const [pinnedColumns] = React.useState({
    right: ['actions'],
  });

  const getCustomFieldsActions = React.useCallback(
    (onDelete, onEdit, disableEdit) => {
      return [
        {
          variant: 'outlined',
          color: 'secondary',
          label: 'Edit field',
          icon: <EditIcon />,
          onClick: onEdit,
        },
        {
          variant: 'outlined',
          color: 'secondary',
          label: 'Delete field',
          tooltipText: disableDeleteTooltip,
          icon: <DeleteIcon />,
          onClick: onDelete,
          disabled: disableDelete,
        },
      ];
    },
    [disableEdit, disableEditTooltip],
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        visibilityBreakpoint: 'sm',
        hideable: false,
        flex: 1,
        minWidth: 200,
        sortable: false,
      },
      {
        headerName: 'Type',
        field: 'type',
        visibilityBreakpoint: 'lg',
        minWidth: 150,
        sortable: false,
      },
      {
        headerName: 'Unit/Options',
        field: 'units',
        visibilityBreakpoint: 'sm',
        minWidth: 400,
        sortable: false,
        editable: false,
        renderCell: ({ row }) =>
          row.type === 'NUMBER' ? (
            <Typography>{row.units}</Typography>
          ) : row.type.endsWith('_SELECT') ? (
            <Autocomplete
              multiple
              options={[]}
              freeSolo
              fullWidth
              disabled
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField variant="standard" margin="normal" {...params} />
              )}
              value={row.options ? row.options : []}
            />
          ) : (
            ''
          ),
      },
      {
        headerName: 'Expected Value',
        field: 'expectedValue',
        visibilityBreakpoint: 'lg',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) =>
          `${
            row.type === 'BOOLEAN'
              ? row.expectedValue === 'true'
                ? 'Yes'
                : 'No'
              : row.expectedValue
          }${
            row.type === 'NUMBER'
              ? ` (min: ${row.minValue || '-'} max: ${row.maxValue || '-'})`
              : ''
          }`,
      },
      {
        headerName: 'Image',
        field: 'image',
        visibilityBreakpoint: 'lg',
        width: 100,
        sortable: false,
        renderCell: ({ row }) =>
          row.image ? <Image src={row.image.url} alt="" /> : '',
      },
      {
        headerName: '',
        type: 'actions',
        field: 'actions',
        hideable: false,
        visibilityBreakpoint: 'sm',
        hideInMenu: true,
        width: 60,
        sortable: false,
        getActions: (params) =>
          getCustomFieldsActions(
            () => onDelete(params.row),
            () => {
              setEditingField(params.row);
              setOpenDialog(true);
            },
            disableEdit,
          ).map((action) =>
            disableEdit ? (
              <Tooltip
                title={action.tooltipText}
                showInMenu
                placement="left-start"
              >
                <span>
                  <GridActionsCellItem
                    icon={action.icon}
                    label={action.label}
                    onClick={action.onClick}
                    showInMenu
                    disabled={action.disabled}
                  />
                </span>
              </Tooltip>
            ) : (
              <GridActionsCellItem
                icon={action.icon}
                label={action.label}
                showInMenu
                onClick={action.onClick}
              />
            ),
          ),
      },
    ],
    [
      customFields,
      disableEdit,
      getCustomFieldsActions,
      onDelete,
      setOpenDialog,
    ],
  );
  const prepareColumnVisibilityModel = (columns) => {
    const breakpointMatches = {
      sm: matchesSM,
      md: matchesMD,
      lg: matchesLG,
    };

    return columns.reduce(
      (obj, column) => ({
        ...obj,
        [column.field]:
          column.hide !== true
            ? column.visibilityBreakpoint
              ? breakpointMatches[column.visibilityBreakpoint]
              : true
            : false,
      }),
      {},
    );
  };

  const [visibilityModel, setVisibilityModel] = React.useState(() => ({
    ...setColumnsVisibilityModel(columns, 'iPadVisible', matchesLG),
  }));

  const apiRef = useGridApiRef();

  React.useEffect(() => {
    let maxVisibleColumnsWithoutScroll = matchesMD ? 5 : 4;
    if (
      Object.values(visibilityModel).filter((columnValue) => columnValue)
        .length >= maxVisibleColumnsWithoutScroll
    ) {
      apiRef.current.setPinnedColumns(pinnedColumns);
    } else {
      apiRef.current.setPinnedColumns({});
    }
  }, [visibilityModel, matchesSM, matchesMD, matchesLG]);

  React.useEffect(() => {
    apiRef.current.setColumnVisibilityModel(
      prepareColumnVisibilityModel(columns),
    );
  }, [columns, matchesSM, matchesMD, matchesLG]);

  const getRowHeight = React.useCallback(({ model: { options } }) => {
    if (options && options.length > 3) {
      return (parseInt(options.length / 3, 10) + 1) * 52;
    }

    return null;
  }, []);

  return (
    <Box
      sx={{
        '& .custom-field-name-cell.has-error': {
          borderBottom: '2px solid',
          borderBottomColor: 'error.main',
        },
      }}
    >
      <TitanDataGrid
        apiRef={apiRef}
        onCellEditCommit={(params) =>
          updateCustomField(params.id, { [params.field]: params.value })
        }
        components={{
          Toolbar: TitanDataGridToolbar,
          ColumnsPanel: CustomGridColumnsPanel,
        }}
        onCreateClick={() => {
          setOpenDialog(true);
        }}
        isCellEditable={({ field, row }) => {
          return !(row.type === 'BOOLEAN' && field === 'expectedValue');
        }}
        createButtonLabel="Custom Field"
        rows={customFields}
        rowCount={customFields.length}
        columns={columns}
        initialState={{
          columns: {
            columnVisibilityModel: {
              ...setColumnsVisibilityModel(columns, 'iPadVisible', matchesLG),
            },
          },
          pinnedColumns:
            Object.values(
              setColumnsVisibilityModel(columns, 'iPadVisible', matchesLG),
            ).filter((cV) => cV).length >= 5
              ? pinnedColumns
              : {},
        }}
        title={title}
        pagination={false}
        showSearch={false}
        getRowHeight={getRowHeight}
        setVisibilityModel={setVisibilityModel}
        rowReordering={rowReordering}
        onRowOrderChange={onRowOrderChange}
      />
      {openDialog && (
        <CustomFieldsDialog
          onAddCustomField={onAdd}
          onClose={() => {
            setOpenDialog(false);
            setEditingField(null);
          }}
          customField={editingField}
          customFields={customFields}
          onChangeCustomField={onChange}
          customTypes={customTypes}
        />
      )}
    </Box>
  );
}
