import moment from 'moment';
import Typography from '@mui/material/Typography';
import React from 'react';

export default function TitanDuration({
  duration,
  format = 'HH:mm:ss',
  emptyValue = '-',
}) {
  if (!duration) {
    return emptyValue;
  }

  return (
    <Typography>
      {moment.duration(duration, 'seconds').format(format, { trim: false })}
    </Typography>
  );
}
