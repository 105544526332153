import { Link as RouterLink, useLocation } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import { Link } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import OverflowTip from '../ExpandRenderCell/OverflowTip';

const useStyles = makeStyles(theme => ({
  hiddenOverflowBlock: {
    overflow: 'hidden'
  }
}));

export default function MaterialLink({
  materialType,
  width,
  onClick = e => e.stopPropagation()
}) {
  const location = useLocation();
  const classes = useStyles();

  return (
    <Link
      className={classes.hiddenOverflowBlock}
      onClick={onClick}
      to={{
        pathname: ROUTES.GEAR_TAB('materials'),
        state: { from: location.pathname }
      }}
      component={RouterLink}
    >
      {width ? (
        <OverflowTip width={width}>{materialType.name}</OverflowTip>
      ) : (
        <span>{materialType.name}</span>
      )}
    </Link>
  );
}
