import ComponentStatus from '../Component/ComponentStatus';
import React from 'react';
import useFusionJobStatus from './use-fusion-job-status';

export default function FusionJobStatus({
  fusorId,
  fusionJobId,
  initialStatus,
  initialProgress
}) {
  const { status, progress } = useFusionJobStatus(
    fusorId,
    fusionJobId,
    initialStatus,
    initialProgress
  );

  return <ComponentStatus component={{ status, progress }} />;
}
