import TextField from '@mui/material/TextField';
import { Editor } from 'react-draft-wysiwyg';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import TermsAndConditionsService from '../../services/TermsAndConditionsService';
import { useTitan } from '../Titan/Titan';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Paper } from '@mui/material';
import { ROUTES } from '../../constants';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100%',
    width: 860,
    padding: theme.spacing(2),
    margin: '0 auto',
  },
  submit: {
    marginTop: theme.spacing(2),
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  version: {
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
}));

export default function TermsForm(props) {
  const { entityMap = {}, termsAndConditions = {}, mode } = props;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { pushSnackbar } = useTitan();

  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty(),
  );
  const [version, setVersion] = React.useState('');
  const [isPublished, setIsPublished] = React.useState(false);
  const [versionError, setVersionError] = React.useState('');

  const checkIfValid = () => {
    return !(
      version &&
      !versionError &&
      editorState.getCurrentContent().hasText()
    );
  };

  const setTermsAndConditions = () => {
    setIsPublished(termsAndConditions.isPublished);
    setVersion(termsAndConditions.version);
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          htmlToDraft(termsAndConditions.content),
          entityMap,
        ),
      ),
    );
  };

  const handleVersionChange = (e) => {
    const value = e.target.value;

    // Allow one decimal point values (1.1, 1.2, ...)
    if (/^\d+(\.\d+)?$/.test(value)) {
      setVersionError('');
    } else {
      setVersionError('Please enter a valid version');
    }

    setVersion(value);
  };

  const toggleChanges = async (publish) => {
    const data = {
      version,
      content: draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
        {},
        false,
        ({ type, data }) => {
          if (type === 'IMAGE') {
            const alignment = data.alignment || 'none';
            const textAlign = alignment === 'none' ? 'center' : alignment;

            return `
                    <img src="${data.src}" alt="${data.alt}" style="float: ${textAlign};height: ${data.height};width: ${data.width}"/>
            `;
          }
        },
      ),
      isPublished: isPublished || publish,
    };

    mode === 'Edit'
      ? await TermsAndConditionsService.update(termsAndConditions.id, data)
      : await TermsAndConditionsService.create(data);

    pushSnackbar(
      `Terms And Conditions ${
        mode === 'Edit' ? 'saved' : 'created'
      } successfully`,
      {
        variant: 'success',
      },
    );

    history.push({
      pathname: ROUTES.ADMIN_DASHBOARD_TAB('terms-and-conditions'),
      state: { from: location.pathname },
    });
  };

  React.useEffect(() => {
    if (mode === 'Edit') {
      setTermsAndConditions();
    }
  }, []);

  return (
    <Paper className={classes.content}>
      <div className={classes.version}>
        <TextField
          autoFocus
          id="version"
          label="Version"
          type="text"
          value={version}
          margin="normal"
          fullWidth
          error={!!versionError}
          helperText={versionError}
          onChange={handleVersionChange}
        />

        <Editor
          placeholder="Content"
          editorState={editorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          stripPastedStyles={false}
          handlePastedText={() => false}
          onEditorStateChange={(editorState) => setEditorState(editorState)}
        />
      </div>

      <Divider />

      <div className={classes.submit}>
        <Button
          variant="outlined"
          type="submit"
          color="primary"
          disabled={checkIfValid()}
          onClick={() => toggleChanges(false)}
        >
          {mode === 'Edit' ? 'Save' : 'Create'}
        </Button>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={checkIfValid()}
          onClick={() => toggleChanges(true)}
        >
          Publish
        </Button>
      </div>
    </Paper>
  );
}
