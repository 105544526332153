import ReactResizeDetector from 'react-resize-detector';
import React from 'react';
import { useBuildPlan } from './BuildPlanPage';
import { VIEW_PLAN_MODE } from '../../constants';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import { colors } from '../Theme/vars';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
import { useBuildPlanPreview } from './BuildPlanPreview';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  appBar: {
    background: colors.GREY5
  },
  viewerContainer: {
    // position: 'absolute',
    // top: props => props.containerTop,
    // left: props => props.containerLeft,
    // width: props => props.containerWidth,
    // height: props => props.containerHeight
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  },
  buildPlateImage: {
    maxHeight: '100%',
    maxWidth: '100%'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  backButton: {
    width: 44
  },
  title: {
    marginLeft: theme.spacing(4)
  },
  loading: {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    left: 'calc(50% - 20px)'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export default function BuildPlanPreviewDialog() {
  const { buildPlan, setOpenPreviewDialog } = useBuildPlan();
  const {
    viewPlanMode,
    setMinGCodeLayer,
    setMaxGCodeLayer,
    minGCodeLayerSelected,
    setMinGCodeLayerSelected,
    maxGCodeLayerSelected,
    setMaxGCodeLayerSelected,
    gcodeTravelShown
  } = useBuildPlanPreview();

  const [hasFiberSTLFiles, setHasFiberSTLFiles] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [buildPlanImage, setBuildPlanImage] = React.useState();

  React.useEffect(() => {
    if (buildPlan && buildPlan.files && buildPlan.files.length) {
      if (viewPlanMode === VIEW_PLAN_MODE.STL) {
        const stlFiles = buildPlan.files.filter(file =>
          file.location.endsWith('.stl')
        );

        setHasFiberSTLFiles(
          stlFiles.find(
            f =>
              f.location.endsWith('.fiber.stl') ||
              f.location.endsWith('.fibre.stl')
          )
        );

        stlFiles.sort(f1 => (f1.location.endsWith('.plastic.stl') ? 1 : -1));

        setFiles(stlFiles.map(file => file.url));

        const buildPlateImage = buildPlan.files.find(file =>
          file.location.endsWith('build-plate.png')
        );

        if (buildPlateImage) {
          setBuildPlanImage(buildPlateImage.url);
        }
      } else if (viewPlanMode === VIEW_PLAN_MODE.GCODE) {
        const files = buildPlan.files
          .filter(file => file.location.endsWith('.gcode'))
          .map(file => `${file.url}`);

        if (files.length !== 0) {
          Promise.all(
            files.map(file =>
              axios
                .get(file, {
                  responseType: 'arraybuffer'
                })
                .then(
                  res =>
                    `data:application/x-www-form-urlencoded;base64,${btoa(
                      new Uint8Array(res.data).reduce(
                        (data, byte) => data + String.fromCharCode(byte),
                        ''
                      )
                    )}`
                )
            )
          ).then(files => setFiles(files));
        } else {
          setFiles([]);
        }
      }
    }
  }, [viewPlanMode, buildPlan]);

  const onBeforeLoadSTL = () => {
    setLoading(true);
  };
  const onAfterLoadSTL = () => {
    setLoading(false);
  };

  const selectedRotationX = 0;
  const selectedRotationY = 0;
  const selectedRotationZ = 0;
  const printerBedShape = '0x0,350x0,350x270,0x270';

  const setMinMaxLayersGCode = (first, second) => {
    const min = second ? first : 0;
    const max = second ? second : first;

    setMinGCodeLayer(min);
    setMaxGCodeLayer(max);
    setMinGCodeLayerSelected(min);
    setMaxGCodeLayerSelected(max);
  };

  const sizeWidth = window.innerWidth;
  const sizeHeight = window.innerHeight;
  const squareSide = Math.max(sizeWidth, sizeHeight);
  const containerTop = (sizeHeight - squareSide) / 2;
  const containerLeft = (sizeWidth - squareSide) / 2;

  const classes = useStyles({
    containerWidth: squareSide,
    containerHeight: squareSide,
    containerTop,
    containerLeft
  });

  const onCloseClick = () => setOpenPreviewDialog(false);

  return (
    <Dialog fullScreen open={true}>
      <AppBar color="inherit" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.titleContainer}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={onCloseClick}
              aria-label="close"
              className={classes.backButton}
            >
              <img src={ArrowLeftIcon} alt="Back Button" title="Back" />
            </IconButton>
            <Typography variant="body2" className={classes.title}>
              {buildPlan.name}
            </Typography>
          </div>
          {/*<BuildPlanPreviewControls />*/}
          <IconButton
            edge="end"
            color="inherit"
            onClick={onCloseClick}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.viewerContainer}>
        {loading && <CircularProgress className={classes.loading} />}
        {buildPlanImage ? (
          <img
            src={buildPlanImage}
            alt=""
            className={classes.buildPlateImage}
          />
        ) : (
          ''
        )}
        {/*<ReactResizeDetector handleWidth handleHeight>*/}
        {/*  <STLViewer*/}
        {/*    className="stl-viewer"*/}
        {/*    onBeforeLoad={onBeforeLoadSTL}*/}
        {/*    setMinMaxLayers={setMinMaxLayersGCode}*/}
        {/*    onAfterLoad={onAfterLoadSTL}*/}
        {/*    urls={files}*/}
        {/*    fileType={viewPlanMode.toLowerCase()}*/}
        {/*    selectedRotationX={selectedRotationX}*/}
        {/*    selectedRotationY={selectedRotationY}*/}
        {/*    selectedRotationZ={selectedRotationZ}*/}
        {/*    cameraX={100}*/}
        {/*    cameraY={100}*/}
        {/*    cameraZ={100}*/}
        {/*    width={sizeWidth}*/}
        {/*    height={sizeHeight}*/}
        {/*    bedDimensions={printerBedShape}*/}
        {/*    backgroundColor={colors.GREY5}*/}
        {/*    stlColors={*/}
        {/*      hasFiberSTLFiles*/}
        {/*        ? [colors.DARK_GREY, colors.DARK_RED]*/}
        {/*        : [colors.DARK_RED, colors.DARK_RED]*/}
        {/*    }*/}
        {/*    gcodeColors={[colors.DARK_GREY, colors.RED2, colors.YELLOW]}*/}
        {/*    gcodeTravelShown={gcodeTravelShown || false}*/}
        {/*    minLayer={minGCodeLayerSelected || null}*/}
        {/*    maxLayer={maxGCodeLayerSelected || null}*/}
        {/*    rotate={false}*/}
        {/*    orbitControls={true}*/}
        {/*  />*/}
        {/*</ReactResizeDetector>*/}
      </div>
    </Dialog>
  );
}
