import React from 'react';
import { format } from 'date-fns';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { SORT_ORDERS } from '../../constants';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import BuildModuleService from '../../services/BuildModuleService';
import FibrifySpoolsSelectionDialog from '../Fibrify/FibrifySpoolsSelectionDialog';
import SpoolLink from '../Spools/SpoolLink';

export default function BuildModuleSpoolTable({ buildModuleId }) {
  const [openSelectSpoolDialog, setOpenSelectSpoolDialog] = React.useState(
    false
  );
  const [unloadBuildModuleSpool, setUnloadBuildModuleSpool] = React.useState(
    null
  );

  const loadData = React.useCallback(
    async (params, config) => {
      const {
        data,
        pagination
      } = await BuildModuleService.getBuildModuleSpools(
        buildModuleId,
        params,
        config
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount
      };
    },
    [buildModuleId]
  );

  const titanDataGridProps = useTitanDataGrid(loadData, {
    orders: {
      load_time: SORT_ORDERS.DESC
    }
  });

  const { reloadData } = titanDataGridProps;

  const loadSpool = React.useCallback(
    async spoolId => {
      await BuildModuleService.loadSpoolToBuildModule(buildModuleId, spoolId);
      reloadData();
    },
    [buildModuleId]
  );

  const unloadSpool = React.useCallback(
    async buildModuleSpool => {
      await BuildModuleService.unloadSpoolFromBuildModule(
        buildModuleId,
        buildModuleSpool.spoolId
      );
      reloadData();
    },
    [buildModuleId]
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: 'ID',
        field: 'spoolIdentifier',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <SpoolLink spool={row.spool} />
      },
      {
        headerName: 'Type',
        field: 'materialType',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => row.spool.materialType
      },
      {
        headerName: 'Material',
        field: 'materialName',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => row.spool.materialName
      },
      {
        headerName: 'Load time',
        field: 'loadTime',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) =>
          format(new Date(row.loadTime), 'yyyy-MM-dd HH:mm:ss')
      },
      {
        headerName: 'Unload time',
        field: 'unloadTime',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) =>
          row.unloadTime
            ? format(new Date(row.unloadTime), 'yyyy-MM-dd HH:mm:ss')
            : '-'
      },
      {
        headerName: '',
        field: 'actions',
        type: 'actions',
        width: 60,
        sortable: false,
        getActions: params =>
          [
            ...(!params.row.unloadTime
              ? [
                  {
                    label: 'Unload',
                    icon: <FileUploadIcon />,
                    onClick: () => setUnloadBuildModuleSpool(params.row)
                  }
                ]
              : [])
          ].map(action => (
            <GridActionsCellItem
              icon={action.icon}
              label={action.label}
              onClick={action.onClick}
              disabled={action.disabled}
              showInMenu
            />
          ))
      }
    ],
    []
  );

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        showSearch={false}
        columns={columns}
        title=" "
        onCreateClick={() => {
          setOpenSelectSpoolDialog(true);
        }}
        createButtonLabel="Load spool"
      />

      {openSelectSpoolDialog ? (
        <FibrifySpoolsSelectionDialog
          title="Load spool to build module"
          onClose={() => {
            setOpenSelectSpoolDialog(false);
          }}
          onSave={spool => loadSpool(spool.id)}
          saveButtonLabel="Load to BM"
        />
      ) : (
        ''
      )}

      {unloadBuildModuleSpool ? (
        <TitanConfirmationDialog
          title="Unload spool from build module"
          message="Are you sure you want to unload spool from build module?"
          confirmLabel="Unload"
          onConfirm={() => unloadSpool(unloadBuildModuleSpool)}
          onClose={() => setUnloadBuildModuleSpool(null)}
        />
      ) : (
        ''
      )}
    </>
  );
}
