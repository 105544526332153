import axios from 'axios';

export default class BuildModuleService {
  static async updateBuildModule(buildModuleId, data) {
    const result = await axios.patch(
      `${process.env.REACT_APP_API_URL}/api/v1/printers/${buildModuleId}`,
      data
    );

    return result;
  }

  static async getBuildModulePreHeatingTemperatures(params = {}, config = {}) {
    const response = await axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/api/v2/build-module-pre-heating-temperatures`,
      { ...config, params }
    );

    return response.data;
  }

  static async createBuildModulePreHeatingTemperature(data) {
    const response = await axios.post(
      `${
        process.env.REACT_APP_API_URL
      }/api/v2/build-module-pre-heating-temperatures`,
      data
    );

    return response.data;
  }

  static async updateBuildModulePreHeatingTemperature(
    buildModulePreHeatingTemperatureId,
    data
  ) {
    const response = await axios.patch(
      `${
        process.env.REACT_APP_API_URL
      }/api/v2/build-module-pre-heating-temperatures/${buildModulePreHeatingTemperatureId}`,
      data
    );

    return response.data;
  }

  static async deleteBuildModulePreHeatingTemperature(
    buildModulePreHeatingTemperatureId
  ) {
    const response = await axios.delete(
      `${
        process.env.REACT_APP_API_URL
      }/api/v2/build-module-pre-heating-temperatures/${buildModulePreHeatingTemperatureId}`
    );

    return response.data;
  }

  static async checkBuildModulePreHeatingTemperatureNameExists(
    buildModuleId,
    name
  ) {
    const response = await axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/api/v2/build-module-pre-heating-temperatures/name-exists`,
      { params: { buildModuleId, name } }
    );

    return response.data;
  }

  static async getBuildModuleSpools(buildModuleId, params = {}, config = {}) {
    const response = await axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/api/v2/build-modules/${buildModuleId}/spools`,
      { ...config, params }
    );

    return response.data;
  }

  static loadSpoolToBuildModule(buildModuleId, spoolId, config = {}) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/build-modules/${buildModuleId}/spools`,
        { spoolId },
        config
      )
      .then(res => res.data);
  }

  static unloadSpoolFromBuildModule(
    buildModuleId,
    buildModuleSpoolId,
    config = {}
  ) {
    return axios
      .put(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/build-modules/${buildModuleId}/spools/${buildModuleSpoolId}`,
        {},
        config
      )
      .then(res => res.data);
  }
}
