import React from 'react';
import { COMPONENT_STATUSES } from '../../constants';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import { Button } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import Stack from '@mui/material/Stack';
import { useTitan } from '../Titan/Titan';

export default function PreformActions({
  preform,
  onBuilt,
  onMarkAsDefected,
  onPrintLabel,
}) {
  const {
    organization: { labelPrinterEnabled = false },
  } = useTitan();

  const [actions, setActions] = React.useState([]);
  const [preformToBuilt, setPreformToBuilt] = React.useState();
  const [preformDefected, setPreformDefected] = React.useState();
  const [preformToPrintLabel, setPreformToPrintLabel] = React.useState();

  React.useEffect(() => {
    const actions = [];

    if (
      [
        COMPONENT_STATUSES.POST_CANCELED,
        COMPONENT_STATUSES.BUILD_CANCELED,
        COMPONENT_STATUSES.DEFECTED,
      ].includes(preform.status)
    ) {
      actions.push({
        color: 'secondary',
        variant: 'outlined',
        label: 'Set as Built',
        onClick: () => setPreformToBuilt(preform),
      });
    }

    if (labelPrinterEnabled && onPrintLabel) {
      actions.push({
        color: 'primary',
        variant: 'outlined',
        label: 'Print label',
        icon: <PrintIcon />,
        onClick: () => setPreformToPrintLabel(preform),
      });
    }

    if (
      [
        COMPONENT_STATUSES.BUILT,
        COMPONENT_STATUSES.BUILDING,
        COMPONENT_STATUSES.BUILD_PAUSING,
        COMPONENT_STATUSES.BUILD_PAUSED,
        COMPONENT_STATUSES.FUSED,
        COMPONENT_STATUSES.FUSING,
        COMPONENT_STATUSES.FUSE_PAUSED,
      ].includes(preform.status)
    ) {
      actions.push({
        color: 'secondary',
        variant: 'outlined',
        label: 'Mark as scrap',
        onClick: () => setPreformDefected(preform),
      });
    }

    setActions(actions);
  }, [preform]);

  if (actions.length === 0) {
    return '';
  }

  return (
    <Stack direction="row" spacing={2}>
      {actions.length
        ? actions.map((action, index) => (
            <Button
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                action.onClick(e);
              }}
              startIcon={action.icon}
              variant={action.variant}
              color={action.color}
            >
              {action.label}
            </Button>
          ))
        : ''}

      {preformToBuilt && (
        <TitanConfirmationDialog
          title={`Set Preform ${preform.id} as successfully built`}
          message={`Are you sure you want to set preform ${preform.id} as successfully built?`}
          onConfirm={async () => {
            await onBuilt(preform);
          }}
          onClose={() => setPreformToBuilt(null)}
        />
      )}

      {preformDefected && (
        <TitanConfirmationDialog
          title={`Mark Preform ${preform.id} as scrap`}
          message={`Are you sure you want to mark preform ${preform.id} as scrap?`}
          onConfirm={async () => {
            await onMarkAsDefected(preform);
          }}
          onClose={() => setPreformDefected(null)}
        />
      )}

      {preformToPrintLabel && (
        <TitanConfirmationDialog
          title={`Print label for ${preform.id} preform`}
          message={`Are you sure you want to print label for ${preform.id} preform?`}
          onConfirm={async () => {
            await onPrintLabel(preform);
          }}
          onClose={() => setPreformToPrintLabel(null)}
        />
      )}
    </Stack>
  );
}
