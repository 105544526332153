import React from 'react';
import TitanDialog from '../Titan/TitanDialog';
import useAsyncEffect from 'use-async-effect';
import ProjectService from '../../services/ProjectService';
import TitanCircularProgress from '../Titan/TitanCircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

export default function SelectProjectDialog({ onSave, onClose, project }) {
  const [selectedProjectId, setSelectedProjectId] = React.useState(
    project ? project.id : null
  );
  const [projects, setProjects] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  useAsyncEffect(async () => {
    setLoading(true);
    const projects = await ProjectService.getProjects({
      page: 0,
      pageSize: 100,
      order: ['name:asc']
    });
    setProjects(projects.data);
    setLoading(false);
  }, []);

  return (
    <TitanDialog
      title={`Select Project`}
      maxWidth="xs"
      onClose={onClose}
      onSave={() => onSave(selectedProjectId)}
      saveButtonLabel="Select"
      isValid={selectedProjectId}
    >
      {loading ? (
        <TitanCircularProgress />
      ) : (
        <FormControl fullWidth>
          <InputLabel id="project-label">Project</InputLabel>
          <Select
            labelId="project-label"
            value={selectedProjectId}
            label="Project"
            onChange={e => setSelectedProjectId(e.target.value)}
          >
            {projects.map(project => (
              <MenuItem value={project.id} key={project.id}>
                {project.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </TitanDialog>
  );
}
