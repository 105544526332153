import React from 'react';
import Link from '@mui/material/Link';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import TitanDialog from '../Titan/TitanDialog';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import OdooService from '../../services/OdooService';
import ProjectForm from './ProjectForm';

const STEP_KEYS = {
  SELECT_ODOO_PROJECT: 'SELECT_ODOO_PROJECT',
  REVIEW_PROJECT: 'REVIEW_PROJECT'
};

export default function ImportOdooProjectDialog({ onSave, onClose }) {
  const [steps, setSteps] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);

  const [selectedOdooProjects, setSelectedOdooProjects] = React.useState([]);

  const [name, setName] = React.useState('');
  const [salesOrderNumber, setSalesOrderNumber] = React.useState('');
  const [projectNumber, setProjectNumber] = React.useState('');
  const [customerAbbreviation, setCustomerAbbreviation] = React.useState('');
  const [partName, setPartName] = React.useState('');
  const [projectKey, setProjectKey] = React.useState('');
  const [previewImage, setPreviewImage] = React.useState();
  const [autoGenerateProjectKey, setAutoGenerateProjectKey] = React.useState(
    true
  );
  const [displayImage, setDisplayImage] = React.useState('');

  React.useEffect(() => {
    setSteps([
      {
        key: STEP_KEYS.SELECT_ODOO_PROJECT,
        label: 'Select Odoo Project'
      },
      {
        key: STEP_KEYS.REVIEW_PROJECT,
        label: 'Review Project'
      }
    ]);
  }, []);

  const loadData = React.useCallback(
    async ({ page, pageSize, search }, config) => {
      const params = {
        page: page,
        pageSize: pageSize
      };

      if (search) {
        params.search = search;
      }

      const { data, pagination } = await OdooService.getOdooProjects(
        params,
        config
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount
      };
    },
    []
  );

  const titanDataGridProps = useTitanDataGrid(loadData);

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Odoo Project',
        field: 'name',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <Link href={row.odooProjectUrl} target="_blank">
            {row.name}
          </Link>
        )
      }
    ],
    []
  );

  React.useEffect(() => {
    if (selectedOdooProjects[0]) {
      const { name } = selectedOdooProjects[0];

      if (name) {
        const nameParts = [
          ...name.matchAll(/S(.*)\s-\s([^_]*)_*([^_]*)_*([^_]*)/gm)
        ][0];

        setName(
          nameParts && nameParts.length > 3
            ? nameParts
                .slice(2, 5)
                .filter(i => i && i.length !== 0)
                .join('_')
            : name
        );

        if (nameParts) {
          if (nameParts[1]) {
            setSalesOrderNumber(nameParts[1]);
          }

          if (nameParts[2]) {
            setProjectNumber(nameParts[2]);
          }

          if (nameParts[3]) {
            setCustomerAbbreviation(nameParts[3]);
          }

          if (nameParts[4]) {
            setPartName(nameParts[4]);
          }
        }
      }
    }
  }, [selectedOdooProjects]);

  const onSaveHandler = React.useCallback(async () => {
    const { id } = selectedOdooProjects[0];

    return onSave({
      name,
      salesOrderNumber: salesOrderNumber || null,
      projectNumber,
      customerAbbreviation,
      partName: partName || null,
      projectKey: projectKey || null,
      previewImage,
      odooProjectId: id
    });
  }, [
    selectedOdooProjects,
    name,
    salesOrderNumber,
    projectNumber,
    customerAbbreviation,
    partName,
    projectKey,
    previewImage
  ]);

  return (
    <TitanDialog
      maxWidth="lg"
      title="Import Project from Odoo"
      onClose={onClose}
      onSave={activeStep === steps.length - 1 ? onSaveHandler : null}
      saveButtonLabel="Import"
      isValid={
        selectedOdooProjects.length > 0 &&
        name.length !== 0 &&
        projectNumber.length !== 0 &&
        customerAbbreviation.length !== 0 &&
        customerAbbreviation.length <= 5
      }
      isNextValid={selectedOdooProjects.length > 0}
      onPrev={activeStep > 0 ? () => setActiveStep(activeStep - 1) : null}
      onNext={
        activeStep < steps.length - 1
          ? () => setActiveStep(activeStep + 1)
          : null
      }
    >
      <>
        <Stepper sx={{ mb: 2 }} activeStep={activeStep} alternativeLabel>
          {steps.map(step => (
            <Step key={step.key} completed={step.completed}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && (
          <TitanDataGrid
            searchPlaceholder="Search by Odoo project name..."
            {...titanDataGridProps}
            columns={columns}
            onClose={onClose}
            onSelectionModelChange={selectedOdooProjects =>
              setSelectedOdooProjects(selectedOdooProjects)
            }
            selectedRows={selectedOdooProjects}
            checkboxSelection
            isRowSelectable={({ row }) => !row.fpProjectId}
          />
        )}

        {activeStep === 1 && (
          <ProjectForm
            name={name}
            setName={setName}
            projectNumber={projectNumber}
            setProjectNumber={setProjectNumber}
            salesOrderNumber={salesOrderNumber}
            setSalesOrderNumber={setSalesOrderNumber}
            customerAbbreviation={customerAbbreviation}
            setCustomerAbbreviation={setCustomerAbbreviation}
            partName={partName}
            setPartName={setPartName}
            projectKey={projectKey}
            setProjectKey={setProjectKey}
            previewImage={previewImage}
            setPreviewImage={setPreviewImage}
            autoGenerateProjectKey={autoGenerateProjectKey}
            setAutoGenerateProjectKey={setAutoGenerateProjectKey}
            displayImage={displayImage}
            setDisplayImage={setDisplayImage}
          />
        )}
      </>
    </TitanDialog>
  );
}
