import axios from 'axios';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ChangeCircle from '@mui/icons-material/ChangeCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

export default class FinalPartService {
  static getFinalParts(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v2/final-parts`, {
        ...config,
        params,
      })
      .then((res) => res.data);
  }

  static async addPhoto(finalPartId, data, version = 'v2') {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/${version}/final-parts/${finalPartId}/photos`,
        data,
      )
      .then((res) => res.data.data);
  }

  static async updatePhoto(photoId, data) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v2/final-parts/photos/${photoId}`,
        data,
      )
      .then((res) => res.data.data);
  }

  static async deletePhoto(photoId, version = 'v2') {
    return axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/${version}/final-parts/photos/${photoId}`,
      )
      .then((res) => res.data.data);
  }

  static printLabel(finalPartId, params = {}) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v2/final-parts/${finalPartId}/print-label`,
        {
          params,
        },
      )
      .then((res) => res.data);
  }

  static getFinalPartActions({
    finalPart,
    onAttachToManufacturingOrder,
    onDetachFromManufacturingOrder,
  }) {
    const actions = [];

    actions.push({
      variant: 'outlined',
      color: 'secondary',
      label: !finalPart.manufacturingOrderId
        ? 'to Manufacturing Order'
        : 'Change Manufacturing Order',
      icon: !finalPart.manufacturingOrderId ? (
        <AddCircleIcon />
      ) : (
        <ChangeCircle />
      ),
      onClick: onAttachToManufacturingOrder,
    });

    if (finalPart.manufacturingOrderId) {
      actions.push({
        variant: 'outlined',
        color: 'secondary',
        label: 'from Manufacturing Order',
        icon: <RemoveCircleIcon />,
        onClick: onDetachFromManufacturingOrder,
      });
    }

    return actions;
  }

  static prepareFinalPartCustomFields(
    finalPartId,
    finalPartTypeCustomFields,
    finalPartCustomFields,
  ) {
    return finalPartCustomFields
      ? finalPartCustomFields
          .concat(
            finalPartTypeCustomFields
              .filter(
                (fusionPlanCustomField) =>
                  !finalPartCustomFields.find(
                    (finalPartCustomField) =>
                      fusionPlanCustomField.id ===
                      finalPartCustomField.finalPartTypeCustomFieldId,
                  ),
              )
              .map((fusionPlanCustomField) => ({
                finalPartTypeCustomFieldId: fusionPlanCustomField.id,
                finalPartId,
                value: '',
              })),
          )
          .reduce((res, customField) => {
            res[customField.finalPartTypeCustomFieldId] = customField;

            return res;
          }, {})
      : {};
  }
}
