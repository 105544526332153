import axios from 'axios';

export default class TagService {
  static getTags(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v2/tags`, {
        ...config,
        params
      })
      .then(res => res.data);
  }
}
