import React from 'react';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';

export const BooleanCell = ({
  row,
  customFieldData,
  customField,
  isEditable,
  updateCustomField,
  prepareComponentToUpdate,
  updateComponents,
}) => {
  return (
    <FormControl sx={{ m: 0.5, width: 350 }} size="small">
      <Select
        disabled={!isEditable(row)}
        value={customFieldData?.value}
        onChange={(e) => {
          let newValue = e.target.value;

          const componentToUpdate = prepareComponentToUpdate(
            'BOOLEAN',
            row,
            customField.name,
            newValue,
          );

          if (customFieldData) {
            updateCustomField(row, {
              ...customFieldData,
              value: newValue,
            });
            updateComponents([componentToUpdate]);
          }
        }}
      >
        <MenuItem value="true">
          <ListItemText primary="Yes" />
        </MenuItem>
        <MenuItem value="false">
          <ListItemText primary="No" />
        </MenuItem>
      </Select>
    </FormControl>
  );
};
