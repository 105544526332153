import React, { Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import TitanRoute from './TitanRoute';
import { PERMISSIONS } from '../../constants/auth0';

import BuildModulePage from '../BuildModule/BuildModulePage';
import FusionModulePage from '../FusionModule/FusionModulePage';
import GuestHomePage from '../Guest/GuestHomePage';
import Welcome from '../Welcome/Welcome';
import VerifyEmail from '../Welcome/VerifyEmail';
import AdminPage from '../AdminPage/AdminDashboardPage';
import Organization from '../Organization/OrganizationPage';
import PreBuildingChecklistPage from '../PreBuildingChecklist/PreBuildingChecklistPage';
import Logout from '../../Logout';
import NotFound from '../NotFound/NotFound';

import ROUTES from '../../constants/routes';
import FDSReleasesPage from '../FDSReleases/FDSReleasesPage';
import HistoryPage from '../History/HistoryPage';
import FinalPartPage from '../FinalPart/FinalPartPage';
import PreformPage from '../Component/PreformPage';
import PresetPage from '../Presets/PresetPage';
import ProfilePage from '../Profile/ProfilePage';
import BuildPlansPage from '../BuildPlans/BuildPlansPage';
import BuildPlanPage from '../BuildPlan/BuildPlanPage';
import FusionPlansPage from '../FusionPlans/FusionPlansPage';
import FusionPlanPage from '../FusionPlan/FusionPlanPage';
import SupportPage from '../Support/SupportPage';
import TermsPage from '../Terms/TermsPage';
import TermsDetailsPage from '../Terms/TermsDetailsPage';
import NewTermsPage from '../Terms/NewTermsPage';
import GearsPage from '../Gears/GearsPage';
import SpoolPage from '../Spools/SpoolPage';
import ProjectsManageList from '../Projects/ProjectsManageList';
import BuildJobPage from '../BuildJob/BuildJobPage';
import FusionJobPage from '../FusionJob/FusionJobPage';
import { useAuth0 } from '../Authentication/Auth0';
import DashboardPage from '../Dashboard/DashboardPage';
import OpenSourceLicensesPage from '../Lisences/OpenSourceLicensesPage';
import PlansComparsionPage from '../PlansComparsion/PlansComparsion';
import ProjectPage from '../Projects/ProjectPage';
import ManufacturingOrderPage from '../ManufacturingOrders/ManufacturingOrderPage';
import BuildModulesPage from '../BuildModules/BuildModulesPage';
import FusionModulesPage from '../FusionModules/FusionModulesPage';
import ManufacturingOrdersPage from '../ManufacturingOrders/ManufacturingOrdersPage';

export default function Router() {
  const { isSuperAdmin } = useAuth0();

  return (
    <Switch>
      <TitanRoute exact path={ROUTES.VERIFY_EMAIL} component={VerifyEmail} />
      <TitanRoute exact path={ROUTES.WELCOME} component={Welcome} />
      <TitanRoute
        exact
        path={ROUTES.BUILD_MODULES}
        permissions={[PERMISSIONS.READ_BUILD_MODULE]}
        component={BuildModulesPage}
      />
      <TitanRoute
        exact
        path={ROUTES.FUSION_MODULES}
        permissions={[PERMISSIONS.READ_FUSION_MODULE]}
        component={FusionModulesPage}
      />
      <TitanRoute
        exact
        path={ROUTES.BUILD_MODULE_TAB_PAGE()}
        permissions={[PERMISSIONS.READ_BUILD_MODULE]}
        component={BuildModulePage}
      />
      <TitanRoute
        exact
        path={ROUTES.FUSION_MODULE_TAB_PAGE()}
        permissions={[PERMISSIONS.READ_FUSION_MODULE]}
        component={FusionModulePage}
      />
      <TitanRoute
        exact
        path={ROUTES.BUILD_PLAN_V2_TAB_PAGE()}
        permissions={[PERMISSIONS.READ_BUILD_PLAN]}
        component={BuildPlanPage}
      />
      <TitanRoute
        exact
        path={ROUTES.BUILD_JOB_TAB_PAGE()}
        component={BuildJobPage}
      />
      <TitanRoute
        exact
        path={ROUTES.PRESET()}
        permissions={PERMISSIONS.READ_PRESETS}
        component={PresetPage}
      />
      <TitanRoute exact path={ROUTES.SPOOL()} component={SpoolPage} />
      <TitanRoute exact path={ROUTES.SPOOL_TAB()} component={SpoolPage} />
      <TitanRoute
        exact
        path={ROUTES.DASHBOARD}
        permissions={PERMISSIONS.READ_BUILD_PLANS}
        component={DashboardPage}
      />
      <TitanRoute
        exact
        path={ROUTES.BUILD_PLANS()}
        permissions={PERMISSIONS.READ_BUILD_PLANS}
        component={BuildPlansPage}
      />
      <TitanRoute
        exact
        path={ROUTES.FUSION_PLAN_V2_TAB_PAGE()}
        permissions={[PERMISSIONS.READ_FUSION_PLAN]}
        component={FusionPlanPage}
      />
      <TitanRoute
        exact
        path={ROUTES.FUSION_JOB_TAB_PAGE()}
        component={FusionJobPage}
      />
      <TitanRoute
        exact
        path={ROUTES.FUSION_PLANS()}
        permissions={PERMISSIONS.READ_FUSION_PLANS}
        component={FusionPlansPage}
      />
      <TitanRoute exact path={ROUTES.HOME_PAGE} component={GuestHomePage} />
      <TitanRoute
        exact
        path={ROUTES.ADMIN_DASHBOARD}
        permissions={[
          PERMISSIONS.READ_ORGANIZATIONS,
          PERMISSIONS.READ_BUILD_MODULE,
          PERMISSIONS.READ_FUSION_MODULE,
        ]}
        matchAll={true}
        component={AdminPage}
      />
      <TitanRoute
        exact
        path={ROUTES.ADMIN_DASHBOARD_TAB_PAGE()}
        permissions={[
          PERMISSIONS.READ_ORGANIZATIONS,
          PERMISSIONS.READ_BUILD_MODULE,
          PERMISSIONS.READ_FUSION_MODULE,
        ]}
        matchAll={true}
        component={AdminPage}
      />
      <TitanRoute
        exact
        path={ROUTES.ORGANIZATION()}
        permissions={[
          PERMISSIONS.READ_BUILD_MODULE,
          PERMISSIONS.READ_FUSION_MODULE,
          PERMISSIONS.READ_ORGANIZATION_MEMBERS,
        ]}
        component={Organization}
      />
      <TitanRoute
        exact
        path={ROUTES.ORGANIZATION_TAB_PAGE()}
        permissions={[
          PERMISSIONS.READ_BUILD_MODULE,
          PERMISSIONS.READ_FUSION_MODULE,
          PERMISSIONS.READ_ORGANIZATION_MEMBERS,
        ]}
        component={Organization}
      />
      <TitanRoute
        exact
        path={ROUTES.PRE_BUILDING_CHECKLIST()}
        permissions={[PERMISSIONS.READ_PRE_BUILDING_CHECKLIST]}
        component={PreBuildingChecklistPage}
      />
      <TitanRoute
        exact
        path={ROUTES.PROJECTS()}
        permissions={[PERMISSIONS.READ_PROJECTS]}
        component={ProjectsManageList}
      />
      <TitanRoute
        exact
        path={ROUTES.PROJECTS_PAGE()}
        permissions={[PERMISSIONS.READ_PROJECTS]}
        component={ProjectsManageList}
      />
      <TitanRoute
        exact
        path={ROUTES.MANUFACTURING_ORDERS()}
        permissions={[PERMISSIONS.WRITE_MANUFACTURING_ORDERS]}
        component={ManufacturingOrdersPage}
      />
      <TitanRoute
        exact
        path={ROUTES.MANUFACTURING_ORDERS_PAGE()}
        permissions={[PERMISSIONS.WRITE_MANUFACTURING_ORDERS]}
        component={ManufacturingOrdersPage}
      />
      <TitanRoute
        exact
        path={ROUTES.PROJECT()}
        permissions={[PERMISSIONS.READ_PROJECTS]}
        component={ProjectPage}
      />
      <TitanRoute
        exact
        path={ROUTES.PROJECT_TAB()}
        permissions={[PERMISSIONS.READ_PROJECTS]}
        component={ProjectPage}
      />
      <TitanRoute
        exact
        path={ROUTES.MANUFACTURING_ORDER()}
        permissions={[PERMISSIONS.READ_MANUFACTURING_ORDERS]}
        component={ManufacturingOrderPage}
      />
      <TitanRoute
        exact
        path={ROUTES.MANUFACTURING_ORDER_TAB()}
        permissions={[PERMISSIONS.READ_MANUFACTURING_ORDERS]}
        component={ManufacturingOrderPage}
      />
      <TitanRoute
        exact
        path={ROUTES.HISTORY_ITEMS()}
        permissions={[
          PERMISSIONS.MANAGE_ASSEMBLIES,
          PERMISSIONS.MANAGE_COMPONENTS,
        ]}
        component={HistoryPage}
      />
      <TitanRoute
        exact
        path={ROUTES.HISTORY()}
        permissions={[
          PERMISSIONS.MANAGE_ASSEMBLIES,
          PERMISSIONS.MANAGE_COMPONENTS,
        ]}
        component={HistoryPage}
      />
      <TitanRoute
        exact
        path={ROUTES.FINAL_PART_TAB_PAGE()}
        permissions={[PERMISSIONS.MANAGE_ASSEMBLIES]}
        component={FinalPartPage}
      />
      <TitanRoute
        exact
        path={ROUTES.PREFORM_TAB()}
        permissions={[PERMISSIONS.MANAGE_COMPONENTS]}
        component={PreformPage}
      />
      <TitanRoute
        exact
        path={ROUTES.FDS_RELEASES_PAGE()}
        permissions={[
          PERMISSIONS.MANAGE_FDS_RELEASES,
          PERMISSIONS.READ_FDS_RELEASES,
        ]}
        component={FDSReleasesPage}
      />
      <TitanRoute exact path="/logout" component={Logout} />
      <TitanRoute
        exact
        path={ROUTES.TERMS_AND_CONDITIONS}
        component={TermsPage}
      />

      <TitanRoute
        exact
        path={ROUTES.LICENSES_OPEN_SOURCE}
        component={OpenSourceLicensesPage}
      />

      {isSuperAdmin && (
        <TitanRoute
          exact
          path={ROUTES.NEW_TERMS_AND_CONDITIONS}
          permissions={PERMISSIONS.CREATE_TERMS_AND_CONDITIONS}
          component={NewTermsPage}
        />
      )}

      <TitanRoute
        exact
        path={ROUTES.TERMS_AND_CONDITIONS_SINGLE()}
        permissions={PERMISSIONS.MANAGE_TERMS_AND_CONDITIONS}
        component={TermsDetailsPage}
      />

      <TitanRoute exact path={ROUTES.SUPPORT} component={SupportPage} />
      <TitanRoute exact path={ROUTES.PROFILE} component={ProfilePage} />
      <Redirect exact path="/library/:tabName/search" to="/library/:tabName" />
      <TitanRoute exact path={ROUTES.GEAR} component={GearsPage} />
      <TitanRoute exact path={ROUTES.GEAR_TAB()} component={GearsPage} />
      <TitanRoute exact path={ROUTES.GEAR_TAB_PAGE()} component={GearsPage} />
      <TitanRoute
        exact
        path={ROUTES.PLANS_COMPARSION}
        component={PlansComparsionPage}
      />
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <TitanRoute component={NotFound} />
        </Switch>
      </Suspense>
    </Switch>
  );
}
