import React from 'react';

import useAsyncEffect from 'use-async-effect';

import { updateBuildJobData } from './use-build-jobs';
import { useTitan } from '../Titan/Titan';
import useWebSocket from 'react-use-websocket';
import { WEB_SOCKET_ACTIONS } from '../../constants';

export default function useBuildJob(loadBuildJob) {
  const { getWebSocketUrl, getWebSocketOptions } = useTitan();

  const [loading, setLoading] = React.useState(true);
  const [buildJob, setBuildJob] = React.useState(null);

  useAsyncEffect(async () => {
    setLoading(true);

    const buildJob = await loadBuildJob();

    setBuildJob(buildJob);
    setLoading(false);
  }, [loadBuildJob]);

  const { lastJsonMessage } = useWebSocket(
    getWebSocketUrl,
    getWebSocketOptions()
  );

  React.useEffect(() => {
    if (
      lastJsonMessage !== null &&
      [
        WEB_SOCKET_ACTIONS.BUILD_JOB,
        WEB_SOCKET_ACTIONS.BUILD_JOB_STATE
      ].includes(lastJsonMessage.action) &&
      lastJsonMessage.data &&
      lastJsonMessage.data.id &&
      buildJob &&
      buildJob.id === lastJsonMessage.data.id
    ) {
      setBuildJob(prev => updateBuildJobData(prev, lastJsonMessage.data));
    }
  }, [lastJsonMessage]);

  return {
    loading,
    buildJob,
    setBuildJob
  };
}
