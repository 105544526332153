import React from 'react';
import { IconButton } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import FibrifyManufacturingOrderSelectionDialog from '../Fibrify/FibrifyManufacturingOrderSelectionDialog';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';

export default function ManufacturingOrderActionsButton({
  manufacturingOrder,
  filters = {},
  onChange
}) {
  const [
    openManufacturingOrdersDialog,
    setOpenManufacturingOrdersDialog
  ] = React.useState(false);
  const [
    openManufacturingOrdersClearConfirmationDialog,
    setOpenManufacturingOrdersClearConfirmationDialog
  ] = React.useState(false);

  return (
    <>
      <IconButton
        size="large"
        onClick={() => setOpenManufacturingOrdersDialog(true)}
      >
        <CreateIcon />
      </IconButton>
      {manufacturingOrder ? (
        <IconButton
          size="large"
          onClick={() =>
            setOpenManufacturingOrdersClearConfirmationDialog(true)
          }
        >
          <DeleteIcon />
        </IconButton>
      ) : (
        ''
      )}
      {openManufacturingOrdersDialog ? (
        <FibrifyManufacturingOrderSelectionDialog
          title="Select Manufacturing order"
          saveButtonLabel="Save"
          filters={filters}
          currentSelection={manufacturingOrder ? [manufacturingOrder] : []}
          onSave={manufacturingOrder => onChange(manufacturingOrder)}
          onClose={() => setOpenManufacturingOrdersDialog(false)}
        />
      ) : (
        ''
      )}

      {openManufacturingOrdersClearConfirmationDialog ? (
        <TitanConfirmationDialog
          title="Clear Manufacturing Order"
          message="Are you sure you want to clear Manufacturing Order?"
          onConfirm={() => onChange(null)}
          onClose={() => {
            setOpenManufacturingOrdersClearConfirmationDialog(false);
          }}
        />
      ) : (
        ''
      )}
    </>
  );
}
