import React from 'react';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import { useFusionPlan } from './FusionPlanPage';
import { useParams } from 'react-router-dom';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import FusionPlanService from '../../services/FusionPlanService';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import PlanService from '../../services/PlanService';
import FusionPlanLink from './FusionPlanLink';
import Typography from '@mui/material/Typography';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import { CustomGridColumnsPanel } from '../TitanDataGrid/TitanDataGridColumnsPanel';
import useComparableFusionPlans from '../FusionPlans/use-comparable-fusion-plans';

export default function FusionPlanHistoryTab() {
  const { fusionPlanId } = useParams();
  const { fusionPlan, setFusionPlan, comparablePlans, toggleComparablePlan } =
    useFusionPlan();

  const [newDefaultVersion, setNewDefaultVersion] = React.useState(null);

  const loadData = React.useCallback(
    async (params, config) => {
      const data = await FusionPlanService.getVersions(
        fusionPlan.id,
        params,
        config,
      );

      return {
        data,
      };
    },
    [
      fusionPlan.name,
      fusionPlan.planKey,
      fusionPlan.isDefault,
      newDefaultVersion,
    ],
  );

  const getComparable = React.useCallback(
    (plan) => {
      return !!comparablePlans.includes(plan.id);
    },
    [comparablePlans],
  );

  const titanDataGridProps = useTitanDataGrid(loadData);

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        flex: 1,
        sortable: false,
        renderCell: ({ row, colDef }) => (
          <FusionPlanLink
            fusionPlan={row}
            useName={false}
            fusionPlanId={fusionPlanId}
            width={colDef.width}
          >
            <Typography>
              {[
                row.name,
                PlanService.formatPlanKey(row.planKey, row.version),
              ].join(' ')}
              {row.id === fusionPlan.id ? ' (current)' : ''}
              {row.isDefault && ' (default)'}
            </Typography>
          </FusionPlanLink>
        ),
      },
      {
        headerName: 'Version',
        field: 'version',
        minWidth: 50,
        sortable: false,
      },
      {
        headerName: 'Uploaded',
        field: 'uploaded',
        minWidth: 180,
        sortable: false,

        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />,
      },
      {
        headerName: 'Comment',
        field: 'comment',
        flex: 1,
        sortable: false,
      },
      {
        headerName: 'FMA Version',
        field: 'fusionModuleVersion',
        minWidth: 120,
        sortable: false,
      },
      {
        headerName: '# Jobs',
        field: 'totalJobs',
        minWidth: 100,
        sortable: false,
      },
      {
        headerName: '# Final Parts',
        field: 'totalFinalParts',
        minWidth: 130,
        sortable: false,
      },
      {
        headerName: '',
        field: 'actions',
        hideable: false,
        hideInMenu: true,
        type: 'actions',
        visibilityBreakpoint: 'sm',
        width: 100,
        getActions: (params) =>
          FusionPlanService.getFusionPlanVersionActions(params.row, {
            onSetIsDefault: () => setNewDefaultVersion(params.row),
            onAddToComparison: () => {
              toggleComparablePlan(params.row);
            },
            isInComparison: () => getComparable(params.row),
          }).map((action) => {
            if (params.row?.assemblyId) {
              return <span />;
            } else {
              return (
                <GridActionsCellItem
                  icon={action.icon}
                  label={action.label}
                  showInMenu={!!action.label}
                  onClick={action.onClick}
                  disabled={action.disabled}
                />
              );
            }
          }),
      },
    ],
    [getComparable, toggleComparablePlan, newDefaultVersion],
  );

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        components={{
          Toolbar: TitanDataGridToolbar,
          ColumnsPanel: CustomGridColumnsPanel,
        }}
        showSearch={false}
        columns={columns}
        pagination={false}
      />

      {newDefaultVersion && (
        <TitanConfirmationDialog
          title="Set this version as default?"
          message="Are you sure you want to set this version of a Fusion Plan as a default one?"
          onClose={() => setNewDefaultVersion(null)}
          onConfirm={async () => {
            if (newDefaultVersion.id === fusionPlan.id) {
              setFusionPlan({ ...fusionPlan, isDefault: true });
            } else {
              setFusionPlan({ ...fusionPlan, isDefault: false });
            }
            await FusionPlanService.updateFusionPlan(
              newDefaultVersion.id,
              { isDefault: true },
              'v2',
            );
          }}
        />
      )}
    </>
  );
}
