import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Link, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import OverflowTip from '../ExpandRenderCell/OverflowTip';
import { useTitan } from '../Titan/Titan';
import { useAuth0 } from '../Authentication/Auth0';
import { ROUTES } from '../../constants';

const useStyles = makeStyles(theme => ({
  linkBox: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center'
  },
  hiddenOverflowBlock: {
    overflow: 'hidden'
  },
  chip: {
    fontSize: '0.7rem',
    marginLeft: '8px'
  }
}));

export default function SpoolLink(props) {
  const {
    spool,
    width,
    disabled = false,
    onClick = e => e.stopPropagation()
  } = props;
  const location = useLocation();
  const classes = useStyles();

  const { addPageToPageHistory, preparePageObjectForLink } = useTitan();

  const handleClick = e => {
    addPageToPageHistory(
      preparePageObjectForLink(
        'SPOOL',
        spool.id,
        spool.spoolIdentifier,
        ROUTES.SPOOL(spool.id, 'summary'),
        'Summary'
      )
    );

    if (onClick) {
      onClick(e);
    }
  };

  if (disabled) {
    return <span>{spool.spoolIdentifier}</span>;
  }

  return (
    <Box className={classes.linkBox}>
      <Link
        className={classes.hiddenOverflowBlock}
        onClick={handleClick}
        to={{
          pathname: ROUTES.SPOOL_TAB(spool.id, 'summary'),
          state: { from: location.pathname }
        }}
        component={RouterLink}
      >
        {width ? (
          <OverflowTip width={width}>{spool.spoolIdentifier}</OverflowTip>
        ) : (
          <span>{spool.spoolIdentifier}</span>
        )}
      </Link>
      {spool?.deletedAt && (
        <Chip
          label="DELETED"
          color="primary"
          variant="outlined"
          className={classes.chip}
        />
      )}
    </Box>
  );
}
