import React from 'react';

import TextField from '@mui/material/TextField';
import PrinterModelService from '../../services/PrinterModelService';
import PrinterSeriesService from '../../services/PrinterSeriesService';
import TitanDialog from '../Titan/TitanDialog';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function PrinterModelDialog(props) {
  const { mode, printerModel, onClose, onSave } = props;

  const [name, setName] = React.useState(printerModel.name || '');
  const [bedShape, setBedShape] = React.useState(printerModel.bedShape || '');
  const [seriesId, setSeriesId] = React.useState(printerModel.seriesId || '');
  const [printerSeries, setPrinterSeries] = React.useState([]);

  React.useEffect(() => {
    PrinterSeriesService.getPrinterSeries().then(response =>
      setPrinterSeries(response.data)
    );
  }, []);

  const onSubmitForm = () => {
    if (mode === 'create') {
      return PrinterModelService.createPrinterModel({
        name: name,
        bedShape,
        seriesId
      }).then(data => {
        onSave(data);
      });
    } else {
      return PrinterModelService.updatePrinterModel(printerModel.id, {
        name,
        bedShape,
        seriesId
      }).then(data => {
        onSave(data);
      });
    }
  };

  const isValid = name.length !== 0 && bedShape.length !== 0 && seriesId;

  return (
    <TitanDialog
      title={`${mode === 'create' ? 'Create' : 'Edit'} printer model`}
      onClose={onClose}
      onSave={onSubmitForm}
      isValid={isValid}
      successSaveMessage={`Printer model successfully ${
        mode === 'create' ? 'created' : 'updated'
      }`}
    >
      <TextField
        autoFocus
        label="Name"
        value={name}
        fullWidth
        margin="normal"
        onChange={e => setName(e.target.value)}
      />

      <TextField
        label="Bed shape"
        value={bedShape}
        fullWidth
        margin="normal"
        onChange={e => setBedShape(e.target.value)}
        placeholder="0x0,215x0,215x220,0x220"
      />

      <FormControl margin="dense" fullWidth>
        <InputLabel id="printer-model-series">Series</InputLabel>
        <Select
          fullWidth
          labelId="printer-model-series"
          value={seriesId}
          onChange={e => setSeriesId(e.target.value)}
        >
          {printerSeries.map(ps => (
            <MenuItem value={ps.id} key={ps.id}>
              {ps.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </TitanDialog>
  );
}
