import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from './Auth0';
import { ROUTES } from '../../constants';
import { PERMISSIONS } from '../../constants/auth0';
import { useTitan } from '../Titan/Titan';
import TermsAndConditionsService from '../../services/TermsAndConditionsService';
import useAsyncEffect from 'use-async-effect';
import TitanCircularProgress from '../Titan/TitanCircularProgress';

export default function CheckTerms(props) {
  const history = useHistory();
  const { isAuthenticated, permissions, profile, user, isSuperAdmin } =
    useAuth0();
  const { pushSnackbar } = useTitan();
  const [loading, setLoading] = React.useState(true);

  const checkIfLatestTermsAndConditions = async () => {
    const terms = await TermsAndConditionsService.loadLatest();

    return terms.id !== profile.termsAndConditionsId;
  };

  useAsyncEffect(async () => {
    if (isAuthenticated) {
      const generalTerms = profile.loggedAs
        ? profile.loggedAsMember.generalTerms
        : profile.generalTerms;

      const isPublishedNewTerms = await checkIfLatestTermsAndConditions();

      if (
        profile &&
        (!generalTerms || isPublishedNewTerms) &&
        user &&
        user.email_verified &&
        history.location.pathname !== ROUTES.TERMS_AND_CONDITIONS
      ) {
        if (isPublishedNewTerms) {
          pushSnackbar('New Terms and Conditions version published');
        }
        history.push(ROUTES.TERMS_AND_CONDITIONS);
      } else if (history.location.pathname === '/') {
        const lastActivePath = window.localStorage.getItem('lastActivePath');

        if (lastActivePath && lastActivePath !== '/') {
          window.localStorage.removeItem('lastActivePath');
          setLoading(false);

          return history.push(lastActivePath);
        }

        if (permissions.indexOf(PERMISSIONS.READ_BUILD_PLANS) !== -1) {
          if (isSuperAdmin) {
            history.push(ROUTES.ADMIN_DASHBOARD_TAB('organizations'));
          } else {
            history.push(ROUTES.DASHBOARD);
          }
        } else {
          history.push(ROUTES.HOME_PAGE);
        }
      }
    }

    setLoading(false);
  }, [isAuthenticated, profile]);

  return loading ? <TitanCircularProgress /> : props.children;
}
