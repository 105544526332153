import React from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import IconButton from '@mui/material/IconButton';
import FusionPlanService from '../../services/FusionPlanService';

export default function BuildPlanFavoriteButton({ fusionPlan, onChange }) {
  return (
    <IconButton
      onClick={async e => {
        e.preventDefault();
        e.stopPropagation();

        const updatedFusionPlan = await (fusionPlan.isFavorite
          ? FusionPlanService.removeFromFavorite(fusionPlan.id)
          : FusionPlanService.addToFavorite(fusionPlan.id));

        onChange({
          ...fusionPlan,
          isFavorite: updatedFusionPlan.isFavorite
        });
      }}
      title={fusionPlan.isFavorite ? 'Remove from favorite' : 'Add to Favorite'}
    >
      {fusionPlan.isFavorite ? (
        <FavoriteIcon color="primary" />
      ) : (
        <FavoriteBorderIcon color="primary" />
      )}
    </IconButton>
  );
}
