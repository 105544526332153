import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useResponsiveQueries = () => {
  const matchesSM = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const matchesMD = useMediaQuery(theme => theme.breakpoints.up('md'));
  const matchesLG = useMediaQuery(theme => theme.breakpoints.up('lg'));
  return {
    matchesSM,
    matchesMD,
    matchesLG
  };
};
