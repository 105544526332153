import React from 'react';
import useWebSocket from 'react-use-websocket';
import { WEB_SOCKET_ACTIONS } from '../../constants';
import useAsyncEffect from 'use-async-effect';

import { updateFusionJobData } from '../FusionJob/use-fusion-jobs';
import { useTitan } from '../Titan/Titan';

export default function useFusionModules(loadFusionModules) {
  const { getWebSocketUrl, getWebSocketOptions } = useTitan();

  const [loading, setLoading] = React.useState(true);
  const [fusionModules, setFusionModules] = React.useState([]);

  useAsyncEffect(async () => {
    setLoading(true);

    const fusionModules = await loadFusionModules();

    setFusionModules(fusionModules);
    setLoading(false);
  }, [loadFusionModules]);

  const onChangeFusionModule = React.useCallback(
    newFusionModuleData => {
      setFusionModules(prev => {
        const fusionModule = prev.find(
          fm => fm.id === newFusionModuleData.fusionModuleId
        );

        if (!fusionModule) {
          return prev;
        }

        return prev.map(fm => {
          if (fm.id === newFusionModuleData.fusionModuleId) {
            const updatedFusionModule = { ...fm };

            if (newFusionModuleData.state) {
              updatedFusionModule.state = newFusionModuleData.state;

              updatedFusionModule.lastState = newFusionModuleData.state;
            }

            if (newFusionModuleData.status) {
              updatedFusionModule.currentStatus = newFusionModuleData.status;
            }

            if (newFusionModuleData.progress) {
              updatedFusionModule.progress = newFusionModuleData.progress;
            }

            if (newFusionModuleData.deviceVersion) {
              updatedFusionModule.deviceVersion =
                newFusionModuleData.deviceVersion;
            }

            if (newFusionModuleData.isAvailableToBuild !== undefined) {
              updatedFusionModule.isAvailableToBuild =
                newFusionModuleData.isAvailableToBuild;
            }

            return updatedFusionModule;
          }

          return fm;
        });
      });
    },
    [setFusionModules]
  );

  const onChangeFusionJob = React.useCallback(
    newFusionJobData => {
      setFusionModules(prev => {
        return prev.map(fusionModule => {
          if (fusionModule.activeJobs) {
            return {
              ...fusionModule,
              activeJobs: fusionModule.activeJobs.map(fusionJob => {
                if (
                  fusionJob.id === newFusionJobData.fusionJobId ||
                  fusionJob.id === newFusionJobData.id
                ) {
                  return updateFusionJobData(fusionJob, newFusionJobData);
                }

                return fusionJob;
              })
            };
          }

          return { ...fusionModule, activeJobs: [newFusionJobData] };
        });
      });
    },
    [setFusionModules]
  );

  const { lastJsonMessage } = useWebSocket(
    getWebSocketUrl,
    getWebSocketOptions()
  );

  React.useEffect(() => {
    if (
      lastJsonMessage !== null &&
      [WEB_SOCKET_ACTIONS.FUSION_MODULE_STATE].includes(
        lastJsonMessage.action
      ) &&
      lastJsonMessage.data
    ) {
      onChangeFusionModule(lastJsonMessage.data);
    }

    if (
      lastJsonMessage !== null &&
      [WEB_SOCKET_ACTIONS.FUSION_JOB].includes(lastJsonMessage.action) &&
      lastJsonMessage.data
    ) {
      onChangeFusionJob(lastJsonMessage.data);
    }
  }, [lastJsonMessage, onChangeFusionModule]);

  return {
    loading,
    fusionModules,
    setFusionModules
  };
}
