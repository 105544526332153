import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import { COMPONENT_STATUSES, ROUTES } from '../../constants';
import { colors } from '../Theme/vars';
import BuildModuleIcon from '../../assets/icons/build-module.svg';
import classNames from 'classnames';
import { useTitan } from '../Titan/Titan';
import BuildModuleState from '../BuildModule/BuildModuleState';
import useBuildModuleState from '../BuildModule/use-build-module-state';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: 330,
    padding: '40px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    border: `2.75px solid ${colors.WHITE}`
  },
  selected: {
    border: `2.75px solid ${colors.RED}`
  },
  content: {
    width: '100%'
  },
  statusBar: {
    width: '100%',
    backgroundColor: colors.GREY2
  }
});

export default function BuildModuleSimpleCard({
  buildModule,
  onClick,
  selected
}) {
  const classes = useStyles();

  const { buildModuleState } = useBuildModuleState(buildModule);

  const history = useHistory();
  const location = useLocation();

  const { addPageToPageHistory } = useTitan();

  return (
    <Card
      className={classNames({
        [classes.container]: true,
        [classes.selected]: selected
      })}
      onClick={e => {
        if (onClick) {
          onClick(e, buildModule);
        } else {
          addPageToPageHistory({
            id: `BUILD_MODULE:${buildModule.id}`,
            url: ROUTES.BUILD_MODULE_TAB(buildModule.id, 'temperature'),
            label: `${buildModule.name || 'Build Module'} | Temperature`
          });
          history.push({
            pathname: ROUTES.BUILD_MODULE(buildModule.id),
            state: { from: location.pathname }
          });
        }
      }}
    >
      <CardMedia>
        <img src={BuildModuleIcon} alt="Device icon" />
      </CardMedia>
      <CardContent className={classes.content}>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="h2">{buildModule.name}</Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <BuildModuleState buildModule={buildModule} />
            </Grid>
          </Grid>
          {[COMPONENT_STATUSES.BUILDING, COMPONENT_STATUSES.FUSING].includes(
            buildModuleState
          ) && (
            <Grid item style={{ width: '100%' }}>
              <LinearProgress
                className={classes.statusBar}
                variant="determinate"
                value={buildModule.progress}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
