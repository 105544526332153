import axios from 'axios';

export default class PrinterSeriesService {
  static getPrinterSeries(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/printer-series`, {
        ...config,
        params
      })
      .then(res => res.data);
  }

  static getPrinterSeriesById(id, params = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/printer-series/${id}`, {
        params
      })
      .then(res => res.data.data);
  }

  static createPrinterSeries(data) {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/printer-series`, data)
      .then(res => res.data.data);
  }

  static updatePrinterSeries(id, data) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v1/printer-series/${id}`,
        data
      )
      .then(res => res.data.data);
  }

  static deletePrinterSeries(id) {
    return axios
      .delete(`${process.env.REACT_APP_API_URL}/api/v1/printer-series/${id}`)
      .then(res => res.data);
  }
}
