import React from 'react';
import semver from 'semver';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import ResendBuildJobDialog from '../Dialog/ResendBuildJobDialog';
import Alert from '@mui/material/Alert';
import { Typography } from '@mui/material';
import { COMPONENT_STATUSES, BUILD_JOB_ACTION_TYPES } from '../../constants';

export default function BuildJobConfirmationDialogs({
  buildJob,
  currentAction,
  onConfirm,
  onCancel,
}) {
  const supportAutoUnload = React.useMemo(() => {
    if (currentAction === BUILD_JOB_ACTION_TYPES.ON_UNLOAD) {
      const buildModuleVersion = buildJob?.buildModule
        ? buildJob?.buildModule?.buildModuleVersion
        : buildJob?.printer?.buildModuleVersion;

      return (
        buildModuleVersion &&
        semver.valid(buildModuleVersion) &&
        semver.gte(buildModuleVersion, 'v1.11.0')
      );
    } else {
      return false;
    }
  }, [buildJob, currentAction]);

  React.useEffect(() => {
    if (currentAction === BUILD_JOB_ACTION_TYPES.ON_FINISH) {
      onConfirm(buildJob);
    }
  }, [currentAction]);

  return (
    <>
      {currentAction === BUILD_JOB_ACTION_TYPES.ON_UNLOAD && (
        <TitanConfirmationDialog
          title="Unload Build Job"
          message="Are you sure you want to unload this Build Job from Build Module?"
          onConfirm={() => onConfirm(buildJob, !supportAutoUnload)}
          onClose={onCancel}
          confirmLabel={supportAutoUnload ? 'Unload' : 'Force Cancel'}
          cancelLabel="Close"
        >
          {!supportAutoUnload ? (
            <Alert severity="warning">
              <Typography sx={{ mb: 1 }}>
                This Build Module version doesn't support automatic unload of
                the job.
              </Typography>
              <Typography>
                You job can be cancelled if pressed on “Force Cancel” button
                below
              </Typography>
            </Alert>
          ) : (
            ''
          )}
        </TitanConfirmationDialog>
      )}

      {currentAction === BUILD_JOB_ACTION_TYPES.ON_FORCE_UNLOAD && (
        <TitanConfirmationDialog
          title="Force Unload of Build Job"
          message="Are you sure you want to force unload this Build Job from Build Module?"
          onConfirm={() => onConfirm(buildJob, true)}
          onClose={onCancel}
        />
      )}

      {currentAction === BUILD_JOB_ACTION_TYPES.ON_CANCEL && (
        <TitanConfirmationDialog
          title="Cancel Build Job"
          message="Are you sure you want to cancel this Build Job?"
          onConfirm={() => onConfirm(buildJob)}
          onClose={onCancel}
        />
      )}

      {currentAction === BUILD_JOB_ACTION_TYPES.ON_FORCE_CANCEL && (
        <TitanConfirmationDialog
          title="Force Cancel Build Job"
          message="Are you sure you want to force cancel this Build Job?"
          onConfirm={() => onConfirm(buildJob, true)}
          onClose={onCancel}
        />
      )}

      {currentAction === BUILD_JOB_ACTION_TYPES.ON_RESEND && (
        <TitanConfirmationDialog
          title={`${
            buildJob.status === COMPONENT_STATUSES.SCHEDULED
              ? 'Load to BM'
              : 'Resend'
          } Build Job to Build Module`}
          message={`Are you sure you want to ${
            buildJob.status === COMPONENT_STATUSES.SCHEDULED ? 'load' : 'resend'
          } Build Job to Build Module?`}
          onConfirm={() => onConfirm(buildJob)}
          onClose={onCancel}
          fullWidth={true}
          cancelLabel="close"
          confirmLabel={
            buildJob.status === COMPONENT_STATUSES.SCHEDULED
              ? 'Load to BM'
              : 'Resend'
          }
        >
          <ResendBuildJobDialog jobToResend={buildJob} />
        </TitanConfirmationDialog>
      )}

      {currentAction === BUILD_JOB_ACTION_TYPES.ON_SET_AS_BUILT && (
        <TitanConfirmationDialog
          title="Mark the Build Job as built"
          message="Are you sure you want to mark this Build Job as built?"
          onConfirm={() => onConfirm(buildJob)}
          onClose={onCancel}
        />
      )}

      {currentAction === BUILD_JOB_ACTION_TYPES.ON_DEFER_POST_BUILDING && (
        <TitanConfirmationDialog
          title="Defer post building"
          message="Are you sure you want to defer post building?"
          onConfirm={() => onConfirm(buildJob)}
          onClose={onCancel}
        />
      )}

      {currentAction === BUILD_JOB_ACTION_TYPES.ON_SET_BUILD_JOB_OPERATOR && (
        <TitanConfirmationDialog
          title="Set build job operator"
          message="Are you sure you want to set your account as owner of this job?"
          onConfirm={() => onConfirm(buildJob)}
          onClose={onCancel}
        />
      )}

      {currentAction === BUILD_JOB_ACTION_TYPES.ON_PRINT_LABEL && (
        <TitanConfirmationDialog
          title="Print build job label"
          message="Are you sure you want to print build job label ?"
          onConfirm={() => onConfirm(buildJob)}
          onClose={onCancel}
        />
      )}

      {/* {currentAction ===
        BUILD_JOB_ACTION_TYPES.ON_ATTACH_TO_MANUFACTURING_ORDER && (
        <ManufacturingOrderSelectionDialog
          title="Select Manufacturing Order to which you want to connect Build Job"
          filters={{
            buildPlanIds: [buildJob.buildPlan.id]
          }}
          onSave={onConfirm}
          onClose={onCancel}
          {...buildJob.manufacturingOrder && {
            currentSelection: [buildJob.manufacturingOrder]
          }}
        />
      )}

      {currentAction ===
        BUILD_JOB_ACTION_TYPES.ON_DETACH_FROM_MANUFACTURING_ORDER && (
        <TitanConfirmationDialog
          title="Remove Build Job from Manufacturing Order"
          message="Are you sure you want to remove this Build Job from Manufacturing Order?"
          onConfirm={() => onConfirm(buildJob)}
          onClose={onCancel}
        />
      )} */}
    </>
  );
}
