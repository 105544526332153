import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import TitanPage from '../Titan/TitanPage';
import { COMPONENT_STATUSES, SORT_ORDERS } from '../../constants';
import DeviceLogService from '../../services/DeviceLogService';
import BuildModuleLink from '../BuildModule/BuildModuleLink';
import FusionModuleLink from '../FusionModule/FusionModuleLink';
import ComponentStatus from '../Component/ComponentStatus';
import { makeStyles } from '@mui/styles';
import { ROUTES } from '../../constants';
import HistoryFilter from './HistoryFilter';
import HistoryChips from './HistoryChips';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import BuildPlanLink from '../BuildPlan/BuildPlanLink';
import FusionPlanLink from '../FusionPlan/FusionPlanLink';
import Rating from '@mui/material/Rating';
import TitanDuration from '../Titan/TitanDuration';
import TitanRated from '../Titan/TitanRated';
import PreformLink from '../Component/PreformLink';
import FinalPartLink from '../FinalPart/FinalPartLink';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import { RenderTreeCell } from '../TitanDataGrid/TitanGroupingTreeCell';
import FusionJobLink from '../FusionJob/FusionJobLink';
import BuildJobLink from '../BuildJob/BuildJobLink';
import { useTitan } from '../Titan/Titan';

const useStyles = makeStyles((theme) => ({
  name: {
    display: 'flex',
  },
  itemIcon: {
    marginRight: theme.spacing(1),
  },
  device: {
    display: 'flex',
    overflow: 'hidden',
  },
}));

const statusMapping = {
  IN_PROGRESS: [
    COMPONENT_STATUSES.PRE_BUILDING,
    COMPONENT_STATUSES.BUILDING,
    COMPONENT_STATUSES.POST_BUILDING,
    COMPONENT_STATUSES.BUILD_PAUSED,
    COMPONENT_STATUSES.PRE_FUSING,
    COMPONENT_STATUSES.FUSING,
    COMPONENT_STATUSES.POST_FUSING,
    COMPONENT_STATUSES.FUSE_PAUSED,
  ],
  FINISHED: [COMPONENT_STATUSES.BUILT, COMPONENT_STATUSES.FUSED],
  CANCELED: [
    COMPONENT_STATUSES.BUILD_CANCELED,
    COMPONENT_STATUSES.POST_CANCELED,
    COMPONENT_STATUSES.FUSE_CANCELED,
    COMPONENT_STATUSES.DEFECTED,
  ],
};

export default function HistoryPage() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { addPageToPageHistory } = useTitan();

  const { routeItemsType, routeKey, routeValue } = useParams();
  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'History',
        disabled: true,
      },
    ],
    [],
  );

  console.log({ routeItemsType, routeKey, routeValue });

  const [itemsType, setItemsType] = React.useState(routeItemsType || 'all');
  const [search, setSearch] = React.useState(
    routeKey === 'search' ? routeValue : '',
  );
  const [status, setStatus] = React.useState('ALL');
  const [quality, setQuality] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);

  const loadData = React.useCallback(
    async (query, config) => {
      const params = { ...query };

      if (itemsType !== 'all') {
        params.itemsType = itemsType;
      }

      if (search) {
        params.search = search;
      }

      if (pageSize) {
        params.pageSize = pageSize;
      }

      if (quality) {
        params.quality = quality;
      }

      if (status && status !== 'ALL' && statusMapping[status]) {
        params.statuses = statusMapping[status];
      }

      const { data, pagination } = await DeviceLogService.getDeviceLogs(
        params,
        config,
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount,
      };
    },
    [itemsType, quality, status, search, pageSize],
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Plan',
        field: 'plan',
        visibilityBreakpoint: 'sm',
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: ({ row, colDef }) => {
          return row.buildPlan && row.itemType === 'BUILD_JOB' ? (
            <BuildPlanLink
              buildPlan={row.buildPlan}
              width={colDef.computedWidth}
            />
          ) : row.fusionPlan && row.itemType === 'FUSION_JOB' ? (
            <FusionPlanLink
              fusionPlan={row.fusionPlan}
              width={colDef.computedWidth}
            />
          ) : (
            ''
          );
        },
        onCellClick: ({ row }) => {
          if (row.buildPlan) {
            history.push({
              pathname: ROUTES.BUILD_PLAN_V2(row.buildPlan.id),
              state: { from: location.pathname },
            });
          } else if (row.fusionPlan) {
            history.push({
              pathname: ROUTES.FUSION_PLAN_V2(row.fusionPlanId),
              state: { from: location.pathname },
            });
          }
        },
      },
      {
        headerName: 'Device',
        field: 'device',
        visibilityBreakpoint: 'lg',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row, colDef }) =>
          row.buildPlan && row.itemType === 'BUILD_JOB' ? (
            row.printer ? (
              <div className={classes.device}>
                {row.printer ? (
                  <BuildModuleLink
                    buildModule={row.printer}
                    width={colDef.computedWidth}
                  />
                ) : (
                  ''
                )}
              </div>
            ) : (
              '-'
            )
          ) : row.fusionPlan && row.itemType === 'FUSION_JOB' ? (
            row.fusor ? (
              <div className={classes.device}>
                {row.fusor ? (
                  <FusionModuleLink
                    fusionModule={row.fusor}
                    width={colDef.computedWidth}
                  />
                ) : (
                  ''
                )}
              </div>
            ) : (
              '-'
            )
          ) : (
            ''
          ),
        onCellClick: ({ row }) => {
          if (row.printer) {
            history.push({
              pathname: ROUTES.BUILD_MODULE(row.printer.id),
              state: { from: location.pathname },
            });
          } else if (row.fusor) {
            history.push({
              pathname: ROUTES.FUSION_MODULE(row.fusor.id),
              state: { from: location.pathname },
            });
          }
        },
      },
      {
        headerName: 'Status',
        field: 'status',
        visibilityBreakpoint: 'sm',
        minWidth: 210,
        sortable: false,
        renderCell: ({ row }) =>
          row.fusionPlan || row.buildPlan ? (
            <ComponentStatus component={row} />
          ) : (
            ''
          ),
      },
      {
        headerName: 'Time',
        field: 'time',
        visibilityBreakpoint: 'lg',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) =>
          row.buildPlan ? (
            <TitanTimeAgo
              time={COMPONENT_STATUSES.SCHEDULED ? row.createdAt : null}
              start={row.startBuildTime}
              end={row.endBuildTime}
              status={row.status}
            />
          ) : row.fusionPlan ? (
            <TitanTimeAgo
              time={COMPONENT_STATUSES.SCHEDULED ? row.createdAt : null}
              start={row.startFuseTime}
              end={row.endFuseTime}
              status={row.status}
            />
          ) : (
            ''
          ),
      },
      {
        headerName: 'Quality',
        field: 'quality',
        visibilityBreakpoint: 'lg',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) =>
          row.quality ? <Rating value={row.quality} readOnly /> : '',
      },
      {
        headerName: 'Rated',
        field: 'rated',
        visibilityBreakpoint: 'lg',
        minWidth: 170,
        sortable: false,
        renderCell: ({ row }) =>
          row.buildPlan ? <TitanRated status={row.rate} /> : '',
      },
      {
        headerName: 'Duration',
        field: 'duration',
        visibilityBreakpoint: 'lg',
        minWidth: 90,
        sortable: false,
        renderCell: ({ row }) =>
          row.buildPlan ? <TitanDuration duration={row.buildTime} /> : '',
      },
    ],
    [],
  );

  const groupingColDef = React.useMemo(
    () => ({
      headerName: 'Preform/Part ID',
      field: 'item',
      visibilityBreakpoint: 'sm',
      hideable: false,
      pinnable: true,
      minWidth: 190,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.row.itemType === 'FUSION_JOB' ? (
          <RenderTreeCell {...params}>
            {(row, colDef) => (
              <FusionJobLink fusionJob={row} width={colDef.computedWidth} />
            )}
          </RenderTreeCell>
        ) : params.row.itemType === 'BUILD_JOB' ? (
          <RenderTreeCell {...params}>
            {(row, colDef) => (
              <BuildJobLink buildJob={row} width={colDef.computedWidth} />
            )}
          </RenderTreeCell>
        ) : params.row.buildPlan ? (
          <RenderTreeCell {...params}>
            {(row, colDef) => (
              <PreformLink preform={row} width={colDef.computedWidth} />
            )}
          </RenderTreeCell>
        ) : (
          <RenderTreeCell {...params}>
            {(row, colDef) => (
              <FinalPartLink finalPart={row} width={colDef.computedWidth} />
            )}
          </RenderTreeCell>
        );
      },
      onCellClick: ({ row }) => {
        if (row.buildPlan && row.itemType !== 'BUILD_JOB') {
          history.push({
            pathname: ROUTES.PREFORM(row.id),
            state: { from: location.pathname },
          });
        } else if (row.fusionPlan && row.itemType !== 'FUSION_JOB') {
          history.push({
            pathname: ROUTES.FINAL_PART(row.id),
            state: { from: location.pathname },
          });
        }
      },
    }),
    [],
  );

  const gridOptions = React.useMemo(() => {
    const gridOptions = {
      orders: { created_at: SORT_ORDERS.DESC },
      columns,
      groupingColumnInfo: {
        groupingColumn: groupingColDef,
        childrenArrays: ['assemblies', 'components'],
      },
      pinnedColumns: {
        left: ['item'],
        right: [],
      },
      onChangePage: (page) => {
        history.push(ROUTES.HISTORY('page', page));
      },
    };

    if (routeKey === 'page' && Number(routeValue) > 0) {
      gridOptions.page = Number(routeValue);
    }

    if (routeKey === 'search' && routeValue) {
      gridOptions.search = routeValue;
    } else if (queryParams.has('search')) {
      gridOptions.search = queryParams.get('search');
    }

    return gridOptions;
  }, [itemsType, routeKey, routeValue, columns, groupingColDef, queryParams]);

  const titanDataGridProps = useTitanDataGrid(loadData, gridOptions);

  const { page } = titanDataGridProps;

  const onChangeItemsType = (value) => {
    setItemsType(value);

    history.push(ROUTES.HISTORY('page', 0));
  };

  const onChangeSearch = (value) => {
    setSearch(value);

    history.push({
      pathname: location.pathname,
      search: value ? `?search=${value}` : '',
    });
  };

  const onChangeStatus = (status) => {
    setStatus(status);
  };

  const onChangeQuality = (quality) => {
    setQuality(quality);
  };

  React.useEffect(() => {
    addPageToPageHistory({
      id: `HISTORY`,
      url: ROUTES.HISTORY('page', page),
      label: page === 0 ? `History` : `History | Page : ${page + 1}`,
    });
  }, [page]);

  React.useEffect(() => {
    if (queryParams.get('search') !== search) {
      setSearch(queryParams.get('search'));
    }
  }, [search, location.search]);

  React.useEffect(() => {
    if (routeKey === 'search') {
      setSearch(routeValue);
    }
  }, [routeKey, routeValue]);

  React.useEffect(() => {
    if (routeItemsType) {
      setItemsType(routeItemsType);
    }
  }, [routeItemsType]);

  return (
    <TitanPage title="History" breadcrumbs={breadcrumbs}>
      <TitanDataGrid
        treeData={true}
        components={{
          Toolbar: TitanDataGridToolbar,
        }}
        {...titanDataGridProps}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        pageSize={pageSize}
        setPageSize={setPageSize}
        searchPlaceholder="Search by preform/part ID"
        search={search}
        setSearch={onChangeSearch}
        filtersContent={
          <HistoryFilter
            itemsType={itemsType}
            onChangeItemsType={onChangeItemsType}
            quality={quality}
            onChangeQuality={onChangeQuality}
            status={status}
            onChangeStatus={onChangeStatus}
          />
        }
        chipsContent={
          <HistoryChips
            itemsType={itemsType}
            onChangeItemsType={onChangeItemsType}
            quality={quality}
            onChangeQuality={onChangeQuality}
            status={status}
            onChangeStatus={onChangeStatus}
          />
        }
        defaultGroupingExpansionDepth={1}
      />
    </TitanPage>
  );
}
