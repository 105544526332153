import React from 'react';
import { useAuth0 } from '../Authentication/Auth0';
import FibrifyItemsSelectionDialog from './FibrifyItemsSelectionDialog';
import BuildPlanService from '../../services/BuildPlanService';
import BuildPlanThumbnail from '../BuildPlan/BuildPlanThubnail';
import BuildPlanLink from '../BuildPlan/BuildPlanLink';
import { STYLING_FUNCTIONS } from '../../constants';
import TitanTimeAgo from '../Titan/TitanTimeAgo';

export default function FibrifyBuildPlanSelectionDialog({
  title,
  multipleSelection = false,
  filters = {},
  currentBuildPlans = [],
  onSave,
  saveButtonLabel = 'Confirm',
  onClose,
  isRowSelectable
}) {
  const { profile } = useAuth0();

  const loadData = React.useCallback(
    async ({ page, pageSize, search }, config) => {
      const params = {
        page: page,
        pageSize: pageSize,
        withRelated: ['files', 'tags'],
        order: ['created_at:desc'],
        ...filters
      };

      if (search) {
        params.search = search;
      }

      const { data, pagination } = await BuildPlanService.getBuildPlans(
        params,
        'v2',
        config
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount
      };
    },
    [profile]
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Preview',
        field: 'preview',
        minWidth: 140,
        sortable: false,
        visibilityBreakpoint: 'sm',
        renderCell: ({ row }) => <BuildPlanThumbnail buildPlan={row} />
      },
      {
        headerName: 'Name',
        field: 'name',
        hideable: false,
        flex: 1,
        minWidth: 200,
        sortable: false,
        visibilityBreakpoint: 'sm',
        renderCell: ({ row, colDef }) => (
          <BuildPlanLink buildPlan={row} width={colDef.computedWidth} />
        )
      },
      {
        headerName: 'Tags',
        field: 'tags',
        minWidth: 300,
        sortable: false,
        visibilityBreakpoint: 'lg',
        valueGetter: ({ row }) => (row.tags ? row.tags.join(', ') : '')
      },
      {
        headerName: 'Version',
        field: 'version',
        minWidth: 50,
        visibilityBreakpoint: 'lg',
        sortable: false
      },
      {
        headerName: 'Uploaded',
        field: 'uploaded',
        minWidth: 150,
        sortable: false,
        visibilityBreakpoint: 'sm',
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />
      },
      {
        headerName: 'Share Type',
        field: 'shareType',
        minWidth: 150,
        visibilityBreakpoint: 'lg',
        sortable: false
      }
    ],
    []
  );

  return (
    <FibrifyItemsSelectionDialog
      onClose={onClose}
      selectedItems={currentBuildPlans}
      loadData={loadData}
      columns={columns}
      title={title}
      multipleSelection={multipleSelection}
      onSave={onSave}
      saveButtonLabel={saveButtonLabel}
      searchPlaceholder="Search by Build Plan name..."
      getRowHeight={STYLING_FUNCTIONS.MAKE_ROW_HEIGHT(110)}
      isRowSelectable={isRowSelectable}
    />
  );
}
