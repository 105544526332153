import React from 'react';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import { useTitan } from '../Titan/Titan';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { useAuth0 } from '../Authentication/Auth0';
import { Tooltip } from '@mui/material';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import { useResponsiveQueries } from '../../hooks/useResponsiveQueries';
import { setColumnsVisibilityModel } from '../../utils/setColumnsVisibilityModel';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { CustomGridColumnsPanel } from '../TitanDataGrid/TitanDataGridColumnsPanel';

import defaultFinalPartTypeCustomFields from '../../constants/defaultFinalPartTypeCustomFields';
import { useFusionPlan } from './FusionPlanPage';
import FusionPlanService from '../../services/FusionPlanService';
import FinalPartTypeDialog from './FinalPartTypeDialog';

export default function FusionPlanFinalPartTypes() {
  const { fusionPlan, updateFusionPlan, hasFinalParts } = useFusionPlan();

  const { currentMemberId } = useAuth0();
  const isOwner = fusionPlan?.memberId === currentMemberId;

  const { matchesLG } = useResponsiveQueries();

  const apiRef = useGridApiRef();

  React.useEffect(() => {
    const columnsVisibility = setColumnsVisibilityModel(
      columns,
      'iPadVisible',
      matchesLG,
    );
    apiRef.current.setColumnVisibilityModel(columnsVisibility);
  }, [apiRef, matchesLG]);

  const { pushSnackbar } = useTitan();

  const [openFinalPartDialog, setOpenFinalPartDialog] = React.useState(false);
  const [editableFinalPartType, setEditableFinalPartType] = React.useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [finalPartTypes, setFinalPartTypes] = React.useState([]);

  React.useEffect(() => {
    setFinalPartTypes(
      fusionPlan && fusionPlan.finalPartTypes
        ? [...fusionPlan.finalPartTypes].sort((a, b) => a.order - b.order)
        : [],
    );
  }, [fusionPlan]);

  const onDeleteFinalPartType = async () => {
    await updateFusionPlan({
      finalPartTypes: fusionPlan.finalPartTypes.filter(
        (pt) => pt.id !== editableFinalPartType.id,
      ),
    });

    pushSnackbar('Final part type successfully deleted', {
      variant: 'success',
    });
    setOpenDeleteDialog(false);
  };

  const onSaveFinalPartType = React.useCallback(
    async (finalPartType) => {
      let updatedFinalPartTypes;

      if (!editableFinalPartType.id) {
        updatedFinalPartTypes = [
          ...finalPartTypes,
          { ...finalPartType, order: finalPartTypes.length },
        ];
      } else {
        updatedFinalPartTypes = fusionPlan.finalPartTypes.map((pt) => {
          if (pt.id === editableFinalPartType.id) {
            return {
              ...editableFinalPartType,
              ...finalPartType,
            };
          }

          return pt;
        });
      }

      await updateFusionPlan({
        finalPartTypes: updatedFinalPartTypes,
      });

      pushSnackbar('Final part type successfully saved', {
        variant: 'success',
      });
      setOpenFinalPartDialog(false);
    },
    [fusionPlan, editableFinalPartType, finalPartTypes],
  );

  let onCreateClickDisabledTooltip;

  if (!isOwner) {
    onCreateClickDisabledTooltip = `Only Fusion Plan owner can add new final part types`;
  }

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        iPadVisible: true,
        hideable: false,
        minWidth: 300,
        flex: 1,
        sortable: false,
      },
      {
        headerName: 'Labels',
        field: 'labels',
        iPadVisible: true,
        minWidth: 200,
        flex: 1,
        sortable: false,
      },
      {
        headerName: 'Custom fields',
        field: 'customFields',
        iPadVisible: false,
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) =>
          row.customFields
            .sort((a, b) => a.order - b.order)
            .map((cf) => cf.name)
            .join(', '),
      },
      {
        field: 'actions',
        type: 'actions',
        hideInMenu: true,
        hideable: false,
        iPadVisible: true,
        width: 60,
        sortable: false,
        getActions: (params) =>
          FusionPlanService.getFinalPartTypesActions(
            () => {
              setEditableFinalPartType(params.row);
              setOpenFinalPartDialog(true);
            },
            () => {
              setEditableFinalPartType(params.row);
              setOpenDeleteDialog(true);
            },
            hasFinalParts,
            isOwner,
          ).map((action) =>
            action.disabled ? (
              <Tooltip
                title={action.tooltipText}
                showInMenu
                placement="left-start"
              >
                <span>
                  <GridActionsCellItem
                    icon={action.icon}
                    label={action.label}
                    onClick={action.onClick}
                    showInMenu
                    disabled={action.disabled}
                  />
                </span>
              </Tooltip>
            ) : (
              <GridActionsCellItem
                icon={action.icon}
                label={action.label}
                onClick={action.onClick}
                showInMenu
              />
            ),
          ),
      },
    ],
    [hasFinalParts],
  );

  const onChangeFinalPartTypeOrder = React.useCallback(
    async ({ oldIndex, targetIndex }) => {
      const orderedFinalPartTypes = [...finalPartTypes];
      const row = orderedFinalPartTypes.splice(oldIndex, 1)[0];
      orderedFinalPartTypes.splice(targetIndex, 0, row);

      await updateFusionPlan({
        finalPartTypes: orderedFinalPartTypes.map((finalPartType, order) => ({
          ...finalPartType,
          order,
        })),
      });
    },
    [finalPartTypes, updateFusionPlan],
  );

  return (
    <>
      <TitanDataGrid
        apiRef={apiRef}
        components={{
          Toolbar: TitanDataGridToolbar,
          ColumnsPanel: CustomGridColumnsPanel,
        }}
        title="Final Part Types"
        rows={finalPartTypes}
        onCreateClick={() => {
          setEditableFinalPartType({
            customFields: defaultFinalPartTypeCustomFields,
          });
          setOpenFinalPartDialog(true);
        }}
        onCreateClickDisabled={!isOwner}
        onCreateClickDisabledTooltip={onCreateClickDisabledTooltip}
        createButtonLabel="Final Part Type"
        columns={columns}
        initialState={{
          columns: {
            columnVisibilityModel: {
              ...setColumnsVisibilityModel(columns, 'iPadVisible', matchesLG),
            },
          },
        }}
        pagination={false}
        showSearch={false}
        rowCount={finalPartTypes.length}
        rowReordering
        onRowOrderChange={onChangeFinalPartTypeOrder}
      />

      {openFinalPartDialog && (
        <FinalPartTypeDialog
          finalPartType={editableFinalPartType}
          onClose={() => {
            setOpenFinalPartDialog(false);
          }}
          onSave={onSaveFinalPartType}
          hasFinalParts={hasFinalParts}
        />
      )}

      {openDeleteDialog && (
        <TitanConfirmationDialog
          title="Delete Final Part Type?"
          message={`Are you sure you want to delete Final Part Type: ${editableFinalPartType.name}?`}
          onClose={() => setOpenDeleteDialog(false)}
          onConfirm={() => onDeleteFinalPartType()}
        />
      )}
    </>
  );
}
