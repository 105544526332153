import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import TitanEditableText from '../Titan/TitanEditableText';
import TitanEditableSelect from '../Titan/TitanEditableSelect';
import Stack from '@mui/material/Stack';
import FibrifyMeasurement from '../Fibrify/FibrifyMeasurement';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import { Typography } from '@mui/material';
import { toNumber } from 'lodash';
import { colors } from '../Theme/vars';

export default function EditableCustomField({
  customField,
  value,
  onChange,
  enableEditing = true,
}) {
  const customFieldUnits = customField.units
    ? customField.units.toLowerCase()
    : '';

  const expectedValue = customField.expectedValue;
  const minValue = customField.minValue;
  const maxValue = customField.maxValue;

  let showWarning = false;
  let difference = null;

  if (customField.type === 'NUMBER') {
    if (value && (minValue || maxValue)) {
      showWarning = value < minValue || value > maxValue;
    }

    if (expectedValue && value) {
      const expectedValueNumber = toNumber(expectedValue.replace(/\D+$/g, ''));
      const valueNumber = toNumber(value.replace(/\D+$/g, ''));

      const expectedValueString = expectedValueNumber
        ? parseFloat(expectedValueNumber).toString()
        : '';
      const valueString = valueNumber ? parseFloat(valueNumber).toString() : '';

      const expectedValueParts = expectedValueString.split('.');
      const valueParts = valueString.split('.');

      const countExpectedDecimals =
        expectedValueParts.length === 2 ? expectedValueParts[1].length : 0;
      const countValueDecimals =
        valueParts.length === 2 ? valueParts[1].length : 0;

      const decimalExpectedMultiplicator =
        10 ** Math.min(4, countExpectedDecimals);
      const decimalValueMultiplicator = 10 ** Math.min(4, countValueDecimals);
      const decimalMultiplicator = Math.max(
        decimalExpectedMultiplicator,
        decimalValueMultiplicator,
      );

      difference =
        Math.round((valueNumber - expectedValueNumber) * decimalMultiplicator) /
        decimalMultiplicator;
    }
  }

  const textField = (
    <Stack>
      <TitanEditableText
        enableEditing={enableEditing}
        text={value}
        label={customField.name}
        onChangeValue={onChange}
        suffix={customFieldUnits}
        inputType={customField.type}
      />

      {expectedValue ? (
        <Stack direction="row">
          <FibrifyMeasurement
            label="Expected"
            value={expectedValue}
            units={customFieldUnits}
          />
          {minValue || maxValue ? (
            <Typography
              variant="caption"
              component="span"
              sx={{ ml: 1 }}
              color={showWarning ? colors.ORANGE2 : ''}
            >
              (Min: {minValue || '-'} Max: {maxValue || '-'})
            </Typography>
          ) : (
            ''
          )}
        </Stack>
      ) : (
        ''
      )}

      {difference !== null ? (
        <FibrifyMeasurement
          label="Difference"
          value={`${difference > 0 ? '+' : ''}${difference}`}
          units={customFieldUnits}
        />
      ) : (
        ''
      )}
    </Stack>
  );

  const booleanField = (
    <Stack>
      <FormControl>
        <RadioGroup
          row
          value={value ? value : ''}
          onChange={(e) => onChange(e.target.value)}
        >
          <FormControlLabel
            value="true"
            control={<Radio />}
            label="Yes"
            disabled={!enableEditing}
          />
          <FormControlLabel
            value="false"
            control={<Radio />}
            label="No"
            disabled={!enableEditing}
          />
        </RadioGroup>
      </FormControl>
      <Typography variant="caption">
        Expected:
        <Typography variant="body1" component="span" sx={{ ml: 1 }}>
          {expectedValue === 'true' ? 'Yes' : 'No'}
        </Typography>
      </Typography>
    </Stack>
  );

  const selectField = (
    <Stack>
      <TitanEditableSelect
        enableEditing={enableEditing}
        text={value}
        inputType={customField.type}
        label={customField.name}
        onChangeValue={onChange}
        options={customField.options}
      />
      {expectedValue && (
        <Typography variant="caption">
          Expected:
          <Typography variant="body1" component="span" sx={{ ml: 1 }}>
            {expectedValue}
          </Typography>
        </Typography>
      )}
    </Stack>
  );

  let field = textField;

  if (customField.type === 'BOOLEAN') {
    field = booleanField;
  } else if (
    customField.type === 'SINGLE_SELECT' ||
    customField.type === 'MULTI_SELECT'
  ) {
    field = selectField;
  }

  return field;
}
