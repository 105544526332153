import React from 'react';
import { IconButton, Stack } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTitan } from '../Titan/Titan';
import Typography from '@mui/material/Typography';

export default function CopyToClipboard({ text, copyValue, label }) {
  const { copyValueToClipboard } = useTitan();

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography>{text}</Typography>
      <IconButton onClick={() => copyValueToClipboard(copyValue, label)}>
        <ContentCopyIcon />
      </IconButton>
    </Stack>
  );
}
