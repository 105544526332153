import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTitan } from '../Titan/Titan';
import { ROUTES, ROLES, SORT_ORDERS } from '../../constants';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { useAuth0 } from '../Authentication/Auth0';
import { PERMISSIONS } from '../../constants/auth0';
import PresetService from '../../services/PresetService';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PresetLink from './PresetLink';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import PresetDialog from './PresetsDialog';
import PreformsChips from '../Preforms/PreformsChips';
import { PRESET_COMPLEXITIES } from './PresetChips';

export default function PresetsPage() {
  const { currentMemberId } = useAuth0();

  const history = useHistory();
  const location = useLocation();
  const { page: pageParam = 0 } = useParams();
  const { pushSnackbar, addPageToPageHistory, backToPreviousPage } = useTitan();
  const { permissions, checkPermissions, roles } = useAuth0();

  const [openPresetDialog, setOpenPresetDialog] = React.useState(false);
  const [editablePreset, setEditablePreset] = React.useState(undefined);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [complexity, setComplexity] = React.useState(PRESET_COMPLEXITIES.BASIC);
  const loadData = React.useCallback(async (query, config) => {
    const { data, pagination } = await PresetService.getPresets(query, config);

    return {
      data,
      page: pagination.page - 1,
      totalCount: pagination.totalCount
    };
  }, []);

  const canManagePresets = React.useMemo(
    () => checkPermissions(permissions, [PERMISSIONS.WRITE_PRESETS]),
    [permissions]
  );

  const columns = React.useMemo(() => {
    const columns = [
      {
        headerName: 'Name',
        field: 'name',
        pinnable: true,
        hideable: false,
        visibilityBreakpoint: 'sm',
        flex: 1,
        minWidth: 180,
        sortable: false,
        renderCell: ({ row, colDef }) => (
          <PresetLink preset={row} width={colDef.computedWidth} />
        ),
        onCellClick: ({ row }) => {
          if (row) {
            addPageToPageHistory({
              id: 'PRESET' + row.id,
              url: ROUTES.PRESET(row.id),
              label: `${row.name}`
            });
            history.push({
              pathname: ROUTES.PRESET(row.id),
              state: { from: location.pathname }
            });
          }
        }
      }
    ];

    if (
      roles.map(r => r.id).includes(ROLES.ADMIN) ||
      roles.map(r => r.id).includes(ROLES.APPLICATION_ENGINEER)
    ) {
      columns.push({
        headerName: '',
        field: 'actions',
        type: 'actions',
        visibilityBreakpoint: 'sm',
        hideable: false,
        sortable: false,
        width: 60,
        getActions: params =>
          [
            {
              label: 'Edit',
              icon: <EditIcon />,
              disabled: !canManagePresets,
              onClick: () => {
                setEditablePreset(params.row);
                setOpenPresetDialog(true);
              }
            },
            {
              label: 'Delete',
              icon: <DeleteIcon />,
              disabled: !canManagePresets,
              onClick: () => {
                setEditablePreset(params.row);
                setOpenDeleteDialog(true);
              }
            }
          ].map(action => (
            <GridActionsCellItem
              icon={action.icon}
              label={action.label}
              onClick={action.onClick}
              disabled={action.disabled}
              showInMenu
            />
          ))
      });
    }

    return columns;
  }, [canManagePresets]);

  const titanDataGridProps = useTitanDataGrid(loadData, {
    columns,
    pinnedColumns: {
      left: ['name'],
      right: ['actions']
    }
  });
  const { setRows } = titanDataGridProps;

  const gridOptions = {
    orders: {
      created_at: SORT_ORDERS.DESC
    }
  };

  if (pageParam && Number(pageParam) > 0) {
    gridOptions.page = Number(pageParam);
  }

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        searchPlaceholder="Search by Fusion Plan name..."
        onCreateClick={() => {
          setOpenPresetDialog(true);
        }}
        onCreateClickDisabled={!canManagePresets}
        onCreateClickDisabledTooltip={
          !canManagePresets ? "You don't have rights to create a Preset" : ''
        }
        createButtonLabel="Preset"
        columns={columns}
        chipsContents={
          <PreformsChips
            complexity={complexity}
            setBuildPlanVersionType={setComplexity}
          />
        }
      />
      {openDeleteDialog && (
        <TitanConfirmationDialog
          title="Delete preset?"
          message={`Are you sure you want to delete preset ${
            editablePreset.name
          }?`}
          onClose={() => {
            setOpenDeleteDialog(false);
            setEditablePreset(undefined);
          }}
          onConfirm={async () => {
            if (await PresetService.deletePreset(editablePreset.id)) {
              pushSnackbar('Preset deleted successfully', {
                variant: 'success'
              });
              setRows(prev => prev.filter(pr => pr.id !== editablePreset.id));
              return;
            }
            pushSnackbar('Cannot delete preset', {
              variant: 'error'
            });
            setOpenDeleteDialog(false);
            setEditablePreset(undefined);
            backToPreviousPage(location);
          }}
        />
      )}
      {openPresetDialog && (
        <PresetDialog
          preset={editablePreset}
          onClose={() => {
            setOpenPresetDialog(false);
            setEditablePreset(undefined);
          }}
          userId={currentMemberId}
          onSave={async params => {
            if (editablePreset) {
              const updatedPresetParameters = {
                id: editablePreset.id,
                name: params.name,
                entries: editablePreset.Entries,
                fiberMaterialTypeId: params.fiberMaterialTypeId,
                polymerMaterialTypeId: params.polymerMaterialTypeId
              };
              const updatedPreset = await PresetService.updatePreset(
                updatedPresetParameters
              );
              if (updatedPreset === false) {
                pushSnackbar('Preset update failed', {
                  variant: 'error'
                });
              } else {
                setRows(prev => [
                  ...prev.filter(prst => prst.id !== editablePreset.id),
                  updatedPreset
                ]);
                pushSnackbar('Preset updated successfully', {
                  variant: 'success'
                });
              }
              setEditablePreset(undefined);
            } else {
              const newPresetParams = {
                name: params.name
              };
              if (params.fiberMaterialTypeId !== '') {
                newPresetParams.fiberMaterialTypeId =
                  params.fiberMaterialTypeId;
              }
              if (params.polymerMaterialTypeId !== '') {
                newPresetParams.polymerMaterialTypeId =
                  params.polymerMaterialTypeId;
              }
              const newPreset = (await PresetService.addPreset(newPresetParams))
                .data;
              setRows(prev => [...prev, newPreset]);
              pushSnackbar(`Preset added: ${newPreset.name}`, {
                variant: 'success'
              });
            }
          }}
        />
      )}
    </>
  );
}
