import moment from 'moment';
import { COMPONENT_ACTIVE_STATUSES, COMPONENT_STATUSES } from '../../constants';
import Typography from '@mui/material/Typography';
import React from 'react';

export default function TitanTimeAgo({
  time,
  start,
  end,
  status,
  emptyValue = ''
}) {
  const [timeFormat, setTimeFormat] = React.useState('fromNow');

  let normalizedTime;

  if (time) {
    normalizedTime = time;
  } else if (COMPONENT_ACTIVE_STATUSES.includes(status)) {
    normalizedTime = start;
  } else if (
    [COMPONENT_STATUSES.BUILT, COMPONENT_STATUSES.FUSED].includes(status)
  ) {
    normalizedTime = end;
  } else if (
    [
      COMPONENT_STATUSES.BUILD_CANCELED,
      COMPONENT_STATUSES.FUSE_CANCELED
    ].includes(status)
  ) {
    normalizedTime = end;
  }

  return normalizedTime ? (
    <Typography
      sx={{ cursor: 'pointer' }}
      onClick={() =>
        setTimeFormat(timeFormat === 'fromNow' ? 'format' : 'fromNow')
      }
    >
      {timeFormat === 'fromNow'
        ? moment(normalizedTime).fromNow()
        : moment(normalizedTime).format('YYYY-MM-DD HH:mm:ss')}
    </Typography>
  ) : (
    emptyValue
  );
}
