import React from 'react';
import TitanPage from '../Titan/TitanPage';
import { ROUTES, WEB_SOCKET_ACTIONS } from '../../constants';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import ComponentSummary from './ComponentSummary';
import ComponentService from '../../services/ComponentService';
import TitanTabs from '../Titan/TitanTabs';
import PreformPhotos from './PreformPhotos';
import PreformStates from '../Preform/PreformStates';
import PreformActions from '../Preform/PreformActions';
import useWebSocket from 'react-use-websocket';
import BuildJobTemperatures from '../BuildJob/BuildJobTemperatures';
import { useTitan } from '../Titan/Titan';
import PreformService from '../../services/PreformService';

export default function PreformPage() {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [preform, setPreform] = React.useState();
  const history = useHistory();
  const location = useLocation();
  const { preformId, tab = 'summary' } = useParams();

  const {
    addPageToPageHistory,
    backToPreviousPage,
    getWebSocketUrl,
    getWebSocketOptions,
    pushSnackbar,
  } = useTitan();

  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Build Plans',
        path: ROUTES.BUILD_PLANS('page', 0),
      },
      {
        name: preform?.buildPlan?.name || 'Build Plan',
        path: ROUTES.BUILD_PLAN_V2_TAB(preform?.buildPlan?.id, 'configuration'),
      },
      {
        name: preform
          ? preform.preformKey
            ? preform.preformKey
            : `Preform ${preform.id}`
          : '',
        disabled: true,
      },
    ],
    [preform],
  );

  React.useEffect(() => {
    setLoading(true);
    ComponentService.getComponent(preformId, {
      withRelated: [
        'buildJob',
        'buildJob.printHead',
        'printer',
        'buildPlan',
        'assembly',
        'photos',
        'preformType',
        'preformType.customFields',
        'customFields',
        'manufacturingOrder',
      ],
    })
      .then((assembly) => {
        setPreform(assembly);
        setError(null);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });
  }, [preformId]);

  const { lastJsonMessage } = useWebSocket(
    getWebSocketUrl,
    getWebSocketOptions(),
  );

  React.useEffect(() => {
    if (
      preformId &&
      lastJsonMessage !== null &&
      lastJsonMessage.action === WEB_SOCKET_ACTIONS.PREFORM &&
      lastJsonMessage.data &&
      preformId === lastJsonMessage.data.id
    ) {
      setPreform({ ...preform, ...lastJsonMessage.data });
    }
  }, [lastJsonMessage, preformId]);

  const tabs = React.useMemo(
    () => [
      {
        label: 'Summary',
        value: 'summary',
        content: preform ? (
          <ComponentSummary component={preform} setComponent={setPreform} />
        ) : (
          ''
        ),
      },
      {
        label: 'Build History Temperatures',
        value: 'buildHistoryTemperatures',
        content: preform ? (
          <BuildJobTemperatures
            buildModuleId={preform.printerId}
            buildJobId={preform.buildJobId}
            startBuildTime={preform.startBuildTime}
            endBuildTime={preform.endBuildTime}
          />
        ) : (
          ''
        ),
      },
      {
        label: 'States History',
        value: 'states',
        content: preform ? <PreformStates preform={preform} /> : '',
      },
      {
        label: 'Photos',
        value: 'photos',
        content: preform ? (
          <PreformPhotos preform={preform} setPreform={setPreform} />
        ) : (
          ''
        ),
      },
    ],
    [preform],
  );

  const preparePageObject = (tab, tabForURL) => {
    return {
      id: `PREFORM:${preformId}`,
      url: ROUTES.PREFORM_TAB(preform.id, tabForURL),
      label: `${preform.preformKey || `Preform: ${preform.id}`} | ${tab}`,
    };
  };

  const onPrintLabel = React.useCallback(async () => {
    try {
      const preformLabel = await PreformService.printLabel(preform.id);

      pushSnackbar(
        `Printing label${
          preformLabel.labelPrinter
            ? ` on ${preformLabel.labelPrinter.name}`
            : ''
        }`,
        {
          variant: 'success',
        },
      );
    } catch (e) {
      pushSnackbar(`Can't print label`, { variant: 'error' });
    }
  }, [preform, pushSnackbar]);

  return (
    <TitanPage
      loading={loading}
      error={error}
      title={
        preform
          ? preform.preformKey
            ? preform.preformKey
            : `Preform ${preform.id}`
          : ''
      }
      breadcrumbs={breadcrumbs}
      onBackButtonClick={() => {
        backToPreviousPage(location);
      }}
      headerContent={
        <>
          <PreformActions
            preform={preform}
            onBuilt={async (preform) => {
              await ComponentService.setAsBuilt(preform.id);
            }}
            onMarkAsDefected={async (preform) => {
              await ComponentService.markAsDefected(preform.id);
            }}
            onPrintLabel={onPrintLabel}
          />
        </>
      }
    >
      {preform && (
        <TitanTabs
          tabs={tabs}
          activeTab={tab}
          onChangeTab={(value) => {
            const activeLabel = tabs.find((tab) => tab.value === value)?.label;
            addPageToPageHistory(preparePageObject(activeLabel, value));
            history.push(ROUTES.PREFORM_TAB(preformId, value));
          }}
        />
      )}
    </TitanPage>
  );
}
