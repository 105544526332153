import TitanPopover from '../Titan/TitanPopover';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Checkbox } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import React from 'react';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

export default function UserListFilter({
  roles,
  sources,
  filters,
  onChangeFilters
}) {
  const handleSourcesChange = e => {
    let updatedSources;

    if (e.target.checked) {
      updatedSources = [...filters.provider, e.target.value];
    } else {
      updatedSources = filters.provider.filter(s => s !== e.target.value);
    }

    onChangeFilters('provider', updatedSources);
  };

  const handleRolesChange = e => {
    let updatedRoles;

    if (e.target.checked) {
      updatedRoles = [...filters.roles, e.target.value];
    } else {
      updatedRoles = filters.roles.filter(s => s !== e.target.value);
    }

    onChangeFilters('roles', updatedRoles);
  };

  return (
    <TitanPopover icon={FilterListIcon}>
      <Box width={400} p={2}>
        <Grid container>
          <Grid item xs={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Sources</FormLabel>
              <FormGroup>
                {Object.entries(sources).map(([key, value]) => (
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        checked={filters.provider.indexOf(key) > -1}
                        onChange={handleSourcesChange}
                        value={key}
                      />
                    }
                    label={value}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Roles</FormLabel>
              <FormGroup>
                {Object.entries(roles).map(([key, value]) => (
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        checked={filters.roles.indexOf(key) > -1}
                        onChange={handleRolesChange}
                        value={key}
                      />
                    }
                    label={value}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </TitanPopover>
  );
}
