import React from 'react';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import { ROUTES, SORT_ORDERS } from '../../constants';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import SpoolService from '../../services/SpoolService';
import MaterialLink from '../Materials/MaterialLink';
import Rating from '@mui/material/Rating';
import SpoolLink from './SpoolLink';
import SpoolDialog from './SpoolDialog';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import SpoolChips from './SpoolChips';
import SpoolFilter from './SpoolFilter';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { CustomGridColumnsPanel } from '../TitanDataGrid/TitanDataGridColumnsPanel';
import { useTitan } from '../Titan/Titan';
import BuildIcon from '@mui/icons-material/Build';
import FibrifyBuildModuleSelectionDialog from '../Fibrify/FibrifyBuildModuleSelectionDialog';
import BuildModuleService from '../../services/BuildModuleService';

export default function SpoolsTable({
  apiRef,
  manufacturingOrderIds,
  title = 'Spools',
  createButtonLabel = 'Spool',
  extraActions,
  extraColumns,
}) {
  const { pushSnackbar } = useTitan();

  const history = useHistory();
  const location = useLocation();
  const { routeKey, routeValue } = useParams();

  const [openSpoolDialog, setOpenSpoolDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [editableSpool, setEditableSpool] = React.useState(null);

  const [selectedMaterialType, setSelectedMaterialType] = React.useState('all');
  const [selectedSpoolType, setSelectedSpoolType] = React.useState('all');
  const [spoolToLoadToBM, setSpoolToLoadToBM] = React.useState(null);

  const onChangeMaterialType = (value) => {
    setSelectedMaterialType(value);
  };

  const onChangeSpoolType = (value) => {
    setSelectedSpoolType(value);
  };

  const loadData = React.useCallback(
    async (params, config) => {
      if (selectedMaterialType !== 'all') {
        params.material = selectedMaterialType;
      }

      if (selectedSpoolType !== 'all') {
        params.type = selectedSpoolType;
      }

      if (manufacturingOrderIds && manufacturingOrderIds.length !== 0) {
        params.manufacturingOrderIds = manufacturingOrderIds;
      }

      const { data, pagination } = await SpoolService.getSpools(
        {
          ...params,
          withRelated: ['materialType', 'tags'],
        },
        config,
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount,
      };
    },
    [selectedMaterialType, selectedSpoolType, manufacturingOrderIds],
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: 'ID',
        field: 'spoolIdentifier',
        pinnable: true,
        hideable: false,
        visibilityBreakpoint: 'sm',
        flex: 1,
        minWidth: 120,
        sortable: false,
        renderCell: ({ row, colDef }) => (
          <SpoolLink spool={row} width={colDef.computedWidth} />
        ),
      },
      {
        headerName: 'Material',
        field: 'materialType',
        visibilityBreakpoint: 'sm',
        minWidth: 120,
        flex: 1,
        sortable: false,
        renderCell: ({ row, colDef }) => (
          <MaterialLink
            materialType={row.materialType}
            width={colDef.computedWidth}
          />
        ),
        onCellClick: ({ row }) => {
          history.push({
            pathname: ROUTES.GEAR_TAB('spools'),
            state: { from: location.pathname },
          });
        },
      },
      {
        headerName: 'Type',
        field: 'type',
        visibilityBreakpoint: 'sm',
        width: 80,
        sortable: false,
      },
      {
        headerName: 'Tags',
        field: 'tags',
        visibilityBreakpoint: 'sm',
        width: 80,
        sortable: false,
        renderCell: ({ row }) =>
          row.tags && row.tags !== 0
            ? row.tags.map((tag) => tag.name).join(', ')
            : '',
      },
      {
        headerName: 'Actual/Original Weight (g)',
        field: 'originalWeight',
        visibilityBreakpoint: 'lg',
        minWidth: 160,
        sortable: false,
        renderCell: ({ row }) =>
          `${row.currentWeight ?? '-'} / ${row.originalWeight ?? '-'}`,
      },
      {
        headerName: 'Actual/Original Length (m)',
        field: 'currentWeight',
        visibilityBreakpoint: 'lg',
        minWidth: 160,
        sortable: false,
        renderCell: ({ row }) =>
          `${row.currentLength ?? '-'} / ${row.originalLength ?? '-'}`,
      },
      {
        headerName: 'Density (g/m)',
        field: 'density',
        visibilityBreakpoint: 'lg',
        minWidth: 160,
        sortable: false,
      },
      {
        headerName: 'Quality',
        field: 'quality',
        visibilityBreakpoint: 'lg',
        minWidth: 140,
        sortable: false,
        renderCell: ({ row }) => <Rating value={row.quality} readOnly />,
      },
      {
        headerName: 'Comment',
        field: 'comment',
        visibilityBreakpoint: 'lg',
        width: 150,
        sortable: false,
      },

      ...(extraColumns ? extraColumns : []),

      {
        headerName: '',
        field: 'actions',
        type: 'actions',
        visibilityBreakpoint: 'sm',
        hideable: false,
        sortable: false,
        width: 60,
        getActions: (params) =>
          [
            {
              title: 'Edit',
              icon: <EditIcon />,
              onClick: () => {
                setEditableSpool(params.row);
                setOpenSpoolDialog(true);
              },
            },
            {
              title: 'Load to BM',
              icon: <BuildIcon />,
              onClick: () => {
                setSpoolToLoadToBM(params.row);
              },
            },
            {
              title: 'Delete',
              icon: <DeleteIcon />,
              onClick: () => {
                setEditableSpool(params.row);
                setOpenDeleteDialog(true);
              },
            },
            ...(extraActions
              ? extraActions.map((action) => ({
                  ...action,
                  onClick: () => action.onClick(params.row),
                }))
              : []),
          ].map((action) => (
            <GridActionsCellItem
              icon={action.icon}
              label={action.title}
              onClick={action.onClick}
              disabled={action.disabled}
              showInMenu
            />
          )),
      },
    ],
    [extraActions, extraColumns],
  );

  const gridOptions = React.useMemo(() => {
    const gridOptions = {
      orders: {
        created_at: SORT_ORDERS.DESC,
      },
      columns,
      pinnedColumns: {
        left: ['spoolIdentifier'],
        right: ['actions'],
      },
      onChangePage: (page) => {
        // setNewPageForTab('spools', 'Spools', page);
      },
    };

    if (routeKey === 'page' && Number(routeValue) > 0) {
      gridOptions.page = Number(routeValue);
    }

    if (routeKey === 'search') {
      gridOptions.search = routeValue;
    }

    return gridOptions;
  }, [routeKey, routeValue, columns]);

  const titanDataGridProps = useTitanDataGrid(loadData, gridOptions);
  const { rows, setRows } = titanDataGridProps;

  const onCloseDialog = async () => {
    setOpenSpoolDialog(false);
    setEditableSpool(null);
  };

  const onSave = React.useCallback(
    async (data) => {
      const { spoolIdentifierExists } =
        await SpoolService.spoolIdentifierExists({
          spoolIdentifier: data.spoolIdentifier,
          ...(editableSpool && { id: editableSpool.id }),
        });

      if (spoolIdentifierExists) {
        throw new Error(
          'Spool with the same identifier already exists within the organization',
        );
      }

      if (editableSpool) {
        const updatedSpool = await SpoolService.updateSpool(
          editableSpool.id,
          data,
        );
        setRows((prev) =>
          prev.map((s) => (s.id === updatedSpool.id ? updatedSpool : s)),
        );
      } else {
        const newSpool = await SpoolService.createSpool(data);
        setRows((prev) => [newSpool, ...prev]);
      }
    },
    [rows, editableSpool],
  );

  const onDelete = React.useCallback(async () => {
    await SpoolService.deleteSpool(editableSpool.id);

    pushSnackbar('Material successfully deleted', { variant: 'success' });
    setOpenDeleteDialog(false);
    setRows((prev) => prev.filter((s) => s.id !== editableSpool.id));
  }, [rows, editableSpool]);

  React.useEffect(() => {
    if (apiRef) {
      apiRef.current = titanDataGridProps.apiRef.current;
      apiRef.current.reloadData = titanDataGridProps.reloadData;
    }
  }, []);

  const loadSpoolToBuildModule = React.useCallback(
    async (buildModule) => {
      await BuildModuleService.loadSpoolToBuildModule(
        buildModule.id,
        spoolToLoadToBM.id,
      );

      pushSnackbar(
        `Spool ${spoolToLoadToBM.spoolIdentifier} loaded to ${buildModule.name}`,
        {
          variant: 'success',
        },
      );
    },
    [spoolToLoadToBM, pushSnackbar],
  );

  return (
    <>
      <TitanDataGrid
        searchPlaceholder="Search by spool identifier"
        {...titanDataGridProps}
        components={{
          Toolbar: TitanDataGridToolbar,
          ColumnsPanel: CustomGridColumnsPanel,
        }}
        filtersContent={
          <SpoolFilter
            selectedMaterialType={selectedMaterialType}
            onChangeMaterialType={onChangeMaterialType}
            selectedSpoolType={selectedSpoolType}
            onChangeSpoolType={onChangeSpoolType}
          />
        }
        chipsContent={
          <SpoolChips
            selectedMaterialType={selectedMaterialType}
            onChangeMaterialType={onChangeMaterialType}
            selectedSpoolType={selectedSpoolType}
            onChangeSpoolType={onChangeSpoolType}
          />
        }
        onCreateClick={
          createButtonLabel ? () => setOpenSpoolDialog(true) : null
        }
        createButtonLabel={createButtonLabel}
        title={title}
      />
      {openSpoolDialog && (
        <SpoolDialog
          onClose={onCloseDialog}
          editableSpool={editableSpool}
          onSave={onSave}
        />
      )}

      {openDeleteDialog && (
        <TitanConfirmationDialog
          title="Delete spool?"
          message={`Are you sure you want to delete spool ${editableSpool.spoolIdentifier}?`}
          onClose={() => {
            setOpenDeleteDialog(false);
            setEditableSpool(null);
          }}
          onConfirm={onDelete}
        />
      )}

      {spoolToLoadToBM && (
        <FibrifyBuildModuleSelectionDialog
          title="Select build module to load spool"
          onClose={() => setSpoolToLoadToBM(null)}
          onSave={loadSpoolToBuildModule}
          saveButtonLabel="Load"
        />
      )}
    </>
  );
}
