import React from 'react';
import { useAuth0 } from '../Authentication/Auth0';
import FibrifyItemsSelectionDialog from './FibrifyItemsSelectionDialog';
import MouldService from '../../services/MouldService';

export default function FibrifyMouldSelectionDialog({
  title,
  multipleSelection = false,
  filters = {},
  selectedMoulds = [],
  onSave,
  saveButtonLabel = 'Load',
  onClose
}) {
  const { profile } = useAuth0();

  const loadData = React.useCallback(
    async ({ page, pageSize, search }, config) => {
      const params = {
        page: page,
        pageSize: pageSize,
        order: ['created_at:desc'],
        ...filters
      };

      if (search) {
        params.search = search;
      }

      const { data, pagination } = await MouldService.getMoulds(params, config);

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount
      };
    },
    [profile]
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Mould ID',
        field: 'mouldIdentifier',
        hideable: false,
        flex: 1,
        minWidth: 200,
        sortable: false,
        visibilityBreakpoint: 'sm'
      }
    ],
    []
  );

  return (
    <FibrifyItemsSelectionDialog
      loadData={loadData}
      columns={columns}
      title={title}
      onSave={onSave}
      onClose={onClose}
      selectedItems={selectedMoulds}
      multipleSelection={multipleSelection}
      saveButtonLabel={saveButtonLabel}
      searchPlaceholder="Search by mould ID"
      chipsNameKey="mouldIdentifier"
    />
  );
}
