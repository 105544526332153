import axios from 'axios';

export default class PrinterModelService {
  static async getPrinterModels(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/printer-models`, {
        ...config,
        params
      })
      .then(res => res.data);
  }

  static getPrinterModelById(id, params = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/printer-models/${id}`, {
        params
      })
      .then(res => res.data.data);
  }

  static createPrinterModel(data) {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/printer-models`, data)
      .then(res => res.data.data);
  }

  static updatePrinterModel(id, data) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v1/printer-models/${id}`,
        data
      )
      .then(res => res.data.data);
  }

  static deletePrinterModel(id) {
    return axios
      .delete(`${process.env.REACT_APP_API_URL}/api/v1/printer-models/${id}`)
      .then(res => res.data);
  }
}
