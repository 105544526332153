import React from 'react';
import { styled } from '@mui/material/styles';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ImageIcon from '@mui/icons-material/Image';
import TitanDialog from '../Titan/TitanDialog';
import OrganizationService from '../../services/OrganizationService';
import FileService from '../../services/FileService';
import { FormGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const Logo = styled('img')({
  display: 'block',
  width: '50%',
  marginTop: '10px',
});

export default function OrganizationDialog(props) {
  const { mode, organization, onClose, onSave } = props;

  const [name, setName] = React.useState(organization.name || '');
  const [metabaseEmbedConfig, setMetabaseEmbedConfig] = React.useState(
    organization.metabaseEmbedConfig
      ? JSON.stringify(organization.metabaseEmbedConfig)
      : '',
  );
  const [odooIntegrationEnabled, setOdooIntegrationEnabled] = React.useState(
    organization.odooIntegrationEnabled || false,
  );
  const [metabaseIntegrationEnabled, setMetabaseIntegrationEnabled] =
    React.useState(organization.metabaseIntegrationEnabled || false);
  const [measuringDevicesEnabled, setMeasuringDevicesEnabled] = React.useState(
    organization.measuringDevicesEnabled || false,
  );
  const [labelPrinterEnabled, setLabelPrinterEnabled] = React.useState(
    organization.labelPrinterEnabled || false,
  );
  const [logo, setLogo] = React.useState();
  const [displayLogo, setDisplayLogo] = React.useState(
    organization.logoUrl || null,
  );

  const [logoUploading, setLogoUploading] = React.useState(false);

  const logoInput = React.useRef();

  const handleLogoUpload = async (e) => {
    if (e.target.files.length === 0) {
      return;
    }

    setLogoUploading(true);
    const file = e.target.files[0];

    const fileData = await FileService.uploadFile(file.name, file);
    const base64File = await FileService.fileToBase64(file);

    setLogoUploading(false);
    setDisplayLogo(base64File);
    setLogo(fileData);
  };

  const handleLogoClear = () => {
    setLogo(null);
    setDisplayLogo(null);
    logoInput.current.value = '';
  };

  const onSubmitForm = () => {
    if (mode === 'create') {
      return OrganizationService.createOrganization({
        name,
        odooIntegrationEnabled,
        metabaseIntegrationEnabled,
        measuringDevicesEnabled,
        labelPrinterEnabled,
        logo,
        metabaseEmbedConfig,
      }).then((data) => {
        onSave(data);
      });
    } else {
      return OrganizationService.updateOrganization(organization.id, {
        name,
        odooIntegrationEnabled,
        metabaseIntegrationEnabled,
        measuringDevicesEnabled,
        labelPrinterEnabled,
        newLogo: logo,
        metabaseEmbedConfig,
      }).then((data) => {
        onSave(data);
      });
    }
  };

  const isValid = name.length !== 0;

  return (
    <TitanDialog
      title={`${mode === 'create' ? 'Create' : 'Edit'} organization`}
      onClose={onClose}
      onSave={onSubmitForm}
      isValid={isValid}
      successSaveMessage={`Organization successfully ${
        mode === 'create' ? 'created' : 'updated'
      }`}
    >
      <TextField
        autoFocus
        label="Name"
        value={name}
        fullWidth
        margin="normal"
        onChange={(e) => setName(e.target.value)}
      />
      <Button variant="contained" component="label" startIcon={<ImageIcon />}>
        Upload New Logo
        <input
          hidden
          type="file"
          accept="image/*"
          ref={logoInput}
          onChange={handleLogoUpload}
        />
      </Button>
      {displayLogo && (
        <Button variant="outlined" onClick={handleLogoClear} sx={{ ml: 2 }}>
          Clear Logo
        </Button>
      )}
      {logoUploading ? (
        <CircularProgress sx={{ display: 'block', mt: 2 }} />
      ) : (
        displayLogo && <Logo src={displayLogo} alt="" />
      )}
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={odooIntegrationEnabled}
              onChange={(e) => setOdooIntegrationEnabled(e.target.checked)}
            />
          }
          label="Odoo integration"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={metabaseIntegrationEnabled}
              onChange={(e) => setMetabaseIntegrationEnabled(e.target.checked)}
            />
          }
          label="Metabase integration"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={measuringDevicesEnabled}
              onChange={(e) => setMeasuringDevicesEnabled(e.target.checked)}
            />
          }
          label="Measuring devices"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={labelPrinterEnabled}
              onChange={(e) => setLabelPrinterEnabled(e.target.checked)}
            />
          }
          label="Label printer"
        />
        {metabaseIntegrationEnabled && (
          <TextField
            autoFocus
            label="Metabase embed config"
            value={metabaseEmbedConfig}
            fullWidth
            margin="normal"
            multiline
            onChange={(e) => setMetabaseEmbedConfig(e.target.value)}
          />
        )}
      </FormGroup>
    </TitanDialog>
  );
}
