import React from 'react';
import Grid from '@mui/material/Grid';
import TitanCircularProgress from '../Titan/TitanCircularProgress';
import FusionModuleCard from './FusionModuleCard';
import FusorService from '../../services/FusorService';
import Box from '@mui/material/Box';

export default function DashboardFusionModules({ params }) {
  const [fusionModules, setFusionModules] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const loadFusionModules = async () => {
    setLoading(true);
    const queryParams = {
      page: 1,
      pageSize: 2,
      withRelated: [
        'activeJobs',
        'activeJobs.fusionPlan',
        'activeJobs.assemblies',
        'prevFusionJob',
        'prevFusionJob.fusionPlan',
        'prevFusionJob.assemblies'
      ],
      ...params
    };

    const { data } = await FusorService.getFusors(queryParams);

    setLoading(false);

    setFusionModules(data);
  };

  React.useEffect(() => {
    loadFusionModules();
  }, []);

  return (
    <Box sx={{ minHeight: 260 }}>
      {loading ? (
        <TitanCircularProgress height={260} />
      ) : (
        <Grid container spacing={2}>
          {fusionModules.map(fusionModule => (
            <Grid key={fusionModule.id} item md={6} sm={12} xs={12}>
              <FusionModuleCard fusionModule={fusionModule} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}
