import React from 'react';
import Stack from '@mui/material/Stack';
import { Checkbox, Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';

export default function useTitanChart(actualSeries, targetSeries, hideTarget) {
  const [isOpenGraphDialog, setIsOpenGraphDialog] = React.useState(false);
  const [legendLabels, setLegendLabels] = React.useState([]);
  const [legendColors, setLegendColors] = React.useState([]);
  const [showedArray, setShowedArray] = React.useState([]);
  const [tempChanges, setTempChanges] = React.useState([]);
  const prevActuals = React.useRef();

  React.useEffect(() => {
    let concatedArray;
    if (prevActuals.current) {
      if (
        Object.values(prevActuals.current).every(array => array.length === 0)
      ) {
        concatedArray = Object.values(actualSeries).map(serriesArray =>
          serriesArray.map(series => ({ ...series, showed: true }))
        );
        setShowedArray(concatedArray);
      }
    } else if (!hideTarget) {
      concatedArray = [...actualSeries, ...targetSeries].map(series => ({
        ...series,
        showed: true
      }));
      setShowedArray(concatedArray);
    }
    prevActuals.current = actualSeries;
  }, [actualSeries]);

  const changeShowedSeriesArray = React.useCallback(
    changesArray => {
      if (hideTarget && changesArray.length > 0) {
        setShowedArray(prev => {
          const newArray = [...prev];
          changesArray.forEach(({ series, tableNumber }) => {
            newArray[tableNumber] = newArray[tableNumber].map(s => {
              if (s.label === series) {
                return { ...s, showed: !s.showed };
              }
              return s;
            });
          });
          return newArray;
        });
      } else if (changesArray.length > 0) {
        setShowedArray(prev => {
          return prev.map(s => {
            if (changesArray.find(({ series }) => series === s.label)) {
              return { ...s, showed: !s.showed };
            }
            return s;
          });
        });
      }
    },
    [hideTarget]
  );

  const showAll = React.useCallback(() => {
    if (hideTarget) {
      setShowedArray(prev =>
        prev.map(serriesArray =>
          serriesArray.map(series => {
            return { ...series, showed: true };
          })
        )
      );
    } else {
      setShowedArray(prev =>
        prev.map(series => {
          return { ...series, showed: true };
        })
      );
    }
  }, [hideTarget]);

  const computedSeriesArray = React.useMemo(() => {
    if (hideTarget) {
      return legendLabels.map((serriesArray, index) =>
        serriesArray
          .map((series, i) => {
            return {
              id: `${series} ${legendColors[index][i]}`,
              title: series,
              color: legendColors[index][i],
              showed: showedArray[index].find(
                showedSeries => series === showedSeries.label
              )?.showed,
              isActual: !!Object.values(actualSeries)[index].find(
                s => s.label === series
              ),
              tableNumber: index
            };
          })
          .sort(
            (a, b) =>
              Number(a.title.match(/\d+/)) - Number(b.title.match(/\d+/))
          )
      );
    } else {
      return legendLabels
        .map((series, index) => ({
          id: `${series} ${legendColors[index]}`,
          title: series,
          color: legendColors[index],
          showed: showedArray.find(
            showedSeries => series === showedSeries.label
          )?.showed,
          isActual: !!actualSeries.find(
            actualSeries => actualSeries.label === series
          )
        }))
        .sort(
          (a, b) => Number(a.title.match(/\d+/)) - Number(b.title.match(/\d+/))
        )
        .reduce((accum, current) => {
          const activeSeriesPair = accum.findIndex(
            series =>
              series?.title?.trim()?.split('(')[0] ===
              current?.title?.trim()?.split('(')[0]
          );

          if (activeSeriesPair !== -1) {
            accum[activeSeriesPair] = {
              id: current.id,
              0: accum[activeSeriesPair],
              1: current
            };
            return accum;
          } else {
            accum.push(current);
            return accum;
          }
        }, []);
    }
  }, [legendLabels, legendColors, showedArray]);

  const columns = [
    {
      headerName: '',
      field: 'color',
      visibilityBreakpoint: 'xs',
      minWidth: hideTarget ? 50 : 150,
      sortable: false,
      renderCell: ({ row }) => (
        <Stack
          direction="row"
          sx={{
            width: '100%',
            justifyContent: 'space-around'
          }}
        >
          {hideTarget ? (
            <Box
              sx={{
                width: '30px',
                height: '30px',
                borderRadius: '25%',
                backgroundColor: row.color
              }}
            />
          ) : (
            <>
              <Box
                sx={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '25%',
                  backgroundColor: row[0].color
                }}
              />
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderWidth: 'thin' }}
              />
              <Box
                sx={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '25%',
                  backgroundColor: row[1].color
                }}
              />
            </>
          )}
        </Stack>
      )
    },
    {
      headerName: 'Name',
      field: 'title',
      visibilityBreakpoint: 'xs',
      minWidth: 300,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => (
        <Stack direction="row">
          {hideTarget ? (
            <Typography>{row?.title}</Typography>
          ) : (
            <>
              <Typography>{row[0].title}</Typography>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderWidth: 'thin', margin: '0 15px' }}
              />
              <Typography>{row[1].title}</Typography>
            </>
          )}
        </Stack>
      )
    },
    {
      headerName: 'Actual',
      field: 'actualShowed',
      visibilityBreakpoint: 'xs',
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) =>
        hideTarget ? (
          row.isActual ? (
            <Checkbox
              checked={row.showed}
              value={row.title}
              onChange={e => {
                setTempChanges(prev => {
                  if (prev.find(({ series }) => series === e.target.value)) {
                    return [
                      ...prev.filter(({ series }) => series === e.target.value)
                    ];
                  } else {
                    return [
                      ...prev,
                      { series: e.target.value, tableNumber: row.tableNumber }
                    ];
                  }
                });
                changeShowedSeriesArray([
                  { series: e.target.value, tableNumber: row.tableNumber }
                ]);
              }}
            />
          ) : (
            ''
          )
        ) : row[0].isActual ? (
          <Checkbox
            checked={row[0].showed}
            value={row[0].title}
            onChange={e => {
              setTempChanges(prev => {
                if (prev.find(({ series }) => series === e.target.value)) {
                  return [
                    ...prev.filter(({ series }) => series !== e.target.value)
                  ];
                } else {
                  return [...prev, { series: e.target.value }];
                }
              });
              changeShowedSeriesArray([{ series: e.target.value }]);
            }}
          />
        ) : (
          ''
        )
    },
    {
      headerName: 'Target',
      field: 'targetShowed',
      visibilityBreakpoint: 'xs',
      minWidth: 100,
      sortable: false,
      hide: hideTarget,
      renderCell: ({ row }) =>
        !row[1].isActual ? (
          <Checkbox
            checked={row[1].showed}
            value={row[1].title}
            onChange={e => {
              setTempChanges(prev => {
                if (prev.find(({ series }) => series === e.target.value)) {
                  return [
                    ...prev.filter(({ series }) => series !== e.target.value)
                  ];
                } else {
                  return [...prev, { series: e.target.value }];
                }
              });
              changeShowedSeriesArray([{ series: e.target.value }]);
            }}
          />
        ) : (
          ''
        )
    }
  ];

  return {
    columns,

    computedSeriesArray,

    changeShowedSeriesArray,

    tempChanges,
    setTempChanges,

    isOpenGraphDialog,
    setIsOpenGraphDialog,

    legendLabels,
    setLegendLabels,

    legendColors,
    setLegendColors,

    showedArray,
    setShowedArray,

    showAll
  };
}
