import React from 'react';
import { makeStyles } from '@mui/styles';
import TitanChart from '../Titan/TitanChart';
import Paper from '@mui/material/Paper';
import TitanCircularProgress from '../Titan/TitanCircularProgress';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Button, IconButton } from '@mui/material';

import { CSVLink } from 'react-csv';
import useBuildModuleTemperature from '../BuildModule/use-build-module-temperature';
import useTitanChart from '../Titan/UseTitanChart';
import TitanDialog from '../Titan/TitanDialog';
import { DataGridPro } from '@mui/x-data-grid-pro';
import SettingsIcon from '@mui/icons-material/Settings';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4)
  },
  content: {
    padding: theme.spacing(2)
  }
}));

export default function BuildJobTemperatures({
  buildJobId,
  buildModuleId,
  startBuildTime,
  endBuildTime
}) {
  const classes = useStyles();

  const {
    loading,
    actualSeries,
    targetSeries,
    csvHeaders,
    csvData
  } = useBuildModuleTemperature(
    buildModuleId,
    buildJobId,
    startBuildTime,
    endBuildTime
  );

  const {
    columns,
    computedSeriesArray,
    isOpenGraphDialog,
    setIsOpenGraphDialog,
    legendLabels,
    setLegendLabels,
    legendColors,
    setLegendColors,
    showedArray,
    changeShowedSeriesArray,
    setTempChanges,
    showAll
  } = useTitanChart(actualSeries, targetSeries);

  const onClose = () => {
    setTempChanges(prev => {
      changeShowedSeriesArray(prev);
      return [];
    });
    setIsOpenGraphDialog(false);
  };

  const onSave = async () => {
    return setTempChanges(prev => []);
  };

  return (
    <Paper>
      <div className={classes.content}>
        {loading && <TitanCircularProgress />}

        {!loading ? (
          <Box>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              sx={{ '& > a': { textDecoration: 'none' } }}
            >
              <CSVLink
                data={csvData}
                headers={csvHeaders}
                filename={`build-job-${buildJobId ? buildJobId : ''}.csv`}
              >
                <Button>Download</Button>
              </CSVLink>
              <IconButton onClick={() => setIsOpenGraphDialog(true)}>
                <SettingsIcon />
              </IconButton>
            </Stack>
            <TitanChart
              actual={actualSeries}
              target={targetSeries}
              legendLabels={legendLabels}
              legendColors={legendColors}
              showedArray={showedArray}
              setLegendLabels={setLegendLabels}
              setLegendColors={setLegendColors}
            />
          </Box>
        ) : (
          ''
        )}
      </div>
      {isOpenGraphDialog && (
        <TitanDialog
          title={`Build Module graph settings`}
          onClose={onClose}
          onSave={onSave}
          maxWidth="md"
          fullWidth
          onAdditionalButton={showAll}
          additionalButtonLabel="Show all"
        >
          <Stack>
            <DataGridPro
              rows={computedSeriesArray}
              columns={columns}
              pageSize={10}
              autoHeight
            />
          </Stack>
        </TitanDialog>
      )}
    </Paper>
  );
}
