export default [
  {
    id: 1,
    name: 'Weight',
    type: 'NUMBER',
    units: 'G',
    isNew: true,
    formType: 'POST_FUSION',
    order: 0,
  },
];
