import React from 'react';
import TitanPopover from '../Titan/TitanPopover';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Checkbox, Grid, MenuItem, Radio, RadioGroup } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ManufacturingOrderStateLabels } from './ManufacturingOrderState';
import Box from '@mui/material/Box';

export default function ManufacturingOrdersTableFilter({
  projects,
  selectedProjects,
  setSelectedProjects,
  selectedStates,
  setSelectedStates,
  manufacturingOrderType,
  setManufacturingOrderType,
  manufacturingOrderProgress,
  setManufacturingOrderProgress,
  filterAssignedMOs,
  setFilterAssignedMOs,
}) {
  return (
    <>
      <TitanPopover icon={FilterListIcon}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 450,
            padding: 2,
          }}
        >
          <Grid container>
            <Grid item xs={6}>
              <FormControl>
                <FormLabel>Type</FormLabel>
                <RadioGroup
                  value={manufacturingOrderType}
                  onChange={(e) => setManufacturingOrderType(e.target.value)}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio />}
                    label="All"
                  />
                  <FormControlLabel
                    value="preform"
                    control={<Radio />}
                    label="Preform"
                  />
                  <FormControlLabel
                    value="finalPart"
                    control={<Radio />}
                    label="Final part"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl>
                <FormLabel>Progress</FormLabel>
                <RadioGroup
                  value={manufacturingOrderProgress}
                  onChange={(e) =>
                    setManufacturingOrderProgress(e.target.value)
                  }
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio />}
                    label="All"
                  />
                  <FormControlLabel
                    value="inProgress"
                    control={<Radio />}
                    label="Progress < 100%"
                  />
                  <FormControlLabel
                    value="completed"
                    control={<Radio />}
                    label="Progress >= 100%"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <FormLabel>States</FormLabel>
                {Object.entries(ManufacturingOrderStateLabels).map(
                  ([key, label]) => (
                    <FormControlLabel
                      key={key}
                      control={
                        <Checkbox checked={selectedStates[key]} name={key} />
                      }
                      label={label}
                      onChange={(event) =>
                        setSelectedStates((prev) => ({
                          ...prev,
                          [event.target.name]: event.target.checked,
                        }))
                      }
                    />
                  ),
                )}
              </FormControl>
              <FormControl>
                <FormLabel>Filter</FormLabel>
                <FormControlLabel
                  control={<Checkbox checked={filterAssignedMOs} />}
                  label="Assigned to me"
                  onChange={(_, checked) => setFilterAssignedMOs(checked)}
                />
              </FormControl>
            </Grid>
          </Grid>

          <FormControl margin="normal">
            <FormLabel>By Projects</FormLabel>
            <Autocomplete
              multiple
              id="projects-filter"
              value={selectedProjects}
              onChange={setSelectedProjects}
              options={projects}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} placeholder="Select projects..." />
              )}
              sx={{ mt: 1 }}
            />
          </FormControl>
        </Box>
      </TitanPopover>
    </>
  );
}
