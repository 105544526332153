import React from 'react';
import TitanDialog from '../Titan/TitanDialog';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TitanInfoItem from '../Titan/TitanInfoItem';
import CopyToClipboard from '../Titan/CopyToClipboard';

export default function DeviceInfoPopUp(props) {
  const { id, accessKey, onClose } = props;

  return (
    <TitanDialog
      onClose={onClose}
      maxWidth="sm"
      title="Device info"
      cancelButtonLabel="Close"
    >
      <Paper>
        <Grid container direction="column">
          <Grid item sx={{ marginLeft: 3, marginTop: 1 }}>
            <TitanInfoItem label="Device ID">
              {id ? (
                <CopyToClipboard text={id} copyValue={id} label="Device ID" />
              ) : (
                '-'
              )}
            </TitanInfoItem>
          </Grid>

          {accessKey && (
            <Grid item sx={{ marginLeft: 3, marginTop: 1 }}>
              <TitanInfoItem label="Access key">
                <CopyToClipboard
                  text={accessKey}
                  copyValue={accessKey}
                  label="Access key"
                />
              </TitanInfoItem>
            </Grid>
          )}
        </Grid>
      </Paper>
    </TitanDialog>
  );
}
