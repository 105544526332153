import { useCallback } from 'react';

export default function useFibrifyForm() {
  const onInputWheel = useCallback((e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  }, []);

  return {
    onInputWheel,
  };
}
