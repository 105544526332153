import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(2, 3)
  }
}));

export default function TitanConfirmationDialog(props) {
  const {
    message,
    title,
    onConfirm,
    onClose,
    cancelLabel = 'No',
    confirmLabel = 'Yes',
    children,
    fullWidth
  } = props;
  const classes = useStyles();

  const [confirmationLoading, setConfirmationLoading] = React.useState(false);

  const handleConfirm = async e => {
    e.stopPropagation();
    setConfirmationLoading(true);
    await onConfirm();
    setConfirmationLoading(false);
    onClose();
  };

  const handleClose = e => {
    e.stopPropagation();
    onClose();
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      fullWidth={fullWidth}
      PaperProps={{
        sx: {
          maxWidth: {
            lg: '1200px'
          }
        }
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.content} align="left">
          {message}
        </DialogContentText>
        {children && children}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose} variant="outlined">
          {cancelLabel}
        </Button>
        <Button
          onClick={handleConfirm}
          disabled={confirmationLoading}
          variant="outlined"
          color="primary"
        >
          {confirmLabel}
          {confirmationLoading && <CircularProgress sx={{ ml: 1 }} size={24} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
