import React from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

export default function TitanTabs({
  tabs,
  activeTab,
  onChangeTab,
  centered = false
}) {
  const [tabIndex, setTabIndex] = React.useState(
    activeTab ? activeTab : tabs.length ? tabs[0].value : null
  );

  return (
    <TabContext value={activeTab ? activeTab : tabIndex}>
      <Box sx={{ borderBottom: 2, borderColor: 'divider', mb: 2 }}>
        <TabList
          onChange={(e, value) => {
            if (!activeTab) {
              setTabIndex(value);
            }
            if (onChangeTab) {
              onChangeTab(value);
            }
          }}
          centered={centered}
        >
          {tabs.map(tab => (
            <Tab
              label={
                tab.disabled ? (
                  <Tooltip title={tab.disabledMessage}>
                    <span>{tab.label}</span>
                  </Tooltip>
                ) : (
                  tab.label
                )
              }
              value={tab.value}
              key={tab.value}
              disabled={tab.disabled}
              style={tab.disabled ? { pointerEvents: 'auto' } : {}}
            />
          ))}
        </TabList>
      </Box>
      {tabs.map(tab => (
        <TabPanel sx={{ p: 0 }} value={tab.value} key={tab.value}>
          {tab.content}
        </TabPanel>
      ))}
    </TabContext>
  );
}
