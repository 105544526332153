import React from 'react';

import { useFuseDialog } from './FuseDialog';

import { COMPONENT_STATUSES, SORT_ORDERS } from '../../constants';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import ComponentService from '../../services/ComponentService';
import PreformLink from '../Component/PreformLink';
import BuildPlanLink from '../BuildPlan/BuildPlanLink';
import ComponentStatus from '../Component/ComponentStatus';
import Rating from '@mui/material/Rating';
import Button from '@mui/material/Button';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import { Stack, Box } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

export default function FuseDialogPreformsStep() {
  const {
    setOpenScanDialog,
    finalParts,
    finalPartTypes,
    setFinalParts,
    selectedFinalPartTypeId,
    setSelectedFinalPartTypeId,
  } = useFuseDialog();

  const loadPreforms = React.useCallback(async (query, config) => {
    const params = {
      ...query,
      withRelated: ['buildPlan', 'preformType'],
    };

    if (!params.search) {
      params.statuses = [COMPONENT_STATUSES.BUILT];
    }

    const { data, pagination } = await ComponentService.getPreforms(
      params,
      config,
    );

    return {
      data,
      page: pagination.page - 1,
      totalCount: pagination.totalCount,
    };
  }, []);

  const preformColumns = React.useMemo(
    () => [
      {
        headerName: 'ID',
        field: 'id',
        visibilityBreakpoint: 'sm',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) => <PreformLink preform={row} />,
      },
      {
        headerName: 'Build Plan',
        field: 'buildPlan',
        visibilityBreakpoint: 'sm',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row, colDef }) =>
          row.buildPlan ? (
            <BuildPlanLink
              buildPlan={row.buildPlan}
              width={colDef.computedWidth}
            />
          ) : (
            ''
          ),
      },
      {
        headerName: 'Preform Type',
        field: 'preformType',
        visibilityBreakpoint: 'lg',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) =>
          row.preformType
            ? `${row.preformType.name} (${row.preformType.labels})`
            : '',
      },
      {
        headerName: 'Status',
        field: 'status',
        visibilityBreakpoint: 'sm',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => <ComponentStatus component={row} />,
      },
      {
        headerName: 'Quality',
        field: 'quality',
        visibilityBreakpoint: 'lg',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) =>
          [COMPONENT_STATUSES.BUILT, COMPONENT_STATUSES.POST_BUILDING].includes(
            row.status,
          ) && row.quality !== null ? (
            <Rating name="read-only" value={row.quality} readOnly />
          ) : (
            ''
          ),
      },
    ],
    [],
  );

  const preformsDataGridProps = useTitanDataGrid(loadPreforms, {
    orders: {
      created_at: SORT_ORDERS.DESC,
    },
    columns: preformColumns,
    pinnedColumns: {
      left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'id'],
      right: [],
    },
  });

  const onSelectPreforms = React.useCallback(
    (newSelectedPreforms) => {
      setFinalParts((prev) =>
        prev.map((finalPart) => {
          if (finalPart.finalPartTypeId !== selectedFinalPartTypeId) {
            return finalPart;
          }

          return {
            ...finalPart,
            selectedPreforms: newSelectedPreforms,
          };
        }),
      );
    },
    [selectedFinalPartTypeId],
  );

  const selectedPreforms = React.useMemo(() => {
    const finalPart = finalParts.find(
      (fpt) => fpt.finalPartTypeId === selectedFinalPartTypeId,
    );

    return finalPart && finalPart.selectedPreforms
      ? finalPart.selectedPreforms
      : [];
  }, [finalParts, selectedFinalPartTypeId]);

  const disabledPreformIds = React.useMemo(() => {
    const disabledPreformIds = [];

    finalParts.forEach((finalPartType) => {
      if (
        finalPartType.finalPartTypeId !== selectedFinalPartTypeId &&
        finalPartType &&
        finalPartType.selectedPreforms
      ) {
        finalPartType.selectedPreforms.forEach((selectedPreform) => {
          disabledPreformIds.push(selectedPreform.id);
        });
      }
    }, []);

    return disabledPreformIds;
  }, [finalParts, selectedFinalPartTypeId]);

  return (
    <>
      <TabContext value={selectedFinalPartTypeId}>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: 'background.paper',
            display: 'flex',
            height: '100%',
          }}
        >
          <Box sx={{ width: '255px', height: '100%' }}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={selectedFinalPartTypeId}
              onChange={(e, newTab) => {
                setSelectedFinalPartTypeId(newTab);
              }}
              sx={{ borderRight: 1, borderColor: 'divider' }}
            >
              {finalPartTypes.map((finalPartType) => (
                <Tab
                  key={finalPartType.id}
                  label={finalPartType.name}
                  value={finalPartType.id}
                  sx={{ alignItems: 'start' }}
                />
              ))}
            </Tabs>
          </Box>
          <Box sx={{ width: 'calc(100% - 210px)', height: '100%' }}>
            <Box sx={{ height: '100%', ml: 2 }}>
              <Stack direction="row" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenScanDialog(true)}
                  title="Scan"
                >
                  Scan
                </Button>
              </Stack>

              <TitanDataGrid
                components={{
                  Toolbar: TitanDataGridToolbar,
                }}
                {...preformsDataGridProps}
                searchPlaceholder="Search by preform id, preform type or Build Plan name"
                isRowSelectable={({ row }) =>
                  !row.assemblyId &&
                  row.status === 'BUILT' &&
                  !disabledPreformIds.includes(row.id)
                }
                checkboxMultiSelection
                selectedRows={selectedPreforms}
                onSelectionModelChange={onSelectPreforms}
              />
            </Box>
          </Box>
        </Box>
      </TabContext>
    </>
  );
}
