import React from 'react';
import FibrifyItemsSelectionDialog from './FibrifyItemsSelectionDialog';

import PrinterService from '../../services/PrinterService';
import BuildModuleLink from '../BuildModule/BuildModuleLink';
import BuildModuleState from '../BuildModule/BuildModuleState';

export default function FibrifyBuildModuleSelectionDialog({
  title,
  multipleSelection = false,
  filters = {},
  onSave,
  saveButtonLabel = 'Select',
  onClose
}) {
  const loadData = React.useCallback(
    async ({ page, pageSize, search }, config) => {
      const params = {
        ...filters,
        page: page,
        pageSize: pageSize
      };

      if (search) {
        params.search = search;
      }

      const response = await PrinterService.getPrinters(params, config);

      return response;
    },
    []
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        visibilityBreakpoint: 'sm',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <BuildModuleLink buildModule={row} />
      },
      {
        headerName: 'Status',
        field: 'status',
        minWidth: 300,
        sortable: false,
        renderCell: ({ row }) => <BuildModuleState buildModule={row} />
      }
    ],
    []
  );

  return (
    <FibrifyItemsSelectionDialog
      onClose={onClose}
      loadData={loadData}
      columns={columns}
      title={title}
      multipleSelection={multipleSelection}
      onSave={onSave}
      saveButtonLabel={saveButtonLabel}
      searchPlaceholder="Search by build module name"
      chipsNameKey="name"
    />
  );
}
