import React from 'react';
import TitanPage from '../Titan/TitanPage';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTitan } from '../Titan/Titan';
import BuildPlanService from '../../services/BuildPlanService';
import ProjectService from '../../services/ProjectService';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import {
  ROUTES,
  ROLES,
  SORT_ORDERS,
  STYLING_FUNCTIONS,
  BUILD_PLAN_SHARE_TYPES,
} from '../../constants';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import BuildPlanDialog from '../BuildPlan/BuildPlanDialog';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import BuildPlanShareDialog from '../BuildPlan/BuildPlanShareDialog';
import MemberSelectionDialog from '../Fibrify/FibrifyMemberSelectionDialog';
import { useAuth0 } from '../Authentication/Auth0';
import BuildPlansChips from './BuildPlansChips';
import BuildPlansTableFilter from './BuildPlansTableFilter';
import BuildPlanThumbnail from '../BuildPlan/BuildPlanThubnail';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import BuildPlanLink from '../BuildPlan/BuildPlanLink';
import BuildPlanTags from '../BuildPlan/BuildPlanTags';
import ProjectLink from '../Projects/ProjectLink';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import { CustomGridColumnsPanel } from '../TitanDataGrid/TitanDataGridColumnsPanel';
import { Box, Checkbox, Button, Typography } from '@mui/material';
import { colors } from '../Theme/vars';

export default function BuildPlansPage() {
  const history = useHistory();
  const location = useLocation();
  const { routeKey, routeValue } = useParams();
  const { currentMemberId, roles } = useAuth0();

  const [openBuildPlanDialog, setOpenBuildPlanDialog] = React.useState(false);
  const [editableBuildPlan, setEditableBuildPlan] = React.useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openShareDialog, setOpenShareDialog] = React.useState(false);
  const [openAssignToDialog, setOpenAssignToDialog] = React.useState(false);
  const [openRemoveFromProjectDialog, setOpenRemoveFromProjectDialog] =
    React.useState(false);

  const [filterOwnedBuildPlans, setFilterOwnedBuildPlans] =
    React.useState(false);
  const [filterAssignedBuildPlans, setFilterAssignedBuildPlans] =
    React.useState(false);
  const [filterApprovedBuildPlans, setFilterApprovedBuildPlans] =
    React.useState(false);
  const [filterPrototypes, setFilterPrototypes] = React.useState(false);

  const [projects, setProjects] = React.useState([]);
  const [chosenProjects, setChosenProjects] = React.useState([]);

  const [sharedMembersIds, setSharedMembersIds] = React.useState([]);

  const [deletablePlans, setDeletablePlans] = React.useState(() => {
    const savedItems = localStorage.getItem('deletableBuildPlans');
    const parsedData = JSON.parse(savedItems);
    return parsedData ? parsedData : [];
  });

  const { pushSnackbar, addPageToPageHistory } = useTitan();

  const [deleteSingle, setDeleteSingle] = React.useState(false);
  const [deletingPlanHasPreforms, setDeletingPlanHasPreforms] =
    React.useState(false);

  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Build Plans',
        disabled: true,
      },
    ],
    [],
  );

  const toggleDeletablePlan = React.useCallback(
    (plan) => {
      if (deletablePlans.find((dP) => dP.id === plan.id)) {
        setDeletablePlans((prev) => {
          return prev.filter((deletablePlan) => deletablePlan.id !== plan.id);
        });
      } else {
        setDeletablePlans((prev) => {
          const newArray = [...prev];
          newArray.push({
            id: plan.id,
            hasPreforms: plan.components.length > 0,
            name: plan.name,
          });
          return newArray;
        });
      }
    },
    [deletablePlans],
  );

  const clearDeletablePlans = React.useCallback(() => {
    setDeletablePlans([]);
  }, []);

  React.useEffect(() => {
    localStorage.setItem('deletableBuildPlans', JSON.stringify(deletablePlans));
  }, [deletablePlans]);

  const getDeletable = React.useCallback(
    (plan) => {
      return !!deletablePlans.find((dp) => dp.id === plan.id);
    },
    [deletablePlans],
  );

  const openAssignedToDialog = React.useCallback(async (buildPlan) => {
    setEditableBuildPlan(buildPlan);
    if (buildPlan.shareType === BUILD_PLAN_SHARE_TYPES.MEMBERS) {
      const sharedMembers = await BuildPlanService.getBuildPlanSharedMembers(
        buildPlan.id,
      );
      setSharedMembersIds(sharedMembers.map((m) => m.id));
    } else {
      setSharedMembersIds([]);
    }
    setOpenAssignToDialog(true);
  }, []);

  const loadData = React.useCallback(
    async (params, config) => {
      const { data, pagination } = await BuildPlanService.getBuildPlans(
        {
          ...params,
          withRelated: ['tags', 'files', 'components', 'assignee', 'project'],
          onlyOwned: filterOwnedBuildPlans,
          onlyApproved: filterApprovedBuildPlans,
          onlyPrototypes: filterPrototypes,
          projectIds: chosenProjects.map((project) => project.id),
          ...(filterAssignedBuildPlans && { assigneeIds: [currentMemberId] }),
        },
        'v2',
        config,
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount,
      };
    },
    [
      filterOwnedBuildPlans,
      filterAssignedBuildPlans,
      filterApprovedBuildPlans,
      filterPrototypes,
      chosenProjects,
      currentMemberId,
    ],
  );

  const columns = React.useMemo(() => {
    const columns = [
      {
        headerName: 'Preview',
        field: 'preview',
        minWidth: 140,
        sortable: false,
        visibilityBreakpoint: 'sm',
        renderCell: ({ row }) => <BuildPlanThumbnail buildPlan={row} />,
      },
      {
        headerName: 'Name',
        field: 'name',
        hideable: false,
        flex: 1,
        minWidth: 300,
        sortable: false,
        visibilityBreakpoint: 'sm',
        renderCell: ({ row, colDef }) => (
          <Box>
            <BuildPlanLink buildPlan={row} sx={{ whiteSpace: 'pre-wrap' }} />
            <BuildPlanTags buildPlan={row} width={colDef.computedWidth} />
          </Box>
        ),
        onCellClick: ({ row }) => {
          if (row) {
            history.push({
              pathname: ROUTES.BUILD_PLAN_V2(row.id),
              state: { from: location.pathname },
            });
          }
        },
      },
      {
        headerName: 'Project',
        field: 'project',
        hideable: false,
        flex: 1,
        minWidth: 200,
        sortable: false,
        visibilityBreakpoint: 'sm',
        renderCell: ({ row, colDef }) =>
          row.projectId ? (
            <ProjectLink
              project={row.project}
              width={colDef.computedWidth}
              onClick={() => {
                history.push({
                  pathname: ROUTES.PROJECT(row.projectId),
                  state: { from: location.pathname },
                });
              }}
            />
          ) : (
            ''
          ),
      },
      {
        headerName: 'Version',
        field: 'version',
        minWidth: 50,
        visibilityBreakpoint: 'lg',
        sortable: false,
      },
      {
        headerName: 'Uploaded',
        field: 'uploaded',
        minWidth: 150,
        sortable: false,
        visibilityBreakpoint: 'sm',
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />,
      },
      {
        headerName: 'Share Type',
        field: 'shareType',
        minWidth: 150,
        visibilityBreakpoint: 'lg',
        sortable: false,
      },
    ];

    if (
      roles.map((r) => r.id).includes(ROLES.ADMIN) ||
      roles.map((r) => r.id).includes(ROLES.APPLICATION_ENGINEER)
    ) {
      columns.push({
        headerName: '',
        field: 'actions',
        type: 'actions',
        width: 100,
        sortable: false,
        hideInMenu: true,
        visibilityBreakpoint: 'sm',
        getActions: (params) =>
          BuildPlanService.getBuildPlanActions(
            params.row,
            currentMemberId,
            roles,
            {
              onRename: () => {
                setEditableBuildPlan(params.row);
                setOpenBuildPlanDialog(true);
              },
              onShare: () => {
                setEditableBuildPlan(params.row);
                setOpenShareDialog(true);
              },
              onDelete: () => {
                setEditableBuildPlan(params.row);
                setDeleteSingle(true);
                setDeletingPlanHasPreforms(params.row.components.length > 0);
                setOpenDeleteDialog(true);
              },
              onToggleFavorite: (buildPlan) => updateBuildPlan(buildPlan),
              onAssignTo: () => {
                openAssignedToDialog(params.row);
              },
              onRemoveFromProject: () => {
                setEditableBuildPlan(params.row);
                setOpenRemoveFromProjectDialog(true);
              },
            },
          ).map((action) =>
            !action.label ? (
              <GridActionsCellItem
                icon={action.icon}
                label={action.label}
                onClick={action.onClick}
                showInMenu={false}
              />
            ) : (
              <GridActionsCellItem
                icon={action.icon}
                label={action.label}
                onClick={action.onClick}
                disabled={action.disabled}
                showInMenu
              />
            ),
          ),
      });
    }

    return columns;
  }, []);

  const gridOptionsObject = React.useMemo(() => {
    const gridOptionsObject = {
      orders: {
        created_at: SORT_ORDERS.DESC,
      },
      columns,
      pinnedColumns: {
        left: ['deleting', 'preview', 'name'],
        right: ['actions'],
      },
    };

    if (routeKey === 'page' && Number(routeValue) > 0) {
      gridOptionsObject.page = Number(routeValue);
    }

    if (routeKey === 'search' && routeValue) {
      gridOptionsObject.search = routeValue;
    }

    return gridOptionsObject;
  }, [routeKey, routeValue, columns]);

  const titanDataGridProps = useTitanDataGrid(loadData, gridOptionsObject);

  const { rows, page, setRows, setColumns, reloadData } = titanDataGridProps;

  const updateBuildPlan = React.useCallback(
    (buildPlan) =>
      setRows((prev) =>
        prev.map((bp) =>
          bp.id === buildPlan.id ? { ...bp, ...buildPlan } : bp,
        ),
      ),
    [rows],
  );

  const onUpdateBuildPlan = async (name) => {
    const updatedBuildPlan = await BuildPlanService.updateBuildPlan(
      editableBuildPlan.id,
      {
        name,
      },
      'v2',
    );

    setOpenBuildPlanDialog(false);
    updateBuildPlan(updatedBuildPlan);
  };

  const onDelete = async () => {
    await BuildPlanService.deleteBuildPlan(editableBuildPlan.id, 'v2');

    pushSnackbar('Build Plan successfully deleted', { variant: 'success' });
    setOpenDeleteDialog(false);

    setRows((prev) => prev.filter((bp) => bp.id !== editableBuildPlan.id));
    clearDeletablePlans();
  };

  const deleteMultiple = async (plansIds) => {
    await Promise.all(
      plansIds.map((planId) => {
        return BuildPlanService.deleteBuildPlan(planId, 'v2');
      }),
    );
    setOpenDeleteDialog(false);
    pushSnackbar('Build Plans successfully deleted', { variant: 'success' });
    setRows((prev) => prev.filter((bp) => !plansIds.includes(bp.id)));
    clearDeletablePlans();
  };

  const onChosenProjectsChange = (e, values) => setChosenProjects([...values]);

  const onAssignToBuildPlan = React.useCallback(
    async (member) => {
      const assigneeId = member ? member.id : null;

      await BuildPlanService.updateBuildPlan(
        editableBuildPlan.id,
        {
          assigneeId,
        },
        'v2',
      );

      setRows((prev) =>
        prev.map((bp) =>
          bp.id === editableBuildPlan.id ? { ...bp, assigneeId } : bp,
        ),
      );
    },
    [editableBuildPlan, setRows],
  );

  const onRemoveFromProject = React.useCallback(async () => {
    await BuildPlanService.updateBuildPlan(
      editableBuildPlan.id,
      {
        projectId: null,
      },
      'v2',
    );

    await reloadData();
  }, [editableBuildPlan, reloadData]);

  React.useEffect(() => {
    ProjectService.getProjects().then(({ data }) => {
      setProjects(data);
    });
  }, []);

  React.useEffect(() => {
    setColumns((prev) => {
      const newArray = [...prev].filter(
        (column) => column.field !== 'deleting',
      );
      if (filterOwnedBuildPlans) {
        newArray.unshift({
          headerName: '',
          field: 'deleting',
          width: 60,
          sortable: false,
          visibilityBreakpoint: 'sm',
          renderCell: ({ row }) => (
            <Checkbox
              checked={getDeletable(row)}
              onClick={() => {
                toggleDeletablePlan(row);
              }}
            />
          ),
        });
      }
      return newArray;
    });
  }, [filterOwnedBuildPlans, deletablePlans]);

  React.useEffect(() => {
    addPageToPageHistory({
      id: `BUILD_PLANS`,
      url: ROUTES.BUILD_PLANS('page', page),
      label: page === 0 ? `Build Plans` : `Build Plans | Page : ${page + 1}`,
    });
  }, [page]);

  return (
    <TitanPage
      breadcrumbs={breadcrumbs}
      title="Build Plans"
      headerContent={
        <>
          {deletablePlans.length > 0 && filterOwnedBuildPlans ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setDeletingPlanHasPreforms(
                  deletablePlans.some((dp) => dp.hasPreforms),
                );
                setOpenDeleteDialog(true);
              }}
              sx={{ marginLeft: 1 }}
            >
              Delete (<span>{deletablePlans.length}</span>) Plans
            </Button>
          ) : (
            ''
          )}
          {deletablePlans.length > 0 && filterOwnedBuildPlans ? (
            <Button
              variant="contained"
              color="primary"
              onClick={clearDeletablePlans}
              sx={{ marginLeft: 1 }}
            >
              Clear Selection
            </Button>
          ) : (
            ''
          )}
        </>
      }
    >
      <TitanDataGrid
        {...titanDataGridProps}
        components={{
          Toolbar: TitanDataGridToolbar,
          ColumnsPanel: CustomGridColumnsPanel,
        }}
        getRowHeight={STYLING_FUNCTIONS.MAKE_ROW_HEIGHT(110)}
        searchPlaceholder="Search by Build Plan name..."
        filtersContent={
          <BuildPlansTableFilter
            filterOwnedBuildPlans={filterOwnedBuildPlans}
            onChangeFilterOwnedBuildPlans={(value) =>
              setFilterOwnedBuildPlans(value)
            }
            filterAssignedBuildPlans={filterAssignedBuildPlans}
            onChangeFilterAssignedBuildPlans={(value) =>
              setFilterAssignedBuildPlans(value)
            }
            filterApprovedBuildPlans={filterApprovedBuildPlans}
            onChangeFilterApprovedBuildPlans={(value) =>
              setFilterApprovedBuildPlans(value)
            }
            filterPrototypes={filterPrototypes}
            onChangeFilterPrototypes={(buildPlanShowPrototypes) => {
              setFilterPrototypes(buildPlanShowPrototypes);
            }}
            projects={projects}
            chosenProjects={chosenProjects}
            onChosenProjectsChange={onChosenProjectsChange}
          />
        }
        chipsContent={
          <BuildPlansChips
            filterOwnedBuildPlans={filterOwnedBuildPlans}
            onChangeFilterOwnedBuildPlans={(value) =>
              setFilterOwnedBuildPlans(value)
            }
            filterAssignedBuildPlans={filterAssignedBuildPlans}
            onChangeFilterAssignedBuildPlans={(value) =>
              setFilterAssignedBuildPlans(value)
            }
            filterApprovedBuildPlans={filterApprovedBuildPlans}
            onChangeFilterApprovedBuildPlans={(value) =>
              setFilterApprovedBuildPlans(value)
            }
            filterPrototypes={filterPrototypes}
            onChangeFilterPrototypes={(buildPlanShowPrototypes) => {
              setFilterPrototypes(buildPlanShowPrototypes);
            }}
            chosenProjects={chosenProjects}
            onChosenProjectsChange={onChosenProjectsChange}
          />
        }
      />

      {openBuildPlanDialog && (
        <BuildPlanDialog
          buildPlan={editableBuildPlan}
          onClose={() => {
            setOpenBuildPlanDialog(false);
          }}
          currentMemberId={currentMemberId}
          onUpdate={onUpdateBuildPlan}
        />
      )}

      {openDeleteDialog && (
        <TitanConfirmationDialog
          title="Delete Build Plan?"
          message={`Are you sure you want to delete Build Plan${
            !deleteSingle && deletablePlans.length > 1 ? 's' : ''
          } ${
            deleteSingle
              ? editableBuildPlan.name
              : deletablePlans.length === 1
              ? deletablePlans[0].name
              : ''
          }?`}
          onClose={() => {
            setOpenDeleteDialog(false);
            setDeletingPlanHasPreforms(false);
            setDeleteSingle(false);
          }}
          onConfirm={() =>
            deleteSingle
              ? onDelete()
              : deleteMultiple(deletablePlans.map((dP) => dP.id))
          }
        >
          {deletingPlanHasPreforms && (
            <Typography sx={{ color: colors.LIGHT_RED }}>
              {`Plan${
                !deleteSingle && deletablePlans.length > 1 ? 's' : ''
              } contain preforms!`}
            </Typography>
          )}
        </TitanConfirmationDialog>
      )}

      {openShareDialog && (
        <BuildPlanShareDialog
          buildPlan={editableBuildPlan}
          onClose={() => {
            setOpenShareDialog(false);
          }}
          onSave={(buildPlan) => {
            setOpenShareDialog(false);
            updateBuildPlan(buildPlan);
          }}
        />
      )}

      {openAssignToDialog && (
        <MemberSelectionDialog
          title="Select member which you want to assign to this Build Plan"
          multipleSelection={false}
          currentMembers={
            editableBuildPlan.assignee ? [editableBuildPlan.assignee] : []
          }
          includeMemberIds={sharedMembersIds}
          onSave={onAssignToBuildPlan}
          onClose={() => setOpenAssignToDialog(false)}
        />
      )}

      {openRemoveFromProjectDialog && (
        <TitanConfirmationDialog
          title="Remove this Build Plan from Project?"
          message="Are you sure you want to remove this Build Plan from Project?"
          onClose={() => {
            setOpenRemoveFromProjectDialog(false);
          }}
          onConfirm={() => onRemoveFromProject()}
        />
      )}
    </TitanPage>
  );
}
