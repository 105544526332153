import React from 'react';
import TextField from '@mui/material/TextField';
import TitanDialog from '../Titan/TitanDialog';
import REGEXPS from '../../constants/regexps';
import TitanCircularProgress from '../Titan/TitanCircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import useAsyncEffect from 'use-async-effect';
import ProjectService from '../../services/ProjectService';

export default function FusionPlanDialog({
  fusionPlan,
  onClose,
  onSave,
  currentMemberId,
}) {
  const [fusionPlanName, setFusionPlanName] = React.useState(
    fusionPlan.name || '',
  );
  const [planKey, setPlanKey] = React.useState(fusionPlan.planKey || '');
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [selectedProjectId, setSelectedProjectId] = React.useState(
    fusionPlan ? fusionPlan.projectId : null,
  );
  const [projects, setProjects] = React.useState([]);
  const [projectsLoading, setProjectsLoading] = React.useState(false);

  const onSubmitForm = React.useCallback(
    () =>
      onSave({
        name: fusionPlanName,
        planKey,
        projectId: selectedProjectId,
      }),
    [fusionPlanName, planKey, selectedProjectId],
  );

  const isOwner =
    fusionPlan.memberId === currentMemberId || !fusionPlan.memberId;

  // Form Validation
  React.useEffect(() => {
    const planKeyValid =
      planKey.length > 0 ? REGEXPS.PLAN_KEY.test(planKey) : true;

    setIsFormValid(!!fusionPlanName && planKeyValid);
  }, [planKey, fusionPlanName]);

  useAsyncEffect(async () => {
    setProjectsLoading(true);
    const projects = await ProjectService.getProjects({
      page: 0,
      pageSize: 100,
      order: ['name:asc'],
    });
    setProjects(projects.data);
    setProjectsLoading(false);
  }, []);

  return (
    <TitanDialog
      title={`${fusionPlan.id ? 'Update' : 'Create'} Fusion Plan`}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth
      onSave={onSubmitForm}
      isValid={isFormValid}
    >
      <TextField
        autoFocus
        id="name"
        label="name"
        type="text"
        value={fusionPlanName}
        fullWidth
        margin="normal"
        onChange={(e) => setFusionPlanName(e.target.value)}
        disabled={!isOwner}
        variant="outlined"
      />
      <TextField
        id="planKey"
        label="Plan key"
        type="text"
        disabled={fusionPlan.hasFinalParts}
        value={planKey}
        fullWidth
        margin="normal"
        onChange={(e) => setPlanKey(e.target.value.toUpperCase())}
        variant="outlined"
        InputProps={{ inputProps: { style: { textTransform: 'uppercase' } } }}
      />
      {projectsLoading ? (
        <TitanCircularProgress />
      ) : (
        <FormControl fullWidth>
          <InputLabel id="project-label">Project</InputLabel>
          <Select
            labelId="project-label"
            value={selectedProjectId}
            label="Project"
            onChange={(e) => setSelectedProjectId(e.target.value)}
          >
            {projects.map((project) => (
              <MenuItem value={project.id} key={project.id}>
                {project.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </TitanDialog>
  );
}
