import axios from 'axios';

export default class MeasuringDeviceService {
  static async getMeasuringDevices(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v2/measuring-devices`, {
        ...config,
        params,
      })
      .then((res) => res.data);
  }

  static createMeasuringDevice(data) {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/v2/measuring-devices`, data)
      .then((res) => res.data);
  }

  static updateMeasuringDevice(measuringDeviceId, data) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v2/measuring-devices/${measuringDeviceId}`,
        data,
      )
      .then((res) => res.data);
  }

  static deleteMeasuringDevice(measuringDeviceId) {
    return axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/v2/measuring-devices/${measuringDeviceId}`,
      )
      .then((res) => res.data);
  }

  static createNewCertificate(measuringDeviceId) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v2/measuring-devices/${measuringDeviceId}/create-new-certificate`,
        {},
      )
      .then((res) => res.data);
  }

  static measure(measuringDeviceId) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v2/measuring-devices/${measuringDeviceId}/measure`,
        {},
      )
      .then((res) => res.data);
  }
}
