import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'typeface-roboto';
import { createGlobalStyle } from 'styled-components';
import { LicenseInfo } from '@mui/x-license-pro';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import NotificationSocketHandlers from './components/Notifications/NotificationSocketHandlers';

import Router from './components/Router/Router';
import { Auth0Provider } from './components/Authentication/Auth0';
import { TitanProvider } from './components/Titan/Titan';
import TitanThemeProvider from './components/Theme/TitanThemeProvider';
import CheckTerms from './components/Authentication/CheckTerms';

import 'react-image-gallery/styles/css/image-gallery.css';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', sans-serif;
  }
  .rc-slider-tooltip {
    z-index: 9999;
  }
`;

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

export default function App() {
  return (
    <TitanThemeProvider>
      <BrowserRouter>
        <SnackbarProvider>
          <CssBaseline />
          <GlobalStyle />
          <Auth0Provider>
            <TitanProvider>
              <CheckTerms>
                <NotificationSocketHandlers />
                <Router />
              </CheckTerms>
            </TitanProvider>
          </Auth0Provider>
        </SnackbarProvider>
      </BrowserRouter>
    </TitanThemeProvider>
  );
}
