import React, { useEffect } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import { makeStyles } from '@mui/styles';
import TitanDialog from './TitanDialog';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(1)
  }
}));

export default function TitanEditableSelect({
  text,
  label,
  emptyValue = '-',
  onChangeValue,
  enableEditing = true,
  inputType = 'SINGLE_SELECT',
  options = [],
  disableReason
}) {
  const classes = useStyles();

  const [openEditTextDialog, setOpenEditTextDialog] = React.useState(false);
  const [updatedValue, setUpdatedValue] = React.useState(text);

  const [multipleSelectValue, setMultipleSelectValue] = React.useState(
    text.length > 0 ? text.split(', ') : []
  );

  useEffect(() => {
    setUpdatedValue(multipleSelectValue.join(', '));
  }, [multipleSelectValue]);

  const handleCheckboxChange = e => {
    if (e.target.checked) {
      setMultipleSelectValue(
        Array.from(new Set([...multipleSelectValue, e.target.value]))
      );
    } else {
      setMultipleSelectValue(
        multipleSelectValue.filter(item => item !== e.target.value)
      );
    }
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography display="inline" variant="body1">
        {text ? text : emptyValue}
      </Typography>

      <Tooltip
        title={disableReason}
        disableHoverListener={!disableReason}
        disableTouchListener={!disableReason}
      >
        <div>
          <IconButton
            size="large"
            disabled={!enableEditing}
            className={classes.button}
            onClick={() => setOpenEditTextDialog(true)}
          >
            <CreateIcon />
          </IconButton>
        </div>
      </Tooltip>

      {openEditTextDialog && (
        <TitanDialog
          title={`Edit ${label}`}
          onClose={() => setOpenEditTextDialog(false)}
          maxWidth="md"
          fullWidth
          onSave={() => onChangeValue(updatedValue)}
        >
          {inputType === 'MULTI_SELECT' ? (
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                {options.map(item => (
                  <FormControlLabel
                    key={item}
                    value={item}
                    onChange={e => handleCheckboxChange(e)}
                    control={
                      <Checkbox
                        checked={multipleSelectValue.includes(item)}
                        name={label}
                      />
                    }
                    label={item}
                  />
                ))}
              </FormGroup>
            </FormControl>
          ) : (
            <FormControl>
              <RadioGroup
                value={updatedValue}
                onChange={e => setUpdatedValue(e.target.value)}
              >
                {options.map(radioItem => (
                  <FormControlLabel
                    key={radioItem}
                    value={radioItem}
                    control={<Radio />}
                    label={radioItem}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}
        </TitanDialog>
      )}
    </Stack>
  );
}
