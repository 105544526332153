import React from 'react';
import PrinterService from '../../services/PrinterService';
import Grid from '@mui/material/Grid';
import TitanCircularProgress from '../Titan/TitanCircularProgress';
import BuildModuleCard from './BuildModuleCard';
import Box from '@mui/material/Box';
import useBuildModules from './use-build-modules';

export default function DashboardBuildModules({ params }) {
  const loadBuildModules = React.useCallback(async () => {
    const queryParams = {
      page: 1,
      pageSize: 2,
      withRelated: [
        'activeJobs',
        'activeJobs.buildPlan',
        'activeJobs.components',
        'prevBuildJob',
        'prevBuildJob.buildPlan',
        'prevBuildJob.components'
      ],
      ...params
    };

    const response = await PrinterService.getPrinters(queryParams);

    return response.data;
  }, []);

  const { buildModules, loading } = useBuildModules(loadBuildModules);

  return (
    <Box sx={{ minHeight: 260 }}>
      {loading ? (
        <TitanCircularProgress height={260} />
      ) : (
        <Grid container spacing={2}>
          {buildModules.map(buildModule => (
            <Grid key={buildModule.id} item md={6} sm={12} xs={12}>
              <BuildModuleCard buildModule={buildModule} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}
