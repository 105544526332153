import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { ROLES } from '../constants';

export default class ManufacturingOrderService {
  static getManufacturingOrders(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v2/manufacturing-orders`, {
        ...config,
        params
      })
      .then(res => res.data);
  }

  static getManufacturingOrderById(id, params = {}) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v2/manufacturing-orders/${id}`,
        {
          params
        }
      )
      .then(res => res.data);
  }

  static createManufacturingOrder(data) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v2/manufacturing-orders`,
        data
      )
      .then(res => res.data.data);
  }

  static updateManufacturingOrder(id, data) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v2/manufacturing-orders/${id}`,
        data
      )
      .then(res => res.data.data);
  }

  static deleteManufacturingOrder(id) {
    return axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/v2/manufacturing-orders/${id}`
      )
      .then(res => res.data);
  }

  static attachBuildPlan(manufacturingOrderId, buildPlanId) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/manufacturing-orders/${manufacturingOrderId}/build-plans/${buildPlanId}`
      )
      .then(res => res.data);
  }

  static detachBuildPlan(manufacturingOrderId, buildPlanId) {
    return axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/manufacturing-orders/${manufacturingOrderId}/build-plans/${buildPlanId}`
      )
      .then(res => res.data);
  }

  static attachFusionPlan(manufacturingOrderId, fusionPlanId) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/manufacturing-orders/${manufacturingOrderId}/fusion-plans/${fusionPlanId}`
      )
      .then(res => res.data);
  }

  static detachFusionPlan(manufacturingOrderId, fusionPlanId) {
    return axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/manufacturing-orders/${manufacturingOrderId}/fusion-plans/${fusionPlanId}`
      )
      .then(res => res.data);
  }

  static getPreformsCount(manufacturingOrderId) {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/manufacturing-orders/${manufacturingOrderId}/preforms-count`
      )
      .then(res => res.data);
  }

  static getFinalPartsCount(manufacturingOrderId) {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/manufacturing-orders/${manufacturingOrderId}/final-parts-count`
      )
      .then(res => res.data);
  }

  static getManufacturingOrderActions({
    manufacturingOrder,
    currentMember,
    memberRoles,
    onEdit,
    onDelete,
    plan,
    onDetachPlan
  }) {
    const isOrganizationAdmin = !!memberRoles.find(r => r.id === ROLES.ADMIN);
    const isApplicationEngineer = !!memberRoles.find(
      r => r.id === ROLES.APPLICATION_ENGINEER
    );
    const isUserMember =
      currentMember.organizationId === manufacturingOrder.organizationId;
    const isUserPlanOwner = plan?.memberId === currentMember.id;

    const actions = [];

    if (onEdit) {
      actions.push({
        label: 'Edit',
        icon: <EditIcon />,
        onClick: onEdit,
        disabled:
          !(isOrganizationAdmin || isApplicationEngineer) || !isUserMember
      });
    }

    if (onDelete) {
      actions.push({
        label: 'Delete',
        icon: <DeleteIcon />,
        onClick: onDelete,
        disabled:
          !(isOrganizationAdmin || isApplicationEngineer) || !isUserMember
      });
    }

    if (onDetachPlan) {
      actions.push({
        label: 'Disconnect',
        icon: <RemoveCircleIcon />,
        onClick: onDetachPlan,
        disabled:
          (!isUserPlanOwner &&
            !(isOrganizationAdmin || isApplicationEngineer)) ||
          !isUserMember
      });
    }

    return actions;
  }

  static attachSpool(manufacturingOrderId, spoolId) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/manufacturing-orders/${manufacturingOrderId}/spools/${spoolId}`
      )
      .then(res => res.data);
  }

  static detachSpool(manufacturingOrderId, spoolId) {
    return axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/manufacturing-orders/${manufacturingOrderId}/spools/${spoolId}`
      )
      .then(res => res.data);
  }

  static attachBuildModule(manufacturingOrderId, buildModuleId) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/manufacturing-orders/${manufacturingOrderId}/build-modules/${buildModuleId}`
      )
      .then(res => res.data);
  }

  static detachBuildModule(manufacturingOrderId, buildModuleId) {
    return axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/manufacturing-orders/${manufacturingOrderId}/build-modules/${buildModuleId}`
      )
      .then(res => res.data);
  }

  static attachFusionModule(manufacturingOrderId, fusionModuleId) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/manufacturing-orders/${manufacturingOrderId}/fusion-modules/${fusionModuleId}`
      )
      .then(res => res.data);
  }

  static detachFusionModule(manufacturingOrderId, fusionModuleId) {
    return axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/manufacturing-orders/${manufacturingOrderId}/fusion-modules/${fusionModuleId}`
      )
      .then(res => res.data);
  }
}
