import React from 'react';
import { useParams } from 'react-router-dom';

import { useAuth0 } from '../Authentication/Auth0';
import { useTitan } from '../Titan/Titan';
import { ROUTES } from '../../constants';

import { Grid, Box, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import TitanPage from '../Titan/TitanPage';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import PreBuildingChecklistSectionDialog from './PreBuildingChecklistSectionDialog';
import PreBuildingChecklistStepDialog from './PreBuildingChecklistStepDialog';
import ActionsMenu from '../Menu/ActionsMenu';

import PreBuildingChecklistService from '../../services/PreBuildingChecklistService';

async function updateRowPosition(initialIndex, newIndex, rows, rowsType) {
  const rowsUpdated = [...rows];
  const row = rowsUpdated.splice(initialIndex, 1)[0];
  rowsUpdated.splice(newIndex, 0, row);

  if (rowsType === 'sections') {
    await Promise.all(
      rowsUpdated.map((section, index) =>
        PreBuildingChecklistService.updateChecklistSection(section.id, {
          order: index
        })
      )
    );
  } else if (rowsType === 'steps') {
    await Promise.all(
      rowsUpdated.map((step, index) => {
        PreBuildingChecklistService.updateChecklistSectionStep(step.id, {
          order: index
        });
      })
    );
  }

  return rowsUpdated;
}

export default function PreBuildingChecklistPage() {
  const { preBuildingChecklistId } = useParams();
  const { isSuperAdmin } = useAuth0();
  const { pushSnackbar } = useTitan();

  const [loading, setLoading] = React.useState(true);

  const [checklist, setChecklist] = React.useState(null);
  const [sections, setSections] = React.useState([]);
  const [steps, setSteps] = React.useState([]);

  const [selectedSection, setSelectedSection] = React.useState(null);
  const [selectedSectionSteps, setSelectedSectionSteps] = React.useState([]);

  const [editableSection, setEditableSection] = React.useState({});
  const [editableStep, setEditableStep] = React.useState({});

  const [sectionDialogMode, setSectionDialogMode] = React.useState('create');
  const [stepDialogMode, setStepDialogMode] = React.useState('create');
  const [openSectionDialog, setOpenSectionDialog] = React.useState(false);
  const [openSectionDeleteDialog, setOpenSectionDeleteDialog] = React.useState(
    false
  );
  const [openStepDialog, setOpenStepDialog] = React.useState(false);
  const [openStepDeleteDialog, setOpenStepDeleteDialog] = React.useState(false);

  React.useEffect(() => {
    if (selectedSection) {
      initSectionSteps(selectedSection);
    }
  }, [steps, selectedSection]);

  const loadChecklistInfo = async () => {
    const { data } = await PreBuildingChecklistService.getChecklistById(
      preBuildingChecklistId,
      { withRelated: ['sections', 'steps'] }
    );

    setChecklist({ name: data.name });

    const sortedSections = data.sections.sort(
      (sectionOne, sectionTwo) => sectionOne.order - sectionTwo.order
    );
    setSections(sortedSections);

    const sortedSteps = data.steps.sort(
      (stepOne, stepTwo) => stepOne.order - stepTwo.order
    );
    setSteps(sortedSteps);
  };

  const initSectionSteps = section => {
    setSelectedSectionSteps(
      steps.filter(step => step.sectionId === section.id)
    );
  };

  const onSelectSection = section => {
    setSelectedSection(section);
  };

  const handleSectionsOrderChange = async params => {
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      sections,
      'sections'
    );

    setSections(newRows);
  };

  const handleStepsOrderChange = async params => {
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      selectedSectionSteps,
      'steps'
    );

    setSelectedSectionSteps(newRows);
  };

  const onSectionSave = React.useCallback(
    section => {
      if (sectionDialogMode === 'create') {
        setSections(prev => [...prev, section]);
      } else {
        setSections(prev =>
          prev.map(ps => (ps.id === section.id ? { ...ps, ...section } : ps))
        );
      }
    },
    [sections, editableSection, sectionDialogMode]
  );

  const onStepSave = React.useCallback(
    step => {
      if (stepDialogMode === 'create') {
        setSteps(prev => [...prev, step]);
      } else {
        setSteps(prev =>
          prev.map(ps => (ps.id === step.id ? { ...ps, ...step } : ps))
        );
      }
    },
    [setSteps, editableStep, stepDialogMode]
  );

  const onSectionDelete = async () => {
    await PreBuildingChecklistService.deleteChecklistSection(
      editableSection.id
    );

    pushSnackbar("Checklist's section successfully deleted", {
      variant: 'success'
    });
    setOpenSectionDeleteDialog(false);
    setSections(prev => prev.filter(ps => ps.id !== editableSection.id));
  };

  const onStepDelete = async () => {
    await PreBuildingChecklistService.deleteChecklistSectionStep(
      editableStep.id
    );

    pushSnackbar("Checklist's step successfully deleted", {
      variant: 'success'
    });
    setOpenStepDeleteDialog(false);
    setSteps(prev => prev.filter(ps => ps.id !== editableStep.id));
  };

  React.useEffect(() => {
    setLoading(true);

    loadChecklistInfo().then(() => setLoading(false));
  }, []);

  React.useEffect(() => {
    if (sections.length > 0) {
      onSelectSection(sections[0]);
    }
  }, [loading]);

  const breadcrumbs = React.useMemo(
    () => [
      ...(isSuperAdmin
        ? [
            {
              name: 'Admin Dashboard',
              path: ROUTES.ADMIN_DASHBOARD
            }
          ]
        : []),
      {
        name: 'Pre Building Checklists',
        path: ROUTES.ADMIN_DASHBOARD_TAB('pre-building-checklists'),
        disabled: !isSuperAdmin
      },
      ...(checklist
        ? [
            {
              name: checklist.name,
              disabled: true
            }
          ]
        : [])
    ],
    [checklist]
  );

  const sectionsColumns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        flex: 1,
        sortable: false
      },
      {
        headerName: '',
        field: 'actions',
        width: 60,
        sortable: false,
        renderCell: ({ row }) => (
          <ActionsMenu
            items={[
              {
                title: 'Edit',
                icon: EditIcon,
                onClick: () => {
                  setEditableSection(row);
                  setSectionDialogMode('edit');
                  setOpenSectionDialog(true);
                }
              },
              {
                title: 'Delete',
                icon: DeleteIcon,
                onClick: () => {
                  setEditableSection(row);
                  setOpenSectionDeleteDialog(true);
                }
              }
            ]}
          />
        )
      }
    ],
    []
  );

  const stepsColumns = React.useMemo(
    () => [
      {
        headerName: 'Description',
        field: 'description',
        flex: 1,
        height: 300,
        sortable: false,
        cellClassName: 'step-description-cell',
        renderCell: ({ row }) => (
          <div>
            <Typography>{row.description}</Typography>
          </div>
        )
      },
      {
        headerName: 'Image',
        field: 'image',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => (
          <Box
            component="img"
            sx={{
              maxWidth: 'auto',
              maxHeight: 100
            }}
            src={row.imageUrl}
            alt=""
          />
        )
      },
      {
        headerName: '',
        field: 'actions',
        width: 60,
        sortable: false,
        renderCell: ({ row }) => (
          <ActionsMenu
            items={[
              {
                title: 'Edit',
                icon: EditIcon,
                onClick: () => {
                  setEditableStep(row);
                  setStepDialogMode('edit');
                  setOpenStepDialog(true);
                }
              },
              {
                title: 'Delete',
                icon: DeleteIcon,
                onClick: () => {
                  setEditableStep(row);
                  setOpenStepDeleteDialog(true);
                }
              }
            ]}
          />
        )
      }
    ],
    []
  );

  const getRowHeight = React.useCallback(() => 100, []);

  return (
    <TitanPage
      title={checklist ? checklist.name : ''}
      loading={loading}
      breadcrumbs={breadcrumbs}
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TitanDataGrid
            title="Sections"
            showSearch={false}
            pagination={false}
            columns={sectionsColumns}
            rows={sections}
            rowCount={sections ? sections.length : 0}
            rowReordering={true}
            onRowOrderChange={handleSectionsOrderChange}
            disableMultipleSelection={true}
            isRowSelectable={() => true}
            onRowClick={(e, section) => onSelectSection(section)}
            onCreateClick={() => {
              setEditableSection({});
              setSectionDialogMode('create');
              setOpenSectionDialog(true);
            }}
            createButtonLabel="Section"
          />

          {openSectionDialog && (
            <PreBuildingChecklistSectionDialog
              checklistId={preBuildingChecklistId}
              section={editableSection}
              order={sections.length}
              mode={sectionDialogMode}
              onClose={() => setOpenSectionDialog(false)}
              onSave={onSectionSave}
            />
          )}

          {openSectionDeleteDialog && (
            <TitanConfirmationDialog
              title="Delete checklist's section?"
              message={`Are you sure you want to delete section ${
                editableSection.name
              }?`}
              onClose={() => setOpenSectionDeleteDialog(false)}
              onConfirm={() => onSectionDelete()}
            />
          )}
        </Grid>

        <Grid
          item
          xs={9}
          sx={{
            '& .MuiDataGrid-cell.step-description-cell': {
              whiteSpace: 'normal'
            }
          }}
        >
          <TitanDataGrid
            title="Steps"
            showSearch={false}
            pagination={false}
            columns={stepsColumns}
            rows={selectedSectionSteps}
            rowCount={selectedSectionSteps.length}
            rowReordering={true}
            onRowOrderChange={handleStepsOrderChange}
            getRowHeight={getRowHeight}
            onCreateClick={() => {
              setEditableStep({});
              setStepDialogMode('create');
              setOpenStepDialog(true);
            }}
            createButtonLabel="Step"
            onCreateClickDisabled={!selectedSection}
            onCreateClickDisabledTooltip="Create section at first"
          />

          {openStepDialog && (
            <PreBuildingChecklistStepDialog
              checklistId={preBuildingChecklistId}
              section={selectedSection}
              step={editableStep}
              order={selectedSectionSteps.length}
              mode={stepDialogMode}
              onClose={() => setOpenStepDialog(false)}
              onSave={onStepSave}
            />
          )}

          {openStepDeleteDialog && (
            <TitanConfirmationDialog
              title="Delete checklist section's step?"
              message={`Are you sure you want to delete step ${
                editableStep.checkNumber
              }?`}
              onClose={() => setOpenStepDeleteDialog(false)}
              onConfirm={() => onStepDelete()}
            />
          )}
        </Grid>
      </Grid>
    </TitanPage>
  );
}
