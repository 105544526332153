import React from 'react';
import { makeStyles } from '@mui/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import TitanPopover from '../Titan/TitanPopover';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 450,
    padding: theme.spacing(2)
  },
  activeJobs: {
    width: {
      xs: '50px',
      md: 'auto'
    }
  },
  buildModulesFilter: {
    marginTop: theme.spacing(1)
  }
}));

export default function BuildJobsFilter({
  showAllJobs,
  setShowAllJobs,
  hideBuildModulesFilter = false,
  buildModules,
  chosenBuildModules,
  onBuildModulesChange
}) {
  const classes = useStyles();

  return (
    <TitanPopover icon={FilterListIcon}>
      <div className={classes.root}>
        <FormControl>
          <FormLabel>Jobs Type</FormLabel>
          <RadioGroup
            row
            value={showAllJobs}
            onChange={e => setShowAllJobs(e.target.value)}
          >
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Filtered Jobs"
            />
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="All Jobs"
            />
          </RadioGroup>
        </FormControl>

        {!hideBuildModulesFilter && (
          <FormControl margin="normal">
            <FormLabel>Build Modules</FormLabel>
            <Autocomplete
              multiple
              id="build-modules-filter"
              value={chosenBuildModules}
              onChange={onBuildModulesChange}
              options={buildModules}
              getOptionLabel={option => option.name}
              filterSelectedOptions
              className={classes.buildModulesFilter}
              renderInput={params => (
                <TextField {...params} placeholder="Select Build Modules..." />
              )}
            />
          </FormControl>
        )}
      </div>
    </TitanPopover>
  );
}
