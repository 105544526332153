import React, { useRef, useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';

const OverflowTip = ({ children, width }) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef();
  useEffect(() => {
    setIsOverflow(
      textElementRef.current.clientWidth < textElementRef.current.scrollWidth
    );
  }, [width]);

  return (
    <Tooltip title={children} disableHoverListener={!isOverflowed}>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default OverflowTip;
