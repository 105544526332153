import React from 'react';

import { useBuildDialog } from './BuildDialog';

import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import Box from '@mui/material/Box';
import BuildPlanThumbnail from '../BuildPlan/BuildPlanThubnail';
import BuildPlanLink from '../BuildPlan/BuildPlanLink';
import BuildPlanTags from '../BuildPlan/BuildPlanTags';
import { ROUTES, STYLING_FUNCTIONS } from '../../constants';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import BuildPlanService from '../../services/BuildPlanService';
import { useHistory, useLocation } from 'react-router-dom';

export default function BuildDialogBuildPlansStep() {
  const history = useHistory();
  const location = useLocation();
  const { selectedManufacturingOrder, setSelectedBuildPlan } = useBuildDialog();

  const [selectedBuildPlans, setSelectedBuildPlans] = React.useState([]);

  const columns = React.useMemo(() => {
    const columns = [
      {
        headerName: 'Preview',
        field: 'preview',
        minWidth: 140,
        sortable: false,
        visibilityBreakpoint: 'sm',
        renderCell: ({ row }) => <BuildPlanThumbnail buildPlan={row} />,
      },
      {
        headerName: 'Name',
        field: 'name',
        hideable: false,
        flex: 1,
        minWidth: 300,
        sortable: false,
        visibilityBreakpoint: 'sm',
        renderCell: ({ row, colDef }) => (
          <Box>
            <BuildPlanLink buildPlan={row} width={colDef.computedWidth} />
            <BuildPlanTags buildPlan={row} width={colDef.computedWidth} />
          </Box>
        ),
        onCellClick: ({ row }) => {
          if (row) {
            history.push({
              pathname: ROUTES.BUILD_PLAN_V2(row.id),
              state: { from: location.pathname },
            });
          }
        },
      },
      {
        headerName: 'Tags',
        field: 'tags',
        minWidth: 300,
        sortable: false,
        visibilityBreakpoint: 'lg',
        valueGetter: ({ row }) => (row.tags ? row.tags.join(', ') : ''),
      },
      {
        headerName: 'Version',
        field: 'version',
        minWidth: 50,
        visibilityBreakpoint: 'lg',
        sortable: false,
      },
      {
        headerName: 'Uploaded',
        field: 'uploaded',
        minWidth: 150,
        sortable: false,
        visibilityBreakpoint: 'sm',
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />,
      },
    ];

    return columns;
  }, []);

  const loadData = React.useCallback(
    async (params, config) => {
      const { data, pagination } = await BuildPlanService.getBuildPlans(
        {
          ...params,
          withRelated: ['tags', 'files', 'assignee'],
          ...(selectedManufacturingOrder
            ? { manufacturingOrderIds: [selectedManufacturingOrder.id] }
            : {}),
        },
        'v2',
        config,
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount,
      };
    },
    [selectedManufacturingOrder],
  );

  const buildPlansDataGridProps = useTitanDataGrid(loadData, {
    columns,
  });

  React.useEffect(() => {
    setSelectedBuildPlan(
      selectedBuildPlans.length !== 0 ? selectedBuildPlans[0] : null,
    );
  }, [selectedBuildPlans]);

  return (
    <Box sx={{ p: 2 }}>
      <TitanDataGrid
        {...buildPlansDataGridProps}
        getRowHeight={STYLING_FUNCTIONS.MAKE_ROW_HEIGHT(110)}
        searchPlaceholder="Search by Build Plan name..."
        checkboxSelection
        selectedRows={selectedBuildPlans}
        onSelectionModelChange={(selectedBuildPlans) => {
          setSelectedBuildPlans(selectedBuildPlans);
        }}
      />
    </Box>
  );
}
