import axios from 'axios';

export default class MemberService {
  static async loadOrganizationMembers(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/members`, {
        ...config,
        params
      })
      .then(res => res.data);
  }

  static createMember(data) {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/members`, data)
      .then(res => res.data.data);
  }

  static updateMember(id, data) {
    return axios
      .patch(`${process.env.REACT_APP_API_URL}/api/v1/members/${id}`, data)
      .then(res => res.data.data);
  }

  static deleteMember(id) {
    return axios
      .delete(`${process.env.REACT_APP_API_URL}/api/v1/members/${id}`)
      .then(res => res.data.data);
  }

  static updateMemberRole(id, roleId) {
    return axios
      .put(`${process.env.REACT_APP_API_URL}/api/v1/members/${id}/role`, {
        roleId
      })
      .then(res => res.data.data);
  }

  static loginAsMember(data) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/members/login-as-member`,
        data
      )
      .then(res => res.data.data);
  }

  static logoutFromMember() {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/members/logout-from-member`
      )
      .then(res => res.data.data);
  }

  static async checkMemberExists(email, organizationId) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/members/check-member-exists`,
        {
          params: { email, organizationId }
        }
      )
      .then(res => res.data.exists);
  }

  static async resendInviteForMember(memberId) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/members/${memberId}/send-verification-email`
      )
      .then(res => res.data);
  }

  static mergeAccounts(memberId, targetMemberId) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/members/${memberId}/merge`,
        {
          targetMemberId
        }
      )
      .then(res => res.data.data);
  }
}
