import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import React from 'react';
import { colors } from '../Theme/vars';
import { makeStyles } from '@mui/styles';
import emptyImage from '../../assets/icons/empty-fusion-plan.svg';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

export default function TitanTableEmptyData({
  image = emptyImage,
  title = 'Empty data',
  buttonLabel,
  onCreateClick
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={image} alt="" />
      <Box textAlign="center" color={colors.GREY} mt={1}>
        <Typography variant="body1">{title}</Typography>
      </Box>
      {buttonLabel && (
        <Box textAlign="center" mt={1}>
          <Button onClick={onCreateClick} color="primary">
            {buttonLabel}
          </Button>
        </Box>
      )}
    </div>
  );
}
