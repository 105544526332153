import React from 'react';
import PrinterModelService from '../../services/PrinterModelService';
import PrinterModelDialog from './PrinterModelDialog';
import ActionsMenu from '../Menu/ActionsMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import { useTitan } from '../Titan/Titan';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import { useParams } from 'react-router-dom';

export default function PrinterModelsManageList() {
  const [openPrinterModelDialog, setOpenPrinterModelDialog] = React.useState(
    false
  );
  const [dialogMode, setDialogMode] = React.useState('create');
  const [editablePrinterModel, setEditablePrinterModel] = React.useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const { pushSnackbar } = useTitan();

  const { page: pageParams = 0 } = useParams();

  const loadData = React.useCallback(async (query, config) => {
    const params = { ...query, withRelated: ['printerSeries'] };

    return PrinterModelService.getPrinterModels(params, config);
  }, []);

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        flex: 1,
        minWidth: 140,
        sortable: false
      },
      {
        headerName: 'Series',
        field: 'series',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) => row.printerSeries.name
      },
      {
        headerName: 'Bed shape',
        field: 'bedShape',
        minWidth: 200,
        sortable: false
      },
      {
        headerName: 'Created',
        field: 'created',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />
      },
      {
        headerName: '',
        field: 'actions',
        width: 60,
        renderCell: ({ row }) => (
          <ActionsMenu
            items={[
              {
                title: 'Edit',
                icon: EditIcon,
                onClick: () => {
                  setEditablePrinterModel(row);
                  setDialogMode('edit');
                  setOpenPrinterModelDialog(true);
                }
              },
              {
                title: 'Delete',
                icon: DeleteIcon,
                onClick: () => {
                  setEditablePrinterModel(row);
                  setOpenDeleteDialog(true);
                }
              }
            ]}
          />
        )
      }
    ],
    []
  );

  const gridOption = {
    columns,
    pinnedColumns: {
      left: ['name'],
      right: ['actions']
    }
  };

  if (pageParams && Number(pageParams) > 0) {
    gridOption.page = Number(pageParams);
  }

  const titanDataGridProps = useTitanDataGrid(loadData, gridOption);

  const { rows, setRows } = titanDataGridProps;

  const onSavePrinterModel = React.useCallback(
    printerSeries => {
      if (dialogMode === 'create') {
        setRows(prev => [printerSeries, ...prev]);
      } else {
        setRows(prev =>
          prev.map(ps =>
            ps.id === printerSeries.id ? { ...ps, ...printerSeries } : ps
          )
        );
      }
    },
    [rows, editablePrinterModel, dialogMode]
  );

  const onCloseDialog = () => {
    setOpenPrinterModelDialog(false);
  };

  const onDelete = React.useCallback(async () => {
    await PrinterModelService.deletePrinterModel(editablePrinterModel.id);

    pushSnackbar('Printer model successfully deleted', { variant: 'success' });
    setOpenDeleteDialog(false);
    setRows(prev => prev.filter(ps => ps.id !== editablePrinterModel.id));
  }, [editablePrinterModel]);

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        title="Printer models"
        onCreateClick={() => {
          setEditablePrinterModel({});
          setDialogMode('create');
          setOpenPrinterModelDialog(true);
        }}
        createButtonLabel="Printer Model"
      />

      {openPrinterModelDialog && (
        <PrinterModelDialog
          printerModel={editablePrinterModel}
          mode={dialogMode}
          onClose={() => onCloseDialog()}
          onSave={onSavePrinterModel}
        />
      )}

      {openDeleteDialog && (
        <TitanConfirmationDialog
          title="Delete printer model?"
          message={`Are you sure you want to delete printer model ${
            editablePrinterModel.name
          }?`}
          onClose={() => setOpenDeleteDialog(false)}
          onConfirm={() => onDelete()}
        />
      )}
    </>
  );
}
