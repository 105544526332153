import React from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { makeStyles } from '@mui/styles';
import {
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid-pro';
import { IconButton, Box } from '@mui/material';

const useStyles = makeStyles(theme => ({
  open: {
    transform: 'rotate(90deg)',
    transition: 'all 0.15s linear'
  },
  close: {
    transform: 'rotate(0)',
    transition: 'all 0.15s linear'
  },
  numberOfChildrensSpan: {
    marginLeft: '10px'
  }
}));

export const RenderTreeCell = props => {
  const classes = useStyles();

  const { id, field, rowNode, row, colDef, children } = props;

  const apiRef = useGridApiContext();

  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  );

  const filteredDescendantCount =
    filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick = event => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <>
      {filteredDescendantCount > 0 ? (
        <IconButton onClick={handleClick} sx={{ mr: 1.5 }}>
          <ArrowForwardIosSharpIcon
            sx={{ fontSize: '0.9rem' }}
            className={rowNode.childrenExpanded ? classes.open : classes.close}
          />
        </IconButton>
      ) : row.hierarchy.length > 1 ? (
        <Box sx={{ mr: 4.5 }} />
      ) : (
        ''
      )}

      {children(row, colDef)}
      {filteredDescendantCount > 0 ? (
        <span className={classes.numberOfChildrensSpan}>
          ({filteredDescendantCount})
        </span>
      ) : null}
    </>
  );
};
