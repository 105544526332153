import axios from 'axios';
import jsMD5 from 'js-md5';

export default class FDSReleaseService {
  static getReleases(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/fds-releases`, {
        ...config,
        params
      })
      .then(res => res.data);
  }

  static getReleaseById(id, params = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/fds-releases/${id}`, {
        params
      })
      .then(res => res.data.data);
  }

  static createRelease(data) {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/fds-releases`, data)
      .then(res => res.data.data);
  }

  static updateRelease(id, data) {
    return axios
      .patch(`${process.env.REACT_APP_API_URL}/api/v1/fds-releases/${id}`, data)
      .then(res => res.data.data);
  }

  static deleteRelease(id) {
    return axios
      .delete(`${process.env.REACT_APP_API_URL}/api/v1/fds-releases/${id}`)
      .then(res => res.data);
  }

  static async uploadReleaseFile(file) {
    const signedUrlToPut = await axios
      .put(`${process.env.REACT_APP_API_URL}/api/v1/files/upload-urls`, {
        files: [{ name: file.name }]
      })
      .then(res => res.data.data[0]);

    return new Promise(async resolve => {
      const reader = new FileReader();

      reader.onload = async event => {
        const arrayBuffer = event.target.result;
        const md5 = jsMD5(arrayBuffer);

        await axios.put(signedUrlToPut.url, file, {
          sendAuth0Headers: false
        });

        resolve({
          location: signedUrlToPut.location,
          name: file.name,
          md5: md5
        });
      };

      reader.readAsArrayBuffer(file);
    });
  }
}
