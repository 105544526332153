import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  InputAdornment,
  IconButton,
} from '@mui/material';
import TitanButton from '../Titan/TitanButton';
import AuthPageVerifyEmail from './AuthPageVerifyEmail';
import AuthPageForgotPassowrdSuccess from './AuthPageForgotPasswordSuccess';
import { WELCOME_PAGE_STATES } from './constants';
import regexps from '../../constants/regexps';
import { colors } from '../Theme/vars';
import GoogleIcon from '../../assets/icons/google.svg';
import PasswordHiddenIcon from '../../assets/icons/password-hidden.svg';
import PasswordVisibleIcon from '../../assets/icons/password-visible.svg';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('xs')]: {
      width: 375,
    },
    [theme.breakpoints.up('sm')]: {
      width: 600,
      paddingTop: 25,
      paddingBottom: 25,
    },
    [theme.breakpoints.up('md')]: {
      width: 480,
    },
  },
  inputs: {
    marginTop: 30,
  },
  button: {
    width: '100%',
    '& img': {
      marginRight: 15,
    },
  },
  actionLabel: {
    cursor: 'pointer',
  },
  generalErrors: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
  delimeterContainer: {
    display: 'flex',
    justifyContent: 'center',

    '& span': {
      position: 'relative',

      '&:before': {
        content: '""',
        borderTop: `1px solid ${colors.GREY2}`,
        width: 200,
        left: -212,
        top: 10,
        position: 'absolute',
      },
      '&:after': {
        display: 'block',
        content: '""',
        borderTop: `1px solid ${colors.GREY2}`,
        width: 200,
        right: -212,
        top: 10,
        position: 'absolute',
      },
    },
  },
}));

export default function AuthPage({
  pageState,
  setPageState,
  onAuth,
  onConnectionClick,
}) {
  const classes = useStyles();

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errors, setErrors] = React.useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = React.useState(false);
  const [passwordHidden, setPasswordHidden] = React.useState(true);

  const { title, buttonLabel } = React.useMemo(
    () => ({
      title:
        pageState === WELCOME_PAGE_STATES.LOG_IN
          ? 'Log In to Fibrify'
          : 'Sign Up to Fibrify',
      buttonLabel:
        pageState === WELCOME_PAGE_STATES.LOG_IN ? 'Log In' : 'Sign Up',
    }),
    [pageState],
  );

  const resetErrors = () => {
    setErrors({ email: '', password: '' });
  };

  const validate = ({ email, password }) => {
    const validationErrors = { email: '', password: '' };
    let valid = true;

    if (!email) {
      validationErrors.email = 'This field is required';
      valid = false;
    } else if (!regexps.EMAIL.test(email)) {
      validationErrors.email = 'Email is incorrect';
      valid = false;
    }

    if (pageState !== WELCOME_PAGE_STATES.FORGOT_PASSWORD) {
      if (!password) {
        validationErrors.password = 'This field is required';
        valid = false;
      } else if (password.length < 8) {
        validationErrors.password =
          'The password should have at least 8 symbols';
        valid = false;
      }
    }

    setErrors(validationErrors);
    return valid;
  };

  const handlePageStateChange = (state) => {
    setEmail('');
    setPassword('');
    resetErrors();

    setPageState(state);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const valid = validate({ email, password });

    if (!valid) {
      return;
    }

    setLoading(true);
    try {
      await onAuth({ email, password });
    } catch (err) {
      if (err) {
        if (err.name === 'PasswordStrengthError') {
          setErrors({ password: err.policy });
        } else if (err.description) {
          setErrors({ general: err.description });
        }
      }
    }
    setLoading(false);
  };

  if (pageState === WELCOME_PAGE_STATES.VERIFY_EMAIL) {
    return (
      <Card className={classes.container}>
        <CardContent>
          <AuthPageVerifyEmail setPageState={setPageState} />
        </CardContent>
      </Card>
    );
  }

  if (pageState === WELCOME_PAGE_STATES.FORGOT_PASSWORD_SUCCESS) {
    return (
      <Card className={classes.container}>
        <CardContent>
          <AuthPageForgotPassowrdSuccess setPageState={handlePageStateChange} />
        </CardContent>
      </Card>
    );
  }

  if (pageState === WELCOME_PAGE_STATES.FORGOT_PASSWORD) {
    return (
      <Card className={classes.container}>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Typography variant="h2" data-pw="welcome-page-title">
              Reset Password
            </Typography>
            {errors.general && (
              <Typography
                variant="caption"
                color="primary"
                data-pw="reset-password-errors"
              >
                {errors.general}
              </Typography>
            )}
            <Box className={classes.inputs}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    type="email"
                    variant="outlined"
                    fullWidth
                    label="Email address"
                    value={email}
                    InputProps={{
                      inputProps: { 'data-pw': 'reset-password-email-input' },
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email && (
                    <Typography
                      variant="caption"
                      color="primary"
                      data-pw="reset-password-email-errors"
                    >
                      {errors.email}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box mt={2}>
              <Typography
                className={classes.actionLabel}
                variant="body2"
                color="primary"
                component="span"
                onClick={() =>
                  handlePageStateChange(WELCOME_PAGE_STATES.LOG_IN)
                }
                data-pw="reset-password-back-to-login"
              >
                Back to Log In
              </Typography>
            </Box>
            <Box mt={3}>
              <TitanButton
                className={classes.button}
                data-pw="reset-password-button"
                type="submit"
                variant="contained"
                color="primary"
                isLoading={loading}
              >
                Reset Password
              </TitanButton>
            </Box>
          </form>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className={classes.container}>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Typography variant="h2" data-pw="welcome-page-title">
            {title}
          </Typography>
          <Typography
            component="div"
            variant="caption"
            color="primary"
            data-pw="auth-errors"
            className={classes.generalErrors}
          >
            {errors.general}
          </Typography>
          <Box className={classes.inputs}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  type="email"
                  variant="outlined"
                  fullWidth
                  label="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    inputProps: { 'data-pw': 'auth-email-input' },
                  }}
                />
                {errors.email && (
                  <Typography
                    variant="caption"
                    color="primary"
                    data-pw="auth-email-input-errors"
                  >
                    {errors.email}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type={passwordHidden ? 'password' : 'text'}
                  variant="outlined"
                  fullWidth
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setPasswordHidden(!passwordHidden)}
                          data-pw="auth-show-password"
                        >
                          <img
                            src={
                              passwordHidden
                                ? PasswordHiddenIcon
                                : PasswordVisibleIcon
                            }
                            alt="Show/Hide password icon"
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                    inputProps: {
                      'data-pw': 'auth-password-input',
                    },
                  }}
                />
                {errors.password && (
                  <Typography
                    variant="caption"
                    color="primary"
                    data-pw="auth-password-input-errors"
                  >
                    <ReactMarkdown source={errors.password} />
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Box mt={2}>
              <Typography
                className={classes.actionLabel}
                data-pw="auth-forgot-password-link"
                variant="body2"
                color="primary"
                component="span"
                onClick={() =>
                  handlePageStateChange(WELCOME_PAGE_STATES.FORGOT_PASSWORD)
                }
              >
                Forgot password?
              </Typography>
            </Box>
            <Box mt={3}>
              <TitanButton
                className={classes.button}
                data-pw="auth-login-button"
                type="submit"
                variant="contained"
                color="primary"
                isLoading={loading}
              >
                {buttonLabel}
              </TitanButton>
            </Box>

            <Box mt={3} className={classes.delimeterContainer}>
              <Typography variant="button" color="secondary">
                OR
              </Typography>
            </Box>
            <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    className={classes.button}
                    data-pw="auth-login-google-button"
                    variant="outlined"
                    color="secondary"
                    onClick={() => onConnectionClick('google-oauth2')}
                  >
                    <img src={GoogleIcon} alt="Google Icon" />
                    Continue with Google
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
}
