import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import React from 'react';
import { useParams } from 'react-router-dom';
import LabelPrinterService from '../../services/LabelPrinterService';
import LabelPrinterDialog from '../LabelPrinters/LabelPrinterDialog';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import Can from '../Authentication/Can';
import { PERMISSIONS } from '../../constants/auth0';
import ActionsMenu from '../Menu/ActionsMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTitan } from '../Titan/Titan';
import LabelPrinterConfigDialog from './LabelPrinterConfigDialog';
import ComponentStatus from '../Component/ComponentStatus';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';

export default function LabelPrintersTable() {
  const { organizationId = '', page: pageParam = 0 } = useParams();
  const { pushSnackbar } = useTitan();

  const [openLabelPrinterDialog, setOpenLabelPrinterDialog] = React.useState(
    false
  );
  const [editablePrinter, setEditablePrinter] = React.useState();
  const [openDeletePrinterDialog, setOpenDeletePrinterDialog] = React.useState(
    false
  );
  const [openEditPrinterDialog, setOpenEditPrinterDialog] = React.useState(
    false
  );
  const [labelPrinterConfig, setLabelPrinterConfig] = React.useState();
  const [
    openNewCertificateConfirmationDialog,
    setOpenNewCertificateConfirmationDialog
  ] = React.useState();

  const loadData = React.useCallback(
    async (query, config) => {
      const params = {
        ...query,
        withRelated: ['organization']
      };

      if (organizationId) {
        params.organizationId = organizationId;
      }

      return LabelPrinterService.getPrinters(params, config);
    },
    [organizationId]
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: 'ID',
        pinnable: true,
        field: 'id',
        minWidth: 300,
        sortable: false
      },
      {
        headerName: 'Name',
        field: 'name',
        flex: 1,
        minWidth: 120,
        sortable: false
      },
      {
        headerName: 'Status',
        field: 'status',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => (
          <ComponentStatus component={{ status: row.currentStatus }} />
        )
      },
      {
        headerName: 'IOT Thing Name',
        field: 'iotThingName',
        minWidth: 200,
        sortable: false
      },
      {
        headerName: 'Organization',
        field: 'organizationId',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) => (row.organization ? row.organization.name : '')
      },
      {
        headerName: 'Created',
        field: 'created',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />
      },
      {
        headerName: '',
        field: 'actions',
        width: 60,
        sortable: false,
        renderCell: ({ row }) => (
          <Can
            permissions={[
              PERMISSIONS.DELETE_BUILD_MODULE,
              PERMISSIONS.UPDATE_BUILD_MODULE
            ]}
            yes={() => (
              <ActionsMenu
                items={[
                  {
                    title: 'Edit',
                    icon: EditIcon,
                    onClick: () => {
                      setEditablePrinter(row);
                      setOpenEditPrinterDialog(true);
                    }
                  },
                  {
                    title: 'Create new access key',
                    icon: VpnKeyIcon,
                    onClick: () => {
                      setEditablePrinter(row);
                      setOpenNewCertificateConfirmationDialog(true);
                    }
                  },
                  {
                    title: 'Delete',
                    icon: DeleteIcon,
                    onClick: () => {
                      setEditablePrinter(row);
                      setOpenDeletePrinterDialog(true);
                    }
                  }
                ]}
              />
            )}
          />
        )
      }
    ],
    []
  );

  const gridOption = {
    columns,
    pinnedColumns: {
      left: ['id'],
      right: []
    }
  };

  if (pageParam && Number(pageParam) > 0) {
    gridOption.page = Number(pageParam);
  }

  const titanDataGridProps = useTitanDataGrid(loadData, gridOption);

  const { rows, setRows } = titanDataGridProps;

  const onSaveLabelPrinter = React.useCallback(
    labelPrinter => {
      if (openLabelPrinterDialog) {
        setRows(prev => [labelPrinter, ...prev]);
      } else {
        setRows(prev =>
          prev.map(ps =>
            ps.id === labelPrinter.id ? { ...ps, ...labelPrinter } : ps
          )
        );
      }
    },
    [rows, openLabelPrinterDialog, openEditPrinterDialog]
  );

  const onDeleteDevice = React.useCallback(async ({ id }) => {
    await LabelPrinterService.deletePrinter(id);
    pushSnackbar('Label printer deleted', { variant: 'success' });

    setOpenDeletePrinterDialog(false);
    setEditablePrinter(null);

    setRows(prev => prev.filter(lp => lp.id !== id));
  }, []);

  const handleCreateNewCertificate = React.useCallback(async () => {
    const labelPrinter = await LabelPrinterService.createNewCertificate(
      editablePrinter.id
    );
    pushSnackbar('Created new certificated', { variant: 'success' });

    setLabelPrinterConfig(labelPrinter.fusionModuleConfig);
  }, [editablePrinter]);

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        title="Label Printers"
        columns={columns}
        onCreateClick={() => setOpenLabelPrinterDialog(true)}
        createButtonLabel="Label Printer"
      />

      {openLabelPrinterDialog && (
        <LabelPrinterDialog
          organizationId={organizationId}
          onClose={() => setOpenLabelPrinterDialog(false)}
          onSave={labelPrinter => {
            if (labelPrinter.labelPrinterConfig) {
              setLabelPrinterConfig(labelPrinter.labelPrinterConfig);
            }

            onSaveLabelPrinter(labelPrinter);
          }}
        />
      )}

      {openEditPrinterDialog && (
        <LabelPrinterDialog
          organizationId={organizationId}
          onClose={() => setOpenEditPrinterDialog(false)}
          labelPrinter={editablePrinter}
          onSave={onSaveLabelPrinter}
        />
      )}

      {openDeletePrinterDialog && (
        <TitanConfirmationDialog
          title={`Delete label printer?`}
          message={`Are you sure you want to delete label printer ${
            editablePrinter.name
          }?`}
          onClose={() => {
            setEditablePrinter(null);
            setOpenDeletePrinterDialog(false);
          }}
          onConfirm={() => onDeleteDevice(editablePrinter)}
        />
      )}

      {labelPrinterConfig && (
        <LabelPrinterConfigDialog
          labelPrinterConfig={labelPrinterConfig}
          onClose={() => setLabelPrinterConfig(null)}
        />
      )}

      {openNewCertificateConfirmationDialog && (
        <TitanConfirmationDialog
          title={`Generate new certificate?`}
          message={`Are you sure you want to create new certificate for label printer ${
            editablePrinter.name
          }? Previous certificate will be removed and disconnected from IoT server`}
          onClose={() => {
            setOpenNewCertificateConfirmationDialog(false);
          }}
          onConfirm={() => handleCreateNewCertificate()}
        />
      )}
    </>
  );
}
