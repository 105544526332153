import React from 'react';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer
} from '@mui/x-data-grid-pro';

export default function TitanDataGridToolbar(props) {
  return (
    <GridToolbarContainer {...props}>
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}
