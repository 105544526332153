import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import React from 'react';
import { useParams } from 'react-router-dom';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import Can from '../Authentication/Can';
import { PERMISSIONS } from '../../constants/auth0';
import ActionsMenu from '../Menu/ActionsMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTitan } from '../Titan/Titan';
import MeasuringDeviceConfigDialog from './MeasuringDeviceConfigDialog';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import MeasuringDeviceService from '../../services/MeasuringDeviceService';
import MeasuringDeviceDialog from './MeasuringDeviceDialog';

export default function MeasuringDevicesTable() {
  const { organizationId = '', page: pageParam = 0 } = useParams();
  const { pushSnackbar } = useTitan();

  const [openMeasuringDeviceDialog, setOpenMeasuringDeviceDialog] =
    React.useState(false);
  const [editableMeasuringDevice, setEditableMeasuringDevice] =
    React.useState();
  const [openDeleteMeasuringDeviceDialog, setOpenDeleteMeasuringDeviceDialog] =
    React.useState(false);
  const [openEditMeasuringDeviceDialog, setOpenEditMeasuringDeviceDialog] =
    React.useState(false);
  const [measuringDeviceConfig, setMeasuringDeviceConfig] = React.useState();
  const [
    openNewCertificateConfirmationDialog,
    setOpenNewCertificateConfirmationDialog,
  ] = React.useState();

  const loadData = React.useCallback(
    async (query, config) => {
      const params = {
        ...query,
        withRelated: ['organization'],
      };

      if (organizationId) {
        params.organizationId = organizationId;
      }

      const { measuringDevices } =
        await MeasuringDeviceService.getMeasuringDevices(params, config);

      return {
        data: measuringDevices,
      };
    },
    [organizationId],
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: 'ID',
        pinnable: true,
        field: 'id',
        minWidth: 300,
        sortable: false,
      },
      {
        headerName: 'Name',
        field: 'name',
        flex: 1,
        minWidth: 120,
        sortable: false,
      },
      {
        headerName: 'Device type',
        field: 'deviceType',
        minWidth: 110,
        sortable: false,
      },
      // {
      //   headerName: 'Status',
      //   field: 'status',
      //   minWidth: 150,
      //   sortable: false,
      //   renderCell: ({ row }) => (
      //     <ComponentStatus component={{ status: row.currentStatus }} />
      //   ),
      // },
      {
        headerName: 'IOT Thing Name',
        field: 'iotThingName',
        minWidth: 200,
        sortable: false,
      },
      // {
      //   headerName: 'Organization',
      //   field: 'organizationId',
      //   minWidth: 200,
      //   sortable: false,
      //   renderCell: ({ row }) =>
      //     row.organization ? row.organization.name : '',
      // },
      {
        headerName: 'Created',
        field: 'created',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />,
      },
      {
        headerName: '',
        field: 'actions',
        width: 60,
        sortable: false,
        renderCell: ({ row }) => (
          <Can
            permissions={[PERMISSIONS.WRITE_MEASURING_DEVICES]}
            yes={() => (
              <ActionsMenu
                items={[
                  {
                    title: 'Edit',
                    icon: EditIcon,
                    onClick: () => {
                      setEditableMeasuringDevice(row);
                      setOpenEditMeasuringDeviceDialog(true);
                    },
                  },
                  {
                    title: 'Create new access key',
                    icon: VpnKeyIcon,
                    onClick: () => {
                      setEditableMeasuringDevice(row);
                      setOpenNewCertificateConfirmationDialog(true);
                    },
                  },
                  {
                    title: 'Delete',
                    icon: DeleteIcon,
                    onClick: () => {
                      setEditableMeasuringDevice(row);
                      setOpenDeleteMeasuringDeviceDialog(true);
                    },
                  },
                ]}
              />
            )}
          />
        ),
      },
    ],
    [],
  );

  const gridOption = {
    columns,
    pinnedColumns: {
      left: ['id'],
      right: [],
    },
  };

  if (pageParam && Number(pageParam) > 0) {
    gridOption.page = Number(pageParam);
  }

  const titanDataGridProps = useTitanDataGrid(loadData, gridOption);

  const { rows, setRows } = titanDataGridProps;

  const onSaveMeasuringDevice = React.useCallback(
    (measuringDevice) => {
      if (openMeasuringDeviceDialog) {
        setRows((prev) => [measuringDevice, ...prev]);
      } else {
        setRows((prev) =>
          prev.map((ps) =>
            ps.id === measuringDevice.id ? { ...ps, ...measuringDevice } : ps,
          ),
        );
      }
    },
    [rows, openMeasuringDeviceDialog, openEditMeasuringDeviceDialog],
  );

  const onDeleteMeasuringDevice = React.useCallback(async ({ id }) => {
    await MeasuringDeviceService.deleteMeasuringDevice(id);
    pushSnackbar('Measuring device deleted', { variant: 'success' });

    setOpenDeleteMeasuringDeviceDialog(false);
    setEditableMeasuringDevice(null);

    setRows((prev) => prev.filter((lp) => lp.id !== id));
  }, []);

  const handleCreateNewCertificate = React.useCallback(async () => {
    const measuringDevice = await MeasuringDeviceService.createNewCertificate(
      editableMeasuringDevice.id,
    );
    pushSnackbar('Created new certificated', { variant: 'success' });

    setMeasuringDeviceConfig(measuringDevice.measuringDeviceConfig);
  }, [editableMeasuringDevice]);

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        title="Measuring devices"
        columns={columns}
        onCreateClick={() => setOpenMeasuringDeviceDialog(true)}
        createButtonLabel="Measuring device"
      />

      {openMeasuringDeviceDialog && (
        <MeasuringDeviceDialog
          organizationId={organizationId}
          onClose={() => setOpenMeasuringDeviceDialog(false)}
          onSave={(measuringDevice) => {
            if (measuringDevice.measuringDeviceConfig) {
              setMeasuringDeviceConfig(measuringDevice.measuringDeviceConfig);
            }

            onSaveMeasuringDevice(measuringDevice);
          }}
        />
      )}

      {openEditMeasuringDeviceDialog && (
        <MeasuringDeviceDialog
          organizationId={organizationId}
          onClose={() => setOpenEditMeasuringDeviceDialog(false)}
          measuringDevice={editableMeasuringDevice}
          onSave={onSaveMeasuringDevice}
        />
      )}

      {openDeleteMeasuringDeviceDialog && (
        <TitanConfirmationDialog
          title={`Delete measuring device?`}
          message={`Are you sure you want to delete measuring device ${editableMeasuringDevice.name}?`}
          onClose={() => {
            setEditableMeasuringDevice(null);
            setOpenDeleteMeasuringDeviceDialog(false);
          }}
          onConfirm={() => onDeleteMeasuringDevice(editableMeasuringDevice)}
        />
      )}

      {measuringDeviceConfig && (
        <MeasuringDeviceConfigDialog
          measuringDeviceConfig={measuringDeviceConfig}
          onClose={() => setMeasuringDeviceConfig(null)}
        />
      )}

      {openNewCertificateConfirmationDialog && (
        <TitanConfirmationDialog
          title={`Generate new certificate?`}
          message={`Are you sure you want to create new certificate for measuring device ${editableMeasuringDevice.name}? Previous certificate will be removed and disconnected from IoT server`}
          onClose={() => {
            setOpenNewCertificateConfirmationDialog(false);
          }}
          onConfirm={() => handleCreateNewCertificate()}
        />
      )}
    </>
  );
}
