import React from 'react';
import FilterChips from '../FilterChips/FilterChips';

export default function FinalPartsChips({
  fusionPlanVersionType,
  setFusionPlanVersionType
}) {
  const items = React.useMemo(() => {
    const items = [];

    items.push({
      label: 'Current Version',
      onClick: () => setFusionPlanVersionType('current'),
      isHighlighted: fusionPlanVersionType === 'current'
    });

    items.push({
      label: 'All Versions',
      onClick: () => setFusionPlanVersionType('all'),
      isHighlighted: fusionPlanVersionType === 'all'
    });

    return items;
  }, [fusionPlanVersionType]);

  return <FilterChips items={items} />;
}
