import React from 'react';

import TextField from '@mui/material/TextField';
import PrinterSeriesService from '../../services/PrinterSeriesService';
import TitanDialog from '../Titan/TitanDialog';

export default function PrinterSeriesDialog(props) {
  const { mode, printerSeries, onClose, onSave } = props;

  const [name, setName] = React.useState(printerSeries.name || '');

  const onSubmitForm = () => {
    if (mode === 'create') {
      return PrinterSeriesService.createPrinterSeries({
        name: name
      }).then(data => {
        onSave(data);
      });
    } else {
      return PrinterSeriesService.updatePrinterSeries(printerSeries.id, {
        name
      }).then(data => {
        onSave(data);
      });
    }
  };

  const isValid = name.length !== 0;

  return (
    <TitanDialog
      title={`${mode === 'create' ? 'Create' : 'Edit'} printer series`}
      onClose={onClose}
      onSave={onSubmitForm}
      isValid={isValid}
      successSaveMessage={`Printer series successfully ${
        mode === 'create' ? 'created' : 'updated'
      }`}
    >
      <TextField
        autoFocus
        label="Name"
        value={name}
        fullWidth
        margin="normal"
        onChange={e => setName(e.target.value)}
      />
    </TitanDialog>
  );
}
