import React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import ForwardIcon from '@mui/icons-material/Forward';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ResendIcon from '@mui/icons-material/Autorenew';
import MergeIcon from '@mui/icons-material/Merge';
import Avatar from '@mui/material/Avatar';
import UserDialog from './UserDialog';
import ChangeOrganizationDialog from './ChangeOrganizationDialog';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import MemberService from '../../services/MemberService';
import { ROUTES } from '../../constants';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { useAuth0 } from '../Authentication/Auth0';
import { useTitan } from '../Titan/Titan';
import { PERMISSIONS } from '../../constants/auth0';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import ActionsMenu from '../Menu/ActionsMenu';
import OrganizationService from '../../services/OrganizationService';
import UserListFilter from './UserListFilter';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import useAsyncEffect from 'use-async-effect';
import TitanDialog from '../Titan/TitanDialog';
import TitanCircularProgress from '../Titan/TitanCircularProgress';
import MemberSelectionDialog from '../Fibrify/FibrifyMemberSelectionDialog';

const accountSourceMap = {
  auth0: 'Auth0 Account',
  'google-oauth2': 'Google',
};

export default function UserList({ setNewPageForTab }) {
  const { organizationId } = useParams();
  const { setProfile, isSuperAdmin, can } = useAuth0();
  const history = useHistory();
  const location = useLocation();
  const { page: pageParam = 0 } = useParams();
  const { pushSnackbar } = useTitan();

  const [roles, setRoles] = React.useState([]);
  const [roleRestrictions, setRoleRestrictions] = React.useState([]);
  const [openUpdateUserDialog, setOpenUpdateUserDialog] = React.useState(false);
  const [openCreateUserDialog, setOpenCreateUserDialog] = React.useState(false);
  const [openChangeOrganizationDialog, setOpenChangeOrganizationDialog] =
    React.useState(false);
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = React.useState(false);
  const [openMergeMemberDialog, setOpenMergeMemberDialog] =
    React.useState(false);
  const [activeUser, setActiveUser] = React.useState(null);

  const [filters, setFilters] = React.useState({
    roles: [],
    provider: [],
  });

  const [loggingAsMember, setLoggingAsMember] = React.useState(false);

  const loadRoleRestrictions = async () =>
    OrganizationService.getOrganizationRoleRestrictions(organizationId).then(
      (res) => {
        const roles = {};

        res.data.forEach((restriction) => {
          roles[restriction.role.id] = restriction.role.name;
        });

        setRoles(roles);
        setRoleRestrictions(res.data);
      },
    );

  useAsyncEffect(async () => {
    await loadRoleRestrictions();
  }, [organizationId]);

  const loadUsers = React.useCallback(
    async (query, config) => {
      const { roles, provider } = filters;

      const params = {
        ...query,
        organizationId,
        withRelated: ['roles'],
      };

      if (roles.length) {
        params.roles = roles;
      }

      if (provider.length) {
        params.provider = provider;
      }

      params.orderBy = 'createdAt';
      params.orderDirection = 'DESC';

      const { data, pagination } = await MemberService.loadOrganizationMembers(
        params,
        config,
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount,
      };
    },
    [filters, organizationId],
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Avatar',
        pinnable: true,
        field: 'avatar',
        minWidth: 50,
        sortable: false,
        renderCell: ({ row }) => (
          <Avatar
            key={row.id}
            src={row.avatarLink}
            style={{ height: 30, width: 30 }}
          />
        ),
      },
      {
        headerName: 'Name',
        field: 'name',
        flex: 1,
        minWidth: 120,
        sortable: false,
      },
      {
        headerName: 'Email',
        field: 'email',
        flex: 1,
        minWidth: 120,
        sortable: false,
      },
      {
        headerName: 'Source',
        field: 'source',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => {
          const provider = row.provider || row.id.split('|')[0];

          return accountSourceMap[provider];
        },
      },
      {
        headerName: 'Role',
        field: 'roles',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) =>
          row.roles.reduce(
            (result, role) => (result ? `${result}, ${role.name}` : role.name),
            '',
          ),
      },
      {
        headerName: 'Created',
        field: 'created',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />,
      },
      {
        headerName: 'Last login at',
        field: 'lastLoginAt',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => <TitanTimeAgo time={row.lastLoginAt} />,
      },
      {
        headerName: '',
        field: 'actions',
        width: 60,
        sortable: false,
        renderCell: ({ row }) => (
          <ActionsMenu
            items={rowMenuItems.filter(
              (item) =>
                !(item.title === 'Resend the invite' && row.lastLoginAt),
            )}
            data={row}
          />
        ),
      },
    ],
    [],
  );

  const gridOptions = {
    columns,
    pinnedColumns: {
      left: ['avatar'],
      right: ['actions'],
    },
  };

  if (pageParam && Number(pageParam) > 0) {
    gridOptions.page = Number(pageParam);
  }

  const titanDataGridProps = useTitanDataGrid(loadUsers, gridOptions);

  const { rows, setRows, reloadData } = titanDataGridProps;

  const handleFilterChange = (filter, value) => {
    setFilters({ ...filters, [filter]: value });
  };

  const availableRoles = React.useMemo(
    () =>
      roleRestrictions.map((restriction) => ({
        id: restriction.role.id,
        name: restriction.role.name,
        availableNumber: restriction.availableNumber,
      })),
    [roleRestrictions],
  );

  const handleAddUser = React.useCallback(
    async (email, roleIds, name, isTrial, trialExpiresAt) => {
      const member = await MemberService.createMember({
        email,
        roleIds,
        organizationId,
        name,
        isTrial,
        trialExpiresAt,
      });

      await loadRoleRestrictions();

      setOpenCreateUserDialog(false);

      setRows((prev) => [member, ...prev]);
    },
    [rows],
  );

  const handleUpdateUser = async (
    email,
    roleIds,
    name,
    isTrial,
    trialExpiresAt,
  ) => {
    const updatedMember = await MemberService.updateMember(activeUser.id, {
      name,
      roleIds,
      isTrial,
      trialExpiresAt,
    });

    await loadRoleRestrictions();

    setRows((prev) =>
      prev.map((u) => (u.id === updatedMember.id ? updatedMember : u)),
    );
  };

  const handleMoveToOrganization = async (organizationId) => {
    setOpenChangeOrganizationDialog(false);

    const updatedMember = await MemberService.updateMember(activeUser.id, {
      organizationId,
    });

    await loadRoleRestrictions();

    setRows((prev) =>
      prev.map((u) => (u.id === updatedMember.id ? updatedMember : u)),
    );
  };

  const handleDeleteUser = async () => {
    await MemberService.deleteMember(activeUser.id);

    await loadRoleRestrictions();

    setRows((prev) => prev.filter((u) => u.id !== activeUser.id));
  };

  const loginAsMember = async (member) => {
    setLoggingAsMember(true);

    const updatedProfile = await MemberService.loginAsMember({
      memberId: member.id,
    });

    setProfile(updatedProfile);

    setLoggingAsMember(false);

    let onLoginRedirect = ROUTES.DASHBOARD;

    if (
      updatedProfile.loggedAs &&
      !updatedProfile.loggedAsMember.generalTerms
    ) {
      onLoginRedirect = ROUTES.TERMS_AND_CONDITIONS;
    }

    pushSnackbar(`You logged as ${updatedProfile.loggedAsMember.name}`, {
      variant: 'success',
    });

    history.push({
      pathname: onLoginRedirect,
      state: { from: location.pathname },
    });
  };

  const rowMenuItems = [];

  if (can(PERMISSIONS.LOGIN_AS_MEMBER)) {
    rowMenuItems.push({
      icon: PermIdentityIcon,
      title: 'Login as Member',
      onClick: (e, rowData) => {
        loginAsMember(rowData);
      },
    });
  }

  if (isSuperAdmin) {
    rowMenuItems.push({
      icon: ForwardIcon,
      title: 'Move to other organization',
      onClick: (e, rowData) => {
        setActiveUser(rowData);
        setOpenChangeOrganizationDialog(true);
      },
    });
  }

  rowMenuItems.push({
    icon: EditIcon,
    title: 'Edit user',
    onClick: (e, rowData) => {
      setActiveUser(rowData);
      setOpenUpdateUserDialog(true);
    },
  });

  rowMenuItems.push({
    icon: DeleteIcon,
    title: 'Delete user',
    onClick: (e, rowData) => {
      setActiveUser(rowData);
      setOpenDeleteUserDialog(true);
    },
  });
  rowMenuItems.push({
    icon: ResendIcon,
    title: 'Resend the invite',
    onClick: (e, rowData) => {
      MemberService.resendInviteForMember(rowData.id).then((response) => {
        if (response.isEmailSent) {
          pushSnackbar(`Invite has been resent`, {
            variant: 'success',
          });
        }
      });
    },
  });
  rowMenuItems.push({
    icon: MergeIcon,
    title: 'Merge accounts',
    onClick: (e, rowData) => {
      setActiveUser(rowData);
      setOpenMergeMemberDialog(true);
    },
  });

  const handlerMergeMembers = React.useCallback(
    async (selectedMember) => {
      await MemberService.mergeAccounts(activeUser.id, selectedMember.id);

      pushSnackbar(`Members accounts merged`, {
        variant: 'success',
      });

      reloadData();
    },
    [activeUser],
  );

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        createButtonLabel="User"
        onCreateClick={() => setOpenCreateUserDialog(true)}
        showSearch={true}
        searchPlaceholder="Search by name or email"
        title="Users"
        filtersContent={
          <UserListFilter
            roles={roles}
            sources={accountSourceMap}
            filters={filters}
            onChangeFilters={handleFilterChange}
          />
        }
      />

      {loggingAsMember && (
        <TitanDialog title="Logging as member">
          <TitanCircularProgress />
        </TitanDialog>
      )}

      {openCreateUserDialog && (
        <UserDialog
          title="Invite new user"
          onClose={() => setOpenCreateUserDialog(false)}
          onSave={handleAddUser}
          availableRoles={availableRoles}
          organizationId={organizationId}
        />
      )}

      {openUpdateUserDialog && (
        <UserDialog
          title="Update user"
          user={activeUser}
          onClose={() => setOpenUpdateUserDialog(false)}
          onSave={handleUpdateUser}
          availableRoles={availableRoles}
          organizationId={organizationId}
        />
      )}

      {openChangeOrganizationDialog && (
        <ChangeOrganizationDialog
          onClose={() => setOpenChangeOrganizationDialog(false)}
          onSave={handleMoveToOrganization}
          currentOrganization={organizationId}
        />
      )}

      {openDeleteUserDialog && (
        <TitanConfirmationDialog
          title="Delete user confirmation"
          message={`Please confirm that you want to delete user ${activeUser.name}. All user's folders will be moved to your subfolder.`}
          onClose={() => setOpenDeleteUserDialog(false)}
          onConfirm={handleDeleteUser}
        />
      )}

      {openMergeMemberDialog && (
        <MemberSelectionDialog
          title={`Select target member account to move all data of ${activeUser.name}`}
          email={activeUser.email}
          excludeMemberIds={[activeUser.id]}
          multipleSelection={false}
          onSave={handlerMergeMembers}
          onClose={() => setOpenMergeMemberDialog(false)}
          saveButtonLabel="Merge"
        />
      )}
    </>
  );
}
