import React from 'react';

import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';

import TitanDialog from '../Titan/TitanDialog';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import FusionPlanLink from '../FusionPlan/FusionPlanLink';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import FusionPlanService from '../../services/FusionPlanService';
import { SORT_ORDERS, WEB_SOCKET_ACTIONS } from '../../constants';
import useWebSocket from 'react-use-websocket';
import { useTitan } from '../Titan/Titan';

export default function LoadFusionModuleConfigDialog(props) {
  const { fusionModuleId, onClose } = props;

  const { getWebSocketUrl, getWebSocketOptions, pushSnackbar } = useTitan();

  const [selectedFusionPlans, setSelectedFusionPlans] = React.useState([]);
  const loadedConfigRef = React.useRef(false);

  const { lastJsonMessage } = useWebSocket(
    getWebSocketUrl,
    getWebSocketOptions()
  );

  React.useEffect(() => {
    if (
      lastJsonMessage !== null &&
      lastJsonMessage.action === WEB_SOCKET_ACTIONS.FUSION_PLAN
    ) {
      loadedConfigRef.current = true;
    }
  }, [lastJsonMessage]);

  const loadData = React.useCallback(async (params, config) => {
    const { data, pagination } = await FusionPlanService.getFusionPlansV2(
      params,
      config
    );

    return {
      data,
      page: pagination.page - 1,
      totalCount: pagination.totalCount
    };
  }, []);

  const onSubmitForm = async () => {
    if (selectedFusionPlans.length === 0) {
      return pushSnackbar('Fusion Plan not selected', { variant: 'error' });
    }

    return new Promise((resolve, reject) => {
      FusionPlanService.sendSaveConfigRequest(
        selectedFusionPlans[0].id,
        {
          fusionModuleId: fusionModuleId
        },
        'v2'
      );

      let retries = 0;
      const interval = setInterval(() => {
        retries += 1;

        if (loadedConfigRef.current) {
          clearInterval(interval);
          resolve();
        } else if (retries > 30) {
          reject(new Error(`Can't load Fusion Module config`));
        }
      }, 1000);
    });
  };

  const titanDataGridProps = useTitanDataGrid(loadData, {
    orders: {
      created_at: SORT_ORDERS.DESC
    }
  });

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <FusionPlanLink fusionPlan={row} />
      },
      {
        headerName: 'Uploaded',
        field: 'uploaded',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />
      }
    ],
    []
  );

  return (
    <TitanDialog
      open={true}
      onClose={onClose}
      onSave={onSubmitForm}
      maxWidth="lg"
      fullWidth
      title={'Load Fusion Module config'}
      isValid={selectedFusionPlans.length !== 0}
      saveButtonLabel="Load"
      successSaveMessage="Fusion Module config saved to Fusion Plan"
    >
      <TitanDataGrid
        {...titanDataGridProps}
        title=""
        searchPlaceholder="Search by Fusion Plan name..."
        columns={columns}
        onSelectionModelChange={newSelectedFusionPlans => {
          setSelectedFusionPlans(newSelectedFusionPlans);
        }}
        selectedRows={selectedFusionPlans}
        checkboxSelection
      />
    </TitanDialog>
  );
}
