import React from 'react';
import Typography from '@mui/material/Typography';
import { WELCOME_PAGE_STATES } from './constants';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  actionLabel: {
    cursor: 'pointer'
  }
}));

export default function AuthPageForgotPasswordSuccess({ setPageState }) {
  const classes = useStyles();

  return (
    <>
      <Typography variant="body2" data-pw="reset-password-success-message">
        The email with the following steps has been sent to the specified email.
      </Typography>

      <Box mt={2}>
        <Typography
          className={classes.actionLabel}
          variant="body2"
          color="primary"
          component="span"
          onClick={() => setPageState(WELCOME_PAGE_STATES.LOG_IN)}
          data-pw="reset-password-back-to-login"
        >
          Back to Log In
        </Typography>
      </Box>
    </>
  );
}
