import axios from 'axios';

export default class TermsAndConditionsService {
  static async load(id) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/terms/${id}`)
      .then(res => res.data.data);
  }

  static async loadLatest(params = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/terms/latest`, {
        params
      })
      .then(res => res.data.data);
  }

  static async loadAll(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/terms`, {
        ...config,
        params
      })
      .then(res => res.data);
  }

  static async create(data) {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/terms`, data)
      .then(res => res.data.data);
  }

  static async update(termsAndConditionsId, data) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v1/terms/${termsAndConditionsId}`,
        data
      )
      .then(res => res.data.data);
  }

  static async delete(termsAndConditionsId) {
    return axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/v1/terms/${termsAndConditionsId}`
      )
      .then(res => res.data.data);
  }
}
