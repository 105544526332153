import TitanDialog from '../Titan/TitanDialog';
import TextField from '@mui/material/TextField';
import React from 'react';
import { useState } from 'react';
import BuildModuleService from '../../services/BuildModuleService';
import { useTitan } from '../Titan/Titan';

export default function PinCodeDialog({ buildModule, onSave, onClose }) {
  const { pushSnackbar } = useTitan();

  const [pinCode, setPinCode] = useState('');

  const { name, pinCodeHash } = buildModule;

  const onSubmitForm = async () => {
    const { data } = await BuildModuleService.updateBuildModule(
      buildModule.id,
      {
        pinCode: pinCode === '' ? null : pinCode
      }
    );

    pushSnackbar(`PIN code for machine ${name} is updated.`, {
      variant: 'success'
    });

    onSave(data.data.pinCodeHash);
    onClose();
  };

  return (
    <>
      <TitanDialog
        title={`${
          pinCodeHash ? 'Edit' : 'Create'
        } PIN code for machine ${name}`}
        maxWidth="sm"
        onClose={onClose}
        onSave={onSubmitForm}
        saveButtonLabel={pinCodeHash ? 'Change' : 'Save'}
        isValid={pinCode === '' || /^[0-9]{4,8}$/.test(pinCode)}
      >
        <TextField
          required
          label="PIN"
          placeholder="4-8 digits, or leave it blank to remove PIN"
          value={pinCode}
          onChange={e => {
            const newValue = e.target.value;
            if (/^[0-9]{1,8}$/.test(newValue) || newValue === '') {
              setPinCode(newValue);
            }
          }}
          fullWidth
          margin="normal"
        />
      </TitanDialog>
    </>
  );
}
