import axios from 'axios';

export default class LabelPrinterService {
  static async printQrCodeLabel(componentId, buildModuleId) {
    const data = {
      componentId,
      buildModuleId
    };

    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/label-printers/print-qrcode`,
        data
      )
      .then(res => res.data);
  }

  static async getPrinters(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/label-printers`, {
        ...config,
        params
      })
      .then(res => res.data);
  }

  static createPrinter(data) {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/label-printers`, data)
      .then(res => res.data.data);
  }

  static updatePrinter(labelPrinterId, data) {
    return axios
      .patch(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/label-printers/${labelPrinterId}`,
        data
      )
      .then(res => res.data.data);
  }

  static deletePrinter(labelPrinterId) {
    return axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/label-printers/${labelPrinterId}`
      )
      .then(res => res.data.data);
  }

  static createNewCertificate(labelPrinterId) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/label-printers/${labelPrinterId}/create-new-certificate`,
        {}
      )
      .then(res => res.data);
  }
}
