import React from 'react';

import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import Box from '@mui/material/Box';
import { ROUTES } from '../../constants';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import { useFuseDialog } from './FuseDialog';
import FusionPlanLink from '../FusionPlan/FusionPlanLink';
import FusionPlanService from '../../services/FusionPlanService';
import { useHistory, useLocation } from 'react-router-dom';

export default function FuseDialogFusionPlansStep() {
  const history = useHistory();
  const location = useLocation();
  const { selectedManufacturingOrder, setSelectedFusionPlan } = useFuseDialog();

  const [selectedFusionPlans, setSelectedFusionPlans] = React.useState([]);

  const columns = React.useMemo(() => {
    const columns = [
      {
        headerName: 'Name',
        field: 'name',
        sortable: false,
        flex: 1,
        renderCell: ({ row, colDef }) => (
          <FusionPlanLink fusionPlan={row} width={colDef.computedWidth} />
        ),
        onCellClick: ({ row }) => {
          if (row) {
            history.push({
              pathname: ROUTES.FUSION_PLAN_V2(row.id),
              state: { from: location.pathname },
            });
          }
        },
      },
      {
        headerName: 'Uploaded',
        field: 'uploaded',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />,
      },
    ];

    return columns;
  }, []);

  const loadData = React.useCallback(
    async (queryParams, config) => {
      const { data, pagination } = await FusionPlanService.getFusionPlansV2(
        {
          ...queryParams,
          ...(selectedManufacturingOrder
            ? {
                manufacturingOrderIds: [selectedManufacturingOrder.id],
              }
            : {}),
        },
        config,
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount,
      };
    },
    [selectedManufacturingOrder],
  );

  const fusionPlansDataGridProps = useTitanDataGrid(loadData, {
    columns,
  });

  React.useEffect(() => {
    setSelectedFusionPlan(
      selectedFusionPlans.length !== 0 ? selectedFusionPlans[0] : null,
    );
  }, [selectedFusionPlans]);

  return (
    <Box sx={{ p: 2 }}>
      <TitanDataGrid
        {...fusionPlansDataGridProps}
        searchPlaceholder="Search by Fusion Plan name..."
        checkboxSelection
        selectedRows={selectedFusionPlans}
        onSelectionModelChange={(selectedFusionPlans) => {
          setSelectedFusionPlans(selectedFusionPlans);
        }}
      />
    </Box>
  );
}
