import React from 'react';
import { useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Can from '../Authentication/Can';
import ActionsMenu from '../Menu/ActionsMenu';
import PrinterDialog from './PrinterDialog';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import { useTitan } from '../Titan/Titan';
import PrinterAccessKeyDialog from './PrinterAccessKeyDialog';
import PrinterService from '../../services/PrinterService';
import { PERMISSIONS } from '../../constants/auth0';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import DeviceInfoPopUp from './DeviceInfoPopUp';
import BuildModuleState from '../BuildModule/BuildModuleState';

export default function BuildModulesTable({
  title = 'Build Modules',
  apiRef,
  setNewPageForTab,
  showManageActions = true,
  organizationId,
  manufacturingOrderId,
  extraActions = []
}) {
  const { page: pageParam = 0 } = useParams();
  const { pushSnackbar } = useTitan();

  const [openPrinterDialog, setOpenPrinterDialog] = React.useState(false);
  const [
    openPrinterAccessKeyDialog,
    setOpenPrinterAccessKeyDialog
  ] = React.useState(false);
  const [editableDevice, setEditableDevice] = React.useState();
  const [openDeleteDeviceDialog, setOpenDeleteDeviceDialog] = React.useState();
  const [openEditPrinterDialog, setOpenEditPrinterDialog] = React.useState();
  const [
    openNewAccessKeyConfirmationDialog,
    setOpenNewAccessKeyConfirmationDialog
  ] = React.useState();
  const [accessKey, setAccessKey] = React.useState();
  const [infoDevice, setInfoDevice] = React.useState(null);
  const [openInfoPopUp, setOpenInfoPopUp] = React.useState(false);

  const columns = React.useMemo(() => {
    const columns = [
      {
        headerName: 'Name',
        field: 'name',
        pinnable: true,
        visibilityBreakpoint: 'sm',
        minWidth: 180,
        flex: 1,
        sortable: false
      },
      {
        headerName: 'Status',
        field: 'status',
        visibilityBreakpoint: 'sm',
        minWidth: 180,
        sortable: false,
        renderCell: ({ row }) => <BuildModuleState buildModule={row} />
      },
      {
        headerName: 'Created',
        field: 'created',
        visibilityBreakpoint: 'sm',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />
      },
      {
        headerName: 'Last used',
        field: 'lastUsed',
        visibilityBreakpoint: 'sm',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => <TitanTimeAgo time={row.lastUsed} />
      },
      {
        headerName: 'Last connected',
        field: 'lastConnected',
        visibilityBreakpoint: 'sm',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => <TitanTimeAgo time={row.lastConnected || ''} />
      },
      {
        headerName: 'BMA Version',
        field: 'deviceVersion',
        visibilityBreakpoint: 'sm',
        minWidth: 150,
        sortable: false
      },
      {
        headerName: '',
        field: 'actions',
        visibilityBreakpoint: 'sm',
        width: 60,
        sortable: false,
        renderCell: ({ row }) => (
          <Can
            permissions={[
              PERMISSIONS.DELETE_BUILD_MODULE,
              PERMISSIONS.UPDATE_BUILD_MODULE
            ]}
            yes={() => (
              <ActionsMenu
                items={[
                  ...(showManageActions
                    ? [
                        {
                          title: 'Edit',
                          icon: EditIcon,
                          onClick: () => {
                            setEditableDevice(row);
                            setOpenEditPrinterDialog(true);
                          }
                        },
                        {
                          title: 'Create new access key',
                          icon: VpnKeyIcon,
                          onClick: () => {
                            setEditableDevice(row);
                            setOpenNewAccessKeyConfirmationDialog(true);
                          }
                        },
                        {
                          title: 'Delete',
                          icon: DeleteIcon,
                          onClick: () => {
                            setEditableDevice(row);
                            setOpenDeleteDeviceDialog(true);
                          }
                        },
                        {
                          title: 'Get info',
                          icon: InfoOutlinedIcon,
                          onClick: () => {
                            setOpenInfoPopUp(true);
                            setInfoDevice({
                              id: row.id,
                              accessKey: row.accessKey,
                              onClose: () => {
                                setOpenInfoPopUp(false);
                                setInfoDevice(null);
                              }
                            });
                          }
                        }
                      ]
                    : []),
                  ...extraActions.map(action => ({
                    ...action,
                    onClick: () => action.onClick(row)
                  }))
                ]}
              />
            )}
          />
        )
      }
    ];

    if (!organizationId) {
      columns.splice(columns.length - 1, 0, {
        headerName: 'Organization',
        field: 'organization',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) => (row.organization ? row.organization.name : '')
      });
    }

    return columns;
  }, []);

  const loadData = React.useCallback(
    async (query, config) => {
      const params = {
        ...query,
        withRelated: ['organization', 'labelPrinter']
      };

      if (organizationId) {
        params.organizationId = organizationId;
      }

      if (manufacturingOrderId) {
        params.manufacturingOrderId = manufacturingOrderId;
      }

      return PrinterService.getPrinters(params, config);
    },
    [organizationId]
  );
  const gridOptions = React.useMemo(() => {
    const options = {
      columns: columns,
      pinnedColumns: {
        left: ['name'],
        right: ['actions']
      },
      onChangePage: page => {
        if (setNewPageForTab) {
          setNewPageForTab('build-modules', 'Build Modules', page);
        }
      }
    };
    return options;
  }, [setNewPageForTab, columns]);

  if (pageParam && Number(pageParam) > 0) {
    gridOptions.page = Number(pageParam);
  }

  const titanDataGridProps = useTitanDataGrid(loadData, gridOptions);

  const { setRows } = titanDataGridProps;

  const handleDeleteDevice = async ({ id, type }) => {
    await PrinterService.deletePrinter(id);
    pushSnackbar('Build Module deleted', { variant: 'success' });

    setOpenDeleteDeviceDialog(false);
    setEditableDevice(false);

    setRows(prev => prev.filter(bm => bm.id !== id));
  };

  const handleCreateNewAccessKey = async ({ id }) => {
    const data = await PrinterService.createNewAccessKey(id);
    pushSnackbar('Build Module access key created', { variant: 'success' });

    setEditableDevice(data.printer);
    setAccessKey(data.accessKey);

    setOpenPrinterAccessKeyDialog(true);
    setRows(prev => prev.map(bm => (bm.id === id ? data.printer : bm)));
  };

  React.useEffect(() => {
    if (apiRef) {
      apiRef.current = titanDataGridProps.apiRef.current;
      apiRef.current.reloadData = titanDataGridProps.reloadData;
    }
  }, [apiRef, titanDataGridProps]);

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        title={title}
        onCreateClick={
          showManageActions ? () => setOpenPrinterDialog(true) : null
        }
        createButtonLabel="Build Module"
      />
      {openPrinterDialog && (
        <PrinterDialog
          organizationId={organizationId}
          onClose={() => setOpenPrinterDialog(false)}
          onSave={data => {
            setEditableDevice(data.printer);
            setAccessKey(data.accessKey);
            setOpenPrinterAccessKeyDialog(true);
            setRows(prev => [data.printer, ...prev]);
          }}
        />
      )}

      {openEditPrinterDialog && (
        <PrinterDialog
          organizationId={organizationId}
          onClose={() => setOpenEditPrinterDialog(false)}
          printer={editableDevice}
          onSave={updatedBuildModule => {
            setRows(prev =>
              prev.map(bm =>
                updatedBuildModule.id === bm.id ? updatedBuildModule : bm
              )
            );
          }}
        />
      )}

      {openPrinterAccessKeyDialog && (
        <PrinterAccessKeyDialog
          printerAccessKey={accessKey}
          onClose={() => {
            setAccessKey(null);
            setOpenPrinterAccessKeyDialog(false);
          }}
          printer={editableDevice}
        />
      )}

      {openDeleteDeviceDialog && (
        <TitanConfirmationDialog
          title={`Delete ${editableDevice.type}?`}
          message={`Are you sure you want to delete Build Module ${
            editableDevice.name
          }?`}
          onClose={() => {
            setEditableDevice(null);
            setOpenDeleteDeviceDialog(false);
          }}
          onConfirm={() => handleDeleteDevice(editableDevice)}
        />
      )}
      {openNewAccessKeyConfirmationDialog && (
        <TitanConfirmationDialog
          title={`Generate new access key?`}
          message={`Are you sure you want to create new access key for Build Module ${
            editableDevice.name
          }? Previous access key will be removed and disconnected from IoT server`}
          onClose={() => {
            setOpenNewAccessKeyConfirmationDialog(false);
          }}
          onConfirm={() => handleCreateNewAccessKey(editableDevice)}
        />
      )}
      {openInfoPopUp && <DeviceInfoPopUp {...infoDevice} />}
    </>
  );
}
