import React from 'react';
import Grid from '@mui/material/Grid';
import { sensorTypeConfig } from './constants';
import TitanChart from '../Titan/TitanChart';
import Typography from '@mui/material/Typography';
import useFusionModuleTelemetry from './use-fusion-module-telemetry';
import TitanCircularProgress from '../Titan/TitanCircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CSVLink } from 'react-csv';
import { Button, Box, IconButton } from '@mui/material';
import Stack from '@mui/material/Stack';
import useTitanChart from '../Titan/UseTitanChart';
import SettingsIcon from '@mui/icons-material/Settings';
import TitanDialog from '../Titan/TitanDialog';
import { DataGridPro } from '@mui/x-data-grid-pro';

const sensorTypeNames = Object.keys(sensorTypeConfig);

export default function FusionModuleTelemetry({
  fusionModuleId,
  fusionJobId,
  from,
  to,
  timeDirection = 'backward',
}) {
  const [openedPopUp, setOpenedPopUp] = React.useState();
  const { sensorData, csvData, loading } = useFusionModuleTelemetry(
    fusionModuleId,
    fusionJobId,
    from,
    to,
  );
  const allDataKeys = React.useMemo(() => {
    const dataKeys = sensorTypeNames.reduce((accum, current) => {
      accum[current] = sensorData[sensorTypeConfig[current].dataKey]
        ? Object.values(sensorData[sensorTypeConfig[current].dataKey])
        : [];
      return accum;
    }, {});
    return dataKeys;
  }, [sensorData]);

  const {
    columns,
    computedSeriesArray,
    isOpenGraphDialog,
    setIsOpenGraphDialog,
    legendLabels,
    setLegendLabels,
    legendColors,
    setLegendColors,
    showedArray,
    changeShowedSeriesArray,
    setTempChanges,
    showAll,
  } = useTitanChart(allDataKeys, [], true);

  const headers = React.useMemo(() => {
    let temperatureKeys = new Set();

    csvData.forEach((row) => {
      Object.keys(row).forEach((rowKey) => {
        if (
          rowKey.startsWith('temperature') &&
          row[rowKey] &&
          row[rowKey].length !== 0
        ) {
          temperatureKeys.add(rowKey);
        }
      });
    });

    temperatureKeys = Array.from(temperatureKeys).sort(
      (a, b) => parseInt(a.substring(11), 10) - parseInt(b.substring(11), 10),
    );

    return [
      { label: 'Time (seconds)', key: 'timestamp' },
      ...temperatureKeys.map((temperatureKey) => ({
        label: `Temperature ${temperatureKey.substring(11)}`,
        key: `temperature${temperatureKey.substring(11)}`,
      })),
      { label: 'Pressure', key: 'cylinderForce' },
      { label: 'Position', key: 'platePosition' },
    ];
  }, [csvData]);

  const onClose = () => {
    setTempChanges((prev) => {
      changeShowedSeriesArray(prev);
      return [];
    });
    setIsOpenGraphDialog(false);
  };

  const onSave = async () => {
    return setTempChanges((prev) => []);
  };

  return loading ? (
    <TitanCircularProgress />
  ) : (
    <div>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ '& > a': { textDecoration: 'none' }, mb: 2 }}
      >
        <CSVLink
          data={csvData}
          headers={headers}
          filename={`${fusionJobId ? 'fusion-job' : 'fusion-module'}-${
            fusionJobId ? fusionJobId : fusionModuleId
          }.csv`}
        >
          <Button>Download</Button>
        </CSVLink>
      </Stack>

      {sensorTypeNames.length ? (
        <Grid container spacing={3}>
          {sensorTypeNames.map((type, i) => (
            <Grid key={type} item xs={12} sm={12} md={6} lg={6}>
              <Card>
                <CardContent>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography variant="h2">
                      {sensorTypeConfig[type].title}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        setOpenedPopUp(i);
                        setIsOpenGraphDialog(true);
                      }}
                    >
                      <SettingsIcon />
                    </IconButton>
                  </Box>
                  <TitanChart
                    actual={
                      sensorData[sensorTypeConfig[type].dataKey]
                        ? Object.values(
                            sensorData[sensorTypeConfig[type].dataKey],
                          )
                        : []
                    }
                    legendLabels={legendLabels[i]}
                    legendColors={legendColors[i]}
                    showedArray={showedArray[i]}
                    setLegendLabels={(arr) =>
                      setLegendLabels((prev) => {
                        const array = [...prev];
                        array[i] = arr;
                        return array;
                      })
                    }
                    setLegendColors={(arr) =>
                      setLegendColors((prev) => {
                        const array = [...prev];
                        array[i] = arr;
                        return array;
                      })
                    }
                    getNull={sensorTypeConfig[type].getNull}
                    autoAdjustYAxisMax={
                      sensorTypeConfig[type].autoAdjustYAxisMax
                    }
                    yUnit={sensorTypeConfig[type].yUnit}
                    timeDirection={timeDirection}
                    min={sensorTypeConfig[type].yMin}
                    max={sensorTypeConfig[type].yMax}
                    duration={60}
                  />
                </CardContent>
              </Card>
              {isOpenGraphDialog && (
                <TitanDialog
                  title={`Build Module graph settings`}
                  onClose={onClose}
                  onSave={onSave}
                  maxWidth="md"
                  fullWidth
                  onAdditionalButton={showAll}
                  additionalButtonLabel="Show all"
                >
                  <Stack>
                    <DataGridPro
                      rows={computedSeriesArray[openedPopUp]}
                      columns={columns}
                      pageSize={20}
                      disableSelectionOnClick
                      autoHeight
                    />
                  </Stack>
                </TitanDialog>
              )}
            </Grid>
          ))}
        </Grid>
      ) : (
        ''
      )}
    </div>
  );
}
