export default class PlanService {
  static formatPlanKey(planKey, version) {
    let formattedVersion;

    if (version) {
      formattedVersion =
        version.indexOf('v') === 0 ? version.substring(1) : version;
    }

    if (planKey) {
      return `(${planKey}-${formattedVersion})`;
    } else if (formattedVersion) {
      return `(v${formattedVersion})`;
    }

    return '';
  }
}
