import React from 'react';
import useWebSocket from 'react-use-websocket';
import { COMPONENT_STATUSES, WEB_SOCKET_ACTIONS } from '../../constants';
import { useTitan } from '../Titan/Titan';

export default function useFusionJobStatus(
  fusorId,
  fusionJobId,
  initialStatus = COMPONENT_STATUSES.OFFLINE,
  initialProgress = 0
) {
  const { getWebSocketUrl, getWebSocketOptions } = useTitan();
  const [status, setStatus] = React.useState(initialStatus);
  const [progress, setProgress] = React.useState(initialProgress);

  const { lastJsonMessage } = useWebSocket(
    getWebSocketUrl,
    getWebSocketOptions()
  );

  React.useEffect(() => {
    if (
      lastJsonMessage !== null &&
      lastJsonMessage.action === WEB_SOCKET_ACTIONS.FUSION_JOB &&
      lastJsonMessage.data &&
      (lastJsonMessage.data.fusorId === fusorId ||
        lastJsonMessage.data.fusionModuleId === fusorId) &&
      (!fusionJobId ||
        lastJsonMessage.data.id === fusionJobId ||
        lastJsonMessage.data.fusionJobId === fusionJobId)
    ) {
      setStatus(lastJsonMessage.data.status);
      setProgress(lastJsonMessage.data.progress);
    }
  }, [lastJsonMessage]);

  return {
    status,
    setStatus,
    progress,
    setProgress
  };
}
