import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BuildPlanService from '../../services/BuildPlanService';
import { ROUTES, SORT_ORDERS } from '../../constants';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import BuildPlanFavoriteButton from '../BuildPlan/BuildPlanFavoriteButton';
import BuildPlanLink from '../BuildPlan/BuildPlanLink';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import { CustomGridColumnsPanel } from '../TitanDataGrid/TitanDataGridColumnsPanel';

export default function DashboardBuildPlans({ params }) {
  const history = useHistory();
  const location = useLocation();

  const loadData = React.useCallback(async (query, config) => {
    const queryParams = {
      page: 1,
      pageSize: 3,
      withRelated: ['tags'],
      ...params
    };

    const { data, pagination } = await BuildPlanService.getBuildPlans(
      queryParams,
      'v2',
      config
    );

    return {
      data,
      page: pagination.page - 1,
      totalCount: pagination.totalCount
    };
  }, []);

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        visibilityBreakpoint: 'sm',
        hideable: false,
        minWidth: 240,
        flex: 1,
        sortable: false,
        renderCell: ({ row, colDef }) => (
          <BuildPlanLink buildPlan={row} width={colDef.computedWidth} />
        ),
        onCellClick: ({ row }) => {
          if (row) {
            history.push({
              pathname: ROUTES.BUILD_PLAN_V2(row.id),
              state: { from: location.pathname }
            });
          }
        }
      },
      {
        headerName: 'Tags',
        field: 'tags',
        visibilityBreakpoint: 'lg',
        flex: 1,
        minWidth: 200,
        sortable: false,
        valueGetter: ({ row }) => (row.tags ? row.tags.join(', ') : '')
      },
      {
        headerName: 'Uploaded',
        field: 'uploaded',
        visibilityBreakpoint: 'sm',
        minWidth: 180,
        sortable: false,
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />
      },
      {
        headerName: 'Share Type',
        field: 'shareType',
        minWidth: 200,
        sortable: false
      },
      {
        headerName: '',
        field: 'favorite',
        visibilityBreakpoint: 'sm',
        hideInMenu: true,
        hideable: false,
        width: 60,
        sortable: false,
        renderCell: ({ row }) => (
          <BuildPlanFavoriteButton
            buildPlan={row}
            onChange={buildPlan => updateBuildPlan(buildPlan)}
          />
        )
      }
    ],
    []
  );

  const titanDataGridProps = useTitanDataGrid(loadData, {
    orders: {
      created_at: SORT_ORDERS.DESC
    },
    columns
  });

  const { setRows } = titanDataGridProps;

  const updateBuildPlan = React.useCallback(
    buildPlan => {
      setRows(prev =>
        prev.map(bp => {
          if (bp.id === buildPlan.id) {
            return {
              ...bp,
              ...buildPlan
            };
          }

          return bp;
        })
      );
    },
    [setRows]
  );

  return (
    <TitanDataGrid
      {...titanDataGridProps}
      height={270}
      showSearch={false}
      pagination={false}
      hideFooter
    />
  );
}
