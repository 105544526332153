import React from 'react';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import { useBuildPlan } from './BuildPlanPage';
import BuildPlanService from '../../services/BuildPlanService';
import { useParams } from 'react-router-dom';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import PlanService from '../../services/PlanService';
import BuildPlanLink from './BuildPlanLink';
import { Typography } from '@mui/material';
import BuildPlanThumbnail from './BuildPlanThubnail';
import { STYLING_FUNCTIONS } from '../../constants';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import { CustomGridColumnsPanel } from '../TitanDataGrid/TitanDataGridColumnsPanel';

export default function BuildPlanHistoryTab() {
  const { buildPlanId } = useParams();
  const { buildPlan } = useBuildPlan();

  const loadData = React.useCallback(
    async (query, config) => {
      const data = await BuildPlanService.getVersions(
        buildPlan.id,
        { withRelated: ['files'] },
        'v2',
        config
      );

      return {
        data
      };
    },
    [buildPlan.name]
  );

  const titanDataGridProps = useTitanDataGrid(loadData);

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Preview',
        field: 'preview',
        minWidth: 140,
        sortable: false,
        visibilityBreakpoint: 'sm',
        renderCell: ({ row }) => <BuildPlanThumbnail buildPlan={row} />
      },
      {
        headerName: 'Name',
        field: 'name',
        flex: 1,
        sortable: false,
        renderCell: ({ row, colDef }) => (
          <BuildPlanLink
            useName={false}
            buildPlan={row}
            width={colDef.width}
            buildPlanId={buildPlanId}
          >
            <Typography>
              {[
                row.name,
                PlanService.formatPlanKey(row.planKey, row.version)
              ].join(' ')}
              {row.isApproved ? ' (approved)' : ''}
              {row.id === buildPlan.id ? ' (current)' : ''}
            </Typography>
          </BuildPlanLink>
        )
      },
      {
        headerName: 'Version',
        field: 'version',
        minWidth: 40,
        sortable: false
      },
      {
        headerName: 'Uploaded',
        field: 'uploaded',
        minWidth: 170,
        sortable: false,
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />
      },
      {
        headerName: 'Comment',
        field: 'comment',
        flex: 1,
        sortable: false
      },
      {
        headerName: '# Jobs',
        field: 'totalJobs',
        minWidth: 150,
        sortable: false
      },
      {
        headerName: '# Preforms',
        field: 'totalPreforms',
        minWidth: 150,
        sortable: false
      }
    ],
    []
  );

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        components={{
          Toolbar: TitanDataGridToolbar,
          ColumnsPanel: CustomGridColumnsPanel
        }}
        showSearch={false}
        columns={columns}
        pagination={false}
        getRowHeight={STYLING_FUNCTIONS.MAKE_ROW_HEIGHT(110)}
      />
    </>
  );
}
