import React from 'react';
import TitanDialog from '../Titan/TitanDialog';
import TextField from '@mui/material/TextField';
import MeasuringDeviceService from '../../services/MeasuringDeviceService';
import REGEXPS from '../../constants/regexps';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OrganizationService from '../../services/OrganizationService';
import { useAuth0 } from '../Authentication/Auth0';

const deviceTypes = ['SCALE', 'KEYENCE'];

export default function MeasuringDeviceDialog(props) {
  const { onClose, onSave, measuringDevice } = props;
  const { isSuperAdmin } = useAuth0();

  const [name, setName] = React.useState(
    measuringDevice ? measuringDevice.name : '',
  );
  const [deviceType, setDeviceType] = React.useState(
    measuringDevice ? measuringDevice.deviceType : '',
  );
  const [nameError, setNameError] = React.useState(false);
  const [organizationId, setOrganizationId] = React.useState(
    measuringDevice ? measuringDevice.organizationId : props.organizationId,
  );
  const [organizations, setOrganizations] = React.useState([]);

  const onChangeName = (e) => {
    setName(e.target.value);
    setNameError(!REGEXPS.IOT_THING_NAME.test(e.target.value));
  };

  const handleSave = async () => {
    const data = {
      name,
      organizationId,
      deviceType,
    };

    let response;

    if (measuringDevice) {
      response = await MeasuringDeviceService.updateMeasuringDevice(
        measuringDevice.id,
        data,
      );
    } else {
      response = await MeasuringDeviceService.createMeasuringDevice(data);
    }

    onSave(response);
  };

  React.useEffect(() => {
    if (isSuperAdmin) {
      OrganizationService.getOrganizations().then((response) =>
        setOrganizations(response.data),
      );
    }
  }, []);

  return (
    <TitanDialog
      title={`${measuringDevice ? 'Edit' : 'Add'} Measuring Device`}
      onClose={onClose}
      onSave={handleSave}
      isValid={!nameError && name.length !== 0 && organizationId && deviceType}
      successSaveMessage={`Measuring device ${
        measuringDevice ? 'update' : 'created'
      }`}
    >
      <TextField
        required
        label="Name"
        placeholder="Name"
        value={name}
        onChange={onChangeName}
        fullWidth
        margin="normal"
        error={nameError}
        helperText="only alphanumeric characters and/or the following: -_:"
      />
      <FormControl required fullWidth margin="dense">
        <InputLabel sx={{ backgroundColor: '#fff', padding: '0 5px' }}>
          Device Type
        </InputLabel>
        <Select
          value={deviceType}
          onChange={(e) => setDeviceType(e.target.value)}
        >
          {deviceTypes.map((deviceType) => (
            <MenuItem key={deviceType} value={deviceType}>
              {deviceType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {isSuperAdmin && (
        <FormControl required fullWidth margin="dense">
          <InputLabel>Organization</InputLabel>
          <Select
            value={organizationId}
            onChange={(e) => setOrganizationId(e.target.value)}
          >
            {organizations &&
              organizations.map((organization) => (
                <MenuItem key={organization.id} value={organization.id}>
                  {organization.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    </TitanDialog>
  );
}
