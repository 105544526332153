import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { useTitan } from '../Titan/Titan';

export default function ProjectLink(props) {
  const {
    project,
    useKey = false,
    disabled = false,
    onClick = e => e.stopPropagation()
  } = props;
  const location = useLocation();

  const { addPageToPageHistory, preparePageObjectForLink } = useTitan();

  const handleClick = e => {
    addPageToPageHistory(
      preparePageObjectForLink(
        'PROJECT',
        project.id,
        project.name || `Project`,
        ROUTES.PROJECT(project.id, 'summary'),
        'Summary'
      )
    );

    if (onClick) {
      onClick(e);
    }
  };

  const content =
    useKey && project.projectKey
      ? `${project.name} (${project.projectKey})`
      : project.name;

  if (disabled) {
    return <span>{content}</span>;
  }

  return (
    <Link
      onClick={handleClick}
      to={{
        pathname: ROUTES.PROJECT(project.id),
        state: { from: location.pathname }
      }}
      component={RouterLink}
    >
      {content}
    </Link>
  );
}
