import axios from 'axios';

export default class OdooService {
  static async getOdooProjects(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v2/odoo/projects`, {
        ...config,
        params
      })
      .then(res => res.data);
  }

  static async importProjectFromOdoo(odooProductId, data = {}) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/odoo/projects/${odooProductId}`,
        data
      )
      .then(res => res.data);
  }

  static async getOdooManufacturingOrders(params = {}, config = {}) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v2/odoo/manufacturing-orders`,
        { ...config, params }
      )
      .then(res => res.data);
  }

  static async importManufacturingOrderFromOdoo(
    odooManufacturingOrderId,
    data = {}
  ) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/odoo/manufacturing-orders/${odooManufacturingOrderId}`,
        data
      )
      .then(res => res.data);
  }

  static async getOdooManufacturingOrderSpools(
    odooManufacturingOrderId,
    params = {},
    config = {}
  ) {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/odoo/manufacturing-orders/${odooManufacturingOrderId}/spools`,
        { ...config, params }
      )
      .then(res => res.data);
  }

  static async importManufacturingOrderSpoolFromOdoo(
    odooManufacturingOrderId,
    odooStockMoveId,
    data = {}
  ) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/odoo/manufacturing-orders/${odooManufacturingOrderId}/spools/${odooStockMoveId}`,
        data
      )
      .then(res => res.data);
  }

  static async syncManufacturingOrderToOdoo(
    manufacturingOrderId,
    data = {},
    config = {}
  ) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/odoo/export/manufacturing-orders/${manufacturingOrderId}`,
        data,
        config
      )
      .then(res => res.data);
  }

  static async getExportManufacturingOrderToOdooData(
    manufacturingOrderId,
    params = {},
    config = {}
  ) {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/odoo/export/manufacturing-orders/${manufacturingOrderId}`,
        { ...config, params }
      )
      .then(res => res.data);
  }
}
