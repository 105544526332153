import TitanDialog from '../Titan/TitanDialog';
import TextField from '@mui/material/TextField';
import React from 'react';
import useAsyncEffect from 'use-async-effect';
import MaterialTypeService from '../../services/MaterialTypeService';
import {
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import { MATERIAL_TYPES } from '../../constants';

export default function PresetDialog({ onSave, onClose, preset }) {
  const [name, setName] = React.useState(preset ? preset.name : '');
  const [loading, setLoading] = React.useState(false);
  const [materialTypes, setMaterialTypes] = React.useState([]);

  const [selectedFiberMaterialId, setSelectedFiberMaterialId] =
    React.useState('');
  const [selectedPolymerMaterialId, setSelectedPolymerMaterialId] =
    React.useState('');

  useAsyncEffect(async () => {
    setLoading(true);
    const incomingMaterialTypes = (
      await MaterialTypeService.getMaterialTypes({
        page: 0,
        pageSize: 1000,
      })
    ).data;
    setMaterialTypes(incomingMaterialTypes);

    if (preset?.fiberMaterialTypeId) {
      setSelectedFiberMaterialId(preset.fiberMaterialTypeId);
    }
    if (preset?.polymerMaterialTypeId) {
      setSelectedPolymerMaterialId(preset.polymerMaterialTypeId);
    }
    setLoading(false);
  }, []);

  const onSubmitForm = () => {
    const params = {
      name,
      fiberMaterialTypeId: selectedFiberMaterialId.length
        ? selectedFiberMaterialId
        : null,
      polymerMaterialTypeId: selectedPolymerMaterialId.length
        ? selectedPolymerMaterialId
        : null,
    };
    return onSave(params);
  };

  return (
    <>
      <TitanDialog
        title={`${name ? 'Edit' : 'Create'} Preset`}
        maxWidth="xs"
        onClose={onClose}
        onSave={onSubmitForm}
        saveButtonLabel={preset ? 'Save' : 'Create'}
        isValid={!loading && name && name.length !== 0}
        loading
      >
        {loading ? (
          <>
            <Grid container alignItems={'center'} flexDirection={'column'}>
              <Typography>Loading Materials...</Typography>
              <CircularProgress sx={{ marginTop: 2 }} />
            </Grid>
          </>
        ) : (
          <>
            <TextField
              required
              label="Name"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <TextField
                select
                label="Tape Material"
                fullWidth
                value={selectedFiberMaterialId}
                onChange={(e) => setSelectedFiberMaterialId(e.target.value)}
                defaultValue=""
              >
                <MenuItem key={'mat-type-fb-null'} value={''}>
                  -
                </MenuItem>
                {materialTypes
                  .filter(
                    (materialType) =>
                      materialType.type === MATERIAL_TYPES.FIBER,
                  )
                  .map((materialType) => (
                    <MenuItem key={materialType.id} value={materialType.id}>
                      {materialType.name}
                    </MenuItem>
                  ))}
              </TextField>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                select
                label="Polymer Material"
                fullWidth
                value={selectedPolymerMaterialId}
                onChange={(e) => {
                  setSelectedPolymerMaterialId(e.target.value);
                }}
              >
                <MenuItem key={'mat-type-pl-null'} value={''}>
                  -
                </MenuItem>
                {materialTypes
                  .filter(
                    (materialType) =>
                      materialType.type === MATERIAL_TYPES.PLASTIC,
                  )
                  .map((materialType) => (
                    <MenuItem value={materialType.id} key={materialType.id}>
                      {materialType.name}
                    </MenuItem>
                  ))}
              </TextField>
            </FormControl>
          </>
        )}
      </TitanDialog>
    </>
  );
}
