import React from 'react';
import Grid from '@mui/material/Grid';
import BuildModuleTemperatureControls from './BuildModuleTemperatureControls';
import { makeStyles } from '@mui/styles';
import TitanChart from '../Titan/TitanChart';
import Paper from '@mui/material/Paper';
import TitanCircularProgress from '../Titan/TitanCircularProgress';
import useBuildModuleTemperature from './use-build-module-temperature';
import Stack from '@mui/material/Stack';
import { CSVLink } from 'react-csv';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import TitanDialog from '../Titan/TitanDialog';
import { DataGridPro } from '@mui/x-data-grid-pro';
import useTitanChart from '../Titan/UseTitanChart';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    padding: theme.spacing(2),
    minHeight: 600
  },
  chartWrapper: {
    [theme.breakpoints.up('md')]: {
      height: 650
    }
  },
  sidebar: {
    height: '100%'
  }
}));

export default function BuildModuleTemperatures({ buildModuleId }) {
  const classes = useStyles();

  const {
    loading,
    actualSeries,
    targetSeries,
    currentTemperature,
    csvHeaders,
    csvData
  } = useBuildModuleTemperature(buildModuleId);

  const {
    columns,
    computedSeriesArray,
    isOpenGraphDialog,
    setIsOpenGraphDialog,
    legendLabels,
    setLegendLabels,
    legendColors,
    setLegendColors,
    showedArray,
    changeShowedSeriesArray,
    setTempChanges,
    showAll
  } = useTitanChart(actualSeries, targetSeries);

  const onClose = () => {
    setTempChanges(prev => {
      changeShowedSeriesArray(prev);
      return [];
    });
    setIsOpenGraphDialog(false);
  };

  const onSave = async () => {
    return setTempChanges(prev => []);
  };

  return (
    <Paper className={classes.container}>
      <Grid container>
        <Grid item xs={12} md={9} sx={{ paddingBottom: '45px' }}>
          <div className={classes.chartWrapper}>
            {loading ? (
              <TitanCircularProgress />
            ) : (
              <Box>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="flex-end"
                  sx={{ '& > a': { textDecoration: 'none' } }}
                >
                  <CSVLink
                    data={csvData}
                    headers={csvHeaders}
                    filename={`build-module-${
                      buildModuleId ? buildModuleId : ''
                    }.csv`}
                  >
                    <Button>Download</Button>
                  </CSVLink>
                  <IconButton onClick={() => setIsOpenGraphDialog(true)}>
                    <SettingsIcon />
                  </IconButton>
                </Stack>
                <TitanChart
                  actual={actualSeries}
                  target={targetSeries}
                  legendLabels={legendLabels}
                  legendColors={legendColors}
                  showedArray={showedArray}
                  setLegendLabels={setLegendLabels}
                  setLegendColors={setLegendColors}
                  timeDirection="backward"
                />
              </Box>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={3} className={classes.sidebar}>
          <BuildModuleTemperatureControls temperature={currentTemperature} />
        </Grid>
      </Grid>
      {isOpenGraphDialog && (
        <TitanDialog
          title={`Build Module graph settings`}
          onClose={onClose}
          onSave={onSave}
          maxWidth="md"
          fullWidth
          onAdditionalButton={showAll}
          additionalButtonLabel="Show all"
        >
          <Stack>
            <DataGridPro
              rows={computedSeriesArray}
              columns={columns}
              pageSize={10}
              autoHeight
            />
          </Stack>
        </TitanDialog>
      )}
    </Paper>
  );
}
