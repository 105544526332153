const fusionPlanCategories = [
  'Force control parameter',
  'Heating parameter',
  'Cooling parameter',
  'Mould closing parameter',
  'Mould opening parameter',
  'PID parameter',
  'Other'
];

export default fusionPlanCategories;
