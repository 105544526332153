import React from 'react';
import { useState } from 'react';
import { FUSION_PLAN_PARAMS_CONFIG } from '../../constants';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';

export default function FusionPlanParamsTable({
  search,
  setSearch,
  category,
  configuration,
  finalConfiguration
}) {
  const [params, setParams] = useState([]);
  const [filteredParams, setFilteredParams] = useState([]);

  React.useEffect(() => {
    const rows = FUSION_PLAN_PARAMS_CONFIG[category]
      .map((p, i) => {
        return {
          id: i,
          key: p.label,
          value: configuration[p.key]
            ? `${configuration[p.key]} ${p.dimension}`
            : '',
          finalValue:
            finalConfiguration && configuration[p.key]
              ? `${finalConfiguration[p.key]} ${p.dimension}`
              : ''
        };
      })
      .filter(p => !finalConfiguration || p.value !== p.finalValue);

    // Push params that not defined in FUSION_PLAN_PARAMS_CONFIG to "Other" category
    if (category === 'Other') {
      let allParams = [];
      const initialIndex = rows.length;

      for (const [, params] of Object.entries(FUSION_PLAN_PARAMS_CONFIG)) {
        allParams = [...allParams, ...params];
      }

      const notDefinedParams = Object.keys(configuration).filter(p => {
        return !allParams.find(param => param.key === p);
      });

      setParams([
        ...rows,
        ...notDefinedParams.map((param, index) => {
          return {
            id: initialIndex + index,
            key: param,
            value: configuration[param],
            finalValue:
              finalConfiguration && configuration[param]
                ? finalConfiguration[param]
                : ''
          };
        })
      ]);
    } else {
      setParams(rows);
    }

    setFilteredParams(params);
    setSearch('');
  }, [configuration, category, finalConfiguration]);

  React.useEffect(() => {
    const regexp = new RegExp(search, 'gi');
    const filteredParams = params.filter(param => param.key.match(regexp));

    setFilteredParams(filteredParams);
  }, [params, search]);

  const columns = React.useMemo(() => {
    const columns = [
      {
        headerName: 'Key',
        field: 'key',
        flex: 3,
        sortable: false
      },
      {
        headerName: 'Value',
        field: 'value',
        flex: 1,
        sortable: false
      }
    ];

    if (finalConfiguration) {
      columns.push({
        headerName: 'Final value',
        field: 'finalValue',
        flex: 1,
        sortable: false
      });
    }

    return columns;
  }, [finalConfiguration]);

  return (
    <>
      <TitanDataGrid
        rows={filteredParams}
        rowCount={params.length}
        showSearch={false}
        columns={columns}
        pagination={false}
      />
    </>
  );
}
