import React from 'react';
import TextField from '@mui/material/TextField';
import RolePicker from './RolePicker';
import TitanDialog from '../Titan/TitanDialog';
import MemberService from '../../services/MemberService';

import regexps from '../../constants/regexps';
import { Divider, FormGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Box from '@mui/material/Box';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { add } from 'date-fns';

export default function UserDialog({
  availableRoles,
  onClose,
  onSave,
  user,
  title,
  organizationId
}) {
  const [email, setEmail] = React.useState(user ? user.email : '');
  const [name, setName] = React.useState(user ? user.name : '');
  const [selectedRoleIds, setSelectedRoleIds] = React.useState(
    user ? user.roles.map(({ id }) => id) : []
  );
  const [isTrial, setIsTrial] = React.useState(user ? user.isTrial : false);
  const [trialExpiresAt, setTrialExpiresAt] = React.useState(
    user ? user.trialExpiresAt : null
  );

  const [emailError, setEmailError] = React.useState('');
  const [showEmailError, setShowEmailError] = React.useState(false);

  const handleEmailChange = e => {
    const { value } = e.target;

    if (!regexps.EMAIL.test(value)) {
      setEmailError('Please enter valid email address');
    } else {
      setEmailError('');
    }

    setEmail(value);
  };

  const validateEmailExists = async () => {
    if (email && !emailError) {
      const memberExists = await MemberService.checkMemberExists(
        email,
        organizationId
      );

      if (memberExists) {
        setEmailError('User already exists');
      }
    }

    setShowEmailError(true);
  };

  const handleRoleChange = roleIds => {
    setSelectedRoleIds(roleIds);
  };

  const onChangeTrial = e => {
    setIsTrial(e.target.checked);
    setTrialExpiresAt(e.target.checked ? add(Date.now(), { days: 31 }) : null);
  };

  const onSubmit = React.useCallback(async () => {
    if (!user) {
      const memberExists = await MemberService.checkMemberExists(
        email,
        organizationId
      );

      if (memberExists) {
        setEmailError('User already exists');
        setShowEmailError(true);

        return Promise.reject({ message: 'User already exists' });
      }
    }

    return onSave(
      email,
      selectedRoleIds,
      name,
      isTrial,
      new Date(trialExpiresAt).getTime()
    );
  }, [onSave, email, selectedRoleIds, name, isTrial, trialExpiresAt]);

  return (
    <TitanDialog
      title={title}
      maxWidth="xs"
      onSave={onSubmit}
      onClose={onClose}
      isValid={email && selectedRoleIds.length && !emailError}
      saveButtonLabel={!!user ? 'Update' : 'Send Invite'}
      successSaveMessage={!!user ? 'User updated' : 'Invite sent'}
    >
      <TextField
        fullWidth
        error={showEmailError && !!emailError}
        type="email"
        label="Email"
        value={email}
        disabled={!!user}
        helperText={showEmailError ? emailError : ''}
        onBlur={() => {
          setShowEmailError(true);
          validateEmailExists();
        }}
        onChange={handleEmailChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Name (optional)"
        value={name}
        helperText={showEmailError ? emailError : ''}
        onChange={e => setName(e.target.value)}
        margin="normal"
      />
      <RolePicker
        availableRoles={availableRoles}
        selectedRoleIds={selectedRoleIds}
        onChange={handleRoleChange}
      />
      <Divider />
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={isTrial} onChange={onChangeTrial} />}
          label="Trial period"
        />
      </FormGroup>
      {isTrial && (
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          localeText={{ start: 'Start', end: 'Finish' }}
        >
          <Box sx={{ mt: 2 }}>
            <DateTimePicker
              label="Trial expires at"
              inputFormat="yyyy-MM-dd HH:mm"
              value={trialExpiresAt}
              onChange={newValue => {
                setTrialExpiresAt(newValue);
              }}
              disabled={!isTrial}
              renderInput={params => <TextField {...params} />}
            />
          </Box>
        </LocalizationProvider>
      )}
    </TitanDialog>
  );
}
