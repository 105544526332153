import React, { useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import BuildPlanService from '../../services/BuildPlanService';
import {
  ROUTES,
  ROLES,
  BUILD_PLAN_SHARE_TYPES,
  WEB_SOCKET_ACTIONS,
} from '../../constants';
import TitanPage from '../Titan/TitanPage';
import BuildPlanContent from './BuildPlanContent';
import BuildPlanPreviewDialog from './BuildPlanPreviewDialog';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import BuildPlanPreview from './BuildPlanPreview';
import BuildDialog from '../BuildDialog/BuildDialog';
import IconButton from '@mui/material/IconButton';
import ShareIcon from '../../assets/icons/share-secondary.svg';
import BuildPlanShareDialog from './BuildPlanShareDialog';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import { useAuth0 } from '../Authentication/Auth0';
import BuildPlanFavoriteButton from './BuildPlanFavoriteButton';
import Stack from '@mui/material/Stack';
import CreateIcon from '@mui/icons-material/Create';
import BuildPlanDialog from './BuildPlanDialog';
import PlanService from '../../services/PlanService';
import { useTitan } from '../Titan/Titan';
import useWebSocket from 'react-use-websocket';

export const BuildPlanContext = React.createContext();
export const useBuildPlan = () => useContext(BuildPlanContext);

export default function BuildPlanPage() {
  const { buildPlanId, tab = 'configuration' } = useParams();

  const location = useLocation();
  const { getWebSocketUrl, getWebSocketOptions } = useTitan();

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [buildPlan, setBuildPlan] = React.useState();
  const [buildPlanVersions, setBuildPlanVersions] = React.useState([]);

  const { currentMemberId, roles } = useAuth0();

  const { addPageToPageHistory, backToPreviousPage } = useTitan();

  const [openPreviewDialog, setOpenPreviewDialog] = React.useState(false);
  const [openApproveDialog, setOpenApproveDialog] = React.useState(false);
  const [openBuildDialog, setOpenBuildDialog] = React.useState(false);
  const [openShareDialog, setOpenShareDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openConfirmNotApprovedDialog, setOpenConfirmNotApprovedDialog] =
    React.useState(false);

  const [hasPreforms, setHasPreforms] = React.useState(false);

  const { lastJsonMessage } = useWebSocket(
    getWebSocketUrl,
    getWebSocketOptions(),
  );

  const isAbleToApprove = React.useMemo(
    () =>
      (roles.find((r) => r.id === ROLES.APPLICATION_ENGINEER) &&
        buildPlan?.member?.id === currentMemberId) ||
      roles.find((r) => r.id === ROLES.ADMIN),
    [currentMemberId, roles, buildPlan?.member?.id],
  );

  const isAbleToShare = React.useMemo(
    () =>
      buildPlan &&
      !buildPlan.deletedAt &&
      buildPlan.member?.id === currentMemberId &&
      !buildPlan.approvedVersion,
    [currentMemberId, buildPlan],
  );

  const updateBuildPlan = React.useCallback(
    async (data) => {
      const updatedBuildPlan = await BuildPlanService.updateBuildPlan(
        buildPlan.id,
        data,
        'v2',
      );

      setBuildPlan({
        ...buildPlan,
        ...updatedBuildPlan,
      });
    },
    [buildPlan],
  );

  const loadBuildPlan = () => {
    setLoading(true);

    BuildPlanService.getBuildPlan(
      buildPlanId,
      {
        withRelated: [
          'files',
          'preformTypes',
          'tags',
          'preformTypes.customFields',
          'preformTypes.customFields.image',
          'plasticMaterialType',
          'fiberMaterialType',
          'member',
          'members',
          'project',
          'assignee',
        ],
        withVirtuals: true,
      },
      'v2',
    )
      .then((buildPlan) => {
        setBuildPlan(buildPlan);
        setError(null);
        setLoading(false);

        setHasPreforms(
          buildPlan &&
            buildPlan.preformTypes.some(
              (preformType) => preformType.hasPreforms,
            ),
        );
      })
      .catch((error) => {
        setError(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const loadBuildPlanVersions = () => {
    BuildPlanService.getVersions(buildPlanId, {}, 'v2')
      .then((buildPlanVersions) => {
        setBuildPlanVersions(buildPlanVersions);
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };

  React.useEffect(() => {
    setOpenBuildDialog(false);
    loadBuildPlan();
    loadBuildPlanVersions();
  }, [buildPlanId]);

  React.useEffect(() => {
    if (
      lastJsonMessage !== null &&
      lastJsonMessage.action === WEB_SOCKET_ACTIONS.BUILD_JOB &&
      lastJsonMessage.data &&
      lastJsonMessage.data.buildPlanId === buildPlanId
    ) {
      setHasPreforms(true);
    }
  }, [lastJsonMessage]);

  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Build Plans',
        path: ROUTES.BUILD_PLANS('page', 0),
      },
      {
        name: buildPlan ? buildPlan.name : '',
        disabled: true,
      },
    ],
    [buildPlan],
  );

  return (
    <BuildPlanContext.Provider
      value={{
        buildPlan,
        setBuildPlan,
        buildPlanVersions,
        setBuildPlanVersions,
        loading,
        openPreviewDialog,
        setOpenPreviewDialog,
        openBuildDialog,
        setOpenBuildDialog,
        hasPreforms,
        setHasPreforms,
        updateBuildPlan,
        setOpenShareDialog,
      }}
    >
      <TitanPage
        loading={loading}
        error={error}
        breadcrumbs={breadcrumbs}
        title={
          <Stack direction="row" alignItems="center">
            {buildPlan
              ? `${buildPlan.name} ${PlanService.formatPlanKey(
                  buildPlan.planKey,
                  buildPlan.version,
                )}`
              : ''}
            {buildPlan?.isApproved && (
              <Chip
                label="APPROVED"
                color="primary"
                variant="outlined"
                sx={{ ml: 1 }}
              />
            )}
            {buildPlan?.deletedAt && (
              <Chip
                label="DELETED"
                color="primary"
                variant="outlined"
                sx={{ ml: 1 }}
              />
            )}
          </Stack>
        }
        onBackButtonClick={() => {
          backToPreviousPage(location);
        }}
        headerContent={
          <Stack direction="row" spacing={2}>
            {buildPlan && !buildPlan.deletedAt && (
              <BuildPlanFavoriteButton
                buildPlan={buildPlan}
                onChange={(updatedBuildPlan) => setBuildPlan(updatedBuildPlan)}
              />
            )}

            {isAbleToShare && (
              <IconButton
                onClick={() => setOpenShareDialog(true)}
                title="Share Build Plan"
              >
                <img src={ShareIcon} alt="" />
              </IconButton>
            )}

            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpenPreviewDialog(true)}
              title="Preview Build Plan"
            >
              Preview
            </Button>

            {isAbleToApprove &&
              !buildPlan?.isApproved &&
              !buildPlan?.deletedAt && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenApproveDialog(true)}
                  title={'Approve Build Plan'}
                >
                  Approve
                </Button>
              )}

            {buildPlan && !buildPlan.deletedAt && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  buildPlan.totalVersions > 1 &&
                  ((buildPlan.approvedVersion &&
                    buildPlan.approvedVersion.id !== buildPlan.id) ||
                    buildPlan.id !== buildPlan.defaultVersion.id)
                    ? setOpenConfirmNotApprovedDialog(true)
                    : setOpenBuildDialog(true)
                }
                title="Send Build Plan to Build Module"
              >
                Build
              </Button>
            )}
          </Stack>
        }
        titleContent={
          !loading &&
          !buildPlan?.deletedAt && (
            <IconButton size="large" onClick={() => setOpenEditDialog(true)}>
              <CreateIcon />
            </IconButton>
          )
        }
      >
        <BuildPlanContent
          buildPlanId={buildPlanId}
          tab={tab}
          addPageToPageHistory={addPageToPageHistory}
        />

        {openPreviewDialog && (
          <BuildPlanPreview>
            <BuildPlanPreviewDialog />
          </BuildPlanPreview>
        )}

        {openApproveDialog && (
          <TitanConfirmationDialog
            title={'Approve Build Plan'}
            message={`Are you sure you want to approve ${
              buildPlan.name
            } ${PlanService.formatPlanKey(
              buildPlan.planKey,
              buildPlan.version,
            )}? This action will also automatically share it to the ORGANIZATION.`}
            onConfirm={async () => {
              await BuildPlanService.share(buildPlanId, {
                shareType: BUILD_PLAN_SHARE_TYPES.ORGANIZATION,
              });
              updateBuildPlan({ isApproved: true });
            }}
            onClose={() => setOpenApproveDialog(false)}
          />
        )}

        {openBuildDialog && (
          <BuildDialog
            buildPlan={buildPlan}
            onClose={() => setOpenBuildDialog(false)}
            onSave={(buildJob) => {
              if (buildJob.buildPlan.lastJobIndex > buildPlan.lastJobIndex) {
                setBuildPlan({
                  ...buildPlan,
                  lastJobIndex: buildJob.buildPlan.lastJobIndex,
                });
              }
            }}
          />
        )}

        {openConfirmNotApprovedDialog && (
          <TitanConfirmationDialog
            title="Not approved build plan version"
            message={`The version of this plan you are trying to build is not the approved one. Are you sure you want to continue?`}
            onClose={() => setOpenConfirmNotApprovedDialog(false)}
            onConfirm={() => setOpenBuildDialog(true)}
          />
        )}

        {openShareDialog && (
          <BuildPlanShareDialog
            buildPlan={buildPlan}
            onClose={() => {
              setOpenShareDialog(false);
            }}
            onSave={(updatedBuildPlan) => {
              setBuildPlan({
                ...buildPlan,
                shareType: updatedBuildPlan.shareType,
              });
              setOpenShareDialog(false);
            }}
          />
        )}

        {openEditDialog && (
          <BuildPlanDialog
            buildPlan={buildPlan}
            onClose={() => {
              setOpenEditDialog(false);
            }}
            currentMemberId={currentMemberId}
            onUpdate={(name) => updateBuildPlan({ name })}
          />
        )}
      </TitanPage>
    </BuildPlanContext.Provider>
  );
}
