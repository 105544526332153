import React from 'react';
import { BooleanCell } from './BooleanCell';
import { MultiSelectCell } from './MultiSelectCell';
import { SingleSelectCell } from './SingleSelectCell';
import { StringCell } from './StringCell';

export const RenderPreformsCell = React.memo(
  ({
    row,
    customField,
    buildPlan,
    isEditable,
    preparePreformToUpdate,
    updateComponent,
    updatePreforms,
    updateCustomField,
    prepareCustomFieldsObject
  }) => {
    const preformType = buildPlan.preformTypes.find(
      pT => pT.id === row.preformTypeId
    );

    if (
      row.customFields &&
      preformType.customFields.find(cf => cf.name === customField.name)
    ) {
      const customFieldData = row.customFields.find(
        cf => cf.preformTypeCustomField.name === customField.name
      );
      if (!customFieldData && isEditable(row)) {
        const preformToUpdate = preparePreformToUpdate(
          'INITIAL',
          row,
          customField.name,
          ''
        );
        updateComponent(
          row,
          prepareCustomFieldsObject(row, customField.id, ''),
          customField.name,
          preformToUpdate,
          customField
        );
      }

      if (customField.type === 'BOOLEAN') {
        return (
          <BooleanCell
            row={row}
            customField={customField}
            customFieldData={customFieldData}
            isEditable={isEditable}
            updateCustomField={updateCustomField}
            prepareComponentToUpdate={preparePreformToUpdate}
            updateComponents={updatePreforms}
          />
        );
      } else if (customField.type === 'MULTI_SELECT') {
        return (
          <MultiSelectCell
            row={row}
            customField={customField}
            customFieldData={customFieldData}
            componentTypes={preformType}
            isEditable={isEditable}
            updateCustomField={updateCustomField}
            prepareComponentToUpdate={preparePreformToUpdate}
            updateComponents={updatePreforms}
          />
        );
      } else if (customField.type === 'SINGLE_SELECT') {
        return (
          <SingleSelectCell
            row={row}
            customField={customField}
            customFieldData={customFieldData}
            componentTypes={preformType}
            isEditable={isEditable}
            updateCustomField={updateCustomField}
            prepareComponentToUpdate={preparePreformToUpdate}
            updateComponents={updatePreforms}
          />
        );
      } else {
        return (
          <StringCell
            row={row}
            customField={customField}
            componentTypes={preformType}
            customFieldData={customFieldData}
            isEditable={isEditable}
            updateCustomField={updateCustomField}
            prepareComponentToUpdate={preparePreformToUpdate}
            updateComponents={updatePreforms}
          />
        );
      }
    } else {
      return '';
    }
  }
);
