import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    margin: theme.spacing(4)
  }
}));

export default function EmptyGallerySelectionMenu({
  hideUploadButtons = false,
  onUploadPhotoSelection,
  onTakePhotoSelection
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="body1" color="secondary">
        No Photos
      </Typography>

      {!hideUploadButtons && (
        <Stack spacing={2} direction="row" justifyContent="center" mt={2}>
          <Button onClick={onTakePhotoSelection} color="primary">
            Take Photo
          </Button>
          <Button onClick={onUploadPhotoSelection} color="primary">
            Upload Photo
          </Button>
        </Stack>
      )}
    </div>
  );
}
