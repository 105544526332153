import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TitalLogo from './TitanLogo';
import ProfileService from '../../services/ProfileService';
import { useTitan } from '../Titan/Titan';
import { WELCOME_PAGE_STATES } from './constants';

export default function AuthPageVerifyEmail({ setPageState }) {
  const titan = useTitan();

  const resendValidationEmail = async () => {
    const result = await ProfileService.resendVerificationEmail();

    if (result.isSent) {
      titan.pushSnackbar('Verification email sent was to your email', {
        variant: 'success'
      });
    } else {
      titan.pushSnackbar('Something went wring. Try later', {
        variant: 'error'
      });
    }
  };

  return (
    <Container maxWidth="xs">
      <Box textAlign="center" py={10}>
        <Box textAlign="center">
          <TitalLogo />
        </Box>
        <Box color="primary.main">
          <Typography variant="h6">
            Please verify your email before logging in.
          </Typography>

          <Box mt={2}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={resendValidationEmail}
            >
              Re-send verification email
            </Button>
          </Box>

          <Box mt={2}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={() => setPageState(WELCOME_PAGE_STATES.LOG_IN)}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
