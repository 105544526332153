import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Paper,
  Stack,
  Typography,
  LinearProgress,
  CircularProgress
} from '@mui/material';
import { colors } from '../Theme/vars';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 132,
    padding: theme.spacing(4),
    marginTop: theme.spacing(1)
  },
  progressBar: {
    height: 20,
    marginTop: theme.spacing(2)
  }
}));

function FibrifyProgressBar({
  name,
  currentValue,
  maxValue,
  zeroMaxValueMessage = 'Incorrect maximum value provided'
}) {
  const classes = useStyles();

  const value = React.useMemo(() => (+currentValue / +maxValue) * 100, [
    currentValue,
    maxValue
  ]);

  return (
    <Box>
      {name && <Typography variant="h2">{name}</Typography>}
      <Paper className={classes.container}>
        {maxValue !== 0 &&
        (currentValue === undefined || maxValue === undefined) ? (
          <CircularProgress />
        ) : maxValue < 1 ? (
          <Typography variant="h4" color={colors.GREY}>
            {zeroMaxValueMessage}
          </Typography>
        ) : (
          <Box alignSelf="stretch">
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row">
                <Typography variant="h5">{currentValue}&nbsp;</Typography>
                <Typography variant="h5" color={colors.GREY}>
                  of&nbsp;
                </Typography>
                <Typography variant="h5">{maxValue}&nbsp;</Typography>
                <Typography variant="h5" color={colors.GREY}>
                  {currentValue === 1 ? 'is' : 'are'} done
                </Typography>
              </Stack>
              <Typography variant="h5">{value.toFixed(2)}%</Typography>
            </Stack>
            <LinearProgress
              variant="determinate"
              value={value > 100 ? 100 : value}
              className={classes.progressBar}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
}

export default FibrifyProgressBar;
