import React from 'react';
import { useTitan } from '../Titan/Titan';
import TitanPage from '../Titan/TitanPage';
import useBuildModules from '../BuildModule/use-build-modules';
import BuildModuleCard from '../BuildModule/BuildModuleCard';
import PrinterService from '../../services/PrinterService';
import { ROUTES } from '../../constants';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import TitanDataGrid, { VIEW_MODES } from '../TitanDataGrid/TitanDataGrid';
import BuildModuleLink from '../BuildModule/BuildModuleLink';
import BuildJobLink from '../BuildJob/BuildJobLink';
import BuildModuleState from '../BuildModule/BuildModuleState';
import ComponentStatus from '../Component/ComponentStatus';
import Chip from '@mui/material/Chip';
import { colors } from '../Theme/vars';

function BuildModulesPage() {
  const { addPageToPageHistory } = useTitan();

  const breadcrumbs = React.useMemo(
    () => [
      {
        path: ROUTES.BUILD_MODULES,
        name: 'Build Modules',
        disabled: true,
      },
    ],
    [],
  );

  const buildModuleColumns = React.useMemo(
    () => [
      {
        headerName: 'Build Module',
        field: 'name',
        visibilityBreakpoint: 'sm',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <BuildModuleLink buildModule={row} />,
      },
      {
        headerName: 'State',
        field: 'state',
        visibilityBreakpoint: 'sm',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => (
          <BuildModuleState buildModule={row} showVersion={false} />
        ),
      },
      {
        headerName: 'Version',
        field: 'version',
        visibilityBreakpoint: 'sm',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => row.deviceVersion || '',
      },
      {
        headerName: 'Build Job',
        field: 'buildJob',
        visibilityBreakpoint: 'sm',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => {
          const activeJob = row.activeJobs[0];
          const prevJob = row.prevBuildJob;

          return activeJob || prevJob ? (
            <>
              <BuildJobLink buildJob={activeJob ? activeJob : prevJob} />
              <Chip
                size="small"
                label={activeJob ? 'Active' : 'Previous'}
                sx={{ ml: 1, color: colors.STONE }}
              />
            </>
          ) : (
            ''
          );
        },
      },
      {
        headerName: 'Job Status',
        field: 'buildJobStatus',
        visibilityBreakpoint: 'sm',
        minWidth: 220,
        sortable: false,
        renderCell: ({ row }) => {
          const activeJob = row.activeJobs[0];
          const prevJob = row.prevBuildJob;

          return activeJob || prevJob ? (
            <ComponentStatus
              component={activeJob ? activeJob : prevJob}
              showTooltip={false}
              showVersion={false}
            />
          ) : (
            ''
          );
        },
      },
    ],
    [],
  );

  const loadBuildModules = React.useCallback(async (params, config) => {
    const response = await PrinterService.getPrinters(
      {
        withRelated: [
          'activeJobs',
          'activeJobs.buildPlan',
          'activeJobs.components',
          'prevBuildJob',
          'prevBuildJob.buildPlan',
          'prevBuildJob.components',
        ],
        ...params,
      },
      config,
    );

    return response;
  }, []);

  const buildModulesDataGridProps = useTitanDataGrid(loadBuildModules, {
    columns: buildModuleColumns,
    defaultViewMode: VIEW_MODES.GRID,
  });

  const { rows } = buildModulesDataGridProps;

  const returnBuildModules = React.useCallback(() => {
    return rows;
  }, [rows]);

  const { buildModules } = useBuildModules(returnBuildModules);

  React.useEffect(() => {
    addPageToPageHistory({
      id: 'BUILD_MODULES',
      url: ROUTES.BUILD_MODULES,
      label: 'Build Modules',
    });
  }, []);

  return (
    <TitanPage breadcrumbs={breadcrumbs} title={'Build Modules'}>
      <TitanDataGrid
        {...buildModulesDataGridProps}
        rows={buildModules}
        allowViewModeChange={true}
        renderGridCard={(item) => <BuildModuleCard buildModule={item} />}
        searchPlaceholder="Search by Build Module name..."
      />
    </TitanPage>
  );
}

export default BuildModulesPage;
