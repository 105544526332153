import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import {
  COMPONENT_ACTIVE_STATUSES,
  COMPONENT_STATUSES,
  BUILD_JOB_ACTION_TYPES,
  ROUTES,
  SORT_ORDERS,
} from '../../constants';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ComponentStatus from '../Component/ComponentStatus';
import BuildJobService from '../../services/BuildJobService';
import PrinterService from '../../services/PrinterService';
import BuildModuleLink from '../BuildModule/BuildModuleLink';
import BuildPlanLink from '../BuildPlan/BuildPlanLink';
import { useTitan } from '../Titan/Titan';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import BuildJobActions from './BuildJobActions';
import BuildJobLink from './BuildJobLink';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import { CustomGridColumnsPanel } from '../TitanDataGrid/TitanDataGridColumnsPanel';
import BuildJobsFilter from './BuildJobsFilter';
import BuildJobsChips from './BuildJobsChips';
import useBuildJobs from './use-build-jobs';
import { Tooltip } from '@mui/material';
import ManufacturingOrderLink from '../ManufacturingOrders/ManufacturingOrderLink';

export default function BuildJobsTable({
  buildPlanId,
  manufacturingOrderId,
  rootBuildPlanId,
  buildPlanName,
  buildModuleId,
  buildModuleName,
  creatorId,
  spoolId,
  hiddenColumns = [],
  hideBuildModulesFilter = false,
  hideToolbar = false,
  hideFooter = false,
  pagination = true,
  showFilter = true,
  ...props
}) {
  const history = useHistory();
  const location = useLocation();
  const { page: pageParam = 0 } = useParams();
  const { pushSnackbar, addPageToPageHistory } = useTitan();

  const [currentBuildJob, setCurrentBuildJob] = React.useState();
  const [currentAction, setCurrentAction] = React.useState();

  const [buildJobsIdsToCancel, setBuildJobIdsToCancel] = React.useState();

  const [showAllJobs, setShowAllJobs] = React.useState(true);

  const [buildModules, setBuildModules] = React.useState([]);
  const [chosenBuildModules, setChosenBuildModules] = React.useState([]);

  const activeStatuses = React.useMemo(
    () => [COMPONENT_STATUSES.SCHEDULED, ...COMPONENT_ACTIVE_STATUSES],
    [],
  );

  const loadData = React.useCallback(
    async (query, config) => {
      const params = {
        ...query,
        withDeletedBuildPlan: showAllJobs,
        withRelated: [
          'buildPlan',
          'components',
          'printer',
          'creator',
          'manufacturingOrder',
        ],
      };

      if (buildPlanId) {
        params.buildPlanId = buildPlanId;
      }

      if (buildModuleId) {
        params.buildModuleId = buildModuleId;
      }

      if (manufacturingOrderId) {
        params.manufacturingOrderId = manufacturingOrderId;
      }

      if (chosenBuildModules.length > 0) {
        params.buildModulesIds = chosenBuildModules.map(
          (buildModule) => buildModule.id,
        );
      }

      if (activeStatuses && !showAllJobs) {
        params.statuses = activeStatuses;
      }

      if (creatorId) {
        params.creatorId = creatorId;
      }

      if (spoolId) {
        params.spoolId = spoolId;
      }

      const { data, pagination } = await BuildJobService.getBuildJobs(
        params,
        config,
      );

      return {
        data: data.map((buildJob) => ({
          ...buildJob,
          buildModule: buildJob.printer,
          preforms: buildJob.components,
        })),
        page: pagination.page - 1,
        totalCount: pagination.totalCount,
      };
    },
    [
      buildPlanId,
      manufacturingOrderId,
      buildModuleId,
      chosenBuildModules,
      activeStatuses,
      showAllJobs,
    ],
  );

  const onBuildModulesChange = (e, values) => {
    const chosenBuildModules = [];

    values.forEach((buildModule) => {
      chosenBuildModules.push(buildModule);
    });

    setChosenBuildModules(chosenBuildModules);
  };

  const columns = React.useMemo(
    () => [
      {
        headerName: 'ID',
        field: 'id',
        hideable: false,
        visibilityBreakpoint: 'sm',
        flex: 1,
        minWidth: 170,
        sortable: false,
        renderCell: ({ row, colDef }) => (
          <BuildJobLink buildJob={row} width={colDef.computedWidth} />
        ),
        onCellClick: ({ row }) => {
          if (row.hasReadAccess === false) {
            return;
          }

          history.push({
            pathname: ROUTES.BUILD_JOB(row.id),
            state: { from: location.pathname },
          });
        },
      },
      {
        headerName: 'Build Module',
        field: 'buildModule',
        visibilityBreakpoint: 'sm',
        flex: 1,
        minWidth: 180,
        sortable: false,
        hide: hiddenColumns.includes('buildModule'),
        renderCell: ({ row, colDef }) =>
          row.buildModule ? (
            <BuildModuleLink
              buildModule={row.buildModule}
              width={colDef.computedWidth}
            />
          ) : (
            ''
          ),
        onCellClick: ({ row }) => {
          if (row.buildModule) {
            history.push({
              pathname: ROUTES.BUILD_MODULE(row.buildModule.id),
              state: { from: location.pathname },
            });
          }
        },
      },
      {
        headerName: 'Build Plan',
        field: 'buildPlan',
        visibilityBreakpoint: 'lg',
        flex: 1,
        minWidth: 180,
        sortable: false,
        hide: hiddenColumns.includes('buildPlan'),
        renderCell: ({ row, colDef }) =>
          row.buildPlan ? (
            <BuildPlanLink
              buildPlan={row.buildPlan}
              width={colDef.computedWidth}
            />
          ) : (
            ''
          ),
        onCellClick: ({ row }) => {
          if (row.hasReadAccess === false) {
            return;
          }

          if (row.buildPlan) {
            history.push({
              pathname: ROUTES.BUILD_PLAN_V2(row.buildPlan.id),
              state: { from: location.pathname },
            });
          }
        },
      },
      {
        headerName: 'Manufacturing order',
        field: 'manufacturingOrder',
        visibilityBreakpoint: 'md',
        flex: 1,
        minWidth: 200,
        sortable: false,
        hide: hiddenColumns.includes('manufacturingOrder'),
        renderCell: ({ row }) =>
          row.manufacturingOrder ? (
            <ManufacturingOrderLink
              manufacturingOrder={row.manufacturingOrder}
            />
          ) : (
            ''
          ),
      },
      {
        headerName: 'Operator',
        field: 'operator',
        visibilityBreakpoint: 'md',
        flex: 1,
        minWidth: 200,
        sortable: false,
        hide: hiddenColumns.includes('operator'),
        renderCell: ({ row }) => (row.creator ? row.creator.name : ''),
      },
      {
        headerName: 'Status',
        field: 'status',
        visibilityBreakpoint: 'sm',
        minWidth: 200,
        sortable: false,
        hide: hiddenColumns.includes('status'),
        renderCell: ({ row }) =>
          row.status && (
            <ComponentStatus
              component={{
                status: row.status,
                progress: row.progress,
              }}
            />
          ),
      },
      {
        headerName: 'Time',
        field: 'time',
        visibilityBreakpoint: 'lg',
        minWidth: 200,
        sortable: false,
        hide: hiddenColumns.includes('time'),
        renderCell: ({ row }) => (
          <TitanTimeAgo
            time={
              row.status === COMPONENT_STATUSES.SCHEDULED
                ? row.createdAt
                : row.statusUpdatedAt
            }
            start={row.startBuildTime}
            end={row.endBuildTime}
            status={row.status}
          />
        ),
      },
      {
        hide: hiddenColumns.includes('actions'),
        field: 'actions',
        hideable: false,
        type: 'actions',
        hideInMenu: true,
        visibilityBreakpoint: 'sm',
        width: 60,
        getActions: (params) =>
          BuildJobService.getBuildJobActions({
            buildJob: params.row,
            onFinish: async () => {
              setCurrentAction(BUILD_JOB_ACTION_TYPES.ON_FINISH);
              setCurrentBuildJob(params.row);
            },
            onResend: () => {
              setCurrentAction(BUILD_JOB_ACTION_TYPES.ON_RESEND);
              setCurrentBuildJob(params.row);
            },
            onCancel: () => {
              setCurrentBuildJob(params.row);
              setCurrentAction(BUILD_JOB_ACTION_TYPES.ON_CANCEL);
            },
            onForceCancel: () => {
              setCurrentBuildJob(params.row);
              setCurrentAction(BUILD_JOB_ACTION_TYPES.ON_FORCE_CANCEL);
            },
            onUnload: () => {
              setCurrentAction(BUILD_JOB_ACTION_TYPES.ON_UNLOAD);
              setCurrentBuildJob(params.row);
            },
            onForceUnload: () => {
              setCurrentBuildJob(params.row);
              setCurrentAction(BUILD_JOB_ACTION_TYPES.ON_FORCE_UNLOAD);
            },
            onSetAsBuilt: () => {
              setCurrentBuildJob(params.row);
              setCurrentAction(BUILD_JOB_ACTION_TYPES.ON_SET_AS_BUILT);
            },
            onDeferPostBuilding: () => {
              setCurrentBuildJob(params.row);
              setCurrentAction(BUILD_JOB_ACTION_TYPES.ON_DEFER_POST_BUILDING);
            },
            onAttachToManufacturingOrder: () => {
              setCurrentBuildJob(params.row);
              setCurrentAction(
                BUILD_JOB_ACTION_TYPES.ON_ATTACH_TO_MANUFACTURING_ORDER,
              );
            },
            onDetachFromManufacturingOrder: () => {
              setCurrentBuildJob(params.row);
              setCurrentAction(
                BUILD_JOB_ACTION_TYPES.ON_DETACH_FROM_MANUFACTURING_ORDER,
              );
            },
            onSetBuildJobOperator: () => {
              setCurrentBuildJob(params.row);
              setCurrentAction(
                BUILD_JOB_ACTION_TYPES.ON_SET_BUILD_JOB_OPERATOR,
              );
            },
          }).map((action) =>
            action.disabled ? (
              <Tooltip
                title={action.tooltipText}
                showInMenu
                placement="left-start"
              >
                <span>
                  <GridActionsCellItem
                    icon={action.icon}
                    label={action.label}
                    onClick={action.onClick}
                    showInMenu
                    disabled={action.disabled}
                  />
                </span>
              </Tooltip>
            ) : (
              <GridActionsCellItem
                icon={action.icon}
                label={action.label}
                showInMenu
                onClick={action.onClick}
                disabled={action.disabled}
              />
            ),
          ),
      },
    ],
    [],
  );

  const pushHistory = React.useCallback(
    (page) => {
      let newRecentPage;

      if (buildPlanId || rootBuildPlanId) {
        newRecentPage = {
          id: `BUILD_PLAN:${buildPlanId || rootBuildPlanId}`,
          url: ROUTES.BUILD_PLAN_V2_TAB_PAGE(
            buildPlanId || rootBuildPlanId,
            'build-jobs',
            page,
          ),
          label:
            page === 0
              ? `${buildPlanName || 'Build Plan'} | Build Jobs`
              : `${buildPlanName || 'Build Plan'} | Build Jobs | Page : ${
                  page + 1
                }`,
        };
      } else if (buildModuleId) {
        newRecentPage = {
          id: `BUILD_MODULE:${buildModuleId}`,
          url: ROUTES.BUILD_MODULE_TAB_PAGE(buildModuleId, 'buildJobs', page),
          label:
            page === 0
              ? `${
                  buildModuleName ? buildModuleName : 'Build Module'
                } | Build Jobs`
              : `${
                  buildModuleName ? buildModuleName : 'Build Module'
                } | Build Jobs | Page : ${page + 1}`,
        };
      }
      addPageToPageHistory(newRecentPage);
    },
    [buildPlanId, rootBuildPlanId, buildModuleId],
  );

  const gridOptions = React.useMemo(() => {
    const options = {
      orders: {
        updated_at: SORT_ORDERS.DESC,
      },
    };

    if (props.pageSize) {
      options.pageSize = props.pageSize;
    }

    if (pageParam && Number(pageParam) > 0) {
      options.page = Number(pageParam);
    }
    options.columns = columns;

    options.onChangePage = (page) => {
      pushHistory(page);
    };
    options.pinnedColumns = {
      left: ['id'],
      right: ['actions'],
    };

    return options;
  }, [props, columns]);

  const titanDataGridProps = useTitanDataGrid(loadData, gridOptions);

  const { rows, pageSize, reloadData } = titanDataGridProps;

  const loadBuildJobs = React.useCallback(() => {
    return rows;
  }, [rows]);

  const filterBuildJobs = React.useCallback(
    (buildJobs) => {
      let filterBuildJobs = buildJobs.filter((newBuildJob) => {
        let filtered =
          (buildModuleId &&
            (newBuildJob.buildModuleId === buildModuleId ||
              newBuildJob.printerId === buildModuleId)) ||
          (buildPlanId && newBuildJob.buildPlanId === buildPlanId) ||
          (!buildModuleId && !buildPlanId);

        if (filtered && activeStatuses && !showAllJobs) {
          filtered = activeStatuses.includes(newBuildJob.status);
        }

        return filtered;
      });

      if (filterBuildJobs.length > pageSize) {
        filterBuildJobs = filterBuildJobs.slice(0, pageSize);
      }

      return filterBuildJobs;
    },
    [
      buildModuleId,
      buildPlanId,
      chosenBuildModules,
      rows,
      activeStatuses,
      showAllJobs,
    ],
  );

  const { buildJobs, updateBuildJob } = useBuildJobs(
    loadBuildJobs,
    filterBuildJobs,
    !spoolId,
  );

  const cancelAllBuildJobs = React.useCallback(() => {
    const buildJobsIdsToCancel = rows
      .filter((buildJob) =>
        [
          COMPONENT_STATUSES.SCHEDULED,
          COMPONENT_STATUSES.LOAD_TO_BM,
          COMPONENT_STATUSES.LOADED_TO_BM,
          COMPONENT_STATUSES.LOAD_TO_BM_FAILED,
          COMPONENT_STATUSES.UNLOAD_FROM_BM,
          COMPONENT_STATUSES.PRE_BUILDING,
        ].includes(buildJob.status),
      )
      .map((buildJob) => buildJob.id);

    if (buildJobsIdsToCancel.length) {
      setBuildJobIdsToCancel(buildJobsIdsToCancel);
    } else {
      pushSnackbar('There are no active Build Jobs ', { variant: 'warning' });
    }
  }, [rows, pushSnackbar]);

  const actions = React.useMemo(
    () => [
      {
        title: 'Cancel All',
        onClick: cancelAllBuildJobs,
      },
    ],
    [cancelAllBuildJobs],
  );

  React.useEffect(() => {
    PrinterService.getPrinters().then(({ data }) => setBuildModules(data));
  }, []);

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        searchPlaceholder="Search by Build Job key or ID, or Build Plan key or name"
        rows={buildJobs}
        components={
          showFilter
            ? {
                Toolbar: TitanDataGridToolbar,
                ColumnsPanel: CustomGridColumnsPanel,
              }
            : null
        }
        actions={actions}
        filtersContent={
          activeStatuses || rootBuildPlanId ? (
            <BuildJobsFilter
              showAllJobs={showAllJobs}
              setShowAllJobs={() => setShowAllJobs(!showAllJobs)}
              hideBuildModulesFilter={hideBuildModulesFilter}
              buildModules={buildModules}
              chosenBuildModules={chosenBuildModules}
              onBuildModulesChange={onBuildModulesChange}
            />
          ) : (
            ''
          )
        }
        chipsContent={
          <BuildJobsChips
            showAllJobs={showAllJobs}
            onChangeShowAllJobs={setShowAllJobs}
          />
        }
        hideToolbar={hideToolbar}
        hideFooter={hideFooter}
        pagination={pagination}
      />

      {buildJobsIdsToCancel && (
        <TitanConfirmationDialog
          title="Cancel all Build Jobs?"
          message="Are you sure you want to cancel all Build Jobs?"
          onClose={() => setBuildJobIdsToCancel(null)}
          onConfirm={async () => {
            await Promise.all(
              buildJobsIdsToCancel.map((buildJobId) =>
                BuildJobService.cancelBuildJob({
                  id: buildJobId,
                }),
              ),
            );
          }}
        />
      )}

      <BuildJobActions
        buildJob={currentBuildJob}
        currentAction={currentAction}
        onSuccess={(updatedBuildJob) => {
          updateBuildJob(updatedBuildJob);
          reloadData();
        }}
        onCancel={() => setCurrentAction(null)}
      />
    </>
  );
}
