import React from 'react';

import { useFuseDialog } from './FuseDialog';

import PreformCustomFields from '../Preform/PreformCustomFields';
import FinalPartService from '../../services/FinalPartService';
import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';

export default function FuseDialogPreFusionCustomFieldsStep() {
  const {
    selectedFinalPartTypeId,
    setSelectedFinalPartTypeId,
    finalPartTypes,
    finalParts,
    setFinalParts,
  } = useFuseDialog();

  const finalPartTypeCustomFields = React.useMemo(() => {
    const finalPartType = finalPartTypes.find(
      (finalPartType) => finalPartType.id === selectedFinalPartTypeId,
    );

    return finalPartType && finalPartType.customFields
      ? finalPartType.customFields.filter(
          (customField) => customField.formType === 'PRE_FUSION',
        )
      : [];
  }, [finalPartTypes, selectedFinalPartTypeId]);

  const finalPartCustomFields = React.useMemo(() => {
    const finalPart = finalParts.find(
      (finalPart) => finalPart.finalPartTypeId === selectedFinalPartTypeId,
    );

    return FinalPartService.prepareFinalPartCustomFields(
      0,
      finalPartTypeCustomFields,
      finalPart.customFields,
    );
  }, [finalPartTypeCustomFields, finalParts, selectedFinalPartTypeId]);

  const onChangeCustomField = React.useCallback(
    (finalPartCustomFields) => {
      setFinalParts((prev) =>
        prev.map((finalPart) => {
          if (finalPart.finalPartTypeId !== selectedFinalPartTypeId) {
            return finalPart;
          }

          return {
            ...finalPart,
            customFields: finalPartCustomFields,
          };
        }),
      );
    },
    [selectedFinalPartTypeId],
  );

  return (
    <TabContext value={selectedFinalPartTypeId}>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          display: 'flex',
          height: '100%',
        }}
      >
        <Box sx={{ width: '255px', height: '100%' }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={selectedFinalPartTypeId}
            onChange={(e, newTab) => {
              setSelectedFinalPartTypeId(newTab);
            }}
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            {finalPartTypes.map((finalPartType) => (
              <Tab
                key={finalPartType.id}
                label={finalPartType.name}
                value={finalPartType.id}
                sx={{ alignItems: 'start' }}
              />
            ))}
          </Tabs>
        </Box>
        <Box sx={{ width: 'calc(100% - 210px)', height: '100%' }}>
          <Box sx={{ height: '100%', ml: 2 }}>
            <PreformCustomFields
              customFields={finalPartTypeCustomFields}
              entityCustomFields={finalPartCustomFields}
              onChange={onChangeCustomField}
            />
          </Box>
        </Box>
      </Box>
    </TabContext>
  );
}
