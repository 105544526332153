import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Link } from '@mui/material';
import { ROUTES } from '../../constants';
import FusionModuleIcon from '../../assets/icons/fusion-module-small-secondary.svg';
import { makeStyles } from '@mui/styles';
import OverflowTip from '../ExpandRenderCell/OverflowTip';
import { useTitan } from '../Titan/Titan';

const useStyles = makeStyles(theme => ({
  fusionModuleLink: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden'
  },
  deviceIcon: {
    marginRight: theme.spacing(1)
  }
}));

export default function FusionModuleLink({
  fusionModule,
  children,
  useName = true,
  width,
  onClick = e => e.stopPropagation()
}) {
  const classes = useStyles();
  const location = useLocation();
  const imageWithSpacingWidth = 52;

  const { addPageToPageHistory, preparePageObjectForLink } = useTitan();

  const handleClick = e => {
    addPageToPageHistory(
      preparePageObjectForLink(
        'FUSION_MODULE',
        fusionModule.id,
        fusionModule.name || 'Fusion Module',
        ROUTES.FUSION_MODULE_TAB(fusionModule.id, 'telemetry'),
        'Telemetry'
      )
    );

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Link
      onClick={handleClick}
      to={{
        pathname: ROUTES.FUSION_MODULE(fusionModule.id),
        state: { from: location.pathname }
      }}
      component={RouterLink}
      className={classes.fusionModuleLink}
    >
      <img className={classes.deviceIcon} src={FusionModuleIcon} alt="" />
      {width ? (
        <OverflowTip width={width - imageWithSpacingWidth}>
          {useName ? fusionModule.name : children}
        </OverflowTip>
      ) : useName ? (
        fusionModule.name
      ) : (
        children
      )}
    </Link>
  );
}
