import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import BuildModuleSimpleCard from '../BuildModules/BuildModuleSimpleCard';
import PreformsTable from '../Preforms/PreformsTable';
import TitanInfoItem from '../Titan/TitanInfoItem';
import TitanDateTime from '../Titan/TitanDateTime';
import FinalPartsTable from '../FinalPart/FinalPartsTable';
import useBuildModule from '../BuildModule/use-build-module';
import TitanCircularProgress from '../Titan/TitanCircularProgress';

export default function ResendBuildJobDialog({ jobToResend }) {
  const { id, creator, printer, buildPlan, createdAt } = jobToResend;

  const loadBuildModule = React.useCallback(async () => printer, [printer]);

  const { loading, buildModule } = useBuildModule(loadBuildModule);

  return (
    <Grid
      sx={{
        marginTop: 4
      }}
    >
      <Grid container display="flex" wrap="wrap">
        <Grid
          item
          xs={6}
          lg={12}
          sx={{
            marginLeft: {
              xs: 2,
              lg: 0
            },
            marginBottom: {
              xs: 0,
              lg: 3
            },
            order: {
              xs: 2,
              lg: 1
            }
          }}
        >
          <Paper
            sx={{
              display: 'flex',
              padding: '20px',
              flexDirection: {
                xs: 'column',
                lg: 'row'
              },
              justifyContent: {
                xs: 'flex-start',
                lg: 'space-between'
              }
            }}
          >
            <Grid>
              <TitanInfoItem label="Build Plan">{buildPlan.name}</TitanInfoItem>
            </Grid>
            <Grid>
              <TitanInfoItem label="Creator">
                {creator ? creator.name : '-'}
              </TitanInfoItem>
            </Grid>
            <Grid>
              <TitanInfoItem label="Created">
                <TitanDateTime time={createdAt} />
              </TitanInfoItem>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          item
          xs={5}
          lg={3}
          sx={{
            paddingRight: {
              lg: 2.5
            },
            order: {
              xs: 1,
              lg: 2
            }
          }}
        >
          {loading ? (
            <TitanCircularProgress />
          ) : (
            <BuildModuleSimpleCard buildModule={buildModule} />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          lg={9}
          sx={{
            marginTop: {
              xs: 5,
              lg: 0
            },
            order: {
              xs: 3
            }
          }}
        >
          {buildPlan ? (
            <PreformsTable
              buildJobId={id}
              isHeaderShown={false}
              columnsToHide={['buildModule', 'operator']}
            />
          ) : (
            <FinalPartsTable fusionJobId={id} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
