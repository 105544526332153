import React from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import AddIcon from '@mui/icons-material/Add';
import TitanPageFilters from '../Titan/TitanPageFilters';
import { CustomPagination } from './TitanCustomPagination';

export const VIEW_MODES = {
  GRID: 'GRID',
  TABLE: 'TABLE',
};

export default function TitanDataGrid({
  title,
  allowViewModeChange = false,
  viewMode = VIEW_MODES.TABLE,
  setViewMode,
  className,
  onCreateClick,
  onCreateClickDisabled = false,
  onCreateClickDisabledTooltip = '',
  createButtonLabel = 'new',
  showSearch = true,
  searchPlaceholder = '',
  onRowClick,
  columns = [],
  filtersContent,
  chipsContent,
  orders,
  actions,
  hideToolbar = false,
  hideFooter = false,
  rowReordering = false,
  onRowOrderChange,
  onCellEditCommit,
  isCellEditable,
  components,
  rowsPerPageOptions = [5, 10, 25, 50, 100],
  page,
  setPage,
  pageSize,
  setPageSize,
  loading,
  rows,
  rowCount,
  search,
  setSearch,
  order,
  setOrder,
  getRowId,
  getRowHeight,
  getRowClassName,
  pagination = true,
  initialState,
  columnVisibilityModel,
  apiRef,
  treeData = false,
  groupingColDef,
  getTreeDataPath = (row) => row.hierarchy,
  defaultGroupingExpansionDepth = 0,
  setVisibilityModel,
  paginationMode = 'server',
  nextPageAvailable,
  checkboxSelection = false,
  checkboxMultiSelection = false,
  selectedRows,
  onSelectionModelChange,
  selectionChipsNameKey = 'name',
  hideFooterSelectedRowCount = true,
  disableMultipleSelection = false,
  isRowSelectable,
  renderGridCard,
  gridBreakpoints = { xs: 12, sm: 12, md: 6, lg: 4, xl: 4 },
}) {
  const { pathname } = useLocation();

  const [newSelectedItemIds, setNewSelectedItemIds] = React.useState(
    selectedRows ? selectedRows.map((i) => i.id) : [],
  );
  const [newSelectedItems, setNewSelectedItems] = React.useState(
    selectedRows ? selectedRows : [],
  );

  const onSelectionModelChangeHandler = React.useCallback(
    (pageSelectedItemIds) => {
      let updatedSelectedItemIds;
      let updatedSelectedItems;

      if (!checkboxMultiSelection) {
        const selectionSet = new Set(newSelectedItemIds);

        updatedSelectedItemIds = pageSelectedItemIds.filter(
          (s) => !selectionSet.has(s),
        );
        updatedSelectedItems =
          updatedSelectedItemIds.length !== 0
            ? [rows.find((i) => i.id === updatedSelectedItemIds[0])]
            : [];
      } else {
        const pageItemIds = rows.map((row) => row.id);
        const currentPageSelectedItems = rows.filter((row) =>
          pageSelectedItemIds.includes(row.id),
        );

        const filteredItemIds = newSelectedItemIds.filter(
          (itemId) => !pageItemIds.includes(itemId),
        );

        updatedSelectedItemIds = [...filteredItemIds, ...pageSelectedItemIds];

        const filteredItems = newSelectedItems.filter(
          (item) => !pageItemIds.includes(item.id),
        );

        updatedSelectedItems = [...filteredItems, ...currentPageSelectedItems];
      }

      setNewSelectedItemIds(updatedSelectedItemIds);
      setNewSelectedItems(updatedSelectedItems);
    },
    [
      rows,
      newSelectedItems,
      newSelectedItemIds,
      setNewSelectedItems,
      setNewSelectedItemIds,
    ],
  );

  const deleteSelectedItem = React.useCallback(
    (itemId) => {
      setNewSelectedItems((prev) => prev.filter((i) => i.id !== itemId));
      setNewSelectedItemIds((prev) => prev.filter((i) => i !== itemId));
    },
    [setNewSelectedItems, setNewSelectedItemIds],
  );

  React.useEffect(() => {
    if (!selectedRows) {
      return;
    }

    setNewSelectedItemIds(selectedRows.map((i) => i.id));
    setNewSelectedItems(selectedRows);
  }, [selectedRows]);

  React.useEffect(() => {
    if (onSelectionModelChange) {
      onSelectionModelChange(newSelectedItems);
    }
  }, [newSelectedItems]);

  React.useEffect(() => {
    const pageName = _.camelCase(pathname);

    const cachedViewMode = localStorage.getItem(`${pageName}ViewMode`);
    const cachedViewModeValid =
      Object.values(VIEW_MODES).includes(cachedViewMode);

    if (
      cachedViewMode &&
      cachedViewModeValid &&
      renderGridCard &&
      setViewMode
    ) {
      setViewMode(cachedViewMode);
    }
  }, [pathname, setViewMode, renderGridCard]);

  const renderHeader = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2,
      }}
    >
      {title && (
        <Box sx={{ flexGrow: 1, display: 'flex' }}>
          <Typography variant="h2" sx={{ mr: 2 }}>
            {title}
          </Typography>
        </Box>
      )}
      {title && onCreateClick && (
        <Tooltip title={onCreateClickDisabledTooltip}>
          <span>
            <Button
              onClick={onCreateClick}
              startIcon={<AddIcon />}
              color="primary"
              variant="text"
              disabled={onCreateClickDisabled}
            >
              {createButtonLabel}
            </Button>
          </span>
        </Tooltip>
      )}
    </Box>
  );

  const preparedChipsContent =
    chipsContent || checkboxSelection || checkboxMultiSelection ? (
      <Stack>
        {chipsContent ? chipsContent : ''}
        {checkboxSelection || checkboxMultiSelection ? (
          <Stack direction="row" alignItems="center" flexWrap="wrap" rowGap={2}>
            <Typography variant="subtitle2" sx={{ mr: 2 }}>
              Selected items:
            </Typography>
            {newSelectedItems.length !== 0 ? (
              newSelectedItems.map((item) => {
                let label = item[selectionChipsNameKey];

                if (!label || label.length === 0) {
                  const row = rows.find((row) => row.id === item.id);

                  if (row && row[selectionChipsNameKey]) {
                    label = row[selectionChipsNameKey];
                  } else {
                    label = item.id;
                  }
                }

                return (
                  <Chip
                    label={label}
                    onDelete={() => deleteSelectedItem(item.id)}
                    key={item.id}
                    sx={{ mr: 2 }}
                  />
                );
              })
            ) : (
              <Typography variant="caption">no selected</Typography>
            )}
          </Stack>
        ) : (
          ''
        )}
      </Stack>
    ) : (
      ''
    );

  return (
    <Box>
      {!hideToolbar ? (
        <>
          {(title || onCreateClick) && renderHeader()}

          <Box display="flex" alignItems="center">
            {(showSearch || orders || allowViewModeChange) && (
              <Box sx={{ mb: 2, flexGrow: 1 }}>
                <TitanPageFilters
                  showSearch={showSearch}
                  search={search}
                  searchPlaceholder={searchPlaceholder}
                  onChangeSearch={setSearch}
                  allowViewModeChange={allowViewModeChange}
                  viewMode={viewMode}
                  onViewModeChange={setViewMode}
                  filtersContent={filtersContent}
                  chipsContent={preparedChipsContent}
                  onChangeOrder={setOrder}
                  order={order}
                  actions={actions}
                />
              </Box>
            )}

            {!title && onCreateClick && (
              <Tooltip title={onCreateClickDisabledTooltip}>
                <span>
                  <Button
                    onClick={onCreateClick}
                    startIcon={<AddIcon />}
                    color="primary"
                    variant="text"
                    disabled={onCreateClickDisabled}
                    sx={{ mb: '16px' }}
                  >
                    {createButtonLabel}
                  </Button>
                </span>
              </Tooltip>
            )}
          </Box>
        </>
      ) : (
        ''
      )}

      {viewMode === VIEW_MODES.TABLE && (
        <Paper
          sx={{
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
              {
                display: 'none',
              },
          }}
        >
          <DataGridPro
            apiRef={apiRef}
            className={className}
            autoHeight
            treeData={treeData}
            getTreeDataPath={getTreeDataPath}
            groupingColDef={groupingColDef}
            disableColumnMenu
            disableSelectionOnClick
            columns={columns}
            rows={rows}
            page={page}
            pageSize={pageSize}
            rowCount={rowCount}
            loading={loading}
            paginationMode={paginationMode}
            onCellClick={(params, event) => {
              if (
                event.currentTarget.dataset &&
                event.currentTarget.dataset.field === 'actions'
              ) {
                return;
              }

              if (params.colDef && params.colDef.onCellClick) {
                params.colDef.onCellClick(params);
              }

              if (onRowClick) {
                event.defaultMuiPrevented = true;

                return onRowClick(event, params.row);
              }
            }}
            onPageChange={setPage}
            onPageSizeChange={(pageSize) => {
              setPage(0);
              setPageSize(pageSize);
            }}
            rowsPerPageOptions={rowsPerPageOptions}
            hideFooter={hideFooter}
            hideFooterSelectedRowCount={hideFooterSelectedRowCount}
            checkboxSelection={checkboxSelection || checkboxMultiSelection}
            onSelectionModelChange={onSelectionModelChangeHandler}
            selectionModel={newSelectedItemIds}
            keepNonExistentRowsSelected
            rowReordering={rowReordering}
            onRowOrderChange={onRowOrderChange}
            disableMultipleSelection={disableMultipleSelection}
            isRowSelectable={isRowSelectable}
            isCellEditable={isCellEditable}
            onCellEditCommit={onCellEditCommit}
            components={{ ...components, Pagination: CustomPagination }}
            componentsProps={{
              pagination: {
                rowCount,
                nextPageAvailable,
              },
              selectedPreformspagination: {
                setPage,
                rowCount,
                setPageSize,
                page,
                pageSize,
              },
            }}
            getRowId={getRowId}
            getRowHeight={getRowHeight}
            getRowClassName={getRowClassName}
            pagination={pagination}
            initialState={initialState}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => {
              if (setVisibilityModel) {
                setVisibilityModel(newModel);
              }
            }}
            // mui will fix this in future (they know about this bug)
            sx={{
              '& .MuiDataGrid-columnSeparator--resizable svg': {
                pointerEvents: 'none',
              },
              '& .MuiDataGrid-pinnedColumns, & .MuiDataGrid-pinnedColumnHeaders':
                {
                  backgroundColor: '#fff',
                  boxShadow: 'none',
                },
            }}
            defaultGroupingExpansionDepth={defaultGroupingExpansionDepth}
          />
        </Paper>
      )}

      {viewMode === VIEW_MODES.GRID && (
        <>
          {loading ? (
            <LinearProgress data-pw="titan-data-grid-loading" />
          ) : (
            <Grid container spacing={3}>
              {rows.length !== 0 ? (
                rows.map((item) => (
                  <Grid item key={item.id} {...gridBreakpoints}>
                    {renderGridCard(item)}
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} display="flex" justifyContent="center" m={6}>
                  <Typography variant="h3">No results found.</Typography>
                </Grid>
              )}
            </Grid>
          )}
        </>
      )}
    </Box>
  );
}
