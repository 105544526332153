import React from 'react';

export default function useBuildJobState(buildJob) {
  const buildJobState = React.useMemo(() => {
    return buildJob.status ? buildJob.status : null;
  }, [buildJob]);

  const buildJobProgress = React.useMemo(() => {
    return buildJob.progress ? buildJob.progress : 0;
  }, [buildJob]);

  return {
    buildJobState,
    buildJobProgress
  };
}
