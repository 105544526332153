import React from 'react';
import TitanDialog from '../Titan/TitanDialog';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PreBuildingChecklistService from '../../services/PreBuildingChecklistService';
import OrganizationService from '../../services/OrganizationService';
import { useAuth0 } from '../Authentication/Auth0';
import { ROLES } from '../../constants';
import { Box } from '@mui/material';

export default function PreBuildingChecklistDialog(props) {
  const { mode, preBuildingChecklist, onClose, onSave } = props;

  const { profile, roles } = useAuth0();

  const isSuperAdmin = React.useMemo(
    () => roles && roles.find(r => r.id === ROLES.SUPER_ADMIN) !== undefined,
    [roles]
  );

  const isOrganizationAdmin = React.useMemo(
    () => roles && roles.find(r => r.id === ROLES.ADMIN) !== undefined,
    [roles]
  );

  const [name, setName] = React.useState(preBuildingChecklist.name || '');

  const [organizations, setOrganizations] = React.useState([]);
  const [chosenOrganization, setChosenOrganization] = React.useState(null);
  const [
    organizationHasChecklist,
    setOrganizationHasChecklist
  ] = React.useState(false);

  const onOrganizationChose = async (e, value) => {
    setChosenOrganization(value);

    setOrganizationHasChecklist(await checkOrganizationHasChecklist(value.id));
  };

  const checkOrganizationHasChecklist = async chosenOrganizationId => {
    const organizationId = isOrganizationAdmin
      ? profile.organization.id
      : chosenOrganizationId;

    const {
      data
    } = await PreBuildingChecklistService.getChecklistByOrganizationId(
      organizationId
    );

    return data.length > 0;
  };

  const onSubmitForm = () => {
    let organizationId = null;

    if (isOrganizationAdmin) {
      organizationId = profile.organization.id;
    } else if (isSuperAdmin) {
      organizationId = chosenOrganization ? chosenOrganization.id : null;
    }

    if (mode === 'create') {
      return PreBuildingChecklistService.createChecklist({
        name,
        organizationId
      }).then(data => {
        onSave(data);
      });
    } else {
      return PreBuildingChecklistService.updateChecklist(
        preBuildingChecklist.id,
        {
          name,
          organizationId
        }
      ).then(data => {
        onSave(data);
      });
    }
  };

  const isValid = name.length !== 0 && !organizationHasChecklist;

  React.useEffect(() => {
    if (isSuperAdmin) {
      OrganizationService.getOrganizations().then(({ data }) => {
        const organization = data.find(
          organization =>
            organization.id === preBuildingChecklist.organizationId
        );

        setChosenOrganization(organization);
        setOrganizations(data);
      });
    }
  }, []);

  return (
    <TitanDialog
      title={`${mode === 'create' ? 'Create' : 'Edit'} checklist`}
      onClose={onClose}
      onSave={onSubmitForm}
      isValid={isValid}
      successSaveMessage={`Checklist successfully ${
        mode === 'create' ? 'created' : 'updated'
      }`}
    >
      <Box display="flex" flexDirection="column">
        <TextField
          autoFocus
          label="Name"
          value={name}
          fullWidth
          margin="normal"
          onChange={e => setName(e.target.value)}
        />
        {isSuperAdmin && (
          <FormControl margin="normal">
            <Autocomplete
              id="organization-input"
              value={chosenOrganization}
              onChange={onOrganizationChose}
              options={organizations}
              getOptionLabel={option => option.name}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Organization"
                  placeholder="Select organization..."
                  error={organizationHasChecklist}
                  helperText={
                    organizationHasChecklist &&
                    'This organization already has a checklist'
                  }
                />
              )}
            />
          </FormControl>
        )}
      </Box>
    </TitanDialog>
  );
}
