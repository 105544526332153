import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { ROLES } from '../../constants';

export default function RolePicker({
  availableRoles,
  selectedRoleIds,
  onChange
}) {
  const handleChange = roleId => {
    const selected = selectedRoleIds.includes(roleId);

    let newRoleIds = [];

    if (selected) {
      newRoleIds = selectedRoleIds.filter(id => id !== roleId);

      if (roleId === ROLES.APPLICATION_ENGINEER) {
        newRoleIds = newRoleIds.filter(id => id !== ROLES.ADMIN);
      }
    } else {
      newRoleIds = [...selectedRoleIds, roleId];

      if (![ROLES.APPLICATION_ENGINEER, ROLES.ADMIN].includes(roleId)) {
        newRoleIds = newRoleIds.filter(
          id => ![ROLES.APPLICATION_ENGINEER, ROLES.ADMIN].includes(id)
        );
      }
    }

    onChange && onChange(newRoleIds);
  };

  const roles = React.useMemo(() => {
    return availableRoles.map(role => {
      const checked = selectedRoleIds.includes(role.id);

      let disabled =
        (role.availableNumber === 0 && selectedRoleIds.length === 0) ||
        (role.id === ROLES.ADMIN
          ? !selectedRoleIds.includes(ROLES.APPLICATION_ENGINEER)
          : selectedRoleIds.length >= 1 && !checked);

      // enable app engineer when selected only admin
      if (
        role.id === ROLES.APPLICATION_ENGINEER &&
        selectedRoleIds.length === 1 &&
        selectedRoleIds[0] === ROLES.ADMIN
      ) {
        disabled = false;
      }

      return {
        ...role,
        disabled
      };
    });
  }, [availableRoles, selectedRoleIds]);

  return (
    <FormControl component="fieldset" margin="normal">
      <FormLabel component="legend">Select roles</FormLabel>
      <FormGroup>
        {roles.map(role => (
          <FormControlLabel
            key={role.id}
            control={
              <Checkbox
                disabled={role.disabled}
                checked={selectedRoleIds.includes(role.id)}
                onChange={() => handleChange(role.id)}
                name={role.name}
              />
            }
            label={`${role.name} (Available ${role.availableNumber})`}
          />
        ))}
      </FormGroup>
      <FormHelperText>
        You can assign Admin role only to Application Engineer
      </FormHelperText>
    </FormControl>
  );
}
