import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTitan } from '../Titan/Titan';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { Grid, Button, List } from '@mui/material';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TitanInfoItem from '../Titan/TitanInfoItem';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import BuildJobLink from '../BuildJob/BuildJobLink';
import PreformLink from '../Component/PreformLink';
import BuildModuleState from './BuildModuleState';
import ComponentStatus from '../Component/ComponentStatus';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import BuildModuleIcon from '../../assets/icons/build-module.svg';
import { COMPONENT_STATUSES, ROUTES } from '../../constants';
import { colors } from '../Theme/vars';
import useBuildJob from '../BuildJob/use-build-job';

const useStyles = makeStyles({
  container: {
    border: `2.75px solid ${colors.WHITE}`,
  },
  selected: {
    border: `2.75px solid ${colors.RED}`,
  },
  content: {
    width: '100%',
  },
  statusBar: {
    width: '100%',
    backgroundColor: colors.GREY2,
  },
});

export default function BuildModuleCard({ buildModule, onClick, selected }) {
  const classes = useStyles();

  const { addPageToPageHistory } = useTitan();
  const history = useHistory();
  const location = useLocation();

  const [prevJob, setPrevJob] = React.useState();
  const [showAllPreforms, setShowAllPreforms] = React.useState(false);

  const loadActiveBuildJob = React.useCallback(() => {
    if (buildModule.activeJobs && buildModule.activeJobs.length !== 0) {
      return buildModule.activeJobs[0];
    }
  }, [buildModule.activeJobs]);

  const { buildJob: activeJob } = useBuildJob(loadActiveBuildJob);

  React.useEffect(() => {
    if (buildModule.prevBuildJob) {
      setPrevJob(buildModule.prevBuildJob);
    } else {
      setPrevJob(null);
    }
  }, [buildModule]);

  const preforms = React.useMemo(() => {
    if (activeJob) {
      return activeJob.preforms || activeJob.components;
    } else if (prevJob) {
      return prevJob.preforms || prevJob.components;
    }

    return [];
  }, [activeJob, prevJob]);

  const cardJob = React.useMemo(
    () => activeJob || prevJob,
    [activeJob, prevJob],
  );

  return (
    <Paper
      sx={{
        width: {
          xs: '100%',
        },
        minHeight: {
          xs: 280,
          md: 283,
          lg: 300,
        },
        p: 2,
        display: 'flex',
        cursor: 'pointer',
      }}
      className={classNames({
        [classes.container]: true,
        [classes.selected]: selected,
      })}
      onClick={(e) => {
        if (onClick) {
          onClick(e, buildModule);
        } else {
          addPageToPageHistory({
            id: `BUILD_MODULE:${buildModule.id}`,
            url: ROUTES.BUILD_MODULE_TAB(buildModule.id, 'temperature'),
            label: `${buildModule.name || 'Build Module'} | Temperature`,
          });
          history.push({
            pathname: ROUTES.BUILD_MODULE(buildModule.id),
            state: { from: location.pathname },
          });
        }
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Box maxWidth={130}>
            <img src={BuildModuleIcon} alt="" width="100%" />
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h2" sx={{ mb: 1 }}>
                {buildModule.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <BuildModuleState buildModule={buildModule} />
            </Grid>
          </Grid>
        </Grid>

        {cardJob && (
          <>
            <Grid item xs={6} key={'build-job'}>
              <TitanInfoItem
                label={'Build Job'}
                chipInfo={`${activeJob ? 'active' : 'previous'}`}
              >
                <BuildJobLink buildJob={cardJob} />

                <List
                  sx={{
                    maxHeight: showAllPreforms
                      ? `${preforms.length * 25}px`
                      : '50px',
                    overflow: 'hidden',
                    transition: '0.5s ease-out',
                  }}
                >
                  {preforms.length !== 0
                    ? preforms.map((preform) => (
                        <PreformLink preform={preform} key={preform.id} />
                      ))
                    : ''}
                </List>
                {preforms.length > 2 ? (
                  <Button
                    sx={{ width: '100%', padding: '0 20px', marginTop: '5px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowAllPreforms((prev) => !prev);
                    }}
                  >
                    {' '}
                    {showAllPreforms ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </Button>
                ) : (
                  ''
                )}
              </TitanInfoItem>
            </Grid>
            <Grid item xs={6} key={'build-job-status'} sx={{ minWidth: '50%' }}>
              {cardJob && (
                <TitanInfoItem label={'Job Status'}>
                  <ComponentStatus component={cardJob} />
                </TitanInfoItem>
              )}

              {cardJob && (
                <TitanInfoItem
                  label={
                    cardJob.status === COMPONENT_STATUSES.SCHEDULED
                      ? 'Created'
                      : [
                          COMPONENT_STATUSES.LOAD_TO_BM,
                          COMPONENT_STATUSES.LOADED_TO_BM,
                          COMPONENT_STATUSES.UNLOAD_FROM_BM,
                          COMPONENT_STATUSES.PRE_BUILDING,
                        ].includes(cardJob.status)
                      ? 'Updated'
                      : cardJob.endBuildTime
                      ? 'Finished'
                      : 'Started'
                  }
                >
                  <TitanTimeAgo
                    time={
                      cardJob.status === COMPONENT_STATUSES.SCHEDULED
                        ? cardJob.createdAt
                        : [
                            COMPONENT_STATUSES.LOAD_TO_BM,
                            COMPONENT_STATUSES.LOADED_TO_BM,
                            COMPONENT_STATUSES.UNLOAD_FROM_BM,
                            COMPONENT_STATUSES.PRE_BUILDING,
                          ].includes(cardJob.status)
                        ? cardJob.statusUpdatedAt
                        : cardJob.endBuildTime
                        ? cardJob.endBuildTime
                        : cardJob.startBuildTime
                    }
                    emptyValue="-"
                  />
                </TitanInfoItem>
              )}
            </Grid>
          </>
        )}

        {buildModule.state &&
          [
            COMPONENT_STATUSES.BUILDING,
            COMPONENT_STATUSES.BUILD_PAUSING,
            COMPONENT_STATUSES.BUILD_PAUSED,
          ].includes(buildModule.state.state) && (
            <Grid item xs={12}>
              <LinearProgress
                sx={{ width: '100%', backgroundColor: colors.GREY2 }}
                variant="determinate"
                value={buildModule.progress}
              />
            </Grid>
          )}
      </Grid>
    </Paper>
  );
}
