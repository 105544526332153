import axios from 'axios';

export default class DeviceLogService {
  static getDeviceLogs(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/device-logs`, {
        ...config,
        params
      })
      .then(res => res.data);
  }
}
