import React from 'react';
import useAsyncEffect from 'use-async-effect';
import { format, parseISO, sub, eachDayOfInterval, isSameDay } from 'date-fns';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Paper,
  Stack,
  Chip,
  CircularProgress,
  Typography,
} from '@mui/material';
import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  VerticalBarSeries,
  DiscreteColorLegend,
} from 'react-vis';
import PrinterService from '../../../services/PrinterService';
import { colors } from '../../Theme/vars';
import { COMPONENT_STATUSES } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(4),
    height: 700,
  },
  chartWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(3),
    height: 600,
  },
}));

const DATE_RANGES = {
  LAST_MONTH: 'Last Month',
};

const ONE_DAY = 86400000;

function BuildModulePreformsChart({ buildModule }) {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(true);

  const [builtPreformsSeries, setBuiltPreformsSeries] = React.useState([]);
  const [canceledPreformsSeries, setCanceledPreformsSeries] = React.useState(
    [],
  );
  const [defectedPreformsSeries, setDefectedPreformsSeries] = React.useState(
    [],
  );

  const [maxYValue, setMaxYValue] = React.useState(0);

  const [selectedDateRange, setSelectedDateRange] = React.useState(
    DATE_RANGES.LAST_MONTH,
  );

  const loadPreformsData = React.useCallback(async () => {
    let fromDate;

    switch (selectedDateRange) {
      case DATE_RANGES.LAST_MONTH:
        fromDate = format(sub(Date.now(), { months: 1 }), 'T');
        break;
      default:
        fromDate = format(sub(Date.now(), { months: 1 }), 'T');
        break;
    }

    const eachDaySeries = eachDayOfInterval({
      start: +fromDate,
      end: Date.now(),
    }).map((day) => ({ x: new Date(day).getTime(), y: 0 }));

    const builtPreformsSeries = [...eachDaySeries];
    const canceledPreformsSeries = [...eachDaySeries];
    const defectedPreformsSeries = [...eachDaySeries];

    const { analytics } = await PrinterService.getPrinterPreformsAnalytics(
      buildModule.id,
      {
        fromDate,
      },
    );

    const fetchedPreformSeries = analytics.map((entry) => ({
      x: entry.day,
      y: parseInt(entry.preforms_count, 10),
      status: entry.status,
    }));

    const insertSeriesEntry = (entry) => {
      let series;

      switch (entry.status) {
        case COMPONENT_STATUSES.BUILT:
          series = builtPreformsSeries;
          break;
        case COMPONENT_STATUSES.BUILD_CANCELED:
          series = canceledPreformsSeries;
          break;
        case COMPONENT_STATUSES.DEFECTED:
          series = defectedPreformsSeries;
          break;
        default:
          return;
      }

      const index = series.findIndex((oldEntry) =>
        isSameDay(new Date(oldEntry.x), parseISO(entry.x)),
      );

      if (index >= 0) {
        series.splice(index, 1, {
          x: new Date(entry.x).getTime(),
          y: entry.y,
        });
      }
    };

    fetchedPreformSeries.forEach(insertSeriesEntry);

    setMaxYValue(Math.max(...fetchedPreformSeries.map((entry) => entry.y)));

    setBuiltPreformsSeries(builtPreformsSeries);
    setCanceledPreformsSeries(canceledPreformsSeries);
    setDefectedPreformsSeries(defectedPreformsSeries);
  }, [buildModule, selectedDateRange]);

  useAsyncEffect(async () => {
    setLoading(true);

    await loadPreformsData();

    setLoading(false);
  }, []);

  return (
    <Paper className={classes.container}>
      <Box display="flex" flexDirection="column">
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h1">Preforms Statistic</Typography>
          <Stack direction="row" spacing={2}>
            {Object.values(DATE_RANGES).map((dateRange) => (
              <Chip
                key={dateRange}
                label={dateRange}
                onClick={() => setSelectedDateRange(dateRange)}
                color={selectedDateRange === dateRange ? 'primary' : undefined}
              />
            ))}
          </Stack>
        </Stack>
        <Box className={classes.chartWrapper}>
          {loading ? (
            <Box sx={{ margin: 'auto' }}>
              <CircularProgress />
            </Box>
          ) : (
            <FlexibleXYPlot
              xType="time"
              xDomain={[Date.now() - 30 * ONE_DAY, Date.now()]}
              yDomain={[0, maxYValue + 2]}
            >
              <XAxis
                title="Time"
                tickFormat={(t, i) =>
                  t > new Date() ? (
                    <tspan />
                  ) : (
                    <tspan>{format(t, 'LLL d')}</tspan>
                  )
                }
              />
              <YAxis title="Amount" tickTotal={maxYValue + 2} />
              <HorizontalGridLines tickTotal={maxYValue + 2} />
              <VerticalGridLines />
              {builtPreformsSeries && (
                <VerticalBarSeries
                  data={builtPreformsSeries}
                  barWidth={0.7}
                  color={colors.LIME_GREEN}
                />
              )}
              {canceledPreformsSeries && (
                <VerticalBarSeries
                  data={canceledPreformsSeries}
                  barWidth={0.7}
                  color={colors.RED2}
                />
              )}
              {defectedPreformsSeries && (
                <VerticalBarSeries
                  data={defectedPreformsSeries}
                  barWidth={0.7}
                  color={colors.DARK_RED}
                />
              )}
              <DiscreteColorLegend
                style={{ position: 'absolute', left: '80px', top: '10px' }}
                orientation="horizontal"
                items={[
                  {
                    title: 'BUILT',
                    color: colors.LIME_GREEN,
                  },
                  {
                    title: 'CANCELLED',
                    color: colors.RED2,
                  },
                  {
                    title: 'SCRAPED',
                    color: colors.DARK_RED,
                  },
                ]}
              />
            </FlexibleXYPlot>
          )}
        </Box>
      </Box>
    </Paper>
  );
}

export default BuildModulePreformsChart;
