import axios from 'axios';

export default class PrinterService {
  static async getTelemetry(printerId, params = {}) {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/printers/${printerId}/telemetry`,
        { params }
      )
      .then(res => res.data.data);
  }

  static getPrinters(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/printers`, {
        ...config,
        params
      })
      .then(res => res.data);
  }

  static getPrinter(printerId, params = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/printers/${printerId}`, {
        params
      })
      .then(res => res.data.data);
  }

  static getPrinterStates(printerId, params = {}) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/printers/${printerId}/states`,
        {
          params
        }
      )
      .then(res => res.data);
  }

  static updatePrinterState(printerId, timestamp, data) {
    return axios
      .patch(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/printers/${printerId}/states/${timestamp}`,
        data
      )
      .then(res => res.data);
  }

  static getPrinterStatesAnalytics(printerId, params = {}) {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/printers/${printerId}/analytics/states`,
        {
          params
        }
      )
      .then(res => res.data);
  }

  static getPrinterPreformsAnalytics(printerId, params = {}) {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/printers/${printerId}/analytics/preforms`,
        {
          params
        }
      )
      .then(res => res.data);
  }

  static createPrinter(data) {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/printers`, data)
      .then(res => res.data.data);
  }

  static updatePrinter(printerId, data) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v1/printers/${printerId}`,
        data
      )
      .then(res => res.data.data);
  }

  static updatePrinterNotes(printerId, data) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v1/printers/${printerId}/notes`,
        data
      )
      .then(res => res.data.data);
  }

  static deletePrinter(printerId) {
    return axios
      .delete(`${process.env.REACT_APP_API_URL}/api/v1/printers/${printerId}`)
      .then(res => res.data.data);
  }

  static createNewAccessKey(printerId) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/printers/${printerId}/create-new-access-key`,
        {}
      )
      .then(res => res.data);
  }

  static startMaintenance(printerId, data = {}) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/printers/${printerId}/maintenance/start`,
        data
      )
      .then(res => res.data);
  }

  static finishMaintenance(printerId, data = {}) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/printers/${printerId}/maintenance/finish`,
        data
      )
      .then(res => res.data);
  }

  static setTargetTemperature(printerId, data = {}) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/printers/${printerId}/control/target-temperature`,
        data
      )
      .then(res => res.data);
  }

  static extrude(printerId, data = {}) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/printers/${printerId}/control/extrude`,
        data
      )
      .then(res => res.data);
  }

  static printSpeed(printerId, data = {}) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/printers/${printerId}/control/print-speed`,
        data
      )
      .then(res => res.data);
  }
}
