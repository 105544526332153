import _ from 'lodash';
import Typography from '@mui/material/Typography';
import React from 'react';

export default function TitanRated({ status, emptyValue = '' }) {
  if (!status) {
    return emptyValue;
  }

  return (
    <Typography>
      {_.upperFirst(status.replaceAll('_', ' ').toLowerCase())}
    </Typography>
  );
}
