import React from 'react';
import FilterChips from '../FilterChips/FilterChips';

export default function BuildJobsChips({ showAllJobs, onChangeShowAllJobs }) {
  const items = React.useMemo(() => {
    const items = [];

    items.push({
      label: 'All jobs',
      onClick: () => onChangeShowAllJobs(true),
      isHighlighted: showAllJobs
    });

    items.push({
      label: 'Active Jobs',
      onClick: () => onChangeShowAllJobs(false),
      isHighlighted: !showAllJobs
    });

    return items;
  }, [showAllJobs]);

  return <FilterChips items={items} />;
}
