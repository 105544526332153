import axios from 'axios';

export default class OrganizationService {
  static getOrganizations(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/organizations`, {
        ...config,
        params
      })
      .then(res => res.data);
  }

  static getOrganizationById(id, params = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/organizations/${id}`, {
        params
      })
      .then(res => res.data.data);
  }

  static createOrganization(data) {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/organizations`, data)
      .then(res => res.data.data);
  }

  static updateOrganization(id, data) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v1/organizations/${id}`,
        data
      )
      .then(res => res.data.data);
  }

  static deleteOrganization(id) {
    return axios
      .delete(`${process.env.REACT_APP_API_URL}/api/v1/organizations/${id}`)
      .then(res => res.data);
  }

  static getOrganizationRoleRestrictions(organizationId, config = {}) {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/organizations/${organizationId}/roles/available`,
        config
      )
      .then(res => res.data);
  }

  static updateOrganizationRoleRestriction(organizationId, data) {
    return axios
      .put(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/organizations/${organizationId}/roles`,
        data
      )
      .then(res => res.data.data);
  }
}
