import Stack from '@mui/material/Stack';
import FibrifyMeasurement from '../components/Fibrify/FibrifyMeasurement';
import { Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { colors } from '../components/Theme/vars';

export default class CustomFieldService {
  static showCustomFieldWarning(customField, value) {
    const { minValue, maxValue, expectedValue } = customField;

    switch (customField.type) {
      case 'NUMBER':
        return (
          value &&
          (minValue || maxValue) &&
          (value < minValue || value > maxValue)
        );
      case 'BOOLEAN':
      case 'STRING':
        return value && expectedValue && value !== expectedValue;
      case 'SINGLE_SELECT':
        return value && expectedValue && value !== expectedValue;
      case 'MULTI_SELECT':
        if (!value || !expectedValue) {
          break;
        }

        const multiValues = value.split(',').map((i) => i.trim());

        const expectedMultiValues = expectedValue
          .split(',')
          .map((i) => i.trim());

        return (
          multiValues.length !== expectedMultiValues.length ||
          !expectedMultiValues.every((i) => multiValues.includes(i))
        );
      default:
        return false;
    }
  }

  static prepareCustomFieldFormHelpText(customField, showWarning = false) {
    const { expectedValue, minValue, maxValue, type } = customField;
    const customFieldUnits = customField.units
      ? customField.units.toLowerCase()
      : '';

    if (expectedValue) {
      return (
        <Stack direction="row" alignItems="center">
          {showWarning && <WarningIcon color="warning" sx={{ mr: 1 }} />}
          <FibrifyMeasurement
            label="Expected"
            value={
              type === 'BOOLEAN'
                ? expectedValue === 'true'
                  ? 'Yes'
                  : 'No'
                : expectedValue
            }
            units={customFieldUnits}
          />
          {minValue || maxValue ? (
            <Typography
              variant="caption"
              component="span"
              sx={{ ml: 1 }}
              color={showWarning ? colors.ORANGE2 : ''}
            >
              (Min: {minValue || '-'} Max: {maxValue || '-'})
            </Typography>
          ) : (
            ''
          )}
        </Stack>
      );
    }

    return '';
  }
}
