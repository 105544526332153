import React from 'react';
import { makeStyles } from '@mui/styles';
import { colors } from '../Theme/vars';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TitanPopover from '../Titan/TitanPopover';
import TitanDuration from '../Titan/TitanDuration';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Rating from '@mui/material/Rating';
import TitanRated from '../Titan/TitanRated';

const useStyles = makeStyles(theme => ({
  container: {
    width: 250
  },
  label: {
    marginLeft: theme.spacing(1),
    color: colors.GREY
  }
}));

export default function ComponentStatusInfo({ component }) {
  const classes = useStyles();

  return (
    <TitanPopover icon={InfoOutlinedIcon}>
      <List className={classes.container}>
        {component.rate && (
          <ListItem>
            <ListItemText primary="Rated" />
            <ListItemSecondaryAction>
              <TitanRated status={component.rate} />
            </ListItemSecondaryAction>
          </ListItem>
        )}
        {component.buildTime && (
          <ListItem>
            <ListItemText primary="Build time" />
            <ListItemSecondaryAction>
              <TitanDuration duration={component.buildTime} />
            </ListItemSecondaryAction>
          </ListItem>
        )}
        {component.quality !== null ? (
          <ListItem>
            <ListItemText primary="Quality" />
            <ListItemSecondaryAction>
              <Rating name="read-only" value={component.quality} readOnly />
            </ListItemSecondaryAction>
          </ListItem>
        ) : (
          ''
        )}
      </List>
    </TitanPopover>
  );
}
