import React, { useMemo } from 'react';
import nanoid from 'nanoid';
import FileService from '../../services/FileService';
import FibrifyPhotosGallery from '../FibrifyPhotosGallery/FibrifyPhotosGallery';
import BuildJobService from '../../services/BuildJobService';
import { useTitan } from '../Titan/Titan';
import ComponentService from '../../services/ComponentService';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import PreformService from '../../services/PreformService';

export default function BuildJobPhotos({ buildJob, setBuildJob }) {
  const { pushSnackbar } = useTitan();
  const { id: buildJobId } = buildJob;
  const [withPreformsPhotos, setWithPreformsPhotos] = React.useState(false);
  const [preformsPhotos, setPreformPhotos] = React.useState([]);

  React.useEffect(() => {
    const loadData = async () => {
      const preforms = (await PreformService.getPreforms({
        buildJobId,
        withRelated: ['photos']
      })).data;

      const sortedPreformsPhotos = preforms
        .sort((a, b) =>
          a.preformType && a.preformType
            ? a.preformType.order - b.preformType.order
            : 0
        )
        .reduce((accumulator, current) => {
          const sortedCurrentPreformPhotos = current.photos.sort(
            (a, b) => a.order - b.order
          );
          return [...accumulator, ...sortedCurrentPreformPhotos];
        }, []);

      if (sortedPreformsPhotos.length !== 0) {
        setPreformPhotos(sortedPreformsPhotos);
      }
    };

    if (withPreformsPhotos) {
      loadData();
    } else {
      setPreformPhotos([]);
    }
  }, [withPreformsPhotos]);

  const buildJobPhotos = useMemo(() => {
    const mainPhotos = [...buildJob.photos].sort((a, b) => a.order - b.order);
    return [...mainPhotos, ...preformsPhotos];
  }, [buildJob, preformsPhotos]);

  const isPreformPhoto = photoId =>
    !!preformsPhotos.find(photo => photo.id === photoId);

  return (
    <FibrifyPhotosGallery
      photos={buildJobPhotos}
      hideUploadButtons={buildJob.buildPlan.deletedAt}
      onAddNewPhotos={async photos => {
        const newBuildJobPhotos = await Promise.all(
          photos.map(async (photo, order) => {
            const name = `${nanoid()}.png`;
            const initialOrder =
              buildJobPhotos[buildJobPhotos.length - 1]?.order + 1 || 0;

            const { location } = await FileService.uploadBase64File(
              name,
              photo
            );

            return BuildJobService.addPhoto(buildJob.id, {
              name,
              location,
              order: initialOrder + order
            });
          })
        );

        setBuildJob(prevState => ({
          ...prevState,
          photos: [...prevState.photos, ...newBuildJobPhotos]
        }));
      }}
      onDeletePhoto={async photoId => {
        if (isPreformPhoto(photoId)) {
          await ComponentService.deletePhoto(photoId);
          setPreformPhotos(prevState =>
            prevState.filter(currentPhoto => currentPhoto.id !== photoId)
          );
        } else {
          await BuildJobService.deletePhoto(photoId);

          setBuildJob(prevState => ({
            ...prevState,
            photos: buildJobPhotos.filter(
              currentPhoto => currentPhoto.id !== photoId
            )
          }));
        }
      }}
      onUpdatePhoto={async (photoId, updatedPhoto) => {
        if (isPreformPhoto(photoId)) {
          await ComponentService.updatePhoto(photoId, updatedPhoto);

          setPreformPhotos(prevState =>
            prevState.map(photo => {
              return photo.id === photoId
                ? { ...photo, ...updatedPhoto }
                : photo;
            })
          );
        } else {
          await BuildJobService.updatePhoto(photoId, updatedPhoto);

          setBuildJob(prevState => ({
            ...prevState,
            photos: buildJobPhotos.map(photo => {
              return photo.id === photoId
                ? { ...photo, ...updatedPhoto }
                : photo;
            })
          }));
        }

        pushSnackbar(`Comment to photo has been successfully updated`, {
          variant: 'success'
        });
      }}
      photoGaleryFiltersContent={
        <FormControlLabel
          control={
            <Switch
              checked={withPreformsPhotos}
              onChange={() => setWithPreformsPhotos(prevState => !prevState)}
              color="primary"
            />
          }
          label="Preform photos"
        />
      }
    />
  );
}
