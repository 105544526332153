import { FormControl, TextField } from '@mui/material';
import React from 'react';

export const StringCell = ({
  row,
  componentTypes,
  customFieldData,
  customField,
  isEditable,
  updateCustomField,
  prepareComponentToUpdate,
  updateComponents,
}) => {
  return (
    <FormControl sx={{ width: '25ch', height: '56px' }}>
      <TextField
        variant="standard"
        margin="normal"
        sx={{ height: '48px' }}
        name={componentTypes?.id}
        disabled={!isEditable(row)}
        value={customFieldData?.value || ''}
        onBlur={(e) => {
          if (e.target.value) {
            if (customFieldData) {
              updateCustomField(row, {
                ...customFieldData,
                value: e.target.value,
              });
            }
          }
        }}
        onChange={(e) => {
          const componentToUpdate = prepareComponentToUpdate(
            'STRING',
            row,
            customField.name,
            e.target.value,
          );

          updateComponents([componentToUpdate]);
        }}
      />
    </FormControl>
  );
};
