import axios from 'axios';
import { COMPONENT_STATUSES } from '../constants';

export default class ComponentService {
  static setStatus(component, status) {
    const data = {
      status
    };

    if (component.status) {
      data.prevStatus = component.status;
    }

    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v1/components/${component.id}`,
        data
      )
      .then(res => res.data.data);
  }

  static markScrapped(
    component,
    scrappedStatus = COMPONENT_STATUSES.SCRAPPED,
    trackToGA = true,
    detachAssembly = true
  ) {
    const data = {
      status: scrappedStatus,
      prevStatus: component.status
    };

    if (detachAssembly) {
      data.assemblyId = null;
    }

    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v1/components/${component.id}`,
        data
      )
      .then(res => res.data.data);
  }

  static async deleteComponent(componentId) {
    return axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/v1/components/${componentId}`
      )
      .then(res => res.data.data);
  }

  static async updateComponent(componentId, data) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v1/components/${componentId}`,
        data
      )
      .then(res => res.data.data);
  }

  static async markAsDefected(preformId, data) {
    return axios
      .patch(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/preforms/${preformId}/mark-as-defected`,
        data
      )
      .then(res => res.data);
  }

  static async setAsBuilt(preformId, data) {
    return axios
      .patch(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/preforms/${preformId}/set-as-built`,
        data
      )
      .then(res => res.data);
  }

  static attachToAssembly(component, assembly) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v1/components/${component.id}`,
        {
          assemblyId: assembly.id
        }
      )
      .then(res => res.data.data);
  }
  static detachFromAssembly(component) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v1/components/${component.id}`,
        {
          assemblyId: null
        }
      )
      .then(res => res.data.data);
  }
  static getComponent(componentId, params) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/components/${componentId}`,
        { params }
      )
      .then(res => res.data.data);
  }

  static getPreforms(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v2/preforms`, {
        ...config,
        params
      })
      .then(res => res.data);
  }

  static async addPhoto(componentId, data, version = 'v2') {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/preforms/${componentId}/photos`,
        data
      )
      .then(res => res.data.data);
  }

  static async updatePhoto(photoId, data) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v2/preforms/photos/${photoId}`,
        data
      )
      .then(res => res.data.data);
  }

  static async deletePhoto(photoId, version = 'v2') {
    return axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/preforms/photos/${photoId}`
      )
      .then(res => res.data.data);
  }
}
