import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { makeStyles } from '@mui/styles';
import { colors } from '../Theme/vars';
import cx from 'classnames';
import nanoid from 'nanoid';

const useStyles = makeStyles(theme => ({
  link: {
    '&:hover': {
      textDecoration: 'none'
    }
  },
  disabled: {
    cursor: 'auto'
  },
  item: {
    color: colors.BLACK
  },
  lastItem: {
    color: colors.GREY
  }
}));

export default function Breadcrumbs({ crumbs }) {
  const classes = useStyles();
  const location = useLocation();

  return (
    <MuiBreadcrumbs
      separator={<ArrowRightIcon fontSize="small" />}
      sx={{
        mb: 1
      }}
    >
      {crumbs.map(({ path, name, disabled, onClick }, index) =>
        disabled ? (
          <Typography
            key={nanoid()}
            className={`${classes.item} ${
              index === crumbs.length - 1 ? classes.lastItem : ''
            }`}
            variant="overline"
          >
            {name}
          </Typography>
        ) : (
          <Link
            key={nanoid()}
            className={cx({
              [classes.link]: true,
              [classes.disabled]: disabled
            })}
            to={{ pathname: path || '#', state: { from: location.pathname } }}
            component={RouterLink}
            disabled={disabled}
            onClick={() => {
              if (onClick) {
                onClick();
              }
            }}
          >
            <Typography
              className={`${classes.item} ${
                index === crumbs.length - 1 ? classes.lastItem : ''
              }`}
              variant="overline"
            >
              {name}
            </Typography>
          </Link>
        )
      )}
    </MuiBreadcrumbs>
  );
}
