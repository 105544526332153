import axios from 'axios';
import { ROLES } from '../constants';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Icon from '@mui/material/Icon';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShareIcon from '../assets/icons/share-secondary.svg';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

export default class BuildPlanService {
  static async getBuildPlans(params = {}, version = 'v1', config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/${version}/build-plans`, {
        ...config,
        params
      })
      .then(res => res.data);
  }

  static async getBuildPlan(buildPlanId, params = {}, version = 'v1') {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/build-plans/${buildPlanId}`,
        { params }
      )
      .then(res => res.data.data);
  }

  static async createBuildPlan(data, version = 'v1') {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/${version}/build-plans`, data)
      .then(res => {
        const buildPlan = res.data.data;

        return buildPlan;
      });
  }

  static async updateBuildPlan(buildPlanId, data, version = 'v1') {
    return axios
      .patch(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/build-plans/${buildPlanId}`,
        data
      )
      .then(res => res.data.data);
  }

  static async deleteBuildPlan(buildPlanId, version = 'v1') {
    return axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/build-plans/${buildPlanId}`
      )
      .then(res => res.data.data);
  }

  static getDefaultSettings() {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/build-plans/settings/default`
      )
      .then(res => res.data.data);
  }

  static getVersions(buildPlanId, params = {}, version = 'v1', config = {}) {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/build-plans/${buildPlanId}/versions`,
        {
          ...config,
          params
        }
      )
      .then(res => res.data.data);
  }

  static getPreforms(buildPlanId, params = {}, version = 'v1') {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/build-plans/${buildPlanId}/preforms`,
        {
          params
        }
      )
      .then(res => res.data.data);
  }

  static publishVersion(buildPlanId, comment, version = 'v1') {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/build-plans/${buildPlanId}/publish`,
        {
          comment
        }
      )
      .then(res => res.data.data);
  }

  static duplicateBuildPlan(buildPlanId, data, version = 'v1') {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/build-plans/${buildPlanId}/duplicate`,
        data
      )
      .then(res => res.data.data);
  }

  static startBuild(buildPlanId, data, version = 'v1') {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/build-plans/${buildPlanId}/build`,
        data
      )
      .then(res => res.data.data);
  }

  static restoreVersion(buildPlanId, version = 'v1') {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/build-plans/${buildPlanId}/restore`
      )
      .then(res => res.data.data);
  }

  static async getBuildPlanSharedMembers(
    buildPlanId,
    params = {},
    version = 'v2'
  ) {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/build-plans/${buildPlanId}/share/members`,
        { params }
      )
      .then(res => res.data.data);
  }

  static share(buildPlanId, data, version = 'v2') {
    return axios
      .put(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/build-plans/${buildPlanId}/share`,
        data
      )
      .then(res => res.data.data);
  }

  static addToFavorite(buildPlanId, data = {}, version = 'v2') {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/build-plans/${buildPlanId}/favorite`,
        data
      )
      .then(res => res.data.data);
  }

  static removeFromFavorite(buildPlanId, data = {}, version = 'v2') {
    return axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/api/${version}/build-plans/${buildPlanId}/favorite`,
        data
      )
      .then(res => res.data.data);
  }

  static getBuildPlanActions(
    buildPlan,
    currentMemberId,
    userRoles,
    {
      onRename,
      onShare,
      onDelete,
      onToggleFavorite,
      onAssignTo,
      onRemoveFromProject,
      onRemoveFromManufacturingOrder
    }
  ) {
    const isUserOwner = buildPlan.memberId === currentMemberId;
    const isUserAdmin = !!userRoles.find(r => r.id === ROLES.ADMIN);
    const isUserApplicationEngineer = !!userRoles.find(
      r => r.id === ROLES.APPLICATION_ENGINEER
    );

    const enableSharing =
      !buildPlan.deletedAt &&
      isUserOwner &&
      !!userRoles.find(r =>
        [ROLES.ADMIN, ROLES.APPLICATION_ENGINEER].includes(r.id)
      ) &&
      !buildPlan.approvedVersion;

    const enableAssigning = isUserAdmin || isUserOwner;

    const ToggleFavorite = async () => {
      const updatedBuildPlan = await (buildPlan.isFavorite
        ? BuildPlanService.removeFromFavorite(buildPlan.id)
        : BuildPlanService.addToFavorite(buildPlan.id));

      onToggleFavorite({
        ...buildPlan,
        isFavorite: updatedBuildPlan.isFavorite
      });
    };

    const actions = [
      {
        label: 'Rename',
        icon: <EditIcon />,
        onClick: onRename,
        disabled: !isUserOwner
      },
      {
        label: 'Delete',
        icon: <DeleteIcon />,
        onClick: onDelete,
        disabled: !isUserOwner
      },
      {
        label: 'Share',
        icon: (
          <Icon>
            <img
              style={{
                width: '100%',
                height: '100%'
              }}
              src={ShareIcon}
              alt=""
            />
          </Icon>
        ),
        onClick: onShare,
        disabled: !enableSharing
      },
      {
        label: 'Assign to',
        icon: <AssignmentIndIcon />,
        onClick: onAssignTo,
        disabled: !enableAssigning
      },
      {
        label: '',
        onClick: ToggleFavorite,
        icon: buildPlan.isFavorite ? (
          <FavoriteIcon color="primary" />
        ) : (
          <FavoriteBorderIcon color="primary" />
        )
      }
    ];

    if (buildPlan.projectId && onRemoveFromProject) {
      actions.push({
        label: 'Remove from Project',
        icon: <RemoveCircleIcon />,
        onClick: onRemoveFromProject,
        disabled: !(isUserOwner && isUserApplicationEngineer) && !isUserAdmin
      });
    }

    if (onRemoveFromManufacturingOrder) {
      actions.push({
        label: 'from Manufacturing Order',
        icon: <RemoveCircleIcon />,
        onClick: onRemoveFromManufacturingOrder,
        disabled: !(isUserOwner && isUserApplicationEngineer) && !isUserAdmin
      });
    }

    return actions;
  }

  static async planKeyExists(buildPlanId, planKey) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v2/build-plans/plan-key-exists`,
        {
          params: {
            planKey,
            buildPlanId
          }
        }
      )
      .then(res => res.data.planKeyExists);
  }
}
