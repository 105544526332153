import React from 'react';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import PreformService from '../../services/PreformService';
import { useTitan } from '../Titan/Titan';
import PreformTypeDialog from '../PreformType/PreformTypeDialog';
import { useBuildPlan } from './BuildPlanPage';
import Paper from '@mui/material/Paper';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import BuildPlanSummary from './BuildPlanSummary';
import { useAuth0 } from '../Authentication/Auth0';
import { Tooltip } from '@mui/material';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import { useResponsiveQueries } from '../../hooks/useResponsiveQueries';
import { setColumnsVisibilityModel } from '../../utils/setColumnsVisibilityModel';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { CustomGridColumnsPanel } from '../TitanDataGrid/TitanDataGridColumnsPanel';

import defaultPreformTypeCustomFields from '../../constants/defaultPreformTypeCustomFields';

export default function BuildPlanConfigurationTab() {
  const { buildPlan, updateBuildPlan, hasPreforms } = useBuildPlan();

  const { currentMemberId } = useAuth0();
  const isOwner = buildPlan?.memberId === currentMemberId;

  const { matchesLG } = useResponsiveQueries();

  const apiRef = useGridApiRef();

  React.useEffect(() => {
    const columnsVisibility = setColumnsVisibilityModel(
      columns,
      'iPadVisible',
      matchesLG,
    );
    apiRef.current.setColumnVisibilityModel(columnsVisibility);
  }, [apiRef, matchesLG]);

  const { pushSnackbar } = useTitan();

  const [openPreformDialog, setOpenPreformDialog] = React.useState(false);
  const [editablePreformType, setEditablePreformType] = React.useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [preformTypes, setPreformTypes] = React.useState([]);

  React.useEffect(() => {
    setPreformTypes(
      buildPlan && buildPlan.preformTypes
        ? [...buildPlan.preformTypes].sort((a, b) => a.order - b.order)
        : [],
    );
  }, [buildPlan]);

  const onDeletePreformType = async () => {
    await updateBuildPlan({
      preformTypes: buildPlan.preformTypes.filter(
        (pt) => pt.id !== editablePreformType.id,
      ),
    });

    pushSnackbar('Preform type successfully deleted', { variant: 'success' });
    setOpenDeleteDialog(false);
  };

  const onSavePreformType = React.useCallback(
    async (preformType) => {
      let updatedPreformTypes;

      if (!editablePreformType.id) {
        updatedPreformTypes = [
          ...preformTypes,
          { ...preformType, order: preformTypes.length },
        ];
      } else {
        updatedPreformTypes = buildPlan.preformTypes.map((pt) => {
          if (pt.id === editablePreformType.id) {
            return {
              ...editablePreformType,
              ...preformType,
            };
          }

          return pt;
        });
      }

      await updateBuildPlan({
        preformTypes: updatedPreformTypes,
      });

      pushSnackbar('Preform type successfully saved', { variant: 'success' });
      setOpenPreformDialog(false);
    },
    [buildPlan, editablePreformType, preformTypes],
  );

  let onCreateClickDisabledTooltip;

  if (!isOwner) {
    onCreateClickDisabledTooltip = `Only Build Plan owner can add new preform types`;
  }

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        iPadVisible: true,
        hideable: false,
        minWidth: 300,
        flex: 1,
        sortable: false,
      },
      {
        headerName: 'Labels',
        field: 'labels',
        iPadVisible: true,
        minWidth: 200,
        flex: 1,
        sortable: false,
      },
      {
        headerName: 'Custom fields',
        field: 'customFields',
        iPadVisible: false,
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) =>
          row.customFields
            .sort((a, b) => a.order - b.order)
            .map((cf) => cf.name)
            .join(', '),
      },
      {
        field: 'actions',
        type: 'actions',
        hideInMenu: true,
        hideable: false,
        iPadVisible: true,
        width: 60,
        sortable: false,
        getActions: (params) =>
          PreformService.getPreformTypesActions(
            () => {
              setEditablePreformType(params.row);
              setOpenPreformDialog(true);
            },
            () => {
              setEditablePreformType(params.row);
              setOpenDeleteDialog(true);
            },
            hasPreforms,
            isOwner,
          ).map((action) =>
            action.disabled ? (
              <Tooltip
                title={action.tooltipText}
                showInMenu
                placement="left-start"
              >
                <span>
                  <GridActionsCellItem
                    icon={action.icon}
                    label={action.label}
                    onClick={action.onClick}
                    showInMenu
                    disabled={action.disabled}
                  />
                </span>
              </Tooltip>
            ) : (
              <GridActionsCellItem
                icon={action.icon}
                label={action.label}
                onClick={action.onClick}
                showInMenu
              />
            ),
          ),
      },
    ],
    [hasPreforms],
  );

  const onChangePreformTypeOrder = React.useCallback(
    async ({ oldIndex, targetIndex }) => {
      const orderedPreformTypes = [...preformTypes];
      const row = orderedPreformTypes.splice(oldIndex, 1)[0];
      orderedPreformTypes.splice(targetIndex, 0, row);

      await updateBuildPlan({
        preformTypes: orderedPreformTypes.map((preformType, order) => ({
          ...preformType,
          order,
        })),
      });
    },
    [preformTypes, updateBuildPlan],
  );

  return (
    <>
      <Paper
        sx={{
          padding: 2,
          marginBottom: 2,
        }}
      >
        <BuildPlanSummary />
      </Paper>

      <TitanDataGrid
        apiRef={apiRef}
        components={{
          Toolbar: TitanDataGridToolbar,
          ColumnsPanel: CustomGridColumnsPanel,
        }}
        title="Preform Types"
        rows={preformTypes}
        onCreateClick={() => {
          setEditablePreformType({
            customFields: defaultPreformTypeCustomFields,
          });
          setOpenPreformDialog(true);
        }}
        onCreateClickDisabled={!isOwner}
        onCreateClickDisabledTooltip={onCreateClickDisabledTooltip}
        createButtonLabel="Preform Type"
        columns={columns}
        initialState={{
          columns: {
            columnVisibilityModel: {
              ...setColumnsVisibilityModel(columns, 'iPadVisible', matchesLG),
            },
          },
        }}
        pagination={false}
        showSearch={false}
        rowCount={preformTypes.length}
        rowReordering
        onRowOrderChange={onChangePreformTypeOrder}
      />

      {openPreformDialog && (
        <PreformTypeDialog
          preformType={editablePreformType}
          onClose={() => {
            setOpenPreformDialog(false);
          }}
          onSave={onSavePreformType}
          hasPreforms={hasPreforms}
        />
      )}

      {openDeleteDialog && (
        <TitanConfirmationDialog
          title="Delete Preform Type?"
          message={`Are you sure you want to delete Preform Type: ${editablePreformType.name}?`}
          onClose={() => setOpenDeleteDialog(false)}
          onConfirm={() => onDeletePreformType()}
        />
      )}
    </>
  );
}
