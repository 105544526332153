import React from 'react';
import MaterialLink from '../Materials/MaterialLink';
import Rating from '@mui/material/Rating';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import SpoolLink from '../Spools/SpoolLink';

export default function BuildJobSpoolsTable({ buildJob }) {
  const columns = React.useMemo(
    () => [
      {
        headerName: 'ID',
        field: 'spoolIdentifier',
        hideable: false,
        visibilityBreakpoint: 'sm',
        flex: 1,
        minWidth: 200,
        sortable: false,
        renderCell: ({ row, colDef }) => (
          <SpoolLink spool={row} width={colDef.computedWidth} />
        )
      },
      {
        headerName: 'Material',
        field: 'materialType',
        visibilityBreakpoint: 'sm',
        flex: 1,
        minWidth: 200,
        sortable: false,
        renderCell: ({ row, colDef }) => (
          <MaterialLink
            materialType={row.materialType}
            width={colDef.computedWidth}
          />
        )
      },
      {
        headerName: 'Original Weight (g)',
        field: 'originalWeight',
        visibilityBreakpoint: 'lg',
        minWidth: 180,
        sortable: false
      },
      {
        headerName: 'Current Weight (g)',
        field: 'currentWeight',
        visibilityBreakpoint: 'lg',
        minWidth: 180,
        sortable: false
      },
      {
        headerName: 'Quality',
        field: 'quality',
        minWidth: 180,
        visibilityBreakpoint: 'lg',
        sortable: false,
        renderCell: ({ row }) => <Rating value={row.quality} readOnly />
      },
      {
        headerName: 'Comment',
        field: 'comment',
        visibilityBreakpoint: 'lg',
        flex: 1,
        sortable: false
      },
      {
        headerName: 'Type',
        field: 'type',
        visibilityBreakpoint: 'lg',
        width: 130,
        sortable: false
      }
    ],
    []
  );

  const titanDataGridProps = useTitanDataGrid(
    React.useCallback(async () => {
      return {
        data: buildJob && buildJob.spools ? buildJob.spools : [],
        page: 0,
        totalCount: buildJob && buildJob.spools ? buildJob.spools.length : 0
      };
    }, [buildJob]),
    { columns, pinnedColumns: { left: ['spoolIdentifier'], right: [] } }
  );

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        components={{
          Toolbar: TitanDataGridToolbar
        }}
        showSearch={false}
        pagination={false}
      />
    </>
  );
}
