import React from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import FormControlLabel from '@mui/material/FormControlLabel';
import TitanPopover from '../Titan/TitanPopover';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

export default function FinalPartsTableFilter({
  fusionPlanVersionType,
  onChangeFusionPlanVersionType
}) {
  return (
    <TitanPopover icon={FilterListIcon}>
      <Box p={2} width={200}>
        <Typography variant="subtitle1">Fusion Plan version:</Typography>
        <FormControl component="fieldset">
          <RadioGroup
            value={fusionPlanVersionType}
            onChange={e => onChangeFusionPlanVersionType(e.target.value)}
          >
            <FormControlLabel
              value="current"
              control={<Radio />}
              label="Current version"
            />
            <FormControlLabel
              value="all"
              control={<Radio />}
              label="All versions"
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </TitanPopover>
  );
}
