import React from 'react';
import FilterChips from '../FilterChips/FilterChips';

export default function FusionJobsChips({
  showAll,
  setShowAll,
  isVersionChips,
  isActivityChips,
  chosenFusionModules,
  setChosenFusionModules
}) {
  const items = React.useMemo(() => {
    const items = [];

    if (isVersionChips) {
      items.push({
        label: 'All Versions',
        onClick: () => setShowAll(true),
        isHighlighted: showAll
      });

      items.push({
        label: 'Current Versions',
        onClick: () => setShowAll(false),
        isHighlighted: !showAll
      });
    }
    if (isActivityChips) {
      items.push({
        label: 'All Jobs',
        onClick: () => setShowAll(true),
        isHighlighted: showAll
      });

      items.push({
        label: 'Active Jobs',
        onClick: () => setShowAll(false),
        isHighlighted: !showAll
      });
    }

    chosenFusionModules &&
      chosenFusionModules.forEach(moduleInstance => {
        items.push({
          label: `Modules: ${moduleInstance.name}`,
          onDelete: () => {
            const remainingSelectedItems = chosenFusionModules.filter(
              m => m.id !== moduleInstance.id
            );
            setChosenFusionModules(
              remainingSelectedItems.length > 0 ? remainingSelectedItems : []
            );
          },
          isHighlighted: true
        });
      });

    return items;
  }, [showAll, chosenFusionModules]);

  return <FilterChips items={items} />;
}
