import React from 'react';

import { useTitan } from '../Titan/Titan';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';

import { BUILD_PLAN_SHARE_TYPES, ROLES } from '../../constants';
import { Typography } from '@mui/material';
import MemberService from '../../services/MemberService';
import { useAuth0 } from '../Authentication/Auth0';
import BuildPlanService from '../../services/BuildPlanService';
import Box from '@mui/material/Box';
import TitanDialog from '../Titan/TitanDialog';
import TitanTabs from '../Titan/TitanTabs';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';

export default function BuildPlanShareDialog(props) {
  const { buildPlan, onClose, onSave } = props;

  const [selectedMembers, setSelectedMembers] = React.useState([]);
  const { pushSnackbar } = useTitan();
  const { profile } = useAuth0();

  const [shareType, setShareType] = React.useState(buildPlan.shareType);

  const onSubmitForm = React.useCallback(() => {
    return BuildPlanService.share(buildPlan.id, {
      shareType,
      memberIds: selectedMembers.map(member => member.id)
    }).then(() => {
      let message = '';

      switch (shareType) {
        case BUILD_PLAN_SHARE_TYPES.PRIVATE:
          message = 'Build Plan is shared only for you';
          break;
        case BUILD_PLAN_SHARE_TYPES.ORGANIZATION:
          message = 'Build Plan is shared to all members of our organization';
          break;
        case BUILD_PLAN_SHARE_TYPES.MEMBERS:
          message = 'Build Plan is share to all selected members';
          break;
        default:
          break;
      }

      pushSnackbar(message, {
        variant: 'success'
      });

      onSave({
        ...buildPlan,
        shareType
      });
    });
  }, [shareType, selectedMembers]);

  const onSelectMembers = React.useCallback(
    selectedMembers => {
      setSelectedMembers(selectedMembers);
    },
    [setSelectedMembers]
  );

  const loadData = React.useCallback(
    async (query, config) => {
      const params = {
        ...query,
        organizationId: profile.organizationId,
        withRelated: ['roles'],
        roles: [ROLES.APPLICATION_ENGINEER, ROLES.OPERATOR]
      };

      const { data, pagination } = await MemberService.loadOrganizationMembers(
        params,
        config
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount
      };
    },
    [shareType]
  );

  const titanDataGridProps = useTitanDataGrid(loadData);

  React.useEffect(() => {
    if (shareType === BUILD_PLAN_SHARE_TYPES.MEMBERS) {
      BuildPlanService.getBuildPlanSharedMembers(buildPlan.id).then(
        checkedMembers => setSelectedMembers(checkedMembers)
      );
    } else {
      setSelectedMembers([]);
    }
  }, [shareType]);

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        minWidth: 300,
        sortable: false
      },
      {
        headerName: 'Roles',
        field: 'roles',
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => row.roles.map(r => r.name).join(', ')
      }
    ],
    []
  );

  const tabs = React.useMemo(
    () => [
      {
        value: BUILD_PLAN_SHARE_TYPES.PRIVATE,
        label: 'Private',
        content: (
          <Typography variant="body1">
            Only you as Build Plan owner will see this plan
          </Typography>
        )
      },
      {
        value: BUILD_PLAN_SHARE_TYPES.ORGANIZATION,
        label: 'Share to Organization',
        content: (
          <Typography variant="body1">
            Visible for all members of your organization
          </Typography>
        )
      },
      {
        value: BUILD_PLAN_SHARE_TYPES.MEMBERS,
        label: 'Share to Members',
        content: (
          <>
            <Typography variant="body1">
              Visible for all members selected from list
            </Typography>

            <Box mt={2}>
              <TitanDataGrid
                {...titanDataGridProps}
                title=""
                searchPlaceholder="Search members by name or email"
                columns={columns}
                checkboxMultiSelection
                selectedRows={selectedMembers}
                onSelectionModelChange={onSelectMembers}
              />
            </Box>
          </>
        )
      }
    ],
    [selectedMembers, onSelectMembers, columns, titanDataGridProps]
  );

  return (
    <TitanDialog
      open={true}
      onClose={onClose}
      onSave={onSubmitForm}
      maxWidth="md"
      fullWidth
      title={`Share Build Plan: ${buildPlan.name}`}
      isValid={
        !(
          shareType === BUILD_PLAN_SHARE_TYPES.MEMBERS &&
          selectedMembers.length === 0
        )
      }
    >
      <TitanTabs
        onChangeTab={shareType => {
          setShareType(shareType);
        }}
        activeTab={shareType}
        tabs={tabs}
      />
    </TitanDialog>
  );
}
