import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Link, Typography, Stack } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import BuildModuleIcon from '../../assets/icons/build-module-small.svg';
import FusionModuleIcon from '../../assets/icons/fusion-module-small.svg';
import LibraryIcon from '../../assets/icons/library.svg';
import OrganizationProfileIcon from '../../assets/icons/organization-profile.svg';
import FibrifyDesignSuiteIcon from '../../assets/icons/fibrify-design-suite.svg';
import DashboardIcon from '../../assets/icons/dashboard-icon.svg';
import FilePackagesIcon from '../../assets/icons/file-packages-icon.svg';
import AssemblyIcon from '../../assets/icons/assembly-icon.svg';
import DeviceLogsIcon from '../../assets/icons/device-logs-icon.svg';
import ComponentIcon from '../../assets/icons/component-icon.svg';
import SettingsIcon from '@mui/icons-material/Settings';
import TermsAndConditionsIcon from '../../assets/icons/terms-and-conditions-icon.svg';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CategoryIcon from '@mui/icons-material/Category';

import ClickAwayListener from '@mui/material/ClickAwayListener';

import React from 'react';
import OverflowTip from '../ExpandRenderCell/OverflowTip';
import { useTitan } from './Titan';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

export default function TitanRecentPages({ recentPages }) {
  const [isOpenRecentPages, setIsOpenRecentPages] = React.useState(false);
  const history = useHistory();
  const { addPageToPageHistory } = useTitan();
  const ListItems = recentPages
    .slice(-10)
    .reverse()
    .map(rP => {
      let icon;
      if (rP.id.startsWith('FUSION_PLAN')) {
        icon = <img src={LibraryIcon} width={28} alt="" />;
      } else if (rP.id.startsWith('BUILD_PLAN')) {
        icon = <img src={FilePackagesIcon} width={28} alt="" />;
      } else if (rP.id.startsWith('BUILD_MODULE')) {
        icon = <img src={BuildModuleIcon} width={28} alt="Build Modules" />;
      } else if (rP.id.startsWith('FUSION_MODULE')) {
        icon = <img src={FusionModuleIcon} width={28} alt="Fusion Modules" />;
      } else if (rP.id.startsWith('PROJECT')) {
        icon = <AccountTreeIcon sx={{ fill: '#000' }} />;
      } else if (rP.id.startsWith('MANUFACTURING_ORDER')) {
        icon = <CategoryIcon sx={{ fill: '#000' }} />;
      } else if (rP.id.startsWith('HISTORY')) {
        icon = <img src={DeviceLogsIcon} width={28} alt="" />;
      } else if (rP.id.startsWith('DASHBOARD')) {
        icon = <img src={DashboardIcon} width={28} alt="" />;
      } else if (rP.id.startsWith('GEAR')) {
        icon = <SettingsIcon sx={{ fill: '#000', width: '28px' }} />;
      } else if (rP.id.startsWith('PRESET')) {
        icon = <SettingsIcon sx={{ fill: '#000', width: '28px' }} />;
      } else if (rP.id.startsWith('ORGANIZATION')) {
        icon = <img src={OrganizationProfileIcon} width={28} alt="" />;
      } else if (rP.id.startsWith('FDS')) {
        icon = (
          <img src={FibrifyDesignSuiteIcon} width={28} alt="fDS Releases" />
        );
      } else if (rP.id.startsWith('TERMS')) {
        icon = <img src={TermsAndConditionsIcon} width={28} alt="" />;
      } else if (rP.id.startsWith('ADMIN')) {
        icon = <img src={OrganizationProfileIcon} width={28} alt="" />;
      } else if (rP.id.startsWith('FINAL') || rP.id.startsWith('FUSION_JOB')) {
        icon = <img src={AssemblyIcon} width={28} alt="" />;
      } else if (rP.id.startsWith('PREFORM') || rP.id.startsWith('BUILD_JOB')) {
        icon = <img src={ComponentIcon} width={28} alt="" />;
      }

      return (
        <ListItem
          sx={{
            pl: 1,
            maxWidth: 500
          }}
          button
          component={Link}
          onClick={() => {
            addPageToPageHistory({
              id: rP.id,
              url: rP.url,
              label: rP.label,
              addInfo: rP.addInfo
            });
            history.push({
              pathname: rP.url,
              state: { from: history.location.pathname }
            });
          }}
          key={rP.id}
        >
          <ListItemIcon sx={{ minWidth: '42px' }}>{icon}</ListItemIcon>
          <Stack
            sx={{ maxWidth: 420, display: 'flex', flexDirection: 'column' }}
          >
            <OverflowTip width={380}>
              <Typography sx={{ color: '#000000de' }}>{rP.label}</Typography>
            </OverflowTip>
            {rP.label.startsWith('Build Job') && rP?.addInfo?.buildPlan && (
              <OverflowTip width={350}>
                <Typography
                  component="div"
                  sx={{ fontSize: '12px', color: '#999' }}
                >
                  {rP.addInfo.buildPlan.name}
                </Typography>
              </OverflowTip>
            )}
            {rP.label.startsWith('Fusion Job') && rP?.addInfo?.fusionPlan && (
              <OverflowTip width={350}>
                <Typography
                  component="div"
                  sx={{ fontSize: '12px', color: '#999' }}
                >
                  {rP.addInfo.fusionPlan.name}
                </Typography>
              </OverflowTip>
            )}
          </Stack>
        </ListItem>
      );
    });

  const handleClick = () => {
    setIsOpenRecentPages(prev => !prev);
  };
  const handleClose = event => {
    event.stopPropagation();
    setIsOpenRecentPages(false);
  };
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <List
        sx={{
          padding: '8px 0 2px 0',
          backgroundColor: '#000',
          position: 'absolute',
          top: 0,
          left: 250
        }}
      >
        <ListItemButton
          onClick={handleClick}
          sx={{ justifyContent: 'flex-start', ml: 1.5, paddingBottom: '18px' }}
        >
          <AccessTimeFilledIcon fontSize="small" sx={{ mr: 1.5 }} />
          <ListItemText primary="Recent Pages" sx={{ flex: '0 0 auto' }} />
          {isOpenRecentPages ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse
          onClick={handleClick}
          in={isOpenRecentPages}
          timeout="auto"
          unmountOnExit
        >
          <List
            component="div"
            sx={{ backgroundColor: '#FFF', marginTop: '2px' }}
          >
            {recentPages && ListItems}
          </List>
        </Collapse>
      </List>
    </ClickAwayListener>
  );
}
