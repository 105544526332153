import React from 'react';
import { styled } from '@mui/material/styles';

import TitanDialog from '../Titan/TitanDialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ImageIcon from '@mui/icons-material/Image';

import PreBuildingChecklistService from '../../services/PreBuildingChecklistService';
import FileService from '../../services/FileService';

const StepImage = styled('img')({
  display: 'block',
  width: '50%',
  marginTop: '10px'
});

export default function PreBuildingChecklistStepDialog(props) {
  const { mode, checklistId, section, step, order, onClose, onSave } = props;

  const [description, setDescription] = React.useState(step.description || '');
  const [image, setImage] = React.useState();

  const [imageUploading, setImageUploading] = React.useState(false);
  const [displayImage, setDisplayImage] = React.useState(step.imageUrl || null);

  const imageInput = React.useRef();

  const onImageUpload = async e => {
    if (e.target.files.length === 0) {
      return;
    }

    setImageUploading(true);
    const file = e.target.files[0];

    const fileData = await FileService.uploadFile(file.name, file);
    const base64File = await FileService.fileToBase64(file);

    setImageUploading(false);
    setDisplayImage(base64File);
    setImage(fileData);
  };

  const handleLogoClear = () => {
    setImage(null);
    setDisplayImage(null);
    imageInput.current.value = '';
  };

  const onSubmitForm = () => {
    if (mode === 'create') {
      return PreBuildingChecklistService.createChecklistSectionStep(
        { description, order, ...(image && { image }) },
        { checklistId, sectionId: section.id }
      ).then(data => {
        onSave(data);
      });
    } else {
      return PreBuildingChecklistService.updateChecklistSectionStep(step.id, {
        description,
        image
      }).then(data => {
        onSave(data);
      });
    }
  };

  const isValid = description.length !== 0;

  return (
    <TitanDialog
      title={`${mode === 'create' ? 'Create' : 'Edit'} checklist's step`}
      onClose={onClose}
      onSave={onSubmitForm}
      isValid={isValid}
      successSaveMessage={`Checklist's step successfully ${
        mode === 'create' ? 'created' : 'updated'
      }`}
    >
      <TextField
        label="Description"
        value={description}
        multiline
        maxRows={5}
        fullWidth
        margin="normal"
        onChange={e => setDescription(e.target.value)}
      />
      <Button variant="contained" component="label" startIcon={<ImageIcon />}>
        Upload New Image
        <input
          hidden
          type="file"
          accept="image/*"
          ref={imageInput}
          onChange={onImageUpload}
        />
      </Button>
      {displayImage && (
        <Button variant="outlined" onClick={handleLogoClear} sx={{ ml: 2 }}>
          Clear Image
        </Button>
      )}
      {imageUploading ? (
        <CircularProgress sx={{ display: 'block', mt: 2 }} />
      ) : (
        displayImage && <StepImage src={displayImage} alt="" />
      )}
    </TitanDialog>
  );
}
