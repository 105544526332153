import { Typography } from '@mui/material';
import React from 'react';
import { FUSION_PLAN_PARAMS_CONFIG } from '../../constants';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

export default function FusionComparePlansTable({
  category,
  fusionPlans,
  hideSame,
  deleteFusionPlanFromComparsion
}) {
  const [params, setParams] = React.useState([]);

  React.useEffect(() => {
    const rows = FUSION_PLAN_PARAMS_CONFIG[category].map((p, i) => {
      const valuesRows = fusionPlans.reduce((accum, current) => {
        accum[current.id] = current.configuration[p.key];
        return accum;
      }, {});

      const isTheSameValues = Object.values(valuesRows).every(
        (value, i, array) => value === array[0]
      );
      return {
        id: i,
        key: p.label,
        ...valuesRows,
        hideableRow: isTheSameValues
      };
    });

    const filteredRows = rows.filter(row => !row.hideableRow);

    // Push params that not defined in FUSION_PLAN_PARAMS_CONFIG to "Other" category
    if (category === 'Other') {
      let allParams = [];
      const initialIndex = rows.length;

      for (const [, params] of Object.entries(FUSION_PLAN_PARAMS_CONFIG)) {
        allParams = [...allParams, ...params];
      }

      const configurations = Object.keys(
        ...fusionPlans.map(plan =>
          plan.configuration?.configuration
            ? plan.configuration.configuration
            : plan.configuration
        )
      ).reduce((accum, current) => {
        if (!accum.includes(current)) {
          accum.push(current);
        }
        return accum;
      }, []);

      const notDefinedParams = configurations.filter(p => {
        return !allParams.find(param => param.key === p);
      });

      const notDefinedParamsRows = notDefinedParams.map((param, index) => {
        const valuesRows = fusionPlans.reduce((accum, current) => {
          accum[current.id] = current.configuration[param];
          return accum;
        }, {});

        const isTheSameValues = Object.values(valuesRows).every(
          (value, i, array) => value === array[0]
        );

        return {
          id: initialIndex + index,
          key: param,
          ...valuesRows,
          hideableRow: isTheSameValues
        };
      });

      const filteredNotDefinedRowsParams = notDefinedParamsRows.filter(
        row => !row.hideableRow
      );
      if (hideSame) {
        setParams([...filteredRows, ...filteredNotDefinedRowsParams]);
      } else {
        setParams([...rows, ...notDefinedParamsRows]);
      }
    } else {
      if (hideSame) {
        setParams([...filteredRows]);
      } else {
        setParams([...rows]);
      }
    }
  }, [category, hideSame]);

  const columns = React.useMemo(() => {
    const columns = [
      {
        headerName: 'Key',
        field: 'key',
        flex: 1,
        sortable: false
      }
    ];

    return columns;
  }, []);

  const additionalColumns = React.useMemo(() => {
    const columns = [];
    fusionPlans.forEach(fP => {
      columns.push({
        field: fP.id,
        flex: 1,
        sortable: false,
        renderHeader: params => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>
              {fP.name} ({fP.version})
            </Typography>
            <IconButton
              onClick={() => deleteFusionPlanFromComparsion(params.field)}
            >
              <ClearIcon />
            </IconButton>
          </Box>
        )
      });
    });
    return columns;
  }, [fusionPlans]);

  return (
    <TitanDataGrid
      rows={params}
      rowCount={params.length}
      showSearch={false}
      columns={[...columns, ...additionalColumns]}
      hideFooter
    />
  );
}
