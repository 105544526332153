import React from 'react';
import TitanPage from '../Titan/TitanPage';
import ReactMarkdown from 'react-markdown';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import GetAppIcon from '@mui/icons-material/GetApp';
import FDSReleaseService from '../../services/FDSReleaseService';
import FDSReleasesFilter from './FDSReleasesFilter';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import { ROUTES } from '../../constants';
import TitanPageFilters from '../Titan/TitanPageFilters';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Paper } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import TablePagination from '@mui/material/TablePagination';
import { useParams } from 'react-router-dom';
import { useTitan } from '../Titan/Titan';

export default function FDSReleasesPage() {
  const [states, setStates] = React.useState(['Release']);

  const { addPageToPageHistory } = useTitan();

  const { page: pageParam = 0 } = useParams();

  const loadData = React.useCallback(
    async (query, config) => {
      const params = {
        ...query,
        states: states,
      };

      const { data, pagination } = await FDSReleaseService.getReleases(
        params,
        config,
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount,
      };
    },
    [states],
  );

  const gridOptions = {
    onChangePage: (page) => {
      const newRoute = ROUTES.FDS_RELEASES_PAGE(page);
      addPageToPageHistory({
        id: `FDS_RELEASES`,
        url: newRoute,
        label:
          page === 0 ? `fDS Releases` : `fDS Releases | Page : ${page + 1}`,
      });
    },
  };

  if (pageParam && Number(pageParam) > 0) {
    gridOptions.page = Number(pageParam);
  }

  const titanDataGridProps = useTitanDataGrid(loadData);

  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'fDS Releases',
        disabled: true,
      },
    ],
    [],
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Version',
        field: 'version',
        width: 150,
        sortable: false,
      },
      {
        headerName: 'State',
        field: 'state',
        width: 150,
        sortable: false,
      },
      {
        headerName: 'Description',
        field: 'description',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <ReactMarkdown>{row.description}</ReactMarkdown>
        ),
      },
      {
        headerName: 'Created',
        field: 'created',
        width: 200,
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />,
      },
      {
        headerName: '',
        field: 'actions',
        width: 60,
        sortable: false,
        renderCell: ({ row }) => (
          <IconButton
            aria-label="download"
            component={Link}
            href={row.downloadUrl}
            target="_blank"
          >
            <GetAppIcon />
          </IconButton>
        ),
      },
    ],
    [],
  );

  const {
    rows,
    rowCount,
    pageSize,
    page,
    setPageSize,
    setPage,
    search,
    setSearch,
  } = titanDataGridProps;

  return (
    <TitanPage breadcrumbs={breadcrumbs} title="fDS Releases">
      <TitanPageFilters
        showSearch
        search={search}
        searchPlaceholder="Search by version or description"
        onChangeSearch={(search) => setSearch(search)}
        filtersContent={
          <FDSReleasesFilter
            states={states}
            onChangeStates={(states) => setStates(states)}
          />
        }
      />

      <Paper sx={{ mt: 2 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.field} sx={{ width: column.width }}>
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  {columns.map((column) => (
                    <TableCell key={`${row.id}-${column.field}`}>
                      {column.renderCell
                        ? column.renderCell({ row })
                        : row[column.field]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={rowCount}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={(e, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => {
            setPageSize(parseInt(e.target.value, 10));
            setPage(0);
          }}
        />
      </Paper>
    </TitanPage>
  );
}
