import React from 'react';
import { Stack, Chip } from '@mui/material';
import nanoid from 'nanoid';

export default function BuildPlanTags({ buildPlan, width }) {
  const [tags, setTags] = React.useState([]);

  React.useEffect(() => {
    const tags = buildPlan.tags
      ? buildPlan.tags.map(tag => {
          if (typeof tag === 'object') {
            return tag;
          }

          return { id: nanoid(), name: tag };
        })
      : [];

    const quantity = calculateChipsQuantity(tags, width);
    const preparedTags = prepareTags(tags, quantity);

    setTags(preparedTags);
  }, [buildPlan.tags, width]);

  return (
    <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
      {tags.map(tag => (
        <Chip key={tag.id} label={tag.name} size="small" variant="outlined" />
      ))}
    </Stack>
  );
}

const calculateChipsQuantity = (tags, width) => {
  let quantity = 0;
  let symbolCounter = 0;

  tags.forEach(tag => {
    symbolCounter += tag.name ? tag.name.length : 0;

    if (symbolCounter * 14 < width) {
      quantity += 1;
    }
  });

  return quantity;
};

const prepareTags = (tags, tagsQuantity) =>
  tags
    .map((tag, index) => {
      if (tagsQuantity > index) {
        return tag;
      } else if (tagsQuantity === index) {
        return { ...tag, name: `+${tags.length - index}` };
      } else {
        return null;
      }
    })
    .filter(tag => (tag ? tag : null));
