import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import React from 'react';
import { useParams } from 'react-router-dom';
import BuildModuleService from '../../services/BuildModuleService';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';

import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import BuildModuleTemperatureDialog from './BuildModulePreHeatingTemperatureDialog';
import { Typography } from '@mui/material';
import BUILD_MODULE_HEATER_LABELS from '../../constants/buildModuleHeaterLabels';

export default function BuildModulePreHeatingTemperaturesTable({
  buildModuleId,
  setNewPageForTab
}) {
  const { page: pageParam = 0 } = useParams();

  const [temperatureToEdit, setTemperatureToEdit] = React.useState();
  const [temperatureToDelete, setTemperatureToDelete] = React.useState();

  const loadData = React.useCallback(
    async (query, config) => {
      const params = {
        buildModuleId,
        ...query
      };

      const {
        data,
        pagination
      } = await BuildModuleService.getBuildModulePreHeatingTemperatures(
        params,
        config
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount
      };
    },
    [buildModuleId]
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        hideable: false,
        visibilityBreakpoint: 'sm',
        minWidth: 300,
        sortable: false
      },
      {
        headerName: 'Temperatures',
        field: 'temperatures',
        hideable: false,
        visibilityBreakpoint: 'sm',
        flex: 1,
        minWidth: 170,
        sortable: false,
        renderCell: ({ row }) =>
          row.temperatures.map(({ key, temperature }) => (
            <Typography sx={{ mr: 1 }} component="span" key={key}>
              <Typography variant="caption" component="span">
                {BUILD_MODULE_HEATER_LABELS[key]
                  ? BUILD_MODULE_HEATER_LABELS[key]
                  : key}
                :
              </Typography>{' '}
              {temperature}
              <Typography variant="caption" component="span">
                °C
              </Typography>
            </Typography>
          ))
      },
      {
        field: 'actions',
        hideable: false,
        type: 'actions',
        hideInMenu: true,
        visibilityBreakpoint: 'sm',
        width: 60,
        getActions: ({ row }) => {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={() => setTemperatureToEdit(row)}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => setTemperatureToDelete(row)}
              showInMenu
            />
          ];
        }
      }
    ],
    []
  );

  const titanDataGridProps = useTitanDataGrid(loadData, { columns });

  const { page, reloadData } = titanDataGridProps;

  React.useEffect(() => {
    setNewPageForTab(
      'pre-heating-temperatures',
      'Pre-Heating Temperatures',
      page
    );
  }, [page]);

  const onSaveTemperature = React.useCallback(
    async temperature => {
      if (temperatureToEdit.id) {
        await BuildModuleService.updateBuildModulePreHeatingTemperature(
          temperatureToEdit.id,
          temperature
        );
      } else {
        await BuildModuleService.createBuildModulePreHeatingTemperature({
          ...temperature,
          buildModuleId
        });
      }

      await reloadData();
    },
    [temperatureToEdit]
  );

  const onDeleteTemperature = React.useCallback(async () => {
    await BuildModuleService.deleteBuildModulePreHeatingTemperature(
      temperatureToDelete.id
    );
    await reloadData();
  }, [temperatureToDelete]);

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        createButtonLabel="Add pre-heating temperatures set"
        onCreateClick={() => {
          setTemperatureToEdit({ name: '', temperatures: [] });
        }}
      />

      {temperatureToEdit && (
        <BuildModuleTemperatureDialog
          buildModuleId={buildModuleId}
          temperature={temperatureToEdit}
          onClose={() => {
            setTemperatureToEdit(null);
          }}
          onSave={onSaveTemperature}
        />
      )}

      {temperatureToDelete && (
        <TitanConfirmationDialog
          title="Delete Build Module Pre-Heating Temperature?"
          message={`Are you sure you want to delete this pre-heating temperatures set?`}
          onClose={() => setTemperatureToDelete(null)}
          onConfirm={onDeleteTemperature}
        />
      )}
    </>
  );
}
