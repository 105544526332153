import React, { useMemo } from 'react';

import nanoid from 'nanoid';
import FileService from '../../services/FileService';
import ComponentService from '../../services/ComponentService';
import FibrifyPhotosGallery from '../FibrifyPhotosGallery/FibrifyPhotosGallery';
import { useTitan } from '../Titan/Titan';

export default function PreformPhotos({ preform, setPreform }) {
  const preformPhotos = useMemo(() => {
    return [...preform.photos].sort((a, b) => a.order - b.order);
  }, [preform]);

  const { pushSnackbar } = useTitan();

  return (
    <FibrifyPhotosGallery
      photos={preformPhotos}
      hideUploadButtons={preform.buildPlan.deletedAt}
      onAddNewPhotos={async photos => {
        const newPreformPhotos = await Promise.all(
          photos.map(async (photo, order) => {
            const name = `${nanoid()}.png`;

            const initialOrder =
              preformPhotos[preformPhotos.length - 1]?.order + 1 || 0;

            const { location } = await FileService.uploadBase64File(
              name,
              photo
            );

            return ComponentService.addPhoto(preform.id, {
              name,
              location,
              order: order + initialOrder
            });
          })
        );

        setPreform(prevState => ({
          ...prevState,
          photos: [...prevState.photos, ...newPreformPhotos]
        }));
      }}
      onDeletePhoto={async photoId => {
        await ComponentService.deletePhoto(photoId);

        setPreform(prevState => ({
          ...prevState,
          photos: preformPhotos.filter(
            currentPhoto => currentPhoto.id !== photoId
          )
        }));
      }}
      onUpdatePhoto={async (photoId, updatedPhoto) => {
        await ComponentService.updatePhoto(photoId, updatedPhoto);

        setPreform(prevState => ({
          ...prevState,
          photos: preformPhotos.map(photo => {
            return photo.id === photoId ? { ...photo, ...updatedPhoto } : photo;
          })
        }));

        pushSnackbar(`Comment to photo has been successfully updated`, {
          variant: 'success'
        });
      }}
    />
  );
}
