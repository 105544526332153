import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import TitanInfoItem from '../Titan/TitanInfoItem';
import FusionModuleLink from '../FusionModule/FusionModuleLink';
import TitanDateTime from '../Titan/TitanDateTime';
import FusionPlanLink from '../FusionPlan/FusionPlanLink';
import Rating from '@mui/material/Rating';
import AssemblyService from '../../services/AssemblyService';

import EditableCustomField from '../CustomFields/EditableCustomField';
import TitanEditableText from '../Titan/TitanEditableText';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import FusionJobLink from '../FusionJob/FusionJobLink';
import { COMPONENT_STATUSES } from '../../constants';
import Paper from '@mui/material/Paper';
import ManufacturingOrderLink from '../ManufacturingOrders/ManufacturingOrderLink';
import FibrifyMeasurement from '../Fibrify/FibrifyMeasurement';
import Stack from '@mui/material/Stack';
import ComponentStatus from '../Component/ComponentStatus';
import PreformService from '../../services/PreformService';
import CustomFieldService from '../../services/CustomFieldService';
import TitanDuration from '../Titan/TitanDuration';

export default function FinalPartSummary({ finalPart, setFinalPart }) {
  const updateFinalPart = async (data) => {
    const updatedFinalPart = await AssemblyService.updateAssembly(
      finalPart.id,
      data,
    );

    setFinalPart({ ...finalPart, ...updatedFinalPart });
  };

  const preFusingFinalPartTypeCustomFields = React.useMemo(() => {
    if (finalPart?.finalPartType?.customFields) {
      const finalPartTypeCustomFields = [
        ...finalPart?.finalPartType?.customFields.filter(
          (cf) => cf.formType === 'PRE_FUSION',
        ),
      ];

      finalPartTypeCustomFields.sort((a, b) => a.order - b.order);

      return finalPartTypeCustomFields;
    }

    return [];
  }, [finalPart]);

  const postFusingFinalPartTypeCustomFields = React.useMemo(() => {
    if (finalPart?.finalPartType?.customFields) {
      const finalPartTypeCustomFields = [
        ...finalPart?.finalPartType?.customFields.filter(
          (cf) => cf.formType === 'POST_FUSION',
        ),
      ];

      finalPartTypeCustomFields.sort((a, b) => a.order - b.order);

      return finalPartTypeCustomFields;
    }

    return [];
  }, [finalPart]);

  const finalPartCustomFields = React.useMemo(
    () =>
      finalPart.customFields
        ? AssemblyService.prepareFinalPartCustomFields(finalPart)
        : [],
    [finalPart],
  );

  const preFusionWeight = React.useMemo(() => {
    if (
      !finalPart ||
      !finalPart.components ||
      finalPart.components.length !== 1 ||
      !finalPart.components[0].preformType ||
      !finalPart.components[0].preformType.customFields ||
      !finalPart.components[0].customFields
    ) {
      return null;
    }

    const weightPreformTypeCustomField =
      finalPart.components[0].preformType.customFields.find(
        (cf) => cf.name.toLowerCase() === 'weight',
      );

    if (!weightPreformTypeCustomField) {
      return null;
    }

    const weightPreformCustomField = finalPart.components[0].customFields.find(
      (cf) => cf.preformTypeCustomFieldId === weightPreformTypeCustomField.id,
    );

    return weightPreformCustomField
      ? parseInt(weightPreformCustomField.value, 10)
      : null;
  }, [finalPart]);

  const postFusionWeight = React.useMemo(() => {
    if (
      !finalPart ||
      !finalPart.finalPartType ||
      !finalPart.finalPartType.customFields
    ) {
      return null;
    }

    const weightCustomField = finalPart?.finalPartType?.customFields.find(
      (cf) => cf.name.toLowerCase() === 'weight',
    );

    if (!weightCustomField) {
      return null;
    }

    const weightPreformCustomField = finalPart?.customFields.find(
      (cf) => cf.finalPartTypeCustomFieldId === weightCustomField.id,
    );

    return weightPreformCustomField
      ? parseInt(weightPreformCustomField.value, 10)
      : null;
  }, [finalPart]);

  const prePostFusionWeightDifference = React.useMemo(() => {
    if (!preFusionWeight || !postFusionWeight) {
      return null;
    }

    return postFusionWeight - preFusionWeight;
  }, [preFusionWeight, postFusionWeight]);

  const enableEditingCustomFields = React.useMemo(
    () =>
      [
        COMPONENT_STATUSES.POST_FUSING,
        COMPONENT_STATUSES.FUSED,
        COMPONENT_STATUSES.FUSE_CANCELED,
        COMPONENT_STATUSES.POST_CANCELED,
        COMPONENT_STATUSES.ASSEMBLY_SCRAPPED,
        COMPONENT_STATUSES.DEFECTED,
      ].includes(finalPart.status),
    [finalPart],
  );

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TitanInfoItem label="Final Part ID">
            {finalPart.finalPartKey || finalPart.id}
          </TitanInfoItem>
        </Grid>
        <Grid item xs={3}>
          <TitanInfoItem label="Device">
            {finalPart.fusor ? (
              <FusionModuleLink fusionModule={finalPart.fusor} />
            ) : (
              '-'
            )}
          </TitanInfoItem>
        </Grid>
        <Grid item xs={3}>
          <TitanInfoItem label="Status">
            <ComponentStatus
              component={{
                status: finalPart.status,
                progress: finalPart.progress,
                showStatusWarning: finalPart.showStatusWarning,
              }}
              itemTypeName="final part"
            />
          </TitanInfoItem>
        </Grid>
        <Grid item xs={3}>
          <TitanInfoItem label="Quality">
            <Rating
              readOnly={finalPart.quality !== null}
              value={finalPart.quality}
              onChange={async (event, quality) => updateFinalPart({ quality })}
            />
          </TitanInfoItem>
        </Grid>
        <Grid item xs={3}>
          <TitanInfoItem label="Start">
            <TitanDateTime time={finalPart.startFuseTime} emptyValue="-" />
          </TitanInfoItem>
        </Grid>
        <Grid item xs={3}>
          <TitanInfoItem label="Completed">
            <TitanDateTime time={finalPart.endFuseTime} emptyValue="-" />
          </TitanInfoItem>
        </Grid>
        <Grid item xs={3}>
          <TitanInfoItem label="Fuse Time">
            <TitanDuration duration={finalPart.fuseTime} />
          </TitanInfoItem>
        </Grid>
        {finalPart.status === COMPONENT_STATUSES.SCHEDULED && (
          <Grid item xs={3}>
            <TitanInfoItem label="Scheduled">
              <TitanTimeAgo time={finalPart.fusionJob.createdAt} />
            </TitanInfoItem>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TitanInfoItem label="Mode">
            {finalPart.fusionJob.isAutomatic ? 'Automatic' : 'Semi-Automatic'}
          </TitanInfoItem>
        </Grid>
        <Grid item xs={3}>
          <TitanInfoItem label="External heating">
            {finalPart.fusionJob.isExternalHeating ? 'Yes' : 'No'}
          </TitanInfoItem>
        </Grid>
        <Grid item xs={3}>
          <TitanInfoItem label="Material setup">
            {finalPart.fusionJob.isMaterialSetup ? 'Yes' : 'No'}
          </TitanInfoItem>
        </Grid>
        <Grid item xs={3}>
          <TitanInfoItem label="Mould setup">
            {finalPart.fusionJob.isMouldSetup ? 'Yes' : 'No'}
          </TitanInfoItem>
        </Grid>
        <Grid item xs={3}>
          <TitanInfoItem label="Mould identifier">
            {finalPart.fusionJob.mould
              ? finalPart.fusionJob.mould.mouldIdentifier
              : '-'}
          </TitanInfoItem>
        </Grid>
        <Grid item xs={4}>
          <TitanInfoItem label="Manufacturing Order">
            {finalPart.manufacturingOrderId ? (
              <ManufacturingOrderLink
                manufacturingOrder={finalPart.manufacturingOrder}
              />
            ) : (
              '-'
            )}
          </TitanInfoItem>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitanInfoItem label="Comment">
            <TitanEditableText
              text={finalPart.comment}
              label="Comment"
              onChangeValue={(comment) => updateFinalPart({ comment })}
            />
          </TitanInfoItem>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TitanInfoItem label="Fusion Plan">
            <FusionPlanLink fusionPlan={finalPart.fusionPlan} />
          </TitanInfoItem>
        </Grid>
        <Grid item xs={3}>
          <TitanInfoItem label="Fusion Job">
            <FusionJobLink fusionJob={finalPart.fusionJob} />
          </TitanInfoItem>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      {preFusingFinalPartTypeCustomFields?.length !== 0 ? (
        <>
          <Typography variant="h3">Pre-fusion custom fields</Typography>
          <Grid container spacing={2}>
            {preFusingFinalPartTypeCustomFields.map(
              (finalPartTypeCustomField) => {
                const value = finalPartCustomFields[finalPartTypeCustomField.id]
                  ? finalPartCustomFields[finalPartTypeCustomField.id].value
                  : '';
                const showWarning = CustomFieldService.showCustomFieldWarning(
                  finalPartTypeCustomField,
                  value,
                );

                return (
                  <Grid item xs={4} key={finalPartTypeCustomField.id}>
                    <TitanInfoItem
                      label={finalPartTypeCustomField.name}
                      showWarning={showWarning}
                    >
                      <EditableCustomField
                        customField={finalPartTypeCustomField}
                        value={value}
                        onChange={(value) =>
                          updateFinalPart({
                            customFields: Object.values({
                              ...finalPartCustomFields,
                              [finalPartTypeCustomField.id]: {
                                ...finalPartCustomFields[
                                  finalPartTypeCustomField.id
                                ],
                                value: String(value),
                              },
                            }).filter(
                              (customField) =>
                                !(
                                  !customField.id &&
                                  customField.value.length === 0
                                ),
                            ),
                          })
                        }
                      />
                    </TitanInfoItem>
                  </Grid>
                );
              },
            )}
          </Grid>
        </>
      ) : (
        ''
      )}
      {postFusingFinalPartTypeCustomFields?.length !== 0 ? (
        <>
          <Typography variant="h3">Post-fusion custom fields</Typography>
          <Grid container spacing={2}>
            {postFusingFinalPartTypeCustomFields.map(
              (finalPartTypeCustomField) => {
                const value = finalPartCustomFields[finalPartTypeCustomField.id]
                  ? finalPartCustomFields[finalPartTypeCustomField.id].value
                  : '';
                const showWarning = CustomFieldService.showCustomFieldWarning(
                  finalPartTypeCustomField,
                  value,
                );

                return (
                  <Grid item xs={4} key={finalPartTypeCustomField.id}>
                    <TitanInfoItem
                      label={finalPartTypeCustomField.name}
                      showWarning={showWarning}
                    >
                      <EditableCustomField
                        customField={finalPartTypeCustomField}
                        enableEditing={enableEditingCustomFields}
                        value={value}
                        onChange={(value) =>
                          updateFinalPart({
                            customFields: Object.values({
                              ...finalPartCustomFields,
                              [finalPartTypeCustomField.id]: {
                                ...finalPartCustomFields[
                                  finalPartTypeCustomField.id
                                ],
                                value: String(value),
                              },
                            }),
                          })
                        }
                      />
                    </TitanInfoItem>
                  </Grid>
                );
              },
            )}
          </Grid>
        </>
      ) : (
        ''
      )}
      {prePostFusionWeightDifference ? (
        <>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TitanInfoItem label="Difference between weight pre/post-fusion">
                <Stack>
                  <FibrifyMeasurement
                    value={prePostFusionWeightDifference}
                    units="g"
                  />

                  <Stack sx={{ mt: 1 }}>
                    <FibrifyMeasurement
                      label="pre fusion"
                      value={preFusionWeight}
                      units="g"
                    />
                    <FibrifyMeasurement
                      label="post fusion"
                      value={postFusionWeight}
                      units="g"
                    />
                  </Stack>
                </Stack>
              </TitanInfoItem>
            </Grid>
          </Grid>
        </>
      ) : (
        ''
      )}
    </Paper>
  );
}
