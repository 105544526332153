import React from 'react';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import { CustomGridColumnsPanel } from '../TitanDataGrid/TitanDataGridColumnsPanel';
import renderCellExpand from '../ExpandRenderCell/ExpandRenderCell';

export default function PresetContent({
  parsedPreset,
  complexityFilter,
  page,
  pageSize,
  setPage,
  setPageSize
}) {
  const columns = React.useMemo(
    () => [
      {
        headerName: 'Key',
        field: 'key',
        pinnable: true,
        hideable: false,
        visibilityBreakpoint: 'sm',
        flex: 1,
        minWidth: 180,
        sortable: false,
        renderCell: ({ row, colDef }) =>
          renderCellExpand(row.key, colDef.computedWidth)
      },
      {
        headerName: 'Value',
        field: 'value',
        pinnable: true,
        hideable: false,
        visibilityBreakpoint: 'sm',
        flex: 1,
        minWidth: 180,
        sortable: false,
        renderCell: ({ row, colDef }) =>
          renderCellExpand(row.value, colDef.computedWidth)
      },
      {
        headerName: 'Description',
        field: 'description',
        pinnable: true,
        hideable: false,
        visibilityBreakpoint: 'sm',
        flex: 1,
        minWidth: 180,
        sortable: false,
        renderCell: ({ row, colDef }) =>
          renderCellExpand(row.description, colDef.computedWidth)
      }
    ],
    []
  );

  const loadData = React.useCallback(async () => {
    return {
      data: parsedPreset.filter(entry => entry.complexity === complexityFilter)
    };
  }, [parsedPreset, complexityFilter]);

  const titanDataGridProps = useTitanDataGrid(loadData, {
    columns,
    pinnedColumns: {
      left: ['key'],
      right: ['actions']
    }
  });

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        components={{
          Toolbar: TitanDataGridToolbar,
          ColumnsPanel: CustomGridColumnsPanel
        }}
        showSearch={false}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        paginationMode="client"
      />
    </>
  );
}
