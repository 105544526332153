import React from 'react';
import moment from 'moment';
import FusorService from '../../services/FusorService';
import { sensorTypeConfig } from './constants';
import useWebSocket from 'react-use-websocket';
import { WEB_SOCKET_ACTIONS } from '../../constants';
import { useTitan } from '../Titan/Titan';
import FusionJobService from '../../services/FusionJobService';

export default function useFusionModuleTelemetry(
  fusionModuleId,
  fusionJobId,
  from,
  to,
) {
  const { getWebSocketUrl, getWebSocketOptions } = useTitan();
  const [loading, setLoading] = React.useState(true);

  const [sensorData, setSensorData] = React.useState({});
  const [csvData, setCSVData] = React.useState([]);

  const { lastJsonMessage } = useWebSocket(
    getWebSocketUrl,
    getWebSocketOptions(),
  );

  React.useEffect(() => {
    if (
      lastJsonMessage !== null &&
      lastJsonMessage.action === WEB_SOCKET_ACTIONS.FUSION_MODULE_TELEMETRY &&
      lastJsonMessage.data.fusionModuleId === fusionModuleId &&
      (!fusionJobId || lastJsonMessage.data.fusionJobId === fusionJobId)
    ) {
      const telemetry = lastJsonMessage.data.data;
      const timestamp = lastJsonMessage.data.timestamp;

      setSensorData((sensorData) => {
        Object.keys(telemetry).forEach((sensorName) => {
          const sensorTypeKeys = Object.values(sensorTypeConfig)
            .filter((sensorType) => sensorType.isDataKey(sensorName))
            .map((sensorType) => sensorType?.dataKey);

          if (!sensorTypeKeys) {
            return;
          }

          sensorTypeKeys.forEach((sensorTypeKey) => {
            if (!sensorData[sensorTypeKey]) {
              sensorData[sensorTypeKey] = {};
            }

            if (!sensorData[sensorTypeKey][sensorName]) {
              sensorData[sensorTypeKey][sensorName] = {
                label: sensorName,
                data: [],
              };
            }

            sensorData[sensorTypeKey][sensorName].data.push({
              x: new Date(timestamp),
              y: telemetry[sensorName],
            });
          });
        });

        setCSVData((csvData) => {
          const csvDataRow = {
            timestamp: moment(timestamp).format('YYYY-MM-DD HH:mm:ss'),
          };

          Object.keys(telemetry).forEach((sensorName) => {
            csvDataRow[sensorName] = telemetry[sensorName];
          });

          return [...csvData, csvDataRow];
        });

        return {
          ...sensorData,
        };
      });
    }
  }, [lastJsonMessage]);

  const loadFusionModuleTelemetryData = async () => {
    let data;

    if (fusionJobId) {
      const fusionJobTelemetry = await FusionJobService.getFusionJobTelemetry(
        fusionJobId,
      );

      data = fusionJobTelemetry.telemetry;
    } else {
      const toTime = to ? moment(to) : moment();
      const fromTime = from ? moment(from) : moment(toTime).add(-60, 'minutes');

      const fusionModuleTelemetry =
        await FusorService.getFusionModuleTelemetryData(fusionModuleId, {
          from: fromTime.utc().toISOString(),
          to: toTime.utc().toISOString(),
        });

      data = fusionModuleTelemetry;
    }

    const sensorData = {};

    data.forEach((item) => {
      const csvDataRow = {
        timestamp: moment(item.timestamp).format('YYYY-MM-DD HH:mm:ss'),
      };

      Object.keys(item.data).forEach((sensorName) => {
        const sensorTypeKeys = Object.values(sensorTypeConfig)
          .filter((sensorType) => sensorType.isDataKey(sensorName))
          .map((sensorType) => sensorType?.dataKey);

        if (!sensorTypeKeys) {
          return;
        }

        sensorTypeKeys.forEach((sensorTypeKey) => {
          if (!sensorData[sensorTypeKey]) {
            sensorData[sensorTypeKey] = {};
          }

          if (!sensorData[sensorTypeKey][sensorName]) {
            sensorData[sensorTypeKey][sensorName] = {
              label: sensorName,
              data: [],
            };
          }

          sensorData[sensorTypeKey][sensorName].data.push({
            x: new Date(item.timestamp),
            y: item.data[sensorName],
          });

          csvDataRow[sensorName] = item.data[sensorName];
        });
      });

      setCSVData((csvData) => {
        return [...csvData, csvDataRow];
      });
    });

    setSensorData(sensorData);
    setLoading(false);
  };

  React.useEffect(() => {
    setLoading(true);

    loadFusionModuleTelemetryData();
  }, [fusionModuleId]);

  return {
    sensorData,
    csvData,
    loading,
  };
}
