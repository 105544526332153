import React from 'react';
import Grid from '@mui/material/Grid';
import OrganizationsManageList from '../Organization/OrganizationsManageList';
import PreBuildingChecklistsManageList from '../PreBuildingChecklists/PreBuildingChecklistsManageList';
import BuildModulesTable from '../DeviceList/BuildModulesTable';
import FusionModulesTable from '../DeviceList/FusionModulesTable';
import LabelPrintersTable from '../LabelPrinters/LabelPrintersTable';
import { PERMISSIONS } from '../../constants/auth0';
import FDSReleasesManageList from '../FDSReleases/FDSReleasesManageList';
import PrinterSeriesManageList from '../PrinterSeries/PrinterSeriesManageList';
import PrinterModelsManageList from '../PrinterModels/PrinterModelsManageList';
import TitanPage from '../Titan/TitanPage';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TitanTabPanel from '../Titan/TitanTabPanel';
import { useAuth0 } from '../Authentication/Auth0';
import TermsTable from '../Terms/TermsTable';
import { Link, useParams, useHistory } from 'react-router-dom';
import { ROUTES } from '../../constants';

export default function AdminDashboardPage() {
  const { can } = useAuth0();
  const { tabName } = useParams();

  const history = useHistory();

  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Admin dashboard',
        disabled: true
      }
    ],
    []
  );

  const [selectedTab, setSelectedTab] = React.useState(
    tabName ? tabName : 'organizations'
  );

  const pushHistory = React.useCallback((tabName, settedPage) => {
    history.push(ROUTES.ADMIN_DASHBOARD_TAB_PAGE(tabName, settedPage));
  }, []);

  const tabs = React.useMemo(
    () => [
      {
        permissions: [
          PERMISSIONS.CREATE_ORGANIZATION,
          PERMISSIONS.READ_ORGANIZATIONS,
          PERMISSIONS.UPDATE_ORGANIZATION,
          PERMISSIONS.DELETE_ORGANIZATION
        ],
        label: 'Organizations',
        value: 'organizations',
        renderPanel: () => <OrganizationsManageList pushHistory={pushHistory} />
      },
      {
        permissions: [PERMISSIONS.READ_BUILD_MODULE],
        label: 'Build Modules',
        value: 'build-modules',
        renderPanel: () => <BuildModulesTable pushHistory={pushHistory} />
      },
      {
        permissions: [PERMISSIONS.READ_FUSION_MODULE],
        label: 'Fusion Modules',
        value: 'fusion-modules',
        renderPanel: () => <FusionModulesTable pushHistory={pushHistory} />
      },
      {
        permissions: [
          PERMISSIONS.READ_PRE_BUILDING_CHECKLISTS,
          PERMISSIONS.CREATE_PRE_BUILDING_CHECKLIST,
          PERMISSIONS.UPDATE_PRE_BUILDING_CHECKLIST,
          PERMISSIONS.DELETE_PRE_BUILDING_CHECKLIST
        ],
        label: 'Pre Building Checklists',
        value: 'pre-building-checklists',
        renderPanel: () => (
          <PreBuildingChecklistsManageList pushHistory={pushHistory} />
        )
      },
      {
        permissions: [PERMISSIONS.READ_BUILD_MODULE],
        label: 'Label printers',
        value: 'label-printers',
        renderPanel: () => <LabelPrintersTable pushHistory={pushHistory} />
      },
      {
        permissions: [PERMISSIONS.MANAGE_PRINTER_SERIES],
        label: 'Printer Series',
        value: 'printer-series',
        renderPanel: () => <PrinterSeriesManageList pushHistory={pushHistory} />
      },
      {
        permissions: [PERMISSIONS.MANAGE_PRINTER_MODELS],
        label: 'Printer Models',
        value: 'printer-models',
        renderPanel: () => <PrinterModelsManageList pushHistory={pushHistory} />
      },
      {
        permissions: [PERMISSIONS.MANAGE_FDS_RELEASES],
        label: 'fDS Releases',
        value: 'fds-releases',
        renderPanel: () => <FDSReleasesManageList pushHistory={pushHistory} />
      },
      {
        permissions: [PERMISSIONS.MANAGE_TERMS_AND_CONDITIONS],
        label: 'Terms And Conditions',
        value: 'terms-and-conditions',
        renderPanel: () => <TermsTable pushHistory={pushHistory} />
      }
    ],
    []
  );

  return (
    <TitanPage title="Admin Dashboard" breadcrumbs={breadcrumbs}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Tabs
            orientation="vertical"
            value={selectedTab}
            onChange={(event, newTab) => {
              setSelectedTab(newTab);
            }}
            sx={{
              '& .MuiTabs-indicator': { left: 0, right: 'auto' },
              '& .MuiTab-root': { textAlign: 'left' }
            }}
          >
            {tabs.map(tab => {
              return can(tab.permissions) ? (
                <Tab
                  sx={{ alignItems: 'start' }}
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  component={Link}
                  to={ROUTES.ADMIN_DASHBOARD_TAB(tab.value)}
                />
              ) : (
                ''
              );
            })}
          </Tabs>
        </Grid>
        <Grid item xs={10}>
          {tabs.map(tab =>
            can(tab.permissions) ? (
              <TitanTabPanel
                key={tab.value}
                value={tab.value}
                index={selectedTab}
              >
                {tab.renderPanel()}
              </TitanTabPanel>
            ) : (
              ''
            )
          )}
        </Grid>
      </Grid>
    </TitanPage>
  );
}
