export default {
  'Heating parameter': [
    {
      key: 'iIndexComboHeatingCircuits',
      label: 'Number of attached heating circuits',
      dimension: ''
    },
    {
      key: 'fPreHeat1',
      label: 'Preheat temperature 1',
      dimension: '°C'
    },
    {
      key: 'fPreHeat2',
      label: 'Preheat temperature 2',
      dimension: '°C'
    },
    {
      key: 'fPreHeat3',
      label: 'Preheat temperature 3',
      dimension: '°C'
    },
    {
      key: 'fPreHeat4',
      label: 'Preheat temperature 4',
      dimension: '°C'
    },
    {
      key: 'fPreHeat5',
      label: 'Preheat temperature 5',
      dimension: '°C'
    },
    {
      key: 'fPreHeat6',
      label: 'Preheat temperature 6',
      dimension: '°C'
    },
    {
      key: 'fPreHeat7',
      label: 'Preheat temperature 7',
      dimension: '°C'
    },
    {
      key: 'fPreHeat8',
      label: 'Preheat temperature 8',
      dimension: '°C'
    },
    {
      key: 'fPressingTemp1',
      label: 'Pressing temperature 1',
      dimension: '°C'
    },
    {
      key: 'fPressingTemp2',
      label: 'Pressing temperature 2',
      dimension: '°C'
    },
    {
      key: 'fPressingTemp3',
      label: 'Pressing temperature 3',
      dimension: '°C'
    },
    {
      key: 'fPressingTemp4',
      label: 'Pressing temperature 4',
      dimension: '°C'
    },
    {
      key: 'fPressingTemp5',
      label: 'Pressing temperature 5',
      dimension: '°C'
    },
    {
      key: 'fPressingTemp6',
      label: 'Pressing temperature 6',
      dimension: '°C'
    },
    {
      key: 'fPressingTemp7',
      label: 'Pressing temperature 7',
      dimension: '°C'
    },
    {
      key: 'fPressingTemp8',
      label: 'Pressing temperature 8',
      dimension: '°C'
    },
    {
      key: 'fTempGradInputHeating1',
      label: 'Heating gradient 1',
      dimension: '°C/min'
    },
    {
      key: 'fTempGradInputHeating2',
      label: 'Heating gradient 2',
      dimension: '°C/min'
    },
    {
      key: 'fTempGradInputHeating3',
      label: 'Heating gradient 3',
      dimension: '°C/min'
    },
    {
      key: 'fTempGradInputHeating4',
      label: 'Heating gradient 4',
      dimension: '°C/min'
    },
    {
      key: 'fTempGradInputHeating5',
      label: 'Heating gradient 5',
      dimension: '°C/min'
    },
    {
      key: 'fTempGradInputHeating6',
      label: 'Heating gradient 6',
      dimension: '°C/min'
    },
    {
      key: 'fTempGradInputHeating7',
      label: 'Heating gradient 7',
      dimension: '°C/min'
    },
    {
      key: 'fTempGradInputHeating8',
      label: 'Heating gradient 8',
      dimension: '°C/min'
    },
    {
      key: 'iIndexComboHeatingAssign1',
      label: 'Heating output assignement heating circle 1',
      dimension: ''
    },
    {
      key: 'iIndexComboHeatingAssign2',
      label: 'Heating output assignement heating circle 2',
      dimension: ''
    },
    {
      key: 'iIndexComboHeatingAssign3',
      label: 'Heating output assignement heating circle 3',
      dimension: ''
    },
    {
      key: 'iIndexComboHeatingAssign4',
      label: 'Heating output assignement heating circle 4',
      dimension: ''
    },
    {
      key: 'iIndexComboHeatingAssign8Ch1',
      label: 'Heating output assignement 8 Channel heating circle 1',
      dimension: ''
    },
    {
      key: 'iIndexComboHeatingAssign8Ch2',
      label: 'Heating output assignement 8 Channel heating circle 2',
      dimension: ''
    },
    {
      key: 'iIndexComboHeatingAssign8Ch3',
      label: 'Heating output assignement 8 Channel heating circle 3',
      dimension: ''
    },
    {
      key: 'iIndexComboHeatingAssign8Ch4',
      label: 'Heating output assignement 8 Channel heating circle 4',
      dimension: ''
    },
    {
      key: 'iIndexComboHeatingAssign8Ch5',
      label: 'Heating output assignement 8 Channel heating circle 5',
      dimension: ''
    },
    {
      key: 'iIndexComboHeatingAssign8Ch6',
      label: 'Heating output assignement 8 Channel heating circle 6',
      dimension: ''
    },
    {
      key: 'iIndexComboHeatingAssign8Ch7',
      label: 'Heating output assignement 8 Channel heating circle 7',
      dimension: ''
    },
    {
      key: 'iIndexComboHeatingAssign8Ch8',
      label: 'Heating output assignement 8 Channel heating circle 8',
      dimension: ''
    },
    { key: 'iIndexTCIN1', label: 'TCIN1', dimension: '' },
    { key: 'iIndexTCIN2', label: 'TCIN2', dimension: '' },
    { key: 'iIndexTCIN3', label: 'TCIN3', dimension: '' },
    { key: 'iIndexTCIN4', label: 'TCIN4', dimension: '' },
    {
      key: 'iIndexTCIN5',
      label: 'TCIN5',
      dimension: ''
    },
    {
      key: 'iIndexTCIN6',
      label: 'TCIN6',
      dimension: ''
    },
    {
      key: 'iIndexTCIN7',
      label: 'TCIN7',
      dimension: ''
    },
    {
      key: 'iIndexTCIN8',
      label: 'TCIN8',
      dimension: ''
    },
    {
      key: 'iIndexTCIN9',
      label: 'TCIN9',
      dimension: ''
    },
    {
      key: 'iIndexTCIN10',
      label: 'TCIN10',
      dimension: ''
    },
    {
      key: 'iIndexTCIN11',
      label: 'TCIN11',
      dimension: ''
    },
    {
      key: 'iIndexTCIN12',
      label: 'TCIN12',
      dimension: ''
    },
    {
      key: 'iIndexTCIN13',
      label: 'TCIN13',
      dimension: ''
    },
    {
      key: 'iIndexTCIN14',
      label: 'TCIN14',
      dimension: ''
    },
    {
      key: 'iIndexTCIN15',
      label: 'TCIN15',
      dimension: ''
    },
    {
      key: 'iIndexTCIN16',
      label: 'TCIN16',
      dimension: ''
    },
    {
      key: 'bActivateAutoPreheating',
      label: 'bActivateAutoPreheating',
      dimension: ''
    }
  ],
  'PID parameter': [
    {
      key: 'fTuneTemp1',
      label: 'Tuning temperature 1',
      dimension: '°C'
    },
    {
      key: 'fTuneTemp2',
      label: 'Tuning temperature 2',
      dimension: '°C'
    },
    {
      key: 'fTuneTemp3',
      label: 'Tuning temperature 3',
      dimension: '°C'
    },
    {
      key: 'fTuneTemp4',
      label: 'Tuning temperature 4',
      dimension: '°C'
    },
    {
      key: 'fTuneTemp5',
      label: 'Tuning temperature 5',
      dimension: '°C'
    },
    {
      key: 'fTuneTemp6',
      label: 'Tuning temperature 6',
      dimension: '°C'
    },
    {
      key: 'fTuneTemp7',
      label: 'Tuning temperature 7',
      dimension: '°C'
    },
    {
      key: 'fTuneTemp8',
      label: 'Tuning temperature 8',
      dimension: '°C'
    },
    {
      key: 'fbTempController1KpCool',
      label: 'fbTempController1KpCool',
      dimension: ''
    },
    {
      key: 'fbTempController1KpHeat',
      label: 'fbTempController1KpHeat',
      dimension: ''
    },
    {
      key: 'fbTempController1TdCool',
      label: 'fbTempController1TdCool',
      dimension: ''
    },
    {
      key: 'fbTempController1TdHeat',
      label: 'fbTempController1TdHeat',
      dimension: ''
    },
    {
      key: 'fbTempController1TnCool',
      label: 'fbTempController1TnCool',
      dimension: ''
    },
    {
      key: 'fbTempController1TnHeat',
      label: 'fbTempController1TnHeat',
      dimension: ''
    },
    {
      key: 'fbTempController1TvCool',
      label: 'fbTempController1TvCool',
      dimension: ''
    },
    {
      key: 'fbTempController1TvHeat',
      label: 'fbTempController1TvHeat',
      dimension: ''
    },
    {
      key: 'fbTempController2KpCool',
      label: 'fbTempController2KpCool',
      dimension: ''
    },
    {
      key: 'fbTempController2KpHeat',
      label: 'fbTempController2KpHeat',
      dimension: ''
    },
    {
      key: 'fbTempController2TdCool',
      label: 'fbTempController2TdCool',
      dimension: ''
    },
    {
      key: 'fbTempController2TdHeat',
      label: 'fbTempController2TdHeat',
      dimension: ''
    },
    {
      key: 'fbTempController2TnCool',
      label: 'fbTempController2TnCool',
      dimension: ''
    },
    {
      key: 'fbTempController2TnHeat',
      label: 'fbTempController2TnHeat',
      dimension: ''
    },
    {
      key: 'fbTempController2TvCool',
      label: 'fbTempController2TvCool',
      dimension: ''
    },
    {
      key: 'fbTempController2TvHeat',
      label: 'fbTempController2TvHeat',
      dimension: ''
    },
    {
      key: 'fbTempController3KpCool',
      label: 'fbTempController3KpCool',
      dimension: ''
    },
    {
      key: 'fbTempController3KpHeat',
      label: 'fbTempController3KpHeat',
      dimension: ''
    },
    {
      key: 'fbTempController3TdCool',
      label: 'fbTempController3TdCool',
      dimension: ''
    },
    {
      key: 'fbTempController3TdHeat',
      label: 'fbTempController3TdHeat',
      dimension: ''
    },
    {
      key: 'fbTempController3TnCool',
      label: 'fbTempController3TnCool',
      dimension: ''
    },
    {
      key: 'fbTempController3TnHeat',
      label: 'fbTempController3TnHeat',
      dimension: ''
    },
    {
      key: 'fbTempController3TvCool',
      label: 'fbTempController3TvCool',
      dimension: ''
    },
    {
      key: 'fbTempController3TvHeat',
      label: 'fbTempController3TvHeat',
      dimension: ''
    },
    {
      key: 'fbTempController4KpCool',
      label: 'fbTempController4KpCool',
      dimension: ''
    },
    {
      key: 'fbTempController4KpHeat',
      label: 'fbTempController4KpHeat',
      dimension: ''
    },
    {
      key: 'fbTempController4TdCool',
      label: 'fbTempController4TdCool',
      dimension: ''
    },
    {
      key: 'fbTempController4TdHeat',
      label: 'fbTempController4TdHeat',
      dimension: ''
    },
    {
      key: 'fbTempController4TnCool',
      label: 'fbTempController4TnCool',
      dimension: ''
    },
    {
      key: 'fbTempController4TnHeat',
      label: 'fbTempController4TnHeat',
      dimension: ''
    },
    {
      key: 'fbTempController4TvCool',
      label: 'fbTempController4TvCool',
      dimension: ''
    },
    {
      key: 'fbTempController4TvHeat',
      label: 'fbTempController4TvHeat',
      dimension: ''
    },
    {
      key: 'fbTempController5KpCool',
      label: 'fbTempController5KpCool',
      dimension: ''
    },
    {
      key: 'fbTempController5KpHeat',
      label: 'fbTempController5KpHeat',
      dimension: ''
    },
    {
      key: 'fbTempController5TdCool',
      label: 'fbTempController5TdCool',
      dimension: ''
    },
    {
      key: 'fbTempController5TdHeat',
      label: 'fbTempController5TdHeat',
      dimension: ''
    },
    {
      key: 'fbTempController5TnCool',
      label: 'fbTempController5TnCool',
      dimension: ''
    },
    {
      key: 'fbTempController5TnHeat',
      label: 'fbTempController5TnHeat',
      dimension: ''
    },
    {
      key: 'fbTempController5TvCool',
      label: 'fbTempController5TvCool',
      dimension: ''
    },
    {
      key: 'fbTempController5TvHeat',
      label: 'fbTempController5TvHeat',
      dimension: ''
    },
    {
      key: 'fbTempController6KpCool',
      label: 'fbTempController6KpCool',
      dimension: ''
    },
    {
      key: 'fbTempController6KpHeat',
      label: 'fbTempController6KpHeat',
      dimension: ''
    },
    {
      key: 'fbTempController6TdCool',
      label: 'fbTempController6TdCool',
      dimension: ''
    },
    {
      key: 'fbTempController6TdHeat',
      label: 'fbTempController6TdHeat',
      dimension: ''
    },
    {
      key: 'fbTempController6TnCool',
      label: 'fbTempController6TnCool',
      dimension: ''
    },
    {
      key: 'fbTempController6TnHeat',
      label: 'fbTempController6TnHeat',
      dimension: ''
    },
    {
      key: 'fbTempController6TvCool',
      label: 'fbTempController6TvCool',
      dimension: ''
    },
    {
      key: 'fbTempController6TvHeat',
      label: 'fbTempController6TvHeat',
      dimension: ''
    },
    {
      key: 'fbTempController7KpCool',
      label: 'fbTempController7KpCool',
      dimension: ''
    },
    {
      key: 'fbTempController7KpHeat',
      label: 'fbTempController7KpHeat',
      dimension: ''
    },
    {
      key: 'fbTempController7TdCool',
      label: 'fbTempController7TdCool',
      dimension: ''
    },
    {
      key: 'fbTempController7TdHeat',
      label: 'fbTempController7TdHeat',
      dimension: ''
    },
    {
      key: 'fbTempController7TnCool',
      label: 'fbTempController7TnCool',
      dimension: ''
    },
    {
      key: 'fbTempController7TnHeat',
      label: 'fbTempController7TnHeat',
      dimension: ''
    },
    {
      key: 'fbTempController7TvCool',
      label: 'fbTempController7TvCool',
      dimension: ''
    },
    {
      key: 'fbTempController7TvHeat',
      label: 'fbTempController7TvHeat',
      dimension: ''
    },
    {
      key: 'fbTempController8KpCool',
      label: 'fbTempController8KpCool',
      dimension: ''
    },
    {
      key: 'fbTempController8KpHeat',
      label: 'fbTempController8KpHeat',
      dimension: ''
    },
    {
      key: 'fbTempController8TdCool',
      label: 'fbTempController8TdCool',
      dimension: ''
    },
    {
      key: 'fbTempController8TdHeat',
      label: 'fbTempController8TdHeat',
      dimension: ''
    },
    {
      key: 'fbTempController8TnCool',
      label: 'fbTempController8TnCool',
      dimension: ''
    },
    {
      key: 'fbTempController8TnHeat',
      label: 'fbTempController8TnHeat',
      dimension: ''
    },
    {
      key: 'fbTempController8TvCool',
      label: 'fbTempController8TvCool',
      dimension: ''
    },
    {
      key: 'fbTempController8TvHeat',
      label: 'fbTempController8TvHeat',
      dimension: ''
    }
  ],
  'Mould closing parameter': [
    {
      key: 'iIndexComboClosingRamps',
      label: 'Number of closing position used',
      dimension: ''
    },
    {
      key: 'fClosingPos1',
      label: 'Closing position 1',
      dimension: 'mm'
    },
    {
      key: 'fClosingPos2',
      label: 'Closing position 2',
      dimension: 'mm'
    },
    {
      key: 'fClosingPos3',
      label: 'Closing position 3',
      dimension: 'mm'
    },
    {
      key: 'fClosingVel1',
      label: 'Closing velocity 1',
      dimension: 'mm/s'
    },
    {
      key: 'fClosingVel2',
      label: 'Closing velocity 2',
      dimension: 'mm/s'
    },
    {
      key: 'fClosingVel3',
      label: 'Closing velocity 3',
      dimension: 'mm/s'
    },
    {
      key: 'fClosingForce1',
      label: 'maximum closing force 1',
      dimension: 'kN'
    },
    {
      key: 'fClosingForce2',
      label: 'maximum closing force 2',
      dimension: 'kN'
    },
    {
      key: 'fClosingForce3',
      label: 'maximum closing force 3',
      dimension: 'kN'
    }
  ],
  'Force control parameter': [
    {
      key: 'fPrePressingTime',
      label: 'Pre force time',
      dimension: 's'
    },
    { key: 'fPreForce_kN', label: 'Pre force', dimension: 'kN' },
    {
      key: 'fConsolidationTime',
      label: 'Pressing time',
      dimension: 's'
    },
    {
      key: 'fForceTarget_kN',
      label: 'Pressing force',
      dimension: 'kN'
    },
    {
      key: 'bEnableCoolingForceControl',
      label: 'Enable cooling force mode',
      dimension: ''
    },
    {
      key: 'fCoolingForce_kN',
      label: 'Cooling force',
      dimension: 'kN'
    },
    {
      key: 'fTempCoolingPressingSet',
      label: 'Temperature where cooling force is applied',
      dimension: '°C'
    },
    {
      key: 'iIndexComboCoolingPressingAssign',
      label: 'Thermocouple which controls cooling pressing force',
      dimension: ''
    },
    {
      key: 'fEndPosition',
      label: 'Pressing end position',
      dimension: 'mm'
    },
    {
      key: 'fZeroOffsetPos',
      label: 'Zero position point of the mould',
      dimension: 'mm'
    },
    {
      key: 'fZeroOffsetForce',
      label: 'Force sensor zero offset',
      dimension: 'kN'
    }
  ],
  'Mould opening parameter': [
    {
      key: 'iIndexComboOpeningRamps',
      label: 'Number of opening position used',
      dimension: ''
    },
    {
      key: 'fOpeningPos1',
      label: 'Opening position 1',
      dimension: 'mm'
    },
    {
      key: 'fOpeningPos2',
      label: 'Opening position 2',
      dimension: 'mm'
    },
    {
      key: 'fOpeningPos3',
      label: 'Opening position 3',
      dimension: 'mm'
    },
    {
      key: 'fOpeningVel1',
      label: 'Opening velocity 1',
      dimension: 'mm/s'
    },
    {
      key: 'fOpeningVel2',
      label: 'Opening velocity 2',
      dimension: 'mm/s'
    },
    {
      key: 'fOpeningVel3',
      label: 'Opening velocity 3',
      dimension: 'mm/s'
    },
    {
      key: 'fDemouldingDisplacement',
      label: 'Demoulding distance',
      dimension: 'mm'
    },
    {
      key: 'fDemouldingVelocity',
      label: 'Demoulding velocity',
      dimension: 'mm/s'
    },
    {
      key: 'bGoAutotoOpenAfterOpenRamps',
      label: 'Move to zero position after openingramps',
      dimension: ''
    },
    {
      key: 'bGoAutotoOpenAfterDemould',
      label: 'Move to zero position after demoulding',
      dimension: ''
    }
  ],
  'Cooling parameter': [
    {
      key: 'iIndexComboCoolingCircuits',
      label: 'Number of attached cooling circuits',
      dimension: ''
    },
    {
      key: 'bActivatePlateau',
      label: 'Plateau activated',
      dimension: ''
    },
    {
      key: 'fTempContinuosCooling',
      label: 'Switch on continuous cooling temperature',
      dimension: '°C'
    },
    {
      key: 'fTempFinishCooling',
      label: 'Switch off temperature for cooling',
      dimension: '°C'
    },
    { key: 'fPlateauTime', label: 'Plateau time', dimension: 's' },
    {
      key: 'fPlateauTemp1',
      label: 'Plateau Temperature 1',
      dimension: '°C'
    },
    {
      key: 'fPlateauTemp2',
      label: 'Plateau Temperature 2',
      dimension: '°C'
    },
    {
      key: 'fPlateauTemp3',
      label: 'Plateau Temperature 3',
      dimension: '°C'
    },
    {
      key: 'fPlateauTemp4',
      label: 'Plateau Temperature 4',
      dimension: '°C'
    },
    {
      key: 'fPlateauTemp5',
      label: 'Plateau Temperature 5',
      dimension: '°C'
    },
    {
      key: 'fPlateauTemp6',
      label: 'Plateau Temperature 6',
      dimension: '°C'
    },
    {
      key: 'fPlateauTemp7',
      label: 'Plateau Temperature 7',
      dimension: '°C'
    },
    {
      key: 'fPlateauTemp8',
      label: 'Plateau Temperature 8',
      dimension: '°C'
    },
    {
      key: 'fTempGradInputCooling1',
      label: 'Cooling gradient 1',
      dimension: '°C/min'
    },
    {
      key: 'fTempGradInputCooling2',
      label: 'Cooling gradient 2',
      dimension: '°C/min'
    },
    {
      key: 'fTempGradInputCooling3',
      label: 'Cooling gradient 3',
      dimension: '°C/min'
    },
    {
      key: 'fTempGradInputCooling1Assigned',
      label: 'Cooling Gradient for heating stabilisation 1',
      dimension: '°C/min'
    },
    {
      key: 'fTempGradInputCooling2Assigned',
      label: 'Cooling Gradient for heating stabilisation 2',
      dimension: '°C/min'
    },
    {
      key: 'fTempGradInputCooling3Assigned',
      label: 'Cooling Gradient for heating stabilisation 3',
      dimension: '°C/min'
    },
    {
      key: 'fTempGradInputCooling4Assigned',
      label: 'Cooling Gradient for heating stabilisation 4',
      dimension: '°C/min'
    },
    {
      key: 'fTempGradInputCooling5Assigned',
      label: 'Cooling Gradient for heating stabilisation 5',
      dimension: '°C/min'
    },
    {
      key: 'fTempGradInputCooling6Assigned',
      label: 'Cooling Gradient for heating stabilisation 6',
      dimension: '°C/min'
    },
    {
      key: 'fTempGradInputCooling7Assigned',
      label: 'Cooling Gradient for heating stabilisation 7',
      dimension: '°C/min'
    },
    {
      key: 'fTempGradInputCooling8Assigned',
      label: 'Cooling Gradient for heating stabilisation 8',
      dimension: '°C/min'
    },
    {
      key: 'iIndexComboCoolingAssign1',
      label: 'Thermocouple assignement cooling circle 1',
      dimension: ''
    },
    {
      key: 'iIndexComboCoolingAssign2',
      label: 'Thermocouple assignement cooling circle 2',
      dimension: ''
    },
    {
      key: 'iIndexComboCoolingAssign3',
      label: 'Thermocouple assignement cooling circle 3',
      dimension: ''
    },
    {
      key: 'iIndexComboCoolingAssign8CH1',
      label: 'Thermocouple assignement cooling circle 8 Channel 1',
      dimension: ''
    },
    {
      key: 'iIndexComboCoolingAssign8CH2',
      label: 'Thermocouple assignement cooling circle 8 Channel 2',
      dimension: ''
    },
    {
      key: 'iIndexComboCoolingAssign8CH3',
      label: 'Thermocouple assignement cooling circle 8 Channel 3',
      dimension: ''
    },
    {
      label: 'bPassivCooling',
      key: 'bPassivCooling',
      dimension: ''
    },
    {
      label: 'bAirCoolingOnly',
      key: 'bAirCoolingOnly',
      dimension: ''
    }
  ],
  Other: []
};
