import React from 'react';

import { useBuildDialog } from './BuildDialog';

import BuildModuleCard from '../BuildModule/BuildModuleCard';
import TitanDataGrid, { VIEW_MODES } from '../TitanDataGrid/TitanDataGrid';
import BuildModuleLink from '../BuildModule/BuildModuleLink';
import BuildModuleState from '../BuildModule/BuildModuleState';
import BuildJobLink from '../BuildJob/BuildJobLink';
import ComponentStatus from '../Component/ComponentStatus';
import PrinterService from '../../services/PrinterService';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import useBuildModules from '../BuildModule/use-build-modules';
import Box from '@mui/material/Box';
import { colors } from '../Theme/vars';
import Chip from '@mui/material/Chip';

export default function BuildDialogBuildModulesStep() {
  const { selectedBuildModules, setSelectedBuildModules } = useBuildDialog();

  const onSelectBuildModule = React.useCallback(
    (e, buildModule) => {
      setSelectedBuildModules((prev) => {
        if (prev.find((bm) => bm.id === buildModule.id)) {
          return prev.filter((bm) => bm.id !== buildModule.id);
        }

        return [...prev, buildModule];
      });
    },
    [setSelectedBuildModules],
  );

  const buildModuleColumns = React.useMemo(
    () => [
      {
        headerName: 'Build Module',
        field: 'name',
        visibilityBreakpoint: 'sm',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <BuildModuleLink buildModule={row} />,
      },
      {
        headerName: 'State',
        field: 'state',
        visibilityBreakpoint: 'sm',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => (
          <BuildModuleState buildModule={row} showVersion={false} />
        ),
      },
      {
        headerName: 'Version',
        field: 'version',
        visibilityBreakpoint: 'sm',
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => row.deviceVersion || '',
      },
      {
        headerName: 'Build Job',
        field: 'buildJob',
        visibilityBreakpoint: 'sm',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => {
          const activeJob = row.activeJobs[0];
          const prevJob = row.prevBuildJob;

          return activeJob || prevJob ? (
            <>
              <BuildJobLink buildJob={activeJob ? activeJob : prevJob} />
              <Chip
                size="small"
                label={activeJob ? 'Active' : 'Previous'}
                sx={{ ml: 1, color: colors.STONE }}
              />
            </>
          ) : (
            ''
          );
        },
      },
      {
        headerName: 'Job Status',
        field: 'buildJobStatus',
        visibilityBreakpoint: 'sm',
        minWidth: 220,
        sortable: false,
        renderCell: ({ row }) => {
          const activeJob = row.activeJobs[0];
          const prevJob = row.prevBuildJob;

          return activeJob || prevJob ? (
            <ComponentStatus
              component={activeJob ? activeJob : prevJob}
              showTooltip={false}
              showVersion={false}
            />
          ) : (
            ''
          );
        },
      },
    ],
    [],
  );

  const loadBuildModules = React.useCallback(async (params, config) => {
    const response = await PrinterService.getPrinters(
      {
        withRelated: [
          'activeJobs',
          'activeJobs.buildPlan',
          'activeJobs.components',
          'prevBuildJob',
          'prevBuildJob.buildPlan',
          'prevBuildJob.components',
        ],
        ...params,
      },
      config,
    );

    return response;
  }, []);

  const buildModulesDataGridProps = useTitanDataGrid(loadBuildModules, {
    columns: buildModuleColumns,
    defaultViewMode: VIEW_MODES.GRID,
  });

  const { rows } = buildModulesDataGridProps;

  const returnBuildModules = React.useCallback(() => {
    return rows;
  }, [rows]);

  const { buildModules } = useBuildModules(returnBuildModules);

  return (
    <Box sx={{ p: 2 }}>
      <TitanDataGrid
        {...buildModulesDataGridProps}
        rows={buildModules}
        allowViewModeChange={true}
        renderGridCard={(item) => (
          <BuildModuleCard
            buildModule={item}
            onClick={onSelectBuildModule}
            selected={!!selectedBuildModules.find((bm) => bm.id === item.id)}
          />
        )}
        searchPlaceholder="Search by Build Module name..."
        checkboxMultiSelection
        selectedRows={selectedBuildModules}
        onSelectionModelChange={(selectedBuildModules) => {
          setSelectedBuildModules(selectedBuildModules);
        }}
      />
    </Box>
  );
}
