import React from 'react';
import OdooService from '../../services/OdooService';
import TitanDialog from '../Titan/TitanDialog';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import SyncIcon from '@mui/icons-material/Sync';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';

export default function OdooImportManufacturingOrderSpoolsDialog({
  odooManufacturingOrderId,
  onClose
}) {
  const [
    odooManufacturingOrderSpool,
    setOdooManufacturingOrderSpool
  ] = React.useState();

  const loadData = React.useCallback(
    async ({ page, pageSize, search }, config) => {
      const params = {
        page: page,
        pageSize: pageSize
      };

      if (search) {
        params.search = search;
      }

      const {
        data,
        pagination
      } = await OdooService.getOdooManufacturingOrderSpools(
        odooManufacturingOrderId,
        params,
        config
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount
      };
    },
    []
  );

  const titanDataGridProps = useTitanDataGrid(loadData);

  const { reloadData } = titanDataGridProps;

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        sortable: false,
        flex: 1
      },
      {
        headerName: 'Odoo sync',
        field: 'fpSpoolId',
        type: 'boolean',
        visibilityBreakpoint: 'md',
        minWidth: 120,
        sortable: false
      },
      {
        headerName: '',
        field: 'actions',
        type: 'actions',
        sortable: false,
        width: 50,
        getActions: ({ row }) => {
          return [
            {
              label: 'Import from Odoo',
              icon: <SyncIcon />,
              onClick: () => setOdooManufacturingOrderSpool(row),
              disabled: !!row.fpSpoolId
            }
          ].map(action => (
            <GridActionsCellItem
              icon={action.icon}
              label={action.label}
              onClick={action.onClick}
              showInMenu={true}
              disabled={action.disabled}
            />
          ));
        }
      }
    ],
    []
  );

  const importManufacturingOrderSpoolFromOdoo = React.useCallback(async () => {
    await OdooService.importManufacturingOrderSpoolFromOdoo(
      odooManufacturingOrderId,
      odooManufacturingOrderSpool.id
    );
    await reloadData();
  }, [odooManufacturingOrderSpool, reloadData]);

  return (
    <TitanDialog
      title="Import Spools to fP"
      maxWidth="lg"
      onClose={onClose}
      cancelButtonLabel="Close"
    >
      <TitanDataGrid
        showSearch={false}
        {...titanDataGridProps}
        columns={columns}
        onClose={onClose}
      />

      {odooManufacturingOrderSpool && (
        <TitanConfirmationDialog
          title="Import Manufacturing Order spool from Odoo?"
          message={`Are you sure you want to import ${
            odooManufacturingOrderSpool.name
          } from Odoo to fP?`}
          onClose={() => {
            setOdooManufacturingOrderSpool(null);
          }}
          onConfirm={importManufacturingOrderSpoolFromOdoo}
        />
      )}
    </TitanDialog>
  );
}
