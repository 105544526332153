import React from 'react';
import { format } from 'date-fns';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { SORT_ORDERS } from '../../constants';
import FusorService from '../../services/FusorService';
import FibrifyMouldSelectionDialog from '../Fibrify/FibrifyMouldSelectionDialog';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';

export default function FusionModuleMouldsTable({ fusionModuleId }) {
  const [openSelectMouldsDialog, setOpenSelectMouldsDialog] = React.useState(
    false
  );
  const [
    unloadFusionModuleMouldId,
    setUnloadFusionModuleMouldId
  ] = React.useState(null);

  const loadData = React.useCallback(
    async (params, config) => {
      const { data, pagination } = await FusorService.getFusionModuleMoulds(
        fusionModuleId,
        params,
        config
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount
      };
    },
    [fusionModuleId]
  );

  const titanDataGridProps = useTitanDataGrid(loadData, {
    orders: {
      load_time: SORT_ORDERS.DESC
    }
  });

  const { reloadData } = titanDataGridProps;

  const loadMould = React.useCallback(
    async mouldId => {
      await FusorService.loadMouldToFusionModule(fusionModuleId, mouldId);
      reloadData();
    },
    [fusionModuleId]
  );

  const unloadMould = React.useCallback(
    async fusionModuleMouldId => {
      await FusorService.unloadMouldFromFusionModule(
        fusionModuleId,
        fusionModuleMouldId
      );
      reloadData();
    },
    [fusionModuleId]
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: 'ID',
        field: 'mouldIdentifier',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => row.mould.mouldIdentifier
      },
      {
        headerName: 'Load time',
        field: 'loadTime',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) =>
          format(new Date(row.loadTime), 'yyyy-MM-dd HH:mm:ss')
      },
      {
        headerName: 'Unload time',
        field: 'unloadTime',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) =>
          row.unloadTime
            ? format(new Date(row.unloadTime), 'yyyy-MM-dd HH:mm:ss')
            : '-'
      },
      {
        headerName: '',
        field: 'actions',
        type: 'actions',
        width: 60,
        sortable: false,
        getActions: params =>
          [
            ...(!params.row.unloadTime
              ? [
                  {
                    label: 'Unload',
                    icon: <FileUploadIcon />,
                    onClick: () => setUnloadFusionModuleMouldId(params.row.id)
                  }
                ]
              : [])
          ].map(action => (
            <GridActionsCellItem
              icon={action.icon}
              label={action.label}
              onClick={action.onClick}
              disabled={action.disabled}
              showInMenu
            />
          ))
      }
    ],
    []
  );

  return (
    <>
      <TitanDataGrid
        {...titanDataGridProps}
        showSearch={false}
        columns={columns}
        title=" "
        onCreateClick={() => {
          setOpenSelectMouldsDialog(true);
        }}
        createButtonLabel="Load Mould"
      />

      {openSelectMouldsDialog ? (
        <FibrifyMouldSelectionDialog
          title="Load mould to Fusion Module"
          onClose={() => {
            setOpenSelectMouldsDialog(false);
          }}
          onSave={mould => loadMould(mould.id)}
        />
      ) : (
        ''
      )}

      {unloadFusionModuleMouldId ? (
        <TitanConfirmationDialog
          title="Unload mould from Fusion Module"
          message="Are you sure you want to unload mould from Fusion Module?"
          confirmLabel="Unload"
          onConfirm={() => unloadMould(unloadFusionModuleMouldId)}
          onClose={() => setUnloadFusionModuleMouldId(null)}
        />
      ) : (
        ''
      )}
    </>
  );
}
