import React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import FusorService from '../../services/FusorService';
import {
  ROUTES,
  COMPONENT_STATUSES,
  COMPONENT_ACTIVE_STATUSES,
} from '../../constants';
import TitanPage from '../Titan/TitanPage';
import FusionModuleTelemetry from './FusionModuleTelemetry';
import FusionJobsTable from '../FusionJob/FusionJobsTable';
import FusionModuleLogsTable from './FusionModuleLogsTable';
import TitanTabs from '../Titan/TitanTabs';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadFusionModuleConfigDialog from './LoadFusionModuleConfigDialog';
import FusionModuleStatus from './FusionModuleStatus';
import { useTitan } from '../Titan/Titan';
import FusionModuleStates from './FusionModuleStates';
import FusionModuleMouldsTable from './FusionModuleMouldsTable';
import MetabaseComponent from '../Metabase/MetabaseComponent';

export default function FusionModulePage() {
  const { fusionModuleId, tab = 'telemetry' } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [fusionModule, setFusionModule] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  const { addPageToPageHistory, backToPreviousPage } = useTitan();

  const [openLoadFusionPlanConfigDialog, setOpenLoadFusionPlanConfigDialog] =
    React.useState(false);

  const loadFusionModule = async () => {
    const fusionModule = await FusorService.getFusor(fusionModuleId);

    setFusionModule(fusionModule);
  };

  const crumbs = React.useMemo(
    () => [
      {
        path: ROUTES.FUSION_MODULES,
        name: 'Fusion Modules',
        onClick: () => {
          addPageToPageHistory({
            id: 'FUSION_MODULES',
            url: ROUTES.FUSION_MODULES,
            label: 'Fusion Modules',
          });
        },
      },
      {
        path: ROUTES.FUSION_MODULE(fusionModuleId),
        name: fusionModule && fusionModule.name,
        disabled: true,
      },
    ],
    [fusionModule],
  );

  React.useEffect(() => {
    setLoading(true);

    loadFusionModule()
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });
  }, [fusionModuleId]);

  const fusionJobsStatuses = React.useMemo(
    () => [COMPONENT_STATUSES.SCHEDULED, ...COMPONENT_ACTIVE_STATUSES],
    [],
  );

  const pushHistory = React.useCallback(
    (tabName, settedPage) => {
      history.push(
        ROUTES.FUSION_MODULE_TAB_PAGE(fusionModuleId, tabName, settedPage),
      );
    },
    [fusionModuleId],
  );

  const preparePageObject = (tab, tabForURL) => {
    return {
      id: `FUSION_MODULE:${fusionModuleId}`,
      url:
        tabForURL === 'telemetry'
          ? ROUTES.FUSION_MODULE_TAB(fusionModuleId, tabForURL)
          : ROUTES.FUSION_MODULE_TAB_PAGE(fusionModuleId, tabForURL, 0),
      label: `${fusionModule.name || 'Fusion Module'} | ${tab}`,
    };
  };

  const setNewPageForTab = React.useCallback(
    (tabName, labelName, settedPage) => {
      if (fusionModuleId) {
        const newRoute = ROUTES.FUSION_MODULE_TAB_PAGE(
          fusionModuleId,
          tabName,
          settedPage,
        );
        addPageToPageHistory({
          id: `FUSION_MODULE:${fusionModuleId}`,
          url: newRoute,
          label:
            settedPage === 0
              ? `${
                  fusionModule.name ? fusionModule.name : 'Fusion Module'
                } | ${labelName}`
              : `${
                  fusionModule.name ? fusionModule.name : 'Fusion Module'
                } | ${labelName} | Page : ${settedPage + 1}`,
        });
      }
    },
    [fusionModule],
  );

  const tabs = React.useMemo(
    () => [
      {
        label: 'Telemetry',
        value: 'telemetry',
        content: <FusionModuleTelemetry fusionModuleId={fusionModuleId} />,
      },
      {
        label: 'Fusion Jobs',
        value: 'fusionJobs',
        content: (
          <FusionJobsTable
            fusionModuleId={fusionModuleId}
            fusionModuleName={fusionModule?.name}
            statuses={fusionJobsStatuses}
            hiddenColumns={['fusionModule']}
            hideFusionModulesFilter={true}
          />
        ),
      },
      {
        label: 'Logs',
        value: 'logs',
        content: (
          <FusionModuleLogsTable
            fusionModuleId={fusionModuleId}
            setNewPageForTab={setNewPageForTab}
            pushHistory={pushHistory}
          />
        ),
      },
      {
        label: 'States History',
        value: 'states',
        content: <FusionModuleStates fusionModule={fusionModule} />,
      },
      {
        label: 'Moulds',
        value: 'moulds',
        content: <FusionModuleMouldsTable fusionModuleId={fusionModuleId} />,
      },
      ...(fusionModule && fusionModule.metabaseUrl
        ? [
            {
              label: 'Statistics',
              value: 'statistics',
              content: (
                <MetabaseComponent metabaseUrl={fusionModule.metabaseUrl} />
              ),
            },
          ]
        : []),
    ],
    [fusionModuleId, fusionModule, setNewPageForTab],
  );

  return (
    <>
      <TitanPage
        breadcrumbs={crumbs}
        loading={loading}
        error={error}
        title={fusionModule ? fusionModule.name : ''}
        onBackButtonClick={() => {
          backToPreviousPage(location);
        }}
        titleContent={
          <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            {fusionModule && (
              <FusionModuleStatus
                fusionModuleId={fusionModule.id}
                fusionModule={fusionModule}
              />
            )}
          </Box>
        }
        headerContent={
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenLoadFusionPlanConfigDialog(true)}
            title="Load Fusion Module config to Fusion Plan"
          >
            Load config
          </Button>
        }
      >
        <TitanTabs
          tabs={tabs}
          activeTab={tab}
          onChangeTab={(value) => {
            const activeLabel = tabs.find((tab) => tab.value === value)?.label;
            addPageToPageHistory(preparePageObject(activeLabel, value));
            value === 'telemetry'
              ? history.push(ROUTES.FUSION_MODULE_TAB(fusionModuleId, value))
              : history.push(
                  ROUTES.FUSION_MODULE_TAB_PAGE(fusionModuleId, value, 0),
                );
          }}
        />
      </TitanPage>

      {openLoadFusionPlanConfigDialog && (
        <LoadFusionModuleConfigDialog
          fusionModuleId={fusionModuleId}
          onClose={() => setOpenLoadFusionPlanConfigDialog(false)}
        />
      )}
    </>
  );
}
