import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select
} from '@mui/material';
import React from 'react';

export const MultiSelectCell = ({
  row,
  customFieldData,
  customField,
  componentTypes,
  isEditable,
  updateCustomField,
  prepareComponentToUpdate,
  updateComponents
}) => {
  const variants = customFieldData?.value
    ? customFieldData?.value.split(', ')
    : [];

  const options =
    customFieldData?.fusionPlanCustomField?.options ||
    customFieldData?.preformTypeCustomField?.options ||
    componentTypes.customFields.find(cf => cf.name === customField.name)
      .options;

  return (
    <FormControl sx={{ m: 0.5, width: 350 }} size="small">
      <InputLabel id="multiple-checkbox-label">Variants</InputLabel>
      <Select
        labelId="multiple-checkbox-label"
        id="demo-multiple-checkbox"
        disabled={!isEditable(row)}
        value={variants}
        multiple
        onClose={() => {
          if (customFieldData) {
            updateCustomField(row, {
              ...customFieldData,
              value: variants.join(', ')
            });
          }
        }}
        onChange={e => {
          let newValues = [];

          e.target.value.forEach(item => {
            if (newValues.findIndex(nV => nV === item) >= 0) {
              newValues = newValues.filter(nV => nV === item);
            } else {
              newValues.push(item);
            }
          });

          const componentToUpdate = prepareComponentToUpdate(
            'MULTI_SELECT',
            row,
            customField.name,
            newValues
          );

          updateComponents([componentToUpdate]);
        }}
        input={<OutlinedInput label="Variants" />}
        renderValue={variants => variants.join(' ')}
      >
        {options &&
          options.map(variant => (
            <MenuItem key={variant} value={variant}>
              <Checkbox
                checked={variants.findIndex(item => item === variant) >= 0}
              />
              <ListItemText primary={variant} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
