import React from 'react';
import semver from 'semver';
import TextField from '@mui/material/TextField';
import FDSReleaseService from '../../services/FDSReleaseService';
import TitanDialog from '../Titan/TitanDialog';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { ButtonGroup, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

export default function FDSReleaseDialog(props) {
  const { mode, release, onClose, onSave } = props;

  const [versionError, setVersionError] = React.useState('');

  const [version, setVersion] = React.useState(release.version || '');
  const [state, setState] = React.useState(release.state || '');
  const [secretKey, setSecretKey] = React.useState(release.secretKey || '');
  const [description, setDescription] = React.useState(
    release.description || ''
  );
  const [fileData, setFileData] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);

  const handleVersionChange = e => {
    const { value } = e.target;
    const newVersion = value
      .split('.')
      .slice(0, 3)
      .join('.');

    if (!semver.valid(newVersion)) {
      setVersionError('Please enter valid version');
    } else {
      setVersionError('');
    }

    setVersion(value);
  };

  const handleBtnClick = event => {
    setState(event.target.textContent);
  };

  const onSubmitForm = () => {
    if (mode === 'create') {
      return FDSReleaseService.createRelease({
        file: fileData,
        version,
        description,
        secretKey,
        state
      }).then(data => {
        onSave(data);
      });
    } else {
      const data = {
        version,
        description,
        secretKey,
        state
      };

      if (fileData) {
        data.file = fileData;
      }

      return FDSReleaseService.updateRelease(release.id, data).then(data => {
        onSave(data);
      });
    }
  };

  const uploadFile = async e => {
    setUploading(true);
    const fileData = await FDSReleaseService.uploadReleaseFile(
      e.target.files[0]
    );
    setFileData(fileData);
    setUploading(false);
  };

  const isValid =
    (mode === 'edit' || fileData) &&
    !uploading &&
    version.length &&
    description.length &&
    state.length &&
    !versionError;

  return (
    <TitanDialog
      title={`${
        mode === 'create' ? 'Create' : 'Edit'
      } SpaceClaim Plugin Release`}
      onClose={onClose}
      onSave={onSubmitForm}
      isValid={isValid}
      successSaveMessage={`SpaceClaim plugin release successfully ${
        mode === 'create' ? 'created' : 'updated'
      }`}
    >
      <TextField
        autoFocus
        label="Version"
        value={version}
        fullWidth
        error={!!versionError}
        helperText={versionError}
        margin="normal"
        variant="outlined"
        onChange={handleVersionChange}
      />

      <TextField
        label="Description"
        multiline
        rows={4}
        value={description}
        fullWidth
        margin="normal"
        variant="outlined"
        onChange={e => setDescription(e.target.value)}
      />

      <TextField
        autoFocus
        label="Secret Key"
        value={secretKey}
        fullWidth
        margin="normal"
        variant="outlined"
        onChange={e => setSecretKey(e.target.value)}
      />

      <ButtonGroup
        required
        color="primary"
        aria-label="contained primary button group"
        onClick={handleBtnClick}
      >
        <Button variant={state === 'Release' ? 'contained' : ''}>
          Release
        </Button>
        <Button variant={state === 'PreRelease' ? 'contained' : ''}>
          PreRelease
        </Button>
        <Button variant={state === 'Alpha' ? 'contained' : ''}>Alpha</Button>
      </ButtonGroup>

      {release.location && (
        <Box>
          Current file:{' '}
          {release.location
            .split('_')
            .slice(1)
            .join('_')}
        </Box>
      )}

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <FormControl margin="normal">
          <input
            accept="*"
            id="sc-plugin-release-file"
            multiple
            type="file"
            style={{ display: 'none' }}
            onChange={uploadFile}
          />
          <label htmlFor="sc-plugin-release-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              disabled={uploading}
            >
              Upload release file
            </Button>
          </label>
        </FormControl>
        {uploading && <CircularProgress />}
        {!uploading && fileData && <div>{fileData.name}</div>}
      </Box>
    </TitanDialog>
  );
}
