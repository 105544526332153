import React from 'react';
import { useHistory } from 'react-router-dom';
import BuildPlanConfigurationTab from './BuildPlanConfigurationTab';
import BuildPlanFilesTab from './BuildPlanFilesTab';
import BuildPlanHistoryTab from './BuildPlanHistoryTab';
import { useBuildPlan } from './BuildPlanPage';
import { useAuth0 } from '../Authentication/Auth0';
import BuildJobsTable from '../BuildJob/BuildJobsTable';
import PreformsTable from '../Preforms/PreformsTable';
import ManufacturingOrdersTable from '../ManufacturingOrders/ManufacturingOrdersTable';
import TitanTabs from '../Titan/TitanTabs';
import {
  COMPONENT_ACTIVE_STATUSES,
  COMPONENT_STATUSES,
  ROUTES,
  BUILD_PLAN_SHARE_TYPES,
} from '../../constants';
import { Paper } from '@mui/material';
import MetabaseComponent from '../Metabase/MetabaseComponent';

export default function BuildPlanContent({
  buildPlanId,
  tab,
  addPageToPageHistory,
}) {
  const { buildPlan, buildPlanVersions } = useBuildPlan();
  const { currentMemberId, isOrganizationAdmin, isApplicationEngineer } =
    useAuth0();

  const history = useHistory();

  const statuses = React.useMemo(
    () => [COMPONENT_STATUSES.SCHEDULED, ...COMPONENT_ACTIVE_STATUSES],
    [],
  );

  const isUserBuildPlanOwner = React.useMemo(
    () => buildPlan?.member?.id === currentMemberId,
    [buildPlan, currentMemberId],
  );

  const enableHistoryTab = React.useMemo(() => {
    if (!buildPlan.approvedVersion) {
      return true;
    }

    const planHasOtherSharedVersions = !!buildPlanVersions.find(
      (version) =>
        (version.shareType === BUILD_PLAN_SHARE_TYPES.ORGANIZATION ||
          version.shareType === BUILD_PLAN_SHARE_TYPES.MEMBERS) &&
        version.id !== buildPlan?.id,
    );

    return planHasOtherSharedVersions || isUserBuildPlanOwner;
  }, [buildPlan, buildPlanVersions, isUserBuildPlanOwner]);

  const preparePageObject = (tab, tabForURL) => {
    return {
      id: `BUILD_PLAN:${buildPlanId}`,
      url:
        tabForURL === 'build-jobs' || tabForURL === 'preforms'
          ? ROUTES.BUILD_PLAN_V2_TAB_PAGE(buildPlanId, tabForURL, 0)
          : ROUTES.BUILD_PLAN_V2_TAB(buildPlanId, tabForURL),
      label: `${buildPlan.name || 'Build Plan'} | ${tab}`,
    };
  };

  const tabs = React.useMemo(
    () => [
      {
        label: 'Configuration',
        value: 'configuration',
        content: <BuildPlanConfigurationTab />,
      },
      {
        label: 'Files',
        value: 'files',
        content: <BuildPlanFilesTab />,
      },
      {
        label: 'Build Jobs',
        value: 'build-jobs',
        content: (
          <BuildJobsTable
            buildPlanId={buildPlan.id}
            rootBuildPlanId={buildPlan.root}
            buildPlanName={buildPlan.name}
            hiddenColumns={['buildPlan']}
            statuses={statuses}
          />
        ),
      },
      {
        label: 'Preforms',
        value: 'preforms',
        content: (
          <PreformsTable
            buildPlanId={buildPlan.id}
            rootBuildPlanId={buildPlan.root}
          />
        ),
      },
      {
        label: 'Manufacturing Orders',
        value: 'manufacturing-orders',
        disabled: !buildPlan.isApproved,
        disabledMessage: 'Build Plan is not approved',
        content: (
          <ManufacturingOrdersTable
            buildPlan={buildPlan}
            connectToManufacturingOrderButton={
              isOrganizationAdmin || isApplicationEngineer
            }
            connectToManufacturingOrderButtonDisabled={!buildPlan.projectId}
            connectToManufacturingOrderButtonDisabledTooltip={
              !buildPlan.projectId ? 'Add Build Plan to the Project first' : ''
            }
          />
        ),
      },
      {
        label: 'History',
        value: 'History',
        content: <BuildPlanHistoryTab />,
        disabled: !enableHistoryTab,
        disabledMessage:
          'You have access only to approved version of this Build Plan',
      },
      ...(buildPlan.metabaseUrl
        ? [
            {
              label: 'Statistics',
              value: 'statistics',
              content: (
                <MetabaseComponent metabaseUrl={buildPlan.metabaseUrl} />
              ),
            },
          ]
        : []),
    ],
    [buildPlan, enableHistoryTab],
  );

  return (
    <TitanTabs
      tabs={tabs}
      activeTab={tab}
      onChangeTab={(value) => {
        const activeLabel = tabs.find((tab) => tab.value === value)?.label;
        addPageToPageHistory(preparePageObject(activeLabel, value));
        history.push(
          value === 'build-jobs' || value === 'preforms'
            ? ROUTES.BUILD_PLAN_V2_TAB_PAGE(buildPlanId, value, 0)
            : ROUTES.BUILD_PLAN_V2_TAB(buildPlanId, value),
        );
      }}
    />
  );
}
