import React from 'react';
import TitanDialog from '../Titan/TitanDialog';
import FusorService from '../../services/FusorService';
import Grid from '@mui/material/Grid';
import FusionModuleCard from '../FusionModule/FusionModuleCard';
import useAsyncEffect from 'use-async-effect';
import TitanCircularProgress from '../Titan/TitanCircularProgress';

export default function SelectFusionModuleDialog({
  onSelect,
  title = 'Select Fusion Module',
  submitButtonLabel = 'Select',
  onClose
}) {
  const [loading, setLoading] = React.useState(false);
  const [fusionModules, setFusionModules] = React.useState([]);
  const [selectedFusionModule, setSelectedFusionModule] = React.useState();

  useAsyncEffect(async () => {
    setLoading(true);

    const response = await FusorService.getFusors({
      withRelated: [
        'activeJobs',
        'activeJobs.fusionPlan',
        'activeJobs.assemblies',
        'prevFusionJob',
        'prevFusionJob.fusionPlan',
        'prevFusionJob.assemblies'
      ]
    });

    setLoading(false);
    setFusionModules(response.data);
  }, []);

  const onSelectFusionModule = React.useCallback(
    (e, fusionModule) => {
      setSelectedFusionModule(fusionModule);
    },
    [setSelectedFusionModule]
  );

  const onSave = () => {
    return onSelect(selectedFusionModule);
  };

  return (
    <TitanDialog
      open={true}
      maxWidth="lg"
      title={title}
      onClose={onClose}
      onSave={onSave}
      saveButtonLabel={submitButtonLabel}
      isValid={!!selectedFusionModule}
    >
      {loading ? (
        <TitanCircularProgress />
      ) : (
        <Grid container spacing={3}>
          {fusionModules.map(fusionModule => (
            <Grid
              key={fusionModule.id}
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
            >
              <FusionModuleCard
                fusionModule={fusionModule}
                onClick={onSelectFusionModule}
                selected={fusionModule === selectedFusionModule}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </TitanDialog>
  );
}
