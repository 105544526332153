import React from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import FormControlLabel from '@mui/material/FormControlLabel';
import TitanPopover from '../Titan/TitanPopover';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { makeStyles } from '@mui/styles';
import Rating from '@mui/material/Rating';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 450,
    padding: theme.spacing(2),
  },
  quality: {
    marginTop: theme.spacing(1),
  },
}));

export default function HistoryFilter({
  itemsType,
  onChangeItemsType,
  quality,
  onChangeQuality,
  status,
  onChangeStatus,
}) {
  const classes = useStyles();

  return (
    <TitanPopover icon={FilterListIcon}>
      <div className={classes.root}>
        <FormControl>
          <FormLabel>Items Type</FormLabel>
          <RadioGroup
            row
            value={itemsType}
            onChange={(e) => onChangeItemsType(e.target.value)}
          >
            <FormControlLabel value="all" control={<Radio />} label="All" />
            <FormControlLabel
              value="preforms"
              control={<Radio />}
              label="Preforms"
            />
            <FormControlLabel
              value="final-parts"
              control={<Radio />}
              label="Final Parts"
            />
          </RadioGroup>
        </FormControl>

        <FormControl margin="normal">
          <FormLabel>Status</FormLabel>
          <RadioGroup
            row
            value={status}
            onChange={(e) => onChangeStatus(e.target.value)}
          >
            <FormControlLabel value="ALL" control={<Radio />} label="All" />
            <FormControlLabel
              value="IN_PROGRESS"
              control={<Radio />}
              label="In Progress"
            />
            <FormControlLabel
              value="FINISHED"
              control={<Radio />}
              label="Finished"
            />
            <FormControlLabel
              value="CANCELED"
              control={<Radio />}
              label="Canceled"
            />
          </RadioGroup>
        </FormControl>

        <FormControl margin="normal">
          <FormLabel>Quality</FormLabel>
          <Rating
            value={quality}
            name="quality"
            onChange={(e, quality) => onChangeQuality(quality)}
            className={classes.quality}
          />
        </FormControl>
      </div>
    </TitanPopover>
  );
}
