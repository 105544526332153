import React from 'react';
import { useAuth0 } from '../Authentication/Auth0';
import FibrifyItemsSelectionDialog from './FibrifyItemsSelectionDialog';
import FusionPlanService from '../../services/FusionPlanService';
import FusionPlanLink from '../FusionPlan/FusionPlanLink';
import TitanTimeAgo from '../Titan/TitanTimeAgo';

export default function FibrifyBuildPlanSelectionDialog({
  title,
  multipleSelection = false,
  filters = {},
  currentMembers = [],
  onSave,
  saveButtonLabel = 'Confirm',
  onClose,
}) {
  const { profile } = useAuth0();

  const loadData = React.useCallback(
    async ({ page, pageSize, search }, config) => {
      const params = {
        page: page,
        pageSize: pageSize,
        order: ['created_at:desc'],
        ...filters,
      };

      if (search) {
        params.search = search;
      }

      const { data, pagination } = await FusionPlanService.getFusionPlansV2(
        params,
        config,
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount,
      };
    },
    [profile],
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        hideable: false,
        flex: 1,
        minWidth: 200,
        sortable: false,
        visibilityBreakpoint: 'sm',
        renderCell: ({ row, colDef }) => (
          <FusionPlanLink fusionPlan={row} width={colDef.computedWidth} />
        ),
      },
      {
        headerName: 'Created',
        field: 'createdAt',
        minWidth: 150,
        sortable: false,
        visibilityBreakpoint: 'sm',
        renderCell: ({ row }) => <TitanTimeAgo time={row.createdAt} />,
      },
    ],
    [],
  );

  return (
    <FibrifyItemsSelectionDialog
      onClose={onClose}
      selectedItems={currentMembers}
      loadData={loadData}
      columns={columns}
      title={title}
      multipleSelection={multipleSelection}
      onSave={onSave}
      saveButtonLabel={saveButtonLabel}
      searchPlaceholder="Search by Fusion Plan name..."
    />
  );
}
