import React from 'react';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@mui/styles';
import { useTitan } from '../Titan/Titan';
import TitanDialog from '../Titan/TitanDialog';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
const FileDownload = require('js-file-download');

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  },
  config: {
    margin: theme.spacing(2, 0),

    '& > p': {
      margin: 0
    }
  },
  certificates: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0)
  }
}));

export default function FusionModuleConfigDialog({
  onClose,
  fusionModuleConfig
}) {
  const classes = useStyles();
  const { copyValueToClipboard } = useTitan();

  const downloadCertificate = () => {
    FileDownload(
      fusionModuleConfig.certificate.certificateData,
      fusionModuleConfig.certificate.certificateFileName
    );
  };

  const downloadPrivateKey = () => {
    FileDownload(
      fusionModuleConfig.certificate.privateKeyData,
      fusionModuleConfig.certificate.privateFileName
    );
  };

  const downloadPublicKey = () => {
    FileDownload(
      fusionModuleConfig.certificate.publicKeyData,
      fusionModuleConfig.certificate.publicFileName
    );
  };

  const config = `hostName=${fusionModuleConfig.awsHost}
    fusorIoTName=${fusionModuleConfig.iotThingName}
    fusorId=${fusionModuleConfig.fusionModuleId}
    sslCA=C:\\FusionModule\\Certificates\\AmazonRootCA1.pem
    sslCert=C:\\FusionModule\\Certificates\\${
      fusionModuleConfig.certificate.certificateFileName
    }
    sslKeyFile=C:\\FusionModule\\Certificates\\${
      fusionModuleConfig.certificate.privateFileName
    }
    logsFolderPath=C:\\FusionModule\\Logs\\`;

  const configMarkdown = `**hostName**=${fusionModuleConfig.awsHost}
    \n**fusorIoTName**=${fusionModuleConfig.iotThingName}
    \n**fusorId**=${fusionModuleConfig.fusionModuleId}
    \n**sslCA**=C:\\FusionModule\\Certificates\\AmazonRootCA1.pem
    \n**sslCert**=C:\\FusionModule\\Certificates\\${
      fusionModuleConfig.certificate.certificateFileName
    }
    \n**sslKeyFile**=C:\\FusionModule\\Certificates\\${
      fusionModuleConfig.certificate.privateFileName
    }
    \n**logsFolderPath**=C:\\FusionModule\\Logs\\`;

  return (
    <TitanDialog
      onClose={onClose}
      maxWidth="md"
      title="Fusion Module config file"
      cancelButtonLabel="Close"
      onAdditionalButton={() => {
        const clipboardConfig = config.replace(/ /g, '');
        copyValueToClipboard(clipboardConfig);
      }}
      additionalButtonLabel="Copy Config"
    >
      <div className={classes.container}>
        <Alert severity="info">
          Copy next config config.ini file of Fusion Module
        </Alert>

        <ReactMarkdown children={configMarkdown} />

        <Alert severity="info">
          Download certificate and key files. You will not able to download
          after close this dialog
        </Alert>

        <div className={classes.certificates}>
          <Button
            variant="outlined"
            color="primary"
            onClick={downloadCertificate}
          >
            certificate.pem.crt
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={downloadPrivateKey}
          >
            private.pem.key
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={downloadPublicKey}
          >
            public.pem.key
          </Button>
        </div>
      </div>
    </TitanDialog>
  );
}
