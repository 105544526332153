import React from 'react';
import TitanDialog from '../Titan/TitanDialog';
import TextField from '@mui/material/TextField';
import REGEXPS from '../../constants/regexps';
import PrinterService from '../../services/PrinterService';
import PrinterModelService from '../../services/PrinterModelService';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OrganizationService from '../../services/OrganizationService';
import { useAuth0 } from '../Authentication/Auth0';
import LabelPrinterService from '../../services/LabelPrinterService';

export default function PrinterDialog(props) {
  const { onClose, onSave, printer } = props;
  const { isSuperAdmin } = useAuth0();

  const [name, setName] = React.useState(printer ? printer.name : '');
  const [nameError, setNameError] = React.useState(false);

  const [printerModelId, setPrinterModelId] = React.useState(
    printer ? printer.printerModelId : ''
  );
  const [printerModels, setPrinterModels] = React.useState([]);
  const [organizationId, setOrganizationId] = React.useState(
    printer ? printer.organizationId : props.organizationId
  );
  const [organizations, setOrganizations] = React.useState([]);
  const [labelPrinterId, setLabelPrinterId] = React.useState(
    printer && printer.labelPrinterId ? printer.labelPrinterId : ''
  );
  const [labelPrinters, setLabelPrinters] = React.useState([]);

  const onChangeName = e => {
    setName(e.target.value);
    setNameError(!REGEXPS.IOT_THING_NAME.test(e.target.value));
  };

  const handleSave = async () => {
    const data = {
      name,
      printerModelId,
      organizationId,
      labelPrinterId
    };

    let printer;

    if (props.printer) {
      printer = await PrinterService.updatePrinter(props.printer.id, data);
    } else {
      printer = await PrinterService.createPrinter(data);
    }

    onSave(printer);
  };

  React.useEffect(() => {
    PrinterModelService.getPrinterModels().then(response =>
      setPrinterModels(response.data)
    );

    if (isSuperAdmin) {
      OrganizationService.getOrganizations().then(response =>
        setOrganizations(response.data)
      );
    }
  }, []);

  React.useEffect(() => {
    if (organizationId) {
      LabelPrinterService.getPrinters({ organizationId }).then(response =>
        setLabelPrinters(response.data)
      );
    } else {
      setLabelPrinters([]);
    }
  }, [organizationId]);

  return (
    <TitanDialog
      title={`${printer ? 'Edit' : 'Add'} Build Module`}
      onClose={onClose}
      onSave={handleSave}
      isValid={
        !nameError && name.length !== 0 && printerModelId && organizationId
      }
      successSaveMessage={`Build Module ${printer ? 'updated' : 'created'}`}
    >
      <TextField
        required
        label="Name"
        placeholder="Name"
        value={name}
        onChange={onChangeName}
        fullWidth
        margin="normal"
        error={nameError}
        helperText="only alphanumeric characters and/or the following: -_:"
      />
      <FormControl required fullWidth margin="dense">
        <InputLabel sx={{ backgroundColor: '#fff', padding: '0 5px' }}>
          Printer model
        </InputLabel>
        <Select
          value={printerModelId}
          onChange={e => setPrinterModelId(e.target.value)}
        >
          {printerModels &&
            printerModels.map(printerModel => (
              <MenuItem key={printerModel.id} value={printerModel.id}>
                {printerModel.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {isSuperAdmin && (
        <FormControl required fullWidth margin="dense">
          <InputLabel>Organization</InputLabel>
          <Select
            value={organizationId}
            onChange={e => setOrganizationId(e.target.value)}
          >
            {organizations &&
              organizations.map(organization => (
                <MenuItem key={organization.id} value={organization.id}>
                  {organization.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
      <FormControl required fullWidth margin="dense">
        <InputLabel sx={{ backgroundColor: '#fff', padding: '0 5px' }}>
          Label Printer
        </InputLabel>
        <Select
          value={labelPrinterId}
          onChange={e => setLabelPrinterId(e.target.value)}
          disabled={!organizationId}
        >
          {labelPrinters &&
            labelPrinters.map(labelPrinter => (
              <MenuItem key={labelPrinter.id} value={labelPrinter.id}>
                {labelPrinter.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </TitanDialog>
  );
}
