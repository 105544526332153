import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Can from '../Authentication/Can';
import { ROUTES } from '../../constants';

export default function TitanRoute({
  component: Component,
  permissions,
  matchAll,
  ...props
}) {
  return (
    <Route
      {...props}
      render={props =>
        permissions ? (
          <Can
            permissions={permissions}
            matchAll={matchAll}
            yes={() => <Component {...props} />}
            no={() => <Redirect to={ROUTES.HOME} />}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}
