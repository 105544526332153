import TitanDialog from '../Titan/TitanDialog';
import { TextField } from '@mui/material';
import React from 'react';

export default function MouldDialog(props) {
  const { onSave, onClose, editableMould } = props;

  const [mouldIdentifier, setMouldIdentifier] = React.useState(
    editableMould && editableMould.mouldIdentifier
      ? editableMould.mouldIdentifier
      : ''
  );
  const [comment, setComment] = React.useState(
    editableMould && editableMould.comment ? editableMould.comment : ''
  );

  const onSubmitForm = () => {
    return onSave({
      mouldIdentifier,
      comment
    });
  };

  return (
    <TitanDialog
      title={`${editableMould.id ? 'Edit' : 'Create'} Mould`}
      maxWidth="xs"
      onClose={onClose}
      onSave={onSubmitForm}
      saveButtonLabel={editableMould.id ? 'Save' : 'Create'}
      isValid={!!mouldIdentifier}
    >
      <TextField
        required
        label="Mould Identifier"
        placeholder="Mould Identifier"
        value={mouldIdentifier}
        onChange={e => setMouldIdentifier(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Comment"
        placeholder="Comment"
        fullWidth
        multiline
        rows={3}
        variant="outlined"
        value={comment}
        onChange={e => setComment(e.target.value)}
        margin="normal"
      />
    </TitanDialog>
  );
}
