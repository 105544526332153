import { FUSOR_TOPICS } from '../../constants';

export const sensorTypeConfig = {
  [FUSOR_TOPICS.TEMPERATURE.toUpperCase()]: {
    title: 'Temperature',
    yMin: 0,
    yMax: 100,
    yUnit: '°C',
    dataKey: FUSOR_TOPICS.TEMPERATURE.toUpperCase(),
    autoAdjustYAxisMax: true,
    isDataKey: key => key.startsWith('temperature')
  },
  [FUSOR_TOPICS.PRESSURE.toUpperCase()]: {
    title: 'Pressure',
    yMin: 0,
    yMax: 10,
    yUnit: 'kN',
    dataKey: FUSOR_TOPICS.PRESSURE.toUpperCase(),
    autoAdjustYAxisMax: true,
    isDataKey: key => ['cylinderForce', 'cylynderForce'].includes(key)
  },
  [FUSOR_TOPICS.POSITION.toUpperCase()]: {
    title: 'Position',
    yMin: -2,
    yMax: 250,
    yUnit: 'mm',
    dataKey: FUSOR_TOPICS.POSITION.toUpperCase(),
    autoAdjustYAxisMax: true,
    isDataKey: key => ['platePosition'].includes(key)
  },
  [FUSOR_TOPICS.PRECISE_POSITION.toUpperCase()]: {
    title: 'Precise Position',
    yMin: -2,
    yMax: 10,
    yUnit: 'mm',
    dataKey: FUSOR_TOPICS.POSITION.toUpperCase(),
    getNull: i => i.y <= 10,
    autoAdjustYAxisMax: false,
    isDataKey: key => ['platePosition'].includes(key)
  }
};
