import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Link } from '@mui/material';
import { ROUTES } from '../../constants';
import PlanService from '../../services/PlanService';
import { makeStyles } from '@mui/styles';
import OverflowTip from '../ExpandRenderCell/OverflowTip';
import { useTitan } from '../Titan/Titan';

const useStyles = makeStyles(theme => ({
  hiddenOverflowBlock: {
    overflow: 'hidden'
  }
}));

export default function FusionPlanLink(props) {
  const {
    fusionPlan,
    fusionPlanId,
    children,
    useName = true,
    width,
    onClick = e => e.stopPropagation(),
    sx = {}
  } = props;
  const location = useLocation();
  const classes = useStyles();

  const { addPageToPageHistory, preparePageObjectForLink } = useTitan();

  const handleClick = e => {
    addPageToPageHistory(
      preparePageObjectForLink(
        'FUSION_PLAN',
        fusionPlan.id,
        fusionPlan.name,
        fusionPlan.id === fusionPlanId
          ? ROUTES.FUSION_PLAN_V2_TAB(fusionPlan.id, 'configuration')
          : ROUTES.FUSION_PLAN_V2(fusionPlan.id),
        'Configuration'
      )
    );

    if (onClick) {
      onClick(e);
    }
  };

  let content;

  if (useName) {
    content = [fusionPlan.name];

    content.push(
      PlanService.formatPlanKey(fusionPlan.planKey, fusionPlan.version)
    );

    if (fusionPlan.deletedAt) {
      content.push('(deleted)');
    }

    content = content.join(' ');
  } else {
    content = children;
  }

  return (
    <Link
      className={classes.hiddenOverflowBlock}
      onClick={handleClick}
      to={{
        pathname:
          fusionPlanId === fusionPlan.id
            ? ROUTES.FUSION_PLAN_V2_TAB(fusionPlan.id, 'configuration')
            : ROUTES.FUSION_PLAN_V2(fusionPlan.id),
        state: { from: location.pathname }
      }}
      component={RouterLink}
      sx={sx}
    >
      {width ? (
        <OverflowTip width={width}>{content}</OverflowTip>
      ) : (
        <span>{content}</span>
      )}
    </Link>
  );
}
