export default {
  OFFLINE: 'OFFLINE',
  ONLINE: 'ONLINE',
  OPERATIONAL: 'OPERATIONAL',
  SCHEDULED: 'SCHEDULED',
  LOAD_TO_BM: 'LOAD_TO_BM',
  LOADED_TO_BM: 'LOADED_TO_BM',
  LOAD_TO_BM_FAILED: 'LOAD_TO_BM_FAILED',
  UNLOAD_FROM_BM: 'UNLOAD_FROM_BM',
  PRE_BUILDING: 'PRE_BUILDING',
  POST_BUILDING: 'POST_BUILDING',
  BUILT: 'BUILT',
  DEFECTED: 'DEFECTED',
  IN_ASSEMBLY: 'IN_ASSEMBLY',
  READY_TO_FUSE: 'READY_TO_FUSE',
  REMAINING: 'REMAINING',
  LOAD_TO_FM: 'LOAD_TO_FM',
  LOADED_TO_FM: 'LOADED_TO_FM',
  LOAD_TO_FM_FAILED: 'LOAD_TO_FM_FAILED',
  UNLOAD_FROM_FM: 'UNLOAD_FROM_FM',
  PRE_FUSING: 'PRE_FUSING',
  FUSING: 'FUSING',
  POST_FUSING: 'POST_FUSING',
  FUSED: 'FUSED',
  POST_CANCELED: 'POST_CANCELED',
  POST_FAILED: 'POST_FAILED',
  BUILD_FAILED: 'BUILD_FAILED',
  BUILD_CANCELING: 'BUILD_CANCELING',
  BUILD_CANCELED: 'BUILD_CANCELED',
  BUILD_PAUSING: 'BUILD_PAUSING',
  BUILD_PAUSED: 'BUILD_PAUSED',
  FUSE_CANCELING: 'FUSE_CANCELING',
  FUSE_CANCELED: 'FUSE_CANCELED',
  FUSE_FAILED: 'FUSE_FAILED',
  FUSE_PAUSED: 'FUSE_PAUSED',
  SCRAPPED: 'SCRAPPED',
  ASSEMBLY_SCRAPPED: 'ASSEMBLY_SCRAPPED',
  UPLOADING_LOG_FILE: 'UPLOADING_LOG_FILE',
  FUSION_PLAN_LOADED: 'FUSION_PLAN_LOADED',
  FUSION_SCRAP: 'FUSION_SCRAP',

  // STATES
  POWER_OFF: 'POWER_OFF',
  IDLE: 'IDLE',
  MAINTENANCE: 'MAINTENANCE',
  BUILD_PREPARATION: 'BUILD_PREPARATION',
  BUILDING: 'BUILDING',
  PAUSED: 'PAUSED',
  POST_BUILD: 'POST_BUILD',
  UNLOADING: 'UNLOADING',
  ERROR: 'ERROR',
  WAIT_FOR_SUPPORT: 'WAIT_FOR_SUPPORT',
  REPAIR: 'REPAIR',
  SUPPORT: 'SUPPORT',
  OFFLINE_AFTER_ERROR: 'OFFLINE_AFTER_ERROR'
};
