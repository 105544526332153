import React from 'react';
import OdooService from '../../services/OdooService';
import TitanDialog from '../Titan/TitanDialog';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import Link from '@mui/material/Link';

export default function SelectOdooProjectDialog({
  title,
  saveButtonLabel,
  multiSelect = false,
  onSelect,
  onClose
}) {
  const [selectedProjects, setSelectedProjects] = React.useState([]);

  const loadData = React.useCallback(
    async ({ page, pageSize, search }, config) => {
      const params = {
        page: page,
        pageSize: pageSize
      };

      if (search) {
        params.search = search;
      }

      const { data, pagination } = await OdooService.getOdooProjects(
        params,
        config
      );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount
      };
    },
    []
  );

  const titanDataGridProps = useTitanDataGrid(loadData);

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Odoo project',
        field: 'name',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <Link href={row.odooProductPageUrl} target="_blank">
            {row.name}
          </Link>
        )
      }
    ],
    []
  );

  return (
    <TitanDialog
      title={title}
      maxWidth="lg"
      onClose={onClose}
      cancelButtonLabel="Close"
      isValid={selectedProjects.length > 0}
      onSave={() => onSelect(selectedProjects)}
      saveButtonLabel={saveButtonLabel}
    >
      <TitanDataGrid
        searchPlaceholder="Search by Odoo project name..."
        {...titanDataGridProps}
        columns={columns}
        onClose={onClose}
        onSelectionModelChange={selectedProjects =>
          setSelectedProjects(selectedProjects)
        }
        selectedRows={selectedProjects}
        checkboxSelection
        checkboxMultiSelection={multiSelect}
        isRowSelectable={({ row }) => !row.fpProjectId}
      />
    </TitanDialog>
  );
}
