import React from 'react';
import TitanTableEmptyData from '../TitanTable/TitanTableEmptyData';
import ContainerDimensions from 'react-container-dimensions';
import {
  XYPlot,
  LineSeries,
  Borders,
  XAxis,
  YAxis,
  DiscreteColorLegend,
  HorizontalGridLines,
  VerticalGridLines,
} from 'react-vis';
import '../../../node_modules/react-vis/dist/style.css';
import { colors } from '../Theme/vars';
import nanoid from 'nanoid';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(moment);

const strokeColors = [
  colors.RED,
  colors.GREEN,
  colors.VIOLET,
  colors.YELLOW,
  colors.PINK,
  colors.DARK_BLUE,
  colors.ORANGE,
  colors.BLUE,
  colors.ORANGE2,
  colors.LIGHT_GREEN,
  colors.PINK,
  colors.GREY,
  colors.AMBER,
  colors.TEAL,
  colors.DEEP_PURPLE,
  colors.LIGHT_CYAN,
  colors.DARK_CYAN,
  colors.DARK_RED,
];

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
  },
  chartWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      height: 650,
    },
    [theme.breakpoints.up('lg')]: {
      height: 620,
    },
  },
}));

export default function TitanChart({
  actual,
  target,
  legendLabels,
  legendColors,
  showedArray,
  setLegendLabels,
  setLegendColors,
  min = 0,
  max = 100,
  yUnit = '°C',
  timeDirection = 'forward',
  sortLegendLabels = false,
  getNull,
  autoAdjustYAxisMax = true,
  duration = 30,
}) {
  const classes = useStyles();

  const [actualSeries, setActualSeries] = React.useState();
  const [targetSeries, setTargetSeries] = React.useState();
  const [xDomain, setXDomain] = React.useState();
  const [yDomain, setYDomain] = React.useState([min, max]);
  const [durationFormat, setDurationFormat] = React.useState('m [min] s [s]');

  React.useEffect(() => {
    const startTime = new Date(new Date().getTime() - duration * 60 * 1000);
    const endTime = new Date(new Date().getTime() + 0.5 * 60 * 1000);

    let minTime;
    let maxTime;
    let minValue = min;
    let maxValue = max;

    const setMinAndMaxParams = (data) => {
      data.forEach((item) => {
        minValue = Math.min(minValue, item.y);
        maxValue = Math.max(maxValue, item.y);

        minTime = new Date(
          Math.min(
            minTime ? minTime.getTime() : new Date(item.x).getTime(),
            new Date(item.x).getTime(),
          ),
        );
        maxTime = new Date(
          Math.max(
            maxTime ? maxTime.getTime() : new Date(item.x).getTime(),
            new Date(item.x).getTime(),
          ),
        );
      });
    };

    let actualSeriesTemp = [];
    let targetSeriesTemp = [];

    if (actual) {
      actualSeriesTemp = actual
        .sort((a, b) => (a.label > b.label ? 1 : -1))
        .map((series, index) => {
          if (series.data.length) {
            setMinAndMaxParams(series.data);
          }

          return {
            ...series,
            key: nanoid(),
            color: strokeColors[index],
          };
        });
      setActualSeries(actualSeriesTemp);
    }

    if (target) {
      targetSeriesTemp = target
        .sort((a, b) => (a.label > b.label ? 1 : -1))
        .map((series, index) => {
          if (series.data.length) {
            setMinAndMaxParams(series.data);
          }

          return {
            ...series,
            key: nanoid(),
            color: strokeColors[actual.length + index],
          };
        });
      setTargetSeries(targetSeriesTemp);
    }

    let xDomain;

    if (timeDirection === 'forward') {
      xDomain = [minTime || startTime, maxTime || endTime];
    } else {
      xDomain = [
        minTime && minTime.getTime() < startTime.getTime()
          ? minTime
          : startTime,
        maxTime && maxTime.getTime() > endTime.getTime() ? maxTime : endTime,
      ];
    }
    setXDomain(xDomain);
    if (autoAdjustYAxisMax) {
      setYDomain([minValue, maxValue + 10]);
    }
    setDurationFormat(
      Math.abs(xDomain[0].getTime() - xDomain[1].getTime()) > 600000
        ? 'm [m]'
        : 'm [m] s [s]',
    );
    const legendLabels = actualSeriesTemp
      .sort(
        (a, b) => Number(a.label.match(/\d+/)) - Number(b.label.match(/\d+/)),
      )
      .map((s) => s?.label)
      .concat(targetSeriesTemp.map((s) => s?.label));

    const legendColors = actualSeriesTemp
      .sort(
        (a, b) => Number(a.label.match(/\d+/)) - Number(b.label.match(/\d+/)),
      )
      .map((s) => s?.color)
      .concat(targetSeriesTemp.map((s) => s?.color));

    if (
      setLegendLabels &&
      setLegendColors &&
      actual.length > 0 &&
      actualSeriesTemp.length !== actualSeries?.length
    ) {
      setLegendLabels(legendLabels);
      setLegendColors(legendColors);
    }
  }, [actual, target]);

  const filterFunction = React.useCallback(
    (series) => {
      const showedValueFiltred = showedArray.find(
        (showedSeries) => showedSeries.label === series.label,
      );
      if (showedValueFiltred) {
        return showedValueFiltred.showed;
      }
      return null;
    },
    [showedArray],
  );

  return (
    <div className={classes.container}>
      <div className={classes.chartWrapper}>
        {!actualSeries ||
        actualSeries?.every((s) => s.data?.length === 0 || s === null) ||
        actualSeries
          .filter((series) => filterFunction(series))
          .every((s) => s.data?.length === 0) ||
        actualSeries.filter((series) => filterFunction(series)).length === 0 ? (
          <TitanTableEmptyData />
        ) : (
          <ContainerDimensions>
            {({ width }) => (
              <XYPlot
                width={width}
                height={560}
                xType="time"
                xDomain={xDomain}
                yDomain={yDomain}
                margin={{ left: 70, bottom: 50 }}
              >
                {actualSeries &&
                  actualSeries
                    .filter((series) => filterFunction(series))
                    .map((series) => {
                      if (series) {
                        return (
                          <LineSeries
                            key={series.key}
                            data={series.data}
                            stroke={series.color}
                            getNull={getNull}
                            style={{
                              strokeWidth: 3,
                            }}
                          />
                        );
                      }
                      return null;
                    })}

                <Borders
                  style={{
                    bottom: { fill: colors.WHITE },
                    left: { fill: colors.WHITE },
                    right: { fill: colors.WHITE },
                    top: { fill: colors.WHITE },
                  }}
                />

                {targetSeries &&
                  targetSeries
                    .filter((series) => filterFunction(series))
                    .map((series) => (
                      <LineSeries
                        key={series.key}
                        data={series.data}
                        stroke={series.color}
                        strokeDasharray="7, 3"
                      />
                    ))}
                <XAxis
                  tickFormat={(value) => {
                    const duration =
                      timeDirection === 'forward'
                        ? -(xDomain[0].getTime() - value)
                        : new Date().getTime() - value;

                    return moment
                      .duration(duration, 'milliseconds')
                      .format(durationFormat);
                  }}
                  tickLabelAngle={-45}
                />
                <YAxis tickFormat={(value) => `${value} ${yUnit}`} />
                <DiscreteColorLegend
                  style={{ paddingLeft: '50px' }}
                  items={legendLabels}
                  colors={legendColors}
                  orientation="horizontal"
                />
                <VerticalGridLines />
                <HorizontalGridLines />
              </XYPlot>
            )}
          </ContainerDimensions>
        )}
      </div>
    </div>
  );
}
