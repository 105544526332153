import React from 'react';
import { useParams } from 'react-router-dom';
import { useTitan } from '../Titan/Titan';
import { useAuth0 } from '../Authentication/Auth0';
import { Box, IconButton, Link } from '@mui/material';
import { format } from 'date-fns';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import TitanDataGridToolbar from '../TitanDataGrid/TitanDataGridToolbar';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { CustomGridColumnsPanel } from '../TitanDataGrid/TitanDataGridColumnsPanel';
import ManufacturingOrderDialog from './ManufacturingOrderDialog';
import ManufacturingOrderSelectionDialog from '../Fibrify/FibrifyManufacturingOrderSelectionDialog';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import ManufacturingOrderLink from './ManufacturingOrderLink';
import ManufacturingOrderService from '../../services/ManufacturingOrderService';
import { SORT_ORDERS, STYLING_FUNCTIONS } from '../../constants';
import AddLinkIcon from '@mui/icons-material/AddLink';
import ManufacturingOrderState from './ManufacturingOrderState';
import ProjectLink from '../Projects/ProjectLink';

export default function ManufacturingOrdersTable({
  apiRef,
  projectIds,
  states,
  manufacturingOrderType,
  manufacturingOrderProgress,
  assigneeIds,
  buildPlan,
  fusionPlan,
  createManufacturingOrderButton = false,
  connectToManufacturingOrderButton = false,
  connectToManufacturingOrderButtonDisabled = false,
  connectToManufacturingOrderButtonDisabledTooltip = '',
  setManufacturingOrderToLinkWithOdoo,
  hiddenColumns,
  filtersContent,
  chipsContent,
}) {
  const { page: pageParam = 0 } = useParams();
  const {
    profile,
    currentMember,
    isOrganizationAdmin,
    isApplicationEngineer,
    roles,
  } = useAuth0();

  const projectId =
    projectIds && projectIds.length === 1 ? projectIds[0] : null;

  const readOnlyState = React.useMemo(() => {
    const hasWriteAccess = isOrganizationAdmin || isApplicationEngineer;

    const isOwner = buildPlan
      ? currentMember.id === buildPlan?.memberId
      : currentMember.id === fusionPlan?.memberId;

    return !(hasWriteAccess || isApplicationEngineer) && !isOwner;
  }, [
    buildPlan,
    fusionPlan,
    currentMember.id,
    isOrganizationAdmin,
    isApplicationEngineer,
  ]);

  const [editableManufacturingOrder, setEditableManufacturingOrder] =
    React.useState({});

  const [openCreateEditDialog, setOpenCreateEditDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openConnectToPlanDialog, setOpenConnectToPlanDialog] =
    React.useState(false);
  const [openDisconnectFromPlanDialog, setOpenDisconnectFromPlanDialog] =
    React.useState(false);

  const { pushSnackbar, odooIntegrationEnabled } = useTitan();

  const loadData = React.useCallback(
    async (params, config) => {
      const { data, pagination } =
        await ManufacturingOrderService.getManufacturingOrders(
          {
            ...params,
            ...(projectIds && { projectIds }),
            ...(states && { states }),
            ...(manufacturingOrderType !== 'all'
              ? { manufacturingOrderType }
              : {}),
            ...(manufacturingOrderProgress !== 'all'
              ? { manufacturingOrderProgress }
              : {}),
            ...(buildPlan && { buildPlanIds: [buildPlan.id] }),
            ...(fusionPlan && { fusionPlanIds: [fusionPlan.id] }),
            ...(assigneeIds && { assigneeIds }),
          },
          config,
        );

      return {
        data,
        page: pagination.page - 1,
        totalCount: pagination.totalCount,
      };
    },
    [
      projectIds,
      buildPlan,
      fusionPlan,
      states,
      manufacturingOrderType,
      manufacturingOrderProgress,
      assigneeIds,
    ],
  );

  const columns = React.useMemo(() => {
    const columns = [
      {
        headerName: 'Name',
        field: 'name',
        hideable: false,
        flex: 1,
        minWidth: 250,
        sortable: false,
        visibilityBreakpoint: 'xs',
        renderCell: ({ row }) => (
          <ManufacturingOrderLink manufacturingOrder={row} />
        ),
      },
      {
        headerName: 'State',
        field: 'state',
        minWidth: 140,
        sortable: false,
        visibilityBreakpoint: 'xs',
        renderCell: ({ row }) => (
          <ManufacturingOrderState manufacturingOrder={row} />
        ),
      },
      {
        headerName: 'Project',
        field: 'project',
        hideable: false,
        flex: 1,
        minWidth: 250,
        sortable: false,
        visibilityBreakpoint: 'xs',
        hide: hiddenColumns?.includes('project'),
        renderCell: ({ row }) =>
          row.project ? <ProjectLink project={row.project} /> : '',
      },
      {
        headerName: 'Order Key',
        field: 'orderKey',
        minWidth: 140,
        sortable: false,
        visibilityBreakpoint: 'xs',
      },
      {
        headerName: 'Preforms',
        field: 'preformsCount',
        minWidth: 120,
        visibilityBreakpoint: 'lg',
        sortable: false,
        renderCell: ({ row }) =>
          row.preformsCount
            ? `${row.totalProducedPreforms || 0}/${row.preformsCount}`
            : '',
      },
      {
        headerName: 'Final Parts',
        field: 'finalPartsCount',
        minWidth: 120,
        visibilityBreakpoint: 'lg',
        sortable: false,
        renderCell: ({ row }) =>
          row.finalPartsCount
            ? `${row.totalProducedFinalParts || 0}/${row.finalPartsCount}`
            : '',
      },
      {
        headerName: 'Start Date',
        field: 'startDate',
        minWidth: 150,
        sortable: false,
        visibilityBreakpoint: 'md',
        renderCell: ({ row }) =>
          row.startDate ? format(new Date(row.startDate), 'yyyy-MM-dd') : null,
      },
      {
        headerName: 'Finish Date',
        field: 'finishDate',
        minWidth: 150,
        sortable: false,
        visibilityBreakpoint: 'md',
        renderCell: ({ row }) =>
          row.finishDate
            ? format(new Date(row.finishDate), 'yyyy-MM-dd')
            : null,
      },
    ];

    if (odooIntegrationEnabled) {
      columns.push({
        headerName: 'Odoo',
        field: 'odooManufacturingOrderName',
        minWidth: 180,
        visibilityBreakpoint: 'lg',
        sortable: false,
        hide: hiddenColumns?.includes('odoo'),
        renderCell: ({ row }) => {
          if (row.odooManufacturingOrderPageUrl) {
            return readOnlyState ? (
              row.odooManufacturingOrderName
            ) : (
              <Link
                href={row.odooManufacturingOrderPageUrl}
                target="_blank"
                rel="noopener"
              >
                {row.odooManufacturingOrderName}
              </Link>
            );
          } else if (setManufacturingOrderToLinkWithOdoo) {
            return readOnlyState ? null : (
              <IconButton
                onClick={() => setManufacturingOrderToLinkWithOdoo(row)}
              >
                <AddLinkIcon />
              </IconButton>
            );
          }
        },
      });
    }

    if (!readOnlyState) {
      columns.push({
        headerName: '',
        field: 'actions',
        type: 'actions',
        width: 120,
        sortable: false,
        hideInMenu: true,
        visibilityBreakpoint: 'sm',
        getActions: (params) =>
          [
            ...ManufacturingOrderService.getManufacturingOrderActions({
              manufacturingOrder: params.row,
              currentMember: currentMember,
              memberRoles: roles,
              onEdit: () => {
                setEditableManufacturingOrder(params.row);
                setOpenCreateEditDialog(true);
              },
              onDelete: () => {
                setEditableManufacturingOrder(params.row);
                setOpenDeleteDialog(true);
              },
              ...((buildPlan || fusionPlan) && {
                plan: buildPlan || fusionPlan,
              }),
              ...((buildPlan || fusionPlan) && {
                onDetachPlan: () => {
                  setEditableManufacturingOrder(params.row);
                  setOpenDisconnectFromPlanDialog(true);
                },
              }),
            }),
          ].map((action) => (
            <GridActionsCellItem
              icon={action.icon}
              label={action.label}
              onClick={action.onClick}
              disabled={action.disabled}
              showInMenu={
                !action.label || action.showInMenu === undefined
                  ? true
                  : action.showInMenu
              }
            />
          )),
      });
    }

    return columns;
  }, [profile, buildPlan, fusionPlan, readOnlyState, hiddenColumns, projectId]);

  const gridOptionsObject = {
    orders: {
      updated_at: SORT_ORDERS.DESC,
    },
    columns,
    pinnedColumns: {
      left: ['name'],
      right: ['actions'],
    },
  };

  if (pageParam && Number(pageParam) > 0) {
    gridOptionsObject.page = Number(pageParam);
  }

  const titanDataGridProps = useTitanDataGrid(loadData, gridOptionsObject);
  const { setRows } = titanDataGridProps;

  const addManufacturingOrder = React.useCallback(
    (mo) => setRows((prev) => [mo, ...prev]),
    [setRows],
  );

  const updateManufacturingOrder = React.useCallback(
    (mo) =>
      setRows((prev) =>
        prev.map((bp) => (bp.id === mo.id ? { ...bp, ...mo } : bp)),
      ),
    [setRows],
  );

  const onCreateManufacturingOrder = React.useCallback(
    async (data) => {
      const createdManufacturingOrder =
        await ManufacturingOrderService.createManufacturingOrder({
          ...data,
        });

      setOpenCreateEditDialog(false);
      setEditableManufacturingOrder(null);

      addManufacturingOrder(createdManufacturingOrder);
    },
    [addManufacturingOrder],
  );

  const onUpdateManufacturingOrder = React.useCallback(
    async (data) => {
      const updatedManufacturingOrder =
        await ManufacturingOrderService.updateManufacturingOrder(
          editableManufacturingOrder?.id,
          {
            ...data,
          },
        );

      setOpenCreateEditDialog(false);
      setEditableManufacturingOrder(null);

      updateManufacturingOrder(updatedManufacturingOrder);
    },
    [editableManufacturingOrder?.id, updateManufacturingOrder],
  );

  const onDeleteManufacturingOrder = React.useCallback(async () => {
    await ManufacturingOrderService.deleteManufacturingOrder(
      editableManufacturingOrder?.id,
    );

    pushSnackbar('Manufacturing Order successfully deleted', {
      variant: 'success',
    });

    setOpenDeleteDialog(false);
    setEditableManufacturingOrder(null);

    setRows((prev) =>
      prev.filter((mo) => mo.id !== editableManufacturingOrder.id),
    );
  }, [editableManufacturingOrder?.id, pushSnackbar, setRows]);

  const onConnectingPlanToManufacturingOrders = React.useCallback(
    async (manufacturingOrders) => {
      await Promise.allSettled(
        manufacturingOrders.map((manufacturingOrder) => {
          if (buildPlan) {
            return ManufacturingOrderService.attachBuildPlan(
              manufacturingOrder.id,
              buildPlan.id,
            );
          } else if (fusionPlan) {
            return ManufacturingOrderService.attachFusionPlan(
              manufacturingOrder.id,
              fusionPlan.id,
            );
          }
          return null;
        }),
      );

      manufacturingOrders.forEach((mo) => addManufacturingOrder(mo));
    },
    [buildPlan, fusionPlan, addManufacturingOrder],
  );

  const onDisconnectingPlanFromManufacturingOrder =
    React.useCallback(async () => {
      if (buildPlan) {
        await ManufacturingOrderService.detachBuildPlan(
          editableManufacturingOrder?.id,
          buildPlan.id,
        );
      } else if (fusionPlan) {
        await ManufacturingOrderService.detachFusionPlan(
          editableManufacturingOrder?.id,
          fusionPlan.id,
        );
      }

      setRows((prev) =>
        prev.filter((mo) => mo.id !== editableManufacturingOrder?.id),
      );

      setOpenDisconnectFromPlanDialog(false);
      setEditableManufacturingOrder(null);
    }, [editableManufacturingOrder?.id, buildPlan, fusionPlan, setRows]);

  React.useEffect(() => {
    if (apiRef) {
      apiRef.current = titanDataGridProps.apiRef.current;
      apiRef.current.reloadData = titanDataGridProps.reloadData;
    }
  }, []);

  return (
    <Box>
      <TitanDataGrid
        {...titanDataGridProps}
        components={{
          Toolbar: TitanDataGridToolbar,
          ColumnsPanel: CustomGridColumnsPanel,
        }}
        getRowHeight={STYLING_FUNCTIONS.MAKE_ROW_HEIGHT(110)}
        searchPlaceholder="Search by Manufacturing Order name..."
        {...(createManufacturingOrderButton &&
          !readOnlyState && {
            onCreateClick: () => setOpenCreateEditDialog(true),
            createButtonLabel: 'Manufacturing Order',
          })}
        {...(connectToManufacturingOrderButton &&
          !readOnlyState && {
            onCreateClick: () => setOpenConnectToPlanDialog(true),
            createButtonLabel: 'Connect to Manufacturing Order',
          })}
        onCreateClickDisabled={connectToManufacturingOrderButtonDisabled}
        onCreateClickDisabledTooltip={
          connectToManufacturingOrderButtonDisabledTooltip
        }
        filtersContent={filtersContent}
        chipsContent={chipsContent}
      />

      {openCreateEditDialog && (
        <ManufacturingOrderDialog
          projectId={projectId}
          manufacturingOrder={editableManufacturingOrder}
          profile={profile}
          onCreate={onCreateManufacturingOrder}
          onUpdate={onUpdateManufacturingOrder}
          onClose={() => {
            setOpenCreateEditDialog(false);
            setEditableManufacturingOrder(null);
          }}
        />
      )}

      {openDeleteDialog && (
        <TitanConfirmationDialog
          title="Delete Manufacturing Order?"
          message="Are you sure you want to delete this Manufacturing Order?"
          onClose={() => {
            setOpenDeleteDialog(false);
            setEditableManufacturingOrder(null);
          }}
          onConfirm={() => onDeleteManufacturingOrder()}
        />
      )}

      {openConnectToPlanDialog && (
        <ManufacturingOrderSelectionDialog
          title={`Select Manufacturing Orders you want to connect this ${
            buildPlan ? 'Build' : 'Fusion'
          } Plan to`}
          saveButtonLabel="Connect"
          multipleSelection
          filters={{
            ...(buildPlan && {
              excludeBuildPlanIds: [buildPlan.id],
              manufacturingOrderType: 'preform',
            }),
            ...(buildPlan?.projectId && {
              projectIds: [buildPlan.projectId],
            }),
            ...(fusionPlan && {
              excludeFusionPlanIds: [fusionPlan.id],
              manufacturingOrderType: 'finalPart',
            }),
            ...(fusionPlan?.projectId && {
              projectIds: [fusionPlan.projectId],
            }),
          }}
          onSave={onConnectingPlanToManufacturingOrders}
          onClose={() => setOpenConnectToPlanDialog(false)}
        />
      )}

      {openDisconnectFromPlanDialog && (
        <TitanConfirmationDialog
          title={`Disconnect this Manufacturing Order from ${
            buildPlan ? 'Build' : 'Fusion'
          } Plan?`}
          message={`Are you sure you want to disconnect this Manufacturing Order from ${
            buildPlan ? 'Build' : 'Fusion'
          } Plan?`}
          onConfirm={onDisconnectingPlanFromManufacturingOrder}
          onClose={() => {
            setOpenDisconnectFromPlanDialog(false);
            setEditableManufacturingOrder({});
          }}
        />
      )}
    </Box>
  );
}
