import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '../Authentication/Auth0';
import FusionPlanConfigurationTab from './FusionPlanConfigurationTab';
import FusionPlanHistoryTab from './FusionPlanHistoryTab';
import FinalPartsTable from '../FinalPart/FinalPartsTable';
import FusionJobsTable from '../FusionJob/FusionJobsTable';
import ManufacturingOrdersTable from '../ManufacturingOrders/ManufacturingOrdersTable';
import { useFusionPlan } from './FusionPlanPage';
import TitanTabs from '../Titan/TitanTabs';
import {
  COMPONENT_ACTIVE_STATUSES,
  COMPONENT_STATUSES,
  ROUTES,
} from '../../constants';
import FusionPlanFinalPartTypes from './FusionPlanFinalPartTypes';
import MetabaseComponent from '../Metabase/MetabaseComponent';

export default function FusionPlanContent({
  fusionPlanId,
  tab,
  addPageToPageHistory,
}) {
  const history = useHistory();
  const { fusionPlan } = useFusionPlan();

  const { isOrganizationAdmin, isApplicationEngineer } = useAuth0();

  const pushHistory = React.useCallback(
    (tabName, settedPage) => {
      history.push(
        ROUTES.FUSION_PLAN_V2_TAB_PAGE(fusionPlanId, tabName, settedPage),
      );
    },
    [fusionPlanId],
  );

  const fusionJobsStatuses = React.useMemo(
    () => [COMPONENT_STATUSES.SCHEDULED, ...COMPONENT_ACTIVE_STATUSES],
    [],
  );

  const preparePageObject = (tabForURL, tab) => {
    return {
      id: `FUSION_PLAN:${fusionPlanId}`,
      url:
        tabForURL === 'fusionJobs' || tabForURL === 'finalParts'
          ? ROUTES.FUSION_PLAN_V2_TAB_PAGE(fusionPlanId, tabForURL, 0)
          : ROUTES.FUSION_PLAN_V2_TAB(fusionPlanId, tabForURL),
      label: `${fusionPlan.name || 'Fusion Plan'} | ${tab}`,
    };
  };

  const setNewPageForTab = React.useCallback(
    (tabName, labelName, settedPage) => {
      if (fusionPlanId) {
        const newRoute = ROUTES.FUSION_PLAN_V2_TAB_PAGE(
          fusionPlanId,
          tabName,
          0,
        );
        addPageToPageHistory({
          id: `FUSION_PLAN:${fusionPlanId}`,
          url: newRoute,
          label:
            settedPage === 0
              ? `${
                  fusionPlan.name ? fusionPlan.name : `Fusion Plan`
                } | ${labelName}`
              : `${
                  fusionPlan.name ? fusionPlan.name : `Fusion Plan`
                } | ${labelName} | Page : ${settedPage + 1}`,
        });
      }
    },
    [fusionPlan],
  );

  const tabs = React.useMemo(
    () => [
      {
        label: 'Configuration',
        value: 'configuration',
        content: <FusionPlanConfigurationTab />,
      },
      {
        label: 'Final Part Types',
        value: 'final-part-types',
        content: <FusionPlanFinalPartTypes />,
      },
      {
        label: 'Fusion Jobs',
        value: 'fusionJobs',
        content: (
          <FusionJobsTable
            fusionPlan={fusionPlan}
            statuses={fusionJobsStatuses}
            hiddenColumns={['fusionPlan']}
          />
        ),
      },
      {
        label: 'Final Parts',
        value: 'finalParts',
        content: (
          <FinalPartsTable
            fusionPlanId={fusionPlan.id}
            rootFusionPlanId={fusionPlan.root}
            setNewPageForTab={setNewPageForTab}
            pushHistory={pushHistory}
          />
        ),
      },
      {
        label: 'Manufacturing Orders',
        value: 'manufacturing-orders',
        disabled: !fusionPlan.isApproved,
        disabledMessage: 'Fusion Plan is not approved',
        content: (
          <ManufacturingOrdersTable
            fusionPlan={fusionPlan}
            fusionPlanId={fusionPlan.id}
            fusionPlansProjectId={fusionPlan.projectId}
            connectToManufacturingOrderButton={
              isOrganizationAdmin || isApplicationEngineer
            }
            connectToManufacturingOrderButtonDisabled={!fusionPlan.projectId}
            connectToManufacturingOrderButtonDisabledTooltip={
              !fusionPlan.projectId
                ? 'Add Fusion Plan to the Project first'
                : ''
            }
          />
        ),
      },
      {
        label: 'History',
        value: 'history',
        content: <FusionPlanHistoryTab />,
      },
      ...(fusionPlan.metabaseUrl
        ? [
            {
              label: 'Statistics',
              value: 'statistics',
              content: (
                <MetabaseComponent metabaseUrl={fusionPlan.metabaseUrl} />
              ),
            },
          ]
        : []),
    ],
    [fusionPlan],
  );

  return (
    <TitanTabs
      tabs={tabs}
      activeTab={tab}
      onChangeTab={(value) => {
        const activeLabel = tabs.find((tab) => tab.value === value)?.label;
        addPageToPageHistory(preparePageObject(value, activeLabel));
        history.push(
          value === 'fusionJobs' || value === 'finalParts'
            ? ROUTES.FUSION_PLAN_V2_TAB_PAGE(fusionPlanId, value, 0)
            : ROUTES.FUSION_PLAN_V2_TAB(fusionPlanId, value),
        );
      }}
    />
  );
}
