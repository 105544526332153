import React from 'react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ProfileService from '../../services/ProfileService';
import { useTitan } from '../Titan/Titan';
import TitanPage from '../Titan/TitanPage';
import { useAuth0 } from '../Authentication/Auth0';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  resend: {
    marginRight: theme.spacing(2)
  },
  text1: {
    fontSize: '20px'
  },
  text2: {
    fontSize: '18px'
  }
}));

export default function VerifyEmail() {
  const classes = useStyles();
  const titan = useTitan();
  const { user } = useAuth0();

  const resendValidationEmail = async () => {
    const result = await ProfileService.resendVerificationEmail();

    if (result.isSent) {
      titan.pushSnackbar('Verification email sent was to your email', {
        variant: 'success'
      });
    } else {
      titan.pushSnackbar('Something went wring. Try later', {
        variant: 'error'
      });
    }
  };

  return (
    <TitanPage title="Verify email">
      <Paper>
        <Box textAlign="center" p={10}>
          <Box py={1}>
            <Typography variant="body1" className={classes.text1}>
              We sent you a verification email to {user ? user.email : ''}.
            </Typography>
          </Box>
          <Box py={1}>
            <Typography variant="body1" className={classes.text2}>
              Please confirm your email clicking "Confirm my account" link
            </Typography>
          </Box>
          <Box py={1}>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              onClick={resendValidationEmail}
              className={classes.resend}
            >
              Re-send verification email
            </Button>

            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={() => {
                window.location.reload();
              }}
            >
              Email verified
            </Button>
          </Box>
        </Box>
      </Paper>
    </TitanPage>
  );
}
