import axios from 'axios';

export default class FusorService {
  static async getFusors(params = {}, config = {}) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/fusors`, {
        ...config,
        params
      })
      .then(res => res.data);
  }

  static getFusor(fusorId) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/fusors/${fusorId}`)
      .then(res => res.data.data);
  }

  static createFusor(data) {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/fusors`, data)
      .then(res => res.data.data);
  }

  static updateFusor(fusorId, data) {
    return axios
      .patch(`${process.env.REACT_APP_API_URL}/api/v1/fusors/${fusorId}`, data)
      .then(res => res.data.data);
  }

  static deleteFusor(fusorId) {
    return axios
      .delete(`${process.env.REACT_APP_API_URL}/api/v1/fusors/${fusorId}`)
      .then(res => res.data.data);
  }

  static createNewCertificate(fusorId) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/fusors/${fusorId}/create-new-certificate`,
        {}
      )
      .then(res => res.data);
  }

  static getFusionModuleTelemetryData(fusorId, params) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/fusors/${fusorId}/telemetry`,
        { params }
      )
      .then(res => res.data.data);
  }

  static startMaintenance(fusionModuleId, data = {}) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/fusors/${fusionModuleId}/maintenance/start`,
        data
      )
      .then(res => res.data);
  }

  static finishMaintenance(fusionModuleId, data = {}) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/fusors/${fusionModuleId}/maintenance/finish`,
        data
      )
      .then(res => res.data);
  }

  static getFusionModuleStates(fusionModuleId, params = {}) {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/fusors/${fusionModuleId}/states`,
        {
          params
        }
      )
      .then(res => res.data);
  }

  static updateFusionModuleState(fusionModuleId, timestamp, data) {
    return axios
      .patch(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/fusors/${fusionModuleId}/states/${timestamp}`,
        data
      )
      .then(res => res.data);
  }

  static getFusionModuleMoulds(fusionModuleId, params = {}, config = {}) {
    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/fusion-modules/${fusionModuleId}/moulds`,
        {
          ...config,
          params
        }
      )
      .then(res => res.data);
  }

  static loadMouldToFusionModule(fusionModuleId, mouldId, config = {}) {
    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/fusion-modules/${fusionModuleId}/moulds`,
        { mouldId },
        config
      )
      .then(res => res.data);
  }

  static unloadMouldFromFusionModule(
    fusionModuleId,
    fusionModuleMouldId,
    config = {}
  ) {
    return axios
      .put(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/fusion-modules/${fusionModuleId}/moulds/${fusionModuleMouldId}`,
        {},
        config
      )
      .then(res => res.data);
  }
}
