import React from 'react';
import Button from '@mui/material/Button';
import { ROUTES } from '../../constants';
import { useHistory } from 'react-router-dom';

const useComparableFusionPlans = () => {
  const history = useHistory();

  const [comparablePlans, setComparablePlans] = React.useState(() => {
    let comparablePlanIds = [];

    try {
      const savedItems = localStorage.getItem('comparableFusionPlans');
      comparablePlanIds = savedItems ? JSON.parse(savedItems) : [];
    } catch (e) {
      console.log(`Can't parse comparableFusionPlans`);
    }

    return comparablePlanIds;
  });

  const toggleComparablePlan = React.useCallback(
    (plan) => {
      if (comparablePlans.includes(plan.id)) {
        setComparablePlans((prev) => {
          return prev.filter(
            (comparablePlanId) => comparablePlanId !== plan.id,
          );
        });
      } else {
        setComparablePlans((prev) => [...prev, plan.id]);
      }
    },
    [comparablePlans],
  );

  const clearComparablePlans = React.useCallback(() => {
    setComparablePlans([]);
  }, []);

  const renderCompareButton = React.useCallback(() => {
    if (comparablePlans && comparablePlans.length === 0) {
      return '';
    }

    return (
      <Button
        variant="outlined"
        color="primary"
        disabled={comparablePlans.length === 1}
        onClick={() => {
          history.push({
            pathname: ROUTES.PLANS_COMPARSION,
          });
        }}
        sx={{ marginLeft: 1 }}
      >
        Compare (<span>{comparablePlans.length}</span>) Plans
      </Button>
    );
  }, [comparablePlans]);

  const renderClearButton = React.useCallback(() => {
    if (comparablePlans && comparablePlans.length === 0) {
      return '';
    }

    return (
      <Button
        variant="outlined"
        color="secondary"
        onClick={clearComparablePlans}
        sx={{ marginLeft: 1 }}
      >
        Clear Selection
      </Button>
    );
  }, [comparablePlans, clearComparablePlans]);

  React.useEffect(() => {
    localStorage.setItem(
      'comparableFusionPlans',
      JSON.stringify(comparablePlans),
    );
  }, [comparablePlans]);

  return {
    comparablePlans,
    setComparablePlans,
    toggleComparablePlan,
    clearComparablePlans,
    renderCompareButton,
    renderClearButton,
  };
};

export default useComparableFusionPlans;
