import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { useTitan } from '../Titan/Titan';

export default function BuildJobLink(props) {
  const {
    buildJob,
    children,
    useName = true,
    disabled = false,
    onClick = e => e.stopPropagation()
  } = props;
  const location = useLocation();

  const { addPageToPageHistory, preparePageObjectForLink } = useTitan();

  const handleClick = e => {
    addPageToPageHistory(
      preparePageObjectForLink(
        'BUILD_JOB',
        buildJob.id,
        buildJob.jobKey || `Build Job`,
        ROUTES.BUILD_JOB_TAB(buildJob.id, 'summary'),
        'Summary',
        { buildPlan: buildJob?.buildPlan }
      )
    );

    if (onClick) {
      onClick(e);
    }
  };

  const content = useName
    ? buildJob.jobKey
      ? buildJob.jobKey
      : `Build Job ${buildJob.id.split('-')[0]}`
    : children;

  if (disabled || buildJob.hasReadAccess === false) {
    return <span>{content}</span>;
  }

  return (
    <Link
      onClick={handleClick}
      to={{
        pathname: ROUTES.BUILD_JOB(buildJob.id),
        state: { from: location.pathname }
      }}
      component={RouterLink}
    >
      {content}
    </Link>
  );
}
