import React from 'react';
import TitanDialog from '../Titan/TitanDialog';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';

export default function FibrifyItemsSelectionDialog({
  title,
  chipsNameKey = 'name',
  multipleSelection = false,
  selectedItems = [],
  loadData,
  columns,
  onSave,
  saveButtonLabel = 'Confirm',
  onClose,
  searchPlaceholder,
  getRowHeight,
  isRowSelectable,
  filtersContent
}) {
  const titanDataGridProps = useTitanDataGrid(loadData);

  const [newSelectedItems, setNewSelectedItems] = React.useState(selectedItems);

  const onSelectionModelChange = React.useCallback(
    newSelectedItems => {
      setNewSelectedItems(newSelectedItems);
    },
    [setNewSelectedItems]
  );

  return (
    <TitanDialog
      title={title}
      maxWidth="lg"
      onClose={onClose}
      onSave={() =>
        onSave(
          multipleSelection
            ? newSelectedItems
            : newSelectedItems.length !== 0
            ? newSelectedItems[0]
            : null
        )
      }
      isValid={newSelectedItems.length !== 0}
      saveButtonLabel={saveButtonLabel}
    >
      <TitanDataGrid
        {...titanDataGridProps}
        columns={columns}
        checkboxSelection={!multipleSelection}
        checkboxMultiSelection={multipleSelection}
        selectedRows={newSelectedItems}
        onSelectionModelChange={onSelectionModelChange}
        searchPlaceholder={searchPlaceholder}
        getRowHeight={getRowHeight}
        selectionChipsNameKey={chipsNameKey}
        isRowSelectable={isRowSelectable}
        filtersContent={filtersContent}
      />
    </TitanDialog>
  );
}
