import React from 'react';
import { ROUTES } from '../../constants';
import TitanPage from '../Titan/TitanPage';
import { useHistory, useParams } from 'react-router-dom';
import TermsForm from './TermsForm';
import TermsAndConditionsService from '../../services/TermsAndConditionsService';

export default function TermsDetailsPage(blocks, entityMap) {
  const history = useHistory();
  const { termsAndConditionsId } = useParams();

  const [termsAndConditions, setTermsAndConditions] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    TermsAndConditionsService.load(termsAndConditionsId).then(async data => {
      await setTermsAndConditions(data);
      setLoading(false);
    });
  }, [termsAndConditionsId]);

  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Admin Dashboard',
        path: ROUTES.ADMIN_DASHBOARD
      },
      {
        name: 'Terms and Conditions',
        disabled: true
      },
      {
        name: termsAndConditions.version ? termsAndConditions.version : '',
        disabled: true
      }
    ],
    []
  );

  return (
    <TitanPage
      loading={loading}
      breadcrumbs={breadcrumbs}
      title={`Terms and Conditions ${
        termsAndConditions.version ? termsAndConditions.version : ''
      }`}
      onBackButtonClick={() =>
        history.push({
          pathname: ROUTES.ADMIN_DASHBOARD_TAB('terms-and-conditions'),
          state: { from: history.location.pathname }
        })
      }
    >
      {termsAndConditions && (
        <TermsForm
          termsAndConditions={termsAndConditions}
          entityMap={entityMap}
          mode="Edit"
        />
      )}
    </TitanPage>
  );
}
