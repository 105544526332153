import React from 'react';

import useFusionModuleState from './use-fusion-module-state';
import ComponentStatus from '../Component/ComponentStatus';

export default function FusionModuleStatus({ fusionModuleId, fusionModule }) {
  const { state, progress, fusionModuleVersion } = useFusionModuleState(
    fusionModuleId,
    fusionModule,
  );

  return (
    <ComponentStatus
      component={{
        status: state,
        progress,
        deviceVersion: fusionModuleVersion,
      }}
    />
  );
}
