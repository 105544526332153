import React from 'react';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import OrganizationService from '../../services/OrganizationService';
import useTitanDataGrid from '../TitanDataGrid/useTitanDataGrid';
import { useParams } from 'react-router-dom';

export default function OrganizationRoleRestrictions({ organizationId }) {
  const { page: pageParam = 0 } = useParams();

  const loadData = React.useCallback(
    async (query, config) => {
      const roleRestrictions = await OrganizationService.getOrganizationRoleRestrictions(
        organizationId,
        config
      );

      return {
        data: roleRestrictions.data.map((roleRestriction, index) => ({
          ...roleRestriction,
          id: roleRestriction.role.id
        }))
      };
    },
    [organizationId]
  );

  const gridOptions = {};

  if (pageParam && Number(pageParam) > 0) {
    gridOptions.page = Number(pageParam);
  }

  const titanDataGridProps = useTitanDataGrid(loadData, gridOptions);

  const { setRows } = titanDataGridProps;

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => row.role.name
      },
      {
        headerName: 'Max number',
        field: 'maxNumber',
        minWidth: 200,
        sortable: false,
        editable: true
      },
      {
        headerName: 'Available',
        field: 'availableNumber',
        minWidth: 200,
        sortable: false
      }
    ],
    []
  );

  const onChangeRoleRestriction = React.useCallback(async params => {
    const updatedRoleRestriction = await OrganizationService.updateOrganizationRoleRestriction(
      organizationId,
      {
        roleId: params.id,
        [params.field]: params.value
      }
    );

    setRows(prev =>
      prev.map(r =>
        r.id === updatedRoleRestriction.roleId
          ? { ...updatedRoleRestriction, id: updatedRoleRestriction.roleId }
          : r
      )
    );
  }, []);

  return (
    <TitanDataGrid
      {...titanDataGridProps}
      onCellEditCommit={onChangeRoleRestriction}
      title="Role restrictions"
      showSearch={false}
      columns={columns}
      pagination={false}
    />
  );
}
