import { ROUTES } from '../../constants';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import TermsAndConditionsService from '../../services/TermsAndConditionsService';
import TitanPage from '../Titan/TitanPage';
import { Paper } from '@mui/material';
import Button from '@mui/material/Button';
import PlusIcon from '../../assets/icons/plus-primary.svg';
import { useAuth0 } from '../Authentication/Auth0';
import Can from '../Authentication/Can';
import { PERMISSIONS } from '../../constants/auth0';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState } from 'draft-js';
import { makeStyles } from '@mui/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TitanButton from '../Titan/TitanButton';
import ProfileService from '../../services/ProfileService';
import htmlToDraft from 'html-to-draftjs';
import { useTitan } from '../Titan/Titan';

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100%',
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: 1160,
    },
    padding: theme.spacing(2),
    margin: '0 auto',
  },
  termsAccepted: {
    height: '100%',
  },
  form: {
    display: 'block',
    margin: '0 auto',
    width: 860,
    marginTop: theme.spacing(2),
  },
}));

export default function TermsPage(entityMap) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { profile, permissions, setProfile } = useAuth0();

  const [termsAndConditions, setTermsAndConditions] = React.useState({});
  const [termsContent, setTermsContent] = React.useState(
    EditorState.createEmpty(),
  );
  const [termsAccepted, setTermsAccepted] = React.useState(
    profile.loggedAs
      ? profile.loggedAsMember.generalTerms
      : profile.generalTerms,
  );
  const [analyticsChecked, setAnalyticsChecked] = React.useState(
    profile.analytics,
  );
  const [termsChecked, setTermsChecked] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const { addPageToPageHistory } = useTitan();

  const acceptTerms = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    await ProfileService.updateProfile({
      generalTerms: termsChecked,
      analytics: analyticsChecked,
      termsAndConditionsId: termsAndConditions.id,
    });

    const profile = await ProfileService.loadProfileData();
    setProfile(profile);

    setIsLoading(false);
    setTermsAccepted(true);

    if (permissions.includes(PERMISSIONS.READ_BUILD_PLANS)) {
      history.push(ROUTES.DASHBOARD);
    } else if (
      permissions.includes(PERMISSIONS.MANAGE_PRODUCTION_EVALUATIONS)
    ) {
      history.push(ROUTES.HOME_PAGE);
    }
  };

  const loadLatestContent = async () => {
    setIsLoading(true);
    const data = await TermsAndConditionsService.loadLatest();

    if (data.id !== profile.termsAndConditionsId) {
      setTermsAccepted(false);
    }

    setTermsAndConditions(data);
    setTermsContent(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlToDraft(data.content), entityMap),
      ),
    );
    setIsLoading(false);
  };

  React.useEffect(() => {
    addPageToPageHistory({
      id: `TERMS`,
      url: ROUTES.TERMS_AND_CONDITIONS,
      label: `Terms and Conditions`,
    });
  }, []);

  React.useEffect(() => {
    loadLatestContent();
  }, []);

  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Terms And Conditions',
        path: ROUTES.TERMS_AND_CONDITIONS,
      },
    ],
    [],
  );

  return (
    <TitanPage
      loading={isLoading}
      title={`Terms And Conditions ${
        termsAndConditions.version ? 'v' + termsAndConditions.version : ''
      }`}
      breadcrumbs={breadcrumbs}
      headerContent={
        <Can
          permissions={[PERMISSIONS.CREATE_TERMS_AND_CONDITIONS]}
          yes={() => (
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                history.push({
                  pathname: ROUTES.NEW_TERMS_AND_CONDITIONS,
                  state: { from: location.pathname },
                });
              }}
            >
              <img src={PlusIcon} alt="" />
              Terms And Conditions
            </Button>
          )}
        />
      }
    >
      <Paper className={classes.content}>
        <Editor
          placeholder="Content"
          editorState={termsContent}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarHidden
          readOnly
        />

        {!termsAccepted && (
          <form className={classes.form} onSubmit={acceptTerms}>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={analyticsChecked}
                    onChange={(e) => setAnalyticsChecked(e.target.checked)}
                    color="primary"
                    name="analytics"
                  />
                }
                label="I agree to anonymously share usage data to improve the stability, performance and the general user experience of fibrify."
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={termsChecked}
                    onChange={(e) => setTermsChecked(e.target.checked)}
                    color="primary"
                    name="terms"
                  />
                }
                label="I agree to the terms of services as listed above *"
              />
            </div>
            <div className={classes.submit}>
              <TitanButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={!termsChecked}
                isLoading={isLoading}
              >
                Proceed
              </TitanButton>
            </div>
          </form>
        )}
      </Paper>
    </TitanPage>
  );
}
